// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_loginBody__1nhGS{\n    position: absolute;\n    width: 100%;\n    min-height: 100%;\n    padding: 60px 10px;\n    background-color: #3f4e62;\n}\n\n.login_loginLogo__2gSMv{\n    text-align: center;\n}\n\n.login_textGreensea__1UxFr{\n    color: #16a085;\n    font-weight: 300;\n    font-size: 30px;\n}\n\n.login_logTitle__JrRlV{\n    font-family: 'Montserrat', sans-serif;\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 30px;\n    color: #154A59;\n    letter-spacing: .35px;\n}", "",{"version":3,"sources":["webpack://src/pages/login/login.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".loginBody{\n    position: absolute;\n    width: 100%;\n    min-height: 100%;\n    padding: 60px 10px;\n    background-color: #3f4e62;\n}\n\n.loginLogo{\n    text-align: center;\n}\n\n.textGreensea{\n    color: #16a085;\n    font-weight: 300;\n    font-size: 30px;\n}\n\n.logTitle{\n    font-family: 'Montserrat', sans-serif;\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 30px;\n    color: #154A59;\n    letter-spacing: .35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginBody": "login_loginBody__1nhGS",
	"loginLogo": "login_loginLogo__2gSMv",
	"textGreensea": "login_textGreensea__1UxFr",
	"logTitle": "login_logTitle__JrRlV"
};
export default ___CSS_LOADER_EXPORT___;
