import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../utils/common";

export const tableConstants = (fetchMessage) => {
  return [
    {
      id: 1,
      name: "Category Name",
      cell: (row) => {
        return (
          <Link to={`/messages/edit/${row.id}`}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Description",
      selector: (row) => row.desc,
      sortable: true,
      reorder: true,
      style: {
        maxWidth: "200px",
      },
    },
    {
      id: 3,
      name: "Date created",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={'message'}
            action={fetchMessage}
            fullPermission={"Messages_FullAccess"}
            approvalPermission={"Messages_Approval"}
            publishPermission={"Messages_Publish"}
            enablePermission={"Messages_Enable"}
            disablePermission={"Messages_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
