export const deletePostData = [
  {
    id: "5fa0336209ab9a249e4b56ed",
    slug: "event-edit",
    creatorId: "5ecb5d7327e66c5ada45579e",
    userId: "5ecb5d7327e66c5ada45579e",
    link: "project/feeds\t",
    postType: "EVENT",
    reportCount: 1.0,
    createTime: 1.618217953709e12,
  },
  {
    id: "5fa140f1af39bc16d7e96b19",
    slug: "event-zzzz",
    creatorId: "5ecb5e6727e66c5ada4557a3",
    userId: "5ecb5e6727e66c5ada4557a3",
    link: "project/feeds\t",
    postType: "EVENT",
    reportCount: 1.0,
    createTime: 1.618217953709e12,
  },
  {
    id: "60deca373211833e8a30c094",
    slug: "upcoming-project-1",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 2.0,
    createTime: 1.625213495378e12,
  },
  {
    id: "60decaa43211833e8a30c10f",
    slug: "upcoming-project3",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "TRAINING",
    reportCount: 2.0,
    createTime: 1.625213604556e12,
  },
  {
    id: "60decacf3211833e8a30c131",
    slug: "upcoming-project-4",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "TRAINING",
    reportCount: 3.0,
    createTime: 1.625213647812e12,
  },
  {
    id: "60decaf73211833e8a30c152",
    slug: "upcoming-5",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "TRAINING",
    reportCount: 1.0,
    createTime: 1.625213687939e12,
  },
  {
    id: "60decb1a3211833e8a30c173",
    slug: "upcoming-6",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 2.0,
    createTime: 1.625213722898e12,
  },
  {
    id: "60decb403211833e8a30c194",
    slug: "upcoming-7",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "TRAINING",
    reportCount: 1.0,
    createTime: 1.625213760514e12,
  },
  {
    id: "60decde63211833e8a30c323",
    slug: "new-job-7",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 1.0,
    createTime: 1.625214438777e12,
  },
  {
    id: "60e328ebe515fe6cd3fbc766",
    slug: "slug-event",
    creatorId: "5ecb5d7327e66c5ada45579e",
    userId: "5ecb5d7327e66c5ada45579e",
    link: "project/feeds\t",
    postType: "EVENT",
    reportCount: 1.0,
    createTime: 1.62549988302e12,
  },
  {
    id: "60e3f60170735b42efe33aed",
    slug: "create-job",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.625552385238e12,
  },
  {
    id: "60e548d970735b42efe33f0f",
    slug: "upcoming-pro1",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 0.0,
    createTime: 1.625639129066e12,
  },
  {
    id: "60e5491070735b42efe33f2d",
    slug: "upcoming-pro2",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "TRAINING",
    reportCount: 0.0,
    createTime: 1.625639184525e12,
  },
  {
    id: "60e5493370735b42efe33f5b",
    slug: "upcoming-job-4",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.625639219834e12,
  },
  {
    id: "60e5495970735b42efe33f86",
    slug: "upcomg-event-5",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "EVENT",
    reportCount: 0.0,
    createTime: 1.625639257857e12,
  },
  {
    id: "60e5498170735b42efe33fb1",
    slug: "upcoming-assignment-6",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 0.0,
    createTime: 1.625639297141e12,
  },
  {
    id: "60e549a870735b42efe33fc8",
    slug: "upcoming-traing-9",
    creatorId: "60e2e39783e189629d74b1b2",
    userId: "60e2e39783e189629d74b1b2",
    link: "project/feeds\t",
    postType: "TRAINING",
    reportCount: 0.0,
    createTime: 1.625639336016e12,
  },
  {
    id: "60ebd67e23577441fef80c10",
    slug: "my-project-new-for-admin-transfer",
    creatorId: "607d15473aa362564d429d23",
    userId: "607d15473aa362564d429d23",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 0.0,
    createTime: 1.626068606158e12,
  },
  {
    id: "60f12ed43458966a0e676513",
    slug: "fdsfsd",
    creatorId: "5ecb5d7327e66c5ada45579e",
    userId: "5ecb5d7327e66c5ada45579e",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.626418900329e12,
  },
  {
    id: "60f12fb03458966a0e6766b5",
    slug: "job-online-cancel",
    creatorId: "60d31bfecb77101fa36d5390",
    userId: "60d31bfecb77101fa36d5390",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.62641912073e12,
  },
  {
    id: "60f1548b3458966a0e67680d",
    slug: "delete-job-flow",
    creatorId: "608b99996e6d041717e8fd52",
    userId: "608b99996e6d041717e8fd52",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.626428555704e12,
  },
  {
    id: "60f154d73458966a0e67686d",
    slug: "org-job-flow",
    creatorId: "608b9c1e6e6d041717e8fd55",
    userId: "608b9c1e6e6d041717e8fd55",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.626428631208e12,
  },
  {
    id: "60f1554c3458966a0e6768a8",
    slug: "circle-flow-job",
    creatorId: "60e2ba9edd47940978d07a4d",
    userId: "60e2ba9edd47940978d07a4d",
    link: "project/feeds\t",
    postType: "JOB",
    reportCount: 0.0,
    createTime: 1.626428748197e12,
  },
  {
    id: "60f155943458966a0e6768e0",
    slug: "delete-flow-ass",
    creatorId: "60e2b340a159b075f532f34a",
    userId: "60e2b340a159b075f532f34a",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 0.0,
    createTime: 1.626428820857e12,
  },
  {
    id: "60ff921117c7743eed8df698",
    slug: "auto-approve-mode",
    creatorId: "60e2b340a159b075f532f34a",
    userId: "60e2b340a159b075f532f34a",
    link: "project/feeds\t",
    postType: "EVENT",
    reportCount: 1.0,
    createTime: 1.627361809931e12,
  },
  {
    id: "60fffa7a747bbb476428b12e",
    slug: "wwwwww",
    creatorId: "60f9255aaeb64d2bbb5aabf9",
    userId: "60f9255aaeb64d2bbb5aabf9",
    link: "project/feeds\t",
    postType: "ASSIGNMENT",
    reportCount: 0.0,
    createTime: 1.627388538106e12,
  },
];
