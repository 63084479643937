import React, { Component } from 'react'
import CommonManagementList from '../CommonManagementList';
import { CommonHeader } from '../../../../utils/commonHeader';
import { Link } from 'react-router-dom';
import styles from "../BelongHere/pre-login.module.css";
import { auth_service } from '../../../../auth/auth_service';

class ProjectManagementList extends Component {
    render() {
        return (
            <CommonHeader>
                <CommonManagementList type={"PROJECT"} />
                {auth_service.getPermission("Pre_Login_Project_Manage_Create", "Pre_Login_Project_Manage_FullAccess") && <Link to={`/pre-login/project-management/create`} className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Create
                </Link>}
            </CommonHeader>
        )
    }
}

export default ProjectManagementList;
