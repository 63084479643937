import React from "react";
import { Link } from "react-router-dom";
import { epochTogmt } from "../../utils/common";
import StatusDetails from "../../components/StatusDetails/status_details";
import StatusDrop from "../../components/StatusDropDown/status_drop";
import styles from "./course.module.css";

export const tableConstantsCourse = (fetchAllCourseList, pageType) => {
    return pageType === "COURSE" ?
        [
            {
                id: 1,
                name: "Name",
                cell: (row) => {
                    return (
                        <Link to={`/course/edit/${row?.id}`}>
                            <span className="sub">{row?.title}</span>
                        </Link>
                    );
                },
                sortable: true,
                style: {
                    color: "#202124",
                    fontSize: "14px",
                    fontWeight: 500,
                },
                grow: 1.5,
            },
            {
                id: 2,
                name: "Slug",
                selector: (row) => row?.slug,
                sortable: true,
            },
            {
                id: 3,
                name: "Sheet Id",
                selector: (row) => row?.sheetId,
                sortable: true,
            },
            {
                id: 4,
                name: "createTime",
                selector: (row) => row?.createTime > 0 ? epochTogmt(row?.createTime) : "-",
                sortable: true,
            },
            {
                id: 5,
                name: "Status",
                cell: (row) => {
                    return (
                        <StatusDetails status={row.status} />
                    );
                },
                sortable: true,
            },
            {
                id: 5,
                name: "Action",
                cell: (row) => {
                    let content = "";
                    let status = "";
                    if (row.status === "DRAFT") {
                        content = "Approve";
                        status = "PENDING_APPROVAL";
                    }
                    if (row.status === "PENDING_APPROVAL") {
                        content = "Publish";
                        status = "PUBLISH";
                    }
                    if (row.status === "PUBLISH") {
                        content = "Enable";
                        status = "ENABLE";
                    }
                    if (row.status === "ENABLE") {
                        content = "Disable";
                        status = "DISABLE";
                    }
                    if (row.status === "DISABLE") {
                        content = "Enable";
                        status = "ENABLE";
                    }
                    return (
                        <StatusDrop
                            children={content}
                            id={row.id}
                            status={status}
                            type={"COURSE"}
                            action={fetchAllCourseList}
                            fullPermission={""}
                            approvalPermission={""}
                            publishPermission={""}
                            enablePermission={""}
                            disablePermission={""}
                        />
                    );
                },
                sortable: true,
                reorder: true,
            },
            {
                id: 6,
                name: "Users",
                cell: (row) => {
                    return (
                        <Link to={`/course/user/${row.id}`} className={`btn ${styles.btnEmailView}`}>View</Link>
                    );
                },
                sortable: true,
            },
        ] : 
        [
            {
                id: 1,
                name: "Name",
                cell: (row) => {
                    return (
                        <span className="sub">{row?.firstName} {row?.lastName}</span>
                    );
                },
                sortable: true,
                style: {
                    color: "#202124",
                    fontSize: "14px",
                    fontWeight: 500,
                },
                grow: 1.5,
            },
            {
                id: 2,
                name: "Email",
                selector: (row) => row?.email,
                sortable: true,
            },
            {
                id: 3,
                name: "Mobile",
                selector: (row) => row?.mobile,
                sortable: true,
            },
            {
                id: 4,
                name: "City",
                selector: (row) => row?.city,
                sortable: true,
            },
            {
                id: 5,
                name: "BirthDay",
                selector: (row) => row?.birthDay > 0 ? epochTogmt(row?.birthDay) : "-",
                sortable: true,
            },
            {
                id: 6,
                name: "Amount",
                selector: (row) => row?.amount,
                sortable: true,
            },
            {
                id: 7,
                name: "createTime",
                selector: (row) => row?.createTime > 0 ? epochTogmt(row?.createTime) : "-",
                sortable: true,
            }
        ];
};