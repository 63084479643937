export const tableLicenceMemberList = () => {
    return [
        {
            id: 1,
            name: "User Name",
            cell: (row) => {
                return (
                    <a href={`${process.env.REACT_APP_frontEndURL}/profile/${row.customUrl}`} target="_blank">
                        <span className="sub">{row.username}</span>
                    </a>
                )
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 2,
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "Country Code",
            selector: (row) => row.countryISDCode,
            sortable: true,
            reorder: true,
        },
        {
            id: 4,
            name: "Phone No",
            selector: (row) => row.mobile,
            sortable: true,
            reorder: true,
        },
        {
            id: 5,
            name: "Country",
            selector: (row) => row.country,
            sortable: true,
            reorder: true,
        },
        
        {
            id: 36,
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            reorder: true,
        },
        {
            id: 7,
            name: "Role",
            selector: (row) => row.role,
            sortable: true,
            reorder: true,
        },
        {
            id: 8,
            name: "Code",
            selector: (row) => row.customCode,
            sortable: true,
            reorder: true,
        }
    ];
};