import StatusDrop from "../../../components/StatusDropDown/status_drop";
import { epochTogmt } from "../../../utils/common";
import {Link} from 'react-router-dom';
import { auth_service } from "../../../auth/auth_service";

export const tableFlagPostConstants = (handleConfirmModal,handleConfirmModalForUnflag) => {
    return [
        {
            id: 1,
            name: "Post Type",
            cell: (row) => {
                return (
                    <Link
                        to={`/report/post-details/${row.id}`}
                        style={{ color: "#154A59" }}
                    >
                        <span className="sub">{row.postType}</span>
                    </Link>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "Entity Created on",
            selector: (row) => epochTogmt(row.createTime),
            sortable: true,
            reorder: true,
        },

        {
            id: 3,
            name: "Creator Name",
            cell: (row) => {
                return (
                    <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.customUrl} target="_blank">
                        <span className="sub">{`${process.env.REACT_APP_frontEndURL}/profile/${row.customUrl}`}</span>
                    </a>
                );
            },
            sortable: true,
            reorder: true,
        },

        {
            id: 4,
            name: "Link",
            cell: (row) => {
                return (
                    <a href={`${process.env.REACT_APP_frontEndURL}/${row.postType === "forum" ? "forumDetail" : row.postType === "poll" ? "pollDetail" : "post"}/${row.slug}?admin=true`} target="_blank">
                        <span className="sub">{`${process.env.REACT_APP_frontEndURL}/${row.postType === "forum" ? "forumDetail" : row.postType === "poll" ? "pollDetail" : "post"}/${row.slug}`}</span>
                    </a>
                );
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 5,
            name: "Reason",
            selector: (row) => row.reason,            
            sortable: true,
            reorder: true,
        },

        {
            id: 6,
            name: "Action",
            cell: (row) => {
                return <><button  className={ "btnUpdte" + (auth_service.getPermission("Report_FlagPost_Delete") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.id, "DELETE");}} disabled={!auth_service.getPermission("Report_FlagPost_Delete")}>
                    Delete
                </button>
                <button className={ "btnUpdte" + (auth_service.getPermission("Report_FlagPost_Unflag") ? "" : " disabled")} onClick={(e) => {handleConfirmModal(row.id, "UNFLAG");}} disabled={!auth_service.getPermission("Report_FlagPost_Unflag")}>
                    UnFlag
                </button>
                </>;

            },
            sortable: true,
            reorder: true,
            width: '230px',
        },

    ];
};
