export const data = [
  {
    id: "6156ada50136125785a753a6",
    year: "2019",
    linkzone1: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    linkzone2: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    timezone1: "UTC +1",
    timezone2: "UTC +10:30",
    speakers: "11",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "5ff4658252a97a324dc5a6ac",
    year: "2020",
    linkzone1: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    linkzone2: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    timezone1: "UTC +1",
    timezone2: "UTC +10:30",
    speakers: "11",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "613c6658ae4d772903489b26",
    year: "2021",
    linkzone1: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    linkzone2: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    timezone1: "UTC +1",
    timezone2: "UTC +10:30",
    speakers: "11",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "613b2667b1e97262f4362e71",
    year: "2022",
    linkzone1: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    linkzone2: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    timezone1: "UTC +1",
    timezone2: "UTC +10:30",
    speakers: "11",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "5ea91ce034b8da3773fd76a7",
    year: "2023",
    linkzone1: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    linkzone2: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    timezone1: "UTC +1",
    timezone2: "UTC +10:30",
    speakers: "11",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "5ef48868de5ecb11d75723d7",
    year: "2024",
    linkzone1: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    linkzone2: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    timezone1: "UTC +1",
    timezone2: "UTC +10:30",
    speakers: "11",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
];
