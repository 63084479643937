// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".location_input_locationWrap__1oYEz {\n  max-width: 572px;\n}\n\n.location_input_locationselect__2nxBt {\n  margin: 0 -8px;\n}\n\n.location_input_selectlist__3E2TN {\n  padding: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://src/components/LocationFields/location_input.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".locationWrap {\n  max-width: 572px;\n}\n\n.locationselect {\n  margin: 0 -8px;\n}\n\n.selectlist {\n  padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locationWrap": "location_input_locationWrap__1oYEz",
	"locationselect": "location_input_locationselect__2nxBt",
	"selectlist": "location_input_selectlist__3E2TN"
};
export default ___CSS_LOADER_EXPORT___;
