import React, { Component } from "react";
import { Link } from "react-router-dom";
import httpService from "../../../../../AxiosInterceptors";
import { CommonHeader } from "../../../../../utils/commonHeader";
import BelongHereForm from "../BelongHereForm";
import { isThisSecond } from "date-fns";

httpService.setupInterceptors();
export class EditBelong extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/pre-login/belong-here" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <BelongHereForm formType={"UPDATE"} id={this.props.match.params.id} loginType={"PRE_LOGIN"} />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default EditBelong;