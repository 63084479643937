import axios from "axios";
import React, { Component } from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import styled from "styled-components";
import styles from "../marketing.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import { auth_service } from "../../../auth/auth_service";
import history from "../../../utils/history";

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class CreatePointsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: auth_service.getUserData()?.id,
            formData: {creditAmount: '',percentageAllowed : ""},
            email: '',
            startDate : null,
            endDate: null,
            searchInputValue: '',
            userList: [],
            selectedUserId: '',
            errors: {},
            isCreditAllowed: true,
            initialValue: {},
            editData: {}
        };
    }
   
    componentDidMount() {
        if(this.props?.formType === "UPDATE"){
            this.fetchCreditPointData();
        }
    };

    handleFormInput = (event) => {
        const { name, value } = event.target;
        this.setState(
            (prevState) => ({formData: {...prevState.formData,[name]: value}})
        );
    }

    handleDateChange = (date,dateType) => {
        this.setState({
            [dateType === "START_DATE" ? "startDate" : "endDate"]: date.getTime()
        })
    };

    handleOnChangeUserName = (data) => {
        this.setState({
            initialValue: data,
            email: data?.email
        })
    }

    handleChangeCreditAllowed = (event) => {
        this.setState({
            isCreditAllowed: event.target.checked
        })
    }

    fetchCreditPointData = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/user/credit/get?id=${this.props?.id}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                this.setState({
                    formData: {creditAmount: result?.creditAmount, percentageAllowed : result?.percentageAllowed},
                    startDate: result?.startPeriod,
                    endDate: result?.validPeriod,
                    email: result?.email,
                    editData: result
                })
                //this.getUserDataById(result?.userId);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getUserDataById = (userId) => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/profile/get?id=${userId}&otherUserId=${this.state.userId}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let responseData = response.data.body;
                this.setState({
                    initialValue: {label: responseData?.userName, value: responseData?.userId, email: responseData?.email, country: responseData?.country},
                    email: responseData?.email
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if(!this.state.initialValue?.value && this.props.formType !== "UPDATE" ) {
            formIsValid = false;
            errors["name"] = "Please select at least one user ";
        }
        if(!this.state.formData.creditAmount) {
            formIsValid = false;
            errors["creditAmount"] = "Please enter credit value";
        }
        // if (!this.state.isCreditAllowed) {
        //     formIsValid = false;
        //     errors["credit_allowed"] = "Please allow your credit";
        // }
        if(!this.state.startDate) {
            formIsValid = false;
            errors["start_date"] = "Please select start date";
        }
        if(!this.state.endDate) {
            formIsValid = false;
            errors["end_date"] = "Please select end date";
        }
        if(!this.state.formData.percentageAllowed || this.state.formData.percentageAllowed > 100){
            formIsValid = false;
            errors["percentageAllowed"] = `Please enter ${!this.state.formData.percentageAllowed ? "your" : "valid"} percentage to be used`;
        }

        this.setState({
            errors: errors,
        });

        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 4000);
        }

        return formIsValid;
    }

    handleOnSubmit = () => {
        if (this.validateForm()) {
            let postData = {
                userId: this.props.formType !== "UPDATE" ? this.state.initialValue?.value : this.state.editData?.userId,
                creditAllowed: this.state?.isCreditAllowed,
                creditAmount: parseInt(this.state.formData?.creditAmount),
                startPeriod: this.state.startDate,
                validPeriod:  this.state.endDate,
                status: this.props.formType === "UPDATE" ? this.state.editData?.status : "DRAFT",
                percentageAllowed: parseInt(this.state.formData.percentageAllowed)
            }
            if(this.props.formType === "UPDATE") {
                postData.id = this.props?.id
            }
            axios({
                method:this.props?.formType === "UPDATE" ? "PUT" : "POST",
                url: `${process.env.REACT_APP_userServiceURL}/user/credit/${this.props.formType === "UPDATE" ? "update" : "create"}`,
                data: postData,
                withCredentials: true,
            }).then((response) => {
                console.log('response:',response)
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    fetchUserData = async (search, loadedOptions, { page }) => {
        const response = await fetch(`${process.env.REACT_APP_userServiceURL}/search/getUsers?userId=${this.state?.userId}&keyWord=${search}&page=${page}&size=20`);
        const responseJSON = await response.json();
        return {
          options: responseJSON.body && responseJSON.body.content && responseJSON.body.content.map((item) => ({label: item?.username, value: item?.id, email: item?.email, country: item?.country})),
          hasMore: !responseJSON.body.page?.last,
          additional: {
            page: page + 1,
          },
        };
    }

    render() {
        const {formData,errors, initialValue, editData} = this.state;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className='row'>
                    <div className="col-md-6">
                        {this.props.formType !== "UPDATE" ?  
                            <div className={"form-group " + (errors["name"] ? 'error' : '')}>
                                <label >Name <mark style={{color:"red"}}>*</mark></label>
                                <AsyncPaginate
                                    closeMenuOnSelect={true}
                                    placeholder={"Select Name"}
                                    value={initialValue}
                                    loadOptions={this.fetchUserData}
                                    onChange={this.handleOnChangeUserName}
                                    additional={{
                                        page: 0,
                                    }}
                                />
                                {errors["name"] &&
                                    <div className="">
                                        <small class="form_input_danger__3kp97">{errors["name"]}</small>
                                    </div>
                                }
                            </div> : 
                            <FormInput
                                value={this.state.editData?.username}
                                label={"Name"}
                                name={"name"}
                                isDisable={true}
                            />
                        } 
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            value={this.state.email}
                            label={"Email"}
                            name={"email"}
                            isDisable={true}
                        />
                    </div>                                 
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            value={formData.creditAmount}
                            label={"Credit Given"}
                            name={"creditAmount"}
                            onChange={this.handleFormInput}
                            error={errors["creditAmount"]}
                            preValue={(initialValue?.country?.toLowerCase() === "india" || editData?.country?.toLowerCase() === "india")  ? '₹' : '$'}
                            showPreSection={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            value={formData.percentageAllowed}
                            label={"% allowed to be used"}
                            name={"percentageAllowed"}
                            onChange={this.handleFormInput}
                            error={errors["percentageAllowed"]}
                        />
                    </div>
                </div>

                {/* <div className={`form-group`} style={{ marginTop: '20px' }}>
                    <label class="switch">
                        Credit allowed <mark style={{color:"red"}}>*</mark>
                        <input
                            type="checkbox"
                            ref="creditAloowed"
                            name="creditAloowed"
                            onClick={(e) => {this.handleChangeCreditAllowed(e)}}
                            checked={this.state.isCreditAllowed}
                        />
                        <span class="slider round"></span>
                    </label>
                    {errors["credit_allowed"] &&
                        <div className="">
                            <small class="form_input_danger__3kp97">{errors["credit_allowed"]}</small>
                        </div>
                    }
                </div> */}

                
                    <div className="row" >
                        <div className="col-md-6">
                            <div className={"form-group " + (errors["start_date"] ? 'error' : '')}>
                                <label>Start Date <span className="text-danger">*</span>{" "}</label>
                                <DatePicker
                                    className={"form-control custom-input-date"}
                                    selected={this.state.startDate}
                                    onChange={(event) => {this.handleDateChange(event, "START_DATE")}}
                                    dateFormat={"dd-MMM-yyyy"}
                                    dropdownMode="select"
                                    placeholderText="Start Date"
                                    minDate={new Date()}
                                    popperPlacement="top"
                                />
                                {errors["start_date"] &&
                                    <div className="">
                                        <small class="form_input_danger__3kp97">{errors["start_date"]}</small>
                                    </div>
                                }
                            </div>
                        </div>
                       <div className="col-md-6">
                            <div className={"form-group " + (errors["end_date"] ? 'error' : '')}>
                                <label>End Date <span className="text-danger">*</span>{" "}</label>
                                <DatePicker
                                    className={"form-control custom-input-date"}
                                    selected={this.state.endDate}
                                    onChange={(event) => {this.handleDateChange(event, "END_DATE")}}
                                    dateFormat={"dd-MMM-yyyy"}
                                    dropdownMode="select"
                                    placeholderText="End Date"
                                    minDate={this.state.startDate}
                                    popperPlacement="top"
                                    disabled={!this.state.startDate}
                                />
                                {errors["end_date"] &&
                                    <div className="">
                                        <small class="form_input_danger__3kp97">{errors["end_date"]}</small>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                 
                <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`} style={{marginTop: '80px'}}>
                    <Button children={"Submit"} onClick={this.handleOnSubmit} />
                </ButtonWrap>
            </div>
        );
    }
}
export default CreatePointsForm;