import axios from "axios";
import React, { Component } from "react";
import {data} from './Data/mockData'
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableConstants } from "./Data/tableConstants";
import Button from "../../../components/Buttons/button";
import styles from "./fe_admin_list.module.css";
import { FilterComponent } from "../../../utils/filter";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FormInput from "../../../components/InputFields/form_input";
import ExportCSV from "../../../components/Buttons/export_csv_button";


const ErorrMsgPopUp = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "User Email", key: "email" },
  { label: "User Id", key: "userId" },
];

export class FEAdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      feAdminList: [],
      filterText: "",
      filterTextOne: "",
      filterDisplay: false,
      changeType: 'ALL',
      exportUserFlag: false,
      fileName: "",
      errors: {},
      error: false,
      loading: false,
      totalRows: 0,
      perPage: 10,
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.feAdminList && this.state.feAdminList.filter(
              (item) =>
                item.email &&
                item.email
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by email'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // validate
 validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.emailId) {
      formIsValid = false;
      errors["emailId"] = "Please enter email id";
    }
    if (
      this.state.emailId != null &&
      this.state.emailId != undefined &&
      this.state.emailId != ""
    ) {
      let emailIdAddress = this.state.emailId;
      let emailIdValid = emailIdAddress.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailIdValid == null) {
        formIsValid = false;
        errors["emailId"] = "Please enter a valid email";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {/* <ExportCSV headers={headers} data={this.state.feAdminList} filename="fe_admin_list" /> */}
      </React.Fragment>
    );
  };

  handleChange = (event) => {
      this.setState({
          emailId: event.target.value.trim()
      })
  }

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        email: this.state.emailId,
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/adminPanelUser/createPermission`,
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            this.setState({
                emailId: ""
            })
            this.fetchFEAdminList(0);
          }
        })
        .catch((error) => {
            console.log(error)
            ErorrMsgPopUp.fire({
                didOpen: () => {
                  ErorrMsgPopUp.clickConfirm()
                }
              }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
              })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          error: false
        });
      }, 5000);
    }
  };


  // fetch all exclude user list
  fetchFEAdminList = async page => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/adminPanelUser/getList?page=${page}&size=${this.state.perPage}`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK"
        ) {
          this.setState({
            feAdminList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          feAdminList: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchFEAdminList(page-1);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminPanelUser/getList?page=${page}&size=${this.state.perPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            feAdminList: response.data.body && response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          feAdminList: [],
          loading: false
        })
      });
  };

  handleConfirmModal = (email) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(confirmModalSwal);
      let postData = {
        email: email
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/adminPanelUser/deletePermission`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              this.fetchFEAdminList(0);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };


  componentDidMount() {
    this.fetchFEAdminList(0);
  }

  render() {
    this.filteredItems = this.state.feAdminList && this.state.feAdminList.filter(
        (item) =>
          item.email &&
          item.email
            .toLowerCase()
            .includes(this.state.filterText.toLowerCase())
    );

    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-6">
                    <FormInput
                        group={"large"}
                        value={this.state.emailId}
                        label={"Email Id"}
                        onChange={this.handleChange}
                        name={"emailId"}
                        error={this.state.errors["emailId"]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Button children={"Add"} onClick={this.onSubmit} />
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              title="FE Admin User List"
              columns={tableConstants(this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              highlightOnHover
              pointerOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default FEAdminList;
