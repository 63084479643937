import { Link } from "react-router-dom";
import { epochTogmt, trimDescription } from "../../../../utils/common";
import { getSubscriptionType } from "../../CommonSubscriptionFunction";
export const tableAmountTenureConstants = () => {


  return [
    {
      id: 1,
      name: "Description",
      cell: (row) => {
        return (
          <Link to={`/subscription/amount-tenure/edit/${row.id}`}>
            <span className="sub">{getSubscriptionType(row.type)}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Tenure monthly(INR)",
      selector: (row) => row.monthly.amount.inr,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Annual Discount pay only for 10 months(INR)",
      selector: (row) => row.annual.amount.inr,
      sortable: true,
      reorder: true,
    }, {
      id: 2,
      name: "Tenure monthly(USD)",
      selector: (row) => row.monthly.amount.usd,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Annual Discount pay only for 10 months(USD)",
      selector: (row) => row.annual.amount.usd,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Created On",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
  ];
};
