import { epochTogmt } from "../../../utils/common";

export const userActionTableConstants = () => {
  return [
    {
      id: 1,
      name: "Name",
      selector: (row) => row.adminName,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Action Type",
      selector: (row) => row.reason,
      cell: (row) => {
        if (row.deactivation) {
          return "Reactivation";
        } else {
         return "Deactivation";
        }        
      }, 
      sortable: true,
      reorder: true,
    },
    {
        id: 3,
        name: "Reported Date",
        selector: (row) => epochTogmt(row.createTime),
        sortable: true,
        reorder: true,
    },
  ];
};
