import axios from "axios";
import React, { Component } from "react";
import { data } from "../Data/mockData";
import { auth_service } from "../../../../../../auth/auth_service";
import { tableAwardCategoriesConstants } from "../Data/tableAwardCategoriesConstants";
import DataTable from "react-data-table-component";
import "../../year.module.css";
import { customStyles } from "../../../../../../utils/tableCustomStyle";
import Export from "../../../../../../components/Buttons/export_button";
import ExportCSV from "../../../../../../components/Buttons/export_csv_button";
import { downloadCSV } from "../../../../../../utils/export_csv";
import { FilterComponent } from "../../../../../../utils/filter";
import Button from "../../../../../../components/Buttons/button";
import styles from "../../year.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../../../utils/commonHeader";
import YearMenuList from "../../../../../../components/YearMenuList/year_menu_list";


const headers = [
  { label: "Section", key: "section" },
  { label: "Title", key: "title" },
  { label: "Nomination Received", key: "nominationCount" },
  { label: "Status", key: "status" },
];
export class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      awardCategoriesList: [],
      selectedYear: this.props.match.params.year,
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.awardCategoriesList.filter(
              (item) =>
                item.title &&
                item.title
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by title'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all years list
  fetchAllAwardCategoriesList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/wpondAwardCategory/getList?year=${this.props.match.params.year}`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            awardCategoriesList: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        <YearMenuList type="award" selectedYear={this.state.selectedYear}/>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("WPOND_AC_ExportCsv", "WPOND_AC_FullAccess") && <ExportCSV headers={headers} data={this.state.awardCategoriesList} filename="award-category" />}
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchAllAwardCategoriesList();
  }

  render() {
    this.filteredItems = this.state.awardCategoriesList.filter(
      (item) =>
        item.title &&
        item.title
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/upcoming-year`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large menu-list">
              <DataTable
                columns={tableAwardCategoriesConstants(
                  this.fetchAllAwardCategoriesList
                )}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("WPOND_AC_Create", "WPOND_AC_FullAccess") && (
        <Link
          to={`/wpond/${this.state.selectedYear}/award-categories/create`}
          className={`btn ${styles.createforumbtn}`}
        >
          <i className="iAddCircle"></i> Create
        </Link>)}
      </CommonHeader>
    );
  }
}

export default EventList;
