import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";
import { epochTogmt, trimDescription } from "../../../../utils/common";
// This is the table constant/settings which needed to render table elements
export const tableConstants = (
  fetchAllPermissionsList,
  deletePermission,
  handleEdit
) => {
  return [
    {
      id: 1,
      name: "Permission Name",
      selector: (row) => row.permission,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Group",
      selector: (row) => row.group,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Created On",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Created By",
      selector: (row) => row.createdBy,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Status",
      selector: (row) => "Enabled",
      sortable: true,
      reorder: true,
    },
  ];
};
