import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import CommomListPage from "./CommomListPage";

export class NotifierViewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/email`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <CommomListPage pageType={"NITIFIER_VIEW"} notifierId={this.props.match.params.id} />
        </div>
      </CommonHeader>
    );
  }
}

export default NotifierViewList;