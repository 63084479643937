import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../utils/commonHeader";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import { postDetailsTableConstants } from "../Data/postDetailsTableConstants";
import { auth_service } from "../../../auth/auth_service";
import { userActionTableConstants } from "../Data/userActionTableConstants";
export class PostDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      postDetailsList: [],
      reportType: this.props.match.params.slug
    };
  }

  // fetch post details data data
  fetchPostDetailsById = () => {
    let url = `reportAbuse/get/?id=${this.props.match.params.id}`;
    if(this.state.reportType === "user-action-history") {
      url = `adminAction/getList?userId=${this.props.match.params.id}`;
    }
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/${url}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            postDetailsList: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    this.fetchPostDetailsById();
  }
 


  render() {
    const { postDetailsList, reportType } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          {reportType === "post-details" &&
              <Link to="/report/post" className="goback">
                Go Back
              </Link>
          }
          {(reportType === "user-details" || reportType === "user-action-history") &&
              <Link to="/report/user" className="goback">
                Go Back
              </Link>
          }
          {reportType === "delete-post-details" &&
              <Link to="/report/delete-post" className="goback">
                Go Back
              </Link>
          }
          {reportType === "studio-details" &&
              <Link to="/report/studio" className="goback">
                Go Back
              </Link>
          }
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
          <DataTable
              title={reportType === "user-action-history" ? "User Action History" : "Reported Details"}
              columns={reportType !== "user-action-history" ? postDetailsTableConstants(this.state.userData) : userActionTableConstants()}
              data={postDetailsList}
              customStyles={customStyles}
             /* pagination
              paginationPerPage={20}*/
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default PostDetails;
