import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import "./index.min.css";
import "./fonts/style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LeftPanel from "./parts/LeftPanel/left_panel";
import Login from "./pages/login/login";
import {auth_service} from "./auth/auth_service";

ReactDOM.render(

  <React.StrictMode>
      <BrowserRouter>
          {auth_service.isAuthenticated() &&
          <LeftPanel/>}
          {!auth_service.isAuthenticated() &&
          <Login/>}

              <App />

      </BrowserRouter>

  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
