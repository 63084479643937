import React from "react";
import style from "./text_area.module.css";
import PropTypes from "prop-types";

const TextArea = ({
  name,
  type,
  placeholder,
  onChange,
  className,
  value,
  error,
  children,
  label,
  group,
  ...props
}) => {
  return (
    <div className={`form-group ${group}`}>
      {label && (
        <label>
          {label} <span className="text-danger">*</span>
        </label>
      )}
      <textarea
        rows="4"
        cols="50"
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        //className={style.formControl}
        className="form-control"
        style={error && { border: "solid 1px red" }}
      />
      {error && <small className={style.danger}>{error}</small>}
    </div>
  );
};

TextArea.defaultProps = {
  type: "text",
  className: "",
};

TextArea.prototype = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;
