import { epochTogmt } from "../../../utils/common";
import {Link} from 'react-router-dom';
import { auth_service } from "../../../auth/auth_service";

export const tableStudioConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Post Type",
      cell: (row) => {
        return (
            <Link
              to={`/report/studio-details/${row.id}`}
              style={{ color: "#154A59" }}
            >
            <span className="sub">{row.postType}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Entity Created on",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Entity Reported on",
      selector: (row) => epochTogmt(row.reportedTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Number of Reports",
      selector: (row) => row.reportCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Link",
      cell: (row) => {
        return (
          <a href={process.env.REACT_APP_frontEndURL+'/studio/'+row.slug+'?admin=true'} target="_blank">
            <span className="sub">{`${process.env.REACT_APP_frontEndURL}/studio/${row.slug}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      cell: (row) => {
        return <button  className={ "btnUpdte" + (auth_service.getPermission("Reports_Studio_Delete") ? '' : ' disabled')} onClick={(e) => {handleConfirmModal(row.id);}}  disabled={!auth_service.getPermission("Reports_Studio_Delete")}>
          Delete
        </button>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
