import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../utils/commonHeader";
import "react-datepicker/dist/react-datepicker.css";
import UpcomingYearForm from "../UpcomingYearForm";
export class CreateUpcomingYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/wpond/upcoming-year" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <UpcomingYearForm formType="CREATE" />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateUpcomingYear;
