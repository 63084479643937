import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../../components/Buttons/button";
import FormInput from "../../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../../utils/commonHeader";
import styles from "../../year.module.css";
import { auth_service } from "../../../../../../auth/auth_service";
import UploadFiles from "../../../../../../components/UploadFiles/upload_files";
import history from "../../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../../components/InputFields/form_input.module.css";
import stripHtml from "string-strip-html";
import MagicUrl from "quill-magic-url";

const ErorrMsgPopUp = withReactContent(alertPopUp);


const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const maxMbFileSize = 100 * 1024 * 1024;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export class CreateMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      selectedYear: this.props.match.params.year,
      coverImage: null,
      link: '',
      content: '',
      title: '',
      coverImageMessage: '',
      videoUploadMessage: "",
      errors: "",
      removeVideoFlag: false,
      videoAttachmentId: "",
      disabledBtn: false
    };
  }
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) {
      formIsValid = false;
      this.setState({
        coverImageMessage: "Please select cover image"
      })
    }
    if (!this.state.title) {
      formIsValid = false;
      errors["title"] = "Please enter title";
    }
    if (!this.state.content || this.state.content.trim() === "" || stripHtml(this.state.content) == '') {
      formIsValid = false;
      errors["content"] = "Please enter description";
    }
    if (this.state.link) {
      let valudateLink = this.state.link;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      if (urlValid == null) {
        formIsValid = false;
        errors["mediaLink"] = "Please enter a valid link";
      }
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  //handle change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  contentChange = (value) => {
    this.setState({ content: value });
  }


  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let postData = {
        year: this.state.selectedYear,
        link: this.state.link,
        description: this.state.content,
        type: 'MEDIA',
        title: this.state.title
      };
      if(this.state.videoAttachmentId) {
        postData.videoAttachmentId = this.state.videoAttachmentId;
      }
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/wpondEventNMedia/create`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };
  modules = {
    toolbar: [
      [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 750, // default
      maxHeight: 500, // default
      imageType: 'image/jpeg' + 'image/png', // default
    },
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
  };

  uploadVideoFile = (file) => {
    let currentFile = file[0];
    let fileSize = currentFile.size;
    if (fileSize > maxMbFileSize) {
      this.setState({
        videoUploadMessage: 'Uploaded file exceeds limit of 100 MB'
      })
    } else {
      const fileName = currentFile.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      const allowedAttachmentsTypeArr = ["mp4", "webm", "mkv", "mov", "avi", "ogg", "flv", "wmv"];
      if (allowedAttachmentsTypeArr.includes(extFile)) {
        this.uploadVideoAttachment(currentFile);
      } else {
        this.setState({
          videoUploadMessage: 'Only ' + allowedAttachmentsTypeArr.join("/") + ' files are allowed!'
        })
      }
    }
  }

  uploadVideoAttachment(currentFile) {
    return new Promise((resolve, reject) => {
      this.setState({ disabledBtn: true });
      const formData = new FormData();
      formData.append("file", currentFile);
      formData.append("userId", this.state.userData.userId);
      formData.append("attachmentType", "VIDEO");
      axios({
        method: 'post',
        url: process.env.REACT_APP_userServiceURL + "/backend/post/uploadAttachment",
        data: formData,
        withCredentials: true
      }).then(response => {
        this.setState({ error: '' });
        if (response && response.data.body !== null && response.data.body.id !== null) {
          this.setState({
            videoAttachmentId: response.data.body?.id,
            removeVideoFlag: true,
            disabledBtn: false
          });
        }
        resolve();
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  removeUploadedVideoFiles = () => {
    this.setState({
      removeVideoFlag: false
    });
  }

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }
  render() {
    const { title ,link, content } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/media`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="row">
                <div className="col-md-8">
                    <UploadFiles
                    accept=".jpg,.png,.jpeg"
                    label="Cover Image"
                    updateFilesCb={this.updateUploadedFiles}
                    imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
                  />
                  {this.state.coverImageMessage &&
                    <div className="text-center">
                      <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                    </div>
                  }
                </div>
                <div className="col-md-4">
                  <UploadFiles
                    accept=".mp4,.webm,.mkv,.mov,.avi,.ogg,.flv,.wmv"
                    label="Video"
                    fileType={"VIDEO"}
                    updateFilesCb={this.uploadVideoFile}
                    logoUploadFiles={true}
                    removeUploadedLogoFiles={this.removeUploadedVideoFiles}
                    removeLogoFlag={this.state.removeVideoFlag}
                    imagePresent={this.state.videoUploadMessage ? 'noImage' : ''}
                  />
                  {this.state.videoUploadMessage &&
                    <div className="text-center">
                      <small class="form_input_danger__3kp97">{this.state.videoUploadMessage}</small>
                    </div>
                  }
                </div>
              </div>
              <FormInput
                group={"large"}
                value={title}
                label={"Title"}
                name={'title'}
                onChange={this.handleChange}
                error={this.state.errors["title"]}
              />
              <FormInput
                group={"large"}
                value={link}
                label={"Link"}
                name={'link'}
                className="not-mandatory"
                onChange={this.handleChange}
                error={this.state.errors["mediaLink"]}
              />
              <div className={"form-group " + (this.state.errors["content"] ? 'error' : '')}>
                <label>
                  Content <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={content}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["content"] && (
                  <small className={style.danger}>
                    {this.state.errors["content"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("WPOND_Media_Create", "WPOND_Media_FullAccess") && (
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Submit"} onClick={this.onSubmit} disabledBtn={this.state.disabledBtn} />
              </ButtonWrap>)}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateMedia;
