export const data = [
  {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637658980339,
    "trackingId" : null,
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "created",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "created"
  }, {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637658980796,
    "trackingId" : null,
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "created",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "created"
  }, {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637658990412,
    "trackingId" : null,
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "created",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "created"
  }, {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637659005706,
    "trackingId" : null,
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "created",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "created"
  }
];
