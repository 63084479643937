import React, { Component } from "react";
import styles from "./CreateOthersSubscription/create_others_subscription.module.css";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import Select from "react-select";
import history from "../../../utils/history";
import httpService from "../../../AxiosInterceptors";
import style from "../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getSubscriptionType, subscriptionType } from "../CommonSubscriptionFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp)
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class OthersSubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionId: "",
            subscriptionType: "",
            managePermission: false,
            errors: {},
            error: false,
            message: null,
        };
    }

    // validate create amount
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.subscriptionType) {
            formIsValid = false;
            errors["subscriptionType"] = "Please enter subscription type";
        }
        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    handleChangeChk = (event) => {
        this.setState({
            [event.target.name]: event.target.checked,
        });
    }

    subscriptionType = (type) => {
        this.setState({ subscriptionType: type });
    };

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                type: this.state.subscriptionType && this.state.subscriptionType.value,
                managePermission: this.state.managePermission
            };
            if(this.props.formType === "EDIT") {
                postData.id = this.state.subscriptionId;
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/subscription/${this.props.formType === "CREATE" ? "other_create" : "other_update"}`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.message === "Success!"
                    ) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: false
                });
            }, 5000);
        }
    };

    fetchSubscriptionById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/subscription/other/get?id=${this.props.id}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response && response.data && response.data.body &&
                    response.data.status === "200 OK"
                ) {
                    this.setState({
                        subscriptionId: response.data.body.id,
                        subscriptionType: { value: response.data.body.type, label: getSubscriptionType(response.data.body.type) },
                        managePermission: response.data.body && response.data.body.managePermission
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    componentDidMount() {
        this.fetchSubscriptionById();
    }

    render() {
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="d-flex align-items-center">Subscription Type <span className="text-danger">*</span></label>
                        <Select
                            options={subscriptionType}
                            onChange={this.subscriptionType}
                            placeholder={"Start typing"}
                            filterOption={(option) => option.value !== "ALL"}
                            closeMenuOnSelect={true}
                            value={this.state.subscriptionType}
                            isDisabled={this.props.formType === "EDIT" ? true : false}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {this.state.errors["subscriptionType"] && (
                            <small className={style.danger}>
                                {this.state.errors["subscriptionType"]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" defaultChecked={this.state.managePermission} ref="managePermission" name="managePermission" onClick={this.handleChangeChk} checked={this.state.managePermission} />
                            <label className="custom-control-label" htmlFor="managePermission"
                                onClick={(e) => {
                                    this.refs["managePermission"].click();
                                }}>
                                Manage Permisssion
                            </label>
                        </div>
                    </div>
                </div>

                <ButtonWrap className={`${styles.bottombutt}`}>
                    <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                </ButtonWrap>
            </div>
        );
    }
}

export default OthersSubscriptionForm;
