import React, { Component } from "react";
import { Link } from "react-router-dom";
import httpService from "../../../../../AxiosInterceptors";
import { CommonHeader } from "../../../../../utils/commonHeader";
import CommonManagementForm from "../../CommonManagementForm";

httpService.setupInterceptors();
export class CreateProjectManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/pre-login/project-management" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <CommonManagementForm formType={"CREATE"} type={"PROJECT"} />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default CreateProjectManagement;