import React from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../../../auth/auth_service";

export const tableConstants = (handleConfirmModal, handleRestrictFundRaise) => {
  return [
    {
      id: 1,
      name: "Organization Name",
      cell: (row) => {
        return <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
          <span className="sub">{row.organizationName}</span>
        </a>
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 18,
      name: "Restrict Fund Raise",
      cell: (row) => {
        return (row.status !== "DELETED" && auth_service.getUserData().email === "punam@wsegroup.in") ? <div className="form-group">
          <label class="switch">
            <input
                type="checkbox"
                name="restrictFundRaise"
                className="restrictFundRaise"
                onClick={(e) => {handleRestrictFundRaise(row.userId, row.fundRaiseRestrictionPermission);}}
                checked={row.fundRaiseRestrictionPermission}
            />
            <span class="slider round"></span>
          </label>
      </div> : null
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "MyWeNat",
      selector: (row) => row?.licenseMember ? "Yes" : "No",
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Subscription",
      cell: (row) => {
        let type = "";
        if (row.subscriptionType == "BRONZE") {
          type = "Standard";
        } else if (row.subscriptionType == "SILVER") {
          type = "Professional"
        } else if (row.subscriptionType == "GOLD") {
          type = "Enterprise"
        } else if (row.subscriptionType == "PLATINUM") {
          type = "Grand"
        } else {
          type = row.subscriptionType;
        }
        return (
          <span className="sub">{type}</span>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Email",
      selector: (row) => row.email,
      conditionalCellStyles: [
        {
          when: row => row.emailVerified,
          classNames: ['emailVerified'],
        },
        {
          when: row => !row.emailVerified,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
    },
   /* {
        id: 5,
          name: "Email Status",
          selector: (row) => row.emailVerified ? 'Verified':'Unverified',
          conditionalCellStyles: [
        {
          when: row => row.emailVerified,
          classNames: ['emailVerified'],
        },
        {
          when: row => !row.emailVerified,
          classNames: ['emailUnverified'],
        },
      ],
          sortable: true,
          reorder: true,
    },*/
    {
      id: 6,
      name: "Country Code",
      cell: (row) => {
        return '+' + row.countryCode
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Phone no.",
      selector: (row) => row.phoneNo,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Admin",
      cell: (row) => {
      return ( row.noOfAdmins > 0 ?
          <Link 
            to={{
              pathname: `/user/organization/${row.userId}`,
              state: { columnName: 'ADMIN',userName: row?.organizationName },
            }}
          >
            <span className="sub">{row.noOfAdmins}</span>
          </Link> : row.noOfAdmins
      );
      },
      sortable: true,
  },

  {
    id: 10,
    name: "No of Employees",
    cell: (row) => {
    return ( row.noOfEmployees > 0 ?
        <Link 
          to={{
            pathname: `/user/organization/${row.userId}`,
            state: { columnName: 'EMPLOYEES',userName: row?.organizationName },
          }}
        >
          <span className="sub">{row.noOfEmployees}</span>
        </Link> : row.noOfEmployees
    );
    },
    sortable: true,
  },

    {
      id: 11,
      name: "No of Followers",
      selector: (row) => row.noOfFollowers,
      sortable: true,
      reorder: true,
    },

    {
      id: 10,
      name: "No of Projects",
      cell: (row) => {
      return ( row.noOfProjects > 0 ?
          <Link 
            to={{
              pathname: `/user/organization/${row.userId}`,
              state: { columnName: 'PROJECT',userName: row?.organizationName },
            }}
          >
            <span className="sub">{row.noOfProjects}</span>
          </Link> : row.noOfProjects 
      );
      },
      sortable: true,
    },

    {
      id: 13,
      name: "No of Post",
      selector: (row) => row.noOfPosts,
      sortable: true,
      reorder: true,
    },
    {
      id: 14,
      name: "Created on",
      selector: (row) => row.createdOn,
      sortable: true,
      reorder: true,
    },
    {
      id: 15,
      name: "Last login",
      selector: (row) => row.lastLogin,
      sortable: true,
      reorder: true,
    },
    {
      id: 18,
      name: "Action",
      cell: (row) => {
        return (row.status === "DELETED" ? 
          <button  className={ "btnUpdte"} onClick={(e) => {handleConfirmModal(row.userId);}}  disabled={false}>
            Reactive
          </button> : 
          null);
      },
      sortable: true,
      reorder: true,
    },
  ];
};
