import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import UploadFiles from "../../../../../../components/UploadFiles/upload_files";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../../components/Buttons/button";
import FormInput from "../../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../../utils/commonHeader";
import styles from "../../year.module.css";
import { auth_service } from "../../../../../../auth/auth_service";
import history from "../../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../../components/InputFields/form_input.module.css";
import stripHtml from "string-strip-html";
import MagicUrl from "quill-magic-url";
import Select from "react-select";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);


export class EditAwardCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverImage: null,
      logoImage: null,
      selectedYear: this.props.match.params.year,
      title: "",
      description: "",
      coverImageMessage: '',
      logoImageMessage: '',
      sectionSelectedValue: 'PRO',
      editData: {},
      prize: "",
      termsAndCondition: "",
      faqs: "",
      removeLogoFlag: false,
      isSponsorImageChange: false,
      errors: "",
      nomineesList: [],
      winnerDetails: {},
      runnerDetails: {},
      yearDetails: {},
      sponsorTitle: ""
    };
  }
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.prize || this.state.prize.trim() === "") {
      formIsValid = false;
      errors["prize"] = "Please enter cash prize";
    }
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter title";
    }
    if (!this.state.description || this.state.description.trim() === "" || stripHtml(this.state.description) == '') {
      formIsValid = false;
      errors["description"] = "Please enter description";
    }
    if (this.state.description && stripHtml(this.state.description) != "" && stripHtml(this.state.description).match(/\S+/g).length > 250) {
      formIsValid = false;
      errors["description"] = "Description should be within 250 words";
    }
    if (!this.state.termsAndCondition || this.state.termsAndCondition.trim() === "" || this.state.termsAndCondition === "<p><br></p>") {
      formIsValid = false;
      errors["termsAndCondition"] = "Please enter terms and condition";
    }
    if (!this.state.faqs || this.state.faqs.trim() === "" || this.state.faqs === "<p><br></p>") {
      formIsValid = false;
      errors["faqs"] = "Please enter faqs";
    }
    

    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          logoImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  onChangeValue(value) {
    this.setState({
      sectionSelectedValue: value
    })
  }

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  updateUploadedLogoFiles = (files) => {
    this.setState({
      logoImage: files[0],
      removeLogoFlag: true,
      isSponsorImageChange: true
    });
  }

  removeUploadedLogoFiles = (files) => {
    this.setState({
      logoImage: null,
      removeLogoFlag: false,
      isSponsorImageChange: true
    });
  }

  // handle content change
  contentChange = (value) => {
    this.setState({ description: value });
  };

  termsAndConditionChange = (value) => {
    this.setState({ termsAndCondition: value });
  };

  faqsChange = (value) => {
    this.setState({ faqs: value });
  };

  //handle change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  //   prize value change
  prizeHandleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("coverImage", this.state.coverImage);
      formData.append("sponsorImage", this.state.logoImage);
      let postData = {
        id: this.state.editData.id,
        status: this.state.editData.status,
        year: this.state.selectedYear,
        title: this.state.title,
        description: this.state.description,
        section: this.state.sectionSelectedValue,
        prize: this.state.prize,
        termsAndCondition: this.state.termsAndCondition,
        faqs: this.state.faqs,
        sponsorImageChange: this.state.isSponsorImageChange,
        winnerId: this.state.winnerDetails?.value,
        // runnerId: this.state.runnerDetails && this.state.runnerDetails.map((data) => {return data?.id}),
        runnerId: [this.state.runnerDetails?.value],
        sponsorTitle: this.state.sponsorTitle
      };

      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/wpondAwardCategory/update`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };

  modules = {
    toolbar: [
      [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 750, // default
      maxHeight: 500, // default
      imageType: 'image/jpeg' + 'image/png', // default
    },
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
  };

  modulesDesc = {
    toolbar: [
      [{ header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
  };

  fetchAwardCategoriesById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/wpondAwardCategory/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            editData: response.data.body,
            title: response.data.body.title,
            description: response.data.body.description,
            sectionSelectedValue: response.data.body.section,
            prize: response.data.body.prize,
            termsAndCondition: response.data.body.termsAndCondition,
            faqs: response.data.body.faqs,
            sponsorTitle: response.data.body?.sponsorTitle
          }, () => {
            if(response.data.body.sponsorImages) {
              this.setState({
                removeLogoFlag: true
              })
            }
            this.getYearDetails();
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.fetchAwardCategoriesById();
  }

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  getYearDetails = () => {
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_userServiceURL}/wpondAbout/getByYear?year=${this.props.match.params.year}`,
          withCredentials: true,
        })
        .then((response) => {
        if (response && response.data && response.data.body && response.data.message === "Success!") {
          this.setState({
            yearDetails: response.data?.body
          },() => {
            if(response.data.body?.status === "COMPLETED"){
              this.getWpondNomineesList();
            }
          });
        }
        })
        .catch((error) => {
        console.log(error);
        });
    };

  getWpondNomineesList = () => {

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/getNomineeListCategoryWise?year=${this.state.editData?.year}&awardCategorySlug=${this.state.editData?.slug}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.body && response.data.message === "Success!") {

          let nomineesOptionsList = [];
          response.data.body && response.data.body.length > 0 && response.data.body.map((data) => {
            nomineesOptionsList.push( { value: data?.userId, label: data.name })

          });

          this.setState({
            nomineesList: nomineesOptionsList
          },() =>{
            let winnerData = this.state.nomineesList.find(item => item.value === this.state.editData?.winnerId);
            let runnerUpsData = this.state.nomineesList.find(item => item.value === (this.state.editData?.runnerId && this.state.editData?.runnerId[0]));

            // let foundRunnerUps = this.state.editData?.runnerId && this.state.editData?.runnerId.map(id => {
            //   let foundRunnerUpsDetails = this.state.nomineesList.find(user => user?.id === id)
            //   return { value: foundRunnerUpsDetails?.name, label: foundRunnerUpsDetails?.name, id: id }
            // });

            this.setState({
              winnerDetails: winnerData,
              runnerDetails: runnerUpsData
            })
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChangeWinnerName = (data) => {
    this.setState({
      winnerDetails : data,
      runnerDetails: {}
    })
  };

  handleChangeRunnerUpName = (data) => {
    this.setState({
      runnerDetails: data
    })
  };

  handleResetWinnerRunnerUp = (type) => {

    if(type === "WINNER") {
      this.setState({ winnerDetails: {},runnerDetails: {}})
    }else{
      this.setState({runnerDetails: {}})
    }
  };
  
  render() {
    const { title, description, prize, termsAndCondition, faqs, sponsorTitle } = this.state;

    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/award-categories`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="form-group">
                <label>
                  Section
                </label>
                <div className="row">
                  <div className="col-md-2">
                    <div class="custom-control custom-checkbox">
                      <label class="radio-container" onClick={(e) => this.onChangeValue("PRO")}>Pro
                        <input type="radio" name="radio" className={this.state.sectionSelectedValue === "PRO" ? "checked" : ''} />
                        <span class="radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="custom-control custom-checkbox">
                      <label class="radio-container" onClick={(e) => this.onChangeValue("GREEN_CHILDHOOD")}>Green childhood
                        <input type="radio" name="radio" className={this.state.sectionSelectedValue === "GREEN_CHILDHOOD" ? "checked" : ''} />
                        <span class="radio-checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <FormInput
                group={"large"}
                value={title}
                label={"Title"}
                name={"title"}
                onChange={this.handleChange}
                error={this.state.errors["title"]}
              />
              <div className="row">
                <div className="col-md-8">
                  <UploadFiles
                    accept=".jpg,.png,.jpeg"
                    label="Cover Image"
                    updateImage={this.state.editData.image}
                    updateFilesCb={this.updateUploadedFiles}
                    imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
                  />
                  {this.state.coverImageMessage &&
                    <div className="text-center">
                      <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                    </div>
                  }
                </div>
                <div className="col-md-4">
                  <UploadFiles
                    accept=".jpg,.png,.jpeg"
                    label="Sponsors Logo"
                    updateImage={this.state.editData.sponsorImages}
                    updateFilesCb={this.updateUploadedLogoFiles}
                    logoUploadFiles={true}
                    removeUploadedLogoFiles={this.removeUploadedLogoFiles}
                    removeLogoFlag={this.state.removeLogoFlag}
                    imagePresent={this.state.logoImageMessage ? 'noImage' : ''}
                  />
                  {this.state.logoImageMessage &&
                    <div className="text-center">
                      <small class="form_input_danger__3kp97">{this.state.logoImageMessage}</small>
                    </div>
                  }
                </div>
              </div>
              <FormInput
                group={"large"}
                value={sponsorTitle}
                label={"Sponsor Title"}
                onChange={this.handleChange}
                className={"not-required"}
                name={"sponsorTitle"}
              />
              <div className={"form-group " + (this.state.errors["description"] ? 'error' : '')}>
                <label>
                  Description <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={description}
                    onChange={this.contentChange}
                    modules={this.modulesDesc}
                  />
                </div>
                {this.state.errors["description"] && (
                  <small className={style.danger}>
                    {this.state.errors["description"]}
                  </small>
                )}
              </div>
              <FormInput
                group={"large"}
                value={prize}
                label={"Cash Prize"}
                onChange={this.prizeHandleChange}
                name={"prize"}
                error={this.state.errors["prize"]}
              />
              <div className={"form-group " + (this.state.errors["termsAndCondition"] ? 'error' : '')}>
                <label>
                  Terms & Conditions <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={termsAndCondition}
                    onChange={this.termsAndConditionChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["termsAndCondition"] && (
                  <small className={style.danger}>
                    {this.state.errors["termsAndCondition"]}
                  </small>
                )}
              </div>
              <div className={"form-group " + (this.state.errors["faqs"] ? 'error' : '')}>
                <label>
                  FAQs <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={faqs}
                    onChange={this.faqsChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["faqs"] && (
                  <small className={style.danger}>
                    {this.state.errors["faqs"]}
                  </small>
                )}
              </div>

            { this.state.yearDetails?.status === "COMPLETED" &&
              <div className="row" >
                  {/* wpond Winner section */}

                  <div className="col-md-6">

                        <div className="row"> 
                            <label className="d-flex align-items-center pl-3">Winner</label>
                            {this.state.winnerDetails?.value && <button type="button" className="btn delbtn"><i className="iTrashBin" style={{position:'relative',top:'-3px'}}  onClick={() => this.handleResetWinnerRunnerUp("WINNER")} /></button>}
                        </div>

                        <Select
                          options={this.state.nomineesList}
                          onChange={this.handleChangeWinnerName}
                          placeholder={"Select winner name"}
                          value={this.state.winnerDetails?.value ? this.state.winnerDetails : null}
                          closeMenuOnSelect={true}
                          styles={{
                            option: (
                              styles,
                              { data, isDisabled, isFocused, isSelected }
                            ) => {
                              const color = "#cfe7c7";
                              return {
                                ...styles,
                                backgroundColor: isDisabled
                                  ? null
                                  : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                      ? "#cfe7c7"
                                      : null,
                                color: isDisabled
                                  ? "#ccc"
                                  : isSelected
                                    ? color > 2
                                      ? "#00374d"
                                      : "#00374d"
                                    : data.color,
                                cursor: isDisabled ? "not-allowed" : "default",

                                ":active": {
                                  ...styles[":active"],
                                  backgroundColor:
                                    !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                },
                              };
                            },
                          }}
                        />
                  </div>
                  

                    {/* wpond Runner Up section */}
                  <div className="col-md-6">

                    <div className="row">
                      <label className="d-flex align-items-center pl-3">Runner</label>
                          {this.state.runnerDetails?.value && <button type="button" className="btn delbtn"><i className="iTrashBin" style={{position:'relative',top:'-3px'}} onClick={() => this.handleResetWinnerRunnerUp("RUNNER")} /></button>}
                      </div>
                      <Select
                        options={this.state.nomineesList.filter(option => option.value !== (this.state.winnerDetails && this.state.winnerDetails.value))}
                        onChange={this.handleChangeRunnerUpName}
                        placeholder={"Select runner up name"}
                        value={this.state.runnerDetails?.value ? this.state.runnerDetails : null}
                        closeMenuOnSelect={true}
                        isDisabled={this.state.winnerDetails && this.state.winnerDetails.value ? false : true}
                        styles={{
                          option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                          ) => {
                            const color = "#cfe7c7";
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                  ? "#cfe7c7"
                                  : isFocused
                                    ? "#cfe7c7"
                                    : null,
                              color: isDisabled
                                ? "#ccc"
                                : isSelected
                                  ? color > 2
                                    ? "#00374d"
                                    : "#00374d"
                                  : data.color,
                              cursor: isDisabled ? "not-allowed" : "default",

                              ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                  !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                              },
                            };
                          },
                        }}
                      />

                  </div>

              </div>
            }

              {auth_service.getPermission("WPOND_AC_Update", "WPOND_AC_FullAccess") && 
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Update"} onClick={this.onSubmit} />
              </ButtonWrap>}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditAwardCategories;
