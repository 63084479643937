import axios from "axios";
import React, { Component } from "react";
import styles from "./dashboard.module.css";
import { tableConstants } from "./Data/tableConstants";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "../../components/Buttons/button";
import { DateRange } from "react-date-range";
import { utcTodate, epochTogmt } from "../../utils/common";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/tableCustomStyle";
import { CommonHeader } from "../../utils/commonHeader";
import { auth_service } from "../../auth/auth_service";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CSVLink, CSVDownload } from "react-csv";
import { data } from '../../utils/mockData';

const ErorrMsgPopUp = withReactContent(alertPopUp);

// date range data
const options = [
  { value: "TODAY", label: "TODAY" },
  { value: "YESTERDAY", label: "YESTERDAY" },
  { value: "LAST7DAY", label: "LAST 7 DAY" },
  { value: "LAST14DAY", label: "LAST 14 DAY" },
  { value: "MONTHTODATE", label: "MONTH TO DATE" },
  { value: "LASTMONTH", label: "LAST MONTH" },
  { value: "CUSTOM", label: "CUSTOM" },
];

const headers = [
  { label: "Particulars", key: "particular" },
  { label: "Total", key: "total" },
  { label: "New", key: "newCount" },
  { label: "Deleted", key: "deleted" },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      exportDashboardList: [],
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRangeButton: false,
      displayRange: false,
      dateData: { value: "YESTERDAY", label: "YESTERDAY" },
      isEnabledCancelFiler: false,
      exportUserFlag: false,
      fileName: "",
      isSearchDisabled: true,
      searchFlag: false,
      expandedRows: [],
    };
  }

  cancelDateRange = () => {
    this.setState({
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      dateData: { value: "YESTERDAY", label: "YESTERDAY" },
      displayRangeButton: false,
      displayRange: false,
    })
    this.fetchDashboardData("YESTERDAY");
  }

  //Cancel filer
  cancelFilter = () => {
    this.setState({
      isEnabledCancelFiler: false,
      dateData: { value: "YESTERDAY", label: "YESTERDAY" },
    });
    this.fetchDashboardData("YESTERDAY");
  }

  // fetch default table data
  fetchDashboardData = (date) => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL + "/user/dashboard/?date=" + date,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            tableData: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // date range change
  handleChange = (newValue) => {
    if (newValue.value === "CUSTOM") {
      this.setState({
        dateData: { value: newValue.value, label: newValue.label },
        displayRangeButton: true,
        isEnabledCancelFiler: false
      });
    } else {
      this.setState({
        dateData: { value: newValue.value, label: newValue.label },
        displayRangeButton: false,
        isEnabledCancelFiler: true
      }, () => {
        this.fetchDashboardData(this.state.dateData.value);
      });
    }
  };

  // fetch date from date range
  fetchFromDateRange = (startDate, endDate) => {
    this.setState({
      tableData: [],
      displayRange: false
      //displayRangeButton: false,
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/user/dashboard/?date=CUSTOM&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate),
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            tableData: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // display range select
  displayRangeSelect = () => {
    this.setState({
      displayRange: this.state.displayRange ? false : true,
    });
  };

  // handle range select
  handleSelect = (range) => {
    console.log(range);
    this.setState(
      {
        date: {
          ...this.state.date,
          startDate: range.selection.startDate,
          endDate: range.selection.endDate,
          key: range.selection.key,
        },
      },
      () => {
        console.log("Data", this.state.date);
      }
    );
  };

  closeDateRange = () => {
    this.setState({
      displayRange: false
    })
  }

  exportCsvModalClose = () => {
    this.setState({
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRange: false
    })
  }

  exportButton = (startDate, endDate) => {
    this.setState({
      displayRange: false
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/user/dashboard/?date=CUSTOM&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate),
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          let date = new Date();
          let dateMs = date.valueOf();
          const file = "dashboard-" + epochTogmt(dateMs) + "-" + dateMs + ".csv";
          let dashboardDataList = [];
          let dashboardData = response.data.body;
          dashboardData && dashboardData.map((item) => {
              dashboardDataList.push({"particular": item.particular, "total": item.total, "newCount": item.newCount, "deleted": item.deleted})
              if(item.metaData?.length > 0) {
                item.metaData && item.metaData.map((data) => {
                  dashboardDataList.push({"particular": data.particular, "total": data.total, "newCount": data.newCount, "deleted": data.deleted})
                })
              } 
          })
          this.setState({
            exportDashboardList: dashboardDataList,
            exportUserFlag: true,
            fileName: file
          }, () => {
            this.csvLink.link.click();
          });
          window.$("#exportCsvModal").modal('hide');
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.status === "404 NOT_FOUND") {
          window.$("#exportCsvModal").modal('hide');
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>User not found in this date range.</p>)
          })
        }
        this.setState({
          exportDashboardList: [],
          exportUserFlag: false
        });
      });
  };

  componentDidMount() {
    this.fetchDashboardData("YESTERDAY");
  }

  handleRowExpand = (row) => {
    const isRowExpanded = this.state.expandedRows.includes(row.particular);
    const newExpandedRows = isRowExpanded
      ? this.state.expandedRows.filter((particular) => particular !== row.particular)
      : [...this.state.expandedRows, row.particular];
    this.setState({ expandedRows: newExpandedRows });
  };

  getSubRows = (row) => {
    return this.props.data.filter((item) => item.parent === row.particular);
  };

  render() {
    const { tableData, displayRange, displayRangeButton, date, isEnabledCancelFiler } = this.state;
    const transformedData = tableData.map((item) => ({
      ...item,
      children: tableData.filter((child) => child.parent === item.particular),
      expanded: false,
    }));

    const ExpandedComponent = ({ data }) => {
      const customStyles = {
        headCells: {
          style: {
            display: 'none', // Hide the header cells
          },
        },
        headRow: {
          style: {
            minHeight: "inherit",
          },
        },
        col: {},
        rows: {
          highlightOnHoverStyle: {
            backgroundColor: "rgb(230, 244, 244)",
            borderBottomColor: "#FFFFFF",
            outline: "1px solid #FFFFFF",
          },
        },
        pagination: {
          style: {
            border: "none",
          },
        },
      };
      if (data?.metaData?.length > 0) {
        return (
          <DataTable
            columns={tableConstants()}
            data={data.metaData}
            customStyles={customStyles}
            expandableIcon
            expandableRows
          />
        );
      }else{
        return <></>
      }

    };
    const rowPreDisabled = row => row.metaData?.length == 0;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={styles.dgcontainer}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <Select
                      options={options}
                      placeholder={"YESTERDAY"}
                      onChange={this.handleChange}
                      value={this.state.dateData}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  {isEnabledCancelFiler &&
                    <div className="col-md-3">
                      <Button
                        onClick={(e) => {
                          this.cancelFilter();
                        }}
                        type={"anchor"}
                        children={`Clear Filter`}
                      />
                    </div>
                  }
                  {displayRangeButton && (
                    <>
                      <div className="col-md-5">
                        <Button
                          onClick={this.displayRangeSelect}
                          type={"range"}
                          children={`${utcTodate(
                            this.state.date.startDate
                          )} to ${utcTodate(this.state.date.endDate)}`}
                        />
                        <Button
                          onClick={(e) => {
                            this.fetchFromDateRange(
                              this.state.date.startDate,
                              this.state.date.endDate
                            );
                          }}
                          type={"anchor"}
                          children={`Apply Filter`}
                        />
                        <Button
                          onClick={(e) => {
                            this.cancelDateRange();
                          }}
                          type={"anchor"}
                          children={`Cancel`}
                        />
                        {displayRange && (
                          <DateRange
                            ranges={[date]}
                            onChange={this.handleSelect}
                            maxDate={new Date()}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {auth_service.getPermission("Dashboard_ExportCSV") &&
                    <div className={`${styles.topBtns} ml-auto`}>
                      <a
                        href="#exportCsvModal"
                        className={`btn exportBtn`}
                        data-toggle="modal"
                      >
                        <i className={"iDownloadFile"}></i>Export CSV
                      </a>
                    </div>}
                </div>
              </div>
            </div>
            <DataTable
              title="Dashboard"
              columns={tableConstants()}
              data={transformedData}
              customStyles={customStyles}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 216px)"}
              expandableRows
              expandOnRowClicked
              expandableRowsComponent={ExpandedComponent}
              expandableRowDisabled={rowPreDisabled}
              //expandableRowsHideExpander
              // expandableRowExpanded={(row) => row.expanded}
              // onRowExpand={this.handleRowExpand}
              // getExpandedSubRows={this.getSubRows}
            />
          </div>

          <CSVLink headers={headers} data={this.state.exportDashboardList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

          <div class="modal fade dgpopup" id="exportCsvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Select Date Rage</h5>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <Button
                        onClick={this.displayRangeSelect}
                        type={"range"}
                        children={`${utcTodate(
                          this.state.date.startDate
                        )} to ${utcTodate(this.state.date.endDate)}`}
                      />
                      <Button
                        onClick={(e) => {
                          this.closeDateRange();
                        }}
                        type={"anchor"}
                        children={`CLose`}
                      />
                      {this.state.displayRange &&
                        <DateRange
                          ranges={[date]}
                          onChange={this.handleSelect}
                          maxDate={new Date()}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.exportCsvModalClose} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={() => this.exportButton(this.state.date.startDate, this.state.date.endDate)}><i class="iDownloadFile" /> Export</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default Dashboard;
