export const data = [
  {
    id: "6156ada50136125785a753a6",
    year: "2019",
    events: "4",
    medias: "4",
    status: "DRAFT",
  },
  {
    id: "5ff4658252a97a324dc5a6ac",
    year: "2020",
    events: "4",
    medias: "4",
    status: "UPCOMING",
  },
  {
    id: "613c6658ae4d772903489b26",
    year: "2021",
    events: "4",
    medias: "4",
    status: "COMPLETED",
  },
  {
    id: "613b2667b1e97262f4362e71",
    year: "2022",
    events: "4",
    medias: "4",
    status: "DISABLE",
  },
  {
    id: "5ea91ce034b8da3773fd76a7",
    year: "2023",
    events: "4",
    medias: "4",
    status: "DISABLE",
  },
  {
    id: "5ef48868de5ecb11d75723d7",
    year: "2024",
    events: "4",
    medias: "4",
    status: "DISABLE",
  },
];
