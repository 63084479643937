export const serverScriptData = [
    {
        id: 1,
        scriptname: "Social graph mismatch user",
        purpose: "Run this script to insert data in neo4j if due to some reason data is not inserted in db.",
        type: "SOCIAL_GRAPH_USER_UPDATE"
    },
    {
        id: 2,
        scriptname: "Elastic serach mismatch user",
        purpose: "Run this script to insert data in neo4j if due to some reason data is not inserted in db.",
        type: "ELASTIC_SEARCH_USER_UPDATE"
    },
];