export const mockData = [
    {
        id: 1,
        particular: "Posts-Audio",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Derek Anotony",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 2,
        particular: "Posts-Video",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Corel Fincher",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 3,
        particular: "Posts-Audio",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Barbara Ramsey",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 4,
        particular: "Posts-Quote",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Joviel Arnos",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 5,
        particular: "Posts-Blogs",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Jamie Cobolt",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 6,
        particular: "Comment",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Emily Johnson",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 7,
        particular: "Posts-Audio",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Derek Anotony",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 8,
        particular: "Posts-Video",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Corel Fincher",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 9,
        particular: "Posts-Audio",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Barbara Ramsey",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 10,
        particular: "Posts-Quote",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Joviel Arnos",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 11,
        particular: "Posts-Blogs",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Jamie Cobolt",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 12,
        particular: "Comment",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Emily Johnson",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 13,
        particular: "Posts-Audio",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Derek Anotony",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 14,
        particular: "Posts-Video",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Corel Fincher",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 15,
        particular: "Posts-Audio",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Barbara Ramsey",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 16,
        particular: "Posts-Quote",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Joviel Arnos",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 17,
        particular: "Posts-Blogs",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Jamie Cobolt",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
    {
        id: 18,
        particular: "Comment",
        createdOn: "08 Feb 2020",
        lastUpdated: "05 Apr 2022",
        periodApplicable: " ",
        createdBy: "Emily Johnson",
        scheduledDays: "7 Days",
        scheduleTime: "4:30pm",
        deleted: "",
    },
];