import React from "react";
import { Link } from "react-router-dom";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";

export const tableTrendingContentConstants = (fetchAllTrendingList, handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Id",
      cell: (row) => {
        return (
          <Link to={`/pre-login/trending-content/edit/${row.id}`}>
            <span className="sub">{row.id}</span>
          </Link>
        
        )
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 7,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"TRENDING_CONTENT"}
            action={fetchAllTrendingList}
            fullPermission={"Pre_Login_Trending_Content_FullAccess"}
            approvalPermission={"Pre_Login_Trending_Content_Approval"}
            publishPermission={"Pre_Login_Trending_Content_Publish"}
            enablePermission={"Pre_Login_Trending_Content_Enable"}
            disablePermission={"Pre_Login_Trending_Content_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 9,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (row.status === 'ENABLE' ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    }
  ];
};
