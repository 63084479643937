// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".create_category_forumcreatwrap__2SRrp {\n  max-width: 885px;\n  margin: 30px auto 100px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/explore/categories/CreateCategory/create_category.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".forumcreatwrap {\n  max-width: 885px;\n  margin: 30px auto 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forumcreatwrap": "create_category_forumcreatwrap__2SRrp"
};
export default ___CSS_LOADER_EXPORT___;
