import StatusDetails from "../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../utils/common";

export const tableBadgeHistoryConstants = (tabType, pageType) => {
    return pageType === "BADGE_HISTORY" ? [
        ...(tabType === "RANK" ?
            [
                {
                    id: 1,
                    name: "Date",
                    selector: (row) => row.date,
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 2,
                    name: "Rank",
                    selector: (row) => row.rank,
                    sortable: true,
                    reorder: true,
                }
            ] : []
        ),
        ...(tabType !== "RANK" ?
            [
                {
                    id: 1,
                    name: "Description",
                    selector: (row) => row.description,
                    sortable: true,
                    reorder: true,
                }
            ] : []
        ),
        {
            id: 3,
            name: "Point",
            selector: (row) => row.point,
            sortable: true,
            reorder: true,
        },
        {
            id: 4,
            name: "Create Time",
            selector: (row) => epochTogmt(row.createTime),
            sortable: true,
            reorder: true,
        },
        ...(tabType !== "RANK" ?
            [
                {
                    id: 5,
                    name: "Status",
                    cell: (row) => {
                        return (
                            <StatusDetails status={row.status} />
                        );
                    },
                    sortable: true,
                    reorder: true,
                }
            ] : []
        ),
    ] : 
    [
        {
            id: 1,
            name: "Date",
            selector: (row) => epochTogmt(row.createTime),
            sortable: true,
            reorder: true,
        },
        {
            id: 2,
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "User Type",
            selector: (row) => row.usesType === "POINT_ISSUED" ? "Point Issued" : row.usesType === "POINT_EXPIRED" ? "Point Expired" : "Point Redeem",
            sortable: true,
            reorder: true,
        },
        {
            id: 4,
            name: "Point",
            selector: (row) => row.points,
            sortable: true,
            reorder: true,
        },
    ];
};
