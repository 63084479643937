import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import { epochTogmt, trimDescription } from "../../../../utils/common";
// This is the table constant/settings which needed to render table elements
export const tableConstants = (fetchAllUsersList, handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Name",
      cell: (row) => {
        return (
          <Link
            to={`/admin-panel/user/edit/${row.id}`}
            style={{ color: "#154A59" }}
          >
            <span className="sub">
              {row.firstName} {row.lastName}
            </span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Phone no",
      selector: (row) => row.mobile,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Department",
      selector: (row) => row.department,
      cell: (row) => {
        let output = [];
        let count = 0;
        row.department &&
          row.department.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Role",
      cell: (row) => {
        let output = [];
        let count = 0;
        row.role &&
          row.role.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Created By",
      selector: (row) => row.createdBy,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Created On",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"user"}
            action={fetchAllUsersList}
            fullPermission={"AP_User_FullAccess"}
            approvalPermission={"AP_User_Approval"}
            publishPermission={"AP_User_Publish"}
            enablePermission={"AP_User_Enable"}
            disablePermission={"AP_User_Disbale"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (row.status === 'ENABLE' ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row?.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    }
  ];
};
