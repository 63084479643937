import React, { Component } from "react";
import styles from "../../PreLogin/BelongHere/pre-login.module.css"
import styled from "styled-components";
import Button from "../../../../components/Buttons/button"
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormInput from "../../../../components/InputFields/form_input";
import axios from "axios";
import history from "../../../../utils/history";
import { auth_service } from "../../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class CategoryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            errors: {},
            error: false,
            editData: {}
        };
    }

    // validate create amount
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.category) {
            formIsValid = false;
            errors["category"] = "Please enter category";
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);
        }
        return formIsValid;
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    }

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                title: this.state.category,
                status: this.props.formType === "CREATE" ? "DRAFT" : this.state.editData.status
            };
            if(this.props.formType === "UPDATE") {
                postData.id = this.props.id
            }
            axios({
                method: this.props.formType === "UPDATE" ? "PUT" : "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/${this.props?.loginType === "POST_LOGIN" ? "post":"pre"}/login/${this.props.formType === "UPDATE" ? "update-belong-category" : "create-belong-category"}`,
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }
    }


    fetchCategoryData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/post/login/get-belong-category?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                this.setState({
                    category: result.title,
                    editData: result
                })
            }
        }).catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm()
                }
            }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
        });
    }

    componentDidMount() {
        if(this.props.formType === "UPDATE") {
            this.fetchCategoryData();
        }
    }

    render() {
        const { category, errors } = this.state;
        const { loginType } = this.props;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-12">
                        <FormInput
                            group={"large"}
                            value={category}
                            label={"Category"}
                            onChange={this.handleChange}
                            name={"category"}
                            error={errors["title"]}
                        />
                    </div>
                </div>

                {auth_service.getPermission(this.props.formType === "CREATE" ? "Post_Login_Category_Create" : "Post_Login_Category_Update", "Post_Login_Category_FullAccess") && 
                    <ButtonWrap className={`${styles.bottombutt}`}>
                        <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                    </ButtonWrap>
                }
            </div>
        );
    }
}

export default CategoryForm;