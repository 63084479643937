import axios from "axios";
import React, { Component } from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import styled from "styled-components";
import { auth_service } from "../../auth/auth_service";
import Button from "../../components/Buttons/button";
import FormInput from "../../components/InputFields/form_input";
import TextArea from "../../components/TextArea/text_area";
import history from "../../utils/history";
import styles from "./marketing.module.css";
import style from "../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatString, notificationType, scheduleType, sendCritariaType, weekDayArray } from "./Data/CommonFunction";
import DatePicker from "react-datepicker";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import MagicUrl from "quill-magic-url";
import {WithContext as ReactTags} from "react-tag-input";
import DateTimePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { Link } from "react-router-dom";


const ErorrMsgPopUp = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

let AlignStyle = Quill.import('attributors/style/align')
let BackgroundStyle = Quill.import('attributors/style/background')
let ColorStyle = Quill.import('attributors/style/color')
let DirectionStyle = Quill.import('attributors/style/direction')
let FontStyle = Quill.import('attributors/style/font')
let SizeStyle = Quill.import('attributors/style/size')    

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);

class CommonNotificationEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            subject: "",
            title: "",
            link: "",
            description: "",
            content: "",
            sendCriteriaValue: "",
            scheduleValue: "",
            country: [],
            personaCountry: [],
            criteriaStartDate: "",
            criteriaEndDate: "",
            scheduleOnceStartDate: "",
            scheduleMonthStartDate: "",
            postActivityStartDate: "",
            postActivityEndDate: "",
            projectActivityStartDate: "",
            projectActivityEndDate: "",
            personaNameList: [],
            typeName: "",
            profileUpdationValue: "",
            postCountValue: "",
            projectCountValue: "",
            dayValue: "",
            personaList: [],
            countryList: [],
            particularsValue: [],
            testingEmail: [],
            lastLoginDate: "",
            errors: {},
            editData: {},
            excludeUserList: [],
            isSubmitDisabled: false
        };
    }

    modules = {
        toolbar: [
            [{ header: "2" }, { header: "3" }, { header: "4" }, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
        ],
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            handleStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        // imageCompress: {
        //     quality: 1, // default
        //     maxWidth: 750, // default
        //     maxHeight: 500, // default
        //     imageType: 'image/jpeg' + 'image/png', // default
        // },
        magicUrl: {
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
            globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        },
    };

    onHandleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeRadioValue(value, name) {
        this.setState({
            [name]: value
        })
    }

    onChangeSchedule(value) {
        this.setState({
            scheduleValue: value
        })
    }

    handleDateChange = (date, type) => {
        if (date) {
            date = date.getTime();
        }
        this.setState({
            [type]: date
        })
    };

    handleDateTimeChange = (date, type) => {
        if (date) {
            date = date._d.getTime();
        }
        this.setState({
            [type]: date
        })
    }

    fetchPersonaList = () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/notifier/persona/list`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                let data = response.data.body;
                let personaList = [];
                data && data.map((item) => {
                    personaList.push({ value: item, label: item });
                })
                this.setState({
                    personaList: response.data.body &&
                        response.data.body.map((data) => ({
                            value: data.persona,
                            label: data.persona + " " + data.count,
                        }))
                });
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    selectDateSection = (type, startDate, endDate) => {
        let startDateName = type === "POST_SECTION" ? "postActivityStartDate" : type === "PROJECT_SECTION" ? "projectActivityStartDate" : type === "ONCE_SECTION" ? "scheduleOnceStartDate" : type === "MONTH_SECTION" ? "scheduleMonthStartDate" : "lastLoginDate";
        let endDateName = type === "POST_SECTION" ? "postActivityEndDate" : type === "PROJECT_SECTION" ? "projectActivityEndDate" : "";
        return (
            <div class="form-group">
                <label for="">Select Date</label>
                <div class="row">
                    <div class="col-md-4">
                        <div className="d-flex">
                            <DatePicker
                                className={"form-control custom-input-date"}
                                dateFormat={"dd-MMM-yyyy"}
                                //minDate={new Date()}
                                openToDate={new Date()}
                                dropdownMode="select"
                                placeholderText={type === "LAST_LOGIN_DATE_SECTION" ? "Select Date" : "Start Date"}
                                selected={startDate}
                                onChange={date => {
                                    this.handleDateChange(date, startDateName)
                                }}
                            />
                        </div>
                        {this.state.errors[startDateName] && (<small className={style.danger}>{this.state.errors[startDateName]}</small>)}
                    </div>
                    {(/* type !== "ONCE_SECTION" && type !== "MONTH_SECTION" &&  */type !== "LAST_LOGIN_DATE_SECTION") && <div class="col-md-4">
                        <div className="d-flex">
                            <DatePicker
                                className={"form-control custom-input-date"}
                                dateFormat={"dd-MMM-yyyy"}
                                //minDate={new Date()}
                                openToDate={new Date()}
                                dropdownMode="select"
                                placeholderText="End Date"
                                selected={endDate}
                                onChange={date => {
                                    this.handleDateChange(date, endDateName)
                                }}
                            />
                        </div>
                        {this.state.errors[endDateName] && (<small className={style.danger}>{this.state.errors[endDateName]}</small>)}
                    </div>}
                </div>
            </div>
        )
    }

    selectDateTimeSection = (type, selectDateTime) => {
        let selectDateTimeName = type === "ONCE_SECTION" ? "scheduleOnceStartDate" : "scheduleMonthStartDate";
        return (
            <div class="form-group">
                <label for="">Select Date Time</label>
                <div class="row">
                    <div class="col-md-6">
                        <div className="">
                            <DateTimePicker 
                                className={""}
                                openToDate={new Date()}
                                dropdownMode="select"
                                closeOnSelect={false}
                                dateFormat={"DD-MMM-YYYY"}
                                //updateOnView="time"
                                value={selectDateTime}
                                inputProps={{placeholder: 'Select Date Time', disabled: false}}
                                onChange={date => {
                                    this.handleDateTimeChange(date, selectDateTimeName)
                                }}
                            />
                        </div>
                        {this.state.errors[selectDateTimeName] && (<small className={style.danger}>{this.state.errors[selectDateTimeName]}</small>)}
                    </div>
                </div>
            </div>
        )
    }

    notificationTypeChange = (value) => {
        this.setState({
            typeName: value
        })
    }

    personaNameChange = (value) => {
        let personaNameList = [];
        value && value.map((item) => {
            personaNameList.push({ value: item.value, label: item.label.replace(/[0-9]/g, '') })
        })
        this.setState({ personaNameList })
    }

    onHandleValueChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value.trimStart(),
            });
        }
    }

    contentChange = (value) => {
        this.setState({
            content: value
        })
    }

    getCountry = (data) => {
        this.setState({
            [this.state.sendCriteriaValue === "PERSONA" ? "personaCountry" : "country"]: data
        })
    }

    handleChange = (value) => {
        this.setState({
            particularsValue: value
        })
    }

    handleAdditionEmail = (email) => {
        this.isEmailValid(email);
    }

    handleDeleteEmail = (index) => {
        let testingEmailList = this.state.testingEmail;
        testingEmailList.splice(index, 1);
        this.setState({
            testingEmail: testingEmailList
        })
    }

    isEmailValid = (email) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(email)) {
            return null;
        } else {
            this.setState({ 
                testingEmail: [...this.state.testingEmail, {id: email, text: email}] 
            })
        }
    }

    // validate create user
    validate = (type) => {
        let errors = {};
        let formIsValid = true;
        if(type === "SUBMIT") {
            if (this.props.pageType === "NOTIFICATION" && !this.state.typeName) {
                formIsValid = false;
                errors["typeName"] = "Please enter type";
            }
            if (this.props.pageType === "NOTIFICATION" && !this.state.description) {
                formIsValid = false;
                errors["description"] = "Please enter description";
            }
            if (this.state.description && this.state.description.match(/[a-z]/g).length > 250) {
                formIsValid = false;
                errors["description"] = "Description should be within 250 characters";
            }
            if (this.props.pageType === "EMAIL" && !this.state.subject) {
                formIsValid = false;
                errors["subject"] = "Please enter subject";
            }
            if (this.props.pageType === "EMAIL" && (!this.state.content || this.state.content.trim() === "" || this.state.content === "<p><br></p>")) {
                formIsValid = false;
                errors["content"] = "Please enter content";
            }
            if (this.state.link) {
                let valudateLink = this.state.link;
                let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (urlValid == null) {
                    formIsValid = false;
                    errors["link"] = "Please enter a valid URL";
                }
            }
            if (!this.state.sendCriteriaValue) {
                formIsValid = false;
                errors["sendCriteriaValue"] = "Please select send criteria";
            }
            if (!this.state.scheduleValue) {
                formIsValid = false;
                errors["scheduleValue"] = "Please select schedule";
            }
            if (this.state.sendCriteriaValue === "PERSONA" && this.state.personaNameList.length === 0) {
                formIsValid = false;
                errors["personaNameList"] = "Please enter persona name";
            }
            if (this.state.sendCriteriaValue === "PROFILE_UPDATE" && !this.state.profileUpdationValue) {
                formIsValid = false;
                errors["profileUpdationValue"] = "Please enter post updation value";
            }
            if (this.state.sendCriteriaValue === "POST_ACTIVITY" && (!this.state.postCountValue || !this.state.postActivityStartDate || !this.state.postActivityEndDate)) {
                formIsValid = false;
                if (!this.state.postCountValue) {
                    errors["postCountValue"] = "Please enter post activity count";
                }
                if (!this.state.postActivityStartDate) {
                    errors["postActivityStartDate"] = "Please enter start date";
                }
                if (!this.state.postActivityEndDate) {
                    errors["postActivityEndDate"] = "Please enter end date";
                }
            }
            if (this.state.sendCriteriaValue === "PROJECT_ACTIVITY" && (!this.state.projectCountValue || !this.state.projectActivityStartDate || !this.state.projectActivityEndDate)) {
                formIsValid = false;
                if (!this.state.projectCountValue) {
                    errors["projectCountValue"] = "Please enter project activity count";
                }
                if (!this.state.projectActivityStartDate) {
                    errors["projectActivityStartDate"] = "Please enter start date";
                }
                if (!this.state.projectActivityEndDate) {
                    errors["projectActivityEndDate"] = "Please enter end date";
                }
            }
            if (this.state.sendCriteriaValue === "LOCATION" && this.state.country.length === 0) {
                formIsValid = false;
                errors["country"] = "Please enter location";
            }
            if (this.state.sendCriteriaValue === "PARTICULARS" && this.state.particularsValue.length === 0) {
                formIsValid = false;
                errors["particulars"] = "Please enter particulars";
            }
            if (this.state.scheduleValue === "ONCE" && !this.state.scheduleOnceStartDate) {
                formIsValid = false;
                errors["scheduleOnceStartDate"] = "Please enter start date";
            }
            if (this.state.scheduleValue === "MONTHLY" && !this.state.scheduleMonthStartDate) {
                formIsValid = false;
                errors["scheduleMonthStartDate"] = "Please enter start date";
            }
            if (this.state.scheduleValue === "WEEKLY" && !this.state.dayValue) {
                formIsValid = false;
                errors["dayValue"] = "Please select day";
            }
            if (this.state.scheduleValue === "LAST_LOGIN_DATE" && !this.state.lastLoginDate) {
                formIsValid = false;
                errors["lastLoginDate"] = "Please select date";
            }
        } else {
            if (this.state.testingEmail.length === 0) {
                formIsValid = false;
                errors["testingEmail"] = "Please enter email";
            }
        }
        
        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    onSubmit = () => {
        if (this.validate("SUBMIT")) {
            this.setState({
                isSubmitDisabled: true
            })
            let postData = {
                notificationType: this.props.pageType === "EMAIL" ? "EMAIL" : "DASHBOARD",
                content: this.props.pageType === "EMAIL" ? this.state.content : this.state.description,
                scheduled: this.state.scheduleValue ? true : false,
                notifierCriteriaType: this.state.sendCriteriaValue
            }
            if (this.props.formType === "EDIT") {
                postData.id = this.state.editData.id
            }
            if (this.props.pageType === "EMAIL") {
                postData.subject = this.state.subject;
            }
            if (this.props.pageType === "NOTIFICATION") {
                postData.category = this.state.typeName.value;
                postData.link = this.state.link;
            }
            if (this.state.sendCriteriaValue === "PERSONA") {
                postData.country = this.state.personaCountry && this.state.personaCountry.map((country) => (country.value));
                postData.personas = this.state.personaNameList && this.state.personaNameList.map((persona) => (persona.value));
            }
            if (this.state.sendCriteriaValue === "PROFILE_UPDATE") {
                postData.profileUpdationvalue = this.state.profileUpdationValue;
            }
            if (this.state.sendCriteriaValue === "POST_ACTIVITY") {
                postData.postActivityCount = this.state.postCountValue;
                postData.activityStartTime = this.state.postActivityStartDate;
                postData.activityEndTime = this.state.postActivityEndDate;
            }
            if (this.state.sendCriteriaValue === "PROJECT_ACTIVITY") {
                postData.projectActivityCount = this.state.projectCountValue;
                postData.activityStartTime = this.state.projectActivityStartDate;
                postData.activityEndTime = this.state.projectActivityEndDate;
            }
            if (this.state.sendCriteriaValue === "LOCATION") {
                postData.country = this.state.country && this.state.country.map((country) => (country.value));
            }
            if (this.state.sendCriteriaValue === "PARTICULARS") {
                postData.userIds = this.state.particularsValue && this.state.particularsValue.map((particulars) => (particulars.value));
            }
            if (this.state.sendCriteriaValue === "LAST_LOGIN_DATE") {
                postData.lastLoginDate = this.state.lastLoginDate;
            }
            if (this.state.scheduleValue) {
                let scheduleData = {
                    type: this.state.scheduleValue
                }
                if (this.state.scheduleValue === "ONCE" || this.state.scheduleValue === "MONTHLY") {
                    scheduleData.startTime = this.state.scheduleValue === "ONCE" ? this.state.scheduleOnceStartDate : this.state.scheduleMonthStartDate
                }
                if (this.state.scheduleValue === "WEEKLY") {
                    scheduleData.weekday = this.state.dayValue
                }
                postData.schedule = scheduleData;
            }
            postData.excludeUserList = this.state.excludeUserList && this.state.excludeUserList.map((user) => (user.value));
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/notifier/${this.props.formType === "EDIT" ? "edit" : "create"}`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            })
                .then((response) => {
                    if (response && response.data && response.data.message === "Success!") {
                        this.setState({
                            isSubmitDisabled: false
                        })
                        history.goBack();
                    }
                })
                .catch((error) => {
                    this.setState({
                        isSubmitDisabled: false
                    })
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 3000);
        }
    }

    onSendEmail = () => {
        if (this.validate("SEND_EMAIL")) {
            this.setState({
                isSubmitDisabled: true
            })
            let postData = {
                id: this.state.editData.id,
                emails: this.state.testingEmail && this.state.testingEmail.map((email) => (email.id))
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/notifier/send/test`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{(this.props.pageType === "EMAIL" ? "Email" : "Notification") + " send sucessfully."}</p>)
                    })
                    this.setState({
                        isSubmitDisabled: false
                    })
                }
            })
            .catch((error) => {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
                this.setState({
                    isSubmitDisabled: false
                })
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 3000);
        }
    }

    fetchFormDataById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/notifier/get?id=${this.props.itemId}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.body &&
                    response.data.status === "200 OK"
                ) {
                    let data = response.data.body;
                    if (data.notifierCriteriaType === "PERSONA") {
                        this.setState({
                            personaNameList: data.personas && data.personas.map((persona) => ({
                                value: persona,
                                label: persona,
                            })),
                            personaCountry: data.country && data.country.map((country) => ({
                                value: country,
                                label: country,
                            }))
                        })
                    } else if (data.notifierCriteriaType === "PROFILE_UPDATE") {
                        this.setState({
                            profileUpdationValue: data.profileUpdationvalue
                        })
                    } else if (data.notifierCriteriaType === "POST_ACTIVITY") {
                        this.setState({
                            postCountValue: data.postActivityCount,
                            postActivityStartDate: data.activityStartTime,
                            postActivityEndDate: data.activityEndTime
                        })
                    } else if (data.notifierCriteriaType === "PROJECT_ACTIVITY") {
                        this.setState({
                            projectCountValue: data.projectActivityCount,
                            projectActivityStartDate: data.activityStartTime,
                            projectActivityEndDate: data.activityEndTime
                        })
                    } else if (data.notifierCriteriaType === "PARTICULARS") {
                        this.setState({
                            particularsValue: data.userList && data.userList.map((user) => ({
                                value: user.id,
                                label: user.username
                            }))
                        })
                    } else if(data.notifierCriteriaType === "LAST_LOGIN_DATE") {
                        this.setState({
                            lastLoginDate: data.lastLoginDate
                        })
                    } else {
                        this.setState({
                            country: data.country && data.country.map((country) => ({
                                value: country,
                                label: country,
                            }))
                        })
                    }
                    this.setState({
                        editData: response.data.body,
                        typeName: { value: data.category, label: formatString(data.category) },
                        [this.props.pageType === "NOTIFICATION" ? "description" : "content"]: data.content,
                        subject: data.subject,
                        scheduleValue: data.schedule && data.schedule.type,
                        [data.schedule.type === "ONCE" ? "scheduleOnceStartDate" : data.schedule.type === "MONTHLY" && "scheduleMonthStartDate"]: data.schedule && data.schedule.startTime,
                        [data.schedule.type === "WEEKLY" && "dayValue"]: data.schedule && data.schedule.weekday,
                        sendCriteriaValue: data.notifierCriteriaType,
                        link: data.link,
                        excludeUserList: data.excludeUserList && data.excludeUserList.map((user) => ({
                            value: user.id,
                            label: user.username
                        }))
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    fetchCountryList = () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_userServiceURL}/backend/region/countries`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        countryList:
                            response.data.body &&
                            response.data.body.map((data) => ({
                                value: data,
                                label: data,
                            }))
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    async fetchParticulars (search, loadedOptions, { page }) {
        const response = await fetch(`${process.env.REACT_APP_userServiceURL}/search/admin/getUsers?&keyWord=${search}&page=${page}&size=20`);
        const responseJSON = await response.json();
        return {
          options: responseJSON.body && responseJSON.body.content && responseJSON.body.content.map((item) => ({label: item.username, value: item.id})),
          hasMore: !responseJSON.body.page?.last,
          additional: {
            page: page + 1,
          },
        };
    }

    handleExcludeUserChange = (value) => {
        this.setState({
            excludeUserList: value
        })
    }

    componentDidMount() {
        this.fetchCountryList();
        this.fetchPersonaList();
        if (this.props.formType === "EDIT") {
            this.fetchFormDataById();
        }
    }

    handleOpenEmailNotificationUserListPopUp = (modalType) => {
        history.push({ pathname: `/user/list/${this.props.itemId}`, state: { modalType: modalType } });
    }

    render() {
        const { typeName, description, content, subject, scheduleOnceStartDate, scheduleMonthStartDate, postActivityStartDate, postActivityEndDate, projectActivityStartDate, projectActivityEndDate, personaList, personaNameList, sendCriteriaValue, scheduleValue, profileUpdationValue, projectCountValue, postCountValue, dayValue, errors, country, countryList, link, personaCountry, particularsValue, testingEmail, lastLoginDate, excludeUserList, isSubmitDisabled, emailNotificationUserFlag, modalType } = this.state;
        const { pageType, disabledUpdate } = this.props;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                {pageType === "NOTIFICATION" ?
                    <>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Type <span className="text-danger">*</span></label>
                                    <Select
                                        filterOption={(option) => option.value !== "ALL"}
                                        options={notificationType}
                                        placeholder={"Select Type"}
                                        value={typeName}
                                        onChange={this.notificationTypeChange}
                                        styles={{
                                            option: (
                                                styles,
                                                { data, isDisabled, isFocused, isSelected }
                                            ) => {
                                                const color = "#cfe7c7";
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? null
                                                        : isSelected
                                                            ? "#cfe7c7"
                                                            : isFocused
                                                                ? "#cfe7c7"
                                                                : null,
                                                    color: isDisabled
                                                        ? "#ccc"
                                                        : isSelected
                                                            ? color > 2
                                                                ? "#00374d"
                                                                : "#00374d"
                                                            : data.color,
                                                    cursor: isDisabled ? "not-allowed" : "default",

                                                    ":active": {
                                                        ...styles[":active"],
                                                        backgroundColor:
                                                            !isDisabled &&
                                                            (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                    {errors["typeName"] && (<small className={style.danger}>{errors["typeName"]}</small>)}
                                </div>
                            </div>
                        </div>
                        <FormInput
                            group={"large"}
                            value={link}
                            label={"Link"}
                            onChange={this.onHandleChange}
                            name={"link"}
                            className="not-required"
                            error={errors["link"]} />
                        <TextArea
                            label={"Description"}
                            value={description}
                            onChange={this.onHandleChange}
                            name={"description"}
                            error={errors["description"]}
                        />
                    </> :
                    <>
                        <FormInput
                            group={"large"}
                            value={subject}
                            label={"Subject"}
                            onChange={this.onHandleChange}
                            name={"subject"}
                            error={errors["subject"]} />
                        <div className={"form-group " + (errors["content"] ? 'error' : '')}>
                            <label>Add Content <span className="text-danger">*</span></label>
                            <div className="reactQuillWrap">
                                <ReactQuill
                                    value={content}
                                    onChange={this.contentChange}
                                    modules={this.modules}
                                />
                            </div>
                            {errors["content"] && (<small className={style.danger}>{errors["content"]}</small>)}
                        </div>
                    </>
                }

                <div className={`form-group ${styles.sendCritaria}`}>
                    <label>Send Criteria</label>
                    <div className={`row ${styles.row}`}>
                        {sendCritariaType && sendCritariaType.map((item) => {
                            return (
                                <div className="">
                                    <div class="custom-control custom-checkbox btn" onClick={(e) => this.onChangeRadioValue(item.value, "sendCriteriaValue")}>
                                        <input type="radio" name="emailMode" className={sendCriteriaValue === item.value ? "checked" : ''} />
                                        <label class="radio-container">
                                            {item.name} <span class="radio-checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            )
                        })
                        }

                    </div>
                    {errors["sendCriteriaValue"] && (<small className={style.danger}>{errors["sendCriteriaValue"]}</small>)}
                </div>

                {sendCriteriaValue === "PERSONA" ?
                    <div class={`form-group ${styles.scheduleMailBox}`}>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="">Persona Name</label>
                                    <Select
                                        options={personaList}
                                        placeholder={"Name of Persona"}
                                        value={personaNameList}
                                        onChange={this.personaNameChange}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        styles={{
                                            option: (
                                                styles,
                                                { data, isDisabled, isFocused, isSelected }
                                            ) => {
                                                const color = "#cfe7c7";
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? null
                                                        : isSelected
                                                            ? "#cfe7c7"
                                                            : isFocused
                                                                ? "#cfe7c7"
                                                                : null,
                                                    color: isDisabled
                                                        ? "#ccc"
                                                        : isSelected
                                                            ? color > 2
                                                                ? "#00374d"
                                                                : "#00374d"
                                                            : data.color,
                                                    cursor: isDisabled ? "not-allowed" : "default",

                                                    ":active": {
                                                        ...styles[":active"],
                                                        backgroundColor:
                                                            !isDisabled &&
                                                            (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                    {errors["personaNameList"] && (<small className={style.danger}>{errors["personaNameList"]}</small>)}
                                </div>
                                <div className="col-md-6">
                                    <label>Location</label>
                                    <Select
                                        value={personaCountry}
                                        placeholder={"Select Country"}
                                        options={countryList}
                                        onChange={this.getCountry}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        styles={{
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                const color = "#cfe7c7";
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? null
                                                        : isSelected
                                                            ? "#cfe7c7"
                                                            : isFocused
                                                                ? "#cfe7c7"
                                                                : null,
                                                    color: isDisabled
                                                        ? "#ccc"
                                                        : isSelected
                                                            ? color > 2
                                                                ? "#00374d"
                                                                : "#00374d"
                                                            : data.color,
                                                    cursor: isDisabled ? "not-allowed" : "default",

                                                    ":active": {
                                                        ...styles[":active"],
                                                        backgroundColor:
                                                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* {this.selectDateSection(criteriaStartDate, criteriaEndDate, "CRITERIA_SECTION")}
                                <LocationInput country={this.country} notAvailableState={true} notAvailableCity={true} /> */}
                    </div> :
                    sendCriteriaValue === "PROFILE_UPDATE" ?
                        <div class={`form-group ${styles.scheduleMailBox}`}>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <FormInput
                                            group={"largoutsidee"}
                                            value={profileUpdationValue}
                                            label={"Profile Updation Value"}
                                            onChange={this.onHandleValueChange}
                                            name={"profileUpdationValue"}
                                            className={"not-required"}
                                            error={errors["profileUpdationValue"]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> :
                        sendCriteriaValue === "LOCATION" ?
                            <div class={`form-group ${styles.scheduleMailBox}`}>
                                <label>Location {<span className="text-danger">*</span>}</label>
                                <div className={`row ${styles.locationselect}`}>
                                    <div className={`form-group col-md-12 ${styles.selectlist}` + (this.state.errors["country"] ? ' error' : '')}>
                                        <Select
                                            value={country}
                                            placeholder={"Select Country"}
                                            options={countryList}
                                            onChange={this.getCountry}
                                            closeMenuOnSelect={false}
                                            isMulti
                                            styles={{
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                        {errors["country"] && (<small className={style.danger}>{errors["country"]}</small>)}
                                    </div>
                                </div>
                            </div> :
                            sendCriteriaValue === "POST_ACTIVITY" ?
                                <div class={`form-group ${styles.scheduleMailBox}`}>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <FormInput
                                                    group={"largoutsidee"}
                                                    value={postCountValue}
                                                    label={"Count"}
                                                    onChange={this.onHandleValueChange}
                                                    name={"postCountValue"}
                                                    className={"not-required"}
                                                    error={errors["postCountValue"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.selectDateSection("POST_SECTION", postActivityStartDate, postActivityEndDate)}
                                </div> :
                                sendCriteriaValue === "PROJECT_ACTIVITY" ? <div class={`form-group ${styles.scheduleMailBox}`}>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <FormInput
                                                    group={"largoutsidee"}
                                                    value={projectCountValue}
                                                    label={"Count"}
                                                    onChange={this.onHandleValueChange}
                                                    name={"projectCountValue"}
                                                    className={"not-required"}
                                                    error={errors["projectCountValue"]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.selectDateSection("PROJECT_SECTION", projectActivityStartDate, projectActivityEndDate)}
                                </div> : 
                                sendCriteriaValue === "PARTICULARS" ? 
                                    <div class={`form-group ${styles.scheduleMailBox}`}>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label>Individuals Name</label>
                                                    <AsyncPaginate
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        placeholder={"Select Name"}
                                                        value={particularsValue}
                                                        loadOptions={this.fetchParticulars}
                                                        onChange={this.handleChange}
                                                        additional={{
                                                            page: 0,
                                                        }}
                                                    />
                                                    {errors["particulars"] && (<small className={style.danger}>{errors["particulars"]}</small>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                sendCriteriaValue === "LAST_LOGIN_DATE" ? 
                                    <div class={`form-group ${styles.scheduleMailBox}`}>
                                        {this.selectDateSection("LAST_LOGIN_DATE_SECTION", lastLoginDate)}
                                    </div> :
                                sendCriteriaValue === "NOT_LOGGED_IN_AFTER_SIGNUP" && <></>
                }

                <div className={`form-group ${styles.sendCritaria}`}>
                    <label>Schedule</label>
                    <div className={`row ${styles.row}`}>
                        {scheduleType && scheduleType.map((item) => {
                            return (
                                <div className="">
                                    <div class="custom-control custom-checkbox btn" onClick={(e) => this.onChangeRadioValue(item.value, "scheduleValue")}>
                                        <input type="radio" name="emailMode" className={scheduleValue === item.value ? "checked" : ''} />
                                        <label class="radio-container">
                                            {item.name} <span class="radio-checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {errors["scheduleValue"] && (<small className={style.danger}>{errors["scheduleValue"]}</small>)}
                </div>

                {scheduleValue === "ONCE" ?
                    <div class={`form-group ${styles.scheduleMailBox}`}>
                        {this.selectDateTimeSection("ONCE_SECTION", scheduleOnceStartDate)}
                    </div> :
                    scheduleValue === "WEEKLY" ?
                        <div class={`form-group ${styles.scheduleMailBox}`}>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <div class="col-md-2"><label for="">Day of the week</label></div>
                                    <div class="col-md-10">
                                        <div class="row">
                                            {weekDayArray && weekDayArray.map((day) => {
                                                return (
                                                    <div class="col-md-3">
                                                        <div class="custom-control custom-checkbox">
                                                            <label class="radio-container" onClick={(e) => this.onChangeRadioValue(day.value, "dayValue")}>{day.name}
                                                                <input type="radio" name="radio" className={dayValue === day.value ? "checked" : ''} />
                                                                <span class="radio-checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {errors["dayValue"] && (<small className={style.danger}>{errors["dayValue"]}</small>)}
                                    </div>
                                </div>
                            </div>
                        </div> :
                        scheduleValue === "MONTHLY" &&
                        <div class={`form-group ${styles.scheduleMailBox}`}>
                            {this.selectDateTimeSection("MONTH_SECTION", scheduleMonthStartDate)}
                        </div>
                }

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Exclude</label>
                            <AsyncPaginate
                                isMulti
                                closeMenuOnSelect={false}
                                placeholder={"Select Name"}
                                value={excludeUserList}
                                loadOptions={this.fetchParticulars}
                                onChange={this.handleExcludeUserChange}
                                additional={{
                                    page: 0,
                                }}
                            />
                        </div>
                    </div>
                </div>


                {disabledUpdate && <div class={`form-group ${styles.scheduleMailBox}`}>
                    <div class="form-group">
                        <div class="row align-items-center">
                            <div class="col-md-12">
                                <label>Send Test {pageType === "NOTIFICATION" ? "Notification" : "Email"}</label>
                                <div className={`testing-email ${styles.tagfield}`}>
                                    <ReactTags tags={testingEmail}
                                        key={this.state.userData.userId}
                                        handleDelete={this.handleDeleteEmail}
                                        handleAddition={this.handleAdditionEmail}
                                        placeholder="Write here..." />
                                </div>

                                {errors["testingEmail"] && (<small className={style.danger}>{errors["testingEmail"]}</small>)}
                                {(auth_service.getPermission("Notification_Test_Send", "Notification_FullAccess") || auth_service.getPermission("Email_Test_Send", "Email_FullAccess")) && disabledUpdate &&
                                    <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`} style={{ marginTop: '10px' }}>
                                        <Button children={"Send"} disabledBtn={isSubmitDisabled} onClick={this.onSendEmail} />
                                    </ButtonWrap>
                                }
                            </div>
                        </div>
                    </div>
                </div>}

                {this.state.editData && this.state.editData.sendgridCount && disabledUpdate &&
                    <div class={`form-group ${styles.scheduleMailBox}`}>
                        <div class="form-group">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <div>
                                        <Link to={{pathname: `/user/list/${this.props.itemId}`, state: { modalType: "OPENED" }}} className={this.state.editData.sendgridCount?.opened > 0 ? "" : "disable-link"} style={{color: "black"}}><strong>Opened : </strong> {this.state.editData.sendgridCount?.opened}</Link>
                                    </div>
                                    <div>
                                        <Link to={{pathname: `/user/list/${this.props.itemId}`, state: { modalType: "DELIVERED" }}} className={this.state.editData.sendgridCount?.delivered > 0 ? "" : "disable-link"}  style={{color: "black"}}><strong>Delivered : </strong> {this.state.editData.sendgridCount?.delivered}</Link>
                                    </div>
                                   {pageType !== "EMAIL" && <div>
                                        {/* <Link to={{pathname: `/user/list/${this.props.itemId}`, state: { modalType: pageType === "EMAIL" ? "UNSUBSCRIBED" : "DELETED" }}} className={(pageType === "EMAIL" ? this.state.editData.sendgridCount?.unsubscribed : this.state.editData.sendgridCount?.deleted) > 0 ? "" : "disable-link"}  style={{color: "black"}}><strong>{pageType === "EMAIL" ? "Unsubscribed" : "Deleted"} : </strong> {pageType === "EMAIL" ? this.state.editData.sendgridCount?.unsubscribed : this.state.editData.sendgridCount?.deleted}</Link> */}
                                        <Link to={{pathname: `/user/list/${this.props.itemId}`, state: { modalType: "DELETED" }}} className={this.state.editData.sendgridCount?.deleted > 0 ? "" : "disable-link"}  style={{color: "black"}}><strong>{"Deleted"} : </strong> {this.state.editData.sendgridCount?.deleted}</Link>
                                    </div>}
                                </div>
                            </div>
                            {this.state.editData.sendgridCount.events &&  this.state.editData.sendgridCount.events.length > 0 &&
                                <div className="row mt-2">
                                    <div className="col-md-10">
                                        <strong>URL</strong>
                                        {this.state.editData.sendgridCount.events && this.state.editData.sendgridCount.events.map((data) => {
                                            return <label><a href={data.url} target="_blank">{data.url}</a></label>
                                        })}
                                    </div>
                                    <div className="col-md-2">
                                        <strong>Count</strong>
                                        {this.state.editData.sendgridCount.events && this.state.editData.sendgridCount.events.map((data) => {
                                            return <div>
                                                <Link to={{pathname: `/user/list/${this.props.itemId}`, state: { modalType: "CLICKED", url: data.url}}} className={data.click > 0 ? "" : "disable-link"} style={{color: "black"}}>{data.click}</Link>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

                {(auth_service.getPermission("Notification_Update", "Notification_FullAccess") || auth_service.getPermission("Email_Update", "Email_FullAccess")) && !disabledUpdate &&
                    <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`} style={{ marginTop: '150px' }}>
                        <Button children={"Submit"} disabledBtn={isSubmitDisabled} onClick={this.onSubmit} />
                    </ButtonWrap>
                }

            </div>
        );
    }
}

export default CommonNotificationEmail;