import { Component } from "react";
import { Router, Switch } from "react-router-dom";
import { auth_service } from "./auth/auth_service";
import CircleDetails from "./pages/account_details/circle/circle_details";
import IndividualDetails from "./pages/account_details/individual/individual_details";
import OrganizationDetails from "./pages/account_details/organization/organization_details";
import ProjectDetails from "./pages/account_details/project/project_details";
import OpratorDetails from "./pages/account_details/operator/operator_details";
import CausesList from "./pages/causes/causes_list";
import CreateCause from "./pages/causes/CreateCause/create_cause";
import EditCause from "./pages/causes/EditCause/edit_cause";
import Dashboard from "./pages/dashboard/dashboard";
import Blogs from "./pages/explore/blogs/blogs";
import CreateBlog from "./pages/explore/blogs/CreateBlog/create_blog";
import EditBlog from "./pages/explore/blogs/EditBlog/edit_blog";
import CreateCategory from "./pages/explore/categories/CreateCategory/create_category";
import EditCategory from "./pages/explore/categories/EditCategory/edit_category";
import ExploreCategories from "./pages/explore/categories/explore_categories";
import CreateFaq from "./pages/faq/CreateFaq/create_faq";
import EditFaq from "./pages/faq/EditFaq/edit_faq";
import Faq from "./pages/faq/faq";
import Login from "./pages/login/login";
import CreateMessage from "./pages/message/CreateMessage/create_message";
import EditMessage from "./pages/message/EditMessage/edit_message";
import Message from "./pages/message/message";
import CreateSpeaker from "./pages/pepole_of_nature/speaker/create_speaker/create_speaker";
import SpeakerList from "./pages/pepole_of_nature/speaker/speaker_list";
import UpdateSpeaker from "./pages/pepole_of_nature/speaker/update_speaker/update_speaker";
import CreateWinner from "./pages/pepole_of_nature/winner/create_winner/create_winner";
import UpdateWinner from "./pages/pepole_of_nature/winner/update_winner/update_winner";
import WinnerList from "./pages/pepole_of_nature/winner/winner_list";
import PromotionList from "./pages/promotion/promotion";
import CreatePromotion from "./pages/promotion/CreatePromotion/create_promotion";
import EditPromotion from "./pages/promotion/EditPromotion/edit_promotion";
import Circle from "./pages/reports/circle/circle";
import DeletePost from "./pages/reports/delete_post/delete_post";
import Post from "./pages/reports/post/post";
import FlagPost from "./pages/reports/flagpost/flag_post";
import User from "./pages/reports/user/user";
import GenerateReport from "./pages/generate_report/generate_report";
import CreateGenerateReport from "./pages/generate_report/create_generate_report";
import UsersList from "./pages/admin_panel/User/users_list";
import CreatUser from "./pages/admin_panel/User/CreateUser/create_user";
import EditUser from "./pages/admin_panel/User/EditUser/edit_user";
import RestrictedUser from "./pages/admin_panel/RestrictedUser/restricted_user";
import DepartmentList from "./pages/admin_panel/Department/deparments_list";
import CreateDepartment from "./pages/admin_panel/Department/CreateDepartment/create_department";
import EditDepartment from "./pages/admin_panel/Department/EditDepartment/edit_department";
import RoleList from "./pages/admin_panel/Role/roles_list";
import CreateRole from "./pages/admin_panel/Role/CreateRole/create_role";
import EditRole from "./pages/admin_panel/Role/EditRole/edit_role";
import PermissionList from "./pages/admin_panel/Permission/permission_list";
import CreateGroup from "./pages/admin_panel/Group/CreateGroup/create_group";
import EditGroup from "./pages/admin_panel/Group/EditGroup/edit_group";
import GroupList from "./pages/admin_panel/Group/group_list";
import ServerScript from "./pages/admin_panel/ServerScript/server_script";
import AmountAndTenure from "./pages/subscription/AmountAndTenure/amountTenure";
import CircleSubscription from "./pages/subscription/CircleSubscription/circle_subscription";
import OrganizationSubscription from "./pages/subscription/Organizations/organizations";
import ProjectSubscription from "./pages/subscription/ProjectSubscription/project_subscription";
import CreateAmountAndTenure from "./pages/subscription/AmountAndTenure/CreateAmountTenure/create_amount_tenure";
import EditAmountAndTenure from "./pages/subscription/AmountAndTenure/EditAmountTenure/edit_amount_tenure";
import CreateCircleSubscription from "./pages/subscription/CircleSubscription/CreateCircleSubscription/create_circle_subscription";
import EditCircleSubscription from "./pages/subscription/CircleSubscription/EditCircleSubscription/edit_circle_subscription";
import CreateProjectSubscription from "./pages/subscription/ProjectSubscription/CreateProjectSubscription/create_project_subscription";
import EditProjectSubscription from "./pages/subscription/ProjectSubscription/EditProjectSubscription/edit_project_subscription";
import ReceivedList from "./pages/payment/Received/received";
import RemittedList from "./pages/payment/Remitted/remitted";
import NotPermission from "./pages/admin_panel/Permission/NotPermission/not_permission";
import PostDetails from "./pages/reports/PostDetails/post_details";
import DeactivatedUserReport from "./pages/reports/user/deactivate_user";
import YearList from "./pages/wpond/Year/YearList/year_list";
import CreateYear from "./pages/wpond/Year/CreateYear/create_year";
import EditYear from "./pages/wpond/Year/EditYear/edit_year";
import EventList from "./pages/wpond/Year/YearList/Events/event_list";
import CreateEvent from "./pages/wpond/Year/YearList/Events/CreateEvent/create_event";
import EditEvent from "./pages/wpond/Year/YearList/Events/EditEvents/edit_events";
import MediaList from "./pages/wpond/Year/YearList/Medias/media_list";
import CreateMedia from "./pages/wpond/Year/YearList/Medias/CreateMedia/create_media";
import EditMedia from "./pages/wpond/Year/YearList/Medias/EditMedia/edit_media";
import UpcomingYearList from "./pages/wpond/UpcomingYear/UpcomingYearList/upcoming_year_list";
import CreateUpcomingYear from "./pages/wpond/UpcomingYear/CreateUpcomingYear/create_upcoming_year";
import EditUpcomingYear from "./pages/wpond/UpcomingYear/EditUpcomingYear/edit_upcoming_year";
import AwardCategoriesList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/award_categories_list";
import CreateAwardCategories from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/CreateAwardCategories/create_award_categories";
import EditAwardCategories from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/EditAwardCategories/edit_award_categories";
import JuriesList from "./pages/wpond/UpcomingYear/UpcomingYearList/Juries/juries_list";
import SponsorsList from "./pages/wpond/UpcomingYear/UpcomingYearList/Sponsors/sponsors_list";
import PartnersList from "./pages/wpond/UpcomingYear/UpcomingYearList/Partners/partners_list";
import UpcomingEventsList from "./pages/wpond/UpcomingYear/UpcomingYearList/UpcomingEvent/UpcomingEventList/upcoming_event_list";
import PrivateRoute from "./routes/private_route";
import PublicRoute from "./routes/public_route";
import history from "./utils/history";
import { createPortal } from "react-dom";
import CreateJuries from "./pages/wpond/UpcomingYear/UpcomingYearList/Juries/CreateJuries/create_juries";
import EditJuries from "./pages/wpond/UpcomingYear/UpcomingYearList/Juries/EditJuries/edit_juries";
import CreateSponsors from "./pages/wpond/UpcomingYear/UpcomingYearList/Sponsors/CreateSponsors/create_sponsors";
import EditSponsors from "./pages/wpond/UpcomingYear/UpcomingYearList/Sponsors/EditSponsors/edit_sponsors";
import CreatePartners from "./pages/wpond/UpcomingYear/UpcomingYearList/Partners/CreatePartners/create_partners";
import EditPartners from "./pages/wpond/UpcomingYear/UpcomingYearList/Partners/EditPartners/edit_partners";
import CreateUpcomingEvent from "./pages/wpond/UpcomingYear/UpcomingYearList/UpcomingEvent/CreateUpcomingEvent/create_upcoming_event";
import EditUpcomingEvent from "./pages/wpond/UpcomingYear/UpcomingYearList/UpcomingEvent/EditUpcomingEvent/edit_upcoming_event";
import EventSpeakerList from "./pages/wpond/UpcomingYear/UpcomingYearList/UpcomingEvent/UpcomingEventList/EventSpeaker/event_speaker_list";
import CreateEventSpeaker from "./pages/wpond/UpcomingYear/UpcomingYearList/UpcomingEvent/UpcomingEventList/EventSpeaker/CreateEventSpeaker/create_event_speaker";
import EditEventSpeaker from "./pages/wpond/UpcomingYear/UpcomingYearList/UpcomingEvent/UpcomingEventList/EventSpeaker/EditEventSpeaker/edit_event_speaker";
import NominationList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/nomination_list";
import CreateNomination from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/CreateNomination/create_nomination";
import EditNomination from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/EditNomination/edit_nomination";
import LeaderBoard from "./pages/wpond/UpcomingYear/UpcomingYearList/LeaderBoard/leader_board";
import { icons } from "./components/EditorHeaderIcon/editor_header_icon";
import HowWeImpactCategoryList from "./pages/how_we_impact/how_we_impact_category_list";
import CreateHowWeImpactCategory from "./pages/how_we_impact/CreateHowWeImpactCategory/create_how_we_impact_category";
import EditHowWeImpactCategory from "./pages/how_we_impact/EditHowWeImpactCategory/edit_how_we_impact_category";
import StudioSummary from "./pages/studio/List/studio_summary";
import VoterList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/Voters/voter_list";
import ExcludeUserList from "./pages/subscription/ExcludeUserList/exclude_user_list";
import FEAdminList from "./pages/admin_panel/FEAdmin/fe_admin_list";
import OthersSubscription from "./pages/subscription/OtherSubscription/othersSubscription";
import CreateOthersSubscription from "./pages/subscription/OtherSubscription/CreateOthersSubscription/create_others_subscription";
import EditOthersSubscription from "./pages/subscription/OtherSubscription/EditOthersSubscription/edit_others_subscription";
import DraftUser from "./pages/account_details/draft/draft_user";
import Studio from "./pages/reports/studio/studio";
import StudioExcludeUserList from "./pages/studio/ExcludeUserList/exclude_user_list";
import StudioViewClickList from "./pages/studio/List/studio_view_click_list";
import ExcludeUserFlagPost from "./pages/admin_panel/ExcludeUserFlagPost/exclude_user_flag_post";
import ExcludeGlobalUser from "./pages/admin_panel/ExcludeGlobalUser/exclude_global_user";
import Testimonials from "./pages/testimonials/testimonials";
import CreateTestimonial from "./pages/testimonials/CreateTestimonials/create_testimonials";
import EditTestimonial from "./pages/testimonials/EditTestimonials/edit_testimonials";
import NotificationPage from "./pages/marketing/notificationPage";
import CreateNotification from "./pages/marketing/create_notification";
import EmailPage from "./pages/marketing/emailPage";
import CreateEmail from "./pages/marketing/create_email";
import EditNotification from "./pages/marketing/edit_notification";
import EditEmail from "./pages/marketing/edit_email";
import NotifierViewList from "./pages/marketing/notifierViewList";
import Forum from "./pages/reports/delete_post/forum/forum";
import Poll from "./pages/reports/delete_post/poll/poll";
import PostNew from "./pages/reports/delete_post/post/post";
import StudioNew from "./pages/reports/delete_post/studio/studio";
import TrialSubscription from "./pages/subscription/TrialSubscription/trialSubscription";
import TrialSubscriptionDetails from "./pages/subscription/TrialSubscription/trialSubscriptionDetails";
import SpamUser from "./pages/reports/user/spam_user";
import ProjectPointsList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/ProjectPoints/project_points_list";
import ProjectActivityPointsList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/ProjectPoints/project_activity_points_list";
import ProjectParticipantsPointsList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/ProjectPoints/project_participants_points_list";
import ActivityPointsList from "./pages/wpond/UpcomingYear/UpcomingYearList/AwardCategories/AwardCategoriesList/Nominations/ActivityPoints/activity_points_list";
import IndividualGenerateCode from "./pages/account_details/individual/individual_generate_code";
import BelongHereList from "./pages/landing_page/PreLogin/BelongHere/BelongHereList";
import CreateBelong from "./pages/landing_page/PreLogin/BelongHere/Create/CreateBelong";
import EditBelong from "./pages/landing_page/PreLogin/BelongHere/Edit/EditBelong";
import InspiringList from "./pages/landing_page/PreLogin/InspiringStories/InspiringList";
import CreateInspiring from "./pages/landing_page/PreLogin/InspiringStories/Create/CreateInspiring";
import EditInspiring from "./pages/landing_page/PreLogin/InspiringStories/Edit/EditInspiring";
import CreateProjectManagement from "./pages/landing_page/PreLogin/ProjectManageMent/Create/CreateProjectManagement";
import EditProjectManagement from "./pages/landing_page/PreLogin/ProjectManageMent/Edit/EditProjectManagement";
import TrendingContentList from "./pages/landing_page/PreLogin/TrendingContent/TrendingContentList";
import CreateTrendingConent from "./pages/landing_page/PreLogin/TrendingContent/Create/CreateTrendingConent";
import EditTrendingConent from "./pages/landing_page/PreLogin/TrendingContent/Edit/EditTrendingConent";
import CreateFundRaise from "./pages/landing_page/PreLogin/FundRaise/Create/CreateFundRaise";
import EditFundRaise from "./pages/landing_page/PreLogin/FundRaise/Edit/EditFundRaise";
import ProjectManagementList from "./pages/landing_page/PreLogin/ProjectManageMent/ProjectManagementList";
import FundRaiseList from "./pages/landing_page/PreLogin/FundRaise/FundRaiseList";
import StudioList from "./pages/landing_page/PreLogin/Studio/StudioList";
import CreateStudio from "./pages/landing_page/PreLogin/Studio/Create/CreateStudio";
import EditStudio from "./pages/landing_page/PreLogin/Studio/Edit/EditStudio";
import CommunitiesList from "./pages/landing_page/PreLogin/Communities/CommunitiesList";
import CreateCommunities from "./pages/landing_page/PreLogin/Communities/Create/CreateCommunities";
import EditCommunities from "./pages/landing_page/PreLogin/Communities/Edit/EditCommunities";
import PostBelongHereList from "./pages/landing_page/PostLogin/BelongHere/PostBelongHereList";
import PostCreateBelong from "./pages/landing_page/PostLogin/BelongHere/Create/PostCreateBelong";
import PostInspiringList from "./pages/landing_page/PostLogin/InspiringStories/PostInspiringList";
import PostCreateInspiring from "./pages/landing_page/PostLogin/InspiringStories/Create/PostCreateInspiring";
import PostEditInspiring from "./pages/landing_page/PostLogin/InspiringStories/Edit/PostEditInspiring";
import PostEditBelong from "./pages/landing_page/PostLogin/BelongHere/Edit/PostEditBelong";
import LicenceMemberList from "./pages/account_details/individual/licence_member_list";
import CreateCreditPoints from "./pages/marketing/CreditPoints/Create/CreateCreditPoints";
import EditCreatePoints from "./pages/marketing/CreditPoints/Edit/EditCreatePoints";
import CreditPointsList from "./pages/marketing/CreditPoints/CreditPointsList";
import CreditGivenpoints from "./pages/marketing/CreditPoints/CreditGiven/CreditGivenpoints";
import CreditUseDetails from "./pages/marketing/CreditPoints/CreditUseDetails/CreditUseDetails";

// import CreditPoints from "./pages/marketing/creditPointsPage";
// import AddCredit from "./pages/marketing/add_Credit";
// import CreditGiven from "./pages/marketing/creditGivenPage";
// import CreditUseDetails from "./pages/marketing/creditUseDetailsPage";
import CreatePosloginCategory from "./pages/landing_page/PostLogin/Category/Create/CreateCategory";
import EditPosloginCategory from "./pages/landing_page/PostLogin/Category/Edit/EditCategory";
import PostLoginCategoryList from "./pages/landing_page/PostLogin/Category/PostLoginCategoryList";
import WouldLikeList from "./pages/landing_page/PostLogin/WoulLike/WouldLikeList";
import CreateWouldLike from "./pages/landing_page/PostLogin/WoulLike/Create/CreateWouldLike";
import EditWouldLike from "./pages/landing_page/PostLogin/WoulLike/Edit/EditWouldLike";
import PersonaForm from "./pages/landing_page/PostLogin/Persona/PersonaForm";
import UserHistoryList from "./pages/landing_page/PostLogin/WoulLike/UserHistoryList";
import IndividualActivityDetails from "./pages/account_details/individual/IndividualActivityDetails";
import ExcludeAnalyticsUser from "./pages/admin_panel/ExcludeAnalyticsUser/ExcludeAnalyticsUser";
import EmailNotificationUserList from "./pages/marketing/EmailNotificationUserList";
import FeedbackList from "./pages/Feedback/FeedbackList";
import ProjectFeedbackList from "./pages/Feedback/ProjectFeedbackList";
import GlobalFeedbackList from "./pages/Feedback/GlobalFeedbackList";
import ExcludeFeedbackUser from "./pages/Feedback/ExcludeFeedbackUser";
import SpamWord from "./pages/admin_panel/SpamWord/spam_word";
import MarketingList from "./pages/wpond/UpcomingYear/UpcomingYearList/Marketing/marketing";
import BadgeConfig from "./pages/badge/Config/badge_config";
import BadgeUserList from "./pages/badge/UserList/user_list";
import DailyBadgeHistory from "./pages/badge/DailyUserBadge/daily_badge_history";
import UserBadgeConfigList from "./pages/badge/UserConfig/user_badge_config_list";
import CreateUserBadgeConfig from "./pages/badge/UserConfig/Create/create_user_badge_config";
import EditUserBadgeConfig from "./pages/badge/UserConfig/Edit/edit_user_badge_config";
import UpdateUserManual from "./pages/UpdateUserManual/updateUserManual";
import ProfileCompletionConfig from "./pages/profileCompletionConfig/profileCompletionConfig";
import CourseList from "./pages/course/CourseList";
import CreateCourse from "./pages/course/Create/CreateCourse";
import EditCourse from "./pages/course/Edit/EditCourse";
import CourseUsersList from "./pages/course/CourseUsersList";

class App extends Component {
    render() {
        return (
            <>
            <Switch>
                <PublicRoute restricted={true} component={Login} path={"/"} exact />
                <PrivateRoute component={Dashboard} path={"/dashboard"} permissionName={'Dashboard_Tab'} exact />
                <PrivateRoute component={CreateCause} path={"/causes/create"} permissionName={'Causes_Create'} fullAccessPermissionName={"Causes_FullAccess"} exact />
                <PrivateRoute component={EditCause} path={"/causes/edit/:id"} permissionName={'Causes_Update'} fullAccessPermissionName={"Causes_FullAccess"} exact />
                <PrivateRoute component={CausesList} path={"/causes"} permissionName={'Causes_Tab'} fullAccessPermissionName={"Causes_FullAccess"} exact />
                <PrivateRoute component={PromotionList} path={"/promotion"} permissionName={'Promotions_Tab'} fullAccessPermissionName={"Promotions_FullAccess"} exact />
                <PrivateRoute component={CreatePromotion} path={"/promotion/create"} permissionName={'Promotions_Create'} fullAccessPermissionName={"Promotions_FullAccess"} exact />
                <PrivateRoute component={EditPromotion} path={"/promotion/edit/:id"} permissionName={'Promotions_Update'} fullAccessPermissionName={"Promotions_FullAccess"} exact />
                <PrivateRoute
                component={ExploreCategories}
                path={"/explore-categories"}
                permissionName={'Explore_Categories_Tab'}
                fullAccessPermissionName={"Explore_category_FullAccess"}
                exact
                />

                <PrivateRoute
                component={CreateCategory}
                path={"/explore-categories/create"}
                permissionName={'Explore_Category_Create'}
                fullAccessPermissionName={"Explore_category_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditCategory}
                path={"/explore-categories/edit/:id"}
                permissionName={'Explore_Category_Update'}
                fullAccessPermissionName={"Explore_category_FullAccess"}
                exact
                />
                <PrivateRoute component={Blogs} path={"/blogs"} permissionName={'Explore_Blogs_Tab'} fullAccessPermissionName={"Explore_Blogs_FullAccess"} exact />
                <PrivateRoute component={CreateBlog} path={"/blogs/create"} permissionName={'Explore_Blogs_Create'} fullAccessPermissionName={"Explore_Blogs_FullAccess"} exact />
                <PrivateRoute component={EditBlog} path={"/blogs/edit/:id"} permissionName={'Explore_Blogs_Update'} fullAccessPermissionName={"Explore_Blogs_FullAccess"} exact />
                <PrivateRoute
                component={IndividualDetails}
                path={"/user/individual"}
                permissionName={'AD_Individual_Tab'}
                exact
                />
                <PrivateRoute
                    component={IndividualGenerateCode}
                    path={"/user/individual/generate-code/:userId"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={LicenceMemberList}
                    path={"/user/individual/licence-member-list"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                component={OrganizationDetails}
                path={"/user/organization"}
                permissionName={'AD_Organization_Tab'}
                exact
                />
                <PrivateRoute
                component={CircleDetails}
                path={"/user/circles"}
                permissionName={'AD_Circle_Tab'}
                exact
                />
                <PrivateRoute
                component={ProjectDetails}
                path={"/user/projects"}
                permissionName={'AD_Project_Tab'}
                exact
                />
                <PrivateRoute
                component={OpratorDetails}
                path={"/user/operator"}
                permissionName={'AD_Operator_Tab'}
                exact
                />
                <PrivateRoute component={Message} path={"/messages"} permissionName={'Messages_Tab'} fullAccessPermissionName={"Messages_FullAccess"} exact />
                <PrivateRoute
                component={CreateMessage}
                path={"/messages/create"}
                permissionName={'Messages_Create'}
                fullAccessPermissionName={"Messages_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditMessage}
                path={"/messages/edit/:id"}
                permissionName={'Messages_Update'}
                fullAccessPermissionName={"Messages_FullAccess"}
                exact
                />
                <PrivateRoute component={Faq} path={"/faq"} permissionName={'FAQ_Tab'} fullAccessPermissionName={'FAQ_FullAccess'} exact />
                <PrivateRoute component={CreateFaq} path={"/faq/create"} permissionName={'FAQ_Create'} fullAccessPermissionName={'FAQ_FullAccess'}  exact />
                <PrivateRoute component={EditFaq} path={"/faq/edit/:id"} permissionName={'FAQ_Update'} fullAccessPermissionName={'FAQ_FullAccess'}  exact />
                <PrivateRoute component={Post} path={"/report/post"} permissionName={'Report_Post_Tab'} exact />
                {/* <PrivateRoute component={FlagPost} path={"/flag/post"} permissionName={'Report_FlagPost_Tab'} exact /> */}
                {/* <PrivateRoute component={Circle} path={"/report/circle"} permissionName={'Report_Circle_Tab'} exact /> */}
                <PrivateRoute component={User} path={"/report/user"} permissionName={'Report_User_Tab'} exact />
                {/* <PrivateRoute component={Studio} path={"/report/studio"} permissionName={'Reports_Studio_Tab'} exact /> */}
                {/* <PrivateRoute component={SpamUser} path={"/report/spam-user"} permissionName={'Report_Spam_User_Tab'} exact /> */}
                <PrivateRoute component={DraftUser} path={"/user/draft-user"} permissionName={'AD_DraftUser_Tab'} exact />
                <PrivateRoute component={PostDetails} path={"/report/:slug/:id"} permissionName={''} exact />
                <PrivateRoute component={DeactivatedUserReport} path={"/report/deactivate-user"} permissionName={'Report_Deactivated_User_Tab'} exact />
                <PrivateRoute component={WinnerList} path={"/winner"} permissionName={'People_Of_Nature_Winner_Tab'} fullAccessPermissionName={"People_Of_Nature_Winner_FullAccess"} exact />
                <PrivateRoute
                component={CreateWinner}
                path={"/winner/create"}
                permissionName={'People_Of_Nature_Winner_Create'}
                fullAccessPermissionName={"People_Of_Nature_Winner_FullAccess"}
                exact
                />
                <PrivateRoute component={SpeakerList} path={"/speaker"} permissionName={'People_Of_Nature_Speaker_Tab'} fullAccessPermissionName={"People_Of_Nature_Speaker_FullAccess"} exact />
                <PrivateRoute
                component={CreateSpeaker}
                path={"/speaker/create"}
                permissionName={'People_Of_Nature_Speaker_Create'}
                fullAccessPermissionName={"People_Of_Nature_Speaker_FullAccess"}
                exact
                />
                <PrivateRoute
                component={UpdateSpeaker}
                path={"/speaker/edit/:id"}
                permissionName={'People_Of_Nature_Speaker_Update'}
                fullAccessPermissionName={"People_Of_Nature_Speaker_FullAccess"}
                exact
                />
                <PrivateRoute
                component={UpdateWinner}
                path={"/winner/edit/:id"}
                permissionName={'People_Of_Nature_Winner_Update'}
                fullAccessPermissionName={"People_Of_Nature_Winner_FullAccess"}
                exact
                />
                <PrivateRoute
                component={Forum}
                path={"/report/deleted-forum"}
                permissionName={'Report_Deleted_Post_Tab'}
                exact
                />
                <PrivateRoute
                component={Poll}
                path={"/report/deleted-poll"}
                permissionName={'Report_Deleted_Post_Tab'}
                exact
                />
                <PrivateRoute
                component={PostNew}
                path={"/report/deleted-post"}
                permissionName={'Report_Deleted_Post_Tab'}
                exact
                />
                <PrivateRoute
                component={StudioNew}
                path={"/report/deleted-studio"}
                permissionName={'Report_Deleted_Post_Tab'}
                exact
                />
                <PrivateRoute
                component={DeletePost}
                path={"/report/delete-post"}
                permissionName={'Report_Deleted_Post_Tab'}
                exact
                />
                {/* <PrivateRoute
                component={GenerateReport}
                path={"/generate-reports"}
                permissionName={''}
                exact
                />
                <PrivateRoute 
                component={CreateGenerateReport} 
                path={"/generate-reports/create"} 
                permissionName={''} 
                exact 
                />       */}
                <PrivateRoute
                component={UsersList}
                path={"/admin-panel/user"}
                permissionName={'AP_User_Tab'}
                fullAccessPermissionName={"AP_User_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreatUser}
                path={"/admin-panel/user/create"}
                permissionName={'AP_User_Create'}
                fullAccessPermissionName={"AP_User_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditUser}
                path={"/admin-panel/user/edit/:id"}
                permissionName={'AP_User_Update'}
                fullAccessPermissionName={"AP_User_FullAccess"}
                exact
                />
                <PrivateRoute
                component={DepartmentList}
                path={"/admin-panel/department"}
                permissionName={'AP_Department_Tab'}
                fullAccessPermissionName={"AP_Department_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateDepartment}
                path={"/admin-panel/department/create"}
                permissionName={'AP_Department_Create'}
                fullAccessPermissionName={"AP_Department_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditDepartment}
                path={"/admin-panel/department/edit/:id"}
                permissionName={'AP_Department_Update'}
                fullAccessPermissionName={"AP_Department_FullAccess"}
                exact
                />
                <PrivateRoute component={RoleList} path={"/admin-panel/role"} permissionName={'AP_Roles_Tab'} fullAccessPermissionName={"AP_Roles_FullAccess"} exact />
                <PrivateRoute
                component={CreateRole}
                path={"/admin-panel/role/create"}
                permissionName={'AP_Roles_Create'}
                fullAccessPermissionName={"AP_Roles_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditRole}
                path={"/admin-panel/role/edit/:id"}
                permissionName={'AP_Roles_Update'}
                fullAccessPermissionName={"AP_Roles_FullAccess"}
                exact
                />
                <PrivateRoute
                component={PermissionList}
                path={"/admin-panel/permission"}
                permissionName={'AP_Permission_Tab'}
                exact
                />
                <PrivateRoute
                component={GroupList}
                path={"/admin-panel/group"}
                permissionName={'AP_Group_Tab'}
                exact
                />
                <PrivateRoute
                component={CreateGroup}
                path={"/admin-panel/group/create"}
                permissionName={'AP_Group_Create'}
                exact
                />
                <PrivateRoute
                component={EditGroup}
                path={"/admin-panel/group/edit/:id"}
                permissionName={''}
                exact
                />        
                <PrivateRoute
                component={ServerScript}
                path={"/admin-panel/server-script"}
                permissionName={'AP_ServerScript_Tab'}
                exact
                />
                <PrivateRoute
                component={ExcludeGlobalUser}
                path={"/admin-panel/exclude-global-user"}
                permissionName={''}
                exact
                />

                <PrivateRoute
                component={AmountAndTenure}
                path={"/subscription/amount-tenure"}
                permissionName={'SM_Amount_Tenure_Tab'}
                exact
                />
                <PrivateRoute
                component={CreateAmountAndTenure}
                path={"/subscription/amount-tenure/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={CreateAmountAndTenure}
                path={"/subscription/amount-tenure/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditAmountAndTenure}
                path={"/subscription/amount-tenure/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={CircleSubscription}
                path={"/subscription/circle"}
                permissionName={'SM_Circle_Tab'}
                exact
                />
                <PrivateRoute
                component={CreateCircleSubscription}
                path={"/subscription/circle/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditCircleSubscription}
                path={"/subscription/circle/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={OrganizationSubscription}
                path={"/subscription/organization"}
                permissionName={'SM_Organization_Tab'}
                exact
                />
                <PrivateRoute
                component={ProjectSubscription}
                path={"/subscription/project"}
                permissionName={'SM_Project_Tab'}
                exact
                />
                <PrivateRoute
                component={CreateProjectSubscription}
                path={"/subscription/project/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditProjectSubscription}
                path={"/subscription/project/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={OthersSubscription}
                path={"/subscription/others"}
                permissionName={'SM_Others_Tab'}
                exact
                />
                <PrivateRoute
                component={CreateOthersSubscription}
                path={"/subscription/others/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditOthersSubscription}
                path={"/subscription/others/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={ReceivedList}
                path={"/payment/received"}
                permissionName={'Payment_Received_Tab'}
                exact
                />
                <PrivateRoute
                component={RemittedList}
                path={"/payment/remitted"}
                permissionName={'Payment_Remitted_Tab'}
                exact
                />
                <PrivateRoute
                component={YearList}
                path={"/wpond/year"}
                permissionName={'WPOND_Tab'}
                fullAccessPermissionName={"WPOND_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateYear}
                path={"/wpond/year/create"}
                permissionName={'WPOND_Create'}
                fullAccessPermissionName={"WPOND_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditYear}
                path={"/wpond/year/edit/:id"}
                permissionName={''}
                fullAccessPermissionName={"WPOND_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EventList}
                path={"/wpond/:year/event"}
                permissionName={'WPOND_WPOND_Event_Count'}
                fullAccessPermissionName={"WPOND_Event_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateEvent}
                path={"/wpond/:year/event/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditEvent}
                path={"/wpond/:year/event/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={MediaList}
                path={"/wpond/:year/media"}
                permissionName={'WPOND_WPOND_Media_Count'}
                fullAccessPermissionName={"WPOND_Media_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateMedia}
                path={"/wpond/:year/media/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditMedia}
                path={"/wpond/:year/media/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={UpcomingYearList}
                path={"/wpond/upcoming-year"}
                permissionName={'WPOND_UpcomingTab'}
                fullAccessPermissionName={"WPOND_Upcoming_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateUpcomingYear}
                path={"/wpond/upcoming-year/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditUpcomingYear}
                path={"/wpond/upcoming-year/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={AwardCategoriesList}
                path={"/wpond/:year/award-categories"}
                permissionName={'WPOND_Award_Categories_Tab'}
                fullAccessPermissionName={"WPOND_AC_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateAwardCategories}
                path={"/wpond/:year/award-categories/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditAwardCategories}
                path={"/wpond/:year/award-categories/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={JuriesList}
                path={"/wpond/:year/juries"}
                permissionName={'WPOND_Juries_Tab'}
                fullAccessPermissionName={"WPOND_Juries_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateJuries}
                path={"/wpond/:year/juries/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditJuries}
                path={"/wpond/:year/juries/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={SponsorsList}
                path={"/wpond/:year/sponsors"}
                permissionName={'WPOND_Sponsors_Tab'}
                fullAccessPermissionName={"WPOND_Sponsors_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateSponsors}
                path={"/wpond/:year/sponsors/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditSponsors}
                path={"/wpond/:year/sponsors/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={PartnersList}
                path={"/wpond/:year/partners"}
                permissionName={'WPOND_Partners_Tab'}
                fullAccessPermissionName={"WPOND_Partners_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreatePartners}
                path={"/wpond/:year/partners/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditPartners}
                path={"/wpond/:year/partners/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={UpcomingEventsList}
                path={"/wpond/:year/upcoming-event"}
                permissionName={'WPOND_Event_Tab'}
                fullAccessPermissionName={"WPOND_Event_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateUpcomingEvent}
                path={"/wpond/:year/upcoming-event/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditUpcomingEvent}
                path={"/wpond/:year/upcoming-event/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EventSpeakerList}
                path={"/wpond/:year/upcoming-event/:slug/speaker"}
                permissionName={'WPOND_Event_Speaker_Count'}
                fullAccessPermissionName={"WPOND_EventSpeaker_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateEventSpeaker}
                path={"/wpond/:year/upcoming-event/:slug/speaker/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditEventSpeaker}
                path={"/wpond/:year/upcoming-event/:slug/speaker/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={NominationList}
                path={"/wpond/:year/award-categories/:slug/nominations"}
                permissionName={'WPOND_AC_NomineeCount'}
                fullAccessPermissionName={"WPOND_ACN_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateNomination}
                path={"/wpond/:year/award-categories/:slug/nomination/create"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={EditNomination}
                path={"/wpond/:year/award-categories/:slug/nomination/edit/:id"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={VoterList}
                path={"/wpond/:year/award-categories/:slug/nominations/voters/:nomineeId"}
                permissionName={'WPOND_ACN_VoteCount'}
                exact
                />
                <PrivateRoute
                component={ProjectPointsList}
                path={"/wpond/:year/award-categories/:slug/nominations/project-list/:nomineeId"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={ProjectActivityPointsList}
                path={"/wpond/:year/award-categories/:slug/nominations/project-activity-list/:nomineeId"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={ProjectParticipantsPointsList}
                path={"/wpond/:year/award-categories/:slug/nominations/project-participants-list/:nomineeId"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={ActivityPointsList}
                path={"/wpond/:year/award-categories/:slug/nominations/activity-list/:nomineeId"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={LeaderBoard}
                path={"/wpond/:year/leader-board"}
                permissionName={'WPOND_LeaderBoard_Tab'}
                exact
                />
                <PrivateRoute
                component={MarketingList}
                path={"/wpond/:year/marketing"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={HowWeImpactCategoryList}
                path={"/how-we-impact"}
                permissionName={'HowWeImpact_Tab'}
                fullAccessPermissionName={"HowWeImpact_FullAccess"}
                exact
                />
                <PrivateRoute
                component={CreateHowWeImpactCategory}
                path={"/how-we-impact/create"}
                permissionName={'HowWeImpact_Create'}
                fullAccessPermissionName={"HowWeImpact_FullAccess"}
                exact
                />
                <PrivateRoute
                component={EditHowWeImpactCategory}
                path={"/how-we-impact/edit/:id"}
                permissionName={'HowWeImpact_Update'}
                fullAccessPermissionName={"HowWeImpact_FullAccess"}
                exact
                />
                <PrivateRoute
                component={StudioSummary}
                path={"/studio/list"}
                permissionName={'Studio_List_Tab'}
                exact
                />
                <PrivateRoute
                component={StudioExcludeUserList}
                path={"/studio/exclude-user-list"}
                permissionName={'Studio_ExcludeUser_Tab'}
                exact
                />
                <PrivateRoute
                component={StudioViewClickList}
                path={"/studio/:actionType/:studioId"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={ExcludeUserList}
                path={"/subscription/exclude-user-list"}
                permissionName={'SM_ExcludeUser_Tab'}
                fullAccessPermissionName={"SM_ExcludeUser_Fullaccess"}
                exact
                />
                <PrivateRoute
                component={TrialSubscription}
                path={"/subscription/trial-user"}
                permissionName={'SM_TRIAL_Tab'}
                fullAccessPermissionName={'SM_TRIAL_FullAccess'}
                exact
                />
                <PrivateRoute
                component={TrialSubscriptionDetails}
                path={"/subscription/trial-user-details/:userId"}
                permissionName={'SM_TRIAL_Details'}
                fullAccessPermissionName={'SM_TRIAL_FullAccess'}
                exact
                />
                <PrivateRoute
                component={FEAdminList}
                path={"/admin-panel/fe-admin"}
                permissionName={'AP_FE_Admin_tab'}
                exact
                />
                <PrivateRoute
                component={ExcludeUserFlagPost}
                path={"/admin-panel/flag-post"}
                permissionName={'AP_FlagPost_Tab'}
                exact
                />
                <PrivateRoute component={RestrictedUser} path={"/restricted-user"} permissionName={'AP_Restricted_User_Tab'} fullAccessPermissionName={'AP_Restricted_User_FullAccess'} exact/>
                <PrivateRoute
                component={Testimonials}
                path={"/testimonials"}
                permissionName={'Testimonial_Tab'}
                exact
                />
                <PrivateRoute
                component={CreateTestimonial}
                path={"/testimonials/create"}
                permissionName={'Testimonial_Create'}
                fullAccessPermissionName={'Testimonial_FullAccess'}
                exact
                />
                <PrivateRoute
                component={EditTestimonial}
                path={"/testimonials/edit/:id"}
                permissionName={''}
                fullAccessPermissionName={''}
                exact
                />
                <PrivateRoute
                component={NotPermission}
                path={"/no-permission"}
                permissionName={''}
                exact
                />
                <PrivateRoute
                component={NotificationPage}
                path={"/notification"}
                permissionName={'Notification_Tab'}
                fullAccessPermissionName={'Notification_FullAccess'}
                exact
                />
                <PrivateRoute 
                component={CreateNotification} 
                path={"/notification/create"} 
                permissionName={'Notification_Create'}
                fullAccessPermissionName={'Notification_FullAccess'}
                exact 
                />
                <PrivateRoute 
                component={EditNotification} 
                path={"/notification/edit/:id"} 
                permissionName={'Notification_Update'}
                fullAccessPermissionName={'Notification_FullAccess'}
                exact 
                />
                <PrivateRoute 
                component={EmailNotificationUserList} 
                path={"/user/list/:id"} 
                permissionName={''}
                fullAccessPermissionName={''}
                exact 
                />
                <PrivateRoute
                component={EmailPage}
                path={"/email"}
                permissionName={'Email_Tab'}
                fullAccessPermissionName={'Email_FullAccess'}
                exact
                />
                <PrivateRoute 
                component={CreateEmail} 
                path={"/email/create"} 
                permissionName={'Email_Create'}
                fullAccessPermissionName={'Email_FullAccess'}
                exact 
                />
                <PrivateRoute 
                component={EditEmail} 
                path={"/email/edit/:id"} 
                permissionName={'Email_Update'}
                fullAccessPermissionName={'Email_FullAccess'}
                exact 
                />
                <PrivateRoute 
                component={NotifierViewList} 
                path={"/email/viewer/:id"} 
                permissionName={'Email_Update'}
                fullAccessPermissionName={'Email_FullAccess'}
                exact 
                />

                <PrivateRoute
                component={CreditPointsList}
                path={"/credit-points"}
                permissionName={'CREDIT_POINT_TAB'}
                fullAccessPermissionName={'CREDIT_POINT_FullAccess'}
                exact
                />
                <PrivateRoute 
                component={CreateCreditPoints}
                path={"/credit-points/create"} 
                permissionName={'CREDIT_POINT_ADD'} 
                fullAccessPermissionName={"CREDIT_POINT_FullAccess"}
                exact 
                />
                 <PrivateRoute 
                component={EditCreatePoints}
                path={"/credit-points/edit/:id"} 
                permissionName={'CREDIT_POINT_UPDATE'} 
                fullAccessPermissionName={"CREDIT_POINT_FullAccess"}
                exact 
                />
                <PrivateRoute 
                component={CreditGivenpoints}
                path={"/credit-points/credit-given/:id"} 
                permissionName={'CREDIT_POINT_USER_LIST'} 
                fullAccessPermissionName={"CREDIT_POINT_FullAccess"}
                exact 
                />
                <PrivateRoute 
                component={CreditUseDetails} 
                path={"/credit-points/credit-given/use-details/:id"} 
                permissionName={'CREDIT_POINT_TRANSACTION_LIST'} 
                fullAccessPermissionName={"CREDIT_POINT_FullAccess"}
                exact 
                />
                
                <PrivateRoute 
                    component={BelongHereList} 
                    path={"/pre-login/belong-here"} 
                    permissionName={'Pre_Login_Belong_Here_Tab'}
                    fullAccessPermissionName={'Pre_Login_Belong_Here_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateBelong} 
                    path={"/pre-login/belong-here/create"} 
                    permissionName={'Pre_Login_Belong_Here_Create'}
                    fullAccessPermissionName={'Pre_Login_Belong_Here_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={EditBelong} 
                    path={"/pre-login/belong-here/edit/:id"} 
                    permissionName={'Pre_Login_Belong_Here_Update'}
                    fullAccessPermissionName={'Pre_Login_Belong_Here_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={InspiringList} 
                    path={"/pre-login/inspiring-story"} 
                    permissionName={'Pre_Login_Inspiring_Stories_Tab'}
                    fullAccessPermissionName={'Pre_Login_Inspiring_Stories_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateInspiring} 
                    path={"/pre-login/inspiring-story/create"} 
                    permissionName={'Pre_Login_Inspiring_Stories_Create'}
                    fullAccessPermissionName={'Pre_Login_Inspiring_Stories_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={EditInspiring} 
                    path={"/pre-login/inspiring-story/edit/:id"} 
                    permissionName={'Pre_Login_Inspiring_Stories_Update'}
                    fullAccessPermissionName={'Pre_Login_Inspiring_Stories_FullAccess'}
                    exact 
                />

                <PrivateRoute 
                    component={ProjectManagementList} 
                    path={"/pre-login/project-management"} 
                    permissionName={'Pre_Login_Project_Manage_Tab'}
                    fullAccessPermissionName={'Pre_Login_Project_Manage_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateProjectManagement} 
                    path={"/pre-login/project-management/create"} 
                    permissionName={'Pre_Login_Project_Manage_Create'}
                    fullAccessPermissionName={'Pre_Login_Project_Manage_FullAccess'}
                    exact 
                />
                 <PrivateRoute 
                    component={EditProjectManagement} 
                    path={"/pre-login/project-management/edit/:id"} 
                    permissionName={'Pre_Login_Project_Manage_Update'}
                    fullAccessPermissionName={'Pre_Login_Project_Manage_FullAccess'}
                    exact 
                />

                <PrivateRoute 
                    component={TrendingContentList} 
                    path={"/pre-login/trending-content"} 
                    permissionName={'Pre_Login_Trending_Content_Tab'}
                    fullAccessPermissionName={'Pre_Login_Trending_Content_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateTrendingConent} 
                    path={"/pre-login/trending-content/create"} 
                    permissionName={'Pre_Login_Trending_Content_Create'}
                    fullAccessPermissionName={'Pre_Login_Trending_Content_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={EditTrendingConent} 
                    path={"/pre-login/trending-content/edit/:id"} 
                    permissionName={'Pre_Login_Trending_Content_Update'}
                    fullAccessPermissionName={'Pre_Login_Trending_Content_FullAccess'}
                    exact 
                />

                <PrivateRoute 
                    component={FundRaiseList} 
                    path={"/pre-login/fund-raise"} 
                    permissionName={'Pre_Login_Fund_Raise_Tab'}
                    fullAccessPermissionName={'Pre_Login_Fund_Raise_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateFundRaise} 
                    path={"/pre-login/fund-raise/create"} 
                    permissionName={'Pre_Login_Fund_Raise_Create'}
                    fullAccessPermissionName={'Pre_Login_Fund_Raise_FullAccess'}
                    exact 
                />
                 <PrivateRoute 
                    component={EditFundRaise} 
                    path={"/pre-login/fund-raise/edit/:id"} 
                    permissionName={'Pre_Login_Fund_Raise_Update'}
                    fullAccessPermissionName={'Pre_Login_Fund_Raise_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={StudioList} 
                    path={"/pre-login/studio"} 
                    permissionName={'Pre_Login_Studio_Tab'}
                    fullAccessPermissionName={'Pre_Login_Studio_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateStudio} 
                    path={"/pre-login/studio/create"} 
                    permissionName={'Pre_Login_Studio_Create'}
                    fullAccessPermissionName={'Pre_Login_Studio_FullAccess'}
                    exact 
                />
                 <PrivateRoute 
                    component={EditStudio} 
                    path={"/pre-login/studio/edit/:id"} 
                    permissionName={'Pre_Login_Studio_Update'}
                    fullAccessPermissionName={'Pre_Login_Studio_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CommunitiesList} 
                    path={"/pre-login/communities"} 
                    permissionName={'Pre_Login_Community_Tab'}
                    fullAccessPermissionName={'Pre_Login_Community_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateCommunities} 
                    path={"/pre-login/communities/create"} 
                    permissionName={'Pre_Login_Community_Create'}
                    fullAccessPermissionName={'Pre_Login_Community_FullAccess'}
                    exact 
                />
                 <PrivateRoute 
                    component={EditCommunities} 
                    path={"/pre-login/communities/edit/:id"} 
                    permissionName={'Pre_Login_Community_Update'}
                    fullAccessPermissionName={'Pre_Login_Community_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={PostBelongHereList} 
                    path={"/post-login/belong-here"} 
                    permissionName={'Post_Login_Belong_Here_Tab'}
                    fullAccessPermissionName={'Post_Login_Belong_Here_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={PostCreateBelong} 
                    path={"/post-login/belong-here/create"} 
                    permissionName={'Post_Login_Belong_Here_Create'}
                    fullAccessPermissionName={'Post_Login_Belong_Here_FullAccess'}
                    exact 
                />
                 <PrivateRoute 
                    component={PostEditBelong} 
                    path={"/post-login/belong-here/edit/:id"} 
                    permissionName={'Post_Login_Belong_Here_Update'}
                    fullAccessPermissionName={'Post_Login_Belong_Here_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={PostInspiringList} 
                    path={"/post-login/inspiring-story"} 
                    permissionName={'Post_Login_Inspiring_Stories_Tab'}
                    fullAccessPermissionName={'Post_Login_Inspiring_Stories_FullAccess'}
                    exact 
                />
                 <PrivateRoute 
                    component={PostCreateInspiring} 
                    path={"/post-login/inspiring-story/create"} 
                    permissionName={'Post_Login_Inspiring_Stories_Create'}
                    fullAccessPermissionName={'Post_Login_Inspiring_Stories_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={PostEditInspiring} 
                    path={"/post-login/inspiring-story/edit/:id"} 
                    permissionName={'Post_Login_Inspiring_Stories_Update'}
                    fullAccessPermissionName={'Post_Login_Inspiring_Stories_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={PostLoginCategoryList} 
                    path={"/post-login/category"} 
                    permissionName={'Post_Login_Category_Tab'}
                    fullAccessPermissionName={'Post_Login_Category_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreatePosloginCategory} 
                    path={"/post-login/category/create"} 
                    permissionName={'Post_Login_Category_Create'}
                    fullAccessPermissionName={'Post_Login_Category_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={EditPosloginCategory} 
                    path={"/post-login/category/edit/:id"} 
                    permissionName={'Post_Login_Category_Update'}
                    fullAccessPermissionName={'Post_Login_Category_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={WouldLikeList} 
                    path={"/post-login/would-like"} 
                    permissionName={'Post_Login_Would_Like_Tab'}
                    fullAccessPermissionName={'Post_Login_Would_Like_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={CreateWouldLike} 
                    path={"/post-login/would-like/create"} 
                    permissionName={'Post_Login_Would_Like_Create'}
                    fullAccessPermissionName={'Post_Login_Would_Like_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={EditWouldLike} 
                    path={"/post-login/would-like/edit/:id"} 
                    permissionName={'Post_Login_Would_Like_Update'}
                    fullAccessPermissionName={'Post_Login_Would_Like_FullAccess'}
                    exact 
                />
                <PrivateRoute 
                    component={PersonaForm} 
                    path={"/post-login/persona"} 
                    permissionName={'Post_Login_Persona_Tab'}
                    fullAccessPermissionName={''}
                    exact 
                />
                <PrivateRoute 
                    component={UserHistoryList} 
                    path={"/post-login/would-like/:categoryId/user-list"} 
                    permissionName={''}
                    fullAccessPermissionName={''}
                    exact 
                />

                <PrivateRoute 
                    component={(props) => (<IndividualActivityDetails key={props.match.params.type + props.match.params.id} pageType={props.match.params?.type === "individual" ? "INDIVIDUAL" : "ORGANIZATION"} {...props} /> )}
                    path={"/user/:type/:id"} 
                    permissionName={''} 
                    exact 
                />

               <PrivateRoute
                    component={ExcludeAnalyticsUser}
                    path={"/admin-panel/exclude-analytics-user"}
                    permissionName={''}
                    fullAccessPermissionName={""}
                    exact
                />

                {/* <PrivateRoute
                    component={FeedbackList}
                    path={"/feedback"}
                    permissionName={''}
                    exact
                /> */}
                {/* <PrivateRoute 
                    component={CreateFeedback}
                    path={"/feedback/create"} 
                    permissionName={''} 
                    exact 
                /> */}

                <PrivateRoute
                    component={ProjectFeedbackList}
                    path={"/project-feedback"}
                    permissionName={''}
                    exact
                /> 
                <PrivateRoute
                    component={GlobalFeedbackList}
                    path={"/global-feedback"}
                    permissionName={''}
                    exact
                /> 

                <PrivateRoute
                    component={ExcludeFeedbackUser}
                    path={"/exclude-feedback-user"}
                    permissionName={''}
                    exact
                />  
                <PrivateRoute component={SpamWord} path={"/spam-word"} permissionName={''} fullAccessPermissionName={''} exact/>
                <PrivateRoute
                    component={BadgeConfig}
                    path={"/badge-config"}
                    permissionName={''}
                    exact
                /> 
                <PrivateRoute
                    component={ProfileCompletionConfig}
                    path={"/profile-completion-config"}
                    permissionName={''}
                    exact
                /> 
                <PrivateRoute
                    component={(props) => (<BadgeUserList pageType={"USER_LIST"} {...props} /> )}
                    path={"/badge-user-list"}
                    permissionName={''}
                    type={"USER_LIST"}
                    exact
                />
                <PrivateRoute
                    component={(props) => (<BadgeUserList pageType={"USER_BADGE_LIST"} {...props} /> )}
                    path={"/user-badge-list"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={(props) => (<DailyBadgeHistory pageType={"BADGE_HISTORY"} {...props} /> )}
                    path={"/badge-user-history/:userId"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={(props) => (<DailyBadgeHistory pageType={"BADGE_ASSIGNMENT_HISTORY"} {...props} /> )}
                    path={"/badge-assignment-history/:userId"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={UserBadgeConfigList}
                    path={"/user-badge-config"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={CreateUserBadgeConfig}
                    path={"/user-badge-config/create"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={EditUserBadgeConfig}
                    path={"/user-badge-config/edit/:id"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={UpdateUserManual}
                    path={"/update-user-manual"}
                    permissionName={''}
                    exact
                />
                <PrivateRoute
                    component={CourseList}
                    path={"/course"}
                    permissionName={''}
                    exact
                /> 
                <PrivateRoute
                    component={CreateCourse}
                    path={"/course/create"}
                    permissionName={''}
                    exact
                /> 
                <PrivateRoute
                    component={EditCourse}
                    path={"/course/edit/:id"}
                    permissionName={''}
                    exact
                /> 
                <PrivateRoute
                    component={CourseUsersList}
                    path={"/course/user/:courseId"}
                    permissionName={''}
                    exact
                /> 
                
            </Switch>
        </>

    );
    }
}

export default App;
