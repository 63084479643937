import React, { Component } from "react";
import { Link } from "react-router-dom";
import httpService from "../../../AxiosInterceptors";
import { CommonHeader } from "../../../utils/commonHeader";
import CreateCourseForm from "../CreateCourseForm";

httpService.setupInterceptors();
export class CreateCourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/course" className="goback">
                            Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <CreateCourseForm formType={"CREATE"} />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default CreateCourse;