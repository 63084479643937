import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { epochTogmt, trimDescription } from "../../../../utils/common";
import StatusDetails from "../../../../components/StatusDetails/status_details";
// This is the table constant/settings which needed to render table elements
export const tableConstants = (fetchAllGroupList, deleteGroup, handleEdit) => {
  return [
    {
      id: 1,
      name: "Group Name",
      cell: (row) => {
        return (
          <Link
            to={`/admin-panel/group/edit/${row.id}`}
            style={{ color: "#154A59" }}
          >
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Permission",
      selector: (row) => row.permission,
      cell: (row) => {
        let output = [];
        let count = 0;
        row.permission &&
          row.permission.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Created On",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
