import axios from "axios";
import React, { Component } from "react";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "../FEAdmin/fe_admin_list.module.css";
import FormInput from "../../../components/InputFields/form_input";
import Button from "../../../components/Buttons/button";
import alertPopUp from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DataTable from "react-data-table-component";
import { tableConstants } from "./Data/tableConstants";
import { customStyles } from "../../../utils/tableCustomStyle";
import { FilterComponent } from "../../../utils/filter";
import { tableConstantsDevice } from "./Data/tableConstantsDevice";
import { auth_service } from "../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp);
let confirmBlockModalSwal = {
  title: "<h5>Are you sure you want to block this device?</h5>",
  focusConfirm: false,
  type: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  allowOutsideClick: false,
};

let confirmUnBlockModalSwal = {
  title: "<h5>Are you sure you want to unblock this device?</h5>",
  focusConfirm: false,
  type: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  allowOutsideClick: false,
};

let allBlockConfirmModalSwal = {
  title: "<h5>Are you sure you want to block all devices?</h5>",
  focusConfirm: false,
  type: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  allowOutsideClick: false,
};

let allUnBlockConfirmModalSwal = {
  title: "<h5>Are you sure you want to unblock all devices?</h5>",
  focusConfirm: false,
  type: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes",
  cancelButtonText: "No",
  allowOutsideClick: false,
};

let deleteConfirmModal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};
class RestrictedUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      errors: {},
      totalRows: 0,
      perPage: 10,
      restrictedList: [],
      filterText: "",
      filterTextOne: "",
      filterDisplay: false,
      restrictedDeviceList: [],
      restricedDeviceEmail: '',
      restrictedDeviceUserId: '',
      restrictedUserStatus: ''
    };
  }

  // validate
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.emailId) {
      formIsValid = false;
      errors["emailId"] = "Please enter email id";
    }
    if (
      this.state.emailId != null &&
      this.state.emailId != undefined &&
      this.state.emailId != ""
    ) {
      let emailIdAddress = this.state.emailId;
      let emailIdValid = emailIdAddress.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailIdValid == null) {
        formIsValid = false;
        errors["emailId"] = "Please enter a valid email";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  componentDidMount() {
    this.fetchRestrictedUserList(0);
  }

  handleChange = (event) => {
    this.setState({
      emailId: event.target.value.trim(),
    });
  };

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        email: this.state.emailId,
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/adminPanelRestrictedUser/addUser`,
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            this.setState(
              {
                emailId: "",
              },
              () => {
                this.fetchRestrictedUserList(0);
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm();
            },
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>);
          });
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          error: false,
        });
      }, 5000);
    }
  };

  fetchRestrictedUserList = async (page) => {
    this.setState({
      loading: true,
    });
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminPanelRestrictedUser/getList?page=${page}&size=${this.state.perPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            restrictedList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({
          restrictedList: [],
          loading: false,
        });
      });
  };

  handlePageChange = (page) => {
    this.fetchRestrictedUserList(page - 1);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true,
    });
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminPanelRestrictedUser/getList?page=${page}&size=${this.state.perPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            restrictedList: response.data.body && response.data.body.content,
            perPage: newPerPage,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          restrictedList: [],
          loading: false,
        });
      });
  };

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems =
              this.state.restrictedList &&
              this.state.restrictedList.filter(
                (item) =>
                  item.email &&
                  item.email.toLowerCase().includes(newFilterText.toLowerCase())
              );
            this.setState({ filterText: newFilterText });
          }}
          searchType={"Search by email"}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {/* <ExportCSV headers={headers} data={this.state.feAdminList} filename="fe_admin_list" /> */}
      </React.Fragment>
    );
  };

  handleConfirmModal = (data) => {
    window.$("#deviceListModal").modal("show");
    this.setState({
      restrictedDeviceList: data.devices,
      restricedDeviceEmail: data.email,
      restrictedDeviceUserId: data.userId,
      restrictedUserStatus: data.status
    })
  };

  handleDeviceConfirmModal = (deviceId, status) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(status === true  ? confirmBlockModalSwal : confirmUnBlockModalSwal);
      let postData = {
        userId: this.state.restrictedDeviceUserId,
        deviceId: deviceId,
        status: status
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/adminPanelRestrictedUser/block-unblock`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              window.$("#deviceListModal").modal("hide");
              this.fetchRestrictedUserList(0);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    handleConfirm();
  }


  blockUnblockAllDevices = (userId,status) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(status === true ?  allBlockConfirmModalSwal : allUnBlockConfirmModalSwal);
      let postData = {
        userId: userId,
        status: status
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/adminPanelRestrictedUser/block-unblock-all`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              window.$("#deviceListModal").modal("hide");
              this.fetchRestrictedUserList(0);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    handleConfirm();
  }

  deleteConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(deleteConfirmModal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/adminPanelRestrictedUser/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              let restrictedListData = this.state.restrictedList;
              const index = restrictedListData && restrictedListData.findIndex(item => item.id === id);
              if(index > -1) {
                restrictedListData.splice(index, 1);
              }

              this.setState({
                restrictedList: restrictedListData
              })
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  render() {
    this.filteredItems =
      this.state.restrictedList &&
      this.state.restrictedList.filter(
        (item) =>
          item.email &&
          item.email.toLowerCase().includes(this.state.filterText.toLowerCase())
      );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-6">
                    <FormInput
                      group={"large"}
                      value={this.state.emailId}
                      label={"Email Id"}
                      onChange={this.handleChange}
                      name={"emailId"}
                      error={this.state.errors["emailId"]}
                    />
                  </div>
                  {auth_service.getPermission("AP_Restricted_User_Add", "AP_Restricted_User_FullAccess") &&
                  <div className="col-md-3">
                    <Button children={"Add"} onClick={this.onSubmit} />
                  </div>}
                </div>
              </div>
            </div>
            <DataTable
              title="FE Restricted User List"
              columns={tableConstants(this.handleConfirmModal, this.deleteConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              highlightOnHover
              pointerOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
          <div
            class="modal fade dgpopup"
            id="deviceListModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Select Device Id List</h5>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                      <div style={{marginBottom:'15px'}}>
                        {auth_service.getPermission("AP_Restricted_User_Block", "AP_Restricted_User_FullAccess") && <button onClick={()=>{
                          this.blockUnblockAllDevices(this.state.restrictedDeviceUserId, true)
                        }} className={ "btnUpdte"}>Block All</button>}
                        {auth_service.getPermission("AP_Restricted_User_Unblock", "AP_Restricted_User_FullAccess") && <button onClick={()=>{
                          this.blockUnblockAllDevices(this.state.restrictedDeviceUserId, false)
                        }} className={ "btnUpdte"}>Unblock All</button>}
                        </div>
                        <DataTable
                          columns={tableConstantsDevice(this.handleDeviceConfirmModal)}
                          data={this.state.restrictedDeviceList}
                          customStyles={customStyles}
                          highlightOnHover
                          pointerOnHover
                          progressPending={this.state.loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    id="closePermissionModal"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default RestrictedUser;
