import React, { Component } from "react";
import styles from "./BelongHere/pre-login.module.css"
import styled from "styled-components";
import Button from "../../../components/Buttons/button"
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormInput from "../../../components/InputFields/form_input";
import axios from "axios";
import history from "../../../utils/history";
import { v4 as uuidv4 } from 'uuid';
import { isVideoFileValid, isYouTubeLink } from "../../../utils/common";
import UploadFilesData from "../../../components/UploadFiles/upload_file_data";
import { auth_service } from "../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

class CommonManagementForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFieldValue: [{ highlight: "", id: uuidv4()}],
            link: "",
            errors: {},
            editData: {},
            thumbnailImage: null,
            thumbnailImageMessage: "",
        }
    }

    componentDidMount() {
        if (this.props.formType === "UPDATE") {
            this.getProjectManagementDataById();
        }
    }

    addHighlights = () => {
        let inputFieldValue = this.state.inputFieldValue;
        inputFieldValue.push({ highlight: "", id: uuidv4() });
        this.setState({
            inputFieldValue
        })
    };

    removeHighlights = (id) => {
        let inputFieldValue = this.state.inputFieldValue;
        let index = inputFieldValue && inputFieldValue.findIndex((item) => item.id === id);
        if (index > -1) {
            inputFieldValue.splice(index, 1);
        }
        this.setState({
            inputFieldValue
        })
    }

    onChangeHighlighs = (id, event) => {
        let inputFieldValue = this.state.inputFieldValue;
        let index = inputFieldValue && inputFieldValue.findIndex((item) => item.id === id);
        if(index > -1) {
            inputFieldValue[index].highlight = event.target.value.trimStart();
        }
        this.setState({
            inputFieldValue,
        })
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    };

    getProjectManagementDataById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/pre/login/management/get?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                let highlightsValue = result?.highlights.map(obj => {
                    let editObj = { highlight: obj, id: uuidv4() }
                    return editObj;
                })
                this.setState({
                    inputFieldValue: highlightsValue,
                    link: result?.link,
                    editData: result,
                    thumbnailImage: result?.attachment,
                })
            }
        }).catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm()
                }
            }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
        });
    }
    
    updateUploadedFiles = (image) => {
        this.setState({
            thumbnailImage: image,
        });
    };



    validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.thumbnailImage) {
            formIsValid = false;
            this.setState({
                thumbnailImageMessage: "Please select thumbnail"
            })
        }

        this.state.inputFieldValue && this.state.inputFieldValue.map((data, index) => {
            const newArray = this.state.inputFieldValue;
            if (!data.highlight) {
                newArray[index].errorMessage = "Please enter a link.";
                formIsValid = false;
            }
            if (data.highlight) {
                let urlValid = data.highlight.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (urlValid == null) {
                    newArray[index].errorMessage = "Please enter a valid link.";
                    formIsValid = false;
                }
            }
            this.setState({
                inputFieldValue: newArray
            })
            setTimeout(() => {
                newArray[index].errorMessage = null;
                this.setState({
                    inputFieldValue: newArray
                })
            }, 5000);
        });

        if (!this.state.link) {
            formIsValid = false;
            errors["link"] = "Please enter a link";
        }

        if (this.state.link) {
                if (!isYouTubeLink(this.state.link) && !isVideoFileValid(this.state.link)) {
                    formIsValid = false;
                    errors["link"] = "Please enter a valid link";
                }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    thumbnailImageMessage: ""
                });
            }, 5000);
        }

        return formIsValid;
    };


    handleFormSubmit = () => {

        if (this.validateForm()) {

            let highlightFieldValues = [];
            this.state.inputFieldValue && this.state.inputFieldValue.map((data, index) => {
                highlightFieldValues.push(data.highlight);
            })

            let formData = {
                highlights: highlightFieldValues,
                link: this.state.link,
                status: this.props.formType === "CREATE" ? "DRAFT" : this.state.editData?.status,
                loginType: "PRE_LOGIN",
                preLoginManagementType: this.props.type,
                attachmentId: this.state.thumbnailImage?.id,
            }

            if (this.props.formType === "UPDATE") {
                formData.id = this.props.id
            }

            axios({
                method: this.props.formType === "CREATE" ? "POST" : "PUT",
                url: `${process.env.REACT_APP_userServiceURL}/backend/pre/login/management/${this.props.formType === "CREATE" ? "create" : "update"}`,
                data: formData,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }

    }


    render() {
        const { inputFieldValue, link, errors ,thumbnailImage,thumbnailImageMessage} = this.state;
        const { type } = this.props;

        return (
            <div className={`${styles.forumcreatwrap}`}>
                 <UploadFilesData
                    id={`${type === "PROJECT" ? "project" : type === "FUND_RAISE" ? "fundraise" : type === "STUDIO" ? "studio" : "communities"}Thumbnail`}
                    accept=".jpg,.png,.jpeg"
                    label="thumbnail"
                    updateFilesCb={this.updateUploadedFiles}
                    updateImage={thumbnailImage?.attachmentUrl}
                    imagePresent={thumbnailImageMessage ? 'noImage' : ''}
                />

                {thumbnailImageMessage &&
                    <div className="text-center">
                        <small class="form_input_danger__3kp97">{thumbnailImageMessage}</small>
                    </div>
                }

                <div className="form-group row">

                    <div className="col-md-12">
                        <FormInput
                            group={"large"}
                            value={link}
                            label={"Link"}
                            onChange={this.handleChange}
                            name={"link"}
                            error={errors["link"]}
                        />
                    </div>

                    <div className="col-md-12">
                        <h5>Highlight section</h5>
                    </div>


                    {inputFieldValue && inputFieldValue.map((item, index) => {
                        return (
                            <>
                                <div className={`col-md-${index === 0 ? "12" : "10"}`}>
                                    <FormInput
                                        group={"large"}
                                        value={item?.highlight}
                                        label={"Highlights Link"}
                                        name={"highlight"}
                                        onChange={(event) => this.onChangeHighlighs(item.id, event)}
                                        error={item?.errorMessage}
                                    />
                                </div>
                                {index > 0 &&
                                    <div className="col-md-2">
                                        <Button className="iTrashBin" children={"Remove"} style={{ marginTop: "35px" }} onClick={() => { this.removeHighlights(item.id) }} />
                                    </div>
                                }
                            </>
                        )
                    })}
                   {inputFieldValue.length < 2 &&  <div className="col-md-12">
                        <Button children={"Add Highlights"} onClick={this.addHighlights} />
                    </div>}
                </div>

               {auth_service.getPermission(
                    this.props.formType === "CREATE" ? (type === "PROJECT" ? "Pre_Login_Project_Manage_Create" : type === "FUND_RAISE" ? "Pre_Login_Fund_Raise_Create" :type === "STUDIO" ? "Pre_Login_Studio_Create" : "Pre_Login_Community_Create" ) : (type === "PROJECT" ? "Pre_Login_Project_Manage_Update" : type === "FUND_RAISE" ? "Pre_Login_Fund_Raise_Update" :type === "STUDIO" ? "Pre_Login_Studio_Update" : "Pre_Login_Community_Update"), 
                    type === "PROJECT" ? "Pre_Login_Project_Manage_FullAccess" : type === "FUND_RAISE" ? "Pre_Login_Fund_Raise_FullAccess" :type === "STUDIO" ? "Pre_Login_Studio_FullAccess" : "Pre_Login_Community_FullAccess" 
                    ) &&
                    <ButtonWrap className={`${styles.bottombutt}`}>
                        <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.handleFormSubmit} />
                    </ButtonWrap>
                }
            </div>
        )
    }
}

export default CommonManagementForm;