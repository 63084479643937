import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableConstants } from "../data/speaker_tableConstant";
import styles from "../winner.module.css";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

export class SpeakerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speakerData: [],
    };
  }
  // fetch speaker list
  fetchSpeaker = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/peopleOfNature/getAll?type=speaker`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            speakerData: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/peopleOfNature/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchSpeaker();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };
  componentDidMount() {
    this.fetchSpeaker();
  }
  render() {
    const { speakerData } = this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title="Speaker"
              columns={tableConstants(this.fetchSpeaker,this.handleConfirmModal)}
              data={speakerData}
              customStyles={customStyles}
              pagination
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
        {auth_service.getPermission("People_Of_Nature_Speaker_Create", "People_Of_Nature_Speaker_FullAccess") && (
          <Link to="/speaker/create" className={`btn ${styles.createforumbtn}`}>
            <i className="iAddCircle"></i> Create Speaker
          </Link>
        )}
      </CommonHeader>
    );
  }
}

export default SpeakerList;
