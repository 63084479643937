import { epochTogmt } from "../../utils/common";

export const tableConstantsEmailNotificationUserList = () => {
    
    return [
        {
            id: 1,
            name: "Username",
            cell: (row) => {
                return (<a href={`${process.env.REACT_APP_frontEndURL}/profile/${row?.customUrl}`} target="_blank"><span className="sub">{row.username}</span> </a>)
            },
            sortable: true,
        },
        {
            id: 2,
            name: "UserId",
            selector: (row) => row.userId,
            sortable: true,
        },
        {
            id: 3,
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            id: 4,
            name: "Create Time",
            selector: (row) => epochTogmt(row.createTime),
            sortable: true,
        }
    ];
};