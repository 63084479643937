import Button from "./button";
import { CSVLink, CSVDownload } from "react-csv";

const ExportCSV = ({ headers,data, filename, ...props  }) => {
    const epochTogmt = (date) => {
        var newDate = new Date(date).toLocaleDateString("en-US")
        return newDate;
    };
    let date = new Date();
    let dateMs = date.valueOf();
    const file = filename+"-"+epochTogmt(dateMs)+"-"+dateMs+".csv";
    return (
        <CSVLink headers={headers} data={data} filename={file}>
            <Button><i class="iDownloadFile" /> Export</Button>
        </CSVLink>
    )
};

export default ExportCSV;
