import React, { Component } from "react";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "../faq.module.css";
import Select from "react-select";
import { selectionListData } from "../Data/selectionListData";
import { auth_service } from "../../../auth/auth_service";
import stripHtml from "string-strip-html";
import style from "../../../components/InputFields/form_input.module.css";
import history from "../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TagsInput from "react-tagsinput";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
export class EditFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: "",
      answer: "",
      link: "",
      selectedCategory: [],
      faqId: this.props.match.params.id,
      errors: {},
      faqData : [],
      metaKeyword: [],
      canShareFlag: false
    };
  }
  //   question change
  questionChange = (event) => {
    this.setState({
      questions: event.target.value,
    });
  };

  answerChange = (value) => {
    this.setState({
      answer: value,
    });
  };

  handleChangeChk = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  }

  //link change
  linkChange = (event) => {
    this.setState({
      link: event.target.value,
    });
  };
  //   category change
  onCategoryChange = (data) => {
    this.setState(
      {
        selectedCategory: data,
      }
    );
  };

  // handle meta keyword change
  metaKeywordTagChange = (tags) => {
    this.setState({ metaKeyword: tags });
  };

  modules = {
      toolbar: [
          [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ align: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
      ],
      clipboard: {
          matchVisual: false,
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          handleStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      imageCompress: {
          quality: 0.7, // default
          maxWidth: 750, // default
          maxHeight: 500, // default
          imageType: 'image/jpeg' + 'image/png', // default
      },
  };

  // validate faq create
  validate = () => {
    let formIsValid = true;
    let errors = {};
    if (!this.state.questions) {
      formIsValid = false;
      errors["questions"] = "Please enter question";
    }
    if (!this.state.answer || stripHtml(this.state.answer) == '') {
      formIsValid = false;
      errors["answer"] = "Please enter answer";
    }
    if (this.state.link) {
      let valudateLink = this.state.link;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      if(urlValid == null) {
          formIsValid = false;
          errors["link"] = "Please enter a valid link";
      }
    }
    if (
      this.state.selectedCategory === null ||
      this.state.selectedCategory === undefined ||
      this.state.selectedCategory.length === 0
    ) {
      formIsValid = false;
      errors["category"] = "Please select a category";
    }
    if(this.state.canShareFlag && this.state.metaKeyword.length === 0) {
      formIsValid = false;
      errors["metaKeyword"] = "Please enter a keyword";
    }
    this.setState({
      errors: errors,
    });

    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
    return formIsValid;
  };

  // fetch data by role id
  fetchFaqById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/faq/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.status === "200 OK"
        ) {
          let selectedCategoryData = [];
          response.data.body.section &&
            response.data.body.section.map((data, index) => {
              selectedCategoryData.push({ value: data, label: data });
            });
          this.setState({
            questions: response.data.body.question,
            answer: response.data.body.answer,
            selectedCategory: selectedCategoryData,
            link: response.data.body.link,
            canShareFlag: response.data.body.canShare,
            metaKeyword: response.data.body.metaTags,
            faqData : response.data.body
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSubmit = () => {
    if (this.validate()) {
      let selectedCategoryDataList = [];
      this.state.selectedCategory &&
        this.state.selectedCategory.map((data) => {
          selectedCategoryDataList.push(data.value);
        });
      let postData = {
        id: this.state.faqId,
        answer: this.state.answer,
        canShare: this.state.canShareFlag,
        link: this.state.link,
        metaDescription: this.state.answer,
        metaTags: this.state.metaKeyword,
        metaTitle: this.state.questions,
        question: this.state.questions,
        section: selectedCategoryDataList,
        status: this.state.faqData.status
      };
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/backend/faq/update",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  componentDidMount() {
    this.fetchFaqById();
  }
  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }
  render() {
    const { questions, answer, link } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/faq" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <FormInput
                group={"large"}
                value={questions}
                label={"Question"}
                onChange={this.questionChange}
                error={this.state.errors["questions"]}
              />
              <div className={`form-group`}>
                <label>Answer</label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={answer}
                    onChange={this.answerChange}
                    modules={this.modules}
                  />                  
                </div>
                {this.state.errors["answer"] && (
                    <small className={style.danger}>
                      {this.state.errors["answer"]}
                    </small>
                )}
              </div>
              <FormInput
                group={"large"}
                notNull = {"false"}
                value={link}
                label={"Link"}
                onChange={this.linkChange}
                error={this.state.errors["link"]}
              />
              <div className="row">
                <div className="form-group col-md-12">
                  <label>
                    {`Category`} <span className="text-danger">*</span>
                  </label>
                  <Select
                    isMulti
                    value={this.state.selectedCategory}
                    options={selectionListData}
                    onChange={this.onCategoryChange}
                    styles={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        const color = "#cfe7c7";
                        return {
                          ...styles,
                          backgroundColor: isDisabled
                            ? null
                            : isSelected
                            ? "#cfe7c7"
                            : isFocused
                            ? "#cfe7c7"
                            : null,
                          color: isDisabled
                            ? "#ccc"
                            : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                          cursor: isDisabled ? "not-allowed" : "default",

                          ":active": {
                            ...styles[":active"],
                            backgroundColor:
                              !isDisabled &&
                              (isSelected ? "#cfe7c7" : "#cfe7c7"),
                          },
                        };
                      },
                    }}
                  />
                  {this.state.errors["category"] && (
                      <small className={style.danger}>
                        {this.state.errors["category"]}
                      </small>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" ref="canShareFlag" name="canShareFlag" onClick={this.handleChangeChk} checked={this.state.canShareFlag}  />
                    <label className="custom-control-label" htmlFor="canShareFlag"
                        onClick={(e) => {
                            this.refs["canShareFlag"].click();
                        }}>
                        Share
                    </label>
                  </div>
                </div>
              </div>
              {this.state.canShareFlag && 
                <div className={`form-group`}>
                      <label>Meta keywords</label>
                      <TagsInput
                        value={this.state.metaKeyword}
                        onChange={this.metaKeywordTagChange}
                        addKeys={[9, 13, 62, 188]}
                      />
                      {this.state.errors["metaKeyword"] && (
                        <small className={style.danger}>
                          {this.state.errors["metaKeyword"]}
                        </small>
                      )}
                </div>
              }
              {auth_service.getPermission("FAQ_Update", "FAQ_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Update"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditFaq;