import React, { useState } from "react";
import styles from "./button.module.css";

const Button = ({
  children,
  onClick,
  btnColor = "#00394D",
  labelColor,
  disabled,
  type,
  style,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };
  const commonStyles = {
    backgroundColor: btnColor,
    color: labelColor || "white",
  };
  const outlineStyles = {
    border: `1px solid ${btnColor}`,
    color: btnColor,
    backgroundColor: "white",
  };
  const outlineHoverStyle = {
    color: labelColor || "white",
    backgroundColor: btnColor,
  };

  const roundedStyle = {
    backgroundColor: btnColor,
    color: labelColor || "white",
    borderRadius: "25px",
  };
  const disabledStyle = {
    cursor: "default",
    backgroundColor: btnColor,
    color: labelColor || "white",
    opacity: 0.4,
  };
  const blockStyles = {
    width: "95%",
    margin: "0 auto",
  };
  const anchorStyle = {
    cursor: "pointer",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#698F8A",
    textTransform: "uppercase",
    textDecoration: "none",
    backgroundColor: "transparent",
  };

  const rangeStyle = {
    margin: "0",
    padding: "5px 15px",
    borderRadius: "4px",
    marginRight: "5px",
  };
  let btnStyle;
  switch (type) {
    case "rounded":
      btnStyle = roundedStyle;
      break;
    case "block":
      btnStyle = blockStyles;
      break;
    case "outline":
      if (hover) {
        btnStyle = outlineHoverStyle;
      } else {
        btnStyle = outlineStyles;
      }
      break;
    case "anchor":
      btnStyle = anchorStyle;
      break;
    case "range":
      btnStyle = rangeStyle;
      break;
    default:
      btnStyle = {
        backgroundColor: btnColor,
        color: labelColor || "white",
      };
      break;
  }
  return (
    <button
      style={
        disabled
          ? { ...commonStyles, ...btnStyle, ...disabledStyle, ...style }
          : { ...commonStyles, ...btnStyle, ...style }
      }
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...props}
      type="button"
      onClick={!disabled ? onClick : () => {}}
      disabled={props.disabledBtn}
      className={props.dateFilter ? "btn" : props.export ? styles.exportBtn : styles.btnsubmit + " btn"}
    >
      {children || "label"}
    </button>
  );
};

export default Button;
