import axios from "axios";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "./userInfo.module.css";
import DatePicker from "react-datepicker";
import style from "../../../components/InputFields/form_input.module.css";
import "react-datepicker/dist/react-datepicker.css";
import FormInput from "../../../components/InputFields/form_input";
import Button from "../../../components/Buttons/button";
import * as _ from "lodash";
import addDays from 'date-fns/addDays';
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { confirmModalSwal } from "../../../utils/common";
import LicenseUserHistory from "./license_user_history";
import { auth_service } from "../../../auth/auth_service";
import history from "../../../utils/history";

const MySwal = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class IndividualGenerateCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.match.params.userId,
            onWenatMember: "ON_WENAT",
            noOfDays: "",
            communities: "",
            organizations: "",
            startTime: "",
            endTime: "",
            customDate: false,
            codeGenerated: false,
            errors: {},
            editData: {},
            status: "DRAFT",
            content: "Draft",
            showMemberList: false,
            historyData: [],
            showHistoryPop: false,
            userName: props.location?.state?.userName
        };
    }

    onHandleValueChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value.trimStart(),
            });
            if (event.target.name === "noOfDays" && this.state.startTime) {
                this.setState({
                    endTime: addDays(this.state.startTime, event.target.value.trimStart()).getTime()
                })
            }
        }
    };

    onChangeValue(value) {
        this.setState({
            onWenatMember: value
        })
    }

    handleDateChange = (date) => {
        this.setState({
            startTime: date.getTime(),
            endTime: addDays(date, this.state.noOfDays).getTime()
        })
    }

    customDateHandleChange = (event) => {
        this.setState({
            customDate: event.target.checked
        })
    }

    validate = (type) => {
        let errors = {};
        let formIsValid = true;
        if (type === "ENTITY" || type === "ALL") {
            if (!this.state.communities) {
                formIsValid = false;
                errors["communities"] = "Please enter community";
            }
            if (!this.state.organizations) {
                formIsValid = false;
                errors["organizations"] = "Please enter organization";
            }
        }
        if (type === "DATE" || type === "ALL") {
            if (!this.state.startTime) {
                formIsValid = false;
                errors["startTime"] = "Please enter start date";
            }
            if (!this.state.endTime) {
                formIsValid = false;
                errors["endTime"] = "Please enter end date";
            }
            if (!this.state.noOfDays) {
                formIsValid = false;
                errors["noOfDays"] = "Please enter no of days";
            }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);
        }
        return formIsValid;
    };

    generateCode = () => {
        if (this.validate("ALL")) {
            const handleConfirm = async () => {
                const swalval = await MySwal.fire(confirmModalSwal("GENERATE_CODE", this.state.userName));
                if (swalval && swalval.value === true) {
                    let postData = {
                        userId: this.state.userId,
                        memberSignIn: this.state.onWenatMember === "ON_WENAT" ? true : false,
                        communities: this.state.communities,
                        organizations: this.state.organizations,
                        customDate: this.state.customDate,
                        noOfDays: this.state.noOfDays,
                        startTime: this.state.startTime,
                        endTime: this.state.endTime
                    }
                    axios({
                        method: "POST",
                        withCredentials: true,
                        data: postData,
                        url: `${process.env.REACT_APP_userServiceURL}/license/create`,
                    }).then((response) => {
                        if (response && response.data && response.data.message === "Success!") {
                            this.fetchIndividualData();
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            };
            handleConfirm();
        }
    }

    updateDataLicence = (type) => {
        if (this.validate(type)) {
            const donationConfirm = async () => {
                const swalval = await MySwal.fire(confirmModalSwal(type));
                if (swalval && swalval.value === true) {
                    let postData = {
                        licenseId: this.state.editData?.id
                    }
                    if (type === "ENTITY") {
                        postData.circles = this.state.communities;
                        postData.organizations = this.state.organizations;
                    }
                    if (type === "DATE") {
                        postData.startTime = this.state.startTime;
                        postData.endTime = this.state.endTime;
                        postData.noOfDays = this.state.noOfDays;
                    }
                    axios({
                        method: "POST",
                        withCredentials: true,
                        data: postData,
                        url: `${process.env.REACT_APP_userServiceURL}/license/update/${type === "ENTITY" ? "entity" : type === "DATE" ? "date" : "code"}`,
                    }).then((response) => {
                        if (response && response.data && response.data.message === "Success!") {
                            this.fetchIndividualData();
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            };
            donationConfirm();
        }
    }

    fetchIndividualData = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/license/get?userId=${this.state.userId}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body?.length > 0 ? response.data.body[0] : {}
                if (!_.isEmpty(result)) {
                    this.setState({
                        codeGenerated: result?.code,
                        communities: result?.communities,
                        organizations: result?.organizations,
                        startTime: result?.startTime,
                        endTime: result?.endTime,
                        onWenatMember: result?.memberSignIn ? "ON_WENAT" : "NON_WENAT",
                        customDate: result?.createTime ? true : false,
                        noOfDays: result?.noOfDays,
                        editData: result
                    });
                    this.getLicenseHistoryData();
                }
                if (result?.status) {
                    this.getStatusContent(result?.status);
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getLicenseHistoryData = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/license/history/list?userId=${this.state.userId}&page=0&size=5`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    historyData: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getStatusContent = (userStatus) => {
        let content = "";
        let status = "";
        if (userStatus === "DRAFT") {
            content = "Enable";
            status = "ENABLE";
        }
        if (userStatus === "ENABLE") {
            content = "Disable";
            status = "DISABLE";
        }
        if (userStatus === "DISABLE") {
            content = "Enable";
            status = "ENABLE";
        }
        this.setState({
            status: status,
            content: content
        })
    }

    handleUserStatus = () => {
        let postData = {
            licenseId: this.state.editData?.id,
            status: this.state.status
        }
        if(this.state.status === "ENABLE" && this.state.startTime > new Date().getTime()) {
            MySwal.fire({
                didOpen: () => {
                    MySwal.clickConfirm()
                }
              }).then(() => {
                return MySwal.fire(<p>{"Before the starting date, you cannot update status to enable."}</p>)
              })
        } else {
            axios({
                method: "POST",
                withCredentials: true,
                data: postData,
                url: `${process.env.REACT_APP_userServiceURL}/license/update/status`,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.fetchIndividualData();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    onCloseHistoryModal = () => {
        window.$("#licenceHistoryModal").modal("hide");
        this.setState({
            showHistoryPop: false
        })
    }

    componentDidMount() {
        if(auth_service.getUserData().email === "punam@wsegroup.in") {
            this.fetchIndividualData();
        } else {
            history.goBack();
        }
    }

    render() {
        const { startTime, endTime, errors, communities, organizations, onWenatMember, showMemberList, codeGenerated, noOfDays, content, status, editData, userId, historyData, showHistoryPop } = this.state;
        return (
            <CommonHeader>
                {showMemberList && <Redirect to={{ pathname: "/user/individual/licence-member-list", state: { "licenseId": editData?.code, "userId": userId } }} />}
                <div className="topglobal">
                    <Link to="/user/individual" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <div className="form-group">
                                <label>Member SignIn</label>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="custom-control custom-checkbox">
                                            <label className="radio-container" onClick={(e) => this.onChangeValue("ON_WENAT")}>On Wenat
                                                <input type="radio" name="radio" className={onWenatMember === "ON_WENAT" ? "checked" : ''} />
                                                <span className="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custom-control custom-checkbox">
                                            <label className="radio-container" onClick={(e) => this.onChangeValue("NON_WENAT")}>Non Wenat
                                                <input type="radio" name="radio" className={onWenatMember === "NON_WENAT" ? "checked" : ''} />
                                                <span className="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className={`col-md-${codeGenerated ? "4" : "6"}`}>
                                    <FormInput
                                        group={"large"}
                                        value={communities}
                                        label={"Community Allowed"}
                                        onChange={this.onHandleValueChange}
                                        name={"communities"}
                                        maxlength={4}
                                        error={errors["communities"]}
                                    />
                                </div>
                                <div className={`col-md-${codeGenerated ? "4" : "6"}`}>
                                    <FormInput
                                        group={"large"}
                                        value={organizations}
                                        label={"Organizations Allowed"}
                                        onChange={this.onHandleValueChange}
                                        name={"organizations"}
                                        maxlength={4}
                                        error={errors["organizations"]}
                                    />
                                </div>
                                {codeGenerated &&
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ marginTop: "34px" }}>
                                            <Button children={"Update Entity"} onClick={() => this.updateDataLicence("ENTITY")} style={{ marginLeft: "12px" }} />
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6">
                                    <FormInput
                                        group={"large"}
                                        value={noOfDays}
                                        label={"No of Days"}
                                        onChange={this.onHandleValueChange}
                                        name={"noOfDays"}
                                        maxlength={4}
                                        error={errors["noOfDays"]}
                                    />
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className={`col-md-3`}>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" ref="customDate" name="customDate" onClick={this.customDateHandleChange} checked={this.state.customDate} />
                                            <label className="custom-control-label" htmlFor="canShareFlag"
                                                onClick={(e) => {
                                                    this.refs["customDate"].click();
                                                }}>
                                                Custom date
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className={`form-group ${styles.scheduleMailBox}`}>
                                <div className="form-group">
                                    <div className="row">
                                        <div className={`col-md-${codeGenerated ? "4" : "6"}`}>
                                            <label for="">Start Date <span className="text-danger">*</span></label>
                                            <div className="d-flex">
                                                <DatePicker
                                                    className={"form-control custom-input-date"}
                                                    dateFormat={"dd-MMM-yyyy"}
                                                    minDate={new Date()}
                                                    openToDate={new Date()}
                                                    dropdownMode="select"
                                                    placeholderText={"Start Date"}
                                                    selected={startTime}
                                                    onChange={date => {
                                                        this.handleDateChange(date, "startTime")
                                                    }}
                                                    disabled={noOfDays ? false : true}
                                                />
                                            </div>
                                            {this.state.errors["startTime"] && (<small className={style.danger}>{this.state.errors["startTime"]}</small>)}
                                        </div>
                                        <div className={`col-md-${codeGenerated ? "4" : "6"}`}>
                                            <label for="">End Date <span className="text-danger">*</span></label>
                                            <div className="d-flex">
                                                <DatePicker
                                                    className={"form-control custom-input-date"}
                                                    dateFormat={"dd-MMM-yyyy"}
                                                    minDate={new Date(startTime)}
                                                    openToDate={new Date(startTime)}
                                                    dropdownMode="select"
                                                    placeholderText={"End Date"}
                                                    selected={endTime}
                                                    disabled={true}
                                                />
                                            </div>
                                            {this.state.errors["endTime"] && (<small className={style.danger}>{this.state.errors["endTime"]}</small>)}
                                        </div>

                                        {codeGenerated &&
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ marginTop: "34px" }}>
                                                    <Button children={"Update Date"} onClick={() => this.updateDataLicence("DATE")} style={{ marginLeft: "12px" }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {codeGenerated &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            value={codeGenerated}
                                            label={"Generated Code"}
                                            name={"codeGenerated"}
                                            isDisable={true}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ marginTop: "34px" }}>
                                            <Button children={"Update Code"} onClick={() => this.updateDataLicence("CODE")} style={{ marginLeft: "12px" }} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {editData?.memberCount > 0 &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            value={editData?.memberCount}
                                            label={"Member Count"}
                                            name={"memberCount"}
                                            isDisable={true}
                                            className={"not-required"}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ marginTop: "34px" }}>
                                            <Button children={"View Member"} onClick={() => this.setState({ showMemberList: true })} style={{ marginLeft: "12px" }} />
                                        </div>
                                    </div>
                                </div>
                            }


                            <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`} style={{ marginTop: '50px' }}>
                                {historyData?.length > 0 && <Button children={"View History"} onClick={() => this.setState({ showHistoryPop: true }, () => window.$("#licenceHistoryModal").modal("show"))} style={{ marginLeft: "12px" }} />}
                                {!codeGenerated ?
                                    <Button children={"Generate Code"} onClick={this.generateCode} /> :
                                    <>
                                        <div className="contxmenu primntn_dropdown">
                                            <button type="button" className="btn btnsubmit" id="status" name="status" onClick={this.handleUserStatus}>
                                                {content}
                                            </button>
                                            <button type="button" className="btn dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div className="dropdown-menu fadeIn animated">
                                                {<a href="javascript:void(0)" className={status !== "DRAFT" ? "disable" : ""} style={{ pointerEvents: status !== "DRAFT" && "none" }}>Draft</a>}
                                                {<a href="javascript:void(0)" className={status !== "ENABLE" ? "disable" : ""} style={{ pointerEvents: status !== "ENABLE" && "none" }} onClick={this.handleUserStatus}>Enable</a>}
                                                {<a href="javascript:void(0)" className={status !== "DISABLE" ? "disable" : ""} style={{ pointerEvents: status !== "DISABLE" && "none" }} onClick={this.handleUserStatus}>Disable</a>}
                                            </div>
                                        </div>
                                    </>
                                }
                            </ButtonWrap>

                            {showHistoryPop && <LicenseUserHistory userId={this.state.userId} onCloseHistoryModal={this.onCloseHistoryModal} />}

                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default IndividualGenerateCode;
