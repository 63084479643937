import axios from "axios";
import React, { Component } from "react";
import { data } from "../Data/mockData";
import { auth_service } from "../../../../auth/auth_service";
import { tableUpcomingYearConstants } from "../Data/tableUpcomingYearConstants";
import DataTable from "react-data-table-component";
import "./year.module.css";
import { customStyles } from "../../../../utils/tableCustomStyle";
import Export from "../../../../components/Buttons/export_button";
import { downloadCSV } from "../../../../utils/export_csv";
import { FilterComponent } from "../../../../utils/filter";
import Button from "../../../../components/Buttons/button";
import styles from "./year.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../utils/commonHeader";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CSVLink, CSVDownload } from "react-csv";
import ExportCSV from "../../../../components/Buttons/export_csv_button";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const headers = [
  { label: "Year", key: "year" },
  { label: "Staus", key: "status" },
  { label: "Meta Title", key: "metaTitle" },
  { label: "Meta Description", key: "metaDescription" },
  { label: "Meta Keywords", key: "keywords" }, 
];
export class UpcomingYearList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      yearsList: [],
      exportYearList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.yearsList.filter(
              (item) =>
                item.year &&
                item.year
                  .includes(newFilterText)
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by year'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all years list
  fetchAllUpcomingYearsList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_userServiceURL +"/wpondAbout/getList",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          let exportYearList = [];
          let data = response.data.body;
          data && data.map((item, index) => {
            exportYearList.push({"year": item.year, "status": item.status, "metaTitle": item.metaTitle, "metaDescription": item.metaDescription, "keywords": item.keywords})
          })
          this.setState({
            yearsList: response.data.body,
            exportYearList: exportYearList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {/* {this.getSubHeaderComponent()} */}
        {auth_service.getPermission("WPOND_Upcoming_ExportCsv", "WPOND_Upcoming_FullAccess") && <ExportCSV headers={headers} data={this.state.exportYearList} filename="abour-year" />}
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchAllUpcomingYearsList();
  }

  render() {
    this.filteredItems = this.state.yearsList.filter(
      (item) =>
        item.year &&
        item.year
          .includes(this.state.filterText)
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Upcoming Years"
                columns={tableUpcomingYearConstants(
                  this.fetchAllUpcomingYearsList
                )}
                data={this.state.yearsList}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("WPOND_Upcoming_Create", "WPOND_Upcoming_FullAccess") && 
        <Link
          to="/wpond/upcoming-year/create"
          className={`btn ${styles.createforumbtn}`}
        >
          <i className="iAddCircle"></i> Create
        </Link>}
      </CommonHeader>
    );
  }
}

export default UpcomingYearList;
