import React from "react";
import { Link } from "react-router-dom";
import { epochTogmt } from "../../../utils/common";
import styles from "../marketing.module.css";

export const tableConstantsCreditPoints = () => {
    
    return [
        {
            id: 1,
            name: "Name",
            cell: (row) => {
                return (
                    <Link to={`/credit-points/edit/${row?.id}`}>
                        <span className="sub">{row?.username}</span>
                    </Link>
                );
            },
            sortable: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
            grow: 1.5,
        },
        {
            id: 2,
            name: "Email",
            selector: (row) => row?.email,
            sortable: true,
        },
        {
            id: 3,
            name: "Credit allowed",
            selector: (row) => row?.creditAllowed ? "Yes" : "No",
            sortable: true,
        },
        {
            id: 4,
            name: "Valid period",
            selector: (row) => row?.validPeriod && epochTogmt(row?.validPeriod),
            sortable: true,
        },
        {
            id: 6,
            name: "Count of credits",
            cell: (row) => {
            return (
                <Link to={`/credit-points/credit-given/${row?.userId}`}>
                    {/* <span className="sub">{row?.countOfCredits}</span> */}
                    <span className="sub">{row.count}</span>
                </Link>
            );
            },
            sortable: true,
        },
    ];
};