import React from "react";
import { Link } from "react-router-dom";

// This is the table constant/settings which needed to render table elements
export const tableConstants = (handleEdit) => {
  return [
    {
      id: 1,
      name: "Particulars",
      selector: (row) => row.particular,
      sortable: true,
      reorder: true,
      grow: 2,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Total",
      selector: (row) => row.total,
      sortable: true,
      reorder: true,
      style: {
        color: "rgba(0,0,0,.54)",
      },
    },
    {
      id: 3,
      name: "New",
      selector: (row) => row.newCount,
      sortable: true,
      reorder: true,
      style: {
        color: "rgba(0,0,0,.54)",
      },
    },
    {
      id: 4,
      name: "Deleted",
      selector: (row) => row.deleted,
      sortable: true,
      reorder: true,
      style: {
        color: "rgba(0,0,0,.54)",
      },
    },
  ];
};
