import React, { Component } from "react";
import { auth_service } from "../../../../auth/auth_service";
import FormInput from "../../../../components/InputFields/form_input";
import styles from "../CreateGroup/create_group.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
import { CommonHeader } from "../../../../utils/commonHeader";
import Select from "react-select";

import httpService from "../../../../AxiosInterceptors";
import history from "../../../../utils/history";
import style from "../../../../components/InputFields/form_input.module.css";
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;
export class EditGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      groupName: "",
      permissionList: [],
      permissionCategories: [],
      permissionId: this.props.match.params.id,
      permissionStatus: "",
      errors: {},
    };
  }

  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.groupName) {
      formIsValid = false;
      errors["groupName"] = "Please enter permission";
    }
    if (
      this.state.permissionList === null ||
      this.state.permissionList === undefined ||
      this.state.permissionList.length === 0
    ) {
      formIsValid = false;
      errors["permission"] = "Please enter a permission";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //   title change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // handle permission change
  permissionTagChange = (permission) => {
    this.setState({ permissionList: permission });
  };

  fetchPermissionList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminPermission/list?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            permissionCategories:
              response.data.body &&
              response.data.body.map((item, index) => ({
                value: index,
                label: item,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchGroupById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.status === "200 OK"
        ) {
          let permissionData = [];
          response.data.body.permission &&
            response.data.body.permission.map((data, index) => {
              permissionData.push({ value: index, label: data });
            });
          this.setState({
            groupName: response.data.body.name,
            permissionList: permissionData,
            permissionStatus: response.data.body.status,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSubmit = () => {
    if (this.validate()) {
      let permissionDataList = [];
      this.state.permissionList &&
        this.state.permissionList.map((data) => {
          permissionDataList.push(data.label);
        });
      let postData = {
        userId: this.state.userData.id,
        createdBy: this.state.userData.firstName,
        name: this.state.groupName,
        permission: permissionDataList,
        id: this.state.permissionId,
        status: this.state.permissionStatus,
      };
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/adminGroup/edit",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            history.push({ pathname: "/admin-panel/group" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  componentDidMount() {
    this.fetchGroupById();
    this.fetchPermissionList();
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/admin-panel/group" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.groupName}
                    label={"Group Name"}
                    onChange={this.handleChange}
                    name={"groupName"}
                    error={this.state.errors["groupName"]}
                  />
                </div>
              </div>
              <div className={`form-group`}>
                <label className="">Permission</label>
                <Select
                  isMulti
                  options={this.state.permissionCategories}
                  onChange={this.permissionTagChange}
                  placeholder={"Start typing"}
                  value={this.state.permissionList}
                  closeMenuOnSelect={false}
                  isDisabled={true}
                  styles={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = "#cfe7c7";
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                            ? "#cfe7c7"
                            : isFocused
                              ? "#cfe7c7"
                              : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                        },
                      };
                    },
                  }}
                />
                {this.state.errors["permission"] && (
                  <small className={style.danger}>
                    {this.state.errors["permission"]}
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditGroup;
