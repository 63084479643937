import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../../utils/common";
// This is the table constant/settings which needed to render table elements
export const tableConstants = (
  fetchAllDepartmentsList,
  deleteDepartment,
  handleConfirmModal
) => {
  return [
    {
      id: 1,
      name: "Department",
      cell: (row) => {
        return (
          <Link
            to={`/admin-panel/department/edit/${row.id}`}
            style={{ color: "#154A59" }}
          >
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    // {
    //   id: 2,
    //   name: "Role",
    //   cell: (row) => {
    //     let output = [];
    //     let count = 0;
    //     row.role &&
    //       row.role.map((data, index) => {
    //         if (index < 1) {
    //           output.push(data);
    //         } else {
    //           count = count + 1;
    //         }
    //       });
    //     if (count === 0) {
    //       return output;
    //     } else {
    //       output = output + ", +" + count;
    //       return output;
    //     }
    //   },
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 2,
      name: "Created On",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Created By",
      selector: (row) => row.createdBy,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"department"}
            action={fetchAllDepartmentsList}
            fullPermission={"AP_Department_FullAccess"}
            approvalPermission={"AP_Department_Approval"}
            publishPermission={"AP_Department_Publish"}
            enablePermission={"AP_Department_Enable"}
            disablePermission={"AP_Department_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (row.status === 'ENABLE' ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
