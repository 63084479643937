// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text_area_danger__JmiSv {\n  color: red;\n}\n\n.text_area_formControl__3wT7l {\n  color: #616f77;\n  outline: 0;\n  vertical-align: top;\n  background-color: #fff;\n  filter: none !important;\n  box-shadow: none;\n  border-radius: 2px;\n  border: 1px solid #dbe0e2;\n  transition: all 0.2s linear;\n  display: block;\n  margin-bottom: 9px;\n  width: 100%;\n  padding: 6px 12px;\n  font-size: 14px;\n  line-height: 1.42857143;\n}\n", "",{"version":3,"sources":["webpack://src/components/TextArea/text_area.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,UAAU;EACV,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".danger {\n  color: red;\n}\n\n.formControl {\n  color: #616f77;\n  outline: 0;\n  vertical-align: top;\n  background-color: #fff;\n  filter: none !important;\n  box-shadow: none;\n  border-radius: 2px;\n  border: 1px solid #dbe0e2;\n  transition: all 0.2s linear;\n  display: block;\n  margin-bottom: 9px;\n  width: 100%;\n  padding: 6px 12px;\n  font-size: 14px;\n  line-height: 1.42857143;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"danger": "text_area_danger__JmiSv",
	"formControl": "text_area_formControl__3wT7l"
};
export default ___CSS_LOADER_EXPORT___;
