import React, { Component } from 'react'
import CommonManagementList from '../CommonManagementList';
import { CommonHeader } from '../../../../utils/commonHeader';
import { Link } from 'react-router-dom';
import styles from "../BelongHere/pre-login.module.css";

class StudioList extends Component {
    render() {
        return (
            <CommonHeader>
                <CommonManagementList type={"STUDIO"} />
                <Link to={`/pre-login/studio/create`} className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Create
                </Link>
            </CommonHeader>
        )
    }
}

export default StudioList;
