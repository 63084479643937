import React from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";
import StatusDetails from "../../../components/StatusDetails/status_details";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import { epochTogmt, epochTogmtDateTime } from "../../../utils/common";
import styles from "../marketing.module.css";

// This is the table constant/settings which needed to render table elements
export const tableConstantsEmail = (updateNotificationData, deleteNotificationHandle, stopNotificationHandle) => {
    return [
        {
            id: 1,
            name: "Subject",
            cell: (row) => {
                return (
                  <Link to={{ pathname: `/email/edit/${row.id}`, state: {disabledUpdate: true}}}>
                    <span className="sub">{row.subject}</span>
                  </Link>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "Execution Date Time",
            selector: (row) => row.executionTime === 0 ? "NA" : epochTogmtDateTime(row.executionTime),
            sortable: true,
            reorder: true,
            //grow: .2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
          id: 8,
          name: "Create Date",
          selector: (row) => row.createTime === 0 ? "NA" : epochTogmt(row.createTime),
          sortable: true,
          reorder: true,
          //grow: .2,
          style: {
              color: "rgba(0,0,0,.54)",
          },
        },
        {
          id: 8,
          name: "Criteria",
          selector: (row) => row.notifierCriteriaType,
          sortable: true,
          reorder: true,
          //grow: .2,
          style: {
              color: "rgba(0,0,0,.54)",
          },
        },
        {
          id: 3,
          name: "Status",
          cell: (row) => {
              return (
                <>
                  {row.status}
                  {row.status === 'COMPLETE' && <a href="javascript:void(0)" className={`btn ${styles.btnCreateNoti}`}>Create</a>}
                </>
              );
            },
          sortable: true,
          reorder: true,
          //grow: .2,
      },
      {
        id: 4,
        name: "Email Status",
        //grow: .2,
        cell: (row) => {
          return (
            <>
              <StatusDetails status={row.notifierStatus}/>
            </>
          );
        },
        sortable: true,
        reorder: true,
      },
        {
          id: 6,
          name: "Action",
          cell: (row) => {
            let content = "";
            let status = "";
            if (row.notifierStatus === "DRAFT") {
              content = "Approve";
              status = "PENDING_APPROVAL";
            }
            if (row.notifierStatus === "PENDING_APPROVAL") {
              content = "Publish";
              status = "PUBLISH";
            }
            if (row.notifierStatus === "PUBLISH") {
              content = "Enable";
              status = "ENABLE";
            }
            if (row.notifierStatus === "ENABLE") {
              content = "Disable";
              status = "DISABLE";
            }
            if (row.notifierStatus === "DISABLE") {
              content = "Enable";
              status = "ENABLE";
            }
            return (
              <StatusDrop
                children={content}
                id={row.id}
                status={status}
                type={"email"}
                action={() => updateNotificationData(row.id, status)}
                fullPermission={"Email_FullAccess"}
                approvalPermission={"Email_Approval"}
                publishPermission={"Email_Publish"}
                enablePermission={"Email_Enable"}
                disablePermission={"Email_Disable"}
              />
            );
          },
          sortable: true,
          reorder: true,
          style: {
            color: "#202124",
            fontSize: "14px",
            fontWeight: 500,
          },
        },
        {
          id: 5,
          name: "",
          width: '290px',
          cell: (row) => {
            return (
              <>
                {row.status === "EXECUTED" && <Link to={`/email/viewer/${row.id}`} className={`btn ${styles.btnEmailView}`}>View</Link>}
                {(row.notifierStatus !== 'ENABLE' && row.notifierStatus !== 'DISABLE') && auth_service.getPermission("Email_Update", "Email_FullAccess") && <Link to={`/email/edit/${row.id}`} className={`btn ${styles.edtbtn}`}><i class="iEditBox"></i> Edit</Link>}
                {row.status === 'WAITING' && row.notifierStatus === 'ENABLE' && <a href="javascript:void(0)" className="btn btnUpdte" onClick={(e) => stopNotificationHandle(row.id, "DISABLE")}>Stop</a>}
                {row.notifierStatus === 'DISABLE' && <a href="javascript:void(0)" className="btn btnUpdte" onClick={(e) => deleteNotificationHandle(row.id)}>Delete</a>}
              </>
            );
          },
          sortable: true,
          reorder: true,
      },
    ];
};
