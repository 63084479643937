import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { epochTogmt, trimDescription } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";
// This is the table constant/settings which needed to render table elements
export const tableConstants = (fetchAllCausesList, deleteCause, handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Cause Title",
      cell: (row) => {
        return (
          <Link to={`/causes/edit/${row.id}`} style={{ color: "#154A59" }}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Description",
      selector: (row) => trimDescription(row.content ? row.content : ""),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "No of Supporters",
      selector: (row) => row.noOfSupporter,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Created On",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "No of posts",
      selector: (row) => row.noOfPosts,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Primary hashtags",
      selector: (row) => row.primaryHashtags,
      cell: (row) => {
        let output = [];
        let count = 0;
        row.hashtags &&
          row.hashtags.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Link for blogs",
      selector: (row) => row.linkIds,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={'causes'}
            action={fetchAllCausesList}
            fullPermission={"Causes_FullAccess"}
            approvalPermission={"Causes_Approval"}
            publishPermission={"Causes_Publish"}
            enablePermission={"Causes_Enable"}
            disablePermission={"Causes_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("Causes_Delete", "Causes_FullAccess") ||row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
    // {
    //   id: 10,
    //   name: "Delete",
    //   cell: (row) => {
    //     if(row.status === 'DRAFT' || row.status === 'PENDING_APPROVAL'){
    //       return (
    //         <a href="javascript:void(0)" onClick={()=>deleteCause(row.id)} style={{ color: "#154A59" }} >
    //         <span className="sub">Delete</span>
    //       </a>
    //       );
    //     }  else
    //     {  
    //       return (
    //         <a href="javascript:void(0)" style={{ color: "#154A59" }} >
    //         <span className="sub">Delete</span>
    //       </a>
    //       );
    //     }
    //   },
    //   sortable: true,
    //   reorder: true,
    // },
  ];
};
