import axios from "axios";
import React, { Component } from "react";
import { serverScriptData } from "./Data/ServerScriptData";
import { auth_service } from "../../../auth/auth_service";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import { CommonHeader } from "../../../utils/commonHeader";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to run this script?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};
export class ServerScript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      scriptType: ""
    };
  }

  serverRunConfirmModal = (scriptType) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        this.setState({
          scriptType: scriptType
        })
        let postBody = {
          type: scriptType
        }
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/recommend/serverscript/`,
          data: postBody,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === 200) {
              this.setState({
                scriptType: ""
              })
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              scriptType: ""
            })
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    
  }

  render() {
    const {scriptType} =this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Server Script"
                columns={tableConstants(this.serverRunConfirmModal, scriptType)}
                data={serverScriptData}
                customStyles={customStyles}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default ServerScript;
