import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../../../components/StatusDetails/status_details";
import stripHtml from "string-strip-html";

export const tableJuriesConstants = (fetchAllJuriesList) => {
  return [
    {
      id: 1,
      name: "Image",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/juries/edit/${row.id}`}>
            <img
              src={row.image}
              alt="coverImage"
              style={{ width: "100px", height: "56px" }}
            />
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Location",
      cell: (row) => {
        return(
          <span className="sub">{row.location.country}, {row.location.state}, {row.location.district}</span>
        )
      },
      sortable: true,
      reorder: true,
    },
    // {
    //   id: 4,
    //   name: "Description",
    //   selector: (row) => stripHtml(row.description),
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 5,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"wpondAboutJuries"}
            action={fetchAllJuriesList}
            fullPermission={"WPOND_Juries_FullAccess"}
            approvalPermission={"WPOND_Juries_Approval"}
            publishPermission={"WPOND_Juries_Publish"}
            enablePermission={"WPOND_Juries_Enable"}
            disablePermission={"WPOND_Juries_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  ];
};
