import React from "react";
import { epochTogmt } from "../../../../utils/common";
import { auth_service } from "../../../../auth/auth_service";

export const tableConstants = (handleConfirmModal, handleRestrictFundRaise) => {
  return [
    {
      id: 1,
      name: "Circle Name",
      cell: (row) => {
        return (<a href={`${process.env.REACT_APP_frontEndURL}/circle/${row?.slug}`} target="_blank"><span className="sub">{row.circleName}</span> </a>)
      },

      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 18,
      name: "Restrict Fund Raise",
      cell: (row) => {
        return (!row.deactivated && auth_service.getUserData().email === "punam@wsegroup.in") ? <div className="form-group">
          <label class="switch">
            <input
                type="checkbox"
                name="restrictFundRaise"
                className="restrictFundRaise"
                onClick={(e) => {handleRestrictFundRaise(row.id, row.fundRaiseRestrictionPermission);}}
                checked={row.fundRaiseRestrictionPermission}
            />
            <span class="slider round"></span>
          </label>
      </div> : null
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "No of Admins",
      selector: (row) => row.adminids.length,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "No of Members",
      selector: (row) => row.memberCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "No of Followers",
      selector: (row) => row.followerCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "No of Projects",
      selector: (row) => row.projectCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "No of Posts",
      selector: (row) => row.posts,
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Status",
      cell: (row) => {
        if(row.active) {
          return "Active"
        } else {
          return "Inactive"
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 11,
      name: "Delete Status",
      cell: (row) => {
        if(row.deactivated) {
          return "Deleted"
        } else {
          return "NA"
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 12,
      name: "Created on",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 13,
      name: "Action",
      cell: (row) => {
        return (row.deactivated ? 
          <button  className={ "btnUpdte"} onClick={(e) => {handleConfirmModal(row.id);}}  disabled={false}>
            Reactive
          </button> : 
          null);
      },
      sortable: true,
      reorder: true,
    },
  ];
};
