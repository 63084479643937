import { Link } from "react-router-dom";
import { epochTogmt, trimDescription } from "../../../../utils/common";
import { getSubscriptionType } from "../../CommonSubscriptionFunction";

export const tableCircleConstants = () => {


  return [
    {
      id: 1,
      name: "Description",
      cell: (row) => {
        return (
          <Link to={`/subscription/circle/edit/${row.id}`}>
            <span className="sub">{getSubscriptionType(row.type)}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Create your Circle",
      selector: (row) => row.create,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Multiple admins",
      selector: (row) => row.multipleAdmins,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Invite participants outside WeNat",
      selector: (row) => row.inviteParticipantsOutsideWN,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Members",
      selector: (row) => row.members,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Membership request control",
      cell: (row) => {
        if (row.memberShipRequestControl) {
          return 'YES';
        } else {
          return 'NO';
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Membership Subscription",
      cell: (row) => {
        if (row.memberShipSubscription) {
          return 'YES';
        } else {
          return 'NO';
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Created On",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
  ];
};
