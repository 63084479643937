import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../../../../../components/StatusDetails/status_details";
import stripHtml from "string-strip-html";

export const tableSpeakerConstants = (fetchAllSpeakersList) => {
  return [
    {
      id: 1,
      name: "Image",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/upcoming-event/${row.eventSlug}/speaker/edit/${row.id}`}>
            <img
              src={row.coverImageUrl}
              alt="coverImage"
              style={{ width: "100px", height: "56px" }}
            />
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Speaker Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Website",
      cell: (row) => {
        return (
          <>{row.website && <a href={row.website} class="link" target="_blank"><i class="iExport"></i></a>}</>
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Social Media",
      selector: (row) => row.timeZoneTwo,
      cell: (row) => {
        return (
          <>
            {row.facebook && 
              <><a href={row.facebook} target="_blank"><i class="iSocial_FB"></i></a>&nbsp;</>
            }
            {row.twitter && 
              <><a href={row.twitter} target="_blank"><i class="iSocial_Twitter"></i></a>&nbsp;</>
            }
            {row.instagram && 
              <><a href={row.instagram} target="_blank"><i class="iSocial_Instagram"></i></a>&nbsp;</>
            }
            {row.youTube && 
              <><a href={row.youTube} target="_blank"><i class="iYouTube_Logo"></i></a>&nbsp;</>
            }
          </>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Content",
      selector: (row) => stripHtml(row.content),
      format: row => `${row.content && stripHtml(row.content).slice(0, 380)}...`,
      wrap: true,
      sortable: true,
      reorder: true,
      grow: 5,
    },
    {
      id: 6,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"wpondAboutEventSpeaker"}
            action={fetchAllSpeakersList}
            fullPermission={"WPOND_EventSpeaker_FullAccess"}
            approvalPermission={"WPOND_EventSpeaker_Approval"}
            publishPermission={"WPOND_EventSpeaker_Publish"}
            enablePermission={"WPOND_EventSpeaker_Enable"}
            disablePermission={"WPOND_EventSpeaker_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  ];
};
