import axios from "axios";
import React, { Component } from "react";
import { tableStudioConstants } from "../Data/tableStudioConstants";
import { auth_service } from "../../../auth/auth_service";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "../../account_details/individual/individual_details.module.css";
import Select from "react-select";


const typeOptions = [
  { value: "ALL", label: "ALL" },
  { value: "DELETED", label: "DELETED" },
];
export class StudioSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      studioDataList: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      typeData: { value: "ALL", label: "ALL" },
    };
  }

  fetchStudioDetailsData = async page => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/paidContentPost/getList?page=${page}&size=${this.state.perPage}&type=${this.state.typeData.value}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            studioDataList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          studioDataList: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchStudioDetailsData(page - 1);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/paidContentPost/getList?page=${page-1}&size=${newPerPage}&type=${this.state.typeData.value}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            studioDataList: response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          studioDataList: [],
          loading: false
        })
      });
  };

  conditionalRowStyles = [
    {
      when: row => row.flagged,
      style: {
        backgroundColor: 'red',
      },
    },
  ];

  handleTypeChange = (type) => {
    this.setState({
      typeData: { value: type.value, label: type.value },
    }, () => {
      this.fetchStudioDetailsData(0);
    })
  }

  componentDidMount() {
    this.fetchStudioDetailsData(0);
  }

  render() {
    const {studioDataList} = this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                    {/* <div className="col-md-3">
                      <Select
                        options={typeOptions}
                        placeholder={"Filter by type"}
                        value={this.state.typeData}
                        onChange={this.handleTypeChange}
                        styles={{
                          option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                          ) => {
                            const color = "#cfe7c7";
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                  ? "#cfe7c7"
                                  : isFocused
                                    ? "#cfe7c7"
                                    : null,
                              color: isDisabled
                                ? "#ccc"
                                : isSelected
                                  ? color > 2
                                    ? "#00374d"
                                    : "#00374d"
                                  : data.color,
                              cursor: isDisabled ? "not-allowed" : "default",

                              ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                  !isDisabled &&
                                  (isSelected ? "#cfe7c7" : "#cfe7c7"),
                              },
                            };
                          },
                        }}
                      />
                    </div> */}
                  </div>
                </div>
            </div>
            <DataTable
              title="Studio Summary"
              columns={tableStudioConstants()}
              data={studioDataList}
              customStyles={customStyles}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 216px)"}
              pagination
              highlightOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              //conditionalRowStyles={this.conditionalRowStyles}
            />

          </div>
        </div>

      </CommonHeader>
    );
  }
}

export default StudioSummary;
