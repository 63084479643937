const CreditStatusDetails = ({ status, creditStatus,statusType, ...props }) => {

    return (
      <>
        {statusType === "CREDIT_STATUS" ?
            <div>
                {creditStatus === "USED" && 
                  <span>Used</span>
                }
                {creditStatus === "EXPIRED" && 
                  <span>Expired</span>
                }
                {creditStatus === "VALID" && 
                  <span>Valid</span>
                }
            </div>
            :
            <div>
                {status === "DRAFT" && 
                  <span>Draft</span>
                }
                {status === "ENABLE" && 
                  <span>Enabled</span>
                }
                {status === "DISABLE" && 
                  <span>Disabled</span>
                }
            </div>
        }

      </>
    );
  };
  
  export default CreditStatusDetails;
  