import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { auth_service } from "../../auth/auth_service";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import { customStyles } from "../../utils/tableCustomStyle";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import Export from "../../components/Buttons/export_button";
import { downloadCSV } from "../../utils/export_csv";
import { FilterComponent } from "../../utils/filter";
import styles from "./faq.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};


const options = [
  { value: "name", label: "Filter By Name" },
  { value: "status", label: "Filter By Name" },
  { value: "name", label: "Filter By Name" },
];

export class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      faqList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.faqList.filter(
              (item) =>
                item.question &&
                item.question.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };


  // fetch faq list
  fetchAllFaqList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_userServiceURL +
        "/backend/faq/list?status=undefined&createdBy=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined",
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            faqList: response.data.body.faqList,
          });
        }
      })
      .catch((error) => {
        console.log("sdfdsfdsfdsf", error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("FAQ_ExportCSV", "FAQ_FullAccess") && <Export onExport={() => downloadCSV(this.state.faqList, "faq")} />}
      </React.Fragment>
    );
  };
  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/faq/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchAllFaqList();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };
  componentDidMount() {
    this.fetchAllFaqList();
  }
  render() {
    this.filteredItems = this.state.faqList.filter(
      (item) =>
        item.question &&
        item.question.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title="Faq"
              columns={tableConstants(this.fetchAllFaqList,this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
        {auth_service.getPermission("FAQ_Create", "FAQ_FullAccess") && (
        <Link to="/faq/create" className={`btn ${styles.createforumbtn}`}>
          <i className="iAddCircle"></i> Create Faq
        </Link>
        )}
      </CommonHeader>
    );
  }
}

export default Faq;
