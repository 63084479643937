import React, { Component } from 'react'
import { CommonHeader } from '../../utils/commonHeader';
import { tableConstantsFeedbackList } from './tableConstantsFeedbackList';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utils/tableCustomStyle';
import axios from 'axios';
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(alertPopUp);

const confirmModalSwal = (isHideFeedback) => ({
    title: `<h5>Are you sure you want to ${isHideFeedback === "HIDE" ? "hide" : "Ignore" } this Feedback?</h5>`,
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
});

class FeedbackList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedBackList: [],
            loading: false,
            totalRows: 0,
            perPage: 10, 
        }
    }

    componentDidMount(){
        this.getFeedbackList(0);
    };

    getFeedbackList = async (page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/feedback/getFlaggedFeedbacks?entityType=${this.props.pageType === "PROJECT_FEEDBACK" ? "PROJECT" : "GLOBAL"}&page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let pageData = response?.data?.body?.content
                this.setState({
                    feedBackList: pageData,
                    totalRows: response.data.body?.totalElements,
                    loading: false
                });
            }
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    handlePageChange = page => {
        this.getFeedbackList(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.getFeedbackList(page-1);
        })
    };

    handleConfirmModal = (id,isHideFeedback) => {
        const handleConfirm = async () => {
          const swalval = await MySwal.fire(confirmModalSwal(isHideFeedback));
          if (swalval && swalval.value === true) {
            axios({
              method: "POST",
              url: `${process.env.REACT_APP_userServiceURL}/backend/feedback/archivedFeedbacks?id=${id}&archive=${isHideFeedback === "HIDE" ? true : false}`,
              withCredentials: true,
            })
              .then((response) => {
                if (response && response.data && response.data.status === "202 ACCEPTED") {
                    let updatedFeedBackList = this.state.feedBackList;
                    let index = updatedFeedBackList && updatedFeedBackList.findIndex(item => item?.id === id);
                    if(index > -1){
                        updatedFeedBackList[index].status = isHideFeedback === "HIDE" ? "Hidden" : "Ignored"
                    }
                    this.setState({
                        feedBackList: updatedFeedBackList
                    })
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        handleConfirm();
      };

    render() {
        const {feedBackList, size, loading, totalRows} = this.state;
        const {pageType} = this.props

        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="marketingTable">
                                <DataTable
                                    title={pageType ===  "PROJECT_FEEDBACK" ? " Project Feedback List" : "Global Feedback List"}
                                    columns={tableConstantsFeedbackList(this.handleConfirmModal, pageType)}
                                    data={feedBackList}
                                    customStyles={customStyles}
                                    // actions={this.actionButton()}                
                                    fixedHeader
                                    fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                                    pagination
                                    paginationPerPage={size}
                                    pointerOnHover
                                    progressPending={loading}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                    onChangePage={this.handlePageChange}
                                />
                        </div>
        
                    </div>
                </div>
               
            </CommonHeader>
        )
    }
}

export default FeedbackList;