import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../auth/auth_service";
import { tableUserBadgeConfigListConstants } from "./tableUserBadgeConfigListConstants";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import { CommonHeader } from "../../../utils/commonHeader";
import { Link } from "react-router-dom";
import styles from "./config_list.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ErorrMsgPopUp = withReactContent(alertPopUp)

let confirmModalSwal = {
    title: '<h5>Are you sure you want to assign badge manually?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
};
export class UserBadgeConfigList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            badgeConfigList: [],
            filterText: "",
            filterDisplay: false,
            loading: false,
            totalRows: 0,
            perPage: 10,
        };
    }

    fetchAllUserBadgeList = (page) => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/backend/user/badge/list?page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response.data.message === "Success!") {
                this.setState({
                    badgeConfigList: response.data?.body?.content,
                    loading: false,
                    totalRows: response?.data?.body?.totalElements
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    actionButton = () => {
        return (
            <React.Fragment>
                <button className="btn addRoleGlobal" onClick={() => this.handleConfirm()}>Assign Badge Manually</button>
            </React.Fragment>
        );
    };

    handleConfirm = async () => {
        const swalval = await ErorrMsgPopUp.fire(confirmModalSwal);
        if (swalval && swalval.value === true) {
            axios({
                method: "GET",
                url: `${process.env.REACT_APP_userServiceURL}/backend/badge/refresh`,
                withCredentials: true,
            }).then((response) => {
                if (response?.data?.message === "Success!") {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{"Updated successfully"}</p>)
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };

    handlePageChange = (page) => {
        this.fetchAllUserBadgeList(page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllUserBadgeList(page - 1);
        })
    };

    componentDidMount() {
        this.fetchAllUserBadgeList(0);
    }

    render() {
        const { badgeConfigList, totalRows, loading, perPage } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large">
                            <DataTable
                                title="User Badge Config List"
                                columns={tableUserBadgeConfigListConstants()}
                                data={badgeConfigList}
                                customStyles={customStyles}
                                actions={process.env.REACT_APP_environment !== "production" ? this.actionButton() : null}
                                pagination
                                highlightOnHover
                                pointerOnHover
                                paginationPerPage={perPage}
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
                <Link to="/user-badge-config/create" className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Create Config
                </Link>
            </CommonHeader>
        );
    }
}

export default UserBadgeConfigList;