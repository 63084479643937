import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableuserConstants } from "../Data/userTableConstants";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FilterComponent } from "../../../utils/filter";
import { CSVLink } from "react-csv";
import { epochTogmt } from "../../../utils/common";


const MySwal = withReactContent(alertPopUp);

let reactivateModalSwal = {
  title: '<h5>Are you sure you want to Reactivate this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

let deactivateModalSwal = {
  title: '<h5>Are you sure you want to Deactivate this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

let ignoreModalSwal = {
  title: '<h5>Are you sure you want to Ignore this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "Username", key: "userName" },
  { label: "System Generated", key: "systemGenerated"},
  { label: "Persona", key: "persona" },
  { label: "Address", key: "addressDetail" },
  { label: "Number of Reports", key: "reportCount" },
  { label: "Link", key: "link" },
  { label: "Reported on", key: "reportedTime" },
  { label: "Deactivated Time", key: "deactivatedTime" },
  { label: "Last action taken by", key: "adminName" }
];

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      userList: [],
      filterText: "",
      loading: false,
      totalRows: 0,
      perPage: 10,
      exportDataList: [],
      fileName: ""
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.userList.filter(
              (item) =>
                item.userName &&
                item.userName.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by user name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />

      </React.Fragment>
    );
  };

  // fetch report userlist
  fetchUserList = (page, size) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/reportAbuse/userList?createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&page=${page}&size=${size}`,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK" && response.data.body) {
          this.setState({
            userList: response.data.body && response.data.body.content,
            totalRows: response.data.body?.totalElements,
            perPage: size,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          userList: [],
          loading: false
        });
      });
  };

  handleConfirmModal = (id, content) => {
    const handleConfirm = async () => {
      let swalval = await MySwal.fire(content === "REACTIVATE" ? reactivateModalSwal : content === "DEACTIVATE" ? deactivateModalSwal : ignoreModalSwal);
      let url = "";
      if (content === "REACTIVATE") {
        url = `backend/deactivation/reactivateProfile?id=${id}&adminId=${this.state.userData.id}`;
      } else if (content === "DEACTIVATE") {
        url = `backend/deactivation/deactivateProfile?id=${id}&adminId=${this.state.userData.id}`;
      } else {
        url = `backend/reportabuse/ignore`;
      }
      let data = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/${url}`,
          data: data,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              let userItemList = this.state.userList;
              const index = userItemList && userItemList.findIndex(data => data.id === id);
              if (index > -1) {
                if (content === "IGNORE") {
                  userItemList.splice(index, 1);
                } else {
                  userItemList[index].deactivated = (content === "REACTIVATE" ? false : true);
                  userItemList[index].adminName = this.state.userData.firstName;
                }
              }
              this.setState({
                userList: userItemList
              });
              //this.fetchUserList();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error && error.response && error.response.data && error.response.data.status === '409 CONFLICT' && content === "DEACTIVATE") {
              MySwal.fire({
                didOpen: () => {
                  MySwal.clickConfirm();
                }
              }).then((result) => {
                return MySwal.fire({
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  title: <><p>{error.response.data.message}</p></>,
                })
              })
            }
          });
      }
    };

    handleConfirm();
  };

  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={() => this.exportData()}><i class="iDownloadFile" /> Export</a>
      </React.Fragment>
    );
  };

  handlePageChange = page => {
    this.fetchUserList(page - 1, this.state.perPage);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.fetchUserList(page - 1, newPerPage);
  };

  exportData = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/reportAbuse/userList?createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&page=0&size=1000`,
    }).then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          let exportDataList = [];
          let data = response.data.body && response.data.body.content;
          let date = new Date();
          let dateMs = date.valueOf();
          const file = "user_report_list-" + epochTogmt(dateMs) + "-" + dateMs + ".csv";
          data && data.map((item) => {
            exportDataList.push({ "userName": item.userName, "systemGenerated": item.systemGenerated ? "Yes" : "No", 
              "persona": item.persona, "addressDetail": item.addressDetail, "reportCount": item.reportCount, 
              "reportedTime": epochTogmt(item.reportedTime), "deactivatedTime": item.deactivatedTime !== 0 ? epochTogmt(item?.deactivatedTime) : "-", 
              "adminName": item.adminName, "link": !item.mywenat ? `${process.env.REACT_APP_frontEndURL}` : `${process.env.REACT_APP_myWenatUrl}/profile/${item.customUrl}` })
          })
          this.setState({
            exportDataList: exportDataList,
            fileName: file
          }, () => {
            this.csvLink.link.click();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          exportDataList: []
        });
      });
  }

  componentDidMount() {
    this.fetchUserList(0, this.state.perPage);
  }

  render() {
    const { userList } = this.state;
    this.filteredItems = this.state.userList.filter((item) => item.userName && item.userName.toLowerCase().includes(this.state.filterText.toLowerCase()));
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title={"User Report"}
              columns={tableuserConstants(this.state.userData, this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={10}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
        </div>

        <CSVLink headers={headers} data={this.state.exportDataList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

      </CommonHeader>
    );
  }
}

export default User;
