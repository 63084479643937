import axios from "axios";
import React, { Component } from "react";
import { tableViewClickConstants } from "../Data/tableViewClickConstants";
import { auth_service } from "../../../auth/auth_service";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import { CommonHeader } from "../../../utils/commonHeader";
import { Link } from "react-router-dom";


export class StudioViewClickList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      studioUserDataList: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      actionType: this.props.match.params.actionType
    };
  }

  capitalizeFirstLetter = (text) => {
    return text && text[0].toUpperCase() + text.slice(1).toLowerCase();
  };

  fetchStudioUserDetailsData = async page => {
    this.setState({
      loading: true
    })
    let url = `${process.env.REACT_APP_userServiceURL}/studioPayment/getPaidUsers/${this.props.match.params.studioId}?userId=${this.state.userData.id}&page=${page}&size=${this.state.perPage}`;
    if(this.state.actionType === "view" || this.state.actionType === "click") {
        url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUsers/${this.props.match.params.studioId}?userId=${this.state.userData.id}&actionType=${this.state.actionType.toUpperCase()}&page=${page}&size=${this.state.perPage}`;
    }
    axios({
      method: "GET",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            studioUserDataList: response.data.body && response.data.body.content,
            totalRows: response.data.body?.page?.totalElements,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          studioUserDataList: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchStudioUserDetailsData(page - 1);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true
    })
    let url = `${process.env.REACT_APP_userServiceURL}/studioPayment/getPaidUsers/${this.props.match.params.studioId}?userId=${this.state.userData.id}&page=${page-1}&size=${newPerPage}`;
    if(this.state.actionType === "view" || this.state.actionType === "click") {
        url = `${process.env.REACT_APP_userServiceURL}/paidContentPost/getUsers/${this.props.match.params.studioId}?userId=${this.state.userData.id}&actionType=${this.state.actionType.toUpperCase()}&page=${page-1}&size=${newPerPage}`;
    }
    axios({
      method: "GET",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            studioUserDataList: response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          studioUserDataList: [],
          loading: false
        })
      });
  };

  componentDidMount() {
    if((auth_service.getPermission("Studio_List_ViewsCount") && this.state.actionType === "view") || (auth_service.getPermission("Studio_List_ClicksCount") && this.state.actionType === "click") || (auth_service.getPermission("Studio_List_PaidCount") && this.state.actionType === "paidview") || (auth_service.getPermission("Studio_List_Subscribers") && this.state.actionType === "subscriber")) {
      this.fetchStudioUserDetailsData(0);
    } else {
      window.location.href = "/no-permission";
    }
  }

  render() {
    const {studioUserDataList, actionType, totalRows, loading} = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/studio/list" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title={`Studio ${actionType !== "paidview" ? this.capitalizeFirstLetter(actionType) : "Paid View"}s`}
              columns={tableViewClickConstants()}
              data={studioUserDataList}
              customStyles={customStyles}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 216px)"}
              pagination
              highlightOnHover
              progressPending={loading}
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />

          </div>
        </div>

      </CommonHeader>
    );
  }
}

export default StudioViewClickList;