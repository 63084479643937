// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_input_danger__3kp97{\n    color: red;\n}\n\n.form_input_formControl__meubr{\n    color: #616f77;\n    outline: 0;\n    vertical-align: top;\n    background-color: #fff;\n    filter: none !important;\n    box-shadow: none;\n    border-radius: 2px;\n    border: 1px solid #dbe0e2;\n    transition: all 0.2s linear;\n    display: block;\n    margin-bottom: 9px;\n    width: 100%;\n    height: 34px;\n    padding: 6px 12px;\n    font-size: 14px;\n    line-height: 1.42857143;\n}", "",{"version":3,"sources":["webpack://src/components/InputFields/form_input.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;IACd,UAAU;IACV,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,2BAA2B;IAC3B,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".danger{\n    color: red;\n}\n\n.formControl{\n    color: #616f77;\n    outline: 0;\n    vertical-align: top;\n    background-color: #fff;\n    filter: none !important;\n    box-shadow: none;\n    border-radius: 2px;\n    border: 1px solid #dbe0e2;\n    transition: all 0.2s linear;\n    display: block;\n    margin-bottom: 9px;\n    width: 100%;\n    height: 34px;\n    padding: 6px 12px;\n    font-size: 14px;\n    line-height: 1.42857143;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"danger": "form_input_danger__3kp97",
	"formControl": "form_input_formControl__meubr"
};
export default ___CSS_LOADER_EXPORT___;
