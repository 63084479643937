import React from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../../../auth/auth_service";

// This is the table constant/settings which needed to render table elements
export const tableConstants = (serverRunConfirmModal, scriptType) => {
    return [
        {
            id: 1,
            name: "Script name",
            selector: (row) => row.scriptname,
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "Purpose",
            //selector: (row) => row.purpose,
            cell: (row) => {
                return (
                  <span>{row.purpose}</span>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 3,
            name: "Action",
            cell: (row) => {
                return <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Post_Delete") ? "" : " disabled") } onClick={(e) => {serverRunConfirmModal(row.type);}} disabled={(scriptType === row.type || !auth_service.getPermission("Report_Post_Delete")) ? true : false}>
                  {scriptType === row.type ? "Running" : "Run"}
              </button>;
              },
            sortable: true,
            reorder: true,
        },
    ];
};
