import axios from "axios";
import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { auth_service } from "../../../../auth/auth_service";
// This is the table constant/settings which needed to render table elements

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export const tableConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "User Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" +  (auth_service.getPermission("AP_FlagPost_Delete") ? '' : ' disable')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
