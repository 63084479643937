import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { customStyles } from '../../../utils/tableCustomStyle';
import axios from 'axios';
import { tableConstantsIndividualsDetails } from '../individual/tableConstantsIndividualsDetails';
import { tableConstantsOrganizationsDetails } from '../organization/tableConstantsOrganizationsDetails';
import { epochTogmt } from '../../../utils/common';
import { CSVLink } from 'react-csv';
import Select from "react-select";
import styles from "../individual/userInfo.module.css";


const individualOrganizationHeader = [
    { label: "Organization Name", key: "companyName" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
    { label: "Country Code", key: "countryCode" },
    { label: "Phone No.", key: "phoneNo" },
    { label: "Country", key: "country" },
    { label: "Created on", key: "createdOn" }
]

const individualCircleHeader = [
    { label: "Circle Name", key: "circleName" },
    { label: "Country", key: "country" },
    { label: "Type", key: "type" },
    { label: "Status", key: "status" },
    { label: "Created on", key: "createdOn" }
]

const individualProjectHeader = [
    { label: "Project Name", key: "projectName" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Category", key: "category" },
    { label: "Type ", key: "type" },
    { label: "Country", key: "country" },
    { label: "Status", key: "status" },
    { label: "Created on", key: "createdOn" }
]

const organizationAdminHeader = [
    { label: "Admin Name", key: "adminName" },
    { label: "Email id", key: "emailid" },
    { label: "Country Code", key: "countryCode" },
    { label: "Phone No.", key: "phoneNo" },
    { label: "Country", key: "country" },
    { label: "Persona", key: "persona" },
    { label: "Created on", key: "createdOn" }
]

const organizationEmployeesHeader = [
    { label: "Employee Name", key: "employeeName" },
    { label: "Email id", key: "emailid" },
    { label: "Country Code", key: "countryCode" },
    { label: "Phone No.", key: "phoneNo" },
    { label: "Country", key: "country" },
    { label: "Persona", key: "persona" },
    { label: "Created on", key: "createdOn" }
]

const userTypeOptions = [
    { value: "WE_NAT", label: "WeNat" },
    { value: "MY_WE_NAT", label: "MyWeNat" },
]


class CommonActivityTableListingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            fileName: "",
            exportDataList: [],
            activeTableTab: this.props?.locationDetails?.columnName ? this.props.locationDetails?.columnName : this.props?.pageType === "INDIVIDUAL" ? "ORGANIZATION" : "ADMIN",
            userCategory: { value: "WE_NAT", label: "WeNat" },

        }
    };

    componentDidMount() {
        this.fetchAllIndividualCategorywiseTableData(0);
    };

    handleChangeTab = (tabValue) => {
        this.setState({
            activeTableTab: tabValue
        }, () => {
            this.fetchAllIndividualCategorywiseTableData(0);
        })
    };

    handleChangeUserType = (userType) => {
        this.setState({
            userCategory: userType
        }, () => {
            this.fetchAllIndividualCategorywiseTableData(0);
        })
    }

    exportData = () => {

        let url;

        if (this.state.activeTableTab === "ORGANIZATION") {
            url = `userDetail/getOrganizationListDetail/${this.props?.id}?isMyWeNat=${this.state.userCategory.value === "MY_WE_NAT" ? true : false}&page=0&size=100`;
        } else if (this.state.activeTableTab === "CIRCLE") {
            url = `backend/userDetail/getCircleListDetail/${this.props?.id}?isMyWeNat=${this.state.userCategory.value === "MY_WE_NAT" ? true : false}&page=0&size=100`
        } else if (this.state.activeTableTab === "ADMIN") {
            url = `userDetail/getOrganizationAdminDetailList/${this.props?.id}`;
        } else if (this.state.activeTableTab === "EMPLOYEES") {
            url = `userDetail/getOrganizationEmployeeDetailList/${this.props?.id}`
        } else {
            if (this.props?.pageType === "INDIVIDUAL") {
                url = `backend/userDetail/getProjectListDetail/${this.props?.id}?isMyWeNat=${this.state.userCategory.value === "MY_WE_NAT" ? true : false}&page=0&size=100`;
            } else {
                url = `backend/userDetail/getProjectListDetail/${this.props?.id}?page=0&size=100`;
            }

        }

        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {

                let exportData = [];

                let data = this.state.activeTableTab === "ADMIN" ? response.data?.body : response.data.body?.content

                data && data.map((item) => {

                    if (this.state.activeTableTab === "ORGANIZATION") {
                        exportData.push({ "companyName": item.companyName, "status": item.status, "email": item.email, "countryCode": item.countryISDCode, "phoneNo": item.mobile, "country": item.country, "createdOn": epochTogmt(item.createTime) })
                    } else if (this.state.activeTableTab === "CIRCLE") {
                        exportData.push({ "circleName": item.title, "country": item.country, "type": item.type, "status": item.active ? "Active" : "Inactive", "createdOn": epochTogmt(item.createTime) })
                    } else if (this.state.activeTableTab === "ADMIN") {
                        exportData.push({ "adminName": item.name, "emailid": item.email, "countryCode": `+${item.countryISDCode}`, "phoneNo": item.mobile, "country": item.country, "persona": item.persona, "createdOn": epochTogmt(item.createTime) })
                    }
                    else if (this.state.activeTableTab === "EMPLOYEES") {
                        exportData.push({ "employeeName": item.name, "emailid": item.email, "countryCode": `+${item.countryISDCode}`, "phoneNo": item.mobile, "country": item.country, "persona": item.persona, "createdOn": epochTogmt(item.createTime) })
                    }
                    else {
                        exportData.push({ "projectName": item.projectName, "country": item.country, "status": item.status, "startDate": epochTogmt(item?.beginningTime), "endDate": epochTogmt(item?.endingTime), "category": item.category, "type": item.type, "createdOn": epochTogmt(item.createTime) })
                    }

                })
                let date = new Date();
                let dateMs = date.valueOf();
                const file = `${this.state.activeTableTab === "ORGANIZATION" ? "individual_organization_list-" : this.state.activeTableTab === "CIRCLE" ? "individual_circle_list-" : this.state.activeTableTab === "ADMIN" ? "organization_admin_list-" : this.state.activeTableTab === "EMPLOYEES" ? "organization_employees_list-" : "organization_project_list"}` + epochTogmt(dateMs) + "-" + dateMs + ".csv"

                this.setState({
                    exportDataList: exportData,
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                })
            }
        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }


    fetchAllIndividualCategorywiseTableData = async (page) => {
        this.setState({
            loading: true,
            tableData: []
        })

        let url;

        if (this.state.activeTableTab === "ORGANIZATION") {
            url = `userDetail/getOrganizationListDetail/${this.props?.id}?isMyWeNat=${this.state.userCategory.value === "MY_WE_NAT" ? true : false}&page=${page}&size=${this.state.perPage}`;
        } else if (this.state.activeTableTab === "CIRCLE") {
            url = `backend/userDetail/getCircleListDetail/${this.props?.id}?isMyWeNat=${this.state.userCategory.value === "MY_WE_NAT" ? true : false}&page=${page}&size=${this.state.perPage}`
        } else if (this.state.activeTableTab === "ADMIN") {
            url = `userDetail/getOrganizationAdminDetailList/${this.props?.id}?page=${page}&size=${this.state.perPage}`;
        } else if (this.state.activeTableTab === "EMPLOYEES") {
            url = `userDetail/getOrganizationEmployeeDetailList/${this.props?.id}?page=${page}&size=${this.state.perPage}`
        } else {
            if (this.props?.pageType === "INDIVIDUAL") {
                url = `backend/userDetail/getProjectListDetail/${this.props?.id}?isMyWeNat=${this.state.userCategory.value === "MY_WE_NAT" ? true : false}&page=${page}&size=${this.state.perPage}`;
            }
            else {
                url = `backend/userDetail/getProjectListDetail/${this.props?.id}?page=${page}&size=${this.state.perPage}`;
            }
        }

        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    tableData: this.state.activeTableTab === "ADMIN" ? response.data?.body : response.data.body?.content,
                    loading: false,
                    totalRows: response.data.body.totalElements
                });
            }
        })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    handlePageChange = page => {
        this.fetchAllIndividualCategorywiseTableData(page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllIndividualCategorywiseTableData(page - 1);
        })
    };

    render() {
        const { pageType } = this.props;
        const { tableData, activeTableTab, totalRows, loading, perPage, fileName, exportDataList } = this.state;
        return (
            <div id="page-wrapper" className={"dgcontainer"}>
                <div id="page-inner">
                    <div className={"dashboard_pagetopwrap__3SiQG"}>
                        <div className={`dashboard_pagetopwleft__38Edx dashboard_accDtlsWrap__34A_h`}>
                            <div className={`dashboard_ptwrow__3Ij8R`} style={{marginBottom: "0px"}}>
                                {this.props?.pageType === "INDIVIDUAL" && 
                                    <div className="col-md-3">
                                        <Select
                                            options={userTypeOptions}
                                            placeholder={"select user type"}
                                            value={this.state.userCategory}
                                            onChange={this.handleChangeUserType}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    width: '240px',
                                                    height: '8px',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    width: '240px',
                                                    maxHeight: '110px',
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                    const color = '#cfe7c7';
                                                    return {
                                                        ...styles,
                                                        fontSize: '15px',
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? '#cfe7c7'
                                                                : isFocused
                                                                    ? '#cfe7c7'
                                                                    : null,
                                                        color: isDisabled
                                                            ? '#ccc'
                                                            : isSelected
                                                                ? color > 2
                                                                    ? '#00374d'
                                                                    : '#00374d'
                                                                : data.color,
                                                        cursor: isDisabled ? 'not-allowed' : 'default',

                                                        ':active': {
                                                            ...styles[':active'],
                                                            backgroundColor: !isDisabled && (isSelected ? '#cfe7c7' : '#cfe7c7'),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                }
                                <div className='col-md-5'>
                                    <div className='pagetopwleft wpondMain'>
                                        <div class="topBtns">
                                            <h1 onClick={(e) => this.handleChangeTab(this.props?.pageType === "INDIVIDUAL" ? "ORGANIZATION" : "ADMIN")} className={"btn btn1 " + ((this.state.activeTableTab === "ORGANIZATION" || this.state.activeTableTab === "ADMIN") ? "active" : "")} >{this.props?.pageType === "INDIVIDUAL" ? "Organization" : "Admin"}</h1>
                                            <h1 onClick={(e) => this.handleChangeTab(this.props?.pageType === "INDIVIDUAL" ? "CIRCLE" : "EMPLOYEES")} className={"btn btn1 " + ((this.state.activeTableTab === "CIRCLE" || this.state.activeTableTab === "EMPLOYEES") ? "active" : "")} >{this.props?.pageType === "INDIVIDUAL" ? "Circles" : "Employees"}</h1>
                                            <h1 onClick={(e) => this.handleChangeTab("PROJECT")} className={"btn btn1 " + (this.state.activeTableTab === "PROJECT" ? "active" : "")} >Project</h1>
                                        </div>
                                    </div>
                                </div>
                                {this.state.tableData?.length > 0 && 
                                    <div className='col-md-4 text-right'>
                                        <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={this.exportData}><i class="iDownloadFile" /> Export</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <DataTable
                        title={this.props?.locationDetails?.userName}
                        columns={pageType === "INDIVIDUAL" ? tableConstantsIndividualsDetails(activeTableTab) : tableConstantsOrganizationsDetails(activeTableTab)}
                        data={tableData}
                        customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                        pagination
                        paginationPerPage={perPage}
                        pointerOnHover
                        progressPending={loading}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                    />
                    <CSVLink headers={activeTableTab === "ORGANIZATION" ? individualOrganizationHeader : activeTableTab === "CIRCLE" ? individualCircleHeader : activeTableTab === "ADMIN" ? organizationAdminHeader : activeTableTab === "EMPLOYEES" ? organizationEmployeesHeader : individualProjectHeader} data={exportDataList} filename={fileName} ref={(r) => (this.csvLink = r)}></CSVLink>
                </div>
            </div>
        )
    }
}

export default CommonActivityTableListingPage;