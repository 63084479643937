import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../utils/commonHeader";
import ProjectSubscriptionForm from "../ProjectSubscriptionForm";
export class EditAmountTenure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectSubscriptionId: this.props.match.params.id,
    };
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/subscription/project" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <ProjectSubscriptionForm formType={"EDIT"} id={this.props.match.params.id} />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditAmountTenure;
