import React from "react";
import { Link } from "react-router-dom";
import StatusDetails from "../../../components/StatusDetails/status_details";
import StatusDrop from "../../../components/StatusDropDown/status_drop";

export const tableCommonManagement = (fetchAllBelongHereList, handleConfirmModal, pageType) => {
  return [
    {
      id: 1,
      name: "Id",
      cell: (row) => {
        return (
          <Link to={`/pre-login/${pageType === "PROJECT" ? "project-management" : pageType === "STUDIO" ? "studio" : pageType === "FUND_RAISE" ? "fund-raise" : "communities"}/edit/${row.id}`}>
            <span className="sub">{row.id}</span>
          </Link>
        
        )
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 7,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={pageType}
            action={fetchAllBelongHereList}
            fullPermission={pageType === "PROJECT" ? "Pre_Login_Project_Manage_FullAccess" : pageType === "FUND_RAISE" ? "Pre_Login_Fund_Raise_FullAccess" : pageType === "STUDIO" ? "Pre_Login_Studio_FullAccess" : "Pre_Login_Community_FullAccess"}
            approvalPermission={pageType === "PROJECT" ? "Pre_Login_Project_Manage_Approval" : pageType === "FUND_RAISE" ? "Pre_Login_Fund_Raise_Approval" : pageType === "STUDIO" ? "Pre_Login_Studio_Approval" : "Pre_Login_Community_Approval"}
            publishPermission={pageType === "PROJECT" ? "Pre_Login_Project_Manage_Publish" : pageType === "FUND_RAISE" ? "Pre_Login_Fund_Raise_Publish" : pageType === "STUDIO" ? "Pre_Login_Studio_Publish" : "Pre_Login_Community_Publish"}
            enablePermission={pageType === "PROJECT" ? "Pre_Login_Project_Manage_Enable" : pageType === "FUND_RAISE" ? "Pre_Login_Fund_Raise_Enable" : pageType === "STUDIO" ? "Pre_Login_Studio_Enable" : "Pre_Login_Community_Enable"}
            disablePermission={pageType === "PROJECT" ? "Pre_Login_Project_Manage_Disable" : pageType === "FUND_RAISE" ? "Pre_Login_Fund_Raise_Disable" : pageType === "STUDIO" ? "Pre_Login_Studio_Disable" : "Pre_Login_Community_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 9,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (row.status === 'ENABLE' ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    }
  ];
};
