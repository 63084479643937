import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import TagsInput from "react-tagsinput";
import TextArea from "../../../components/TextArea/text_area";
import UploadFiles from "../../../components/UploadFiles/upload_files";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import styles from "./UpcomingYearList/year.module.css";
import { auth_service } from "../../../auth/auth_service";
import history from "../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../components/InputFields/form_input.module.css";
import MagicUrl from "quill-magic-url";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

export default class UpcomingYearForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: "",
            coverImage: null,
            metaKeyWords: [],
            metaTitle: "",
            metaDescription: "",
            coverImageMessage: '',
            canShowHeatMap: false,
            eventTitle: "",
            eventThemeName: "",
            eventDescription: "",
            editData: {},
            voteEndDate: "",
            nominationEndDate: "",
            bannerDate: "",
            errors: "",
            showWpondLogo: false,
            showInThreeDot: false,
            yearValid: false,
            dynamicBanner: false
        }
    }
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if ((this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) && (this.props.formType === "CREATE")) {
            formIsValid = false;
            this.setState({
              coverImageMessage: "Please select cover image"
            })
        }
        if (!this.state.year || this.state.year === "") {
            formIsValid = false;
            errors["year"] = "Please enter  year";
        }
        if (this.state.year && (this.state.year.length > 4 || this.state.year.length < 4)) {
            formIsValid = false;
            errors["year"] = "Please enter a valid year";
        }
        if (!this.state.metaTitle || this.state.metaTitle.trim() === "") {
            formIsValid = false;
            errors["metaTitle"] = "Please enter meta title";
        }
        if (!this.state.metaDescription || this.state.metaDescription.trim() === "") {
            formIsValid = false;
            errors["metaDescription"] = "Please enter meta description";
        }
        if (!this.state.bannerDate) {
            formIsValid = false;
            errors["bannerDate"] = "Please enter banner date";
        }
        if (
            this.state.metaKeyWords === null ||
            this.state.metaKeyWords === undefined ||
            this.state.metaKeyWords.length === 0
        ) {
            formIsValid = false;
            errors["metaKeyWords"] = "Please enter a meta keywords";
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    coverImageMessage: "",
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    };

    onSubmit = () => {
        if (this.validate()) {
            const formData = new FormData();
            formData.append("image", this.state.coverImage);
            let postData = {
                year: this.state.year,
                metaTitle: this.state.metaTitle,
                metaDescription: this.state.metaDescription,
                keywords: this.state.metaKeyWords,
                eventTitle: this.state.eventTitle,
                themeName: this.state.eventThemeName,
                description: this.state.eventDescription,
                voteEndDate: this.state.voteEndDate,
                nominationEndDate: this.state.nominationEndDate,
                bannerDate: this.state.bannerDate
            };
            if (this.props.formType === "UPDATE") {
                postData.id = this.state.editData.id;
                postData.status = this.state.editData.status;
                postData.showInThreeDot = this.state.showInThreeDot;
                postData.showWpondLogo = this.state.showWpondLogo;
                postData.canShowHeatMap = this.state.canShowHeatMap;
                postData.banner = this.state.dynamicBanner
            }
            formData.append("data", JSON.stringify(postData));
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/wpondAbout/${this.props.formType === "CREATE" ? "create" : "update"}`,
                headers: { "Content-Type": "multipart/form-data" },
                data: formData,
                withCredentials: true,
            })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.message === "Success!"
                    ) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
    };

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    // cover image update
    updateUploadedFiles = (files) => {
        this.setState({
            coverImage: files[0],
        });
    };

    // handle meta keywords change
    metaKeyWordsChange = (tags) => {
        this.setState({ metaKeyWords: tags });
    };

    // handle content change
    contentChange = (value) => {
        this.setState({ eventDescription: value });
    };

    //handle change
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart(),
        });
    };

    onChangeSelectBanner = (value) => {
        this.setState({
            dynamicBanner: value
        })
    }

    //Date Change
    handleDateChange = (date, type) => {
        if (date) {
            date = date.getTime();
        }
        this.setState({
            [type === "VOTE_END_DATE" ? "voteEndDate" : type === "BANNER_DATE" ? "bannerDate" : "nominationEndDate"]: date
        })
    };

    agreeHandleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    yearChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value.trimStart(),
                yearValid: event.target.value.length === 4 ? true : false
            });
        }
    };

    fetchUpcomingYearById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/wpondAbout/get?id=${this.props.id}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.body &&
                    response.data.message === "Success!"
                ) {
                    this.setState({
                        editData: response.data.body,
                        year: response.data.body.year,
                        metaTitle: response.data.body.metaTitle,
                        metaDescription: response.data.body.metaDescription,
                        metaKeyWords: response.data.body.keywords,
                        canShowHeatMap: response.data.body.canShowHeatMap,
                        eventTitle: response.data.body.eventTitle,
                        eventThemeName: response.data.body.themeName,
                        eventDescription: response.data.body.description,
                        voteEndDate: response.data.body.voteEndDate,
                        nominationEndDate: response.data.body.nominationEndDate,
                        bannerDate: response.data.body.bannerDate,
                        showWpondLogo: response.data.body.showWpondLogo,
                        showInThreeDot: response.data.body.showInThreeDot,
                        dynamicBanner: response.data.body.banner,
                        yearValid: true
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentDidMount() {
        if (this.props.formType === "UPDATE") {
            this.fetchUpcomingYearById();
        }
    }

    modules = {
        toolbar: [
            [{ header: "2" }, { header: "3" }, { header: "4" }, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
        ],
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            handleStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        imageCompress: {
            quality: 0.7, // default
            maxWidth: 750, // default
            maxHeight: 500, // default
            imageType: 'image/jpeg' + 'image/png', // default
        },
        magicUrl: {
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
            globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        },
    };

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    render() {
        const { year, metaTitle, metaDescription, metaKeyWords, canShowHeatMap, eventThemeName, eventTitle, eventDescription, voteEndDate, nominationEndDate, showWpondLogo, showInThreeDot, bannerDate, yearValid, dynamicBanner } = this.state;
        const { formType } = this.props;
        const month = new Date().getMonth() + 1;
        const date = new Date().getDate();
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <UploadFiles
                    accept=".jpg,.png,.jpeg"
                    label="Cover Image"
                    updateImage={this.state.editData.image}
                    updateFilesCb={this.updateUploadedFiles}
                    imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
                />
                {this.state.coverImageMessage &&
                    <div className="text-center">
                        <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                    </div>
                }
                {this.state.editData.status === "UPCOMING" && 
                    <div className="form-group">
                        <label>
                            Banner
                        </label>
                        <div className="row">
                            <div className="col-md-2">
                                <div class="custom-control custom-checkbox">
                                    <label class="radio-container" onClick={(e) => this.onChangeSelectBanner(false)}>Default
                                        <input type="radio" name="radio" className={!dynamicBanner ? "checked" : ''} />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div class="custom-control custom-checkbox">
                                    <label class="radio-container" onClick={(e) => this.onChangeSelectBanner(true)}>Dynamic
                                        <input type="radio" name="radio" className={dynamicBanner ? "checked" : ''} />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
 
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={year}
                            label={"Year"}
                            name={"year"}
                            onChange={this.yearChange}
                            error={this.state.errors["year"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className={"form-group" + (this.state.errors["bannerDate"] ? " error" : "")}>
                            <label>Banner Date <span className="text-danger">*</span></label>
                            <div className="d-flex">
                                <DatePicker
                                    className={"form-control custom-input-date"}
                                    dateFormat={"dd-MMM-yyyy"}
                                    minDate={new Date(year + "-" + month + "-" + date)}
                                    openToDate={new Date(year + "-" + month + "-" + date)}
                                    maxDate={new Date(year + "-12-31")}
                                    dropdownMode="select"
                                    placeholderText="Banner Date"
                                    selected={bannerDate}
                                    onChange={date => {
                                        this.handleDateChange(date, "BANNER_DATE")
                                    }}
                                    disabled={!yearValid}
                                />
                            </div>
                            {this.state.errors["bannerDate"] && (
                                <small className={style.danger}>
                                    {this.state.errors["bannerDate"]}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <Line />
                <FormInput
                    group={"large"}
                    value={metaTitle}
                    label={"Meta Title"}
                    name={"metaTitle"}
                    onChange={this.handleChange}
                    error={this.state.errors["metaTitle"]}
                />
                <TextArea
                    label={"Meta Description"}
                    value={metaDescription}
                    name={"metaDescription"}
                    onChange={this.handleChange}
                    error={this.state.errors["metaDescription"]}
                />
                <div className={"form-group " + (this.state.errors["metaKeyWords"] ? 'error' : '')}>
                    <label>
                        {`Meta Keywords `}
                        <span className="text-danger">*</span>{" "}
                    </label>
                    <TagsInput
                        value={metaKeyWords}
                        onChange={this.metaKeyWordsChange}
                        addKeys={[9, 13, 62, 188]}
                    />
                    {this.state.errors["metaKeyWords"] && (
                        <small className={style.danger}>
                            {this.state.errors["metaKeyWords"]}
                        </small>
                    )}
                </div>
                <Line />
                <FormInput
                    group={"large"}
                    value={eventThemeName}
                    label={"Event Theme"}
                    name={"eventThemeName"}
                    onChange={this.handleChange}
                    className="not_mandatory"
                />
                <FormInput
                    group={"large"}
                    value={eventTitle}
                    label={"Event Title"}
                    name={"eventTitle"}
                    onChange={this.handleChange}
                    className="not_mandatory"
                />
                <div className={"form-group"}>
                    <label>
                        Event Description
                    </label>
                    <div className="reactQuillWrap">
                        <ReactQuill
                            value={eventDescription}
                            onChange={this.contentChange}
                            modules={this.modules}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className={"form-group"}>
                            <label>Vote End Date</label>
                            <div className="d-flex">
                                <DatePicker
                                    className={"form-control custom-input-date"}
                                    dateFormat={"dd-MMM-yyyy"}
                                    minDate={new Date()}
                                    openToDate={new Date()}
                                    dropdownMode="select"
                                    placeholderText="Vote End Date"
                                    selected={voteEndDate}
                                    onChange={date => {
                                        this.handleDateChange(date, "VOTE_END_DATE")
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={"form-group"}>
                            <label>Nomination End Date</label>
                            <div className="d-flex">
                                <DatePicker
                                    className={"form-control custom-input-date"}
                                    dateFormat={"dd-MMM-yyyy"}
                                    minDate={new Date()}
                                    openToDate={new Date()}
                                    dropdownMode="select"
                                    placeholderText="Nomination End Date"
                                    selected={nominationEndDate}
                                    onChange={date => {
                                        this.handleDateChange(date, "NOMINATION_END_DATE")
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.editData.status === "UPCOMING" &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`form-group`}>
                                <label class="switch">
                                    Show Heat Map
                                    <input
                                        type="checkbox"
                                        ref="canShowHeatMap"
                                        name="canShowHeatMap"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={canShowHeatMap}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                }
                {this.state.editData.status === "COMPLETED" &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`form-group`}>
                                <label class="switch">
                                    WPOND Logo
                                    <input
                                        type="checkbox"
                                        ref="showWpondLogo"
                                        name="showWpondLogo"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={showWpondLogo}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={`form-group`}>
                                <label class="switch">
                                    Show in three dot
                                    <input
                                        type="checkbox"
                                        ref="showInThreeDot"
                                        name="showInThreeDot"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={showInThreeDot}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                }
                {((formType === "CREATE" && auth_service.getPermission("WPOND_Upcoming_Create", "WPOND_Upcoming_FullAccess")) || (formType === "UPDATE" && auth_service.getPermission("WPOND_Upcoming_Update", "WPOND_Upcoming_FullAccess"))) &&
                    <ButtonWrap className={`${styles.bottombutt}`}>
                        <Button children={formType === "CREATE" ? "Submit" : "Update"} onClick={this.onSubmit} />
                    </ButtonWrap>}
            </div>
        )
    }
}
