export const subscriptionType = [
    { value: 'ALL', label: 'All' },
    { value: 'BRONZE', label: 'Standard' },
    { value: 'SILVER', label: 'Professional' },
    { value: 'GOLD', label: 'Enterprise' },
    { value: 'PLATINUM', label: 'Grand' },
    { value: 'CORPORATE', label: 'Corporate' }
]

export const getSubscriptionType = (type) => {
    if (type === "BRONZE") {
        return "Standard";
    } else if (type === "SILVER") {
        return "Professional";
    } else if (type === "GOLD") {
        return "Enterprise";
    } else if (type === "PLATINUM") {
        return "Grand";
    } else {
        return "Corporate";
    }
}

export const userBadgeType = [
    { value: 'ALL', label: 'All' },
    { value: 'SILVER', label: 'Silver' },
    { value: 'GOLD', label: 'Gold' },
    { value: 'PLATINUM', label: 'Platinum' },
]

export const getUserBadgeType = (type) => {
    if (type === "SILVER") {
        return "Silver";
    } else if (type === "GOLD") {
        return "Gold";
    }
    return "Platinum";
}