import { Link } from "react-router-dom";
import StatusDetails from "../../../components/StatusDetails/status_details";
import StatusDrop from "../../../components/StatusDropDown/status_drop";

export const tableConstants = (fetchAllCategoryList) => {
  return [
    {
      id: 1,
      name: "Category Name",
      cell: (row) => {
        return (
          <Link to={`/how-we-impact/edit/${row.id}`}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Video URl",
      selector: (row) => row.video,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            year={row.year}
            type={"howWeImpact"}
            action={fetchAllCategoryList}
            fullPermission={"HowWeImpact_FullAccess"}
            approvalPermission={"HowWeImpact_Approval"}
            publishPermission={"HowWeImpact_Publish"}
            enablePermission={"HowWeImpact_Enable"}
            disablePermission={"HowWeImpact_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
