import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../auth/auth_service";
import { tableBadgeHistoryConstants } from "./tableBadgeHistoryConstants";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import { FilterComponent } from "../../../utils/filter";
import { CommonHeader } from "../../../utils/commonHeader";
import Select from "react-select";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Button from "../../../components/Buttons/button";
import { DateRange } from "react-date-range";
import { utcTodate } from "../../../utils/common";
import styles from "../../account_details/individual/individual_details.module.css";
import { Link } from "react-router-dom";

const options = [
    { value: "ALL", label: "ALL" },
    { value: "TODAY", label: "TODAY" },
    { value: "YESTERDAY", label: "YESTERDAY" },
    { value: "LAST_7_DAYS", label: "LAST 7 DAY" },
    { value: "LAST_30_DAYS", label: "LAST 30 DAY" },
    { value: "LAST_365_DAYS", label: "LAST 365 DAY" },
    { value: "CUSTOM", label: "CUSTOM" },
];

export class DailyBadgeHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            historyDataList: [],
            filterText: "",
            filterDisplay: false,
            loading: false,
            totalRows: 0,
            perPage: 10,
            tabType: "RANK",
            date: { startDate: new Date(), endDate: new Date(), key: "selection" },
            displayRangeButton: false,
            displayRange: false,
            dateData: { value: "ALL", label: "ALL" },
            isEnabledCancelFiler: false,
        };
    }

    // subheader of a component
    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.historyDataList.filter(
                            (item) =>
                                item.id &&
                                item.id.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    fetchDailyBadgeHistoryList = (dateData, page) => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/backend/badge/${this.props.pageType === "BADGE_ASSIGNMENT_HISTORY" ? "assignment/history" : `daily/${this.state.tabType === "RANK" ? "ranks" : "earning"}`}?filter=${dateData}&startTime=${this.state?.date?.startDate.getTime()}&endTime=${this.state?.date?.endDate.getTime()}&page=${page}&size=${this.state.perPage}&userId=${this.props.match?.params?.userId}`,
        }).then((response) => {
            if (response?.data?.message === "Success!") {
                this.setState({
                    historyDataList: response.data?.body?.content,
                    loading: false,
                    totalRows: response?.data?.body?.totalElements,
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    showFilter = () => {
        this.setState({
            filterDisplay: this.state.filterDisplay ? false : true,
        });
    };

    onChangeTabData = (tabType) => {
        this.setState({
            tabType: tabType,
            dateData: { value: "ALL", label: "ALL" },
            displayRangeButton: false,
            isEnabledCancelFiler: false,
        }, () => {
            this.setState({
                historyDataList: [],
                totalRows: 0
            })
            this.fetchDailyBadgeHistoryList(this.state.dateData.value, 0);
        })
    }

    handleDateChange = (newValue) => {
        this.setState({
            dateData: newValue,
            displayRangeButton: newValue.value === "CUSTOM" ? true : false,
            isEnabledCancelFiler: newValue.value === "CUSTOM" ? false : true
        }, () => {
            if (newValue.value !== "CUSTOM") {
                this.setState({
                    date: { startDate: new Date(), endDate: new Date(), key: "selection" },
                })
                this.fetchDailyBadgeHistoryList(this.state.dateData.value, 0);
            }
        })
    };

    displayRangeSelect = () => {
        this.setState({
            displayRange: this.state.displayRange ? false : true,
        });
    };

    handleSelect = (range) => {
        this.setState({
            date: {
                ...this.state.date,
                startDate: range.selection.startDate,
                endDate: range.selection.endDate,
                key: range.selection.key,
            },
        });
    };

    cancelFilterData = (type) => {
        this.setState({
            displayRangeButton: type === "DATE" ? false : this.state.displayRangeButton,
            isEnabledCancelFiler: false,
            dateData: { value: "ALL", label: "ALL" }
        }, () => {
            this.fetchDailyBadgeHistoryList(this.state?.dateData?.value, 0);
        });
    }

    applyFilter = () => {
        this.setState({
            displayRange: false
        })
        this.fetchDailyBadgeHistoryList(this.state?.dateData?.value, 0);
    }
 
    actionButton = () => {
        return (
            <React.Fragment>
                {this.props.pageType === "BADGE_HISTORY" ? 
                    <div class="pagetopwleft wpondMain">
                        <div class="ptwrow">
                            <div class="topBtns">
                                <button className={"btn btn1" + (this.state.tabType === "RANK" ? " active" : "")} onClick={() => this.onChangeTabData("RANK")}>Ranks</button>
                                <button className={"btn btn1" + (this.state.tabType === "EARN" ? " active" : "")} onClick={() => this.onChangeTabData("EARN")}>Earning</button>
                            </div>
                        </div>
                    </div>: 
                    <></>
                }
            </React.Fragment>
        );
    };

    handlePageChange = (page) => {
        this.fetchDailyBadgeHistoryList(this.state?.dateData?.value, page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchDailyBadgeHistoryList(this.state?.dateData?.value, page - 1);
        })
    };

    componentDidMount() {
        this.fetchDailyBadgeHistoryList(this.state?.dateData?.value, 0);
    }

    render() {
        const { historyDataList, totalRows, loading, perPage, isEnabledCancelFiler, displayRangeButton, displayRange, date, dateData, tabType } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to={this.props.pageType !== "BADGE_ASSIGNMENT_HISTORY" ? `/badge-user-list` : `/user-badge-list`} className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={styles.pagetopwrap}>
                            <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                                <div className={`${styles.ptwrow}`}>
                                    <div className="col-md-3">
                                        <Select
                                            options={options}
                                            placeholder={"ALL"}
                                            value={dateData}
                                            onChange={this.handleDateChange}
                                            styles={{
                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled &&
                                                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                    {isEnabledCancelFiler &&
                                        <div className="col-md-3">
                                            <Button
                                                onClick={(e) => {this.cancelFilterData("FILTER")}}
                                                type={"anchor"}
                                                children={`Clear Filter`}
                                            />
                                        </div>
                                    }
                                    {displayRangeButton && (
                                        <>
                                            <div className="col-md-5">
                                                <Button
                                                    onClick={this.displayRangeSelect}
                                                    type={"range"}
                                                    children={`${utcTodate(this.state.date.startDate)} to ${utcTodate(this.state.date.endDate)}`}
                                                />
                                                <Button
                                                    onClick={(e) => {this.applyFilter()}}
                                                    type={"anchor"}
                                                    children={`Apply Filter`}
                                                />
                                                <Button
                                                    onClick={(e) => {this.cancelFilterData("DATE")}}
                                                    type={"anchor"}
                                                    children={`Cancel`}
                                                />
                                                {displayRange && (
                                                    <DateRange
                                                        ranges={[date]}
                                                        onChange={this.handleSelect}
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col2Large menu-list">
                            <DataTable
                                //title="Badge User List"
                                columns={tableBadgeHistoryConstants(tabType, this.props.pageType)}
                                data={historyDataList}
                                customStyles={customStyles}
                                actions={this.actionButton()}
                                pagination
                                highlightOnHover
                                pointerOnHover
                                paginationPerPage={perPage}
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default DailyBadgeHistory;