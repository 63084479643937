import React, { Component } from "react";
import { auth_service } from "../../../../auth/auth_service";
import FormInput from "../../../../components/InputFields/form_input";
import styles from "../CreateUser/create_user.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
import { CommonHeader } from "../../../../utils/commonHeader";

import httpService from "../../../../AxiosInterceptors";
import history from "../../../../utils/history";
import style from "../../../../components/InputFields/form_input.module.css";
import TagsInput from "react-tagsinput";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

httpService.setupInterceptors();



const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

export class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      departmentData: [],
      roleData: [],
      departmentCategories: [],
      roleCategories: [],
      departmentList: [],
      roleList: [],
      permissionList: [],
      groupList: [],
      groupCategories: [],
      permissionCategories: [],
      selectedPermissionList: [],
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      userStatus: "",
      isRoleDisable: false,
      isPermissionDisable: false,
      isReportDetailsDisable: false,
      errors: {},
      userId: this.props.match.params.id,
      allDepartmentChecked: false,
      allRoleChecked: false,
      filterText: "",
      editDepartmentFlag: false,
      editRoleFlag: false,
      filterTextDepartment: "",
      selectedDepartmentRows: [],
      filterTextRole: "",
      selectedRoleRows: [],
      departmentClearRows: false,
      roleClearRows: false,

    };

  }

  // validate create user
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.firstName || this.state.firstName.trim() === "") {
      formIsValid = false;
      errors["firstName"] = "Please enter first name";
    }
    if (!this.state.lastName || this.state.lastName.trim() === "") {
      formIsValid = false;
      errors["lastName"] = "Please enter last name";
    }
    if (!this.state.phoneNo) {
      formIsValid = false;
      errors["phoneNo"] = "Please enter phone no.";
    }
    if (
      this.state.phoneNo != null &&
      this.state.phoneNo != undefined &&
      this.state.phoneNo != ""
    ) {
      let phoneNumber = this.state.phoneNo.toString();
      let phoneValid = phoneNumber.match(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      );
      if (phoneValid == null) {
        formIsValid = false;
        errors["phoneNo"] = "Please enter a valid phone no";
      }
    }
    if (
      this.state.email != null &&
      this.state.email != undefined &&
      this.state.email != ""
    ) {
      let emailAddress = this.state.email;
      let emailValid = emailAddress.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailValid == null) {
        formIsValid = false;
        errors["email"] = "Please enter a valid email";
      }
    }
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter email";
    }
    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Please enter password";
    }
    if (
      this.state.password != null &&
      this.state.password != undefined &&
      this.state.password != ""
    ) {
      let passwordValue = this.state.password;
      let uppercaseTest = passwordValue.match("^(?=.*[A-Z])");
      let minCharacterTest = passwordValue.match("^(?=.{8})");
      if (uppercaseTest == null) {
        formIsValid = false;
        errors["password"] = "Please enter 1 Uppercase";
      }
      if (minCharacterTest == null) {
        formIsValid = false;
        errors["password"] = "Password must be at least 8 characters long with";
      }
    }
    if (
      this.state.departmentList === null ||
      this.state.departmentList === undefined ||
      this.state.departmentList.length === 0
    ) {
      formIsValid = false;
      errors["department"] = "Please enter a department";
    }
    if (
      this.state.roleList === null ||
      this.state.roleList === undefined ||
      this.state.roleList.length === 0
    ) {
      formIsValid = false;
      errors["role"] = "Please enter a role";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // validate create user
  validatePassword = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.newPassword) {
      formIsValid = false;
      errors["newPassword"] = "Please enter password";
    }
    if (
      this.state.newPassword != null &&
      this.state.newPassword != undefined &&
      this.state.newPassword != ""
    ) {
      let passwordValue = this.state.newPassword;
      let uppercaseTest = passwordValue.match("^(?=.*[A-Z])");
      let minCharacterTest = passwordValue.match("^(?=.{8})");
      if (uppercaseTest == null) {
        formIsValid = false;
        errors["newPassword"] = "Please enter 1 Uppercase";
      }
      if (minCharacterTest == null) {
        formIsValid = false;
        errors["newPassword"] =
          "Password must be at least 8 characters long with";
      }
    }
    if (!this.state.confirmPassword) {
      formIsValid = false;
      errors["confirmPassword"] = "Please enter password";
    }
    if (
      this.state.confirmPassword != null &&
      this.state.confirmPassword != undefined &&
      this.state.confirmPassword != ""
    ) {
      let passwordValue = this.state.confirmPassword;
      let uppercaseTest = passwordValue.match("^(?=.*[A-Z])");
      let minCharacterTest = passwordValue.match("^(?=.{8})");
      if (uppercaseTest == null) {
        formIsValid = false;
        errors["confirmPassword"] = "Please enter 1 Uppercase";
      }
      if (minCharacterTest == null) {
        formIsValid = false;
        errors["confirmPassword"] =
          "Password must be at least 8 characters long with";
      }
      let newPasswordValue = this.state.newPassword;
      let confirmPasswordValue = this.state.confirmPassword;
      if (newPasswordValue !== confirmPasswordValue) {
        formIsValid = false;
        errors["confirmPassword"] = "Password does not match";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //   title change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  agreeHandleChange = (fieldName, event) => {
    if (event.target.checked) {
      this.setState({
        isReportDetailsDisable: event.target.checked,
      });
    } else {
      this.setState({
        isReportDetailsDisable: event.target.checked,
      });
    }
    this.setState({
      [fieldName]: event.target.checked,
    });
  };

  onResetPassword = () => {
    if (this.validatePassword()) {
      let postData = {
        id: this.state.userId,
        userId: this.state.userData.id,
        password: this.state.confirmPassword,
      };

      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/adminUser/updatePassword",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            this.fetchUserById();
            document.getElementById("closeResetModal").click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  passwordView(id) {
    var attr = document.getElementById(id);
    if (attr.type === "password") {
      attr.type = "text";
      document.getElementById(id + "toggle").innerHTML = "Hide";
    } else {
      attr.type = "password";
      document.getElementById(id + "toggle").innerHTML = "Show";
    }
  }

  // fetch data by user id
  fetchUserById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminUser/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.status === "200 OK"
        ) {

          this.setState({
            firstName: response.data.body.firstName,
            lastName: response.data.body.lastName,
            password: response.data.body.password,
            email: response.data.body.email,
            phoneNo: response.data.body.mobile,
            userStatus: response.data.body.status,
            departmentList: response.data.body.department,
            roleList: response.data.body.role,
          });
          this.fetchRoleList(response.data.body.department);
          this.fetchPermissionList(response.data.body.group);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  onUpdateUser = () => {
    if (this.validate()) {
      let permissionDataList = [];
      this.state.permissionList &&
        this.state.permissionList.map((data) => {
          permissionDataList.push(data.label);
        });
      let postData = {
        id: this.state.userId,
        userId: this.state.userData.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
        email: this.state.email,
        phoneNumber: this.state.phoneNo,
        department: this.state.departmentList,
        permission: permissionDataList,
        role: this.state.roleList,
        status: this.state.userStatus,
      };
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/adminUser/edit",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  fetchRoleList = (departmentData) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminDepartment/getByName?name=${departmentData}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            roleCategories:
              response.data.body &&
              response.data.body.map((item, index) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                isChecked: this.checkRole(item.name),
                name: item.name
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkRole = (value) =>{
    return this.state.roleList.includes(value);
  }

  fetchDepartmentList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminDepartment/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            departmentCategories:
              response.data.body &&
              response.data.body.map((item, index) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                isChecked: this.checkDepartment(item.name),
                name: item.name
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkDepartment = (value) =>{
    return this.state.departmentList.includes(value);
  }

  fetchGroupList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            groupCategories:
              response.data.body &&
              response.data.body.map((item) => ({
                value: item.name,
                label: item.name,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchPermissionList = (groupName) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/getByName?name=${groupName}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            permissionCategories:
              response.data.body &&
              response.data.body.map((item, index) => ({
                value: item,
                label: item,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchPermissionListByRoleName = (roleData) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminRole/getByName?name=${roleData}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          let permissionDataList = [];
          response.data.body &&
            response.data.body.map((item, index) => {
              permissionDataList.push(item);
            });
          this.setState({
            selectedPermissionList: permissionDataList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addDepartmentList = () => {
    let department = this.state.departmentCategories;
    let selectDepartmentList = [];
    department && department.map((item, index) => {
      if (item.isChecked) {
        selectDepartmentList.push(item.name);
      }
    })
    this.setState({ departmentList: selectDepartmentList, addDepartmentFlag: true }, () => {
      if (selectDepartmentList.length > 0) {
        this.setState({
          isFieldDisable: false,
        });
        this.fetchRoleList(this.state.departmentList);
      } else {
        this.setState({
          isFieldDisable: true,
          roleList: []
        });
      }
    });
    document.getElementById("closeAddDepartmentModal").click();
  }

  addRoleList = () => {
    let role = this.state.roleCategories;
    let selectRoleList = [];
    role && role.map((item, index) => {
      if (item.isChecked) {
        selectRoleList.push(item.name);
      }
    })
    this.setState({ roleList: selectRoleList, addRoleFlag: true });
    document.getElementById("closeRoleModal").click();
  }

  addDepartmentModalClose = () => {
    this.setState({
      filterText: "",
    });
  }

  addRoleModalClose = () => {
    this.setState({
      filterText: "",
    })
  }

  componentDidMount() {

    this.fetchUserById();
    this.fetchDepartmentList();
    this.fetchGroupList();
  }

  render() {
    this.filteredDepartmentItems = this.state.departmentCategories.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterTextDepartment.toLowerCase())
    );
    this.filteredRoleItems = this.state.roleCategories.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterTextRole.toLowerCase())
    );
    const selectRoleRowProp = {
      mode: 'checkbox',
      selected: this.state.roleList,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.state.roleCategories && this.state.roleCategories.map((item) => {
          if (item.id === row.id) {
            item.isChecked = isSelect;
          }
        })
      },
      onSelectAll: (isSelect, rows, e) => {
        this.state.roleCategories && this.state.roleCategories.map((item) => {
          item.isChecked = isSelect;
        })
      }
    };

    const selectDepartmentRowProp = {
      mode: 'checkbox',
      selected: this.state.departmentList,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.state.departmentCategories && this.state.departmentCategories.map((item) => {
          if (item.id === row.id) {
            item.isChecked = isSelect;
          }
        })
      },
      onSelectAll: (isSelect, rows, e) => {
        this.state.departmentCategories && this.state.departmentCategories.map((item) => {
          item.isChecked = isSelect;
        })
      }
    };
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/admin-panel/user" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <h2>USER UPDATE</h2>
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.firstName}
                    label={"First Name"}
                    onChange={this.handleChange}
                    name={"firstName"}
                    error={this.state.errors["firstName"]}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.lastName}
                    label={"Last Name"}
                    onChange={this.handleChange}
                    name={"lastName"}
                    error={this.state.errors["lastName"]}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.email}
                    label={"Email"}
                    onChange={this.handleChange}
                    name={"email"}
                    error={this.state.errors["email"]}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.phoneNo}
                    label={"Phone No"}
                    onChange={this.handleChange}
                    maxlength="10"
                    name={"phoneNo"}
                    error={this.state.errors["phoneNo"]}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group passfield">
                    <label className="">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="custom-input form-control"
                      id="password"
                      name="password"
                      value={this.state.password || ""}
                      style={
                        this.state.validation &&
                        this.state.validation.password &&
                        this.state.validation.password.style
                      }
                      onChange={this.handleChange}
                      aria-describedby="inputGroupPrepend"
                      placeholder="Password"
                      style={
                        this.state.errors["password"] && {
                          border: "solid 1px red",
                        }
                      }
                      disabled
                    />
                    {this.state.errors["password"] && (
                      <small className={style.danger}>
                        {this.state.errors["password"]}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label for=""> &nbsp; </label>
                    <a
                      href="#resetPassmodal"
                      className="btn addRoleGlobal mt-2"
                      data-toggle="modal"
                    >
                      Reset Password
                    </a>
                  </div>
                </div>
              </div>
              <Line />
              <div className={"form-group user-tags-input " + (this.state.errors["department"] ? 'error' : '')}>
                <label className="d-flex align-items-center">Department
                  <a href="#departmentListModal" className="btn addRoleGlobal ml-auto" data-toggle="modal"><i class="iAddCircle"></i> Add Department</a>
                </label>
                <TagsInput
                  inputProps={{ placeholder: 'Add department' }}
                  value={this.state.departmentList}
                />
                {this.state.errors["department"] && (
                  <small className={style.danger}>
                    {this.state.errors["department"]}
                  </small>
                )}
              </div>
              <div className={"form-group user-tags-input " + (this.state.errors["role"] ? 'error' : '')}>
                <label className="d-flex align-items-center">Role
                  <a href="#roleListModal" className={"btn addRoleGlobal ml-auto " + (this.state.departmentList.length > 0 ? '' : 'disabled')} data-toggle="modal"><i class="iAddCircle"></i> Add Role</a>
                </label>
                <TagsInput
                  inputProps={{ placeholder: 'Add role' }}
                  value={this.state.roleList}
                />
                {this.state.errors["role"] && (
                  <small className={style.danger}>
                    {this.state.errors["role"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("AP_User_Update", "AP_User_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Update"} onClick={this.onUpdateUser} />
                </ButtonWrap>
              )}

              <div
                className="modal fade dgpopup permissionMod"
                id="resetPassmodal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Reset your Password
                      </h5>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group passfield">
                              <label className="">
                                Enter New Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="password"
                                className="custom-input form-control"
                                id="newPassword"
                                name="newPassword"
                                value={this.state.newPassword || ""}
                                onChange={this.handleChange}
                                aria-describedby="inputGroupPrepend"
                                placeholder="Password"
                                style={
                                  this.state.errors["newPassword"] && {
                                    border: "solid 1px red",
                                  }
                                }
                              />

                              {this.state.errors["newPassword"] && (
                                <small className={style.danger}>
                                  {this.state.errors["newPassword"]}
                                </small>
                              )}
                              <span
                                className="passtoggle"
                                id="newPasswordtoggle"
                                onClick={() => this.passwordView("newPassword")}
                                style={{
                                  display: "block",
                                }}
                              >
                                Show
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group passfield">
                              <label className="">
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="password"
                                className="custom-input form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={this.state.confirmPassword || ""}
                                onChange={this.handleChange}
                                aria-describedby="inputGroupPrepend"
                                placeholder="Password"
                                style={
                                  this.state.errors["confirmPassword"] && {
                                    border: "solid 1px red",
                                  }
                                }
                              />
                              {this.state.errors["newPassword"] && (
                                <small className={style.danger}>
                                  {this.state.errors["confirmPassword"]}
                                </small>
                              )}
                              <span
                                className="passtoggle"
                                id="confirmPasswordtoggle"
                                onClick={() =>
                                  this.passwordView("confirmPassword")
                                }
                                style={{
                                  display: "block",
                                }}
                              >
                                Show
                              </span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        id="closeResetModal"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.onResetPassword}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade dgpopup permissionMod" id="departmentListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Select Department to add</h5>
                </div>
                <div class="modal-body">
                  <div className="tableadminpsearch role">
                    <div className="col2Large dgtable">
                      <BootstrapTable striped data={this.filteredDepartmentItems} selectRow={selectDepartmentRowProp} search={true}>
                        <TableHeaderColumn isKey dataField='value'>Department</TableHeaderColumn>
                      </BootstrapTable>
                      {this.state.errors["departmentModal"] && (
                        <div className="text-center">
                          <small className={style.danger}>
                            {this.state.errors["departmentModal"]}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.addDepartmentModalClose} id="closeAddDepartmentModal" data-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary" onClick={this.addDepartmentList}>Add</button>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade dgpopup permissionMod" id="roleListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalRoleLabel">Select Role to add</h5>
                </div>
                <div class="modal-body">
                  <div className="tableadminpsearch role">
                    <div className="col2Large dgtable">
                      <BootstrapTable striped data={this.filteredRoleItems} selectRow={selectRoleRowProp} search={true}>
                        <TableHeaderColumn isKey dataField='value'>Role</TableHeaderColumn>
                      </BootstrapTable>
                      {this.state.errors["roleModal"] && (
                        <div className="text-center">
                          <small className={style.danger}>
                            {this.state.errors["roleModal"]}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" id="closeRoleModal" onClick={this.addRoleModalClose} data-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary" onClick={this.addRoleList}>Add</button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </CommonHeader>
    );
  }
}

export default EditUser;