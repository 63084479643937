import React, { useState, useRef, useEffect } from "react";
import { auth_service } from "../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import styles from "../../pages/causes/CreateCause/create_cause.module.css";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2 * 1024 * 1024;

const UploadFilesData = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  updateImage,
  ...props
}) => {
  const fileInputField = useRef(null);
  const [errors, setErrors] = useState("");
  const [files, setFiles] = useState({});
  const [previewImage, setPreviewImage] = useState("");

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const uploadAddAttachments = (e) => {
    let currentFile = e.target.files[0];
    let fileSize = e.target.files[0].size;
    if (fileSize < maxFileSizeInBytes) {
      const fileName = document.getElementById(props.id).value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      const allowedAttachmentsTypeArr = ['jpg', 'jpeg', 'png'];

      let addAttachmentFile = {
        size: e.target.files[0].size,
        fileName: e.target.files[0].name,
        attachmentUrl: URL.createObjectURL(e.target.files[0]),
        attachmentType: extFile
      };
      setPreviewImage(addAttachmentFile?.attachmentUrl);
      if (allowedAttachmentsTypeArr.includes(extFile)) {
        uploadAttachments(currentFile, "IMAGE");
      } else {
        setErrors(`Only ${allowedAttachmentsTypeArr.join("/")}  files are allowed!`);
        document.getElementById(props.id).value = null;
        setPreviewImage("");
      }
    } else {
      setErrors('Please upload the file less than 2MB');
      document.getElementById(props.id).value = null;
    }

    setTimeout(() => {
      setErrors("");
    }, 2000);
  };

  const uploadAttachments = async (currentFile, attachmentType) => {
    const formData = new FormData();
    formData.append("file", currentFile);
    formData.append("userId", auth_service.getUserData()?.id);
    formData.append("attachmentType", attachmentType);
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/backend/pre/login/belong/uploadAttachment`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        if (response && response.data && response.data.message === "Success!") {
            updateFilesCb(response?.data?.body, props.logoUploadFiles, props?.id );
        } 
    }).catch((error) => {
        console.log(error);
        ErorrMsgPopUp.fire({
            didOpen: () => {
                ErorrMsgPopUp.clickConfirm()
            }
        }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
        })
    });
  }

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
  };


  const removeLogo = () => {
    setPreviewImage([]);
    props.removeUploadedLogoFiles();
  }

  useEffect(() => {
    setPreviewImage(updateImage);
  }, [updateImage]);


  return (
    <div className={"form-group text-center " + (props.imagePresent === 'noImage' ? 'error' : '')}>
      <div style={{ display: "inline-block" }}>
        <div className={`${styles.causesformuploadimg} upBlk` + (props.logoUploadFiles ? " logoUploadFiles" : "")}>
          <input
            type="file"
            onChange={uploadAddAttachments}
            id={props.id}
            ref={fileInputField}
            title=""
            className={`${styles.file2}`}
            value=""
            {...props}
          />
          <button
            type="button"
            onClick={handleUploadBtnClick}
            className={`${styles.projbrowse} ${styles.btn}`}
          >
            <div>
              Add {label}
            </div>
            {previewImage && <img src={previewImage} id="preview2" alt="preview" />}
          </button>
          {props.logoUploadFiles && props.removeLogoFlag && <button type="button" className="propicdel btn sponsors-remove" onClick={(e) => removeLogo()}><i class="iTrashBin" /></button>}
        </div>
        <div className={`${styles.recommendimg}`}>
          {!props.logoUploadFiles ? "Recommended size: 552px by 200px." : "Recommended size: 150px by 150px."}
        </div>
        <span style={{ color: "red" }}>{errors}</span>
      </div>
    </div>
  );
};

export default UploadFilesData;