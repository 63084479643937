export const data = [
  {
    "id" : "63045d6dd0d1b73c35193072",
    "circleName" : "Charlie && Circle",
    "slug" : "charlie-circle1",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62ff4b25ff95012ea008e410",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 4,
    "followerIds" : [ "62ff4b25ff95012ea008e410", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86" ],
    "followerCount" : 5,
    "projectCount" : 2,
    "posts" : 0,
    "createdOn" : "08/23/2022",
    "deactivated" : false,
    "active" : true
  }, {
    "id" : "63045d45d0d1b73c3519305a",
    "circleName" : "Charlie & Circle",
    "slug" : "charlie-circle",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62ff4b25ff95012ea008e410",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 0,
    "followerIds" : [ "62ff4b25ff95012ea008e410" ],
    "followerCount" : 1,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/23/2022",
    "deactivated" : true,
    "active" : false
  }, {
    "id" : "63045ca4d0d1b73c3519303f",
    "circleName" : "Charlie circle 3",
    "slug" : "charlie-circle-3",
    "country" : "Albania",
    "type" : "Academician",
    "superAdminId" : "62ff4b25ff95012ea008e410",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 0,
    "followerIds" : [ "62ff4b25ff95012ea008e410" ],
    "followerCount" : 1,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/23/2022",
    "deactivated" : false,
    "active" : false
  }, {
    "id" : "630381b0d0d1b73c35192ecb",
    "circleName" : "circle charlie",
    "slug" : "circle-charlie",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62ff4b25ff95012ea008e410",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 1,
    "followerIds" : [ "62ff4b25ff95012ea008e410", "62ff4b96ff95012ea008e415" ],
    "followerCount" : 2,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : false,
    "active" : false
  }, {
    "id" : "63036f893b4bd708cc06fe87",
    "circleName" : "Turtle Conservation Strategies",
    "slug" : "research-conservation3",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62fcddf0aa03957009310dd4",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 0,
    "followerIds" : [ "62fcddf0aa03957009310dd4" ],
    "followerCount" : 1,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : false,
    "active" : false
  }, {
    "id" : "6303665c3b4bd708cc06fae3",
    "circleName" : "RESEARCH",
    "slug" : "research-conservation2",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62fb93bd18eb0a111201c644",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 0,
    "followerIds" : [ "62fb93bd18eb0a111201c644", "60a91dfb7798225eccd3fe0b", "62a8d4aa30c718229fc0fd78" ],
    "followerCount" : 3,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : false,
    "active" : false
  }, {
    "id" : "6303523ec63c755f70e2a3ad",
    "circleName" : "RESEARCH && CONSERVATION",
    "slug" : "research-conservation1",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62fcd3d5aa03957009310dc8",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 1,
    "followerIds" : [ "62fcd3d5aa03957009310dc8", "607d15473aa362564d429d23" ],
    "followerCount" : 2,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : false,
    "active" : false
  }, {
    "id" : "63034ea64df778221275ca25",
    "circleName" : "RESEARCH & CONSERVATION",
    "slug" : "updated-bustanigardens",
    "country" : "Afghanistan",
    "type" : "Academician",
    "superAdminId" : "62fcddf0aa03957009310dd4",
    "adminids" : [ "60a91dfb7798225eccd3fe0b" ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 4,
    "followerIds" : [ "62fcddf0aa03957009310dd4", "60a91dfb7798225eccd3fe0b", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "621dadc98fc6aa62430735b0", "62a8d4aa30c718229fc0fd78" ],
    "followerCount" : 7,
    "projectCount" : 2,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : false,
    "active" : true
  }, {
    "id" : "63034bc99e9eca069338e51f",
    "circleName" : "Bustani Gardens",
    "slug" : "bustani-gardens",
    "country" : "Afghanistan",
    "type" : "Accountant",
    "superAdminId" : "62fcddf0aa03957009310dd4",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 1,
    "followerIds" : [ "62fcddf0aa03957009310dd4", "60a91dfb7798225eccd3fe0b" ],
    "followerCount" : 2,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : true,
    "active" : false
  }, {
    "id" : "6303333cc104f118eff1e0e0",
    "circleName" : "RESEARCH  CONSERVATION",
    "slug" : "research-conservation",
    "country" : "Afghanistan",
    "type" : "Accountant",
    "superAdminId" : "62fcd3d5aa03957009310dc8",
    "adminids" : [ ],
    "memberIds" : [ "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff564f64c0cb0e52560d7f", "62ff56dd64c0cb0e52560d86", "62ff4b96ff95012ea008e415", "62ff4d68740d8f3a0090dd34", "62ff55b564c0cb0e52560d78", "62ff56dd64c0cb0e52560d86", "62ff564f64c0cb0e52560d7f", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "6229ff70f6e3534b31a5cb54", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62b30be2b12ce544ef4fdb30", "62c5d6019b23f04615f77aca", "607d15473aa362564d429d23", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "621dadc98fc6aa62430735b0", "608b99996e6d041717e8fd52", "60a91dfb7798225eccd3fe0b", "607d15473aa362564d429d23", "608b99996e6d041717e8fd52", "6110d9bb0ef92063d7d9415a", "6152ed21b686f0136000cd91", "61b984f2ac937a46df17a06e", "621dadc98fc6aa62430735b0", "62a8d53e30c718229fc0fd7d", "62c5d6019b23f04615f77aca", "62b30be2b12ce544ef4fdb30" ],
    "memberCount" : 1,
    "followerIds" : [ "62fcd3d5aa03957009310dc8", "61b984f2ac937a46df17a06e" ],
    "followerCount" : 2,
    "projectCount" : 0,
    "posts" : 0,
    "createdOn" : "08/22/2022",
    "deactivated" : false,
    "active" : false
  }
];
