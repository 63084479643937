import axios from "axios";
import React, { Component } from "react";
import styled from "styled-components";
import Button from "../../components/Buttons/button";
import styles from "../wpond/UpcomingYear/UpcomingYearList/year.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CommonHeader } from "../../utils/commonHeader";
import CsvParse from 'papaparse';
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import { epochTogmt } from "../../utils/common";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const uploadUserDataSwal = (failureCount, totalCount) => {
    return ({
        title: `${failureCount === 0 ? `${totalCount} is successfully uploaded. Please review the list for details.` : `${failureCount}/${totalCount} data failed to upload. Please review the list for details.`}`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
    })
}

const headers = [
    { label: "Email", key: "email" },
    { label: "Upload Status", key: "status" },
    { label: "Reason", key: "reason" }
];

export class UpdateUserManual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachmentUserData: [],
            uploadAttachmentFlag: false,
            errors: {},
            uploadedUserListData: [],
            fileName: ""
        };
    }

    uploadAttachment = (e) => {
        let maxFileSize = 2 * 1024 * 1024;
        if (e.target.files && e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            if (fileSize > maxFileSize) {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    }
                }).then((result) => {
                    return ErorrMsgPopUp.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        title: <><p>{'Please upload the file less than 100MB'}</p></>,
                    })
                })
                document.getElementById("attachmentFileUploader").value = null;
                setTimeout(() => {
                    this.setState({
                        error: ''
                    });
                }, 5000);
            } else {
                const fileName = document.getElementById("attachmentFileUploader").value;
                const idxDot = fileName.lastIndexOf(".") + 1;
                const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                const allowedAttachmentsTypeArr = ['csv'];
                if (allowedAttachmentsTypeArr.includes(extFile)) {
                    const file = e.target.files[0];
                    CsvParse.parse(file, {
                        complete: (result) => {
                            let userDataList = this.bulkUserDataUpdate(result?.data);
                            userDataList = userDataList && userDataList.filter((data) => data?.email);
                            this.setState({
                                attachmentUserData: userDataList,
                                uploadAttachmentFlag: true
                            })
                        },
                        header: true,
                    });
                } else {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm();
                        }
                    }).then((result) => {
                        return ErorrMsgPopUp.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: <><p>{'Only CSV files are accepted'}</p></>,
                        })
                    })
                    document.getElementById("attachmentFileUploader").value = null;

                }
            }
        }
    }

    removeAttachment = () => {
        this.setState({
            uploadAttachmentFlag: false,
            attachmentUserData: []
        })
    }

    bulkUserDataUpdate = (userDataList) => {
        userDataList = userDataList.map(function (obj) {
            obj['email'] = obj['Email'];
            delete obj['Email'];
            return obj;
        });
        return userDataList;
    }

    getDuplicates = (arr, key) => {
        const keys = arr.map(item => item[key]);
        return keys.filter(key => keys.indexOf(key) !== keys.lastIndexOf(key))
    }

    download_same_csv = (evt, url) => {
        evt.preventDefault();
        var parsedUrl = url.substring(url.lastIndexOf("/") + 1);
        let filename = parsedUrl.substring(parsedUrl.indexOf("-") + 1);
        FileSaver.saveAs(url, filename);
    };

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.attachmentUserData?.length === 0) {
            errors["attachmentUserData"] = !this.state.uploadAttachmentFlag ? "Please upload attachment" : "There is no data in the file you are trying to upload.";
            formIsValid = false;
        }
        if (this.state.attachmentUserData?.length > 0) {
            let validation = this.state.attachmentUserData.filter((item) => !item.email);
            let emailDupicateData = this.getDuplicates(this.state.attachmentUserData, "email");
            if (validation?.length > 0) {
                errors["attachmentUserData"] = "Some fields are missing";
                formIsValid = false;
            } else if (emailDupicateData?.length > 0) {
                errors["attachmentUserData"] = "Email should be unique";
                formIsValid = false;
            }
        }

        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    onSubmit = async () => {
        if (this.validateForm()) {
            this.setState({
                isBtnShowDisabled: true
            })
            let postData = {
                email: this.state?.attachmentUserData?.map((data) => data?.email)
            };
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/user/manual/update`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response.data.message === "Success!" && response?.data?.body?.length > 0) {
                    this.setState({ isBtnShowDisabled: false })
                    const provideLicenceConfirm = async () => {
                        const failureCount = response?.data?.body && response?.data?.body.filter((data) => data.status === "FAILURE")?.length;
                        const swalval = await ErorrMsgPopUp.fire(uploadUserDataSwal(failureCount, response?.data?.body?.length));
                        if (swalval && swalval.isConfirmed) {
                            let dateMs = new Date().valueOf();
                            const file = "upload_user_list-" + epochTogmt(dateMs) + "-" + dateMs + ".csv"
                            this.setState({
                                uploadedUserListData: response?.data?.body && response?.data?.body.map((data) => { return { "email": data?.email, "status": data?.status, "reason": data?.reason } }),
                                fileName: file,
                                attachmentUserData: [],
                                uploadAttachmentFlag: false
                            }, () => {
                                this.csvLink.link.click();
                            });
                        }
                    };
                    provideLicenceConfirm();
                }
            }).catch((error) => {
                console.log(error);
                if (error.response?.data?.status === "409 CONFLICT") {
                    this.setState({
                        isBtnShowDisabled: false,
                    })
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm();
                        }
                    }).then((result) => {
                        return ErorrMsgPopUp.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            title: <><p>{error?.response?.message}</p></>,
                        })
                    })
                }
            });
        }
    }

    render() {
        const { errors, uploadedUserListData, uploadAttachmentFlag, fileName } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <h3>Update User Manual</h3>
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <div className='promoContWrap'>
                                        {!uploadAttachmentFlag ?
                                            <div className='addPC'>
                                                <a href="javascript:void(0)" onClick={(e) => { this.refs.attachmentUploader.click(); }}>
                                                    <input
                                                        type="file"
                                                        accept=".csv"
                                                        id="attachmentFileUploader"
                                                        onChange={(e) => this.uploadAttachment(e)}
                                                        ref="attachmentUploader"
                                                        style={{ display: "none" }}
                                                    />
                                                    <button to="/testimonials/create" className={`btn btn-primary`}>
                                                        <i className="iAddCircle"></i> Upload
                                                    </button>
                                                </a>
                                            </div> :
                                            <div className="previewPan">
                                                <span><img src={"https://cdn.dscovr.com/images/csv-icon.webp"} alt="" /></span>
                                                <i className="iCross_Rounded closebtn" onClick={(e) => this.removeAttachment()}></i>
                                            </div>
                                        }
                                        {errors["attachmentUserData"] && <div className="login-feedback-error mt-1" style={{ display: "block" }}>{errors["attachmentUserData"]}</div>}
                                    </div>
                                </div>
                            </div>

                            {uploadAttachmentFlag &&
                                <ButtonWrap className={`${styles.bottombutt}`}>
                                    <Button children={"Submit"} onClick={this.onSubmit} />
                                </ButtonWrap>
                            }
                        </div>
                    </div>
                </div>
                <CSVLink headers={headers} data={uploadedUserListData} filename={fileName} ref={(r) => (this.csvLink = r)}></CSVLink>
            </CommonHeader>
        );
    }
}

export default UpdateUserManual;