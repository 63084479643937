import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { tableConstants } from "./Data/tableConstants";
import { data } from "./Data/mockData";
import { customStyles } from "../../../utils/tableCustomStyle";
import { FilterComponent } from "../../../utils/filter";
import Select from "react-select";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import styles from "../individual/individual_details.module.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "../../../components/Buttons/button";
import { DateRange } from "react-date-range";
import { utcTodate, epochTogmt, fundRaiseConfirmModalSwal } from "../../../utils/common";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CSVDownload, CSVLink } from "react-csv";
import { auth_service } from "../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Reactivate this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "Organization Name", key: "organizationName" },
  { label: "Status", key: "status" },
  { label: "Subscription", key: "subscriptionType" },
  { label: "Custom Url", key: "customUrl" },
  { label: "Email", key: "email" },
  { label: "Email Verified", key: "emailVerified" },
  { label: "Country Code", key: "countryCode" },
  { label: "Phone No.", key: "phoneNo" },
  //{ label: "Mobile Verified", key: "mobileVerified" },
  { label: "Country", key: "country" },
  { label: "Admin", key: "noOfAdmins" },
  { label: "No of Employee", key: "noOfEmployees" },
  { label: "No of followers", key: "noOfFollowers" },
  { label: "No of Projects", key: "noOfProjects" },
  { label: "No of Posts", key: "noOfPosts" },
  { label: "Created on", key: "createdOn" },
  { label: "Last Login", key: "lastLogin" },
];

// status data
const statusOptions = [
  { value: "ALL", label: "ALL" },
  { value: "DRAFT", label: "DRAFT" },
  { value: "COMPLETED", label: "COMPLETED" },
  { value: "DELETED", label: "DELETED" },
];

// date range data
const options = [
  { value: "ALL", label: "ALL" },
  { value: "TODAY", label: "TODAY" },
  { value: "YESTERDAY", label: "YESTERDAY" },
  { value: "LAST7DAY", label: "LAST 7 DAY" },
  { value: "LAST14DAY", label: "LAST 14 DAY" },
  { value: "LASTMONTH", label: "LAST MONTH" },
  { value: "CUSTOM", label: "CUSTOM" },
];
export class OrganizationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      organizationList: [],
      exportOrganizationList: [],
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRangeButton: false,
      displayRange: false,
      filterText: "",
      filterTextOne: "",
      filterDisplay: false,
      filterData: "",
      statusData: { value: "ALL", label: "ALL" },
      dateData: { value: "ALL", label: "ALL" },
      isEnabledCancelFiler: false,
      loading: false,
      totalRows: 0,
      perPage: 10,
      changeType: 'ALL',
      exportOrganizationFlag: false,
      isSearchDisabled: true
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <div class="dgdropdown">
          <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">Search by<strong>{this.state.filterData}</strong></button>
          <div class="dropdown-menu fadeIn animated">
            <a onClick={e => { this.changeSearchData("Name") }} class="dropdown-item">Name</a>
            <a onClick={e => { this.changeSearchData("Country") }} class="dropdown-item">Country</a>
            <a onClick={e => { this.changeSearchData("Email") }} class="dropdown-item">Email</a>
          </div>
        </div>
        <div>
          <FilterComponent
            onFilter={this.handleChangeFilter}
            searchType={'Search by ' + this.state.filterData.toLowerCase()}
            onClear={this.handleClear}
            filterText={this.state.filterText}
          />
          <span class="search-close" onClick={() => this.clearSearch()}></span>
        </div>
      </React.Fragment>
    );
  };

  changeSearchData = (data) => {
    this.setState({
      filterData: data,
      filterText: "",
      isSearchDisabled: true
    });
  }

  clearSearch = () => {
    this.setState({
      filterData: "",
      filterText: "",
      isSearchDisabled: true
    },()=> {
      this.searchOrganizationUsersDetails();
    });
  }

  handleChangeFilter = (e) => {
    this.setState({
      filterText: e.target.value.trimStart(),
      isSearchDisabled: e.target.value === "" ? true : false
    })
  }

  handleStatusChange = (selectStatus) => {
    if (this.state.dateData.value === "CUSTOM") {
      this.setState({
        statusData: { value: selectStatus.value, label: selectStatus.value },
        isEnabledCancelFiler: false,
        changeType: 'DATE'
      }, () => {
        this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, 0);
      });
    } else {
      this.setState({
        statusData: { value: selectStatus.value, label: selectStatus.value },
        isEnabledCancelFiler: true,
        changeType: 'STATUS'
      }, () => {
        this.fetchFilterOraganizationList(this.state.dateData.value, this.state.statusData.value, 0);
      })
    }
  }

  // date range change
  handleDateChange = (newValue) => {
    if (newValue.value === "CUSTOM") {
      this.setState({
        dateData: {value: newValue.value, label: newValue.label},
        displayRangeButton: true,
        isEnabledCancelFiler: false,
        changeType: 'DATE'
      });
    } else {
      this.setState({
        dateData: {value: newValue.value, label: newValue.label},
        displayRangeButton: false,
        isEnabledCancelFiler: true,
        changeType: 'DATE'
      }, () => {
        this.fetchFilterOraganizationList(this.state.dateData.value, this.state.statusData.value, 0);
      })
    }
  };

  fetchFilterOraganizationList = (date, status, page) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/user/organization/search-by-date-status/?page=${page}&size=${this.state.perPage}&date=${date}&status=${status}&type=${this.state.filterData ? this.state.filterData : null}&searchParam=${this.state.filterText ? this.state.filterText : null}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            organizationList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          organizationList: [],
          loading: false
        })
      });
  };

  // fetch date from date range
  fetchFromDateRange = (startDate, endDate, page) => {
    this.setState({
      displayRange: false
      //displayRangeButton: false,
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/user/organization/search-by-date-status/?page=" +page + "&size="+this.state.perPage+"&date=CUSTOM&status="+this.state.statusData.value+"&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate)+"&type="+(this.state.filterData ? this.state.filterData : null)+"&searchParam="+(this.state.filterText ? this.state.filterText : null),
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            organizationList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          organizationList: [],
          loading: false
        })
      });
  };

  // display range select
  displayRangeSelect = () => {
    this.setState({
      displayRange: this.state.displayRange ? false : true,
    });
  };

  // handle range select
  handleSelect = (range) => {
    this.setState(
      {
        date: {
          ...this.state.date,
          startDate: range.selection.startDate,
          endDate: range.selection.endDate,
          key: range.selection.key,
        },
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  //Cancel filer
  cancelFilter = () => {
    this.setState({
      isEnabledCancelFiler: false,
      statusData: { value: "ALL", label: "ALL" },
      dateData: { value: "ALL", label: "ALL" },
      filterData: "",
      filterText: "",
      isSearchDisabled: true
    });
    this.fetchOrganizationUsersDetails(0);
  }

  //Cancel date dange
  cancelDateRange = () => {
    this.setState({
      displayRangeButton: false,
      isEnabledCancelFiler: false,
      statusData: { value: "ALL", label: "ALL" },
      dateData: { value: "ALL", label: "ALL" },
      filterData: "",
      filterText: "",
      isSearchDisabled: true
    })
    this.fetchOrganizationUsersDetails(0);
  }

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        <button className={"btn addRoleGlobal"} onClick={(e) => this.searchOrganizationUsersDetails()} disabled={this.state.isSearchDisabled}><i class="fa fa-search" /> Search</button>
        {auth_service.getPermission("AD_Organization_ExportCSV") && <a className={"btn addRoleGlobal"} href="#exportCsvModal" data-toggle="modal"><i class="iDownloadFile" /> Export</a>}
      </React.Fragment>
    );
  };

  fetchOrganizationUsersDetails = async page => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/user/organization?page=${page}&size=${this.state.perPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            organizationList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          organizationList: [],
          loading: false
        })
      });
  };

  searchOrganizationUsersDetails = () => {
    if (this.state.dateData.value === "CUSTOM") {
      this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, 0);
    } else {
      this.fetchFilterOraganizationList(this.state.dateData.value, this.state.statusData.value, 0);
    }
  };

  handlePageChange = page => {
    if(this.state.changeType === "STATUS" || (this.state.changeType === "DATE" && this.state.dateData.value !== "CUSTOM")) {
      this.fetchFilterOraganizationList(this.state.dateData.value, this.state.statusData.value, page-1);
    } else if(this.state.changeType === "DATE" && this.state.dateData.value === "CUSTOM") {
      this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, page-1);
    } else if(this.state.filterData || this.state.filterText) {
      this.fetchFilterOraganizationList(this.state.dateData.value, this.state.statusData.value, page-1);
    } else {
      this.fetchOrganizationUsersDetails(page-1);
    }
  };

  handlePerRowsChange = async (newPerPage, page) => {
    let url = '';
    if(this.state.changeType === "STATUS" || (this.state.changeType === "DATE" && this.state.dateData.value !== "CUSTOM")) {
      url = `${process.env.REACT_APP_userServiceURL}/user/organization/search-by-date-status/?page=${page-1}&size=${newPerPage}&date=${this.state.dateData.value}&status=${this.state.statusData.value}&type=${this.state.filterData ? this.state.filterData : null}&searchParam=${this.state.filterText ? this.state.filterText : null}`;
    } else if(this.state.changeType === "DATE" && this.state.dateData.value === "CUSTOM") {
      url = process.env.REACT_APP_userServiceURL + "/user/organization/search-by-date-status/?page=" +(page-1) + "&size="+newPerPage+"&date=CUSTOM&status=" + this.state.statusData.value + "&startDate=" +utcTodate(this.state.date.startDate) +"&endDate=" +utcTodate(this.state.date.endDate)+"&type="+(this.state.filterData ? this.state.filterData : null)+"&searchParam="+(this.state.filterText ? this.state.filterText : null);
    } else if(this.state.filterData || this.state.filterText) {
      url = `${process.env.REACT_APP_userServiceURL}/user/organization/search-by-date-status/?page=${page-1}&size=${newPerPage}&date=${this.state.dateData.value}&status=${this.state.statusData.value}&type=${this.state.filterData ? this.state.filterData : null}&searchParam=${this.state.filterText ? this.state.filterText : null}`;
    } else {
      url = `${process.env.REACT_APP_userServiceURL}/user/organization?page=${page-1}&size=${newPerPage}`;
    }
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            organizationList: response.data.body && response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          organizationList: [],
          loading: false
        })
      });
  };

  closeDateRange = () => {
    this.setState({
      displayRange: false
    })
  }

  exportButton = (startDate, endDate) => {
    this.setState({
      displayRange: false
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/user/organization/search-by-date-status/?page=0&size=2000&date=CUSTOM&status="+this.state.statusData.value+"&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate)+"&type="+(this.state.filterData ? this.state.filterData : null)+"&searchParam="+(this.state.filterText ? this.state.filterText : null),
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          let date = new Date();
          let dateMs = date.valueOf();
          const file = "organization_list-"+epochTogmt(dateMs)+"-"+dateMs+".csv"
          this.setState({
            exportOrganizationList: response.data.body && response.data.body.content,
            exportOrganizationFlag: true,
            fileName: file
          }, () => {
            this.csvLink.link.click();
          });
          window.$("#exportCsvModal").modal('hide');
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.status === "404 NOT_FOUND") {
          window.$("#exportCsvModal").modal('hide');
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>Organization not found in this date range.</p>)
          })
        }
        this.setState({
          exportOrganizationList: [],
          exportOrganizationFlag: false
        });
      });
  };

  exportCsvModalClose = () => {
    this.setState({
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRange: false
    })
  }

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/deactivation/reactivateProfile?id=${id}&adminId=${this.state.userData.id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              let organizationLItemist = this.state.organizationList;
              const index = organizationLItemist && organizationLItemist.findIndex(data => data.userId === id);
              if (index > -1) {
                organizationLItemist.splice(index, 1); 
              }
              this.setState({
                organizationList: organizationLItemist
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  handleRestrictFundRaise = (id, flag) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(fundRaiseConfirmModalSwal(flag));
      if (swalval && swalval.value === true) {
        let postData = {
          entityId: id,
          entityType: "BUSINESS_PAGES",
          fundRestrictionType: "NONE",
          permissionAssigned: !flag
        }
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/fund-raise-restriction/create`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              let organizationLItemist = this.state.organizationList;
              const index = organizationLItemist && organizationLItemist.findIndex(data => data.userId === id);
              organizationLItemist[index].fundRaiseRestrictionPermission = !flag;
              this.setState({
                organizationList: organizationLItemist
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  }

  componentDidMount() {
    this.fetchOrganizationUsersDetails(0);
  }
  render() {
    const { organizationList, displayRange, displayRangeButton, date, isEnabledCancelFiler } = this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <Select
                      options={statusOptions}
                      placeholder={"Filter by status"}
                      value={this.state.statusData}
                      onChange={this.handleStatusChange}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      options={options}
                      placeholder={"ALL"}
                      value={this.state.dateData}
                      onChange={this.handleDateChange}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  {isEnabledCancelFiler &&
                    <div className="col-md-3">
                      <Button
                        onClick={(e) => {
                          this.cancelFilter();
                        }}
                        type={"anchor"}
                        children={`Clear Filter`}
                      />
                    </div>
                  }

                  {displayRangeButton && (
                    <>
                      <div className="col-md-5">
                        <Button
                          onClick={this.displayRangeSelect}
                          type={"range"}
                          children={`${utcTodate(
                            this.state.date.startDate
                          )} to ${utcTodate(this.state.date.endDate)}`}
                        />
                        <Button
                          onClick={(e) => {
                            this.fetchFromDateRange(
                              this.state.date.startDate,
                              this.state.date.endDate,
                              0
                            );
                          }}
                          type={"anchor"}
                          children={`Apply Filter`}
                        />
                        <Button
                          onClick={(e) => {
                            this.cancelDateRange();
                          }}
                          type={"anchor"}
                          children={`Cancel`}
                        />
                        {displayRange && (
                          <DateRange
                            ranges={[date]}
                            onChange={this.handleSelect}
                            maxDate={new Date()}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <DataTable
              title="Organization List"
              columns={tableConstants(this.handleConfirmModal, this.handleRestrictFundRaise)}
              data={organizationList}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 216px)"}

              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>

          {/* {this.state.exportOrganizationList && this.state.exportOrganizationList.length > 0 && this.state.exportOrganizationFlag &&
              <CSVDownload filename="sifter_results" headers={headers} data={this.state.exportOrganizationList} />
          } */}

          <CSVLink headers={headers} data={this.state.exportOrganizationList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

          {/* Export Button */}
          <div class="modal fade dgpopup" id="exportCsvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Select Date Rage</h5>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                        <Button
                          onClick={this.displayRangeSelect}
                          type={"range"}
                          children={`${utcTodate(
                            this.state.date.startDate
                          )} to ${utcTodate(this.state.date.endDate)}`}
                        />
                        <Button
                          onClick={(e) => {
                            this.closeDateRange();
                          }}
                          type={"anchor"}
                          children={`CLose`}
                        />
                        {this.state.displayRange && 
                          <DateRange
                            ranges={[date]}
                            onChange={this.handleSelect}
                            maxDate={new Date()}
                          />
                        }
                      </div>    
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.exportCsvModalClose} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={() => this.exportButton(this.state.date.startDate, this.state.date.endDate)}><i class="iDownloadFile" /> Export</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default OrganizationDetails;
