import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../../../components/StatusDetails/status_details";
import stripHtml from "string-strip-html";

export const tableAwardCategoriesConstants = (fetchAllAwardCategoriesList) => {
  return [
    {
      id: 1,
      name: "Section",
      cell: (row) => {
        let output = "";
        if (row.section === "PRO") {
          output = "Pro";
        } else {
          output = "Green childhood";
        }
        return (
          <Link to={`/wpond/${row.year}/award-categories/edit/${row.id}`}>
            <span className="sub">{output}</span>
          </Link>
        )
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Image",
      cell: (row) => {
        return (
          <img
            src={row.image}
            alt="coverImage"
            style={{ width: "100px", height: "56px" }}
          />
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Sponsors",
      cell: (row) => {
        return (
          row.sponsorImages ? <img
            src={row.sponsorImages}
            alt="coverImage"
            style={{ width: "100px", height: "56px" }}
          /> : "No image available"
        )
      },
      sortable: true,
      reorder: true,
    },
    // {
    //   id: 5,
    //   name: "Description",
    //   selector: (row) => stripHtml(row.description),
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 6,
      name: "Nominations received",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/award-categories/${row.slug}/nominations`}>
            <span className="sub">{row.nominationCount}</span>
          </Link>
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"wpondAwardCategories"}
            action={fetchAllAwardCategoriesList}
            fullPermission={"WPOND_AC_FullAccess"}
            approvalPermission={"WPOND_AC_Approval"}
            publishPermission={"WPOND_AC_Publish"}
            enablePermission={"WPOND_AC_Enable"}
            disablePermission={"WPOND_AC_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  ];
};
