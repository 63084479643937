import React, { Component } from "react";
import FormInput from "../../../components/InputFields/form_input";
import styles from "./CreateProjectSubscription/create_project_subscription.module.css";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import Select from "react-select";
import history from "../../../utils/history";
import httpService from "../../../AxiosInterceptors";
import style from "../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getSubscriptionType, subscriptionType } from "../CommonSubscriptionFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp)
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export class ProjectSubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectSubscriptionId: "",
            subscriptionType: "",
            organizatinCreatePrice: "",
            multipleAdminsPrice: "",
            invitePartPrice: "",
            raiseFundPrice: "",
            managePrivateProject: false,
            errors: {},
            error: false,
            message: null,
            studioContentFlag: false,
            topUpProjectFlag: false,
            topUpPostsFlag: false,
            studioNoProject: "",
            studioNoPostProject: "",
            topUpProjectAmount: "",
            topUpProjectAmountINR: "",
            topUpPostAmount: "",
            topUpPostAmountINR: "",
            projectTopUpAllow: false,
            projectTopUpAmountINR: "",
            projectTopUpAmountUSD: "",
            donateContentFlag: false,
            donateProjectCount: "",
            daysAllowed: "",
            donatenNoOfProject: "",
            donateAmountCircleINR: "",
            donateAmountCircleUSD: "",
            additionalDaysPlan: [{days: "", amountInINR: "", amointInUSD: ""}],
            donateNowTopAllowed: false,
            additionalDaysTopUpAllowed: false
        };
    }

    // validate create user
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.subscriptionType) {
            formIsValid = false;
            errors["subscriptionType"] = "Please enter subscription type";
        }
        if (!this.state.organizatinCreatePrice) {
            formIsValid = false;
            errors["organizatinCreatePrice"] = "Please enter create your organization";
        }
        if (!this.state.multipleAdminsPrice) {
            formIsValid = false;
            errors["multipleAdminsPrice"] = "Please enter multiple admins";
        }
        if (!this.state.invitePartPrice) {
            formIsValid = false;
            errors["invitePartPrice"] = "Please enter invite participants";
        }
        // if (!this.state.raiseFundPrice) {
        //     formIsValid = false;
        //     errors["raiseFundPrice"] = "Please enter raise funds";
        // }
        if (!this.state.invitePartOutWNPrice) {
            formIsValid = false;
            errors["invitePartOutWNPrice"] = "Please enter invite participants outside wenat";
        }
        if (this.state.managePrivateProject && !this.state.privateProjectCount) {
            formIsValid = false;
            errors["privateProjectCount"] = "Please enter no of private project.";
        }
        if (this.state.studioContentFlag && (!this.state.studioNoProject || !this.state.studioNoPostProject)) {
            formIsValid = false;
            if (!this.state.studioNoProject) {
                errors["studioNoProject"] = "Please enter no of project.";
            }
            if (!this.state.studioNoPostProject) {
                errors["studioNoPostProject"] = "Please enter no of post per project.";
            }
        }
        if (this.state.topUpProjectFlag) {
            if(!this.state.topUpProjectAmountINR) {
                formIsValid = false;
                errors["topUpProjectAmountINR"] = "Please enter amount per projects(INR).";
            }
            if(!this.state.topUpProjectAmount) {
                formIsValid = false;
                errors["topUpProjectAmount"] = "Please enter amount per projects(USD).";
            }
        }
        if (this.state.topUpPostsFlag) {
            if(!this.state.topUpPostAmountINR) {
                formIsValid = false;
                errors["topUpPostAmountINR"] = "Please enter amount per projects(INR).";
            }
            if(!this.state.topUpPostAmount) {
                formIsValid = false;
                errors["topUpPostAmount"] = "Please enter amount per projects(USD).";
            }
        }
        if (this.state.projectTopUpAllow) {
            if(!this.state.projectTopUpAmountINR) {
                formIsValid = false;
                errors["projectTopUpAmountINR"] = "Please enter amount per projects(INR).";
            } 
            if(!this.state.projectTopUpAmountUSD) {
                formIsValid = false;
                errors["projectTopUpAmountUSD"] = "Please enter amount per projects(USD).";
            }
            
        }
        if(this.state.donateContentFlag) {
            if (!this.state.donateProjectCount) {
                formIsValid = false;
                errors["donateProjectCount"] = "Please enter circle count.";
            }
            if (!this.state.daysAllowed) {
                formIsValid = false;
                errors["daysAllowed"] = "Please enter days allowed.";
            }
            /* if (!this.state.donatenNoOfProject) {
                formIsValid = false;
                errors["donatenNoOfProject"] = "Please enter no of circles.";
            } */
            if(this.state.donateNowTopAllowed) {
                if (!this.state.donateAmountCircleINR) {
                    formIsValid = false;
                    errors["donateAmountCircleINR"] = "Please enter amount per Projects(INR).";
                }
                if (!this.state.donateAmountCircleUSD) {
                    formIsValid = false;
                    errors["donateAmountCircleUSD"] = "Please enter amount per Projects(USD).";
                }
            }
            if(this.state.additionalDaysTopUpAllowed) {
                this.state.additionalDaysPlan && this.state.additionalDaysPlan.map((data, index) => {
                    const newArray = this.state.additionalDaysPlan;
                    if (!data.days) {
                        newArray[index].daysErrorMessage = "Please enter additional top up days.";
                        formIsValid = false;
                    }
                    if (!data.amountInINR) {
                        newArray[index].amountInINRErrorMessage = "Please enter amount per Projects(INR).";
                        formIsValid = false;
                    }
                    if (!data.amointInUSD) {
                        newArray[index].amointInUSDErrorMessage = "Please enter amount per Projects(INR).";
                        formIsValid = false;
                    }
                    this.setState({
                        additionalDaysPlan: newArray
                    })
                    setTimeout(() => {
                        newArray[index].daysErrorMessage = null;
                        newArray[index].amountInINRErrorMessage = null;
                        newArray[index].amointInUSDErrorMessage = null;
                        this.setState({
                            additionalDaysPlan: newArray
                        })
                    }, 5000);
                });
            }
        }
        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    // handle value change
    onHandleValueChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value.trimStart(),
            });
        }
    };

    subscriptionType = (type) => {
        this.setState({ subscriptionType: type });
    };

    addAdditionalTopUp = () => {
        let additionalDaysData = this.state.additionalDaysPlan;
        additionalDaysData.push({days: "", amountInINR: "", amointInUSD: ""});
        this.setState({
            additionalDaysPlan: additionalDaysData
        })
    }

    removeAdditiionaldays = (index) => {
        let additionalDaysData = this.state.additionalDaysPlan;
        additionalDaysData.splice(index, 1);
        this.setState({
            additionalDaysPlan: additionalDaysData
        })
    }

    handleChangeAdditionalDaysPlan = (event, index) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            let additionalDaysData = this.state.additionalDaysPlan;
            if(event.target.name === "days") {
                additionalDaysData[index].days = event.target.value
            } else if(event.target.name === "amountInINR") {
                additionalDaysData[index].amountInINR = event.target.value
            } else {
                additionalDaysData[index].amointInUSD = event.target.value
            }
            this.setState({
                additionalDaysPlan: additionalDaysData
            });
        }
    }

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                type: this.props.formType === "CREATE" ? this.state.subscriptionType.value : this.state.subscriptionType.value,
                create: this.state.organizatinCreatePrice,
                admins: this.state.multipleAdminsPrice,
                inviteParticipants: this.state.invitePartPrice,
                inviteParticipantsOutsideWN: this.state.invitePartOutWNPrice,
                raiseFund: this.state.raiseFundPrice,
                managePrivateProject: this.state.managePrivateProject,
                studioContentAllow: this.state.studioContentFlag,
                privateProjectCount: this.state.privateProjectCount,
                projectTopUpAllow: this.state.projectTopUpAllow,
                donateNow: this.state.donateContentFlag
            };
            if (this.state.studioContentFlag) {
                let studioContentData = {
                    projects: this.state.studioNoProject,
                    postPerProjects: this.state.studioNoPostProject,
                    topUpProject: this.state.topUpProjectFlag,
                    topUpPost: this.state.topUpPostsFlag
                }
                if (this.state.topUpProjectFlag) {
                    studioContentData.topUpPerProject = this.state.topUpProjectAmount;
                    studioContentData.topUpPerProjectINR = this.state.topUpProjectAmountINR;
                }
                if (this.state.topUpPostsFlag) {
                    studioContentData.topUpPerPost = this.state.topUpPostAmount;
                    studioContentData.topUpPerPostINR = this.state.topUpPostAmountINR;
                }
                postData.studioContent = studioContentData;
            }
            if (this.state.projectTopUpAllow) {
                let projectTopUpContentData = {
                    amountPerProjectINR: this.state.projectTopUpAmountINR,
                    amountPerProjectUSD: this.state.projectTopUpAmountUSD
                }
                postData.projectTopUpContent = projectTopUpContentData
            }
            if(this.state.donateContentFlag) {
                let donateNowContent = {
                    count: this.state.donateProjectCount,
                    daysAllowed: this.state.daysAllowed,
                    donateNowTopAllowed: this.state.donateNowTopAllowed,
                    additionalDaysTopUpAllowed: this.state.additionalDaysTopUpAllowed
                }
                if(this.state.donateNowTopAllowed) {
                    let donateNowTopup = {
                        count: this.state.donatenNoOfProject,
                        amountInINR: this.state.donateAmountCircleINR,
                        amointInUSD: this.state.donateAmountCircleUSD
                    }
                    donateNowContent.donateNowTopup = donateNowTopup;
                    if(this.state.additionalDaysTopUpAllowed) {
                        donateNowContent.additionalDaysPlan = this.state.additionalDaysPlan;
                    }
                }

                postData.donateNowContent = donateNowContent;
            }
            if (this.props.formType === "EDIT") {
                postData.id = this.state.projectSubscriptionId;
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/subscription/${this.props.formType === "EDIT" ? "project_update" : "project_create"}`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.message === "Success!"
                    ) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: false
                });
            }, 5000);
        }
    };

    agreeHandleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
        if(event.target.name === "additionalDaysTopUpAllowed" && !event.target.checked) {
            this.setState({
                additionalDaysPlan: [{days: "", amountInINR: "", amointInUSD: ""}]
            })
        }
    }

    fetchProjectSubscriptionById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/subscription/project/get?id=${this.props.id}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response && response.data && response.data.body &&
                    response.data.status === "200 OK"
                ) {
                    let data = response.data.body;
                    this.setState({
                        projectSubscriptionId: data.id,
                        subscriptionType: { value: data.type, label: getSubscriptionType(data.type) },
                        organizatinCreatePrice: data.create,
                        raiseFundPrice: data.raiseFunds,
                        invitePartPrice: data.inviteParticipants,
                        invitePartOutWNPrice: data.inviteParticipantsOutsideWN,
                        multipleAdminsPrice: data.admins,
                        managePrivateProject: data.managePrivateProject,
                        studioContentFlag: data.studioContentAllow,
                        topUpProjectFlag: data.studioContent && data.studioContent.topUpProject,
                        topUpPostsFlag: data.studioContent && data.studioContent.topUpPost,
                        studioNoProject: data.studioContent && data.studioContent.projects,
                        studioNoPostProject: data.studioContent && data.studioContent.postPerProjects,
                        topUpProjectAmount: data.studioContent && data.studioContent.topUpPerProject,
                        topUpPostAmount: data.studioContent && data.studioContent.topUpPerPost,
                        privateProjectCount: data.privateProjectCount,
                        projectTopUpAllow: data.projectTopUpAllow,
                        projectTopUpAmountINR: data.projectTouUpContent && data.projectTouUpContent.amountPerProjectINR,
                        projectTopUpAmountUSD: data.projectTouUpContent && data.projectTouUpContent.amountPerProjectUSD,
                        topUpPostAmountINR: data.studioContent && data.studioContent.topUpPerPostINR,
                        topUpProjectAmountINR: data.studioContent && data.studioContent.topUpPerProjectINR,
                        donateContentFlag: data.donateNow,
                        donateNowTopAllowed: data?.donateNowContent?.donateNowTopAllowed,
                        additionalDaysTopUpAllowed: data?.donateNowContent?.additionalDaysTopUpAllowed,
                        donateProjectCount: data.donateNowContent && data.donateNowContent.count,
                        daysAllowed: data.donateNowContent && data.donateNowContent.daysAllowed,
                        donatenNoOfProject: data.donateNowContent && data.donateNowContent.donateNowTopup && data.donateNowContent.donateNowTopup.count,
                        donateAmountCircleINR: data.donateNowContent && data.donateNowContent.donateNowTopup && data.donateNowContent.donateNowTopup.amountInINR,
                        donateAmountCircleUSD: data.donateNowContent && data.donateNowContent.donateNowTopup && data.donateNowContent.donateNowTopup.amointInUSD,
                        additionalDaysPlan: data.donateNowContent && data.donateNowContent.additionalDaysPlan ? data.donateNowContent.additionalDaysPlan : [{days: "", amountInINR: "", amointInUSD: ""}]
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentDidMount() {
        if (this.props.formType === "EDIT") {
            this.fetchProjectSubscriptionById();
        }
    }

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    render() {
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="d-flex align-items-center">Subscription Type <span className="text-danger">*</span></label>
                        <Select
                            value={this.state.subscriptionType}
                            options={subscriptionType}
                            onChange={this.subscriptionType}
                            placeholder={"Start typing"}
                            closeMenuOnSelect={true}
                            filterOption={(option) => option.value !== "ALL"}
                            isDisabled={this.props.formType === "EDIT" ? true : false}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {this.state.errors["subscriptionType"] && (
                            <small className={style.danger}>
                                {this.state.errors["subscriptionType"]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.organizatinCreatePrice}
                            label={"Create & Organize your projects"}
                            onChange={this.onHandleValueChange}
                            name={"organizatinCreatePrice"}
                            error={this.state.errors["organizatinCreatePrice"]}
                        />
                    </div>
                    {/* <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.raiseFundPrice}
                            label={"Raise funds"}
                            onChange={this.onHandleValueChange}
                            name={"raiseFundPrice"}
                            error={this.state.errors["raiseFundPrice"]}
                        />
                    </div> */}
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.invitePartPrice}
                            label={"Invite participants"}
                            onChange={this.onHandleValueChange}
                            name={"invitePartPrice"}
                            error={this.state.errors["invitePartPrice"]}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.invitePartOutWNPrice}
                            label={"Invite participants outside WeNat"}
                            onChange={this.onHandleValueChange}
                            name={"invitePartOutWNPrice"}
                            error={this.state.errors["invitePartOutWNPrice"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"largoutsidee"}
                            value={this.state.multipleAdminsPrice}
                            label={"Multiple admins"}
                            onChange={this.onHandleValueChange}
                            name={"multipleAdminsPrice"}
                            error={this.state.errors["multipleAdminsPrice"]}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <div className={`form-group`} style={{ marginTop: '40px' }}>
                            <label class="switch">
                                Manage Private Project
                                <input
                                    type="checkbox"
                                    ref="managePrivateProject"
                                    name="managePrivateProject"
                                    onClick={(e) => {
                                        this.agreeHandleChange(e);
                                    }}
                                    checked={this.state.managePrivateProject}
                                />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                {this.state.managePrivateProject && <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"largoutsidee"}
                            value={this.state.privateProjectCount}
                            label={"No. of Private Project"}
                            onChange={this.onHandleValueChange}
                            name={"privateProjectCount"}
                            error={this.state.errors["privateProjectCount"]}
                        />
                    </div>
                </div>}

                <hr />

                <div className={`form-group`}>
                    <label class="switch">
                        Studio Content
                        <input
                            type="checkbox"
                            ref="studioContentFlag"
                            name="studioContentFlag"
                            onClick={(e) => {
                                this.agreeHandleChange(e);
                            }}
                            checked={this.state.studioContentFlag}
                        />
                        <span class="slider round"></span>
                    </label>
                </div>

                {this.state.studioContentFlag &&
                    <div className="stsubscripForm">
                        <div className="form-group row align-items-center">
                            <div className="col-md-7">
                                <div className="colMarge">
                                    <FormInput
                                        type={'number'}
                                        group={"large"}
                                        label={"No of Projects"}
                                        value={this.state.studioNoProject}
                                        name={"studioNoProject"}
                                        onChange={this.onHandleValueChange}
                                        error={this.state.errors["studioNoProject"]}
                                    />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label class="switch">
                                    Topup Project
                                    <input
                                        type="checkbox"
                                        ref="topUpProjectFlag"
                                        name="topUpProjectFlag"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={this.state.topUpProjectFlag}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.topUpProjectFlag &&
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <FormInput
                                        group={"large"}
                                        label={"Amount per Projects(INR)"}
                                        value={this.state.topUpProjectAmountINR}
                                        name={"topUpProjectAmountINR"}
                                        onChange={this.onHandleValueChange}
                                        error={this.state.errors["topUpProjectAmountINR"]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FormInput
                                        group={"large"}
                                        label={"Amount per Projects(USD)"}
                                        value={this.state.topUpProjectAmount}
                                        name={"topUpProjectAmount"}
                                        onChange={this.onHandleValueChange}
                                        error={this.state.errors["topUpProjectAmount"]}
                                    />
                                </div>
                            </div>}

                        <div className="form-group row align-items-center">
                            <div className="col-md-7">
                                <div className="colMarge">
                                    <FormInput
                                        type={'number'}
                                        group={"large"}
                                        label={"No of Post per Projects"}
                                        value={this.state.studioNoPostProject}
                                        name={"studioNoPostProject"}
                                        onChange={this.onHandleValueChange}
                                        error={this.state.errors["studioNoPostProject"]}
                                    />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label class="switch">
                                    Topup Posts
                                    <input
                                        type="checkbox"
                                        ref="topUpPostsFlag"
                                        name="topUpPostsFlag"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={this.state.topUpPostsFlag}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.topUpPostsFlag &&
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <FormInput
                                        group={"large"}
                                        label={"Amount per Post(INR)"}
                                        value={this.state.topUpPostAmountINR}
                                        name={"topUpPostAmountINR"}
                                        onChange={this.onHandleValueChange}
                                        error={this.state.errors["topUpPostAmountINR"]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FormInput
                                        group={"large"}
                                        label={"Amount per Post(USD)"}
                                        value={this.state.topUpPostAmount}
                                        name={"topUpPostAmount"}
                                        onChange={this.onHandleValueChange}
                                        error={this.state.errors["topUpPostAmount"]}
                                    />
                                </div>
                            </div>}
                    </div>
                }

                <hr />

                <div className={`form-group`}>
                    <label class="switch">
                        Project Topup Content
                        <input
                            type="checkbox"
                            ref="projectTopUpAllow"
                            name="projectTopUpAllow"
                            onClick={(e) => {
                                this.agreeHandleChange(e);
                            }}
                            checked={this.state.projectTopUpAllow}
                        />
                        <span class="slider round"></span>
                    </label>
                </div>

                {this.state.projectTopUpAllow &&
                    <div className="stsubscripForm">
                        <div className="form-group row">
                            <div className="col-md-6">
                                <FormInput
                                    group={"large"}
                                    label={"Amount per Projects(INR)"}
                                    value={this.state.projectTopUpAmountINR}
                                    name={"projectTopUpAmountINR"}
                                    onChange={this.onHandleValueChange}
                                    error={this.state.errors["projectTopUpAmountINR"]}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    group={"large"}
                                    label={"Amount per Projects(USD)"}
                                    value={this.state.projectTopUpAmountUSD}
                                    name={"projectTopUpAmountUSD"}
                                    onChange={this.onHandleValueChange}
                                    error={this.state.errors["projectTopUpAmountUSD"]}
                                />
                            </div>
                        </div>
                    </div>
                }

                <hr />

                <div className={`form-group`}>
                    <label class="switch">
                        Donation Content
                        <input
                            type="checkbox"
                            ref="donateContentFlag"
                            name="donateContentFlag"
                            onClick={(e) => {
                                this.agreeHandleChange(e);
                            }}
                            checked={this.state.donateContentFlag}
                        />
                        <span class="slider round"></span>
                    </label>
                </div>

                {this.state.donateContentFlag &&
                    <div className="stsubscripForm">
                        <div className="form-group row">
                            <div className="col-md-6">
                                <FormInput
                                    group={"large"}
                                    label={"Project Count"}
                                    value={this.state.donateProjectCount}
                                    name={"donateProjectCount"}
                                    onChange={this.onHandleValueChange}
                                    error={this.state.errors["donateProjectCount"]}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    group={"large"}
                                    label={"Days Allowed"}
                                    value={this.state.daysAllowed}
                                    name={"daysAllowed"}
                                    onChange={this.onHandleValueChange}
                                    error={this.state.errors["daysAllowed"]}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label class="switch">
                                    <strong>Top Up</strong>
                                    <input
                                        type="checkbox"
                                        ref="donateNowTopAllowed"
                                        name="donateNowTopAllowed"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={this.state.donateNowTopAllowed}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.donateNowTopAllowed && 
                            <>  
                                <div className="form-group row">
                                    {/* <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            label={"No of Projects"}
                                            value={this.state.donatenNoOfProject}
                                            name={"donatenNoOfProject"}
                                            onChange={this.onHandleValueChange}
                                            error={this.state.errors["donatenNoOfProject"]}
                                        />
                                    </div> */}
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            label={"Amount per Projects(INR)"}
                                            value={this.state.donateAmountCircleINR}
                                            name={"donateAmountCircleINR"}
                                            onChange={this.onHandleValueChange}
                                            error={this.state.errors["donateAmountCircleINR"]}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            label={"Amount per Projects(USD)"}
                                            value={this.state.donateAmountCircleUSD}
                                            name={"donateAmountCircleUSD"}
                                            onChange={this.onHandleValueChange}
                                            error={this.state.errors["donateAmountCircleUSD"]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <label class="switch">
                                            <strong>Additional Days Top Up</strong>
                                            <input
                                                type="checkbox"
                                                ref="additionalDaysTopUpAllowed"
                                                name="additionalDaysTopUpAllowed"
                                                onClick={(e) => {
                                                    this.agreeHandleChange(e);
                                                }}
                                                checked={this.state.additionalDaysTopUpAllowed}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {this.state.additionalDaysTopUpAllowed && 
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <ButtonWrap>
                                                    <Button children={"+ Add Additional Days Top Up"} onClick={this.addAdditionalTopUp} />
                                                </ButtonWrap>
                                            </div>
                                        </div>
                                        {this.state.additionalDaysPlan && this.state.additionalDaysPlan.map((data, index) => {
                                            return (
                                                <div className="form-group row">
                                                    <div className={"col-md-" + (index > 0 ? "3" : "4")}>
                                                        <FormInput
                                                            group={"large"}
                                                            label={"Additional Top Up Days"}
                                                            value={data.days}
                                                            name={"days"}
                                                            onChange={(e) => this.handleChangeAdditionalDaysPlan(e, index)}
                                                            className={"not-required"}
                                                            error={data?.daysErrorMessage}
                                                        />
                                                    </div>
                                                    <div className={"col-md-" + (index > 0 ? "3" : "4")}>
                                                        <FormInput
                                                            group={"large"}
                                                            label={"Amount per Projects(INR)"}
                                                            value={data.amountInINR}
                                                            name={"amountInINR"}
                                                            onChange={(e) => this.handleChangeAdditionalDaysPlan(e, index)}
                                                            className={"not-required"}
                                                            error={data?.amountInINRErrorMessage}
                                                        />
                                                    </div>
                                                    <div className={"col-md-4"}>
                                                        <FormInput
                                                            group={"large"}
                                                            label={"Amount per Projects(USD)"}
                                                            value={data.amointInUSD}
                                                            name={"amointInUSD"}
                                                            onChange={(e) => this.handleChangeAdditionalDaysPlan(e, index)}
                                                            className={"not-required"}
                                                            error={data?.amointInUSDErrorMessage}
                                                        />
                                                    </div>
                                                    {index > 0 && 
                                                        <div className="col-md-2">
                                                            <Button className="iTrashBin" children={"Remove"} style={{ marginTop: "35px" }} onClick={() => { this.removeAdditiionaldays(index) }} />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </>
                        }
                    </div>
                }

                <ButtonWrap className={`${styles.bottombutt}`}>
                    <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                </ButtonWrap>
                {this.state.error.message !== "" && this.state.error.message !== undefined &&
                    <div className={`${styles.bottombutt} alert alert-warning`}>
                        {this.state.error.message}
                    </div>
                }
            </div>
        );
    }
}

export default ProjectSubscriptionForm;