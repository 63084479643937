export const data = [
  {
    id: "5ecfb9d9d654557162afd861",
    projectName: "title 1234 job",
    slug: "title-1234-job",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecfb7bc08bace4c6941341e",
      userName: "Arka Bandy",
      customUrl: "c309be41-013b-4344-9db3-514793cd3d6a",
    },
    superAdminName: "Arka Bandy",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.590671831e12,
    endDate: 1.591449431e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5ed9e6729c475d2c9f87a456",
    projectName: "test job 1",
    slug: "test-job-1",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5d7327e66c5ada45579e",
      userName: "Durga Old",
      customUrl: "dp2020",
    },
    superAdminName: "Durga Old",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 2.0,
    startDate: 1.591338606e12,
    endDate: 1.591770606e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5ed9f6df9c475d2c9f87a463",
    projectName: "title 54356",
    slug: "title-54356",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecfb7bc08bace4c6941341e",
      userName: "Arka Bandy",
      customUrl: "c309be41-013b-4344-9db3-514793cd3d6a",
    },
    superAdminName: "Arka Bandy",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.591342813e12,
    endDate: 1.591342813e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5eda0a2c9c475d2c9f87a468",
    projectName: "test job 2",
    slug: "test-job-2",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5d7327e66c5ada45579e",
      userName: "Durga Old",
      customUrl: "dp2020",
    },
    superAdminName: "Durga Old",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 1.0,
    startDate: 1.591347755e12,
    endDate: 1.591434155e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5ee75f33c4cafa5eab36a803",
    projectName: "sfdsfsfsf",
    slug: "sfdsfsfsf",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ec8d399f0cd146da0546d7d",
      userName: "Harsh Tibrewal",
      customUrl: "harshtibrewal12",
    },
    superAdminName: "Harsh Tibrewal",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.592307874e12,
    endDate: 1.592999074e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5ee7cb989897dc40e2d96115",
    projectName: "dffdfada",
    slug: "dffdfada",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ec8d399f0cd146da0546d7d",
      userName: "Harsh Tibrewal",
      customUrl: "harshtibrewal12",
    },
    superAdminName: "Harsh Tibrewal",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.592249237e12,
    endDate: 1.592508437e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5efdfe879e6473347f52f2de",
    projectName: "job 13",
    slug: "job-13",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ec8d399f0cd146da0546d7d",
      userName: "Harsh Tibrewal",
      customUrl: "harshtibrewal12",
    },
    superAdminName: "Harsh Tibrewal",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.59439527e12,
    endDate: 1.59448167e12,
    country: "Afghanistan",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5efe479dff6e513f365f9c85",
    projectName: "test job",
    slug: "test-job",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ec8d399f0cd146da0546d7d",
      userName: "Harsh Tibrewal",
      customUrl: "harshtibrewal12",
    },
    superAdminName: "Harsh Tibrewal",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.59372278e12,
    endDate: 1.59398198e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f04173b51067e4ea5101508",
    projectName: "jp job",
    slug: "jp-job",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5e6727e66c5ada4557a3",
      userName: "MM Gg",
      customUrl: "fr",
    },
    superAdminName: "MM Gg",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.594110849e12,
    endDate: 1.596270849e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f07512477d16f68f66513ac",
    projectName: "test job 3",
    slug: "test-job-3",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ec8d399f0cd146da0546d7d",
      userName: "Harsh Tibrewal",
      customUrl: "harshtibrewal12",
    },
    superAdminName: "Harsh Tibrewal",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.594401444e12,
    endDate: 1.594919844e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f08165c51a78b7699cb0e59",
    projectName: "job next",
    slug: "job-next",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5e6727e66c5ada4557a3",
      userName: "MM Gg",
      customUrl: "fr",
    },
    superAdminName: "MM Gg",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.59445193e12,
    endDate: 1.59566153e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f0816f951a78b7699cb0e5d",
    projectName: "start on 17",
    slug: "start-on-17",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5e6727e66c5ada4557a3",
      userName: "MM Gg",
      customUrl: "fr",
    },
    superAdminName: "MM Gg",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.594970487e12,
    endDate: 1.595575287e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f081aad51a78b7699cb0e61",
    projectName: "asdsadasd sa",
    slug: "asdsadasd-sa",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ec8d399f0cd146da0546d7d",
      userName: "Harsh Tibrewal",
      customUrl: "harshtibrewal12",
    },
    superAdminName: "Harsh Tibrewal",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.594366637e12,
    endDate: 1.594971437e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f0d8874917fe032b6a52e71",
    projectName: "job title one two",
    slug: "job-title-one-two",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ee8bb024e5024774e9f98ad",
      userName: "Join Company",
      customUrl: "kkkk",
    },
    superAdminName: "Join Company",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.594722419e12,
    endDate: 1.595672819e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f129fdb45d06671f3fd4a8b",
    projectName: "test job post",
    slug: "test-job-post",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5d7327e66c5ada45579e",
      userName: "Durga Old",
      customUrl: "dp2020",
    },
    superAdminName: "Durga Old",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.59505609e12,
    endDate: 1.59617929e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f1ffc7d686a7058fe26475b",
    projectName: "job title three",
    slug: "job-title-three",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5d7327e66c5ada45579e",
      userName: "Durga Old",
      customUrl: "dp2020",
    },
    superAdminName: "Durga Old",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.595931772e12,
    endDate: 1.596277372e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f228662debae45b6899ad12",
    projectName: "dura 4444",
    slug: "dura-4444",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5e6727e66c5ada4557a3",
      userName: "MM Gg",
      customUrl: "fr",
    },
    superAdminName: "MM Gg",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 0.0,
    startDate: 1.596098146e12,
    endDate: 1.596789346e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
  {
    id: "5f2bfc17ccd88621fc114194",
    projectName: "test job kolkata",
    slug: "test-job-kolkata",
    category: "ONLINE",
    type: "JOB",
    superAdmin: {
      userId: "5ecb5d7327e66c5ada45579e",
      userName: "Durga Old",
      customUrl: "dp2020",
    },
    superAdminName: "Durga Old",
    admins: null,
    noOfAdmins: 0.0,
    createdOn: "12/04/2021",
    applicationReceived: 0.0,
    participants: 2.0,
    startDate: 1.596718097e12,
    endDate: 1.598878097e12,
    country: "India",
    guestInvite: [],
    noOfGuestInvite: 0.0,
    status: "ACTIVE",
    posts: 0.0,
  },
];
