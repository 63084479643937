import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../../../../../components/StatusDetails/status_details";

export const tableNominationConstants = (fetchAllSpeakersList) => {
  return [
    {
      id: 1,
      name: "Name",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/award-categories/${row.awardCategorySlug}/nomination/edit/${row.id}`}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Image",
      cell: (row) => {
        return (
          <img
              src={row.image ? row.image : "https://cdn.dscovr.com/images/users1.png"}
              alt="coverImage"
              style={{ width: "100px", height: "56px" }}
            />
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Project",
      //selector: (row) => row.project,
      cell: (row) => {
        return (
          <a href={row.project} target="_blank">
            <span className="sub">{row.project}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Points",
      selector: (row) => row.point && row.point.points ? row.point.points : "",
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Project Points",
      cell: (row) => {
        return (
          <Link to={{pathname : `/wpond/${row.year}/award-categories/${row.awardCategorySlug}/nominations/project-list/${row.nomineeId}` , state: row.name}}>
            <span className="sub">{row.point?.project}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Activity Points",
      cell: (row) => {
        return (
          <Link to={{pathname : `/wpond/${row.year}/award-categories/${row.awardCategorySlug}/nominations/activity-list/${row.nomineeId}` , state: row.name}}>
            <span className="sub">{row.point?.activity}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Votes",
      cell: (row) => {
        return (
          <Link to={{pathname : `/wpond/${row.year}/award-categories/${row.awardCategorySlug}/nominations/voters/${row.nomineeId}` , state: row.name}}>
            <span className="sub">{row.voteCount}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Nominated By",
      selector: (row) => row.nominatedBy,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Nomination Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.nominationStatus} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Deactivated Status",
      cell: (row) => {
        return (
          row.deactivated ? "True" : "False"
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"wpondAwardNomination"}
            action={fetchAllSpeakersList}
            fullPermission={"WPOND_ACN_FullAccess"}
            approvalPermission={"WPOND_ACN_Approval"}
            publishPermission={"WPOND_ACN_Publish"}
            enablePermission={"WPOND_ACN_Enable"}
            disablePermission={"WPOND_ACN_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  ];
};
