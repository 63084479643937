import axios from "axios";
import React, { Component } from "react";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import styles from "../../wpond/UpcomingYear/UpcomingYearList/year.module.css";
import history from "../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CommonHeader } from "../../../utils/commonHeader";
import { capitalizeFirstLetter } from "../../marketing/Data/CommonFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class BadgeConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            individualData: { videoPost: "", textPost: "", imagePost: "", audioPost: "", documentPost: "", articlePost: "", poll: "", forum: "", hashTags: "" },
            projectData: { videoPost: "", textPost: "", imagePost: "", audioPost: "", documentPost: "", articlePost: "", creation: "", pastCreation: "", hashTags: "", fundRaise: "", studio: "" },
            causeData: { videoPost: "", textPost: "", imagePost: "", audioPost: "", documentPost: "", articlePost: "", hashTags: "" },
            communityData: { create: "", videoPost: "", textPost: "", imagePost: "", audioPost: "", documentPost: "", articlePost: "", hashTags: "", fundRaise: "", projectCreate: "", pastProjectCreate: "" },
            showBadgeInPublic: false,
            isBtnShowDisabled: false,
            individualError: {},
            projectError: {},
            causeError: {},
            communityError: {},
            activeTab: "INDIVIDUAL"
        };
    }

    //handle change
    handleValueChange = (event, fieldData) => {
        if (fieldData === "individualData") {
            let individualData = this.state.individualData;
            individualData[event.target.name] = event.target.value?.replace(/^\D+/g, '');
        } else if (fieldData === "projectData") {
            let projectData = this.state.projectData;
            projectData[event.target.name] = event.target.value?.replace(/^\D+/g, '');
        } else if (fieldData === "causeData") {
            let causeData = this.state.causeData;
            causeData[event.target.name] = event.target.value?.replace(/^\D+/g, '');
        } else {
            let communityData = this.state.communityData;
            communityData[event.target.name] = event.target.value?.replace(/^\D+/g, '');
        }
        this.setState({
            fieldData,
            isBtnShowDisabled: true
        })
    };

    handleCheckBoxChange = (event, fieldData) => {
        this.setState({
            [fieldData]: event.target.checked ,
            isBtnShowDisabled: true
        })
    }

    validateForm = () => {
        let individualError = {};
        let projectError = {};
        let causeError = {};
        let communityError = {};
        let formIsValid = true;
        let individualData = this.state?.individualData;
        let projectData = this.state?.projectData;
        let causeData = this.state?.causeData;
        let communityData = this.state?.communityData;
        if(this.state.activeTab === "INDIVIDUAL") {
            Object.keys(individualData).forEach(function (key, index) {
                if (!individualData[key]) {
                    formIsValid = false;
                    individualError[key] = "Please enter data";
                }
            });
        } else if(this.state.activeTab === "PROJECT") {
            Object.keys(projectData).forEach(function (key, index) {
                if (!projectData[key]) {
                    formIsValid = false;
                    projectError[key] = "Please enter data";
                }
            });
        } else if(this.state.activeTab === "CAUSE") {
            Object.keys(causeData).forEach(function (key, index) {
                if (!causeData[key]) {
                    formIsValid = false;
                    causeError[key] = "Please enter data";
                }
            });
        } else {
            Object.keys(communityData).forEach(function (key, index) {
                if (!communityData[key]) {
                    formIsValid = false;
                    communityError[key] = "Please enter data";
                }
            });
        }
        this.setState({
            individualError: individualError,
            projectError: projectError,
            causeError: causeError,
            communityError: communityError
        });
        {
            setTimeout(() => {
                this.setState({
                    individualError: {},
                    projectError: {},
                    causeError: {},
                    communityError: {}
                });
            }, 5000);
        }
        return formIsValid;
    }

    onSubmit = () => {
        if (this.validateForm()) {
            let postData = {
                individual: this.state?.individualData,
                project: this.state?.projectData,
                cause: this.state?.causeData,
                community: this.state?.communityData,
                showPublic: this.state.showBadgeInPublic
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/badge/config/create`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response.data.message === "Success!") {
                    this.setState({
                        isBtnShowDisabled: false
                    })
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{"Data is saved successfully"}</p>)
                    })
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    fetchBadgeConfigData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/badge/config/get`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response.data.message === "Success!" && response?.data?.body?.id) {
                let data = response?.data?.body;
                this.setState({
                    individualData: data?.individual,
                    projectData: data?.project,
                    causeData: data?.cause,
                    communityData: data?.community,
                    showBadgeInPublic: data?.showPublic
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.fetchBadgeConfigData();
    }

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }


    render() {
        const { individualData, projectData, causeData, communityData, individualError, projectError, causeError, communityError, isBtnShowDisabled, activeTab, showBadgeInPublic } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <div class="pagetopwleft wpondMain mb-5">
                                <div class="ptwrow">
                                    <div class="topBtns">
                                        <a className={"btn btn1 " + (activeTab === "INDIVIDUAL" ? "active" : "")} onClick={() => { this.setState({ activeTab: "INDIVIDUAL" }) }}>Individual</a>
                                        <a className={"btn btn1 " + (activeTab === "PROJECT" ? "active" : "")} onClick={() => { this.setState({ activeTab: "PROJECT" }) }}>Project</a>
                                        <a className={"btn btn1 " + (activeTab === "CAUSE" ? "active" : "")} onClick={() => { this.setState({ activeTab: "CAUSE" }) }}>Cause</a>
                                        <a className={"btn btn1 " + (activeTab === "COMMUNITY" ? "active" : "")} onClick={() => { this.setState({ activeTab: "COMMUNITY" }) }}>Community</a>
                                        <a className={"btn btn1 " + (activeTab === "SHOW_BADGE_PUBLIC" ? "active" : "")} onClick={() => { this.setState({ activeTab: "SHOW_BADGE_PUBLIC" }) }}>Show Badge Public</a>
                                    </div>
                                </div>
                            </div>
                            {activeTab !== "SHOW_BADGE_PUBLIC" && <h2>{capitalizeFirstLetter(activeTab.toLowerCase())}</h2>}
                            {activeTab === "SHOW_BADGE_PUBLIC" ?
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label class="switch">
                                            Show Badge in Public
                                            <input
                                                type="checkbox"
                                                ref="donateNow"
                                                name="donateNow"
                                                onClick={(event) => { this.handleCheckBoxChange(event, "showBadgeInPublic");}}
                                                checked={showBadgeInPublic}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div> :
                                activeTab === "INDIVIDUAL" ? 
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.videoPost}
                                            label={"Video Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"videoPost"}
                                            error={individualError["videoPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.textPost}
                                            label={"Text Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"textPost"}
                                            error={individualError["textPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.imagePost}
                                            label={"Image Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"imagePost"}
                                            error={individualError["imagePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.audioPost}
                                            label={"Audio Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"audioPost"}
                                            error={individualError["audioPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.documentPost}
                                            label={"Document Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"documentPost"}
                                            error={individualError["documentPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.articlePost}
                                            label={"Article Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"articlePost"}
                                            error={individualError["articlePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.poll}
                                            label={"Poll Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"poll"}
                                            error={individualError["poll"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.forum}
                                            label={"Forum Post"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"forum"}
                                            error={individualError["forum"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={individualData?.hashTags}
                                            label={"Hashtags"}
                                            onChange={(event) => this.handleValueChange(event, "individualData")}
                                            name={"hashTags"}
                                            error={individualError["hashTags"]}
                                        />
                                    </div>
                                </div> :
                                activeTab === "PROJECT" ?
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.videoPost}
                                            label={"Video Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"videoPost"}
                                            error={projectError["textPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.textPost}
                                            label={"Text Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"textPost"}
                                            error={projectError["textPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.imagePost}
                                            label={"Image Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"imagePost"}
                                            error={projectError["imagePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.audioPost}
                                            label={"Audio Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"audioPost"}
                                            error={projectError["audioPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.documentPost}
                                            label={"Document Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"documentPost"}
                                            error={projectError["documentPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.articlePost}
                                            label={"Article Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"articlePost"}
                                            error={projectError["articlePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.creation}
                                            label={"Create"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"creation"}
                                            error={projectError["creation"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.pastCreation}
                                            label={"Past Creation"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"pastCreation"}
                                            error={projectError["pastCreation"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.hashTags}
                                            label={"Hashtags"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"hashTags"}
                                            error={projectError["hashTags"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.fundRaise}
                                            label={"Fund Raise"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"fundRaise"}
                                            error={projectError["fundRaise"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={projectData?.studio}
                                            label={"Studio Post"}
                                            onChange={(event) => this.handleValueChange(event, "projectData")}
                                            name={"studio"}
                                            error={projectError["studio"]}
                                        />
                                    </div>
                                </div> :
                                activeTab === "CAUSE" ?
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.videoPost}
                                            label={"Video Post"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"videoPost"}
                                            error={causeError["videoPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.textPost}
                                            label={"Text Post"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"textPost"}
                                            error={causeError["textPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.imagePost}
                                            label={"Image Post"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"imagePost"}
                                            error={causeError["imagePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.audioPost}
                                            label={"Audio Post"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"audioPost"}
                                            error={causeError["audioPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.documentPost}
                                            label={"Document Post"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"documentPost"}
                                            error={causeError["documentPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.articlePost}
                                            label={"Article Post"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"articlePost"}
                                            error={causeError["articlePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={causeData?.hashTags}
                                            label={"Hashtags"}
                                            onChange={(event) => this.handleValueChange(event, "causeData")}
                                            name={"hashTags"}
                                            error={causeError["hashTags"]}
                                        />
                                    </div>
                                </div> :
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.create}
                                            label={"Create"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"create"}
                                            error={communityError["create"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.videoPost}
                                            label={"Video Post"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"videoPost"}
                                            error={communityError["videoPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.textPost}
                                            label={"Text Post"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"textPost"}
                                            error={communityError["textPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.imagePost}
                                            label={"Image Post"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"imagePost"}
                                            error={communityError["imagePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.audioPost}
                                            label={"Audio Post"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"audioPost"}
                                            error={communityError["audioPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.documentPost}
                                            label={"Document Post"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"documentPost"}
                                            error={communityError["documentPost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.articlePost}
                                            label={"Article Post"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"articlePost"}
                                            error={communityError["articlePost"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.hashTags}
                                            label={"Hashtags"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"hashTags"}
                                            error={communityError["hashTags"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.fundRaise}
                                            label={"Fund Raise"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"fundRaise"}
                                            error={communityError["fundRaise"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.projectCreate}
                                            label={"Project Create"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"projectCreate"}
                                            error={communityError["projectCreate"]}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            value={communityData?.pastProjectCreate}
                                            label={"Past Project Create"}
                                            onChange={(event) => this.handleValueChange(event, "communityData")}
                                            name={"pastProjectCreate"}
                                            error={communityError["pastProjectCreate"]}
                                        />
                                    </div>
                                </div>
                            }

                            {isBtnShowDisabled &&
                                <ButtonWrap className={`${styles.bottombutt}`}>
                                    <Button children={"Submit"} onClick={this.onSubmit} />
                                </ButtonWrap>
                            }
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default BadgeConfig;