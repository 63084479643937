import axios from "axios";
import React, { Component } from "react";
import { data } from "../Data/mockData";
import { auth_service } from "../../../../auth/auth_service";
import { tableYearConstants } from "../Data/tableYearConstants";
import DataTable from "react-data-table-component";
import "./year.module.css";
import { customStyles } from "../../../../utils/tableCustomStyle";
import Export from "../../../../components/Buttons/export_button";
import { downloadCSV } from "../../../../utils/export_csv";
import { FilterComponent } from "../../../../utils/filter";
import Button from "../../../../components/Buttons/button";
import styles from "./year.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../utils/commonHeader";

export class YearsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      yearsList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.yearsList.filter(
              (item) =>
                item.year &&
                item.year
                  .includes(newFilterText)
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by year'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all years list
  fetchAllYearsList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_userServiceURL +"/wpondYear/getList",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            yearsList: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {/* {this.getSubHeaderComponent()} */}
        {auth_service.getPermission("WPOND_ExportCSV", "WPOND_FullAccess") && <Export onExport={() => downloadCSV(this.state.yearsList, "year")} />}
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchAllYearsList();
  }

  render() {
    // this.filteredItems = this.state.yearsList.filter(
    //   (item) =>
    //     item.year &&
    //     item.year
    //       .includes(this.state.filterText)
    // );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Years"
                columns={tableYearConstants(
                  this.fetchAllYearsList
                )}
                data={this.state.yearsList}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("WPOND_Create", "WPOND_FullAccess") && (<Link
          to="/wpond/year/create"
          className={`btn ${styles.createforumbtn}`}
        >
          <i className="iAddCircle"></i> Create
        </Link>)}
      </CommonHeader>
    );
  }
}

export default YearsList;
