import React, { Component } from 'react'
import { CommonHeader } from '../../../../utils/commonHeader';
import styles from "../BelongHere/pre-login.module.css";
import { Link } from 'react-router-dom';
import CommonPreloginList from '../CommonPreloginList';
import { auth_service } from '../../../../auth/auth_service';

class InspiringList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <CommonPreloginList type={"INSPIRING_STORY"} loginType={"PRE_LOGIN"} />
                    </div>
                </div>
               {auth_service.getPermission("Pre_Login_Inspiring_Stories_Create", "Pre_Login_Inspiring_Stories_FullAccess") && <Link to={`/pre-login/inspiring-story/create`} className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Create
                </Link>}
            </CommonHeader>
        )
    }
}

export default InspiringList;