import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Button from "../../../components/Buttons/button";
import Export from "../../../components/Buttons/export_button";
import { CommonHeader } from "../../../utils/commonHeader";
import { downloadCSV } from "../../../utils/export_csv";
import { FilterComponent } from "../../../utils/filter";
import { customStyles } from "../../../utils/tableCustomStyle";
import styles from "../../causes/causes_list.module.css";
import { data } from "../Data/mockData";
import { tableConstants } from "../Data/tableConstants";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

export class ExploreCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.categories.filter(
              (item) =>
                item.name &&
                item.name.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("Explore_Category_ExportCSV", "Explore_Category_FullAccess") && <Export onExport={() => downloadCSV(this.state.categories, "category")} />}
      </React.Fragment>
    );
  };

  // fetch explore category
  fetchAllCategoryList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_category/getByStatus?status=all`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            categories: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_category/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchAllCategoryList();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    this.fetchAllCategoryList();
  }
  render() {
    const { categories, filterText } = this.state;
    this.filteredItems = categories.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title="Categories"
              columns={tableConstants(this.fetchAllCategoryList,this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
        {auth_service.getPermission("Explore_Category_Create", "Explore_category_FullAccess") && (
          <Link
            to="/explore-categories/create"
            className={`btn ${styles.createforumbtn}`}
          >
            <i className="iAddCircle"></i> Create Categories
          </Link>
        )}
      </CommonHeader>
    );
  }
}

export default ExploreCategories;
