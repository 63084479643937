import React, { Component } from "react";
import styled from "styled-components";
import UploadFiles from "../../../components/UploadFiles/upload_files";
import { auth_service } from "../../../auth/auth_service";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "./create_testimonials.module.css";
import axios from "axios";
import style from "../../../components/InputFields/form_input.module.css";
import "react-datepicker/dist/react-datepicker.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import history from "../../../utils/history";
import ReactQuill, { Quill } from "react-quill";
import stripHtml from "string-strip-html";
import { Link } from "react-router-dom";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end;
`;

export class CreateTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      userDetails: {},
      userLogoImage: null,
      userLogoImagePath: "",
      removeUserLogoFlag: false,
      profileLink: '',
      userName: "",
      userLogoImageMessage: "",
      content: "",
      errors: {},
    };
  }

  modules = {
    toolbar: [
      [{ header: "2" }, { header: "3" }, { header: "4" }, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    imageCompress: {
      quality: 1, // default
      maxWidth: 750, // default
      maxHeight: 500, // default
      imageType: 'image/jpeg' + 'image/png', // default
    },
  };



  onBlueProfileChange = () => {
    if (this.validateProfileLink()) {
      let customUrl = (this.state.profileLink).substring((this.state.profileLink).lastIndexOf("/") + 1);
      if (customUrl) {
        axios({
          url: `${process.env.REACT_APP_userServiceURL}/profile/get_user_by_custom_url?customUrl=${customUrl}&otherUserId=${this.state.userData.id}`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }).then((response) => {
          if (response && response.data && response.data.body && response.data.message === "Success!") {
            let data = response.data.body;
            this.setState({
              userDetails: data,
              userName: data.userName,
              userLogoImagePath: data.profileImage
            })
          }
        }).catch((error) => {
          console.log(error);
          this.setState({
            profileErrorMessage: error.response.data.message,
            userName: "",
            userDetails: {},
            userLogoImagePath: null
          })
        });
      }

      setTimeout(() => {
        this.setState({
          profileErrorMessage: "",
        })
      }, 5000);
    }
  }

  // validate faq create
  validateProfileLink = () => {
    let formIsValid = true;
    let errors = {};
    if (this.state.profileLink) {
      let valudateLink = this.state.profileLink;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      let urlValidWenat = valudateLink.startsWith(process.env.REACT_APP_frontEndURL);
      if (urlValid == null || !urlValidWenat) {
        formIsValid = false;
        errors["profileLink"] = "Please enter a valid link";
      }
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  // validate faq create
  validate = () => {
    let formIsValid = true;
    let errors = {};
    if (!this.state.profileLink || this.state.profileLink.trim() === "") {
      formIsValid = false;
      errors["profileLink"] = "Please enter profileLink";
    }
    if (!this.state.userName || this.state.userName.trim() === "") {
      formIsValid = false;
      errors["userName"] = "Please enter userName";
    }
    if (!this.state.content || this.state.content.trim() === "" || stripHtml(this.state.content) == '') {
      formIsValid = false;
      errors["content"] = "Please enter description";
    }
    if (this.state.profileLink) {
      let valudateLink = this.state.profileLink;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      let urlValidWenat = valudateLink.startsWith(process.env.REACT_APP_frontEndURL);
      if (urlValid == null || !urlValidWenat) {
        formIsValid = false;
        errors["profileLink"] = "Please enter a valid link";
      }
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  updateUploadedLogoFiles = (files) => {
    this.setState({
      userLogoImage: files[0],
      removeUserLogoFlag: true
    });
  }

  removeUploadedLogoFiles = (files) => {
    this.setState({
      userLogoImage: null,
      removeUserLogoFlag: false,
      userLogoImagePath: ""
    });
  }

  //handle change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  contentChange = (value) => {
    this.setState({
      content: value
    })
  }

  // on faq submit
  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.userLogoImage  ? this.state.userLogoImage : this.state.userLogoImagePath );
      let postData = {
        userId: this.state.userDetails.userId,
        name: this.state.userName,
        content: this.state.content,
        profileLink: this.state.userDetails.customUrl,
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/testimonial/create`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log("object", error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  render() {
    const { profileLink, userName, content } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/testimonials" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="row">
                <div className="col-md-8">
                  <FormInput
                    group={"large"}
                    value={profileLink}
                    label={"Profile Link"}
                    name={"profileLink"}
                    onChange={this.handleChange}
                    onBlur={this.onBlueProfileChange}
                    error={this.state.errors["profileLink"]}
                  />
                  {this.state.profileErrorMessage && <small className={style.danger}>
                    {this.state.profileErrorMessage}
                  </small>
                  }
                  <FormInput
                    group={"large"}
                    value={userName}
                    label={"User Name"}
                    name={"userName"}
                    onChange={this.handleChange}
                    error={this.state.errors["userName"]}
                  />                  
                </div>
                <div className={`col-md-4 ${styles.testoImgUpload}`}>
                  <UploadFiles
                    accept=".jpg,.png,.jpeg"
                    label="User Image"
                    updateImage={(this.state.userLogoImagePath !== null ? this.state.userLogoImagePath : (this.state.userDetails.type !== "COMPANY" ? "https://cdn.dscovr.com/images/users1.png" : "https://cdn.dscovr.com/images/DefaultBusiness.png"))}
                    updateFilesCb={this.updateUploadedLogoFiles}
                    logoUploadFiles={true}
                    removeUploadedLogoFiles={this.removeUploadedLogoFiles}
                    //removeLogoFlag={this.state.removeUserLogoFlag || this.state.userLogoImagePath}
                    imagePresent={this.state.userLogoImageMessage ? 'noImage' : ''}
                  />
                </div>
              </div>
              <div className={"form-group " + (this.state.errors["content"] ? 'error' : '')}>
                <label>
                  Content <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={content}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["content"] && (
                  <small className={style.danger}>
                    {this.state.errors["content"]}
                  </small>
                )}
              </div>
              
              {auth_service.getPermission("Promotions_Create", "Promotions_FullAccess") && (
                <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`}>
                  <Button children={"Submit"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateTestimonial;
