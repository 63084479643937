import React, { Component } from 'react'
import FeedbackList from './FeedbackList';

class ProjectFeedbackList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
    };

    render() {
        return (
           <FeedbackList pageType={"PROJECT_FEEDBACK"}/>
        )
    }
}

export default ProjectFeedbackList;