import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { auth_service } from "../auth/auth_service";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (auth_service.isAuthenticated() && auth_service.getPermission(rest.permissionName, rest.fullAccessPermissionName)) ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/no-permission"} />
        )
      }
    />
  );
};

export default PrivateRoute;
