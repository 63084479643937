import React from "react";
import { epochTogmt } from "../../../../utils/common";

export const tableVisitorUserList = () => {
    return [
        {
            id: 1,
            name: "User Name",
            cell: (row) => {
                return <a href={`${process.env.REACT_APP_frontEndURL}/profile/${row.customUrl}`} target="_blank">
                    <span className="sub">{row.username}</span>
                </a>
            },
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 3,
            name: "Country Code",
            selector: (row) => "+" + row.countryCode,
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 4,
            name: "Phone No.",
            selector: (row) => row.mobile,
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 5,
            name: "Country",
            selector: (row) => row.country,
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 6,
            name: "Type",
            selector: (row) => row.type === "COMPANY" ? "Organization" : "Individual",
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 7,
            name: "Created On",
            selector: (row) => epochTogmt(row.date),
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 8,
            name: "Registered On",
            selector: (row) => epochTogmt(row.createdDate),
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        }
    ];
};