import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";
import { CommonHeader } from "../../../utils/commonHeader";
import CommonSubscriptionListPage from "../CommonSubscriptionListPage";

export class TrialSubscriptionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      userId: this.props.match.params.userId
    };
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/subscription/trial-user`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
            <CommonSubscriptionListPage type="TRIAL_USER_DETAILS" userId={this.state.userId} />
        </div>
      </CommonHeader>
    );
  }
}

export default TrialSubscriptionDetails;