import React, { Component } from "react";
import { CommonHeader } from "../../../utils/commonHeader";
import history from "../../../utils/history";
import CommonActivityTableListingPage from "../common/CommonActivityTableListingPage";

export class IndividualActivityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  clickGoback = () => {
    history.goBack();
  } 

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <a href={"javascript:void(0)"} className="goback" onClick={(e) => {e.preventDefault();this.clickGoback()}}>
            Go Back
          </a>
        </div>
        <CommonActivityTableListingPage pageType={this.props?.pageType} id={this.props.match?.params?.id} locationDetails={this.props?.location?.state}/>
      </CommonHeader>
    );
  }
}

export default IndividualActivityDetails;