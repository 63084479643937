export const data = [
  {
    id: "618278f8594c2f4af3785b50",
    name: "User Control",
    createdOn: "1592392166258",
    status: "ENABLE",
  },
  {
    id: "618278f8594c2f4af3785b51",
    name: "Management",
    createdOn: "1592392166258",
    status: "DISABLE",
  },
  {
    id: "618278f8594c2f4af3785b52",
    name: "Mobile App",
    createdOn: "1592392166258",
    status: "DISABLE",
  },
  {
    id: "618278f8594c2f4af3785b53",
    name: "Articles",
    createdOn: "1592392166258",
    status: "ENABLE",
  },
];
