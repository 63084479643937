import React, { Component } from "react";
import { auth_service } from "../../../../auth/auth_service";
import FormInput from "../../../../components/InputFields/form_input";
import styles from "./create_user.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
import { CommonHeader } from "../../../../utils/commonHeader";
import Select from "react-select";
import httpService from "../../../../AxiosInterceptors";
import history from "../../../../utils/history";
import style from "../../../../components/InputFields/form_input.module.css";
import TagsInput from "react-tagsinput";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

export class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      departmentData: [],
      roleData: [],
      departmentCategories: [],
      roleCategories: [],
      departmentList: [],
      roleList: [],
      permissionList: [],
      groupList: [],
      groupCategories: [],
      permissionCategories: [],
      selectedPermissionList: [],
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      password: "",
      isFieldDisable: true,
      isAdditionPerDisable: true,
      isReportDetailsDisable: false,
      upperCaseMsg: '1 Uppercase',
      minimumPasswordCharacter: "Password must be at least 8 characters long with",
      errors: {},
      addPermissionFlag: false,
      isPermissionDisable: true,
      allDepartmentChecked: false,
      allRoleChecked: false,
      filterText: "",
      addDepartmentFlag: false,
      addRoleFlag: false,
      filterTextDepartment: "",
      selectedDepartmentRows: [],
      filterTextRole: "",
      selectedRoleRows: [],
      departmentClearRows: false,
      roleClearRows: false,
    };
  }

  // validate create user
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.firstName || this.state.firstName.trim() === "") {
      formIsValid = false;
      errors["firstName"] = "Please enter first name";
    }
    if (!this.state.lastName || this.state.lastName.trim() === "") {
      formIsValid = false;
      errors["lastName"] = "Please enter last name";
    }
    if (!this.state.phoneNo) {
      formIsValid = false;
      errors["phoneNo"] = "Please enter phone no.";
    }
    if (
      this.state.phoneNo != null &&
      this.state.phoneNo != undefined &&
      this.state.phoneNo != ""
    ) {
      let phoneNumber = this.state.phoneNo;
      let phoneValid = phoneNumber.match(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      );
      if (phoneValid == null) {
        formIsValid = false;
        errors["phoneNo"] = "Please enter a valid phone no";
      }
    }
    if (
      this.state.email != null &&
      this.state.email != undefined &&
      this.state.email != ""
    ) {
      let emailAddress = this.state.email;
      let emailValid = emailAddress.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailValid == null) {
        formIsValid = false;
        errors["email"] = "Please enter a valid email";
      }
    }
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Please enter email";
    }
    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Please enter password";
    }
    if (
      this.state.password != null &&
      this.state.password != undefined &&
      this.state.password != ""
    ) {
      let passwordValue = this.state.password;
      let uppercaseTest = passwordValue.match("^(?=.*[A-Z])");
      let minCharacterTest = passwordValue.match("^(?=.{8})");
      if (uppercaseTest == null) {
        formIsValid = false;
        errors["password"] = "Please enter 1 Uppercase";
      }
      if (minCharacterTest == null) {
        formIsValid = false;
        errors["password"] = "Password must be at least 8 characters long with";
      }
      if (minCharacterTest == null && uppercaseTest == null) {
        formIsValid = false;
        errors["password"] = "Password must be at least 8 characters long with 1 Uppercase";
      }
    }
    if (
      this.state.departmentList === null ||
      this.state.departmentList === undefined ||
      this.state.departmentList.length === 0
    ) {
      formIsValid = false;
      errors["department"] = "Please Select a Department";
    }
    if (
      this.state.roleList === null ||
      this.state.roleList === undefined ||
      this.state.roleList.length === 0
    ) {
      formIsValid = false;
      errors["role"] = "Please Select a Role";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleSelectDepartment = (state) => {
    this.setState({
      selectedDepartmentRows: state.selectedRows
    })
  };

  handleSelectRole = (state) => {
    this.setState({
      selectedRoleRows: state.selectedRows
    })
  };

  //   title change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });

    if (event.target.name === 'password') {
      this.analyzePassword(event.target.value)
    }
  };

  // handle hashtag change
  permissionTagChange = (permission) => {
    this.setState({ permissionList: permission });
  };

  groupTagChange = (group) => {
    this.setState({ groupList: group });
    let groupListData = [];
    if (group.length > 0) {
      this.setState({
        isPermissionDisable: false,
      });
      group &&
        group.map((item) => {
          groupListData.push(item.label);
        });
      this.fetchPermissionList(groupListData);
    } else {
      this.setState({
        isPermissionDisable: true,
      });
    }
  };

  addPermissionBtn = () => {
    this.setState({
      addPermissionFlag: true,
    });
  };

  analyzePassword = (value) => {
    let error = false;
    let uppercaseTest = value.match("^(?=.*[A-Z])");
    let minCharacterTest = value.match("^(?=.{8})");
    if (uppercaseTest !== null) {
      this.setState({ upperCaseMsg: '' });
    } else {
      this.setState({ upperCaseMsg: '1 Uppercase' });
    }
    if (minCharacterTest !== null) {
      this.setState({ minimumPasswordCharacter: '' });
    } else {
      this.setState({ minimumPasswordCharacter: 'Password must be at least 8 characters long with' });
    }
  };

  // handle department change
  departmentTagChange = (department) => {
    this.setState({ departmentList: department });
    let departmentListData = [];
    if (department.length > 0) {
      department && department.map((item) => {
        departmentListData.push(item.label);
      });
      this.setState({
        isFieldDisable: false,
      });
      this.fetchRoleList(departmentListData);
    } else {
      this.setState({
        isFieldDisable: true
      });
    }
  };

  roleTagChange = (role) => {
    this.setState({ roleList: role });
    let roleListData = [];
    if (role.length > 0) {
      role && role.map((item) => {
        roleListData.push(item.label);
      });
      this.setState({
        isAdditionPerDisable: false
      });
      this.fetchPermissionListByRoleName(roleListData);
    } else {
      this.setState({
        addPermissionFlag: false,
        isAdditionPerDisable: true
      });
    }

  };

  agreeHandleChange = (fieldName, event) => {
    if (event.target.checked) {
      this.setState({
        isReportDetailsDisable: event.target.checked,
      });
    } else {
      this.setState({
        isReportDetailsDisable: event.target.checked,
      });
    }
    this.setState({
      [fieldName]: event.target.checked,
    });
  };

  onSubmit = () => {
    if (this.validate()) {
      let permissionDataList = [];
      this.state.permissionList &&
        this.state.permissionList.map((data) => {
          permissionDataList.push(data);
        });
      let postData = {
        userId: this.state.userData.id,
        createdBy: this.state.userData.firstName.trim(),
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        password: this.state.password,
        email: this.state.email,
        phoneNumber: this.state.phoneNo,
        department: this.state.departmentList,
        permission: permissionDataList,
        role: this.state.roleList,
        status: "DRAFT",
      };
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/adminUser/create",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  fetchRoleList = (departmentData) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminDepartment/getByName?name=${departmentData}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            roleCategories:
              response.data.body &&
              response.data.body.map((item, index) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                isChecked: this.checkRole(item.name),
                name: item.name
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkRole = (value) =>{
    return this.state.roleList.includes(value);
  }

  fetchDepartmentList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminDepartment/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            departmentCategories:
              response.data.body &&
              response.data.body.map((item, index) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                isChecked: this.checkDepartment(item.name),
                name: item.name
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkDepartment = (value) =>{
    return this.state.departmentList.includes(value);
  }

  fetchGroupList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            groupCategories:
              response.data.body &&
              response.data.body.map((item) => ({
                value: item.name,
                label: item.name,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchPermissionList = (groupName) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/getByName?name=${groupName}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          if (response.data.body != null) {
            const res = response.data.body;
            const permisson = this.state.selectedPermissionList;
            let responseUniqueData = res.filter((o) => permisson.indexOf(o) === -1);
            let permissionUniqueData = permisson.filter((o) => res.indexOf(o) === -1);
            const uniqueData = responseUniqueData.concat(permissionUniqueData);
            this.setState({
              permissionCategories:
                uniqueData &&
                uniqueData.map((item) => ({
                  value: item,
                  label: item,
                })),
            });
          }

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  fetchPermissionListByRoleName = (roleData) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminRole/getByName?name=${roleData}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          let permissionDataList = [];
          response.data.body &&
            response.data.body.map((item, index) => {
              permissionDataList.push(item);
            });
          this.setState({
            selectedPermissionList: permissionDataList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  passwordView(id) {
    var attr = document.getElementById(id);
    if (attr.type === "password") {
      attr.type = "text";
      document.getElementById(id + "toggle").innerHTML = "Hide";
    } else {
      attr.type = "password";
      document.getElementById(id + "toggle").innerHTML = "Show";
    }
  }

  handleAllDepartmentSelectChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { departmentCategories, allDepartmentChecked } = prevState;
      if (itemName === "checkAllDepartment") {
        allDepartmentChecked = checked;
        departmentCategories = departmentCategories.map(item => ({ ...item, isChecked: checked }));
      } else {
        departmentCategories = departmentCategories.map(item =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        allDepartmentChecked = departmentCategories.every(item => item.isChecked);
      }
      return { departmentCategories, allDepartmentChecked };
    });
  };

  handleDepartmentChangeChk = (event) => {
    let department = this.state.departmentCategories;
    department && department.map((item, index) => {
      if (item.id == event.target.id) {
        item.isChecked = event.target.checked;
      }
    })
    this.setState({ departmentCategories: department }, () => {
      let unique = [...new Set(this.state.departmentCategories.map(propYoureChecking => (propYoureChecking.isChecked === true)))];
      if (unique.length === 1 && unique[0]) {
        this.setState({
          allDepartmentChecked: true
        })
      } else {
        this.setState({
          allDepartmentChecked: false
        })
      }
    });
  }

  handleAllRoleSelectChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { roleCategories, allRoleChecked } = prevState;
      if (itemName === "checkAllRole") {
        allRoleChecked = checked;
        roleCategories = roleCategories.map(item => ({ ...item, isChecked: checked }));
      } else {
        roleCategories = roleCategories.map(item =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        allRoleChecked = roleCategories.every(item => item.isChecked);
      }
      return { roleCategories, allRoleChecked };
    });
  };

  handleRoleChangeChk = (event) => {
    let role = this.state.roleCategories;
    role && role.map((item, index) => {
      if (item.id == event.target.id) {
        item.isChecked = event.target.checked;
      }
    })
    this.setState({ roleCategories: role }, () => {
      let unique = [...new Set(this.state.roleCategories.map(propYoureChecking => (propYoureChecking.isChecked === true)))];
      if (unique.length === 1 && unique[0]) {
        this.setState({
          allRoleChecked: true
        })
      } else {
        this.setState({
          allRoleChecked: false
        })
      }
    });
  }

  addDepartmentList = () => {
    let department = this.state.departmentCategories;
    let selectDepartmentList = [];
    department && department.map((item, index) => {
      if (item.isChecked) {
        selectDepartmentList.push(item.name);
      }
    })
    this.setState({ departmentList: selectDepartmentList, addDepartmentFlag: true }, () => {
      if (selectDepartmentList.length > 0) {
        this.setState({
          isFieldDisable: false,
        });
        this.fetchRoleList(this.state.departmentList);
      } else {
        this.setState({
          isFieldDisable: true,
          roleList: []
        });
      }
    });
    document.getElementById("closeAddDepartmentModal").click();
  }

  addRoleList = () => {
    let role = this.state.roleCategories;
    let selectRoleList = [];
    role && role.map((item, index) => {
      if (item.isChecked) {
        selectRoleList.push(item.name);
      }
    })
    this.setState({ roleList: selectRoleList, addRoleFlag: true });
    document.getElementById("closeRoleModal").click();
  }

  addDepartmentModalClose = () => {
    this.setState({
      filterTextDepartment: "",
    });
    this.handlerClickCleanFiltered();
  }

  addRoleModalClose = () => {
    this.setState({
      filterTextRole: "",
    });
    this.handlerClickCleanFiltered();
  }

  handlerClickCleanFiltered() {
    this.refs.roleName.cleanFiltered();
    this.refs.departmentName.cleanFiltered();
  }

  componentDidMount() {
    this.fetchDepartmentList();
    this.fetchGroupList();
  }

  render() {
    this.filteredDepartmentItems = this.state.departmentCategories.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterTextDepartment.toLowerCase())
    );
    this.filteredRoleItems = this.state.roleCategories.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterTextRole.toLowerCase())
    );
    const selectRoleRowProp = {
      mode: 'checkbox',
      selected: this.state.roleList,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.state.roleCategories && this.state.roleCategories.map((item) => {
          if (item.id === row.id) {
            item.isChecked = isSelect;
          }
        })
      },
      onSelectAll: (isSelect, rows, e) => {
        this.state.roleCategories && this.state.roleCategories.map((item) => {
          item.isChecked = isSelect;
        })
      }
    };

    const selectDepartmentRowProp = {
      mode: 'checkbox',
      selected: this.state.departmentList,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.state.departmentCategories && this.state.departmentCategories.map((item) => {
          if (item.id === row.id) {
            item.isChecked = isSelect;
          }
        })
      },
      onSelectAll: (isSelect, rows, e) => {
        this.state.departmentCategories && this.state.departmentCategories.map((item) => {
          item.isChecked = isSelect;
        })
      }
    };
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/admin-panel/user" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.firstName}
                    label={"First Name"}
                    onChange={this.handleChange}
                    name={"firstName"}
                    error={this.state.errors["firstName"]}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.lastName}
                    label={"Last Name"}
                    onChange={this.handleChange}
                    name={"lastName"}
                    error={this.state.errors["lastName"]}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.email}
                    label={"Email"}
                    onChange={this.handleChange}
                    name={"email"}
                    error={this.state.errors["email"]}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={this.state.phoneNo}
                    label={"Phone No"}
                    onChange={this.handleChange}
                    maxlength="10"
                    name={"phoneNo"}
                    error={this.state.errors["phoneNo"]}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group passfield">
                    <label className="">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="custom-input form-control"
                      id="password"
                      name="password"
                      value={this.state.password || ""}
                      style={
                        this.state.validation &&
                        this.state.validation.password &&
                        this.state.validation.password.style
                      }
                      onChange={this.handleChange}
                      aria-describedby="inputGroupPrepend"
                      placeholder="Password"
                      style={
                        this.state.errors["password"] && {
                          border: "solid 1px red",
                        }
                      }
                    />
                    <div><small className="mt-1"> {this.state.minimumPasswordCharacter} {this.state.upperCaseMsg}</small></div>

                    {this.state.errors["password"] && (
                      <small className={style.danger}>
                        {this.state.errors["password"]}
                      </small>
                    )}
                    <span
                      className="passtoggle"
                      id="passwordtoggle"
                      onClick={() => this.passwordView("password")}
                      style={{
                        display: "block",
                      }}
                    >
                      Show
                    </span>
                  </div>
                </div>
              </div>
              <Line />
              <div className={"form-group user-tags-input " + (this.state.errors["department"] ? 'error' : '')}>
                <label className="d-flex align-items-center">Department
                  <a href="#departmentListModal" className="btn addRoleGlobal ml-auto" data-toggle="modal"><i class="iAddCircle"></i> Add Department</a>
                </label>
                <TagsInput
                  inputProps={{ placeholder: 'Add department' }}
                  value={this.state.departmentList}
                  readOnly={true}
                />
                {/* <Select
                  isMulti
                  options={this.state.departmentCategories}
                  onChange={this.departmentTagChange}
                  placeholder={"Start typing"}
                  closeMenuOnSelect={false}
                  styles={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = "#cfe7c7";
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                            ? "#cfe7c7"
                            : isFocused
                              ? "#cfe7c7"
                              : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                        },
                      };
                    },
                  }}
                /> */}
                {this.state.errors["department"] && (
                  <small className={style.danger}>
                    {this.state.errors["department"]}
                  </small>
                )}
              </div>
              <div className={"form-group user-tags-input " + (this.state.errors["role"] ? 'error' : '')}>
                <label className="d-flex align-items-center">Role
                  <a href="#roleListModal" className={"btn addRoleGlobal ml-auto " + (this.state.departmentList.length > 0 ? '' : 'disabled')} data-toggle="modal"><i class="iAddCircle"></i> Add Role</a>
                </label>
                <TagsInput
                  inputProps={{ placeholder: 'Add role' }}
                  value={this.state.roleList}
                />
                {/* <Select
                  isMulti
                  options={this.state.roleCategories}
                  onChange={this.roleTagChange}
                  placeholder={"Start typing"}
                  closeMenuOnSelect={false}
                  isDisabled={this.state.isFieldDisable}
                  styles={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = "#cfe7c7";
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                            ? "#cfe7c7"
                            : isFocused
                              ? "#cfe7c7"
                              : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                        },
                      };
                    },
                  }}
                /> */}
                {this.state.errors["role"] && (
                  <small className={style.danger}>
                    {this.state.errors["role"]}
                  </small>
                )}
              </div>
              {/* <div class="form-group">
                <label for="" class="text-right">
                  <Button
                    className="btn addRoleGlobal ml-auto text-right"
                    onClick={this.addPermissionBtn}
                    disabled={this.state.isAdditionPerDisable}
                  >
                    <i class="iAddCircle"></i> Additional Permission
                  </Button>
                </label>
              </div> */}
              {this.state.addPermissionFlag && (
                <div>
                  <div className={`form-group`}>
                    <label className="">Group</label>
                    <Select
                      isMulti
                      options={this.state.groupCategories}
                      onChange={this.groupTagChange}
                      placeholder={"Start typing"}
                      closeMenuOnSelect={false}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  <div className={`form-group`}>
                    <label className="">Permission</label>
                    <Select
                      isMulti
                      options={this.state.permissionCategories}
                      onChange={this.permissionTagChange}
                      placeholder={"Start typing"}
                      closeMenuOnSelect={false}
                      isDisabled={this.state.isPermissionDisable}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              {/* <div className={`form-group userswitch`}>
                <label class="switch">
                  Report Restiction
                  <input
                    type="checkbox"
                    ref="reportRestriction"
                    name="reportRestriction"
                    onClick={(e) => {
                      this.agreeHandleChange("reportRestriction", e);
                    }}
                  />
                  <span class="slider round"></span>
                </label>

                <div class="form-group">
                  <label for="">Report Detail Start Date:</label>
                  <select
                    name=""
                    id=""
                    class="form-control datefield"
                    disabled={this.state.isReportDetailsDisable}
                  >
                    <option value="">21st Jan 2020</option>
                  </select>
                </div>
              </div> */}
              {auth_service.getPermission("AP_User_Create", "AP_User_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Create"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>


          <div class="modal fade dgpopup permissionMod" id="departmentListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Select Department to add</h5>
                </div>
                <div class="modal-body">
                  <div className="tableadminpsearch role">
                    <div className="col2Large dgtable">
                      <BootstrapTable striped data={this.filteredDepartmentItems} selectRow={selectDepartmentRowProp} search={true}>
                        <TableHeaderColumn isKey dataField='value' ref='departmentName'>Department</TableHeaderColumn>
                      </BootstrapTable>
                      {this.state.errors["departmentModal"] && (
                        <div className="text-center">
                          <small className={style.danger}>
                            {this.state.errors["departmentModal"]}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.addDepartmentModalClose} id="closeAddDepartmentModal" data-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary" onClick={this.addDepartmentList}>Add</button>
                </div>
              </div>
            </div>
          </div>


          <div class="modal fade dgpopup permissionMod" id="roleListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalRoleLabel">Select Role to add</h5>
                </div>
                <div class="modal-body">
                  <div className="tableadminpsearch role">
                    <div className="col2Large dgtable">
                      <BootstrapTable striped data={this.filteredRoleItems} selectRow={selectRoleRowProp} search={true}>
                        <TableHeaderColumn isKey dataField='value' ref='roleName'>Role</TableHeaderColumn>
                      </BootstrapTable>
                      {this.state.errors["roleModal"] && (
                        <div className="text-center">
                          <small className={style.danger}>
                            {this.state.errors["roleModal"]}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.addRoleModalClose} id="closeRoleModal" data-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary" onClick={this.addRoleList}>Add</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default CreateUser;
