import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../auth/auth_service";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/tableCustomStyle";
import { tableTrialConstants } from "./TrialSubscription/Data/tableTrialConstants";
import { tableAmountTenureConstants } from "./AmountAndTenure/Data/tableAmountTenureConstants";
import { tableCircleConstants } from "./CircleSubscription/Data/tableCircleConstants";
import { tableProjectConstants } from "./ProjectSubscription/Data/tableProjectConstants";
import { tableOtherConstants } from "./OtherSubscription/Data/tableOtherConstants";
import { tableTrialDetailsConstants } from "./TrialSubscription/Data/tableTrialDetailsConstants";
import { CSVLink } from "react-csv";
import { epochTogmt } from "../../utils/common";
import styles from "../account_details/individual/individual_details.module.css";
import Select from "react-select";
import { subscriptionType } from "./CommonSubscriptionFunction";

export class CommonSubscriptionListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            subscriptionList: [],
            exportSubscriptionList: [],
            totalRows: 0,
            perPage: 10,
            subscriptionFilter: { value: 'ALL', label: 'All' }
        };
    }

    // fetch all amount and tenure list
    fetchAllSubscriptionListData = () => {
        let url = "";
        if (this.props.type === "AMOUNT_TENURE") {
            url = "subscription/list";
        } else if (this.props.type === "CIRCLE") {
            url = "subscription/circle/list";
        } else if (this.props.type === "PROJECT") {
            url = "subscription/project/list";
        } else if (this.props.type === "OTHER") {
            url = "subscription/other/list";
        } else {
            url = `trial/subscription/user-detail?userId=${this.props.userId}&type=${this.state.subscriptionFilter?.value}`;
        }
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
        }).then((response) => {
            if (
                response &&
                response.data &&
                response.data.status === "200 OK" &&
                response.data.body
            ) {
                this.setState({
                    subscriptionList: response.data.body,
                });
            }
        })
            .catch((error) => {
                console.log(error);
            });
    };

    fetchAllTrialSubscriptionListData = async (page) => {
        this.setState({
            loading: true,
        });
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/trial/subscription/users?page=${page}&size=${this.state.perPage}&type=${this.state.subscriptionFilter?.value}`,
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        subscriptionList: response.data.body && response.data.body.content,
                        totalRows: response.data.body.totalElements,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    subscriptionList: [],
                    loading: false,
                });
            });
    };

    handlePageChange = (page) => {
        this.fetchAllTrialSubscriptionListData(page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            loading: true,
        });
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/trial/subscription/users?page=${page - 1}&size=${this.state.perPage}&type=${this.state.subscriptionFilter?.value}`,
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        subscriptionList: response.data.body && response.data.body.content,
                        perPage: newPerPage,
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    subscriptionList: [],
                    loading: false,
                });
            });
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.state.subscriptionList.length > 0 && auth_service.getPermission("SM_TRIAL_ExportCSV", "SM_TRIAL_FullAccess") && <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={() => this.exportButton()}><i class="iDownloadFile" /> Export</a>}
            </React.Fragment>
        );
    };

    exportButton = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/trial/subscription/users?page=0&size=2000&type=${this.state.subscriptionFilter?.value}`,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                let exportData = [];
                let date = new Date();
                let dateMs = date.valueOf();
                const file = this.props.fileName + "-" + epochTogmt(dateMs) + "-" + dateMs + ".csv";
                response.data.body && response.data.body.content.map((item) => {
                    exportData.push({ "username": item.username, "email": item.email ? item.email : "-", "mobile": item.mobile && item.mobile !== 0 ? item.mobile : "-" })
                })
                this.setState({
                    exportSubscriptionList: exportData,
                    fileName: file,
                    loading: false
                }, () => {
                    this.csvLink.link.click();
                });
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }

    handleSubscriptionTypeChange = (type) => {
        this.setState({
            subscriptionFilter: type
        }, () => {
            this.fetchAllTrialSubscriptionListData(0);
        })
    }

    componentDidMount() {
        if (this.props.type === "TRIAL") {
            this.fetchAllTrialSubscriptionListData(0);
        } else {
            this.fetchAllSubscriptionListData();
        }
    }

    render() {
        const { type } = this.props;
        const { subscriptionList, subscriptionFilter } = this.state;
        return (
            <div id="page-inner">
                {this.props.type === "TRIAL" &&
                    <div className={styles.pagetopwrap}>
                        <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                            <div className={`${styles.ptwrow}`}>
                                <div className="col-md-3">
                                    <Select
                                        options={subscriptionType.filter((type) => type.value !== "BRONZE")}
                                        placeholder={"Filter by status"}
                                        value={subscriptionFilter}
                                        onChange={this.handleSubscriptionTypeChange}
                                        styles={{
                                            option: (
                                                styles,
                                                { data, isDisabled, isFocused, isSelected }
                                            ) => {
                                                const color = "#cfe7c7";
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? null
                                                        : isSelected
                                                            ? "#cfe7c7"
                                                            : isFocused
                                                                ? "#cfe7c7"
                                                                : null,
                                                    color: isDisabled
                                                        ? "#ccc"
                                                        : isSelected
                                                            ? color > 2
                                                                ? "#00374d"
                                                                : "#00374d"
                                                            : data.color,
                                                    cursor: isDisabled ? "not-allowed" : "default",

                                                    ":active": {
                                                        ...styles[":active"],
                                                        backgroundColor:
                                                            !isDisabled &&
                                                            (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="col2Large">
                    {type !== "TRIAL" ?
                        <DataTable
                            title={type === "AMOUNT_TENURE" ? "AMOUNT & TENURE" : type === "CIRCLE" ? "CIRCLE SUBSCRIPTION" : type === "PROJECT" ? "PROJECT SUBSCRIPTION" : type === "OTHER" ? "OTHER SUBSCRIPTION" : "TRIAL SUBSCRIPTION DETAILS"}
                            columns={type === "AMOUNT_TENURE" ? tableAmountTenureConstants() : type === "CIRCLE" ? tableCircleConstants() : type === "PROJECT" ? tableProjectConstants() : type === "OTHER" ? tableOtherConstants() : tableTrialDetailsConstants()}
                            data={subscriptionList}
                            customStyles={customStyles}
                            pointerOnHover
                            fixedHeader
                            fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                        /> :
                        <DataTable
                            title={"TRIAL SUBSCRIPTION"}
                            columns={tableTrialConstants(this.props.openExtraTrialModal)}
                            data={subscriptionList}
                            customStyles={customStyles}
                            actions={this.actionButton()}
                            pointerOnHover
                            fixedHeader
                            fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                            pagination
                            highlightOnHover
                            progressPending={this.state.loading}
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            onChangeRowsPerPage={this.handlePerRowsChange}
                            onChangePage={this.handlePageChange}
                        />
                    }

                    <CSVLink headers={this.props.headers} data={this.state.exportSubscriptionList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>
                </div>
            </div>
        );
    }
}

export default CommonSubscriptionListPage;
