import React from "react";
import { Link } from "react-router-dom";
import StatusDetails from "../../../../../../../../../components/StatusDetails/status_details";
import StatusDrop from "../../../../../../../../../components/StatusDropDown/status_drop";

export const tableVoterConstants = (fetchAllVotersList) => {
  return [
    {
      id: 1,
      name: "Name",
      selector: (row) => row.username,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Image",
      cell: (row) => {
        return (
          <img
              src={row.imageUrl ? row.imageUrl : "https://cdn.dscovr.com/images/users1.png"}
              alt="coverImage"
              style={{ width: "100px", height: "56px" }}
            />
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Persona",
      selector: (row) => row.persona,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Country",
      selector: (row) => row.addressDetail && row.addressDetail.country,
      sortable: true,
      reorder: true,
    },
  ];
};
