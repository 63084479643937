import StatusDrop from "../../../components/StatusDropDown/status_drop";
import { epochTogmt } from "../../../utils/common";
import {Link} from 'react-router-dom';
import { auth_service } from "../../../auth/auth_service";

export const tablepostConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Post Type",
      cell: (row) => {
        return (
            <Link
              to={`/report/post-details/${row.id}`}
              style={{ color: "#154A59" }}
            >
            <span className="sub">{row.postType}</span>
          </Link>
        );
      },
      conditionalCellStyles: [
        {
          when: row => row.systemGenerated,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Entity Created on",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Entity Reported on",
      selector: (row) => epochTogmt(row.reportedTime),
      sortable: true,
      reorder: true,
    },
    /*{
      id: 4,
      name: "Creator Id",
      selector: (row) => row.creatorId,
      sortable: true,
      reorder: true,
      style: {
        width: "500px",
      },
    },*/
    {
      id: 5,
      name: "Number of Reports",
      selector: (row) => row.reportCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Link",
      cell: (row) => {
        return (
          <a href={`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/${row.link}/${row.slug}?admin=true`} target="_blank">
            <span className="sub">{`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/${row.link}/${row.slug}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      width: '230px',
      cell: (row) => {
        return <>
          <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Post_Delete") ? "" :  " disabled") } onClick={(e) => {handleConfirmModal(row.id, "DELETE");}} disabled={!auth_service.getPermission("Report_Post_Delete")}>
            Delete
          </button>
          <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Post_Ignore") ? "" :  " disabled") } onClick={(e) => {handleConfirmModal(row.id, "IGNORE");}} disabled={!auth_service.getPermission("Report_Post_Ignore")}>
            Ignore
          </button>
      </>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
