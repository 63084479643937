import React from "react";
import { auth_service } from "../../../../auth/auth_service";

export const tableExcludeGlobalUserConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "User Name",
      cell: (row) => {
        return <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
          <span className="sub">{row.userName}</span>
        </a>
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "User Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Access type",
      cell: (row) => {
        return row.accessType === "FULL_ACCESS" ? "Full Access" : row.accessType === "VIEW_ACCESS" ? "View Access" : "Custom Access";
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" +  (auth_service.getPermission("AP_Exclude_Global_User_Delete") ? '' : ' disable')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
