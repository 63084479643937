import axios from "axios";
import React, { Component } from "react";
import styles from "./marketing.module.css";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/tableCustomStyle";
import { tableConstantsNotification } from "./Data/tableConstantsNotification";
import { tableConstantsEmail } from "./Data/tableConstantsEmail";
import { tableConstantViewer } from "./Data/tableConstantViewer";
import { FilterComponent } from "../../utils/filter";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { emailViewerHeader, notificationType, statusType } from "./Data/CommonFunction";
import { auth_service } from "../../auth/auth_service";
import { CSVLink } from "react-csv";
import { epochTogmt } from "../../utils/common";

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

export class CommomListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationStatus: { value: "ALL", label: "ALL" },
            dateData: 'ALL',
            notificationEmailNotifierDataList: [],
            exportNotificationEmailNotifierDataList: [],
            filterText: "",
            filterData: "",
            fileName: "",
            loading: false,
            totalRows: 0,
            perPage: 10,
            status: { label: "All", value: "ALL"},
            category: { label: "All", value: "ALL" }
        };
    }

    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        if(this.props.pageType === "EMAIL") {
                            this.filteredItems = this.state.notificationEmailNotifierDataList.filter(
                                (item) =>
                                    item.subject &&
                                    item.subject.toLowerCase().includes(this.state.filterText.toLowerCase())
                            );
                        } else if(this.props.pageType === "NOTIFICATION"){
                            this.filteredItems = this.state.notificationEmailNotifierDataList.filter(
                                (item) =>
                                    item.category &&
                                    item.category.toLowerCase().includes(this.state.filterText.toLowerCase())
                            );
                        } else {
                            this.filteredItems = this.state.notificationEmailNotifierDataList.filter(
                                (item) =>
                                    item.username &&
                                    item.username.toLowerCase().includes(newFilterText.toLowerCase())
                            );
                        }
                        this.setState({ filterText: newFilterText });
                    }}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                    searchType={`Search by ${this.props.pageType === "EMAIL" ? 'subject' : this.props.pageType === "NOTIFICATION" ? 'category' : 'user name'}`}
                />
            </React.Fragment>
        );
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
                {this.state.notificationEmailNotifierDataList.length > 0 && this.props.pageType === "NITIFIER_VIEW" && auth_service.getPermission("Email_ExportCSV", "Email_FullAccess") && <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={() => this.exportButton()}><i class="iDownloadFile" /> Export</a>}
            </React.Fragment>
        );
    };

    fetchNotificationEmailViewerData = async page => {
        this.setState({
            loading: true
        })
        let url = `notifier/list?type=${this.props.pageType === "EMAIL" ? "EMAIL" : "DASHBOARD"}&status=${this.state.status.value}&category=${this.props.pageType === "EMAIL" ? "ALL" : this.state.category.value}&page=${page}&size=${this.state.perPage}`;
        if(this.props.pageType === "NITIFIER_VIEW") {
            url = `notifier/notifier-list?notifierId=${this.props.notifierId}&page=${page}&size=${this.state.perPage}`;
        }
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({
                        notificationEmailNotifierDataList: response.data.body && response.data.body.content,
                        totalRows: response.data.body.totalElements,
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    notificationEmailNotifierDataList: [],
                    loading: false
                })
            });
    }

    handlePageChange = page => {
        this.fetchNotificationEmailViewerData(page-1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            loading: true
        })
        let url = `notifier/notifier-list?notifierId=${this.props.match.params.id}&page=${page-1}&size=${newPerPage}`;
        if(this.props.pageType !== "NITIFIER_VIEW") {
            url = `notifier/list?type=${this.props.pageType === "EMAIL" ? "EMAIL" : "DASHBOARD"}&status=${this.state.status.value}&category=${this.props.pageType === "EMAIL" ? "ALL" : this.state.category.value}&page=${page-1}&size=${newPerPage}`;
        }
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        notificationEmailNotifierDataList: response.data.body && response.data.body.content,
                        perPage: newPerPage,
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    notificationEmailNotifierDataList: [],
                    loading: false
                })
            });
    };

    stopNotificationHandle = (id, status) => {
        let postData = {
            id: id,
            status: status
        }
        axios({
            method: "POST",
            withCredentials: true,
            data: postData,
            url: `${process.env.REACT_APP_userServiceURL}/notifier/updateStatus`,
          }).then((response) => {
              if (response && response.data && response.data.message === "Success!") {
                this.updateNotificationData(id, status);
              }
            })
            .catch((error) => {
              console.log(error);
            });
    }

    deleteNotificationHandle = (id) => {
        const handleConfirm = async () => {
          const swalval = await MySwal.fire(confirmModalSwal);
          let postData = {
            id: id
          }
          if (swalval && swalval.value === true) {
            axios({
              method: "POST",
              url: `${process.env.REACT_APP_userServiceURL}/notifier/delete`,
              data: postData,
              withCredentials: true,
            })
              .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    let notificationEmailNotifierDataList = this.state.notificationEmailNotifierDataList;
                    const index = notificationEmailNotifierDataList && notificationEmailNotifierDataList.findIndex(data => data.id === id);
                    if (index > -1) {
                        notificationEmailNotifierDataList.splice(index, 1);
                    }
                    this.setState({
                        notificationEmailNotifierDataList: notificationEmailNotifierDataList
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        
        handleConfirm();
    };

    updateNotificationData = (id, status) => {
        let notificationEmailNotifierDataList = this.state.notificationEmailNotifierDataList;
        const index = notificationEmailNotifierDataList && notificationEmailNotifierDataList.findIndex(data => data.id === id);
        if (index > -1) {
            notificationEmailNotifierDataList[index].notifierStatus = status;
        }
        this.setState({
            notificationEmailNotifierDataList: notificationEmailNotifierDataList
        });
    }

    exportButton = () => {
        axios({
          method: "GET",
          withCredentials: true,
          url: `${process.env.REACT_APP_userServiceURL}/notifier/notifier-list?notifierId=${this.props.notifierId}&page=0&size=2000`,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              let exportData = [];
              let date = new Date();
              let dateMs = date.valueOf();
              const file = "email_viewer_list-"+epochTogmt(dateMs)+"-"+dateMs+".csv";
              response.data.body && response.data.body.content.map((item) => {
                exportData.push({"username": item.username, "email": item.email ? item.email : "-", "mobile": item.mobile && item.mobile !== 0 ? item.mobile : "-", "profileLink": `${process.env.REACT_APP_frontEndURL}/profile/${item.customUrl}`, "createTime": epochTogmt(item.createTime)}) 
              })    
              this.setState({
                exportNotificationEmailNotifierDataList: exportData,
                fileName: file,
                loading: false
              }, () => {
                this.csvLink.link.click();
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };

    categoryChange = (value) => {
        this.setState({
            category: value
        },() => {
            this.fetchNotificationEmailViewerData(0);
        })
    }

    statusChange = (value) => {
        this.setState({
            status: value
        }, () => {
            this.fetchNotificationEmailViewerData(0);
        })
    }



    stausCategoryFilterSection = (options, value, type) => {
        return (
            <Select
                options={options}
                value={value}
                onChange={type === "CATEGORY" ? this.categoryChange : this.statusChange}
                styles={{
                    option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                    ) => {
                        const color = "#cfe7c7";
                        return {
                            ...styles,
                            backgroundColor: isDisabled
                                ? null
                                : isSelected
                                    ? "#cfe7c7"
                                    : isFocused
                                        ? "#cfe7c7"
                                        : null,
                            color: isDisabled
                                ? "#ccc"
                                : isSelected
                                    ? color > 2
                                        ? "#00374d"
                                        : "#00374d"
                                    : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                    !isDisabled &&
                                    (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                        };
                    },
                }}
            />
        )
    }

    componentDidMount() {
        this.fetchNotificationEmailViewerData(0);
    }

    render() {
        if(this.props.pageType === "EMAIL") {
            this.filteredItems = this.state.notificationEmailNotifierDataList.filter(
                (item) =>
                    item.subject &&
                    item.subject.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        } else if(this.props.pageType === "NOTIFICATION") {
            this.filteredItems = this.state.notificationEmailNotifierDataList.filter(
                (item) =>
                    item.category &&
                    item.category.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        } else {
            this.filteredItems = this.state.notificationEmailNotifierDataList.filter(
                (item) =>
                    item.username &&
                    item.username.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        }
        
        const { status, category } = this.state;
        const { pageType } = this.props;
        return (
            <div id="page-inner">
                {this.props.pageType !== "NITIFIER_VIEW" && 
                <div className={styles.pagetopwrap}>
                    <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                        <div className={`${styles.ptwrow}`}>
                            {pageType !== "EMAIL" && <div className="col-md-3">
                                {this.stausCategoryFilterSection(notificationType, category, "CATEGORY")}
                            </div>}
                            <div className="col-md-3">
                                {this.stausCategoryFilterSection(statusType, status, "STATUS")}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="marketingTable">
                    <DataTable
                        title={pageType === "EMAIL" ? "Email" : pageType === "NOTIFICATION" ? "Notification" : "Email View"}
                        columns={pageType === "EMAIL" ? tableConstantsEmail(this.updateNotificationData, this.deleteNotificationHandle, this.stopNotificationHandle) : pageType === "NOTIFICATION" ? tableConstantsNotification(this.updateNotificationData,this.stopNotificationHandle, this.deleteNotificationHandle) : tableConstantViewer()}
                        data={this.filteredItems}
                        customStyles={customStyles}
                        actions={this.actionButton()}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                        highlightOnHover
                        pointerOnHover
                        progressPending={this.state.loading}
                        paginationServer
                        paginationTotalRows={this.state.totalRows}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                    />
                </div>

                <CSVLink headers={emailViewerHeader} data={this.state.exportNotificationEmailNotifierDataList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

            </div>
        );
    }
}

export default CommomListPage;