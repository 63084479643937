import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { customStyles } from '../../../utils/tableCustomStyle';
import axios from 'axios';
import { tableConstantsCreditPoints } from './tableConstantsCreditPoints';
import { tableConstantsCreditGiven } from './CreditGiven/tableConstantsCreditGiven';
import { tableConstantsCreditUseDetails } from '../Data/tableConstantsCreditUseDetails';
import { auth_service } from '../../../auth/auth_service';
import { CSVLink } from 'react-csv';
import { epochTogmt } from '../../../utils/common';

const creditPointUserHeader = [
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Credit Allowed", key: "creditAllowed" },
    { label: "Valid Period", key: "validPeriod" },
    { label: "Count of credits", key: "count" }
]

const creditPointsHeader = [
    { label: "Issued Date", key: "createTime" },
    { label: "Start Date", key: "startPeriod" },
    { label: "Date of Expiry", key: "validPeriod" },
    { label: "Total Credit given", key: "creditAmount" },
    { label: "% allowed to be used", key: "percentageAllowed" },
    { label: "Credit Status", key: "creditStatus" },
    { label: "Use For", key: "usedvalue" },
    { label: "Status", key: "status" }
]

const creditUtilizationHeader = [
    { label: "Type", key: "creditUsedSource" },
    { label: "Amount", key: "creditAmountUsed" },
    { label: "% adjusted", key: "percentageUsed" },
    { label: "Date", key: "createTime" }
]
class CommonCreditPointsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            creditPointsList: [],
            exportDataList: [],
            fileName: "",
            loading: false,
            totalRows: 0,
            perPage: 10, 
        }
    }

    fetchAllCreditPointsTableData = async (page) => {
        this.setState({
            loading: true
        })
        let url;
        if(this.props?.pageType === "CREDIT_POINTS") {
            url = `user/credit/list?page=${page}&size=${this.state.perPage}`;
        } else if(this.props?.pageType === "CREDIT_GIVEN"){
            url = `user/credit/user/credits?userId=${this.props?.id}&page=${page}&size=${this.state.perPage}`;
        } else {
            url = `user/credit/credit-utilization?userCreditId=${this.props?.id}`;
        }

        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    creditPointsList: this.props?.pageType !== "CREDIT_USER_DETAILS" ? response.data.body?.content : response.data.body,
                    totalRows: response.data.body.totalElements,
                    loading: false
                });
            }
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    exportData = () => {
        let url;
        if(this.props?.pageType === "CREDIT_POINTS") {
            url = `user/credit/list?page=0&size=200`;
        } else if(this.props?.pageType === "CREDIT_GIVEN"){
            url = `user/credit/user/credits?userId=${this.props?.id}&page=0&size=200`;
        } else {
            url = `user/credit/credit-utilization?userCreditId=${this.props?.id}`;
        }
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/${url}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let exportData = [];
                let data = this.props?.pageType !== "CREDIT_USER_DETAILS" ? response.data.body?.content : response.data?.body;
                data && data.map((item) => {
                    if(this.props?.pageType === "CREDIT_POINTS") {
                        exportData.push({"username": item.username, "email": item.email, "creditAllowed": item.creditAllowed ? "Yes" : "No", "validPeriod": epochTogmt(item.validPeriod), "count": item.count})
                    } else if(this.props.pageType === "CREDIT_GIVEN") {
                        exportData.push({"createTime": epochTogmt(item.username), "startPeriod": epochTogmt(item.startPeriod), "validPeriod": epochTogmt(item.validPeriod), "creditAmount": item.creditAmount, "percentageAllowed": item.percentageAllowed, "creditStatus": item.creditStatus, "usedvalue": item.usedvalue, "status": item.status})
                    } else {
                        exportData.push({"creditUsedSource": item.creditUsedSource, "creditAmountUsed": item.creditAmountUsed, "percentageUsed": item.percentageUsed, "createTime": epochTogmt(item.createTime)})
                    }
                })
                let date = new Date();
                let dateMs = date.valueOf();
                const file = `${this.props?.pageType === "CREDIT_POINTS" ? "credit_point_user_list-" : this.props.pageType === "CREDIT_GIVEN" ? "credit_point_list-" : "credit_utilization_list-"}`+epochTogmt(dateMs)+"-"+dateMs+".csv"
                this.setState({
                    exportDataList: exportData,
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                })
            }
        })
        .catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    handlePageChange = page => {
        this.fetchAllCreditPointsTableData(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllCreditPointsTableData(page-1);
        })
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.state.creditPointsList?.length > 0 &&
                    <>
                        {auth_service.getPermission(this.props.pageType === "CREDIT_GIVEN" ? "CREDIT_POINT_USER_LIST_ExportCSV" : this.props.pageType === "CREDIT_USER_DETAILS" ? "CREDIT_POINT_TRANSACTION_LIST_ExportCSV" : "CREDIT_POINT_ExportCSV", "CREDIT_POINT_FullAccess") && this.state.creditPointsList?.length > 0 && <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={this.exportData}><i class="iDownloadFile" /> Export</a>}
                    </>
                }
            </React.Fragment>
        );
    };

    componentDidMount() {
        this.fetchAllCreditPointsTableData(0);
    }

    render() {
        const{pageType} = this.props;
        const{creditPointsList, totalRows, loading, perPage, exportDataList, fileName} = this.state;
        return (
            <div className="marketingTable">
                <DataTable
                    title={pageType === "CREDIT_GIVEN" ? "Credit Points User List" : "Credit Points List"}
                    columns={pageType === "CREDIT_GIVEN" ? tableConstantsCreditGiven(this.fetchAllCreditPointsTableData) : pageType === "CREDIT_USER_DETAILS" ? tableConstantsCreditUseDetails() : tableConstantsCreditPoints()}
                    data={creditPointsList}
                    customStyles={customStyles}
                    actions={this.actionButton()}                
                    fixedHeader
                    fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                    pagination
                    paginationPerPage={perPage}
                    pointerOnHover
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                />

                <CSVLink headers={pageType === "CREDIT_POINTS" ? creditPointUserHeader: pageType === "CREDIT_GIVEN" ? creditPointsHeader : creditUtilizationHeader} data={exportDataList} filename={fileName} ref={(r) => (this.csvLink = r)}></CSVLink>
            </div>
        )
    }
}

export default CommonCreditPointsList;