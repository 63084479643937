import React from "react";
import { epochTogmt } from "../../../utils/common";

// This is the table constant/settings which needed to render table elements
export const tableConstantViewer = () => {
    return [
        {
            id: 1,
            name: "User Name",
            selector: (row) => row.username,
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
          id: 2,
          name: "Email",
          selector: (row) => row.email ? row.email : "-",
          sortable: true,
          reorder: true,
          style: {
              color: "#202124",
              fontSize: "14px",
              fontWeight: 500,
          },
      },
      {
        id: 3,
        name: "Phone No",
        selector: (row) => row.mobile && row.mobile !== 0 ? row.mobile : "-",
        sortable: true,
        reorder: true,
        style: {
            color: "#202124",
            fontSize: "14px",
            fontWeight: 500,
        },
      },
        {
          id: 4,
          name: "Profile Link",
          cell: (row) => {
            return (
              <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.customUrl} target="_blank">
                <span className="sub">{`${process.env.REACT_APP_frontEndURL}/profile/${row.customUrl}`}</span>
              </a>
            );
          },
          sortable: true,
          reorder: true,
          //grow: .2,
      },
      {
        id: 5,
        name: "Create Time",
        selector: (row) => epochTogmt(row.createTime),
        sortable: true,
        reorder: true,
        //grow: .2,
        style: {
            color: "rgba(0,0,0,.54)",
        },
    },
    ];
};
