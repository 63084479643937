import React, { Component } from "react";
import { auth_service } from "../../../../auth/auth_service";
import FormInput from "../../../../components/InputFields/form_input";
import styles from "../CreateRole/create_role.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
import { CommonHeader } from "../../../../utils/commonHeader";
import Select from "react-select";

import httpService from "../../../../AxiosInterceptors";
import history from "../../../../utils/history";
import style from "../../../../components/InputFields/form_input.module.css";
import TagsInput from "react-tagsinput";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

//const permissionSelectCritera = row => row.permissionName === "Causes_Tab"
export class CreateRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      title: "",
      roleId: this.props.match.params.id,
      departmentCategories: [],
      permissionCategories: [],
      departmentList: [],
      permissionList: [],
      groupList: [],
      categories: [],
      tempValue: [],
      roleStatus: "",
      errors: {},
      addPermissionFlag: false,
      isPermissionDisable: true,
      filterText: "",
      selectedPermissionRows: [],
    };
  }

  permissionSelectCritera = (row) => {
    let data = this.state.permissionList.includes(row.permissionName);
    this.setState({ 'tempValue': data });
    return data;

  };

  // validate create user
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter title";
    }
    if (
      this.state.permissionList === null ||
      this.state.permissionList === undefined ||
      this.state.permissionList.length === 0
    ) {
      formIsValid = false;
      errors["permission"] = "Please enter a permission";
    }
    if (
      this.state.departmentList === null ||
      this.state.departmentList === undefined ||
      this.state.departmentList.length === 0
    ) {
      formIsValid = false;
      errors["department"] = "Please enter a department";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  handleChange = (state) => {
    this.setState({
      selectedPermissionRows: state.selectedRows,
    })
  };

  //   title change
  titleChange = (event) => {
    this.setState({
      title: event.target.value.trimStart(),
    });
  };

  // handle hashtag change
  permissionTagChange = (permission) => {
    this.setState({ permissionList: permission });
  };

  // handle department change
  departmentTagChange = (department) => {
    this.setState({ departmentList: department });
  };

  groupTagChange = (group) => {
    this.setState({ groupList: group });
    let groupListData = [];
    if (group.length > 0) {
      this.setState({
        isPermissionDisable: false,
      });
      group &&
        group.map((item) => {
          groupListData.push(item.label);
        });
      this.fetchPermissionList(groupListData);
    } else {
      this.setState({
        permissionList: [],
        isPermissionDisable: true,
      });
    }
  };

  // fetch data by role id
  fetchRoleById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminRole/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.status === "200 OK"
        ) {
          let departmentData = [];
          let groupData = [];
          response.data.body.department &&
            response.data.body.department.map((data, index) => {
              departmentData.push({ value: data, label: data });
            });
          response.data.body.groups &&
            response.data.body.groups.map((data, index) => {
              groupData.push({ value: data, label: data });
            });
          this.setState({
            title: response.data.body.name,
            departmentList: departmentData,
            permissionList: response.data.body.permission,
            groupList: groupData,
            roleStatus: response.data.body.status,
          });
          this.fetchPermissionList(response.data.body.groups);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchDepartmentList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminDepartment/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            departmentCategories:
              response.data.body &&
              response.data.body.map((item) => ({
                value: item.name,
                label: item.name,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onUpdateRole = () => {
    if (this.validate()) {
      let departmentDataList = [];
      this.state.departmentList &&
        this.state.departmentList.map((data) => {
          departmentDataList.push(data.label);
        });
      let groupDataList = [];
      this.state.groupList &&
        this.state.groupList.map((data) => {
          groupDataList.push(data.label);
        });
      let postData = {
        userId: this.state.userData.id,
        id: this.state.roleId,
        name: this.state.title.trim(),
        department: departmentDataList,
        permission: this.state.permissionList,
        group: groupDataList,
        createdBy: this.state.userData.firstName,
        status: this.state.roleStatus,
      };
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/adminRole/edit",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  fetchGroupList = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            groupCategories:
              response.data.body &&
              response.data.body.map((item) => ({
                value: item.name,
                label: item.name,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchPermissionList = (groupName) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminGroup/getGroup?name=${groupName}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          let permissionMap = [];
          response.data.body && response.data.body.map((group, index) => {
            group && group.permission.map((item, index2) => {
              permissionMap.push({
                groupName: group.name,
                permissionName: item,
                isChecked: false,
                name: item
              })
            })
          });
          let permissionNewMap = [];
          permissionMap &&
            permissionMap.map((item) => (
                permissionNewMap.push({
                  groupName: item.groupName,
                  permissionName: item.permissionName,
                  isChecked: this.checkPermission(item.name),
                  name: item.name
                })
            ))
          this.setState({
            permissionCategories: permissionNewMap
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkPermission = (value) =>{
    return this.state.permissionList.includes(value);
  }

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
      </React.Fragment>
    );
  };

  addPermissionModalClose = () => {
    this.setState({
      filterText: "",
    });
    this.handlerClickCleanFiltered();
  }

  addPermissionList = () => {
    let permission = this.state.permissionCategories;
    let selectPermissionList = [];
    permission && permission.map((item, index) => {
      if (item.isChecked) {
        selectPermissionList.push(item.name);
      }
    })

    this.setState({ permissionList: selectPermissionList, addPermissionFlag: true }, () => {
      document.getElementById("closePermissionModal").click();
    });
  }

  handlerClickCleanFiltered() {
    this.refs.groupName.cleanFiltered();
  }

  componentDidMount() {
    this.fetchRoleById();
    this.fetchGroupList();
    this.fetchDepartmentList();
  }

  render() {
    this.filteredItems = this.state.permissionCategories.filter(
      (item) =>
        item.groupName &&
        item.groupName.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    const selectPermissionRowProp = {
      mode: 'checkbox',
      selected: this.state.permissionList,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.state.permissionCategories && this.state.permissionCategories.map((item) => {
          if (item.name === row.name) {
            item.isChecked = isSelect;
          }
        })
      },
      onSelectAll: (isSelect, rows, e) => {
        this.state.permissionCategories && this.state.permissionCategories.map((item) => {
          item.isChecked = isSelect;
        })
      }
    };

    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/admin-panel/role" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <h2>Role Update</h2>
              <FormInput
                group={"large"}
                value={this.state.title}
                label={"Title"}
                onChange={this.titleChange}
                name={"title"}
                error={this.state.errors["title"]}
              />
              <div className={`form-group`}>
                <label className="d-flex align-items-center">
                  Department{" "}
                </label>
                <Select
                  isMulti
                  options={this.state.departmentCategories}
                  onChange={this.departmentTagChange}
                  placeholder={"Start typing"}
                  value={this.state.departmentList}
                  closeMenuOnSelect={false}
                  styles={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = "#cfe7c7";
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                            ? "#cfe7c7"
                            : isFocused
                              ? "#cfe7c7"
                              : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                        },
                      };
                    },
                  }}
                />
                {this.state.errors["department"] && (
                  <small className={style.danger}>
                    {this.state.errors["department"]}
                  </small>
                )}
              </div>
              <div className={`form-group`}>
                <label className="">Group</label>
                <Select
                  isMulti
                  options={this.state.groupCategories}
                  onChange={this.groupTagChange}
                  placeholder={"Start typing"}
                  closeMenuOnSelect={false}
                  value={this.state.groupList}
                  styles={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = "#cfe7c7";
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                            ? "#cfe7c7"
                            : isFocused
                              ? "#cfe7c7"
                              : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled &&
                            (isSelected ? "#cfe7c7" : "#cfe7c7"),
                        },
                      };
                    },
                  }}
                />
                {this.state.errors["group"] && (
                  <small className={style.danger}>
                    {this.state.errors["group"]}
                  </small>
                )}
              </div>
              <div className={"form-group user-tags-input " + (this.state.errors["permission"] ? 'error' : '')}>
                <label className="d-flex align-items-center">Permission
                  <a href="#permissionmodal" className={"btn addRoleGlobal ml-auto " + (this.state.groupList.length > 0 ? '' : 'disabled')} data-toggle="modal"><i class="iAddCircle"></i> Update Permission</a>
                </label>
                <TagsInput
                  inputProps={{ placeholder: 'Add permission' }}
                  value={this.state.permissionList}
                  readOnly={true}
                />
                {this.state.errors["permission"] && (
                  <small className={style.danger}>
                    {this.state.errors["permission"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("AP_Roles_Update", "AP_Roles_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Update"} onClick={this.onUpdateRole} />
                </ButtonWrap>
              )}
            </div>
          </div>

          {/* Permission Modal  */}
          <div class="modal fade dgpopup permissionMod" id="permissionmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalRoleLabel">Select Permission to add</h5>
                </div>
                <div class="modal-body">
                  <div className="tableadminpsearch role">
                    <div className="col2Large dgtable">
                      <BootstrapTable striped data={this.filteredItems} selectRow={selectPermissionRowProp} search={true}>
                        <TableHeaderColumn dataField='groupName' ref='groupName'>Group</TableHeaderColumn>
                        <TableHeaderColumn isKey dataField='permissionName'>Permission</TableHeaderColumn>
                      </BootstrapTable>
                      {this.state.errors["permissionModal"] && (
                        <div className="text-center">
                          <small className={style.danger}>
                            {this.state.errors["permissionModal"]}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.addPermissionModalClose} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary" onClick={this.addPermissionList}>Add</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default CreateRole;
