import axios from "axios";
import React, { Component } from "react";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "../FEAdmin/fe_admin_list.module.css";
import FormInput from "../../../components/InputFields/form_input";
import Button from "../../../components/Buttons/button";
import alertPopUp from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DataTable from "react-data-table-component";
import { tableConstants } from "./Data/tableConstants";
import { customStyles } from "../../../utils/tableCustomStyle";
import { FilterComponent } from "../../../utils/filter";

const ErorrMsgPopUp = withReactContent(alertPopUp);

let deleteConfirmModal = {
    title: '<h5>Are you sure you want to Delete?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
};

class SpamWord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spamWord: "",
            errors: {},
            spamWordList: [],
            filterText: "",
            filterTextOne: "",
            filterDisplay: false
        };
    }

    // validate
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.spamWord) {
            formIsValid = false;
            errors["spamWord"] = "Please enter spam word";
        }
        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    handleChange = (event) => {
        this.setState({
            spamWord: event.target.value.trimStart(),
        });
    };

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                name: this.state.spamWord,
            };
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/spam/word/create`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response.data.status === "201 CREATED") {
                    this.setState({
                        spamWord: "",
                    }, () => {
                        this.fetchSpamWordList();
                    });
                }
            }).catch((error) => {
                console.log(error);
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm();
                    },
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>);
                });
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: false,
                });
            }, 5000);
        }
    };

    fetchSpamWordList = async () => {
        this.setState({
            loading: true,
        });
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/spam/word/list`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                this.setState({
                    spamWordList: response.data.body,
                    loading: false,
                });
            }
        }).catch((error) => {
            console.log("error", error);
            this.setState({
                spamWordList: [],
                loading: false,
            });
        });
    };

    componentDidMount() {
        this.fetchSpamWordList();
    }

    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems =
                            this.state.spamWordList &&
                            this.state.spamWordList.filter(
                                (item) =>
                                    item.name &&
                                    item.name.toLowerCase().includes(newFilterText.toLowerCase())
                            );
                        this.setState({ filterText: newFilterText });
                    }}
                    searchType={"Search by name"}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
            </React.Fragment>
        );
    };

    deleteConfirmModal = (id) => {
        const handleConfirm = async () => {
            const swalval = await ErorrMsgPopUp.fire(deleteConfirmModal);
            if (swalval && swalval.value === true) {
                axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_userServiceURL}/backend/spam/word/delete?id=${id}`,
                    withCredentials: true,
                }).then((response) => {
                    if (response && response.data && response.data.status === "202 ACCEPTED") {
                        let spamWordListData = this.state.spamWordList;
                        const index = spamWordListData && spamWordListData.findIndex(item => item.id === id);
                        if (index > -1) {
                            spamWordListData.splice(index, 1);
                        }

                        this.setState({
                            spamWordList: spamWordListData
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        };

        handleConfirm();
    };

    render() {
        this.filteredItems =
            this.state.spamWordList &&
            this.state.spamWordList.filter(
                (item) =>
                    item.name &&
                    item.name.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={styles.pagetopwrap}>
                            <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                                <div className={`${styles.ptwrow}`}>
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            value={this.state.spamWord}
                                            label={"Spam Word"}
                                            onChange={this.handleChange}
                                            name={"spamWord"}
                                            error={this.state.errors["spamWord"]}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Button children={"Add"} onClick={this.onSubmit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            title="Spam Word List"
                            columns={tableConstants(this.deleteConfirmModal)}
                            data={this.filteredItems}
                            customStyles={customStyles}
                            actions={this.actionButton()}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            progressPending={this.state.loading}
                        />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default SpamWord;
