import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";

export const tableConstantsBlog = (fetchBlogs, handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Blog Title",
      cell: (row) => {
        return (
          <Link to={`/blogs/edit/${row.id}`}>
            <span className="sub">{row.title}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Last updated by",
      selector: (row) => row.updatedByName,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Created on",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Category",
      cell: (row) => {
        let output = [];
        let count = 0;
        row.categoryIdList &&
          row.categoryIdList.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Likes",
      selector: (row) => row.likesCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Comments",
      selector: (row) => row.commentCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Hashtags",
      cell: (row) => {
        let output = [];
        let count = 0;
        row.hashTags &&
          row.hashTags.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Image",
      cell: (row) => {
        return (
          <img
            src={row.coverImageUrl}
            alt="coverImage"
            style={{ width: "100px", height: "auto" }}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Source",
      selector: (row) => row.source,
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 11,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"blog"}
            action={fetchBlogs}
            fullPermission={"Explore_Blogs_FullAccess"}
            approvalPermission={"Explore_Blogs_Approval"}
            publishPermission={"Explore_Blogs_Publish"}
            enablePermission={"Explore_Blogs_Enable"}
            disablePermission={"Explore_Blogs_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 12,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("Explore_Blogs_Delete", "Explore_Blogs_FullAccess") || row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
