import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../utils/commonHeader";
import httpService from "../../../../AxiosInterceptors";
import UserBadgeConfigForm from "../user_badge_config";

httpService.setupInterceptors();

export class EditUserBadgeConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/user-badge-config" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <UserBadgeConfigForm formType={"EDIT"} id={this.props.match.params.id} />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default EditUserBadgeConfig;
