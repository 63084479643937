import React, { Component } from 'react'
import { CommonHeader } from '../../utils/commonHeader';
import { tableConstantsFeedbackList } from './tableConstantsFeedbackList';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utils/tableCustomStyle';
import axios from 'axios';
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormInput from '../../components/InputFields/form_input';
import Button from '../../components/Buttons/button';
import styles from "../admin_panel/FEAdmin/fe_admin_list.module.css";


const MySwal = withReactContent(alertPopUp);
const ErorrMsgPopUp = withReactContent(alertPopUp);

const confirmModalSwal = () => ({
    title: `<h5>Are you sure you want to delete this User?</h5>`,
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
});

class ExcludeFeedbackUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            excludefeedBackUserList: [],
            emailId: "",
            errors: {},
        }
    }

    componentDidMount(){
        this.getExcludeFeedbackUserList();
    };

    handleChange = (event) => {
        this.setState({
          emailId: event.target.value.trim(),
        });
    };

  // validate
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.emailId) {
      formIsValid = false;
      errors["emailId"] = "Please enter email id";
    }
    if (this.state.emailId != null && this.state.emailId != undefined && this.state.emailId != "" ) {
      let emailIdAddress = this.state.emailId;
      let emailIdValid = emailIdAddress.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailIdValid == null) {
        formIsValid = false;
        errors["emailId"] = "Please enter a valid email";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

    onSubmit = () => {
        if (this.validate()) {
          let postData = {
            email: this.state.emailId,
          };

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_userServiceURL}/feedbackGlobalUser/addUser`,
            headers: { "Content-Type": "application/json" },
            data: postData,
            withCredentials: true,
          })
            .then((response) => {
              if (response && response.data && response.data.status === "201 CREATED") {
                this.setState({
                    emailId: "",
                },() => {
                    this.getExcludeFeedbackUserList();
                })
              }
            })
            .catch((error) => {
              console.log(error);
              ErorrMsgPopUp.fire({
                didOpen: () => {
                  ErorrMsgPopUp.clickConfirm();
                },
              }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>);
              });
            });
        }
        {
          setTimeout(() => {
            this.setState({
              errors: {},
              error: false,
            });
          }, 5000);
        }
      };

    getExcludeFeedbackUserList = async () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/feedbackGlobalUser/list`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    excludefeedBackUserList: response?.data?.body?.content
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleConfirmModal = (id) => {
        const handleConfirm = async () => {
          const swalval = await MySwal.fire(confirmModalSwal());
          if (swalval && swalval.value === true) {
            let postData= {
                id: id
            }
            axios({
              method: "DELETE",
              url: `${process.env.REACT_APP_userServiceURL}/feedbackGlobalUser/delete`,
              data: postData,
              withCredentials: true,
            })
              .then((response) => {
                if (response && response.data && response.data.status === "202 ACCEPTED") {
                  this.getExcludeFeedbackUserList();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        };
        handleConfirm();
      };

    render() {
        const {excludefeedBackUserList} = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                    <div className={styles.pagetopwrap}>
                        <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                            <div className={`${styles.ptwrow}`}>
                            <div className="col-md-6">
                                <FormInput
                                  group={"large"}
                                  value={this.state.emailId}
                                  label={"Email Id"}
                                  onChange={this.handleChange}
                                  name={"emailId"}
                                  error={this.state.errors["emailId"]}
                                />
                            </div>
                            
                            <div className="col-md-3" style={{marginTop: "15px"}}>
                                <Button children={"Add"} onClick={this.onSubmit} />
                            </div>
                            </div>
                        </div>
                    </div>
                        <div className="marketingTable">
                                <DataTable
                                    title={"Exclude feedback User"}
                                    columns={tableConstantsFeedbackList(this.handleConfirmModal, "EXCLUDE_FEEDBACK_USER")}
                                    data={excludefeedBackUserList}
                                    customStyles={customStyles}
                                    // actions={this.actionButton()}                
                                    fixedHeader
                                    fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                                    pagination
                                />
                        </div>
                    </div>
                </div>
               
            </CommonHeader>
        )
    }
}

export default ExcludeFeedbackUser;