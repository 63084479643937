import React from "react";
import { epochTogmt } from "../../../../../../../../../utils/common";

export const tableProjectPointsConstants = () => {
  return [
    {
      id: 1,
      name: "Project Id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: '230px',
    },
    {
      id: 2,
      name: "Project Name",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      grow: 1,
      style: {
          color: "#202124",
          fontSize: "14px",
          fontWeight: 500,
      },
    },    
    {
      id: 3,
      name: "Project Link",
      cell: (row) => {
        return (
          <a href={row.link} target="_blank">
            <span className="sub">{row.link}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Date",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    }
  ];
};
