import React, { Component } from "react";
import { auth_service } from "../../../auth/auth_service";
import FormInput from "../../../components/InputFields/form_input";
import UploadFiles from "../../../components/UploadFiles/upload_files";
import styles from "./create_cause.module.css";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import { CommonHeader } from "../../../utils/commonHeader";
import style from "../../../components/InputFields/form_input.module.css";
import httpService from "../../../AxiosInterceptors";
import history from "../../../utils/history";
import TextArea from "../../../components/TextArea/text_area";
import LocationInput from "../../../components/LocationFields/location_input";
import stripHtml from "string-strip-html";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const ErorrMsgPopUp = withReactContent(alertPopUp);

httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
export class CreateCause extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      coverImage: null,
      title: "",
      blogLinks: [],
      content: "",
      hashTag: [],
      country: "",
      states: "",
      city: "",
      metaKeyWords: [],
      metaTitle: "",
      metaDescription: "",
      coverImageMessage: "",
      errors: "",
    };
  }

  // validate create cause
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter cause title";
    }
    if (this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) {
      formIsValid = false;
      this.setState({
        coverImageMessage: "Please select cover image"
      })
    }
    if (
      this.state.hashTag === null ||
      this.state.hashTag === undefined ||
      this.state.hashTag.length === 0
    ) {
      formIsValid = false;
      errors["hashTag"] = "Please enter hashtags";
    }
    if (!this.state.content || this.state.content.trim() === "" || stripHtml(this.state.content) == '') {
      formIsValid = false;
      errors["content"] = "Please enter description";
    }
    if (!this.state.metaTitle) {
      formIsValid = false;
      errors["metaTitle"] = "Please enter meta title";
    }
    if (!this.state.metaDescription || this.state.metaDescription.trim() === "" || this.state.metaDescription === "<p><br></p>") {
      formIsValid = false;
      errors["metaDescription"] = "Please enter meta description";
    }
    if (
      this.state.metaKeyWords === null ||
      this.state.metaKeyWords === undefined ||
      this.state.metaKeyWords.length === 0
    ) {
      formIsValid = false;
      errors["metaKeyWords"] = "Please enter a meta keywords";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  //   title change
  titleChange = (event) => {
    this.setState({
      title: event.target.value.trimStart(),
    });
  };

  //   blog link change
  blogLinkChange = (event) => {
    let blogLinksList = [];
    blogLinksList.push(event.target.value.trimStart());
    this.setState({
      blogLinks: blogLinksList,
    });
  };
  // handle content change
  contentChange = (value) => {
    this.setState({ content: value });
  };

  // handle hashtag change
  hashTagChange = (tags) => {
    this.setState({ hashTag: tags });
  };

  //   meta title change
  metatitleChange = (event) => {
    this.setState({
      metaTitle: event.target.value.trimStart(),
    });
  };

  //   meta description change
  metadescriptionChange = (event) => {
    this.setState({
      metaDescription: event.target.value,
    });
  };

  // handle meta keywords change
  metaKeyWordsChange = (keywords) => {
    this.setState({ metaKeyWords: keywords });
  };

  onSubmit = () => {
    if(this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let postData = {
        userId: this.state.userData.id,
        createdBy: this.state.userData.firstName,
        name: this.state.title,
        content: this.state.content,
        hashTags: this.state.hashTag,
        linkIds: this.state.blogLinks,
        keyWords: this.state.metaKeyWords,
        metaTitle: this.state.metaTitle,
        metaDescription: this.state.metaDescription,
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/cause/create",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  // country states
  country = (value) => {
    this.setState({
      country: value,
    });
  };

  // states state
  states = (value) => {
    this.setState({
      states: value,
    });
  };

  // city state
  city = (value) => {
    this.setState({
      city: value,
    });
  };

  modules = {
      toolbar: [
          [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ align: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
      ],
      clipboard: {
          matchVisual: false,
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          handleStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      imageCompress: {
          quality: 0.7, // default
          maxWidth: 750, // default
          maxHeight: 500, // default
          imageType: 'image/jpeg' + 'image/png', // default
      },
  };

  render() {
    const {
      title,
      blogLinks,
      content,
      hashTag,
      metaTitle,
      metaKeyWords,
      metaDescription,
    } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/causes" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <UploadFiles
                accept=".jpg,.png,.jpeg"
                label="Cover Image"
                updateFilesCb={this.updateUploadedFiles}
                imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
              />
              {this.state.coverImageMessage && 
                <div className="text-center">
                  <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                </div>
              }
              <FormInput
                group={"large"}
                value={title}
                label={"Cause title"}
                onChange={this.titleChange}
                error={this.state.errors["title"]}
              />
              <div className={"form-group " + (this.state.errors["content"] ? 'error' : '')}>
                <label>Content <span className="text-danger">*</span>{" "}</label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={content}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["content"] && (
                  <small className={style.danger}>
                    {this.state.errors["content"]}
                  </small>
                )}
              </div>
              <FormInput
                group={"large"}
                value={blogLinks}
                label={"Link of blogs"}
                onChange={this.blogLinkChange}
                className="not_required"
              />
              {/* <LocationInput
                country={this.country}
                state={this.states}
                city={this.city}
              /> */}
              <div className={"form-group " + (this.state.errors["hashTag"] ? 'error' : '')}>
                <label>Hashtag <span className="text-danger">*</span>{" "}</label>
                <TagsInput
                  value={hashTag}
                  onChange={this.hashTagChange}
                  addKeys={[9, 13, 32, 62, 188]}
                />

                {this.state.errors["hashTag"] && (
                  <small className={style.danger}>
                    {this.state.errors["hashTag"]}
                  </small>
                )}
              </div>
              <Line />
              <FormInput
                group={"large"}
                value={metaTitle}
                label={"Meta Title"}
                onChange={this.metatitleChange}
                error={this.state.errors["metaTitle"]}
              />
              <TextArea
                label={"Meta Description"}
                value={metaDescription}
                onChange={this.metadescriptionChange}
                error={this.state.errors["metaDescription"]}
              />
              <div className={"form-group " + (this.state.errors["metaKeyWords"] ? 'error' : '')}>
                <label>
                  {`Meta Keywords `}
                  <span className="text-danger">*</span>{" "}
                </label>
                <TagsInput
                  value={metaKeyWords}
                  onChange={this.metaKeyWordsChange}
                  addKeys={[9, 13, 62, 188]}
                />
                {this.state.errors["metaKeyWords"] && (
                  <small className={style.danger}>
                    {this.state.errors["metaKeyWords"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("Causes_Create", "Causes_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Submit"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateCause;
