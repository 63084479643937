import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import styles from "./post.module.css";
import { tablepostConstants } from "../Data/postTableConstants";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from "react-select";
import { FilterComponent } from "../../../utils/filter";

const MySwal = withReactContent(alertPopUp);

const filterOptions = [
  { value: "ALL", label: "ALL" },
  { value: "POLL", label: "POLL" },
  { value: "FORUM", label: "FORUM" },
  { value: "POST", label: "POST" },
  { value: "ASSIGNMENT", label: "ASSIGNMENT" },
  { value: "EXPEDITION", label: "EXPEDITION" },
  { value: "STORYBOOK", label: "STORYBOOK" },
  { value: "FUNDRAISE", label: "FUNDRAISE" },
  { value: "EVENT", label: "EVENT" },
  { value: "JOB", label: "JOB" },
  { value: "TRAINING", label: "TRAINING" },
  { value: "STUDIO", label: "STUDIO" },
];

let deleteConfirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

let ignoreConfirmModalSwal = {
  title: '<h5>Are you sure you want to Ignore?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};
export class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      postData: [],
      filterData: { value: "ALL", label: "ALL" },
      filterText: "",
      loading: false,
      totalRows: 0,
      perPage: 10,
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.postData.filter(
              (item) =>
                item.postType &&
                item.postType.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by post type'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
        
      </React.Fragment>
    );
  };

  // fetch report post data
  fetchReportPostData = async (page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/list?page=${page}&size=${this.state.perPage}&createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&filterType=${this.state.filterData.value}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            postData: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
          MySwal.clickConfirm()
        }
      })
      .then((error) => {
        console.log(error);
        this.setState({
          loading: false
        })
      });
  };

  handleConfirmModal = (id, type) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(type === "DELETE" ? deleteConfirmModalSwal : ignoreConfirmModalSwal);
      if (swalval && swalval.value === true) {
        let data = {
          id: id
        }
        axios({
          method: "POST",
          url: type === "DELETE" ? `${process.env.REACT_APP_userServiceURL}/backend/reportPost/update?id=${id}` : `${process.env.REACT_APP_userServiceURL}/backend/reportabuse/ignore`,
          data: data,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              let postItemList = this.state.postData;
              const index = postItemList && postItemList.findIndex(data => data.id === id);
              if (index > -1) {
                postItemList.splice(index, 1); 
              }
              this.setState({
                postData: postItemList
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    handleConfirm();
  };

  handleFilterChange = (value) => {
    this.setState({
      filterData: value
    },() => {
      this.fetchReportPostData(0);
    })
  }

  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
      </React.Fragment>
    );
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/list?page=${page-1}&size=${newPerPage}&createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&filterType=${this.state.filterData.value}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            postData: response.data.body && response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          postData: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchReportPostData(page-1);
  };

  componentDidMount() {
    this.fetchReportPostData(0);
  }
  
  render() {
    const { filterData } = this.state;
    this.filteredItems = this.state.postData.filter(
      (item) =>
        item.postType &&
        item.postType.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <Select
                      options={filterOptions}
                      placeholder={"Filter by status"}
                      value={filterData}
                      onChange={this.handleFilterChange}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              title="Post Report"
              columns={tablepostConstants(this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              highlightOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default Post;
