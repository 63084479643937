import React, { Component } from "react";
import styles from "../BelongHere/pre-login.module.css"
import styled from "styled-components";
import Button from "../../../../components/Buttons/button"
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormInput from "../../../../components/InputFields/form_input";
import axios from "axios";
import history from "../../../../utils/history";
import { auth_service } from "../../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class InspiringForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            link: "",
            errors: {},
            error: false,
            coverImageMessage: "",
            editData: {}
        };
    }

    // validate create amount
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (this.props?.loginType !== "POST_LOGIN" && !this.state.title) {
            formIsValid = false;
            errors["title"] = "Please enter title";
        }
        if (this.props?.loginType !== "POST_LOGIN" && !this.state.link) {
            formIsValid = false;
            errors["link"] = "Please enter link";
        }
        if (this.state.link) {
            let valudateLink = this.state.link;
            let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                formIsValid = false;
                errors["link"] = "Please enter a valid link";
            }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    }

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                title: this.state.title,
                link: this.state.link,
                status: this.props.formType === "CREATE" ? "DRAFT" : this.state.editData.status,
                loginType : this.props?.loginType,
            };
            if(this.props.formType === "UPDATE") {
                postData.id = this.props.id
            }

            axios({
                method: this.props.formType === "UPDATE" ? "PUT" : "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/${this.props?.loginType === "POST_LOGIN" ? "post" : "pre"}/login/inspiringStories/${this.props.formType === "UPDATE" ? "update" : "create"}`,
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }
    }


    fetchInspiringData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/${this.props?.loginType === "POST_LOGIN" ? "post":"pre"}/login/inspiringStories/get?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                this.setState({
                    title: result?.title,
                    link: result?.link,
                    editData: result
                })
            }
        }).catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm()
                }
            }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
        });
    }

    componentDidMount() {
        if(this.props.formType === "UPDATE") {
            this.fetchInspiringData();
        }
    }

    render() {
        const { title, link, errors } = this.state;
        const { loginType } = this.props;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-12">
                       {loginType !== "POST_LOGIN" && 
                        <FormInput
                                group={"large"}
                                value={title}
                                label={"Title"}
                                onChange={this.handleChange}
                                name={"title"}
                                error={errors["title"]}
                            />
                        }
                    </div>
                    <div className="col-md-12">
                        <FormInput
                            group={"large"}
                            value={link}
                            label={"Link"}
                            onChange={this.handleChange}
                            name={"link"}
                            error={errors["link"]}
                        />
                    </div>
                </div>

                {auth_service.getPermission(this.props.formType === "CREATE" ? (loginType !== "POST_LOGIN" ? "Pre_Login_Inspiring_Stories_Create" : "Post_Login_Inspiring_Stories_Create") : (loginType !== "POST_LOGIN" ? "Pre_Login_Inspiring_Stories_Update" : "Post_Login_Inspiring_Stories_Update"), (loginType !== "POST_LOGIN" ? "Pre_Login_Inspiring_Stories_FullAccess" : "Post_Login_Inspiring_Stories_FullAccess")) && 
                    <ButtonWrap className={`${styles.bottombutt}`}>
                        <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                    </ButtonWrap>}
            </div>
        );
    }
}

export default InspiringForm;