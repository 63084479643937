import React, { Component } from "react";
import FormInput from "../../../components/InputFields/form_input";
import styles from "./CreateCircleSubscription/create_circle_subscription.module.css";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import Select from "react-select";
import history from "../../../utils/history";
import httpService from "../../../AxiosInterceptors";
import style from "../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getSubscriptionType, subscriptionType } from "../CommonSubscriptionFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp)
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

class CircleSubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionType: "",
            circleCreatePrice: "",
            multipleAdminsPrice: "",
            invitePartPrice: "",
            members: "",
            memebershipRequestFlag: false,
            membershipSubscriptiontFlag: false,
            errors: {},
            error: false,
            message: null,
            circleSubscriptionId: "",
            donateContentFlag: false,
            donateCircleCount: "",
            daysAllowed: "",
            donatenNoOfCircle: "",
            donateAmountCircleINR: "",
            donateAmountCircleUSD: "",
            additionalDaysPlan: [{days: "", amountInINR: "", amointInUSD: ""}],
            donateNowTopAllowed: false,
            additionalDaysTopUpAllowed: false
        };
    }

    // validate create user
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.subscriptionType) {
            formIsValid = false;
            errors["subscriptionType"] = "Please enter subscription type";
        }
        if (!this.state.circleCreatePrice) {
            formIsValid = false;
            errors["circleCreatePrice"] = "Please enter create your circle";
        }
        if (!this.state.multipleAdminsPrice) {
            formIsValid = false;
            errors["multipleAdminsPrice"] = "Please enter multiple admins";
        }
        if (!this.state.invitePartPrice) {
            formIsValid = false;
            errors["invitePartPrice"] = "Please enter invite participants";
        }
        if (!this.state.members) {
            formIsValid = false;
            errors["members"] = "Please enter members";
        }
        if(this.state.donateContentFlag) {
            if (!this.state.donateCircleCount) {
                formIsValid = false;
                errors["donateCircleCount"] = "Please enter circle count.";
            }
            if (!this.state.daysAllowed) {
                formIsValid = false;
                errors["daysAllowed"] = "Please enter days allowed.";
            }
            /* if (!this.state.donatenNoOfCircle) {
                formIsValid = false;
                errors["donatenNoOfCircle"] = "Please enter no of circles.";
            } */
            if(this.state.donateNowTopAllowed) {
                if (!this.state.donateAmountCircleINR) {
                    formIsValid = false;
                    errors["donateAmountCircleINR"] = "Please enter amount per Circles(INR).";
                }
                if (!this.state.donateAmountCircleUSD) {
                    formIsValid = false;
                    errors["donateAmountCircleUSD"] = "Please enter amount per Circles(USD).";
                }
            }
            if(this.state.additionalDaysTopUpAllowed) {
                this.state.additionalDaysPlan && this.state.additionalDaysPlan.map((data, index) => {
                    const newArray = this.state.additionalDaysPlan;
                    if (!data.days) {
                        newArray[index].daysErrorMessage = "Please enter additional top up days.";
                        formIsValid = false;
                    }
                    if (!data.amountInINR) {
                        newArray[index].amountInINRErrorMessage = "Please enter amount per Circles(INR).";
                        formIsValid = false;
                    }
                    if (!data.amointInUSD) {
                        newArray[index].amointInUSDErrorMessage = "Please enter amount per Circles(INR).";
                        formIsValid = false;
                    }
                    this.setState({
                        additionalDaysPlan: newArray
                    })
                    setTimeout(() => {
                        newArray[index].daysErrorMessage = null;
                        newArray[index].amountInINRErrorMessage = null;
                        newArray[index].amointInUSDErrorMessage = null;
                        this.setState({
                            additionalDaysPlan: newArray
                        })
                    }, 5000);
                });
            }
        }
        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    //   price value change
    priceValueChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };

    subscriptionType = (type) => {
        this.setState({ subscriptionType: type });
    };

    handleChangeChk = (event) => {
        this.setState({
            [event.target.name]: event.target.checked,
        });
        if(event.target.name === "additionalDaysTopUpAllowed" && !event.target.checked) {
            this.setState({
                additionalDaysPlan: [{days: "", amountInINR: "", amointInUSD: ""}]
            })
        }
    }

    addAdditionalTopUp = () => {
        let additionalDaysData = this.state.additionalDaysPlan;
        additionalDaysData.push({days: "", amountInINR: "", amointInUSD: ""});
        this.setState({
            additionalDaysPlan: additionalDaysData
        })
    }

    removeAdditiionaldays = (index) => {
        let additionalDaysData = this.state.additionalDaysPlan;
        additionalDaysData.splice(index, 1);
        this.setState({
            additionalDaysPlan: additionalDaysData
        })
    }

    handleChangeAdditionalDaysPlan = (event, index) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            let additionalDaysData = this.state.additionalDaysPlan;
            if(event.target.name === "days") {
                additionalDaysData[index].days = event.target.value
            } else if(event.target.name === "amountInINR") {
                additionalDaysData[index].amountInINR = event.target.value
            } else {
                additionalDaysData[index].amointInUSD = event.target.value
            }
            this.setState({
                additionalDaysPlan: additionalDaysData
            });
        }
    }

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                type: this.state.subscriptionType && this.state.subscriptionType.value,
                create: this.state.circleCreatePrice,
                multipleAdmins: this.state.multipleAdminsPrice,
                inviteParticipantsOutsideWN: this.state.invitePartPrice,
                memberShipRequestControl: this.state.memebershipRequestFlag,
                memberShipSubscription: this.state.membershipSubscriptiontFlag,
                members: this.state.members,
                donateNow: this.state.donateContentFlag
            };
            if(this.state.donateContentFlag) {
                let donateNowContent = {
                    count: this.state.donateCircleCount,
                    daysAllowed: this.state.daysAllowed,
                    donateNowTopAllowed: this.state.donateNowTopAllowed,
                    additionalDaysTopUpAllowed: this.state.additionalDaysTopUpAllowed
                }
                if(this.state.donateNowTopAllowed) {
                    let donateNowTopup = {
                        count: this.state.donatenNoOfCircle,
                        amountInINR: this.state.donateAmountCircleINR,
                        amointInUSD: this.state.donateAmountCircleUSD
                    }
                    donateNowContent.donateNowTopup = donateNowTopup;
                    if(this.state.additionalDaysTopUpAllowed) {
                        donateNowContent.additionalDaysPlan = this.state.additionalDaysPlan;
                    }
                }
                postData.donateNowContent = donateNowContent;
            }
            if (this.props.formType === "EDIT") {
                postData.id = this.state.circleSubscriptionId;
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/subscription/${this.props.formType === "CREATE" ? "circle_create" : "circle_update"}`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.message === "Success!"
                ) {
                    history.goBack();
                }
            }).catch((error) => {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: false
                });
            }, 5000);
        }
    };

    fetchCircleSubscriptionById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/subscription/circle/get?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (
                response && response.data && response.data.body &&
                response.data.status === "200 OK"
            ) {
                let data = response.data.body;
                this.setState({
                    circleSubscriptionId: data.id,
                    subscriptionType: { value: data.type, label: getSubscriptionType(data.type) },
                    circleCreatePrice: data.create,
                    invitePartPrice: data.inviteParticipantsOutsideWN,
                    multipleAdminsPrice: data.multipleAdmins,
                    memebershipRequestFlag: data.memberShipRequestControl,
                    membershipSubscriptiontFlag: data.memberShipSubscription,
                    members: data.members,
                    donateContentFlag: data.donateNow,
                    donateNowTopAllowed: data?.donateNowContent?.donateNowTopAllowed,
                    additionalDaysTopUpAllowed: data?.donateNowContent?.additionalDaysTopUpAllowed,
                    donateCircleCount: data?.donateNowContent?.count,
                    daysAllowed: data?.donateNowContent?.daysAllowed,
                    donatenNoOfCircle: data?.donateNowContent?.donateNowTopup?.count,
                    donateAmountCircleINR: data?.donateNowContent?.donateNowTopup?.amountInINR,
                    donateAmountCircleUSD: data?.donateNowContent?.donateNowTopup?.amointInUSD,
                    additionalDaysPlan: data.donateNowContent && data.donateNowContent.additionalDaysPlan ? data.donateNowContent.additionalDaysPlan : [{days: "", amountInINR: "", amointInUSD: ""}]
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    componentDidMount() {
        if (this.props.formType === "EDIT") {
            this.fetchCircleSubscriptionById();
        }
    }

    render() {
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="d-flex align-items-center">Subscription Type <span className="text-danger">*</span></label>
                        <Select
                            value={this.state.subscriptionType}
                            options={subscriptionType}
                            onChange={this.subscriptionType}
                            placeholder={"Start typing"}
                            closeMenuOnSelect={true}
                            filterOption={(option) => option.value !== "ALL"}
                            isDisabled={this.props.formType === "EDIT" ? true : false}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {this.state.errors["subscriptionType"] && (
                            <small className={style.danger}>
                                {this.state.errors["subscriptionType"]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.circleCreatePrice}
                            label={"Create your Circle"}
                            onChange={this.priceValueChange}
                            name={"circleCreatePrice"}
                            error={this.state.errors["circleCreatePrice"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.multipleAdminsPrice}
                            label={"Multiple admins"}
                            onChange={this.priceValueChange}
                            name={"multipleAdminsPrice"}
                            error={this.state.errors["multipleAdminsPrice"]}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.invitePartPrice}
                            label={"Invite participants outside WeNat"}
                            onChange={this.priceValueChange}
                            name={"invitePartPrice"}
                            error={this.state.errors["invitePartPrice"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.members}
                            label={"Members"}
                            onChange={this.priceValueChange}
                            name={"members"}
                            error={this.state.errors["members"]}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" ref="memebershipRequestPrice" name="memebershipRequestFlag" onClick={this.handleChangeChk} checked={this.state.memebershipRequestFlag}  />
                            <label className="custom-control-label" htmlFor="memebershipRequestPrice"
                                onClick={(e) => {
                                    this.refs["memebershipRequestPrice"].click();
                                }}>
                                Membership request control
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" ref="membershipSubscriptionPrice" name="membershipSubscriptiontFlag" onClick={this.handleChangeChk} checked={this.state.membershipSubscriptiontFlag} />
                            <label className="custom-control-label" htmlFor="membershipSubscriptionPrice"
                                onClick={(e) => {
                                    this.refs["membershipSubscriptionPrice"].click();
                                }}>
                                Membership Subscription
                            </label>
                        </div>
                    </div>
                </div>

                <hr />

                <div className={`form-group`}>
                    <label class="switch">
                        Donation Content
                        <input
                            type="checkbox"
                            ref="donateContentFlag"
                            name="donateContentFlag"
                            onClick={(e) => {
                                this.handleChangeChk(e);
                            }}
                            checked={this.state.donateContentFlag}
                        />
                        <span class="slider round"></span>
                    </label>
                </div>

                {this.state.donateContentFlag &&
                    <div className="stsubscripForm">
                        <div className="form-group row">
                            <div className="col-md-6">
                                <FormInput
                                    group={"large"}
                                    label={"Circle Count"}
                                    value={this.state.donateCircleCount}
                                    name={"donateCircleCount"}
                                    onChange={this.priceValueChange}
                                    error={this.state.errors["donateCircleCount"]}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    group={"large"}
                                    label={"Days Allowed"}
                                    value={this.state.daysAllowed}
                                    name={"daysAllowed"}
                                    onChange={this.priceValueChange}
                                    error={this.state.errors["daysAllowed"]}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label class="switch">
                                    <strong>Top Up</strong>
                                    <input
                                        type="checkbox"
                                        ref="donateNowTopAllowed"
                                        name="donateNowTopAllowed"
                                        onClick={(e) => {
                                            this.handleChangeChk(e);
                                        }}
                                        checked={this.state.donateNowTopAllowed}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.donateNowTopAllowed && 
                            <>
                                <div className="form-group row">
                                    {/* <div className="col-md-4">
                                        <FormInput
                                            group={"large"}
                                            label={"No of Circles"}
                                            value={this.state.donatenNoOfCircle}
                                            name={"donatenNoOfCircle"}
                                            onChange={this.priceValueChange}
                                            error={this.state.errors["donatenNoOfCircle"]}
                                        />
                                    </div> */}
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            label={"Amount per Circles(INR)"}
                                            value={this.state.donateAmountCircleINR}
                                            name={"donateAmountCircleINR"}
                                            onChange={this.priceValueChange}
                                            error={this.state.errors["donateAmountCircleINR"]}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FormInput
                                            group={"large"}
                                            label={"Amount per Circles(USD)"}
                                            value={this.state.donateAmountCircleUSD}
                                            name={"donateAmountCircleUSD"}
                                            onChange={this.priceValueChange}
                                            error={this.state.errors["donateAmountCircleUSD"]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <label class="switch">
                                            <strong>Additional Days Top Up</strong>
                                            <input
                                                type="checkbox"
                                                ref="additionalDaysTopUpAllowed"
                                                name="additionalDaysTopUpAllowed"
                                                onClick={(e) => {
                                                    this.handleChangeChk(e);
                                                }}
                                                checked={this.state.additionalDaysTopUpAllowed}
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {this.state.additionalDaysTopUpAllowed && 
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <ButtonWrap>
                                                    <Button children={"+ Add Additional Days Top Up"} onClick={this.addAdditionalTopUp} />
                                                </ButtonWrap>
                                            </div>
                                        </div>
                                        {this.state.additionalDaysPlan && this.state.additionalDaysPlan.map((data, index) => {
                                            return (
                                                <div className="form-group row">
                                                    <div className={"col-md-" + (index > 0 ? "3" : "4")}>
                                                        <FormInput
                                                            group={"large"}
                                                            label={"Additional Top Up Days"}
                                                            value={data.days}
                                                            name={"days"}
                                                            onChange={(e) => this.handleChangeAdditionalDaysPlan(e, index)}
                                                            className={"not-required"}
                                                            error={data?.daysErrorMessage}
                                                        />
                                                    </div>
                                                    <div className={"col-md-" + (index > 0 ? "3" : "4")}>
                                                        <FormInput
                                                            group={"large"}
                                                            label={"Amount per Circles(INR)"}
                                                            value={data.amountInINR}
                                                            name={"amountInINR"}
                                                            onChange={(e) => this.handleChangeAdditionalDaysPlan(e, index)}
                                                            className={"not-required"}
                                                            error={data?.amountInINRErrorMessage}
                                                        />
                                                    </div>
                                                    <div className={"col-md-" + (index > 0 ? "3" : "4")}>
                                                        <FormInput
                                                            group={"large"}
                                                            label={"Amount per Circles(USD)"}
                                                            value={data.amointInUSD}
                                                            name={"amointInUSD"}
                                                            onChange={(e) => this.handleChangeAdditionalDaysPlan(e, index)}
                                                            className={"not-required"}
                                                            error={data?.amointInUSDErrorMessage}
                                                        />
                                                    </div>
                                                    {index > 0 && 
                                                        <div className="col-md-3">
                                                            <Button className="iTrashBin" children={"Remove"} style={{ marginTop: "35px" }} onClick={() => { this.removeAdditiionaldays(index) }} />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }             
                            </>
                        }
                    </div>
                }

                <ButtonWrap className={`${styles.bottombutt}`}>
                    <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                </ButtonWrap>
                {this.state.error.message !== "" && this.state.error.message !== undefined &&
                    <div className={`${styles.bottombutt} alert alert-warning`}>
                        {this.state.error.message}
                    </div>
                }
            </div>
        );
    }
}

export default CircleSubscriptionForm;
