import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";

export const tableConstants = (fetchAllCategoryList,handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Category Name",
      cell: (row) => {
        return (
          <Link to={`/explore-categories/edit/${row.id}`}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Hashtags",
      cell: (row) => {
        let output = [];
        let count = 0;
        row.hashTags &&
          row.hashTags.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "No. of blogs",
      selector: (row) => row.noOfBlogs,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Date created",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"category"}
            action={fetchAllCategoryList}
            fullPermission={"Explore_Category_FullAccess"}
            approvalPermission={"Explore_Category_Approval"}
            publishPermission={"Explore_Category_Publish"}
            enablePermission={"Explore_Category_Enable"}
            disablePermission={"Explore_Category_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 7,
      name: "Sort Bypass",
      selector: (row) => row.sortBypass,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Sort Bypass Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"category"}
            action={fetchAllCategoryList}
            fullPermission={"Explore_Category_FullAccess"}
            approvalPermission={"Explore_Category_Approval"}
            publishPermission={"Explore_Category_Publish"}
            enablePermission={"Explore_Category_Enable"}
            disablePermission={"Explore_Category_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 9,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("Explore_Category_Delete", "Explore_category_FullAccess") || row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
