export const customStyles = {
  headRow: {
    style: {
      minHeight: "inherit",
    },
  },
  col: {},
  headCells: {
    style: {
      background: "#698F8A",
      padding: "12px 10px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "13px",
      color: "#F2FFF5",
      position: "relative",
      color: "#F2FFF5",
      borderLeft: "1px solid",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};
