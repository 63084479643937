import axios from "axios";
import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { auth_service } from "../../../auth/auth_service";
// This is the table constant/settings which needed to render table elements

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export const tableExcludeUserConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "User Name",
      selector: (row) => row.userName,
      cell: (row) => {
        return (
          <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.customUrl} target="_blank">
            <span className="sub">{`${row.userName}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (auth_service.getPermission("Studio_ExcludeUser_Delete") ? '' : ' disable')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
