export const data = [
  {
    id: "6156ada50136125785a753a6",
    year: "2019",
    link: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT",
  },
  {
    id: "6156ada50136125785a753a7",
    year: "2019",
    link: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT",
  },
  {
    id: "6156ada50136125785a753a8",
    year: "2019",
    link: "https://www.youtube.com/watch?v=GiyL4KFRNBA",
    details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT",
  },
];
