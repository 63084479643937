import React, { Component } from "react";
import styles from "../BelongHere/pre-login.module.css"
import styled from "styled-components";
import Button from "../../../../components/Buttons/button"
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormInput from "../../../../components/InputFields/form_input";
import axios from "axios";
import history from "../../../../utils/history";
import TagsInput from "react-tagsinput";
import style from "../../../../components/InputFields/form_input.module.css";
import { v4 as uuidv4 } from 'uuid';
import UploadFilesData from "../../../../components/UploadFiles/upload_file_data";
import { auth_service } from "../../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

class TrendingContentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trendingArticle: [{ link: "", id: uuidv4() }],
            trendingVideo: [{ link: "", thumbnailAttachment: {}, id: uuidv4() }],
            errors: "",
            editData: {},
            popularTags: [],
        }
    }

    addTrendingData = (fieldType) => {
        let trendingData = fieldType === "TRENDING_ARTICLE" ? this.state.trendingArticle : this.state.trendingVideo;
        trendingData.push({ link: "",thumbnailAttachment: {}, id: uuidv4() });
        this.setState({
            [fieldType === "TRENDING_ARTICLE" ? "trendingArticle" : "trendingVideo"]: trendingData
        })
    };

    removeTrending = (fieldType, id) => {
        let trendingData = fieldType === 'TRENDING_ARTICLE' ? this.state.trendingArticle : this.state.trendingVideo;
        let index = trendingData && trendingData.findIndex((item) => item.id === id);
        if (index > -1) {
            trendingData.splice(index, 1);
        }
        this.setState({
            [fieldType === "TRENDING_ARTICLE" ? "trendingArticle" : "trendingVideo"]: trendingData
        });
    };

    onChangeTrendingData = (id, event, fieldType) => {
        let trendingData = fieldType === 'TRENDING_ARTICLE' ? this.state.trendingArticle : this.state.trendingVideo;
        let index = trendingData && trendingData.findIndex((item) => item.id === id);
        if(index > -1) {
            trendingData[index].link = event.target.value.trimStart();
        }
        this.setState({
            [fieldType === "TRENDING_ARTICLE" ? "trendingArticle" : "trendingVideo"]: trendingData,
        })
    };

    popularTagsChange = (keywords) => {
        this.setState({ popularTags: keywords });
    };

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        this.state.trendingArticle && this.state.trendingArticle.map((data, index) => {
            const newArray = this.state.trendingArticle;
            if (!data.link) {
                newArray[index].errorMessage = "Please enter a link.";
                formIsValid = false;
            }
            if (data.link) {
                let urlValid = data.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (urlValid == null) {
                    newArray[index].errorMessage = "Please enter a valid link.";
                    formIsValid = false;
                }
            }
            this.setState({
                trendingArticle: newArray
            })
            setTimeout(() => {
                newArray[index].errorMessage = null;
                this.setState({
                    trendingArticle: newArray
                })
            }, 5000);
        });

        this.state.trendingVideo && this.state.trendingVideo.map((data, index) => {
            const newArray = this.state.trendingVideo;
            if (!data.link) {
                newArray[index].errorMessage = "Please enter a link.";
                formIsValid = false;
            }
            
            if (data.link) {
                let urlValid = data.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (urlValid == null) {
                    newArray[index].errorMessage = "Please enter a valid link.";
                    formIsValid = false;
                }
            }

            if(!data.thumbnailAttachment?.id) {
                newArray[index].attachmentMessage = "Please select attachment.";
                formIsValid = false;
            }
            
            this.setState({
                trendingVideo: newArray
            })
            setTimeout(() => {
                newArray[index].errorMessage = null;
                newArray[index].attachmentMessage = null;
                this.setState({
                    trendingVideo: newArray
                })
            }, 5000);
        });

        if (this.state.popularTags.length === 0) {
            formIsValid = false;
            errors["popularTags"] = "Please enter a popular tags";
        }
        this.setState({
            errors: errors,
        })
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    }

    handleFormSubmit = () => {
        if (this.validateForm()) {
            let formData = {
                trendingArticles: this.state.trendingArticle && this.state.trendingArticle.map((item) => {return item.link}),
                trendingVideoLinksDto: this.state.trendingVideo && this.state.trendingVideo.map((item) => {return {trendingVideoLink: item?.link , attachmentId: item?.thumbnailAttachment?.id}}),
                popularTags: this.state.popularTags,
                status: this.props.formType === "CREATE" ? "DRAFT" : this.state.editData?.status,
                loginType: "PRE_LOGIN",                
            }

            if (this.props.formType === "UPDATE") {
                formData.id = this.props.id
            }
            axios({
                method: this.props.formType === "CREATE" ? "POST" : "PUT",
                url: `${process.env.REACT_APP_userServiceURL}/backend/pre/login/trendingContent/${this.props.formType === "CREATE" ? "create" : "update"}`,
                data: formData,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }
    }

    getTrendingContentbyId = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/pre/login/trendingContent/get?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                let trendingArticles = result?.trendingArticles && result.trendingArticles.map((item) => {
                    return { link: item, id: uuidv4() };
                })
                let trendingVideo = result?.trendingVideos && result.trendingVideos.map((item) => {
                    return { link: item?.trendingVideoLink,thumbnailAttachment: item?.attachment ? item?.attachment : {}, id: uuidv4() };
                })
                this.setState({
                    trendingArticle: trendingArticles,
                    trendingVideo: trendingVideo,
                    popularTags: result?.popularTags,
                    editData: result
                })
            }
        }).catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm()
                }
            }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
        });
    }

    componentDidMount() {
        if(this.props.formType === "UPDATE") {
            this.getTrendingContentbyId()
        }
    }


    updateUploadedFiles = (image,logoUploadFiles, id) => {
       let trendingVideo = this.state.trendingVideo
       let index =  trendingVideo && trendingVideo.findIndex(item => item.id === id)
       if(index > -1) {
           trendingVideo[index].thumbnailAttachment = image;
       }
        this.setState({
            trendingVideo: trendingVideo,
        });
    };

    render() {
        const { trendingArticle, trendingVideo, popularTags, errors} = this.state;

        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-12">
                        <h5>Trending Article Section</h5>
                    </div>

                    {trendingArticle && trendingArticle.map((item, index) => {
                        return (
                            <>
                                <div className={`col-md-${index === 0 ? "12" : "10"}`}>
                                    <FormInput
                                        group={"large"}
                                        value={item?.link}
                                        label={"Link"}
                                        name={"articleLink"}
                                        onChange={(event) => this.onChangeTrendingData(item.id, event, "TRENDING_ARTICLE")}
                                        error={item?.errorMessage}
                                    />
                                </div>

                                {index > 0 &&
                                    <div className="col-md-2">
                                        <Button className="iTrashBin" children={"Remove"} style={{ marginTop: "35px" }} onClick={() => { this.removeTrending("TRENDING_ARTICLE", item.id) }} />
                                    </div>
                                }
                            </>
                        )
                    })}
                    {trendingArticle.length < 4 && <div className="col-md-12">
                        <Button children={"Add Article"} onClick={() => this.addTrendingData("TRENDING_ARTICLE")} />
                    </div>}

                    <div className="col-md-12">
                        <hr />
                    </div>

                    <div className="col-md-12">
                        <h5>Trending Video Section</h5>
                    </div>

                    {trendingVideo && trendingVideo.map((item, index) => {
                        return (
                            <>
                                <div className={`col-md-8`}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormInput
                                                group={"large"}
                                                value={item?.link}
                                                label={"Link"}
                                                name={"videoLink"}
                                                onChange={(event) => this.onChangeTrendingData(item.id, event, "TRENDING_VIDEO")}
                                                error={item?.errorMessage}
                                            />
                                        </div>
                                        {index > 0 && 
                                            <div className="col-md-12">
                                                <Button className="iTrashBin" children={"Remove"} style={{ marginTop: "35px" }} onClick={() => { this.removeTrending("TRENDING_VIDEO", item.id) }} />
                                            </div>
                                        }
                                    </div>
                                    
                                </div>
                                <div className="col-md-4">
                                    <UploadFilesData
                                        id={`${item.id}`}
                                        accept=".jpg,.png,.jpeg"
                                        label="thumbnail"
                                        updateFilesCb={this.updateUploadedFiles}
                                        logoUploadFiles={true}
                                        updateImage={item?.thumbnailAttachment?.attachmentUrl}
                                        imagePresent={!item?.thumbnailAttachment?.attachmentUrl ? 'noImage' : ''}
                                    />
                                </div>
                                {item?.attachmentMessage &&
                                    <div className="text-center">
                                        <small class="form_input_danger__3kp97">{item?.attachmentMessage}</small>
                                    </div>
                                }
                            </>
                        )
                    })}
                    {trendingVideo.length < 3 && <div className="col-md-12">
                        <Button children={"Add Video"} onClick={() => this.addTrendingData("TRENDING_VIDEO")} />
                    </div>}

                    <div className="col-md-12">
                        <hr />
                    </div>

                    <div className="col-md-12">
                        <div className={"form-group " + (this.state.errors["popularTags"] ? 'error' : '')}>
                            <label>{`Popular Tags`}<span className="text-danger">*</span>{" "}</label>
                            <TagsInput value={popularTags} onChange={this.popularTagsChange} addKeys={[9, 13, 62, 188]} />
                            {errors["popularTags"] && (<small className={style.danger}>{errors["popularTags"]}</small>)}
                        </div>
                    </div>

                </div>
               {auth_service.getPermission(this.props.formType === "CREATE" ? "Pre_Login_Trending_Content_Create" : "Pre_Login_Trending_Content_Update", "Pre_Login_Trending_Content_FullAccess") &&  <ButtonWrap className={`${styles.bottombutt}`}>
                    <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.handleFormSubmit} />
                </ButtonWrap>}
            </div>
        )
    }
}

export default TrendingContentForm;