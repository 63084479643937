import React, { Component } from 'react'
import { CommonHeader } from '../../../utils/commonHeader';
import DataTable from 'react-data-table-component';
import { auth_service } from '../../../auth/auth_service';
import { FilterComponent } from '../../../utils/filter';
import { customStyles } from '../../../utils/tableCustomStyle';
import { tableLicenceMemberList } from './Data/tableLicenceMemberList';
import ExportCSV from '../../../components/Buttons/export_csv_button';
import axios from 'axios';
import history from '../../../utils/history';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { epochTogmt } from '../../../utils/common';

const headers = [
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Country Code", key: "countryISDCode" },
    { label: "Phone No", key: "mobile" },
    { label: "Country", key: "country" },
    { label: "Status", key: "status" },
    { label: "Role", key: "role" },
    { label: "Code", key: "customCode" },
];

class LicenceMemberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenceMemberList: [],
            exportMemberList: [],
            fileName: "",
            filterText: "",
            loading: false,
            totalRows: 0,
            perPage: 10,
        }
    }

    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.licenceMemberList?.filter(
                            (item) =>
                                item.username &&
                                item.username.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.state.licenceMemberList?.length > 0 &&
                    <>
                        {this.getSubHeaderComponent()}
                        {auth_service.getPermission("Promotions_ExportCSV", "Promotions_FullAccess") && <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={this.exportMemberList}><i class="iDownloadFile" /> Export</a>}
                    </>
                }
            </React.Fragment>
        );
    };

    getMemberList = async (page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/license/user/list?licenseId=${this.props.location?.state?.licenseId}&page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    licenceMemberList: response.data.body?.content,
                    totalRows: response.data.body.totalElements,
                    loading: false
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                licenceMemberList: [],
                loading: false
            })
        });
    }

    exportMemberList = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/license/user/list?licenseId=${this.props.location?.state?.licenseId}&page=0&size=200`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let date = new Date();
                let dateMs = date.valueOf();
                const file = "license_member_list-"+epochTogmt(dateMs)+"-"+dateMs+".csv"
                this.setState({
                    exportMemberList: response.data.body?.content,
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    handlePageChange = page => {
        this.getMemberList(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.getMemberList(page-1);
        })
    };

    componentDidMount() {
        if (this.props.location?.state?.licenseId) {
            this.getMemberList(0);
        } else {
            history.goBack();
        }
    }

    render() {
        this.filteredItems = this.state.licenceMemberList && this.state.licenceMemberList.filter(
            (item) =>
                item.username &&
                item.username.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to={`/user/individual/generate-code/${this.props.location?.state?.userId}`} className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large">
                            <DataTable
                                title="Licence Member List"
                                columns={tableLicenceMemberList()}
                                data={this.filteredItems}
                                customStyles={customStyles}
                                actions={this.actionButton()}
                                pagination
                                paginationPerPage={10}
                                pointerOnHover
                                fixedHeader
                                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                                progressPending={this.state.loading}
                                paginationServer
                                paginationTotalRows={this.state.totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>

                <CSVLink headers={headers} data={this.state.exportMemberList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

            </CommonHeader>
        );
    }
}

export default LicenceMemberList;