import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import UploadFiles from "../../../../../components/UploadFiles/upload_files";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../components/Buttons/button";
import FormInput from "../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../utils/commonHeader";
import styles from "../year.module.css";
import { auth_service } from "../../../../../auth/auth_service";
import history from "../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../components/InputFields/form_input.module.css";
import stripHtml from "string-strip-html";
import MagicUrl from "quill-magic-url";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export class LeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: this.props.match.params.year,
      projectCount: "",
      projectPoint: "",
      projectFeedsCount: "",
      projectFeedsPoint: "",
      participantPoint: "",
      videoPoint: "",
      imagesPoint: "",
      blogPoint: "",
      votePoint: "",
      pptPoint: "", 
      pdfPoint: "",
      editData: {},
    };
  }

  //handle change
  handleValueChange = (event) => {
    const re = /^[0-9.\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  onSubmit = () => {
    let postData = {
      id: this.state.editData ? this.state.editData.id : "",
      year: this.state.selectedYear,
      projectCount: this.state.projectCount,
      projectPoint: this.state.projectPoint,
      projectFeedsCount: this.state.projectFeedsCount,
      projectFeedsPoint: this.state.projectFeedsPoint,
      participantPoint: this.state.participantPoint,
      videoPoint: this.state.videoPoint,
      imagesPoint: this.state.imagesPoint,
      blogPoint: this.state.blogPoint,
      votePoint: this.state.votePoint,
      pptPoint: this.state.pptPoint,
      pdfPoint: this.state.pdfPoint
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_userServiceURL}/backend/point/calculation/create`,
      headers: { "Content-Type": "application/json" },
      data: postData,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.message === "Success!"
        ) {
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{"Data is saved successfully"}</p>)
          })
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        // ErorrMsgPopUp.fire({
        //   didOpen: () => {
        //     ErorrMsgPopUp.clickConfirm()
        //   }
        // }).then(() => {
        //   return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
        // })
      });
  };

  fetchLeaderBoardDataByYear = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_userServiceURL}/backend/point/calculation/get-by-year?year=${this.props.match.params.year}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data && response.data.body &&
          response.data.message === "Success!"
        ) {
          let data = response.data.body
          this.setState({
            editData: data,
            projectCount: data.projectCount,
            projectPoint: data.projectPoint,
            projectFeedsCount: data.projectFeedsCount,
            projectFeedsPoint: data.projectFeedsPoint,
            participantPoint: data.participantPoint,
            videoPoint: data.videoPoint,
            imagesPoint: data.imagesPoint,
            blogPoint: data.blogPoint,
            votePoint: data.votePoint,
            pptPoint: data.pptPoint,
            pdfPoint: data.pdfPoint
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.fetchLeaderBoardDataByYear();
  }

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }



  render() {
    const { projectCount, projectPoint, projectFeedsCount, projectFeedsPoint, participantPoint, videoPoint, blogPoint, imagesPoint, votePoint, pptPoint, pdfPoint } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/award-categories`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <h2>Project</h2>
              <div className="form-group row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={projectCount}
                    label={"Count"}
                    onChange={this.handleValueChange}
                    name={"projectCount"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={projectPoint}
                    label={"Point"}
                    onChange={this.handleValueChange}
                    name={"projectPoint"}
                    className="not_mandatory"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={projectFeedsCount}
                    label={"Feeds"}
                    onChange={this.handleValueChange}
                    name={"projectFeedsCount"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={projectFeedsPoint}
                    label={"Point"}
                    onChange={this.handleValueChange}
                    name={"projectFeedsPoint"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={participantPoint}
                    label={"Participant"}
                    onChange={this.handleValueChange}
                    name={"participantPoint"}
                    className="not_mandatory"
                  />
                </div>
              </div>
              <h2>Activity</h2>
              <div className="form-group row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={imagesPoint}
                    label={"Images"}
                    onChange={this.handleValueChange}
                    name={"imagesPoint"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={videoPoint}
                    label={"Video"}
                    onChange={this.handleValueChange}
                    name={"videoPoint"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={blogPoint}
                    label={"Blog"}
                    onChange={this.handleValueChange}
                    name={"blogPoint"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={pptPoint}
                    label={"Ppt"}
                    onChange={this.handleValueChange}
                    name={"pptPoint"}
                    className="not_mandatory"
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={pdfPoint}
                    label={"Pdf"}
                    onChange={this.handleValueChange}
                    name={"pdfPoint"}
                    className="not_mandatory"
                  />
                </div>
              </div>
              <h2>Vote</h2>
              <div className="form-group row">
                <div className="col-md-6">
                  <FormInput
                    group={"large"}
                    value={votePoint}
                    label={"Point"}
                    onChange={this.handleValueChange}
                    name={"votePoint"}
                    className="not_mandatory"
                  />
                </div>
              </div>
              {auth_service.getPermission("WPOND_LeaderBoard_Submit") &&
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Submit"} onClick={this.onSubmit} />
              </ButtonWrap>}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default LeaderBoard;
