import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
import FormInput from "../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../utils/commonHeader";
import styles from "../YearList/year.module.css";
import { auth_service } from "../../../../auth/auth_service";
import history from "../../../../utils/history";
import axios from "axios";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export class EditYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      editdData: {},
      errors: "",
    };
  }
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.year || this.state.year === "") {
      formIsValid = false;
      errors["year"] = "Please enter  year";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  onSubmit = () => {
    if(this.validate()) {
      let postData = {
        id : this.props.match.params.id,
        status: this.state.editdData.status,
        year: this.state.year
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/wpondYear/update`,
        data: postData,
        withCredentials: true,
      })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.message === "Success!"
            ) {
              history.goBack();
            }
          })
          .catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
              didOpen: () => {
                ErorrMsgPopUp.clickConfirm()
              }
            }).then(() => {
              return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
          });
    }
  };
  componentDidMount() {
    this.fetchYearById();
  }
  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }
  //handle change
  handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value.trimStart(),
      });
    }
  };

  fetchYearById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/wpondYear/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
        .then((response) => {
          if (
              response &&
              response.data &&
              response.data.body &&
              response.data.message === "Success!"
          ) {
            this.setState({
              editData: response.data.body,
              year: response.data.body.year,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
  render() {
    const { year} = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/wpond/year" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <FormInput
                group={"large"}
                value={year}
                label={"Year"}
                name={"year"}
                onChange={this.handleChange}
                error={this.state.errors["year"]}
              />
              {/* <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Update"} onClick={this.onSubmit} />
              </ButtonWrap> */}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditYear;
