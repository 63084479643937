import React, { Component } from "react";
import { Link } from "react-router-dom";
import httpService from "../../../../AxiosInterceptors";
import { CommonHeader } from "../../../../utils/commonHeader";
import CreatePointsForm from "../CreatePointsForm";
// import { CommonHeader } from "../../../../../utils/commonHeader";
// import InspiringForm from "../InspiringForm";

httpService.setupInterceptors();
export class EditCreatePoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/credit-points" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                      <CreatePointsForm formType={"UPDATE"} id={this.props.match?.params?.id}/>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default EditCreatePoints;