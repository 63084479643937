import React, { Component } from "react";
import httpService from "../../../../AxiosInterceptors";
import styles from "../../PreLogin/BelongHere/pre-login.module.css"
import styled from "styled-components";
import { CommonHeader } from "../../../../utils/commonHeader";
import Select from "react-select";
import style from "../../../../components/InputFields/form_input.module.css";
import Button from "../../../../components/Buttons/button";
import axios from "axios";
import * as _ from "lodash";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PreLoginMenuList from "../../PreLogin/PreLoginMenuList";
import { auth_service } from "../../../../auth/auth_service";

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = (type) => {
    return ({
      title: `<h5>${type === "CREATE" ? "Create" : "Update"} successfully.</h5>`,
      focusConfirm: false,
      type: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      allowOutsideClick: false,
    })
};

let personaListError = () => {
    return ({
        title: `<p>You can select maximum 10 personas.</p>`,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
}

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

httpService.setupInterceptors();

class PersonaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            personaList: [],
            countryList: [],
            personaData: [],
            countryData: [],
            personaCountryData: {}
        };
    }

    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.personaData?.length === 0) {
            formIsValid = false;
            errors["persona"] = "Please select atleast one persona";
        }
        if (this.state.countryData?.length === 0) {
            formIsValid = false;
            errors["country"] = "Please select atleast one country";
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);
        }
        return formIsValid;
    };

    onSubmit = (type) => {
        if (this.validate()) {
            let postData = {
                persona: this.state.personaData && this.state.personaData.map((data) => {return data.value}),
                country: this.state.countryData && this.state.countryData.map((data) => {return data.value})
            }
            if(type === "UPDATE") {
                postData.id = this.state.personaCountryData?.id
            }
            axios({
                method: type === "UPDATE" ? "PUT" : "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/post/login/${type === "UPDATE" ? "persona-update" : "persona-create"}`,
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if(response?.data?.message === "Success!") {
                    const handleConfirm = async () => {
                        const swalval = await MySwal.fire(confirmModalSwal(type));
                        if (swalval && swalval.value) {
                            this.fetchPersonaCountryData();
                        }
                    };
                    handleConfirm();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    fetchPersonaCountryData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/post/login/persona-list`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    personaCountryData: response.data.body?.length > 0 ? response.data.body[0] : {}
                }, () => {
                    this.getPersonaCountryList();
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getPersonaCountryList = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/recommend/postloginpersonaandcountry/`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.status === 200) {
                let result = response?.data?.data?.length > 0 ? response?.data?.data[0] : [];
                let personaList = result && result?.persona?.map((data) => {return {label: data, value: data}});
                let countryList = result && result?.country?.map((data) => {return {label: data, value: data}});
                let countryDataList = this.state.personaCountryData?.persona?.length > 0 && countryList.filter(data => this.state.personaCountryData && this.state.personaCountryData?.country.includes(data.value));
                let personaDataList = this.state.personaCountryData?.country?.length > 0 && personaList.filter(data => this.state.personaCountryData && this.state.personaCountryData?.persona.includes(data.value));
                this.setState({ personaList, countryList, personaData: personaDataList, countryData: countryDataList })
                
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    onChangePersonaCountry = (data, type) => {
        if((type === "personaData" && data?.length <= 10) || type === "countryData") {
            this.setState({
                [type]: data
            })       
        } else {
            const handleConfirm = async () => {
                await MySwal.fire(personaListError());
            };
            handleConfirm();
        }
    }

    componentDidMount() {
        this.fetchPersonaCountryData();
    }

    render() {
        const { errors, personaList, countryList, personaCountryData, personaData, countryData } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large menu-list persona-form" >
                            <PreLoginMenuList type={"PERSONA"} loginType={"POST_LOGIN"} />
                        </div>
                        <div className={`${styles.forumcreatwrap}`}>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <label className="d-flex align-items-center">Persona <span className="text-danger">*</span></label>
                                    <Select
                                        options={personaList}
                                        onChange={(e) => this.onChangePersonaCountry(e, "personaData")}
                                        placeholder={"Select Persona"}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={personaData.length > 0 ? personaData : ""}
                                        styles={{
                                            option: (
                                                styles,
                                                { data, isDisabled, isFocused, isSelected }
                                            ) => {
                                                const color = "#cfe7c7";
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? null
                                                        : isSelected
                                                            ? "#cfe7c7"
                                                            : isFocused
                                                                ? "#cfe7c7"
                                                                : null,
                                                    color: isDisabled
                                                        ? "#ccc"
                                                        : isSelected
                                                            ? color > 2
                                                                ? "#00374d"
                                                                : "#00374d"
                                                            : data.color,
                                                    cursor: isDisabled ? "not-allowed" : "default",

                                                    ":active": {
                                                        ...styles[":active"],
                                                        backgroundColor:
                                                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                    {errors["persona"] && (
                                        <small className={style.danger}>
                                            {errors["persona"]}
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <label className="d-flex align-items-center">Country <span className="text-danger">*</span></label>
                                    <Select
                                        options={countryList}
                                        onChange={(e) => this.onChangePersonaCountry(e, "countryData")}
                                        placeholder={"Select Country"}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={countryData?.length > 0 ? countryData : ""}
                                        styles={{
                                            option: (
                                                styles,
                                                { data, isDisabled, isFocused, isSelected }
                                            ) => {
                                                const color = "#cfe7c7";
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? null
                                                        : isSelected
                                                            ? "#cfe7c7"
                                                            : isFocused
                                                                ? "#cfe7c7"
                                                                : null,
                                                    color: isDisabled
                                                        ? "#ccc"
                                                        : isSelected
                                                            ? color > 2
                                                                ? "#00374d"
                                                                : "#00374d"
                                                            : data.color,
                                                    cursor: isDisabled ? "not-allowed" : "default",

                                                    ":active": {
                                                        ...styles[":active"],
                                                        backgroundColor:
                                                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                    },
                                                };
                                            },
                                        }}
                                    />
                                    {errors["country"] && (
                                        <small className={style.danger}>
                                            {errors["country"]}
                                        </small>
                                    )}
                                </div>
                            </div>

                            {auth_service.getPermission("Post_Login_Persona_Update") && 
                                <ButtonWrap className={`${styles.bottombutt}`}>
                                    <Button children={!_.isEmpty(personaCountryData) ? "Update" : "Create"} onClick={() => this.onSubmit(!_.isEmpty(personaCountryData) ? "UPDATE" : "CREATE")} />
                                </ButtonWrap>
                            }
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default PersonaForm;