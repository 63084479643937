import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import alertPopUp from 'sweetalert2'

const ErorrMsgPopUp = withReactContent(alertPopUp)

const statusChangeSwal = (status) => {
  return ({
      title: `<h5>Are you sure you want to ${status.toLowerCase()} credit status?</h5>`,
      focusConfirm: false,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false,
  })
};

const CreditStatusDrop = ({ children, id, status, creditStatus, ...props }) => {

  const statusConfirmPopUp = (id, status) => {
    const statusConfirm = async () => {
        const swalval = await ErorrMsgPopUp.fire(statusChangeSwal(status));
        if (swalval && swalval.value === true) {
          statusChange(id, status);
        }
      };
      statusConfirm();
}


  const statusChange = (id, status) => {

    const formData = new FormData();
    let postData = {
      status: status,
      id: id,
    };
    formData.append("data", JSON.stringify(postData));

    axios({
      method: "PUT",
      url: `${process.env.REACT_APP_userServiceURL}/user/credit/update/status`,
      data: postData,
      withCredentials: true,
    })
      .then((response) => {
        props.action();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        ErorrMsgPopUp.fire({
          didOpen: () => {
            ErorrMsgPopUp.clickConfirm()
          }
        }).then(() => {
          return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
        })
      });
  };

  return (
    <div className="dgdropdown">
      <button type="button" className={"btn dropdown-toggle" + ((creditStatus === "EXPIRED" || creditStatus === "USED") ? " disabled" : "")} data-toggle="dropdown">{children}</button>
      <div className="dropdown-menu fadeIn animated">
        <button className={"disabled dropdown-item"}>Draft</button>
        <button className={children === "Enable" ? "dropdown-item" : "disabled dropdown-item"} onClick={(e) => { statusConfirmPopUp(id, 'ENABLE') }}>Enable</button>
        <button className={children === "Disable" ? "dropdown-item" : "disabled dropdown-item"} onClick={(e) => { statusConfirmPopUp(id, 'DISABLE') }}> Disable</button>
      </div>

    </div>
  );
};

export default CreditStatusDrop;
