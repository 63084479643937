import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../../../../../auth/auth_service";
import { tableNominationConstants } from "./Data/tableNominationConstants";
import DataTable from "react-data-table-component";
import "../../../year.module.css";
import { customStyles } from "../../../../../../../utils/tableCustomStyle";
import { FilterComponent } from "../../../../../../../utils/filter";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../../../../utils/commonHeader";
import YearMenuList from "../../../../../../../components/YearMenuList/year_menu_list";
import ExportCSV from "../../../../../../../components/Buttons/export_csv_button";
import { CSVLink, CSVDownload } from "react-csv";
import { epochTogmt } from "../../../../../../../utils/common";

const headers = [
  { label: "Award category", key: "awardCategorySlug" },
  { label: "Name", key: "name" },
  { label: "Project", key: "project" },
  { label: "Points", key: "points" },
  { label: "Project Points", key: "projectPoints" },
  { label: "Activity Points", key: "activityPoints" },
  { label: "Votes", key: "voteCount" },
  { label: "Nominated By", key: "nominatedBy" },
  { label: "Nomination Status", key: "nominationStatus" },
  { label: "Status", key: "status" },
];
export class NominationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      nominationList: [],
      exportNominationList: [],
      selectedYear: this.props.match.params.year,
      awardCategorySlug: this.props.match.params.slug,
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
      loading: false,
      totalRows: 0,
      perPage: 10,
      exportButtonDisable: false
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.nominationList.filter(
              (item) =>
                item.name &&
                item.name
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all years list
  fetchAllNominationsList = (page, size) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/getList?year=${this.props.match.params.year}&awardCategorySlug=${this.props.match.params.slug}&page=${page}&size=${size}`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            nominationList: response.data.body && response.data.body.content,
            totalRows: response.data.body?.totalElements,
            perPage: size,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          nominationList: [],
          loading: false
        });
      });
  };

  exportNomineeData = () => {
    this.setState({
      exportButtonDisable: true
    })
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/getList?year=${this.props.match.params.year}&awardCategorySlug=${this.props.match.params.slug}&page=0&size=200`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          let exportNominationList = [];
          let data = response.data.body && response.data.body.content;
          data && data.map((item, index) => {
            exportNominationList.push({"awardCategorySlug": item.awardCategorySlug, "name": item.name,"project" : item.project,"points" : item.point && item.point.points, "projectPoints": item.point?.project, "activityPoints": item.point?.activity, "voteCount": item.voteCount, "nominatedBy": item.nominatedBy, "nominationStatus": item.nominationStatus, "status": item.status})
          })
          let date = new Date();
          let dateMs = date.valueOf();
          const file = this.state.awardCategorySlug+"_nominations_"+epochTogmt(dateMs)+"-"+dateMs+".csv"
          this.setState({
            exportNominationList: exportNominationList,
            fileName: file,
            exportButtonDisable: false
          }, () => {
            this.csvLink.link.click();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          exportNominationList: [],
          exportButtonDisable: false
        })
      });
  }

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        <YearMenuList type="awardNominations"  selectedYear={this.state.selectedYear} />
        {this.getSubHeaderComponent()}
        {this.state.nominationList && this.state.nominationList.length > 0 && auth_service.getPermission("WPOND_ACN_ExportCsv", "WPOND_ACN_FullAccess") && <button className={"btn addRoleGlobal"} onClick={() => this.exportNomineeData()} disabled={this.state.exportButtonDisable}><i class="iDownloadFile" /> Export</button>}
      </React.Fragment>
    );
  };

  handlePageChange = page => {
    this.fetchAllNominationsList(page-1, this.state.perPage);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.fetchAllNominationsList(page-1, newPerPage);
  };

  componentDidMount() {
    this.fetchAllNominationsList(0, this.state.perPage);
  }

  render() {
    this.filteredItems = this.state.nominationList.filter(
      (item) =>
        item.name &&
        item.name
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/award-categories`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large menu-list">
              <DataTable
                columns={tableNominationConstants(
                  this.fetchAllNominationsList
                )}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={10}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                progressPending={this.state.loading}
                paginationServer
                paginationTotalRows={this.state.totalRows}
                onChangeRowsPerPage={this.handlePerRowsChange}
                onChangePage={this.handlePageChange}
              />
            </div>
          </div>
        </div>

        <CSVLink headers={headers} data={this.state.exportNominationList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

        {/* <Link
          to={`/wpond/${this.state.selectedYear}/award-categories/${this.state.awardCategorySlug}/nomination/create`}
          className={`btn ${styles.createforumbtn}`}
        >
          <i className="iAddCircle"></i> Create
        </Link> */}
      </CommonHeader>
    );
  }
}

export default NominationList;
