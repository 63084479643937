export const tableConstants = (deleteConfirmModal) => {
  return [
    {
      id: 1,
      name: "Word",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin"} onClick={(e) => {deleteConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
