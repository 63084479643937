import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import TagsInput from "react-tagsinput";
import { auth_service } from "../../../../auth/auth_service";
import FormInput from "../../../../components/InputFields/form_input";
import UploadFiles from "../../../../components/UploadFiles/upload_files";
import { CommonHeader } from "../../../../utils/commonHeader";
import styles from "../blogs.module.css";
import Select from "react-select";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import styled from "styled-components";
import TextArea from "../../../../components/TextArea/text_area";
import Button from "../../../../components/Buttons/button";
import style from "../../../../components/InputFields/form_input.module.css";
import history from "../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import stripHtml from "string-strip-html";
import * as _ from "lodash";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
export class EditBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      coverImage: null,
      editdData: {},
      title: "",
      categories: [],
      selectedCategory: [],
      hashTag: [],
      metaKeyWords: [],
      content: "",
      blogSource: "FROM EDITOR'S DESK",
      metaTitle: "",
      metaDescription: "",
      errors: "",
    };
  }

  // validate update blog
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter blog title";
    }
    if (
      this.state.selectedCategory === null ||
      this.state.selectedCategory === undefined ||
      this.state.selectedCategory.length === 0
    ) {
      formIsValid = false;
      errors["category"] = "Please select a category";
    }
    if (
      this.state.hashTag === null ||
      this.state.hashTag === undefined ||
      this.state.hashTag.length === 0
    ) {
      formIsValid = false;
      errors["hashTag"] = "Please enter a hashtag";
    }
    // if (this.state.blogSource !== "" && this.state.blogSource !== null && this.state.blogSource !== undefined) {
    //   const re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    //   if (!re.test(this.state.blogSource)) {
    //     formIsValid = false;
    //     errors["blogSource"] = "Please enter a valid link";
    //   }
    // }
    if (!this.state.content || this.state.content.trim() === "" || stripHtml(this.state.content) == '') {
      formIsValid = false;
      errors["content"] = "Please enter description";
    }
    if (!this.state.blogSource || this.state.blogSource.trim() === "") {
      formIsValid = false;
      errors["blogSource"] = "Please enter blog source";
    }
    if (!this.state.metaTitle || this.state.metaTitle.trim() === "") {
      formIsValid = false;
      errors["metaTitle"] = "Please enter meta title";
    }
    if (!this.state.metaDescription || this.state.metaDescription.trim() === "" || this.state.metaDescription === "<p><br></p>") {
      formIsValid = false;
      errors["metaDescription"] = "Please enter meta description";
    }
    if (
      this.state.metaKeyWords === null ||
      this.state.metaKeyWords === undefined ||
      this.state.metaKeyWords.length === 0
    ) {
      formIsValid = false;
      errors["metaKeyWords"] = "Please enter a meta keywords";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  //   title change
  titleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  // handle hashtag change
  hashTagChange = (tags) => {
    this.setState({ hashTag: tags });
  };

  // handle meta keywords change
  metaKeyWordsChange = (keywords) => {
    this.setState({ metaKeyWords: keywords });
  };

  // handle content change
  contentChange = (value) => {
    this.setState({ content: value });
  };

  // handle source change
  sourceChange = (event) => {
    this.setState({ blogSource: event.target.value });
  };

  //   category change
  onCategoryChange = (data) => {
    this.setState({
      selectedCategory: data,
    });
  };

  //   meta title change
  metatitleChange = (event) => {
    this.setState({
      metaTitle: event.target.value.trimStart(),
    });
  };

  //   meta description change
  metadescriptionChange = (event) => {
    this.setState({
      metaDescription: event.target.value,
    });
  };
  //   fetch categories
  fetchCategories = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_category/getListByStatus`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            categories:
              response.data.body &&
              response.data.body.map((item) => ({
                value: item.id,
                label: item.name,
              })),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // fetch blog details
  fetchBlogDetailById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_blog/getById?id=${this.props.match.params.id}&userId=${this.state.userData.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.message === "Success!") {
          let categoryData = [];
          response.data.body.categoryList &&
            response.data.body.categoryList.map((data, index) => {
              categoryData.push({ value: data.id, label: data.name });
            });
          this.setState({
            editdData: response.data.body,
            title: response.data.body.title,
            hashTag: response.data.body.hashTags,
            content: response.data.body.content,
            metaTitle: response.data.body.metaTitle,
            metaDescription: response.data.body.metaDescription,
            metaKeyWords: response.data.body.metaTags,
            coverImage: response.data.body.coverImageUrl,
            selectedCategory: categoryData,
            blogSource: response.data.body.source
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // submit edited blog
  onSubmit = () => {
    if(this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let categoryList = [];
      this.state.selectedCategory &&
        this.state.selectedCategory.map((data) => {
          categoryList.push(data.value);
        });
      let postData = {
        createdById: this.state.userData.id,
        createdByName: this.state.userData.firstName,
        id: this.state.editdData.id,
        title: this.state.title.trim(),
        content: this.state.content,
        hashTags: this.state.hashTag,
        metaTags: this.state.metaKeyWords,
        metaTitle: this.state.metaTitle,
        metaDescription: this.state.metaDescription,
        source: /* "FROM EDITOR'S DESK" */ this.state.blogSource,
        categoryIdList: categoryList,
        updatedById: this.state.userData.id,
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_blog/update`,
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log("object", error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };

  componentDidMount() {
    this.fetchBlogDetailById();
    this.fetchCategories();
  }
  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }
  
  modules = {
      toolbar: [
          [{ header: "2" },{ header: "3" },{ header: "4" }, { font: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ align: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
      ],
      clipboard: {
          matchVisual: false,
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          handleStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      imageCompress: {
          quality: 0.7, // default
          //maxWidth: 750, // default
          //maxHeight: 500, // default
          imageType: 'image/jpeg' + 'image/png', // default
      },
  };
  render() {
    const {
      title,
      categories,
      hashTag,
      content,
      blogSource,
      metaTitle,
      metaDescription,
      metaKeyWords,
    } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/blogs" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <UploadFiles
                accept=".jpg,.png,.jpeg"
                label="Cover Image"
                updateImage={this.state.editdData.coverImageUrl}
                updateFilesCb={this.updateUploadedFiles}
                imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
              />
              {this.state.coverImageMessage && 
                <div className="text-center">
                  <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                </div>
              }
              <FormInput
                group={"large"}
                value={title}
                label={"Blog title"}
                onChange={this.titleChange}
                error={this.state.errors["title"]}
              />
              <div className="row">
                <div className={"form-group col-md-6 " + (this.state.errors["category"] ? 'error' : '')}>
                  <label>
                    {`Category`} <span className="text-danger">*</span>
                  </label>
                  <Select
                    isMulti
                    options={categories}
                    onChange={this.onCategoryChange}
                    closeMenuOnSelect={false}
                    value={this.state.selectedCategory}
                    styles={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        const color = "#cfe7c7";
                        return {
                          ...styles,
                          backgroundColor: isDisabled
                            ? null
                            : isSelected
                            ? "#cfe7c7"
                            : isFocused
                            ? "#cfe7c7"
                            : null,
                          color: isDisabled
                            ? "#ccc"
                            : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                          cursor: isDisabled ? "not-allowed" : "default",

                          ":active": {
                            ...styles[":active"],
                            backgroundColor:
                              !isDisabled &&
                              (isSelected ? "#cfe7c7" : "#cfe7c7"),
                          },
                        };
                      },
                    }}
                  />
                  {this.state.errors["category"] && (
                    <small className={style.danger}>
                      {this.state.errors["category"]}
                    </small>
                  )}
                </div>
                <div className={"form-group col-md-6 " + (this.state.errors["hashTag"] ? 'error' : '')}>
                  <label>
                    {`Hashtag`} <span className="text-danger">*</span>
                  </label>
                  <TagsInput
                    value={hashTag}
                    onChange={this.hashTagChange}
                    addKeys={[9, 13, 32, 62, 188]}
                  />
                  {this.state.errors["hashTag"] && (
                    <small className={style.danger}>
                      {this.state.errors["hashTag"]}
                    </small>
                  )}
                </div>
              </div>
              <div className={"form-group " + (this.state.errors["content"] ? 'error' : '')}>
                <label>
                  Content <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={content}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["content"] && (
                  <small className={style.danger}>
                    {this.state.errors["content"]}
                  </small>
                )}
              </div>
              <FormInput
                group={"large"}
                value={blogSource}
                label={"Blog Source"}
                onChange={this.sourceChange}
                error={this.state.errors["blogSource"]}
                //isDisable={true}
              />
              <Line />
              <FormInput
                group={"large"}
                value={metaTitle}
                label={"Meta Title"}
                onChange={this.metatitleChange}
                error={this.state.errors["metaTitle"]}
              />
              <TextArea
                label={"Meta Description"}
                value={metaDescription}
                onChange={this.metadescriptionChange}
                error={this.state.errors["metaDescription"]}
              />
              <div className={"form-group " + (this.state.errors["metaKeyWords"] ? 'error' : '')}>
                <label>
                  {`Meta Keywords `}
                  <span className="text-danger">*</span>{" "}
                </label>
                <TagsInput
                  value={metaKeyWords}
                  onChange={this.metaKeyWordsChange}
                  addKeys={[9, 13, 62, 188]}
                />
                {this.state.errors["metaKeyWords"] && (
                  <small className={style.danger}>
                    {this.state.errors["metaKeyWords"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("Explore_Blogs_Update", "Explore_Blogs_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Update"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditBlog;
