export const data = [
  {
    id: "615197af8398bd4beac7a5ad",
    name: "dsadadsad",
    hashTags: [],
    createTime: 1.632737199581e12,
    status: "PUBLISHED",
    publishedTime: 1.632737234937e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 0.0,
    sortBypass: "YES",
    slug: "dsadadsad",
  },
  {
    id: "614453daee106d44804ea960",
    name: "Infographics",
    hashTags: [],
    createTime: 1.631867866726e12,
    status: "ENABLED",
    publishedTime: 0.0,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 1.0,
    sortBypass: "YES",
    slug: "infographics",
  },
  {
    id: "61444d4fee106d44804ea95c",
    name: "Test 345",
    hashTags: ["test"],
    createTime: 1.631866191484e12,
    status: "PUBLISHED",
    publishedTime: 1.631866376274e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 0.0,
    sortBypass: null,
    slug: "test-345",
  },
  {
    id: "60ad259019c2e9476a49d98d",
    name: "new 250521",
    hashTags: ["testing"],
    createTime: 1.621960080911e12,
    status: "PUBLISHED",
    publishedTime: 1.62196032536e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 4.0,
    sortBypass: null,
    slug: "new-250521",
  },
  {
    id: "600ff129b208a56e7cbc02a9",
    name: "Infographic",
    hashTags: ["wenaturalists", "nature", "savetheearth", "environment"],
    createTime: 1.611657513796e12,
    status: "PUBLISHED",
    publishedTime: 1.611658056261e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 4.0,
    sortBypass: null,
    slug: "infographic",
  },
  {
    id: "5fc88ab328a25636cd083b4f",
    name: "Sustainability",
    hashTags: ["Sustainability"],
    createTime: 1.606978227969e12,
    status: "PENDING_APPROVAL",
    publishedTime: 0.0,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 4.0,
    sortBypass: null,
    slug: "sustainability",
  },
  {
    id: "5f898a0f9cb97f6c79f9aefb",
    name: "testing",
    hashTags: [],
    createTime: 1.602849295648e12,
    status: "PUBLISHED",
    publishedTime: 1.602849323622e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 0.0,
    sortBypass: "YES",
    slug: "testing",
  },
  {
    id: "5f696469fcb980087d9ff763",
    name: "Final Testing",
    hashTags: [],
    createTime: 1.600742505764e12,
    status: "PUBLISHED",
    publishedTime: 1.604559139456e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 4.0,
    sortBypass: "YES",
    slug: "final-testing",
  },
  {
    id: "5f67916088f43803b29e1a3b",
    name: "Causes",
    hashTags: [
      "savetheenvironment",
      "climatechange",
      "preventpollution",
      "protection",
    ],
    createTime: 1.600622944622e12,
    status: "PUBLISHED",
    publishedTime: 1.600742910927e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 7.0,
    sortBypass: null,
    slug: "causes",
  },
  {
    id: "5f2122f08cae2b25436188ba",
    name: "Science",
    hashTags: [],
    createTime: 1.596007152063e12,
    status: "PUBLISHED",
    publishedTime: 1.596007235284e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 16.0,
    sortBypass: "NO",
    slug: "science",
  },
  {
    id: "5f20094a24bfad38cb056d35",
    name: "CSR",
    hashTags: ["covid", "charity", "csr"],
    createTime: 1.595935050496e12,
    status: "PUBLISHED",
    publishedTime: 1.595935109833e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 11.0,
    sortBypass: null,
    slug: "csr",
  },
  {
    id: "5f20092d24bfad38cb056d34",
    name: "happiness & Disasters",
    hashTags: [],
    createTime: 1.595935021352e12,
    status: "PUBLISHED",
    publishedTime: 1.595935106197e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 6.0,
    sortBypass: "YES",
    slug: "happiness-disasters",
  },
  {
    id: "5f20090c24bfad38cb056d33",
    name: "Inspirational",
    hashTags: ["motivation", "happiness"],
    createTime: 1.595934988487e12,
    status: "PUBLISHED",
    publishedTime: 1.595935102326e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 6.0,
    sortBypass: null,
    slug: "inspirational",
  },
  {
    id: "5f2008e324bfad38cb056d32",
    name: "Histography",
    hashTags: ["photographylovers", "photographyeveryday"],
    createTime: 1.59593494723e12,
    status: "PUBLISHED",
    publishedTime: 1.595935098833e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 7.0,
    sortBypass: null,
    slug: "histography",
  },
  {
    id: "5f2008cd24bfad38cb056d31",
    name: "Discoveries",
    hashTags: ["discoveries", "travel", "nature"],
    createTime: 1.595934925228e12,
    status: "PUBLISHED",
    publishedTime: 1.595935095131e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 7.0,
    sortBypass: null,
    slug: "discoveries",
  },
  {
    id: "5f2004fd24bfad38cb056d26",
    name: "History",
    hashTags: ["discovery", "history"],
    createTime: 1.595933949453e12,
    status: "PUBLISHED",
    publishedTime: 1.609502551101e12,
    createdById: "5e8eb3c336a09d3234f0d53e",
    createdByName: "punam kanodia",
    noOfBlogs: 7.0,
    sortBypass: null,
    slug: "history",
  },
];
