import { Link } from "react-router-dom";
import { getUserBadgeType } from "../../subscription/CommonSubscriptionFunction";

export const tableUserBadgeConfigListConstants = () => {
    return [
        {
            id: 1,
            name: "Type",
            cell: (row) => {
                return (
                    <Link to={`/user-badge-config/edit/${row.id}`}>
                        <span className="sub">{getUserBadgeType(row?.type)}</span>
                    </Link>
                )
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 2,
            name: "Point",
            selector: (row) => row.point,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "Credit Point Issued",
            selector: (row) => row?.credit?.pointIssued || "-",
            sortable: true,
            reorder: true,
        },
        {
            id: 4,
            name: "Credit Value(INR)",
            selector: (row) => row?.credit?.creditValueInINR || "-",
            sortable: true,
            reorder: true,
        },
        {
            id: 5,
            name: "Credit Value(USD)",
            selector: (row) => row?.credit?.creditValueInUSD || "-",
            sortable: true,
            reorder: true,
        },
        {
            id: 6,
            name: "Module",
            selector: (row) => row?.incentives?.module || "-",
            sortable: true,
            reorder: true,
        },
        {
            id: 7,
            name: "Module Value(INR)",
            selector: (row) => row?.incentives?.moduleValueInINR || "-",
            sortable: true,
            reorder: true,
        },
        {
            id: 8,
            name: "Module Value(USD)",
            selector: (row) => row?.incentives?.moduleValueInUSD || "-",
            sortable: true,
            reorder: true,
        },
    ];
};
