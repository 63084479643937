import { Link } from "react-router-dom";
import { epochTogmt } from "../../../utils/common";
import StatusDetails from "../../../components/StatusDetails/status_details";
import StatusDrop from "../../../components/StatusDropDown/status_drop";

export const tableUserListConstants = (pageType, fetchAllUserList) => {
    return [
        {
            id: 1,
            name: "User",
            cell: (row) => {
                return (
                    <Link to={{ pathname: `/${pageType === "USER_BADGE_LIST" ? "badge-assignment-history" : "badge-user-history"}/${row.userId}`, state: { pageType: pageType } }}>
                        <span className="sub">{row?.username}</span>
                    </Link>
                )
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 2,
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "Persona",
            selector: (row) => row.persona || "-",
            sortable: true,
            reorder: true,
        },
        ...(pageType !== "USER_BADGE_LIST" ?
            [
                {
                    id: 4,
                    name: "Rank",
                    selector: (row) => row.rank,
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 5,
                    name: "Point",
                    selector: (row) => row.point,
                    sortable: true,
                    reorder: true,
                }
            ] :
            [
                {
                    id: 4,
                    name: "Type",
                    selector: (row) => row.type || "-",
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 5,
                    name: "Point Assigned",
                    selector: (row) => row.pointAssigned,
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 6,
                    name: "Point Remaining",
                    selector: (row) => row.pointRemaining,
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 7,
                    name: "Last Point Assign Time",
                    selector: (row) => epochTogmt(row.lastPointAssignmentTime),
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 10,
                    name: "Status",
                    cell: (row) => {
                        return (
                            <StatusDetails status={row.status} />
                        );
                    },
                    sortable: true,
                    reorder: true,
                },
                {
                    id: 11,
                    name: "Action",
                    cell: (row) => {
                        let content = "";
                        let status = "";
                        if (row.status === "ENABLE") {
                            content = "Disable";
                            status = "DISABLE";
                        }
                        if (row.status === "DISABLE") {
                            content = "Enable";
                            status = "ENABLE";
                        }
                        return (
                            <StatusDrop
                                children={content}
                                id={row.userId}
                                status={status}
                                type={"USER_BADGE_LIST"}
                                action={fetchAllUserList}
                                fullPermission={""}
                                approvalPermission={""}
                                publishPermission={""}
                                enablePermission={""}
                                disablePermission={""}
                            />
                        );
                    },
                    sortable: true,
                    reorder: true,
                },
            ]
        ), {
            id: 8,
            name: "Phone No.",
            selector: (row) => row.mobile,
            sortable: true,
            reorder: true,
        },
        {
            id: 9,
            name: "Country",
            selector: (row) => row.country,
            sortable: true,
            reorder: true,
        }
    ];
};
