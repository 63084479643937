import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../../../../../../auth/auth_service";
import DataTable from "react-data-table-component";
import "../../../../year.module.css";
import { customStyles } from "../../../../../../../../utils/tableCustomStyle";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../../../../../utils/commonHeader";
import { tableActivityPointsConstants } from "./Data/tableActivityPointsConstants";
import { FilterComponent } from "../../../../../../../../utils/filter";
import ExportCSV from "../../../../../../../../components/Buttons/export_csv_button";
import { epochTogmt } from "../../../../../../../../utils/common";

const headers = [
  { label: "Activity Type", key: "activityType" },
  { label: "Date", key: "time" },
  { label: "Link", key: "link" },
];

export class ActivityPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      activityList: [],
      exportActivityList: [],
      filterText: "",
      filterDisplay: false,
      selectedYear: this.props.match.params.year,
      awardCategorySlug: this.props.match.params.slug,
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.activityList.filter(
              (item) =>
                item.activityType &&
                item.activityType
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all years list
  fetchAllActivityList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/pointcalculation/get/activity/point?userId=${this.props.match.params.nomineeId}&year=${this.state.selectedYear}&slug=${this.state.awardCategorySlug}`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          let exportActivityList = [];
          response.data.body && response.data.body.map((item, index) => {
            exportActivityList.push({ "activityType": item.activityType, "time": epochTogmt(item.time), "link": item.link })
          })
          this.setState({
            activityList: response.data.body,
            exportActivityList: exportActivityList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.state.activityList?.length > 0 &&
          <>
            {this.getSubHeaderComponent()}
            {/* auth_service.getPermission("WPOND_ACN_ExportCsv", "WPOND_ACN_FullAccess") &&  */<ExportCSV headers={headers} data={this.state.exportActivityList} filename={this.state.awardCategorySlug + "_nominations_activity"} />}
          </>
        }
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchAllActivityList();
  }

  render() {
    this.filteredItems = this.state.activityList.filter(
      (item) =>
        item.activityType &&
        item.activityType
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/award-categories/${this.state.awardCategorySlug}/nominations`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Activity Points"
                columns={tableActivityPointsConstants()}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default ActivityPoints;
