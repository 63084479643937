import axios from "axios";
import React, { Component } from "react";
import styled from "styled-components";
import styles from "./course.module.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../components/Buttons/button";
import FormInput from "../../components/InputFields/form_input";
import { auth_service } from "../../auth/auth_service";
import history from "../../utils/history";
import { v4 as uuidv4 } from 'uuid';
import TextArea from "../../components/TextArea/text_area";
import DatePicker from "react-datepicker";
import style from "../../components/InputFields/form_input.module.css";

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const statusOptions = [
    { value: "ENABLE", label: "Enable" },
    { value: "DISABLE", label: "Disable" },
];

const colorOptions = [
    { value: "GREEN", label: "Green" },
    { value: "GRAY", label: "Gray" },
];

export class CreateCourseForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: auth_service.getUserData()?.id,
            formData: { title: '', slug: "", sheetId: "", notify: false, description: "", endTime: new Date(), notifyDescription: "" },
            categoryList: [{ title: "", id: uuidv4() }],
            courseFeesList: [{ description: "", categoryData: [{ title: "", colorAppearance: { value: "GREEN", label: "Green" } }], amount: "", status: { value: "ENABLE", label: "Enable" }, id: uuidv4() }],
            errors: {},
            initialValue: {},
            editData: {}
        };
    }

    addSection = () => {
        let courseFeesDataList = this.state.courseFeesList;
        let categoryDataList = this.state?.categoryList?.map((category) => ({ title: category?.title, colorAppearance: { value: "GREEN", label: "Green" } }))
        courseFeesDataList.push({ description: "", categoryData: categoryDataList, amount: "", status: { value: "ENABLE", label: "Enable" }, id: uuidv4() })
        this.setState({
            courseFeesList: courseFeesDataList
        })
    };

    deleteSection = (id, fieldName) => {
        let dataList = fieldName === "courseFeesList" ? this.state.courseFeesList : this.state.categoryList;
        let index = dataList.findIndex((data) => data.id === id);
        if (index > -1) {
            dataList.splice(index, 1);
            if (fieldName !== "courseFeesList") {
                this.removeCategoryData(index);
            }
        }
        this.setState({
            [fieldName]: dataList
        })
    };

    removeCategoryData = (index) => {
        let updatedCourseFeesList = [];
        this.state?.courseFeesList?.map((course) => {
            course.categoryData.splice(index, 1);
            updatedCourseFeesList.push(course);
        })
        this.setState({
            courseFeesList: updatedCourseFeesList
        })
    }

    addCategory = () => {
        let categoryDataList = this.state.categoryList;
        categoryDataList.push({ title: "", id: uuidv4() });
        let updatedCourseFeesList = [];
        this.state?.courseFeesList?.map((course) => {
            course.categoryData.push({ title: "", colorAppearance: { value: "GREEN", label: "Green" } });
            updatedCourseFeesList.push(course);
        })
        this.setState({
            categoryList: categoryDataList,
            courseFeesList: updatedCourseFeesList
        })
    };

    handleChangeDetails = (event, id, fieldName, type) => {
        let dataList = type === "CATEGORY" ? this.state.categoryList : this.state.courseFeesList;
        const newState = dataList?.map(obj => {
            if (obj.id === id) {
                return {
                    ...obj,
                    [fieldName]: (fieldName === "colorAppearance" || fieldName === "status") ? event : fieldName === "description" || fieldName === "title" ? event.target.value?.trimStart()?.replace(/\s+/g, ' ') : event.target.value?.trimStart()?.replace(/[^\d\.]*/g, '')
                };
            }
            return obj;
        });
        this.setState({
            [type === "CATEGORY" ? "categoryList" : "courseFeesList"]: newState
        })
        if (type === "CATEGORY") {
            this.updateCategoryTitleData(event.target.value?.trimStart()?.replace(/\s+/g, ' '), id);
        }
    };

    handleChangeColorAppearence = (value, id, index) => {
        let courseFeesDataList = this.state.courseFeesList;
        const newState = courseFeesDataList?.map(obj => {
            if (obj.id === id) {
                obj.categoryData[index].colorAppearance = value
                return {
                    ...obj
                };
            }
            return obj;
        });
        this.setState({
            courseFeesList: newState
        })
    };

    updateCategoryTitleData = (title, id) => {
        let index = this.state?.categoryList?.findIndex((category) => category?.id === id);
        let updatedCourseFeesList = [];
        if (index > -1) {
            this.state?.courseFeesList?.map((course) => {
                course.categoryData[index].title = title;
                updatedCourseFeesList.push(course);
            })
        }
        this.setState({
            courseFeesList: updatedCourseFeesList
        })
    }

    handleFormInput = (event, fieldName) => {
        if(fieldName === "endTime") {
            this.setState(
                (prevState) => ({ formData: { ...prevState.formData, [fieldName]: new Date(event) } })
            );
        } else {
            this.setState(
                (prevState) => ({ formData: { ...prevState.formData, [fieldName]: fieldName === "notify" ? !this.state?.formData?.notify : fieldName === "title" || fieldName === "description" || fieldName === "notifyDescription" ? event.target?.value?.trimStart() : event.target?.value?.trimStart()?.replace(/\s/g, '') } })
            );
        }
    }

    fetchCourseData = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/course/get?id=${this.props?.id}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                let courseFeesDataList = [];
                let categoryDataList = [];
                result?.fees?.map((data) => {
                    courseFeesDataList.push({ description: data?.description, categoryData: data?.colorAppearance?.map((category, index) => ({ title: result?.categoryData[index], colorAppearance: colorOptions?.find((item) => item?.value === category) })), amount: data?.amount, status: statusOptions?.find((item) => item?.value === data?.status), id: uuidv4() })
                })
                result?.categoryData?.map((data) => {
                    categoryDataList.push({ title: data, id: uuidv4() })
                })
                this.setState({
                    formData: { title: result?.title, slug: result?.slug, sheetId: result?.sheetId, description: result?.description, notify: result?.notify, notifyDescription: result?.notifier?.description, endTime: result?.notifier?.endTime > 0 ? new Date(result?.notifier?.endTime) : new Date() },
                    courseFeesList: courseFeesDataList,
                    categoryList: categoryDataList
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.formData?.title) {
            formIsValid = false;
            errors["title"] = "Please enter title";
        }
        if (!this.state.formData?.description) {
            formIsValid = false;
            errors["description"] = "Please enter description";
        }
        if (!this.state.formData?.slug) {
            formIsValid = false;
            errors["slug"] = "Please enter slug";
        }
        if (this.state?.formData?.notify && !this.state.formData?.notifyDescription) {
            formIsValid = false;
            errors["notifyDescription"] = "Please enter description";
        }
        // if (!this.state.formData?.sheetId) {
        //     formIsValid = false;
        //     errors["sheetId"] = "Please enter sheet Id";
        // }
        this.state?.categoryList?.forEach((data, index) => {
            const newCategoryArray = this.state?.categoryList;
            if (!data.title) {
                newCategoryArray[index].titleError = "Please enter title";
                formIsValid = false;
            }
            this.setState({
                categoryList: newCategoryArray
            })
            setTimeout(() => {
                newCategoryArray[index].titleError = null;
                this.setState({
                    categoryList: newCategoryArray
                })
            }, 2000);
        });
        this.state?.courseFeesList?.forEach((data, index) => {
            const newArray = this.state?.courseFeesList;
            if (!data.description) {
                newArray[index].descriptionError = "Please enter description";
                formIsValid = false;
            }
            if (!data.amount) {
                newArray[index].amountError = "Please enter amount";
                formIsValid = false;
            }
            else if (parseInt(data.amount) === 0) {
                newArray[index].amountError = "Amount should be greater than 0";
                formIsValid = false;
            }
            if (!data?.status?.value) {
                newArray[index].statusError = "Please select status";
                formIsValid = false;
            }
            this.setState({
                courseFeesList: newArray
            })
            setTimeout(() => {
                newArray[index].descriptionError = null;
                newArray[index].amountError = null;
                newArray[index].positionError = null;
                newArray[index].colorAppearanceError = null;
                newArray[index].statusError = null;
                this.setState({
                    courseFeesList: newArray
                })
            }, 2000);
        });

        this.setState({
            errors: errors,
        });

        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                });
            }, 4000);
        }

        return formIsValid;
    }

    handleOnSubmit = () => {
        if (this.validateForm()) {
            let postData = {
                title: this.state?.formData?.title,
                description: this.state?.formData?.description,
                slug: this.state?.formData?.slug,
                sheetId: this.state?.formData?.sheetId,
                categoryData: this.state?.categoryList?.map((data) => data?.title),
                fees: this.state?.courseFeesList?.map((data) => ({ description: data?.description, amount: data?.amount, colorAppearance: data?.categoryData?.map((category) => category?.colorAppearance?.value), status: data?.status?.value })),
                notify: this.state?.formData?.notify
            }
            if(this.state?.formData?.notify) {
                postData.notifier = { description: this.state?.formData?.notifyDescription, endTime: this.state?.formData?.endTime?.getTime() }
            }
            if (this.props.formType === "UPDATE") {
                postData.id = this.props?.id
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/course/${this.props.formType === "UPDATE" ? "update" : "create"}`,
                data: postData,
                withCredentials: true,
            }).then((response) => {
                console.log('response:', response)
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    componentDidMount() {
        if (this.props?.formType === "UPDATE") {
            this.fetchCourseData();
        }
    };

    render() {
        const { formData, errors, courseFeesList, categoryList } = this.state;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className='row'>
                    <div className="col-md-12">
                        <FormInput
                            value={formData?.title}
                            label={"Title"}
                            name={"title"}
                            onChange={(e) => this.handleFormInput(e, "title")}
                            error={errors["title"]}
                        />
                    </div>
                    <div className="col-md-12">
                        <TextArea
                            value={formData?.description}
                            label={"Description"}
                            name={"description"}
                            onChange={(e) => this.handleFormInput(e, "description")}
                            error={errors["description"]}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            value={formData.slug}
                            label={"Slug"}
                            name={"slug"}
                            onChange={(e) => this.handleFormInput(e, "slug")}
                            error={errors["slug"]}
                            isDisable={this.props?.formType === "UPDATE"}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            value={formData?.sheetId}
                            label={"Sheet Id"}
                            name={"sheetId"}
                            className={"not-required"}
                            onChange={(e) => this.handleFormInput(e, "sheetId")}
                            required={true}
                            error={errors["sheetId"]}
                        />
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-md-12">
                        <label class="switch">
                            Notify
                            <input
                                type="checkbox"
                                ref="notify"
                                name="notify"
                                onClick={(e) => this.handleFormInput(e, "notify")}
                                checked={formData?.notify}
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                {formData?.notify && 
                    <div className="row">
                        <div className="col-md-8">
                            <TextArea
                                value={formData?.notifyDescription}
                                label={"Description"}
                                name={"notifyDescription"}
                                onChange={(e) => this.handleFormInput(e, "notifyDescription")}
                                error={errors["notifyDescription"]}
                            />
                        </div>
                        <div className="col-md-4">
                            <label for="">End Date <span className="text-danger">*</span></label>
                            <div className="d-flex date-time">
                                <DatePicker
                                    className={"form-control custom-input-date"}
                                    dateFormat={"dd-MMM-yyyy"}
                                    minDate={new Date()}
                                    showTimeSelect
                                    openToDate={new Date()}
                                    dropdownMode="select"
                                    placeholderText={"End Date"}
                                    selected={formData?.endTime}
                                    onChange={(e) => this.handleFormInput(e, "endTime")}
                                />
                            </div>
                            {errors["endTime"] && (<small className={style.danger}>{errors["endTime"]}</small>)}
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <h3>Category</h3>
                    </div>
                </div>
                {categoryList?.map((data, index) => {
                    return (
                        <div className="row">
                            <div className={`col-md-${index > 0 ? "10" : "12"}`}>
                                <FormInput
                                    value={data?.title}
                                    label={"Title"}
                                    name={"title"}
                                    onChange={(e) => this.handleChangeDetails(e, data.id, "title", "CATEGORY")}
                                    required={true}
                                    error={data?.titleError}
                                />
                            </div>
                            {index > 0 &&
                                <div className="col-md-2 text-right" style={{ marginTop: "35px" }}>
                                    <Button onClick={() => this.deleteSection(data.id, "categoryList")} className="iTrashBin" children={"Remove"} style={{ backgroundColor: "red" }} />
                                </div>
                            }
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div>
                    )
                })}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Button color="primary" onClick={this.addCategory} className="d-block">
                            Add
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Course Fees</h3>
                    </div>
                </div>
                {courseFeesList?.map((data, index) => {
                    return (
                        <div className="row">
                            <div className="col-md-12">
                                <FormInput
                                    value={data?.description}
                                    label={"Description"}
                                    name={"description"}
                                    onChange={(e) => this.handleChangeDetails(e, data.id, "description")}
                                    required={true}
                                    error={data?.descriptionError}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    value={data?.amount}
                                    label={"Amount"}
                                    name={"amount"}
                                    onChange={(e) => this.handleChangeDetails(e, data.id, "amount")}
                                    required={true}
                                    error={data?.amountError}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Select Status <span className="text-danger">*</span></label>
                                <Select
                                    options={statusOptions}
                                    value={data?.status}
                                    onChange={(value) => this.handleChangeDetails(value, data.id, "status")}
                                    styles={{
                                        option: (
                                            styles,
                                            { data, isDisabled, isFocused, isSelected }
                                        ) => {
                                            const color = "#cfe7c7";
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? null
                                                    : isSelected
                                                        ? "#cfe7c7"
                                                        : isFocused
                                                            ? "#cfe7c7"
                                                            : null,
                                                color: isDisabled
                                                    ? "#ccc"
                                                    : isSelected
                                                        ? color > 2
                                                            ? "#00374d"
                                                            : "#00374d"
                                                        : data.color,
                                                cursor: isDisabled ? "not-allowed" : "default",

                                                ":active": {
                                                    ...styles[":active"],
                                                    backgroundColor:
                                                        !isDisabled &&
                                                        (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                },
                                            };
                                        },
                                    }}
                                />
                            </div>
                            {data?.categoryData?.map((category, categoryIndex) => {
                                return (
                                    <>
                                        <div className="col-md-6">
                                            <FormInput
                                                value={category?.title}
                                                label={"Title"}
                                                name={"title"}
                                                className={"not-required"}
                                                isDisable={true}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Select Color <span className="text-danger">*</span></label>
                                            <Select
                                                options={colorOptions}
                                                value={category?.colorAppearance}
                                                onChange={(value) => this.handleChangeColorAppearence(value, data.id, categoryIndex)}
                                                styles={{
                                                    option: (
                                                        styles,
                                                        { data, isDisabled, isFocused, isSelected }
                                                    ) => {
                                                        const color = "#cfe7c7";
                                                        return {
                                                            ...styles,
                                                            backgroundColor: isDisabled
                                                                ? null
                                                                : isSelected
                                                                    ? "#cfe7c7"
                                                                    : isFocused
                                                                        ? "#cfe7c7"
                                                                        : null,
                                                            color: isDisabled
                                                                ? "#ccc"
                                                                : isSelected
                                                                    ? color > 2
                                                                        ? "#00374d"
                                                                        : "#00374d"
                                                                    : data.color,
                                                            cursor: isDisabled ? "not-allowed" : "default",

                                                            ":active": {
                                                                ...styles[":active"],
                                                                backgroundColor:
                                                                    !isDisabled &&
                                                                    (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                            },
                                                        };
                                                    },
                                                }}
                                            />
                                        </div>
                                    </>
                                )
                            })}
                            {index > 0 && (
                                <div className="col-md-12 mt-4 text-right">
                                    <Button onClick={() => this.deleteSection(data.id, "courseFeesList")} className="iTrashBin" children={"Remove"} style={{ backgroundColor: "red" }} />
                                </div>
                            )}
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div>
                    )
                })}
                <div className="row">
                    <div className="col-md-12">
                        <Button color="primary" onClick={this.addSection} className="d-block">
                            Add
                        </Button>
                    </div>
                </div>

                <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`} style={{ marginTop: '80px' }}>
                    <Button children={"Submit"} onClick={this.handleOnSubmit} />
                </ButtonWrap>
            </div>
        );
    }
}
export default CreateCourseForm;