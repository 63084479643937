import { Link } from "react-router-dom";

const ProjectMenuList = ({ pageType, year, awardCategorySlug, userId, ...props }) => {
    return (
        <div class="pagetopwleft wpondMain">
            <div class="ptwrow" style={{marginTop: '10px'}}>
                <div class="topBtns">
                    <Link className={"btn btn1 " + (pageType === "PROJECT" ? "active" : "")} to={`/wpond/${year}/award-categories/${awardCategorySlug}/nominations/project-list/${userId}`}>Projects Counted</Link>
                    <Link className={"btn btn1 " + (pageType === "ACTIVITY" ? "active" : "")} to={`/wpond/${year}/award-categories/${awardCategorySlug}/nominations/project-activity-list/${userId}`}>Activity Counted</Link>
                    <Link className={"btn btn1 " + (pageType === "PARTICIPANTS" ? "active" : "")} to={`/wpond/${year}/award-categories/${awardCategorySlug}/nominations/project-participants-list/${userId}`}>Participants Counted</Link>
                </div>
            </div>
        </div>
    );
};

export default ProjectMenuList;
