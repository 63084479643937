import React from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../../../../components/StatusDetails/status_details";
import stripHtml from "string-strip-html";

export const tableEventConstants = (fetchAllEventsList) => {
  return [
    {
      id: 1,
      name: "Title",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/upcoming-event/edit/${row.id}`}>
            <span className="sub">{row.title}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      grow: 2,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Link zone 1",
      selector: (row) => row.linkOne,
      sortable: true,
      reorder: true,
      width: '230px',
    },
    // {
    //   id: 3,
    //   name: "Link zone 2",
    //   selector: (row) => row.linkTwo,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 4,
      name: "Time zone 1",
      selector: (row) => row.timeZoneOne,
      sortable: true,
      reorder: true,
      width: '230px',
    },
    // {
    //   id: 5,
    //   name: "Time zone 2",
    //   selector: (row) => row.timeZoneTwo,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 6,
      name: "Description",
      selector: (row) => stripHtml(row.description),      
      format: row => `${row.description && stripHtml(row.description).slice(0, 380)}...`,
      wrap: true,
      sortable: true,
      reorder: true,
      grow: 5,
    },
    {
      id: 7,
      name: "Speakers",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/upcoming-event/${row.slug}/speaker`}>
            <span className="sub">{row.speakerCount}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            year={row.year}
            type={"wpondAboutEvent"}
            action={fetchAllEventsList}
            fullPermission={"WPOND_Event_FullAccess"}
            approvalPermission={"WPOND_Event_Approval"}
            publishPermission={"WPOND_Event_Publish"}
            enablePermission={"WPOND_Event_Enable"}
            disablePermission={"WPOND_Event_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  ];
};
