import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../../../../../../auth/auth_service";
import { tableVoterConstants } from "./Data/tableVoterConstants";
import DataTable from "react-data-table-component";
import "../../../../year.module.css";
import { customStyles } from "../../../../../../../../utils/tableCustomStyle";
import ExportCSV from "../../../../../../../../components/Buttons/export_csv_button";
import { downloadCSV } from "../../../../../../../../utils/export_csv";
import { FilterComponent } from "../../../../../../../../utils/filter";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../../../../../utils/commonHeader";
import YearMenuList from "../../../../../../../../components/YearMenuList/year_menu_list";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Name", key: "username" },
  { label: "Persona", key: "persona" },
  { label: "Country", key: "country" },
];

export class VoterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      voterList: [],
      exportVoterList: [],
      awardCategorySlug: this.props.match.params.slug,
      selectedYear: this.props.match.params.year,
      nomineeId: this.props.match.params.nomineeId,
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
      nominationName: this.props.location.state
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.voterList && this.state.voterList.filter(
              (item) =>
                item.username &&
                item.username
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all years list
  fetchAllVotersList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/wpondNominationVote/getVoter?id=${this.props.match.params.nomineeId}&year=${this.props.match.params.year}&slug=${this.props.match.params.slug}&page=0&size=100`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          let exportVoterList = [];
          let data = response.data.body && response.data.body.content;
          data && data.map((item, index) => {
            exportVoterList.push({"username": item.username, "persona": item.persona, "country": item.addressDetail && item.addressDetail.country})
          })
          this.setState({
            voterList: response.data.body && response.data.body.content,
            exportVoterList: exportVoterList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  nominationNameForPdf = (value) => {
    let data = value.replace(/ /g, '_');
    return data;
  }

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        <YearMenuList type="awardNominationsVoters"  selectedYear={this.state.selectedYear} />
        {this.getSubHeaderComponent()}
        {/* <Export onExport={() => downloadCSV(this.state.exportVoterList, "nomination_voter")} /> */}
        {auth_service.getPermission("WPOND_ACNV_ExportCsv") && <ExportCSV headers={headers} data={this.state.exportVoterList} filename={this.nominationNameForPdf(this.state.nominationName)+"_nomination_voter"} />}
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchAllVotersList();
    if(this.state.nominationName && this.state.nominationName !== undefined) {
      localStorage.setItem('nominationName', this.state.nominationName);
    } else {
      this.setState({
        nominationName: localStorage.getItem('nominationName')
      })
    }
  }

  render() {
    this.filteredItems = this.state.voterList && this.state.voterList.filter(
      (item) =>
        item.username &&
        item.username
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/award-categories`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large menu-list">
              <DataTable
                columns={tableVoterConstants(
                  this.fetchAllVotersList
                )}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default VoterList;
