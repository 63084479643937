import axios from "axios";
import React, { Component } from "react";
import { data } from "./Data/mockData";
import { auth_service } from "../../../auth/auth_service";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import { FilterComponent } from "../../../utils/filter";
import { CommonHeader } from "../../../utils/commonHeader";

const options = [
    { value: "name", label: "Filter By Name" },
    { value: "status", label: "Filter By Name" },
];

export class RemittedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            remittedList: [],
            filterText: "",
            filterDisplay: false,
            resetPaginationToggle: false,
        };
    }

    // subheader of a component
    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.remittedList.filter(
                            (item) =>
                                item.id &&
                                item.id.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    // fetch all remitted list
    fetchAllRemittedList = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_userServiceURL + "/payment/get_failed_payment",
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.status === "200 OK" &&
                    response.data.body
                ) {
                    this.setState({
                        remittedList: response.data.body,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // handle filter display
    showFilter = () => {
        this.setState({
            filterDisplay: this.state.filterDisplay ? false : true,
        });
    };

    // action button
    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
                <Export onExport={() => downloadCSV(this.state.remittedList, "remitted")} />
            </React.Fragment>
        );
    };

    componentDidMount() {
        this.fetchAllRemittedList();
    }

    render() {
        this.filteredItems = this.state.remittedList.filter(
            (item) =>
                item.username &&
                item.username.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large">
                            <DataTable
                                title="Remitted"
                                columns={tableConstants(
                                    this.fetchAllRemittedList
                                )}
                                data={this.filteredItems}
                                //data={data}
                                customStyles={customStyles}
                                actions={this.actionButton()}
                                pagination
                                paginationPerPage={20}
                                pointerOnHover
                                fixedHeader
                                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                            />
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default RemittedList;
