import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import { auth_service } from "../../../../auth/auth_service";
import Button from "../../../../components/Buttons/button";
import FormInput from "../../../../components/InputFields/form_input";
import TextArea from "../../../../components/TextArea/text_area";
import { CommonHeader } from "../../../../utils/commonHeader";
import history from "../../../../utils/history";
import styles from "./create_category.module.css";
import style from "../../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const ErorrMsgPopUp = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;
export class CreateCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      name: "",
      hashTag: [],
      metaKeyWords: [],
      metaTitle: "",
      metaDescription: "",
      errors: "",
    };
  }
  
  // handle hashtag change
  hashTagChange = (tags) => {
    this.setState({ hashTag: tags });
  };

  //meta keyword chnage
  metaKeyWordsChange = (tags) => {
    this.setState({
      metaKeyWords: tags
    })
  }

  // name change
  onHandleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // validation error handle
  validate = () => {
    let errors = {};
    let formIsValid = true;
    // title empty validation
    if (!this.state.name || this.state.name.trim() === "") {
      formIsValid = false;
      errors["name"] = "Please enter category name";
    }
    // hashtag empty validation
    if (
      this.state.hashTag === null ||
      this.state.hashTag === undefined ||
      this.state.hashTag.length === 0
    ) {
      formIsValid = false;
      errors["hashtag"] = "Hashtag can not be empty";
    }
    if (!this.state.metaTitle || this.state.metaTitle.trim() === "") {
      formIsValid = false;
      errors["metaTitle"] = "Please enter meta title";
    }
    if (!this.state.metaDescription || this.state.metaDescription.trim() === "") {
      formIsValid = false;
      errors["metaDescription"] = "Please enter meta description";
    }
    if (
      !this.state.metaKeyWords ||
      this.state.metaKeyWords.length === 0
    ) {
      formIsValid = false;
      errors["metaKeyWords"] = "Meta keywords can not be empty";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  //   category create
  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        createdById: this.state.userData.id,
        createdByName: this.state.userData.firstName,
        name: this.state.name.trim(),
        hashTags: this.state.hashTag,
        metaTitle: this.state.metaTitle.trim(),
        metaDescription: this.state.metaDescription,
        metaTags: this.state.metaKeyWords,
        status: "DRAFT"
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_category/create`,
        data: postData,
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/explore-categories" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <FormInput
                value={this.state.name}
                group={"large"}
                label={"Category Name"}
                name={'name'}
                onChange={this.onHandleChange}
                error={this.state.errors["name"]}
              />
              <div className={"form-group " + (this.state.errors["hashtag"] ? 'error' : '')}>
                <label>Hashtag<span className="text-danger"> *</span>{" "}</label>
                <TagsInput
                  value={this.state.hashTag}
                  onChange={this.hashTagChange}
                  addKeys={[9, 13, 32, 62, 188]}
                />
                {this.state.errors["hashtag"] && (
                  <small className={style.danger}>
                    {this.state.errors["hashtag"]}
                  </small>
                )}
              </div>
              <Line />
              <FormInput
                group={"large"}
                value={this.state.metaTitle}
                label={"Meta Title"}
                name={'metaTitle'}
                onChange={this.onHandleChange}
                error={this.state.errors["metaTitle"]}
              />
              <TextArea
                label={"Meta Description"}
                value={this.state.metaDescription}
                name={'metaDescription'}
                onChange={this.onHandleChange}
                error={this.state.errors["metaDescription"]}
              />
              <div className={"form-group " + (this.state.errors["metaKeyWords"] ? 'error' : '')}>
                <label>
                  {`Meta Keywords `}
                  <span className="text-danger">*</span>{" "}
                </label>
                <TagsInput
                  value={this.state.metaKeyWords}
                  onChange={this.metaKeyWordsChange}
                  addKeys={[9, 13, 62, 188]}
                />
                {this.state.errors["metaKeyWords"] && (
                  <small className={style.danger}>
                    {this.state.errors["metaKeyWords"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("Explore_Category_Create", "Explore_category_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Submit"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateCategory;
