import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableFlagPostConstants } from "../Data/flagPostTableConstants";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FilterComponent } from "../../../utils/filter";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import styles from "../post/post.module.css";
import Select from "react-select";

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
    title: '<h5>Are you sure you want to Delete?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
};

let confirmModalSwalForUnFlag = {
    title: '<h5>Are you sure you want to Unflag?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
};

let confirmModalSwalForIgnore = {
    title: '<h5>Are you sure you want to Ignore?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
};

const filterOptions = [
    { value: "POST", label: "Post" },
    { value: "FORUM", label: "Forum" },
    { value: "POLL", label: "Poll" }

];
export class FlagPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postList: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            filterText: "",
            filterData: { value: "POST", label: "Post" }
        };
    }


    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.postList.filter(
                            (item) =>
                                item.postType &&
                                item.postType.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    searchType={'Search by post type'}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    fetchFilterIndividualList = (date, status, page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/user/individual/search-by-date-status/?page=${page}&size=${this.state.perPage}&date=${date}&status=${status}&type=${this.state.filterData ? this.state.filterData : null}&searchParam=${this.state.filterText ? this.state.filterText : null}`,
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        postList: response.data.body && response.data.body.content,
                        totalRows: response.data.body.totalElements,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    postList: [],
                    loading: false
                });
            });
    };



    fetchIndividualUsersDetails = async page => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/admin/post/flagged/list?type=${this.state.filterData?.value}&page=${page}&size=${this.state.perPage}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                this.setState({
                    postList: response.data.body && response.data.body.content,
                    totalRows: response.data.body.totalElements,
                    loading: false
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                postList: [],
                loading: false
            })
        });
    };



    handlePageChange = page => {
        this.fetchIndividualUsersDetails(page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/admin/post/flagged/list?type=${this.state.filterData?.value}&page=${page - 1}&size=${newPerPage}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                this.setState({
                    postList: response.data.body && response.data.body.content,
                    totalRows: response.data.body.totalElements,
                    loading: false
                })
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                postList: [],
                loading: false
            })
        });
    };

    updatePostListData = (id) => {
        let postItemList = this.state.postList;
        const index = postItemList && postItemList.findIndex(data => data.id === id);
        if (index > -1) {
            postItemList.splice(index, 1);
        }
        this.setState({
            postList: postItemList
        });
    }

    handleConfirmModal = (id, type) => {
        const handleConfirm = async () => {
            const swalval = await MySwal.fire(type === "DELETE" ? confirmModalSwal : type === "UNFLAG" ? confirmModalSwalForUnFlag : confirmModalSwalForIgnore);
            if (swalval && swalval.value === true) {
                let data = {
                    id: id
                }
                axios({
                    method: "POST",
                    url: type === "DELETE" ? `${process.env.REACT_APP_userServiceURL}/backend/admin/post/delete?id=${id}&type=${this.state.filterData?.value}` : type === "UNFLAG" ? `${process.env.REACT_APP_userServiceURL}/backend/admin/post/unflag?id=${id}&type=${this.state.filterData?.value}` : `${process.env.REACT_APP_userServiceURL}/backend/reportabuse/ignore?type=${this.state.filterData?.value}`,
                    data: data,
                    withCredentials: true,
                })
                    .then((response) => {
                        if (response && response.data && response.data.message === "Success!") {
                            this.updatePostListData(id);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };

        handleConfirm();
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
            </React.Fragment>
        );
    };

    handleFilterChange = (value) => {
        this.setState({
            filterData: value
        }, () => {
            this.fetchIndividualUsersDetails(0);
        })
    }

    componentDidMount() {
        this.fetchIndividualUsersDetails(0);
    } 

    render() {
        this.filteredItems = this.state.postList.filter(
            (item) =>
                item.postType &&
                item.postType.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={styles.pagetopwrap}>
                            <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                                <div className={`${styles.ptwrow}`}>
                                    <div className="col-md-3">
                                        <Select
                                            options={filterOptions}
                                            placeholder={"Filter by status"}
                                            value={this.state.filterData}
                                            onChange={this.handleFilterChange}
                                            styles={{
                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled &&
                                                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            title="Flag Post List"
                            columns={tableFlagPostConstants(this.handleConfirmModal)}
                            data={this.filteredItems}
                            customStyles={customStyles}
                            actions={this.actionButton()}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            progressPending={this.state.loading}
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            onChangeRowsPerPage={this.handlePerRowsChange}
                            onChangePage={this.handlePageChange}
                        />
                    </div>


                    {/*<CSVLink headers={headers} data={this.state.exportUserList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>*/}

                    {/* Export CSV Modal */}

                    {/*<div class="modal fade dgpopup" id="exportCsvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Select Date Rage</h5>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button
                                                onClick={this.displayRangeSelect}
                                                type={"range"}
                                                children={`${utcTodate(
                                                    this.state.date.startDate
                                                )} to ${utcTodate(this.state.date.endDate)}`}
                                            />
                                            <Button
                                                onClick={(e) => {
                                                    this.closeDateRange();
                                                }}
                                                type={"anchor"}
                                                children={`CLose`}
                                            />
                                            {this.state.displayRange &&
                                                <DateRange
                                                    ranges={[date]}
                                                    onChange={this.handleSelect}
                                                    maxDate={new Date()}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" onClick={this.exportCsvModalClose} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary" onClick={() => this.exportButton(this.state.date.startDate, this.state.date.endDate)}><i class="iDownloadFile" /> Export</button>
                                </div>
                            </div>
                        </div>
                    </div>*/}

                </div>
            </CommonHeader>
        );
    }
}

export default FlagPost;
