import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableConstants } from "./Data/tableConstants";
import Button from "../../../components/Buttons/button";
import styles from "./exclude_user_flag_post.module.css";
import { FilterComponent } from "../../../utils/filter";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FormInput from "../../../components/InputFields/form_input";
import ExportCSV from "../../../components/Buttons/export_csv_button";
import { auth_service } from "../../../auth/auth_service";


const ErorrMsgPopUp = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "User Email", key: "email" }
];

export class ExcludeUserFlagPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      flagUserList: [],
      filterText: "",
      filterTextOne: "",
      filterDisplay: false,
      changeType: 'ALL',
      exportUserFlag: false,
      fileName: "",
      errors: {},
      error: false,
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.flagUserList && this.state.flagUserList.filter(
              (item) =>
                item.email &&
                item.email
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by email'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // validate
 validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.userEmail) {
      formIsValid = false;
      errors["userEmail"] = "Please enter user email";
    }
    if(this.state.userEmail) {
      let email = this.state.userEmail;
      if(!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        formIsValid = false;
        errors["userEmail"] = "Please enter a valid email address";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("AP_FlagPost_ExportCSV") && <ExportCSV headers={headers} data={this.state.flagUserList} filename="exclude_user_flag_post" />}
      </React.Fragment>
    );
  };

  handleChange = (event) => {
      this.setState({
          userEmail: event.target.value
      })
  }

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        email: this.state.userEmail,
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/backend/flag/intimation/create`,
        //headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            this.setState({
                userEmail: ""
            })
            this.fetchExcludeUserFlagPost();
          }
        })
        .catch((error) => {
            console.log(error)
            ErorrMsgPopUp.fire({
                didOpen: () => {
                  ErorrMsgPopUp.clickConfirm()
                }
              }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
              })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          error: false
        });
      }, 5000);
    }
  };


  // fetch all exclude user list
  fetchExcludeUserFlagPost = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/backend/flag/intimation/list`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK"
        ) {
          this.setState({
            flagUserList: response.data.body
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/flag/intimation/delete?id=${id}`,
          //data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              this.fetchExcludeUserFlagPost();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };


  componentDidMount() {
    this.fetchExcludeUserFlagPost();
  }

  render() {
    this.filteredItems = this.state.flagUserList && this.state.flagUserList.filter(
        (item) =>
          item.email &&
          item.email
            .toLowerCase()
            .includes(this.state.filterText.toLowerCase())
    );

    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-6">
                    <FormInput
                        group={"large"}
                        value={this.state.userEmail}
                        label={"User Email"}
                        onChange={this.handleChange}
                        name={"userEmail"}
                        error={this.state.errors["userEmail"]}
                    />
                  </div>
                  {auth_service.getPermission("AP_FlagPost_Add") &&
                  <div className="col-md-3">
                    <Button children={"Add"} onClick={this.onSubmit} />
                  </div>}
                </div>
              </div>
            </div>
            <DataTable
              title="Flag Post Alert"
              columns={tableConstants(this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              highlightOnHover
              pointerOnHover
            />
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default ExcludeUserFlagPost;
