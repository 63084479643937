import React from "react";
import { Link } from "react-router-dom";

// This is the table constant/settings which needed to render table elements
export const tableConstants = (handleEdit) => {
    return [
        {
            id: 1,
            name: "Report name",
            //selector: (row) => row.particular,
            cell: (row) => {
                return (
                  <Link to={"#"}>
                    <span className="sub">{row.particular}</span>
                  </Link>
                );
            },
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "Created on",
            selector: (row) => row.createdOn,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 3,
            name: "Last updated",
            selector: (row) => row.lastUpdated,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 4,
            name: "Period applicable",
            selector: (row) => row.periodApplicable,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 5,
            name: "Created by",
            selector: (row) => row.createdBy,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 6,
            name: "Scheduled days",
            selector: (row) => row.scheduledDays,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 7,
            name: "Schedule time",
            selector: (row) => row.scheduleTime,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 8,
            name: "Delete",
            cell: (row) => {
                return <i className={"iTrashBin"}></i>;
            },
            sortable: true,
            reorder: true,
        },
    ];
};
