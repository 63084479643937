export const data = [
  {
    id: "5ee9f9e65f21fa4f929444bd",
    name: "Promotion",
    group: "User Control",
    createdOn: "1592392166258",
    status: "DISABLE",
  },
  {
    id: "5ee9f9e65f21fa4f929444bd",
    name: "Deletion",
    group: "Management",
    createdOn: "1592392166258",
    status: "DISABLE",
  },
  {
    id: "5ee9f9e65f21fa4f929444bd",
    name: "Editiong",
    group: "Articles",
    createdOn: "1592392166258",
    status: "DISABLE",
  },
  {
    id: "5ee9f9e65f21fa4f929444bd",
    name: "Reporting",
    group: "Mobile App",
    createdOn: "1592392166258",
    status: "DISABLE",
  },
];
