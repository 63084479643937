import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { customStyles } from '../../../utils/tableCustomStyle';
import { tableLicenseHistory } from './Data/tableLicenseHistory';
import axios from 'axios';

class LicenseUserHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            historyData: []
        }
    }

    getLicenseHistoryData = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/license/history/list?userId=${this.props.userId}&page=0&size=1000`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    historyData: response.data.body
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.getLicenseHistoryData();
    }

    render() {
        const { historyData } = this.state;
        return (
            <div class="modal fade dgpopup permissionMod" id="licenceHistoryModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalRoleLabel">History</h5>
                        </div>
                        <div class="modal-body">
                            <div className="tableadminpsearch role">
                                <div className="col2Large dgtable">
                                    <DataTable
                                        //title="Permission"
                                        columns={tableLicenseHistory()}
                                        data={historyData}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={20}
                                        pointerOnHover
                                        fixedHeader
                                        fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={this.props.onCloseHistoryModal} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LicenseUserHistory;
