import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { auth_service } from "../../../../auth/auth_service";
// This is the table constant/settings which needed to render table elements

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export const tableConstantsDevice = (handleDeviceConfirmModal) => {
  return [
    {
      id: 1,
      name: "Device Id",
      selector: (row) => row.deviceId,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Action",
      cell: (row) => {
        return (<button  className={ "btnUpdte" + (row.status ? (auth_service.getPermission("AP_Restricted_User_Unblock", "AP_Restricted_User_FullAccess") ? "" : " disabled") : (auth_service.getPermission("AP_Restricted_User_Block", "AP_Restricted_User_FullAccess") ? "" : " disabled"))} disabled={row.status ? (auth_service.getPermission("AP_Restricted_User_Unblock", "AP_Restricted_User_FullAccess") ? false : true) : (auth_service.getPermission("AP_Restricted_User_Block", "AP_Restricted_User_FullAccess") ? false : true)} onClick={()=>{
            handleDeviceConfirmModal(row.deviceId, !row.status)
        }}>
            {row.status ? 'Unblock' : 'block'}
          </button>);
      },
      sortable: true,
      reorder: true,
    },
  ];
};
