import React, { Component } from 'react'
import { CommonHeader } from '../../../../utils/commonHeader';
import styles from "../../PreLogin/BelongHere/pre-login.module.css";
import { Link } from 'react-router-dom';
import CommonPreloginList from '../../PreLogin/CommonPreloginList';
import { auth_service } from '../../../../auth/auth_service';

class PostInspiringList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <CommonPreloginList type={"INSPIRING_STORY_POST"} loginType={"POST_LOGIN"} />
                    </div>
                </div>
                {auth_service.getPermission("Post_Login_Inspiring_Stories_Create", "Post_Login_Inspiring_Stories_FullAccess") && <Link to={`/post-login/inspiring-story/create`} className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Create
                </Link>}
            </CommonHeader>
        )
    }
}

export default PostInspiringList;