import React, { Component } from "react";
import { auth_service } from "../../../../auth/auth_service";
import FormInput from "../../../../components/InputFields/form_input";
import styles from "../CreateDepartment/create_department.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
import { CommonHeader } from "../../../../utils/commonHeader";
import Select from "react-select";

import httpService from "../../../../AxiosInterceptors";
import history from "../../../../utils/history";
import style from "../../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ErorrMsgPopUp = withReactContent(alertPopUp);
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;
export class CreateDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      departmentName: "",
      roleList: [],
      categories: [],
      departmentId: this.props.match.params.id,
      departmentStatus: "",
      errors: {},
    };
  }

  // validate create user
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.departmentName || this.state.departmentName.trim() === "") {
      formIsValid = false;
      errors["departmentName"] = "Please enter department name";
    }
    // if (
    //   this.state.roleList === null ||
    //   this.state.roleList === undefined ||
    //   this.state.roleList.length === 0
    // ) {
    //   formIsValid = false;
    //   errors["role"] = "Please enter a role";
    // }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //   title change
  titleChange = (event) => {
    this.setState({
      departmentName: event.target.value.trimStart(),
    });
  };

  // handle hashtag change
  roleTagChange = (roles) => {
    this.setState({ roleList: roles });
  };

  // fetch data by department id
  fetchDepartmentById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/adminDepartment/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.status === "200 OK"
        ) {
          this.setState({
            departmentName: response.data.body.name,
            departmentStatus: response.data.body.status,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // fetchRoleList = () => {
  //   axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_userServiceURL}/adminRole/listByStatus?status=undefined&createdBy=${this.state.userData.firstName}&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined`,
  //     withCredentials: true,
  //   })
  //     .then((response) => {
  //       if (
  //         response &&
  //         response.data &&
  //         response.data.body &&
  //         response.data.message === "Success!"
  //       ) {
  //         this.setState({
  //           categories:
  //             response.data.body &&
  //             response.data.body.map((item) => ({
  //               value: item.name,
  //               label: item.name,
  //             })),
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  onUpdateDepartment = () => {
    if (this.validate()) {
      // let roleDataList = [];
      // this.state.roleList &&
      //   this.state.roleList.map((data) => {
      //     roleDataList.push(data.label);
      //   });
      let postData = {
        userId: this.state.userData.id,
        createdBy: this.state.userData.firstName,
        id: this.state.departmentId,
        name: this.state.departmentName.trim(),
        status: this.state.departmentStatus,
      };
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/adminDepartment/edit",
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "202 ACCEPTED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  componentDidMount() {
    this.fetchDepartmentById();
  }

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/admin-panel/department" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <h2>Department Update</h2>
              <div className="row">
                <div className="col-md-6">
                  <FormInput
                    group={"small"}
                    value={this.state.departmentName}
                    label={"Department Name"}
                    onChange={this.titleChange}
                    name={"departmentName"}
                    error={this.state.errors["departmentName"]}
                  />
                </div>
              </div>

              {/* <div className={`form-group`}>
                <label>Role</label>
                <Select
                  isMulti
                  options={this.state.categories}
                  onChange={this.roleTagChange}
                  placeholder={"Start typing"}
                  value={this.state.roleList}
                  closeMenuOnSelect={false}
                  styles={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = "#cfe7c7";
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                            ? "#cfe7c7"
                            : isFocused
                              ? "#cfe7c7"
                              : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                            ? color > 2
                              ? "#00374d"
                              : "#00374d"
                            : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                        },
                      };
                    },
                  }}
                />
                {this.state.errors["role"] && (
                  <small className={style.danger}>
                    {this.state.errors["role"]}
                  </small>
                )}
              </div> */}
              {(auth_service.getPermission("AP_Department_Update", "AP_Department_FullAccess")) && (
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Update"} onClick={this.onUpdateDepartment} />
              </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateDepartment;
