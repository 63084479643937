const StatusDetails = ({ status, ...props }) => {

  return (
    <div>
        {status === "DRAFT" && 
            <span>Draft</span>
        }
        {status === "PENDING_APPROVAL" && 
            <span>Approved</span>
        }
        {status === "PUBLISH" && 
            <span>Published</span>
        }
        {status === "ENABLE" && 
            <span>Enabled</span>
        }
        {status === "DISABLE" && 
            <span>Disabled</span>
        }
        {status === "UPCOMING" && 
            <span>Upcoming</span>
        }
        {status === "COMPLETED" && 
            <span>Completed</span>
        }
        {status === "ACCEPTED" && 
            <span>Accepted</span>
        }
        {status === "INITIAL" && 
            <span>Initial</span>
        }
        {status === "IGNORED" && 
            <span>Ignored</span>
        }
        {status === "ASSIGNED" && 
            <span>Assigned</span>
        }
    </div>
  );
};

export default StatusDetails;
