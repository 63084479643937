export const notificationType = [
    { value: "ALL", label: "All" },
    { value: "POST", label: "Post" },
    { value: "PROJECT", label: "Project" },
    { value: "CIRCLE", label: "Circle" },
    { value: "FORUM", label: "Forum" },
    { value: "POLL", label: "Poll" },
    { value: "NETWORK", label: "Network" },
    { value: "OTHER", label: "Other" },
];

export const sendCritariaType = [
    {value: "PERSONA", name: "Persona"},
    {value: "PROFILE_UPDATE", name: "Profile update"},
    {value: "POST_ACTIVITY", name: "Post activity"},
    {value: "LOCATION", name: "Location"},
    {value: "PROJECT_ACTIVITY", name: "Project activity"},
    {value: "PARTICULARS", name: "Individuals"},
    {value: "LAST_LOGIN_DATE", name: "Last login date"},
    {value: "NOT_LOGGED_IN_AFTER_SIGNUP", name: "Not login after signup"},
]

export const scheduleType = [
    {value: "ONCE", name: "Choose once"},
    {value: "WEEKLY", name: "Once a week"},
    {value: "MONTHLY", name: "Once a month"}
]

export const weekDayArray = [
    {value: "MONDAY", name: "Monday"},
    {value: "TUESDAY", name: "Tuesday"},
    {value: "WEDNESDAY", name: "Wednesday"},
    {value: "THURSDAY", name: "Thursday"},
    {value: "FRIDAY", name: "Friday"},
    {value: "SATURDAY", name: "Saturday"},
    {value: "SUNDAY", name: "Saturday"},
]

export const emailViewerHeader= [
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Phone No", key: "mobile" },
    { label: "Profile Link", key: "profileLink" },
    { label: "Create Time", key: "createTime" },
]

export const statusType = [
    { label: "All", value: "ALL" },
    { label: "Draft", value: "DRAFT" },
    { label: "Approved", value: "PENDING_APPROVAL" },
    { label: "Published", value: "PUBLISH" },
    { label: "Enabled", value: "ENABLE" },
    { label: "Disabled", value: "DISABLE" },
]

export const formatString = (str) => {
    return str && str.replace(/(\B)[^ ]*/g, match => (match.toLowerCase()));
}

export const capitalizeFirstLetter = (text) => {
    return text && text[0].toUpperCase() + text.slice(1).toLowerCase();
};
