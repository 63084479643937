import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import { auth_service } from "../../../../auth/auth_service";
import Button from "../../../../components/Buttons/button";
import FormInput from "../../../../components/InputFields/form_input";
import UploadFiles from "../../../../components/UploadFiles/upload_files";
import { CommonHeader } from "../../../../utils/commonHeader";
import history from "../../../../utils/history";
import styles from "../../winner.module.css";
import style from "../../../../components/InputFields/form_input.module.css";
import * as _ from "lodash";

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const options = [
  { id: "1", value: "Speaker 1", label: "Speaker 1" },
  { id: "2", value: "Speaker 2", label: "Speaker 2" },
  { id: "3", value: "Speaker 3", label: "Speaker 3" },
  { id: "4", value: "Speaker 4", label: "Speaker 4" },
  { id: "5", value: "Speaker 5", label: "Speaker 5" },
  { id: "6", value: "Speaker 6", label: "Speaker 6" },
];

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
export class CreateSpeaker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      title: "",
      content: "",
      selectedSpeaker: {},
      coverImage: null,
      videoLink: "",
      errors: "",
      coverImageMessage: "",
    };
  }
  modules = {
      toolbar: [
          [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ align: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
      ],
      clipboard: {
          matchVisual: false,
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          handleStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      imageCompress: {
          quality: 0.7, // default
          maxWidth: 750, // default
          maxHeight: 500, // default
          imageType: 'image/jpeg' + 'image/png', // default
      },
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  //   title change
  titleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  //   video url change
  videoChange = (event) => {
    this.setState({
      videoLink: event.target.value,
    });
  };

  // handle content change
  contentChange = (value) => {
    this.setState({ content: value });
  };

  //   category change
  onCategoryChange = (data) => {
    this.setState({
      selectedSpeaker: data,
    });
  };

  // validate create speaker
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.title || this.state.title.trim() == "") {
      formIsValid = false;
      errors["name"] = "Please enter speaker name";
    }
    if (this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) {
      formIsValid = false;
      this.setState({
        coverImageMessage: "Please select cover image"
      })
    }
    if (this.state.videoLink !== "" && this.state.videoLink !== null && this.state.videoLink !== undefined) {
      const re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      if (!re.test(this.state.videoLink)) {
        formIsValid = false;
        errors["videoLink"] = "Please enter a valid link";
      }
    }
    if (!this.state.content || this.state.content.trim() == "") {
      formIsValid = false;
      errors["content"] = "Please enter speaker content";
    }
    if (
      !this.state.selectedSpeaker ||
      this.state.selectedSpeaker === null ||
      this.state.selectedSpeaker === undefined ||
      _.isEmpty(this.state.selectedSpeaker)
    ) {
      formIsValid = false;
      errors["selectedSpeaker"] = "Please select speaker";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          coverImageMessage: ''
        });
      }, 5000);
    }
    return formIsValid;
  };

  // create speaker
  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let postData = {
        name: this.state.title.trim(),
        description: this.state.content,
        videoUrl: this.state.videoLink,
        status: "DRAFT",
        type: "SPEAKER",
        category: this.state.selectedSpeaker.value,
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/backend/peopleOfNature/create?type=speaker`,
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const { title, content, videoLink } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/speaker" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <UploadFiles
                accept=".jpg,.png,.jpeg"
                label="Cover Image"
                updateFilesCb={this.updateUploadedFiles}
              />
              {this.state.coverImageMessage && 
                <div className="text-center">
                  <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                </div>
              }
              <FormInput
                group={"large"}
                value={title}
                label={"Speaker Name"}
                onChange={this.titleChange}
                error={this.state.errors["name"]}
              />
              <FormInput
                group={"large"}
                value={videoLink}
                label={"Video Url"}
                onChange={this.videoChange}
                className={'not_required'}
                error={this.state.errors["videoLink"]}
              />
              <div className={`form-group`}>
                <label>
                  Content <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={content}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["content"] && (
                  <small className={style.danger}>
                    {this.state.errors["content"]}
                  </small>
                )}
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {`Speaker`} <span className="text-danger">*</span>
                  </label>
                  <Select options={options} onChange={this.onCategoryChange} />
                  {this.state.errors["selectedSpeaker"] && (
                    <small className={style.danger}>
                      {this.state.errors["selectedSpeaker"]}
                    </small>
                  )}
                </div>
              </div>
              {auth_service.getPermission(
                "People_Of_Nature_Speaker_Create", "People_Of_Nature_Speaker_FullAccess"
              ) && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Submit"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateSpeaker;
