import React, { Component } from 'react';
import { CommonHeader } from '../../../../utils/commonHeader';
import axios from "axios";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../../utils/tableCustomStyle";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styles from "../../post/post.module.css";
import Select from "react-select";
import { FilterComponent } from "../../../../utils/filter";
import { tableDeletePostConstants } from '../../Data/deletePostTableConstants';

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
    title: '<h5>Are you sure you want to reactivate this poll?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
};

class Poll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deletePost: [],
            filterData: { value: "POLL", label: "POLL" },
            filterText: ""
        };
    }

    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.deletePost.filter(
                            (item) =>
                                item.postType &&
                                item.postType.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    searchType={'Search by post type'}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    // fetch delete post report
    fetchDeletePost = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/deletedList?createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&filterType=${this.state.filterData.value}`,
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        deletePost: response.data.body.reportPostList,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    handleConfirmModal = (id) => {
        const handleConfirm = async () => {
            const swalval = await MySwal.fire(confirmModalSwal);
            if (swalval && swalval.value === true) {
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/reactivate?id=${id}`,
                    withCredentials: true,
                })
                    .then((response) => {
                        if (response && response.data && response.data.status === "200 OK") {
                            let deleteItemList = this.state.deletePost;
                            const index = deleteItemList && deleteItemList.findIndex(data => data.id === id);
                            if (index > -1) {
                                deleteItemList.splice(index, 1);
                            }
                            this.setState({
                                deletePost: deleteItemList
                            });
                            //this.fetchDeletePost();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };

        handleConfirm();
    };

    handleFilterChange = (value) => {
        this.setState({
            filterData: value
        }, () => {
            this.fetchDeletePost();
        })
    }

    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
            </React.Fragment>
        );
    };

    componentDidMount() {
        this.fetchDeletePost();
    }


    render() {
        const { deletePost, filterData } = this.state;
        this.filteredItems = this.state.deletePost.filter(
            (item) =>
                item.postType &&
                item.postType.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={styles.pagetopwrap}>
                            <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                                <div className={`${styles.ptwrow}`}>
                                    
                                </div>
                            </div>
                        </div>
                        <DataTable
                            title={"Deleted Poll Entity"}
                            columns={tableDeletePostConstants(this.handleConfirmModal)}
                            data={this.filteredItems}
                            customStyles={customStyles}
                            actions={this.actionButton()}
                            pagination
                            paginationPerPage={20}
                            pointerOnHover
                            fixedHeader
                            fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                        />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default Poll;
