import React, { Component } from "react";
import { auth_service } from "../../../../../../../../auth/auth_service";
import "../../../../year.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../../../../../utils/commonHeader";
import ProjectActivityParticipantsCommon from "./ProjectActivityParticipantsCommon";

export class ProjectPointsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
    };
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.props.match.params.year}/award-categories/${this.props.match.params.slug}/nominations`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large menu-list">
              <ProjectActivityParticipantsCommon pageType={"PROJECT"} userId={this.props.match.params.nomineeId} year={this.props.match.params.year} awardCategorySlug={this.props.match.params.slug} />
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default ProjectPointsList;
