export const data = [
  {
    userId: null,
    organizationName: "Wenat",
    status: "DRAFT",
    email: "company@yopmail.com",
    phoneNo: 7.777733333e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/05/2020",
    lastLogin: "25/05/2020",
    customUrl: "d1c21ca5-ad4f-4395-bcef-e50ac357209a",
  },
  {
    userId: null,
    organizationName: "company",
    status: "COMPLETED",
    email: "comp@yopmail.com",
    phoneNo: 7.677889987e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5ecb9fdb27e66c5ada4557b9",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "25/05/2020",
    lastLogin: "25/05/2020",
    customUrl: "b4ff287e-25fa-4768-aa43-2840464cddd8",
  },
  {
    userId: null,
    organizationName: "companyoperator",
    status: "COMPLETED",
    email: "opr@yopmail.com",
    phoneNo: 8.867654433e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2c170aa4b32549c18ab17f",
        userName: "We com Community",
        customUrl: "we comcommunity",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/05/2020",
    lastLogin: "25/05/2020",
    customUrl: "c71718dd-b560-4f06-9c14-f853ff4b3e65",
  },
  {
    userId: null,
    organizationName: "deepacompany",
    status: "COMPLETED",
    email: "durgaa@yopmail.com",
    phoneNo: 8.801789783e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/05/2020",
    lastLogin: "25/05/2020",
    customUrl: "7f31979a-3045-4c48-b7ab-3258ac99b63f",
  },
  {
    userId: null,
    organizationName: "Community",
    status: "COMPLETED",
    email: "community@yopmail.com",
    phoneNo: 7.778895544e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "26/05/2020",
    lastLogin: "26/05/2020",
    customUrl: "30df94f8-1af0-497a-9a77-2abcff2135b4",
  },
  {
    userId: null,
    organizationName: "lucky",
    status: "DRAFT",
    email: "lucky@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "27/05/2020",
    lastLogin: "27/05/2020",
    customUrl: "6203c6a1-c2fb-4d38-9513-6a0b33fe3f97",
  },
  {
    userId: null,
    organizationName: "Qawenat",
    status: "DRAFT",
    email: "qawenat@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "27/05/2020",
    lastLogin: "27/05/2020",
    customUrl: "0f137f09-2a6f-4c62-be00-fa7d8244f1ac",
  },
  {
    userId: null,
    organizationName: "companyaccountoperator",
    status: "COMPLETED",
    email: "companyopr@gmail.com",
    phoneNo: 8.643333567e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "28/05/2020",
    lastLogin: "28/05/2020",
    customUrl: "7c3f6be3-97fe-45ad-8091-5e572d45a7c5",
  },
  {
    userId: null,
    organizationName: "durga",
    status: "COMPLETED",
    email: "durgaj@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/05/2020",
    lastLogin: "29/05/2020",
    customUrl: "853b8f19-01bc-49c7-a2db-8d033f0c7ee8",
  },
  {
    userId: null,
    organizationName: "Wenat",
    status: "DRAFT",
    email: "wse13@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/05/2020",
    lastLogin: "29/05/2020",
    customUrl: "e302fd38-6e9a-41c4-b4b9-5895e5e5cbd8",
  },
  {
    userId: null,
    organizationName: "Wenatje",
    status: "DRAFT",
    email: "wenatje@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/05/2020",
    lastLogin: "29/05/2020",
    customUrl: "6ac1c0c0-24e9-41f7-9803-07f1cdd7876d",
  },
  {
    userId: null,
    organizationName: "we nat & company ",
    status: "DRAFT",
    email: "pallaviwenat@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/06/2020",
    lastLogin: "01/06/2020",
    customUrl: "53925ffb-b88a-4d0d-bee4-d0a385507d6f",
  },
  {
    userId: null,
    organizationName: "company name@#$$",
    status: "COMPLETED",
    email: "companyaccountoperator@yopmail.com",
    phoneNo: 5.555555555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 2.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
      {
        userId: "5ed89e5b08bace4c69413455",
        userName: "company name@#$$",
        customUrl: "fa17ecaf-a02f-4791-8ee1-144ab7b57e65",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/06/2020",
    lastLogin: "04/06/2020",
    customUrl: "fa17ecaf-a02f-4791-8ee1-144ab7b57e65",
  },
  {
    userId: null,
    organizationName: "WSE INFRATECH",
    status: "DRAFT",
    email: "wseinfra@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/06/2020",
    lastLogin: "04/06/2020",
    customUrl: "28e27ed9-b5ba-4bd5-9f69-ec3ccaceaaff",
  },
  {
    userId: null,
    organizationName: "Wenat & co",
    status: "COMPLETED",
    email: "wenatco@yopmail.com",
    phoneNo: 5.555555555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "04/06/2020",
    lastLogin: "04/06/2020",
    customUrl: "9e0250bf-cbbf-4edb-a931-319e738b68b2",
  },
  {
    userId: null,
    organizationName: "Company Community",
    status: "DRAFT",
    email: "com@yopmail.com",
    phoneNo: 9.030990041e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/06/2020",
    lastLogin: "04/06/2020",
    customUrl: "32a163fe-9e8b-4a3f-88e3-8ab9d4e5a464",
  },
  {
    userId: null,
    organizationName: "Wenat_ & company",
    status: "COMPLETED",
    email: "wenatcompany@yopmail.com",
    phoneNo: 6.290718671e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "05/06/2020",
    lastLogin: "05/06/2020",
    customUrl: "5c9280d6-1bab-44b1-8e99-5513580cbb5d",
  },
  {
    userId: null,
    organizationName: "company ",
    status: "COMPLETED",
    email: "comwenat@yopmail.com",
    phoneNo: 6.666655555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/06/2020",
    lastLogin: "05/06/2020",
    customUrl: "3322bea1-c390-4a05-b239-d21ee92bff4a",
  },
  {
    userId: null,
    organizationName: "MADHU",
    status: "COMPLETED",
    email: "madhuppihu1997@gmail.com",
    phoneNo: 9.507512042e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda37df78ca964fda50507c",
        userName: "MADHU",
        customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/06/2020",
    lastLogin: "05/06/2020",
    customUrl: "80b4e07f-ab81-4435-85fe-3dffb0469c96",
  },
  {
    userId: null,
    organizationName: "TestingCompany123",
    status: "COMPLETED",
    email: "testcompany@yopmail.com",
    phoneNo: 1.123456789e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda44e2adbcbe44fbc8d44b",
        userName: "TestingCompany123",
        customUrl: "ab0b522b-f10d-428c-812d-2cc86dc32d9b",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f60500fd134ee2cfa07240f",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5eda44e2adbcbe44fbc8d44b",
        status: "REQUEST_SENT",
        createTime: 1.600147471349e12,
        lastUpdate: 1.600147471349e12,
      },
    ],
    employees: null,
    noOfPosts: 2.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/06/2020",
    lastLogin: "05/06/2020",
    customUrl: "ab0b522b-f10d-428c-812d-2cc86dc32d9b",
  },
  {
    userId: null,
    organizationName: "Support Team",
    status: "COMPLETED",
    email: "support@yopmail.com",
    phoneNo: 9.123456789e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eda4c76adbcbe44fbc8d453",
        userName: "Support Team",
        customUrl: "8591d75a-139f-4379-94cf-a1bd84c2fb75",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 2.0,
    createdOn: "05/06/2020",
    lastLogin: "05/06/2020",
    customUrl: "8591d75a-139f-4379-94cf-a1bd84c2fb75",
  },
  {
    userId: null,
    organizationName: "Welcome to wenat",
    status: "DRAFT",
    email: "welcm@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/06/2020",
    lastLogin: "06/06/2020",
    customUrl: "ef0c08b9-9b4d-4ad6-a243-8fea76093314",
  },
  {
    userId: null,
    organizationName: "fun time",
    status: "COMPLETED",
    email: "fun@yopmail.com",
    phoneNo: 9.877555588e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5edb797504c242330a7a34ab",
        userName: "fun time",
        customUrl: "8c351d30-7e55-4447-b8b9-84980151cc24",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/06/2020",
    lastLogin: "06/06/2020",
    customUrl: "8c351d30-7e55-4447-b8b9-84980151cc24",
  },
  {
    userId: null,
    organizationName: "comp",
    status: "DRAFT",
    email: "co@yopmail.com",
    phoneNo: 9.999999999e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 2.0,
    admin: [
      {
        userId: "5edbd16162e0195bf5016b48",
        userName: "comp",
        customUrl: "aa328253-406d-4585-8018-99abe946ee34",
      },
      {
        userId: "5edbd16162e0195bf5016b48",
        userName: "comp",
        customUrl: "aa328253-406d-4585-8018-99abe946ee34",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/06/2020",
    lastLogin: "06/06/2020",
    customUrl: "aa328253-406d-4585-8018-99abe946ee34",
  },
  {
    userId: null,
    organizationName: "we Naturalists",
    status: "COMPLETED",
    email: "wenaturalists@yopmail.com",
    phoneNo: 6.290718671e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: "we Naturalists",
        customUrl: "4a4b5557-5fc8-4f75-87e6-849df72748a7",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 6.0,
    followers: [
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde4be62e0195bf5016b53",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "08/06/2020",
    lastLogin: "27/07/2021",
    customUrl: "4a4b5557-5fc8-4f75-87e6-849df72748a7",
  },
  {
    userId: null,
    organizationName: "durga company",
    status: "COMPLETED",
    email: "dc@yopmail.com",
    phoneNo: 9.888888888e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 2.0,
    admin: [
      {
        userId: "5edde5ae62e0195bf5016b55",
        userName: "durga company",
        customUrl: "dccompany90",
      },
      {
        userId: "60b75942574c4e50c47adf89",
        userName: "Kundan Kumar",
        customUrl: "kundankumar",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f566f83442bcf59ec8ee5e5",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5edde5ae62e0195bf5016b55",
        status: "REQUEST_SENT",
        createTime: 1.599500163718e12,
        lastUpdate: 1.599500163718e12,
      },
    ],
    employees: null,
    noOfPosts: 4.0,
    noOfFollowers: 5.0,
    followers: [
      {
        userId: "5edde5ae62e0195bf5016b55",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde5ae62e0195bf5016b55",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde5ae62e0195bf5016b55",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde5ae62e0195bf5016b55",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5edde5ae62e0195bf5016b55",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 4.0,
    createdOn: "08/06/2020",
    lastLogin: "08/06/2020",
    customUrl: "dccompany90",
  },
  {
    userId: null,
    organizationName: "hdfh",
    status: "COMPLETED",
    email: "hdfhf@yopmai.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/06/2020",
    lastLogin: "08/06/2020",
    customUrl: "d45c20f3-17ec-4de6-a777-ea446b31ab04",
  },
  {
    userId: null,
    organizationName: "We naturalists",
    status: "COMPLETED",
    email: "wenat56@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5edde79062e0195bf5016b5f",
        userName: "We naturalists",
        customUrl: "0103ae00-a58b-4f88-8ea9-bde72260d03e",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/06/2020",
    lastLogin: "08/06/2020",
    customUrl: "0103ae00-a58b-4f88-8ea9-bde72260d03e",
  },
  {
    userId: null,
    organizationName: "hhhh",
    status: "COMPLETED",
    email: "ggh@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/06/2020",
    lastLogin: "08/06/2020",
    customUrl: "bf2b3b6d-a535-441d-b0ef-f6424a324d37",
  },
  {
    userId: null,
    organizationName: "lockdown company",
    status: "COMPLETED",
    email: "lockdown1@yopmail.com",
    phoneNo: 9.555555888e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ee06f475344716053412eb7",
        userName: "lockdown company",
        customUrl: "b81a637c-9aee-4374-9700-75b0ee1da5eb",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "10/06/2020",
    lastLogin: "10/06/2020",
    customUrl: "b81a637c-9aee-4374-9700-75b0ee1da5eb",
  },
  {
    userId: null,
    organizationName: "community",
    status: "DRAFT",
    email: "commm@yopmail.com",
    phoneNo: 9.855555555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ee0d27b7ebf4c287157fa34",
        userName: "community",
        customUrl: "65a5b34c-6370-428f-b190-4ba31d923cb6",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "10/06/2020",
    lastLogin: "10/06/2020",
    customUrl: "65a5b34c-6370-428f-b190-4ba31d923cb6",
  },
  {
    userId: null,
    organizationName: "puppy company",
    status: "COMPLETED",
    email: "puppy1@yopmail.com",
    phoneNo: 7.445885555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ee1feb17ebf4c287157fa46",
        userName: "puppy company",
        customUrl: "bb834d3d-f40d-4adf-bc42-f414e7931a9e",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "11/06/2020",
    lastLogin: "11/06/2020",
    customUrl: "bb834d3d-f40d-4adf-bc42-f414e7931a9e",
  },
  {
    userId: null,
    organizationName: "fff",
    status: "COMPLETED",
    email: "tgg@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "12/06/2020",
    lastLogin: "12/06/2020",
    customUrl: "40c4028f-eedf-4c0a-97ac-ac035a2aa7b8",
  },
  {
    userId: null,
    organizationName: "ggg",
    status: "COMPLETED",
    email: "eee@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "12/06/2020",
    lastLogin: "12/06/2020",
    customUrl: "31c11eed-3ed0-488a-8f7f-041c5004dde6",
  },
  {
    userId: null,
    organizationName: "Naturalist company ",
    status: "DRAFT",
    email: "comnature@yopmail.com",
    phoneNo: 8.989890099e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ee715225891681c1ef2b900",
        userName: "Naturalist company ",
        customUrl: "7a2783b9-3958-45d3-8a54-ad9e12db30b4",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "15/06/2020",
    lastLogin: "15/06/2020",
    customUrl: "7a2783b9-3958-45d3-8a54-ad9e12db30b4",
  },
  {
    userId: null,
    organizationName: "Biologist",
    status: "COMPLETED",
    email: "biologist@yopmail.com",
    phoneNo: 5.764567545e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ee747295891681c1ef2b90c",
        userName: "Biologist",
        customUrl: "3e316027-fb28-4bc8-a4c7-1ff4af124889",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "15/06/2020",
    lastLogin: "15/06/2020",
    customUrl: "3e316027-fb28-4bc8-a4c7-1ff4af124889",
  },
  {
    userId: null,
    organizationName: "Join as company",
    status: "COMPLETED",
    email: "companyjs@yopmail.com",
    phoneNo: 3.366665555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ee8baa24e5024774e9f98ab",
        userName: "Join as company",
        customUrl: "a77ee0bb-6a66-4b11-bc1b-ed0545630ffc",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "16/06/2020",
    lastLogin: "16/06/2020",
    customUrl: "a77ee0bb-6a66-4b11-bc1b-ed0545630ffc",
  },
  {
    userId: null,
    organizationName: "join Company",
    status: "COMPLETED",
    email: "joinemail@yopmail.com",
    phoneNo: 999.0,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 2.0,
    admin: [
      {
        userId: "5ee8bb024e5024774e9f98ad",
        userName: "join Company",
        customUrl: "kkkk",
      },
      {
        userId: "5ee8bb024e5024774e9f98ad",
        userName: "join Company",
        customUrl: "kkkk",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 17.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 7.0,
    createdOn: "16/06/2020",
    lastLogin: "14/09/2021",
    customUrl: "kkkk",
  },
  {
    userId: null,
    organizationName: "join our company",
    status: "COMPLETED",
    email: "join1@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "16/06/2020",
    lastLogin: "16/06/2020",
    customUrl: "822a7dce-2acf-4da8-a3e3-6db9d908d281",
  },
  {
    userId: null,
    organizationName: "Biker Association",
    status: "COMPLETED",
    email: "bikerass@yopmail.com",
    phoneNo: 7.777777776e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eeb72b84e5024774e9f98ec",
        userName: "Biker Association",
        customUrl: "eb9a20bd-f536-4542-8ab5-c92873a6a590",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "18/06/2020",
    lastLogin: "18/06/2020",
    customUrl: "eb9a20bd-f536-4542-8ab5-c92873a6a590",
  },
  {
    userId: null,
    organizationName: "Wenat & company ",
    status: "COMPLETED",
    email: "weco@yopmail.com",
    phoneNo: 5.555555555e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eec76064e5024774e9f98f1",
        userName: "Wenat & company ",
        customUrl: "2d2c2c7b-6a17-4de0-839c-91c3f1a434da",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 2.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "19/06/2020",
    lastLogin: "19/06/2020",
    customUrl: "2d2c2c7b-6a17-4de0-839c-91c3f1a434da",
  },
  {
    userId: null,
    organizationName: "new one",
    status: "COMPLETED",
    email: "new@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "19/06/2020",
    lastLogin: "03/09/2021",
    customUrl: "28bb1390-4c90-4e4e-b1ad-0d73a8807b31",
  },
  {
    userId: null,
    organizationName: "rexc",
    status: "COMPLETED",
    email: "rex@yopmail.com",
    phoneNo: 6666.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5eec8e814e5024774e9f98f4",
        userName: "rexc",
        customUrl: "46f27202-1a3f-4f2b-b49b-3980f0c80992",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "19/06/2020",
    lastLogin: "19/06/2020",
    customUrl: "46f27202-1a3f-4f2b-b49b-3980f0c80992",
  },
  {
    userId: null,
    organizationName: "joindeepa",
    status: "COMPLETED",
    email: "deepu@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "22/06/2020",
    lastLogin: "22/06/2020",
    customUrl: "031b014b-8672-4178-a682-b21e6db4ceb9",
  },
  {
    userId: null,
    organizationName: "join deepa",
    status: "COMPLETED",
    email: "deepu1@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "22/06/2020",
    lastLogin: "22/06/2020",
    customUrl: "bcc3b1bb-27d3-4b08-b4ad-2c96b726ec67",
  },
  {
    userId: null,
    organizationName: "join deepa2",
    status: "COMPLETED",
    email: "joinde@yopmail.com",
    phoneNo: 988888.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ef08ac64e5024774e9f990b",
        userName: "join deepa2",
        customUrl: "9cddc18d-c721-4de2-a4ba-e1e1a3718285",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f2e7b180defc6754c745428",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5ef08ac64e5024774e9f990b",
        status: "REQUEST_ACCEPTED",
        createTime: 1.596881688664e12,
        lastUpdate: 1.596881688664e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "22/06/2020",
    lastLogin: "22/06/2020",
    customUrl: "9cddc18d-c721-4de2-a4ba-e1e1a3718285",
  },
  {
    userId: null,
    organizationName: "join company",
    status: "COMPLETED",
    email: "compjoin@yopmail.com",
    phoneNo: 7.665555667e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ef0b1354e5024774e9f9911",
        userName: "join company",
        customUrl: "bbdccced-f68e-4f7d-8ef4-68b86bcbea07",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "22/06/2020",
    lastLogin: "22/06/2020",
    customUrl: "bbdccced-f68e-4f7d-8ef4-68b86bcbea07",
  },
  {
    userId: null,
    organizationName: "Join my wenat",
    status: "COMPLETED",
    email: "joinwenat@yopmail.com",
    phoneNo: 6.554333333e9,
    organizationCountry: "Iran",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5efc6f0eb2f83a731d09d9b1",
        userName: "Join my wenat",
        customUrl: "company",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 12.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/07/2020",
    lastLogin: "01/07/2020",
    customUrl: "company",
  },
  {
    userId: null,
    organizationName: "wenat jopin",
    status: "COMPLETED",
    email: "joinemail12@yopmail.com",
    phoneNo: 5.554443336e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f059780dd003700aa39acfb",
        userName: "wenat jopin",
        customUrl: "wenat jopin",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/07/2020",
    lastLogin: "08/07/2020",
    customUrl: "wenat jopin",
  },
  {
    userId: null,
    organizationName: "join our community",
    status: "COMPLETED",
    email: "joinemail2@yopmail.com",
    phoneNo: 8.888888888e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f0598f1dd003700aa39acfd",
        userName: "join our community",
        customUrl: "join our community",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/07/2020",
    lastLogin: "08/07/2020",
    customUrl: "join our community",
  },
  {
    userId: null,
    organizationName: "join our community",
    status: "COMPLETED",
    email: "joinemail7@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "09/07/2020",
    lastLogin: "09/07/2020",
    customUrl: "join our community1",
  },
  {
    userId: null,
    organizationName: "Wenat Corp.",
    status: "COMPLETED",
    email: "wenat@yopmail.com",
    phoneNo: 9.211232321e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f08cb094a6fa2082849c00e",
        userName: "Wenat Corp.",
        customUrl: "wenat corp.",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "10/07/2020",
    lastLogin: "10/07/2020",
    customUrl: "wenat corp.",
  },
  {
    userId: null,
    organizationName: "Durga & CO",
    status: "DRAFT",
    email: "durgaco@yopmail.com",
    phoneNo: 9.030990049e9,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f0c0c5a1fa7c837cecae619",
        userName: "Durga & CO",
        customUrl: "durga & co",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "13/07/2020",
    lastLogin: "13/07/2020",
    customUrl: "durga & co",
  },
  {
    userId: null,
    organizationName: "join com",
    status: "DRAFT",
    email: "joincom@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "13/07/2020",
    lastLogin: "13/07/2020",
    customUrl: "join com",
  },
  {
    userId: null,
    organizationName: "Join wenatco",
    status: "COMPLETED",
    email: "joinwenatco@yopmail.com",
    phoneNo: 3.445323322e9,
    organizationCountry: "Bangladesh",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f11a9f63e003f7510d50faa",
        userName: "wenat co join company",
        customUrl: "wenat cojoin company",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "17/07/2020",
    lastLogin: "17/07/2020",
    customUrl: "join wenatco",
  },
  {
    userId: null,
    organizationName: "Test",
    status: "DRAFT",
    email: "test45@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "17/07/2020",
    lastLogin: "17/07/2020",
    customUrl: "test12",
  },
  {
    userId: null,
    organizationName: "Ccc",
    status: "COMPLETED",
    email: "gddh@h.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f5635b5442bcf59ec8ee5dc",
        userId: "5ee362797ebf4c287157fa73",
        companyId: "5f179e5e0b01815d5a904c8a",
        status: "REQUEST_SENT",
        createTime: 1.599485365611e12,
        lastUpdate: 1.599485365611e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "22/07/2020",
    lastLogin: "22/07/2020",
    customUrl: "ccc12",
  },
  {
    userId: null,
    organizationName: "cvbbnnxddc",
    status: "COMPLETED",
    email: "joinemail4@yopmail.com",
    phoneNo: 4.444444333e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f224a5d427a24033f644f27",
        userName: "css dss",
        customUrl: "cssdss",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "cvbbnnxddc",
  },
  {
    userId: null,
    organizationName: "new",
    status: "COMPLETED",
    email: "new12@yopmail.com",
    phoneNo: 3.223333333e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f224b7c427a24033f644f29",
        userName: "vvc cfxxc",
        customUrl: "vvccfxxc",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "new",
  },
  {
    userId: null,
    organizationName: "wes",
    status: "COMPLETED",
    email: "wes@yopmail.com",
    phoneNo: 1.111111333e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f224d07427a24033f644f2c",
        userName: "ees sss",
        customUrl: "eessss",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "wes",
  },
  {
    userId: null,
    organizationName: "szszssd",
    status: "COMPLETED",
    email: "aaa@yopmail.com",
    phoneNo: 1.233222222e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f224da5427a24033f644f2e",
        userName: "eewq qqsa",
        customUrl: "eewqqqsa",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "szszssd",
  },
  {
    userId: null,
    organizationName: "transformer",
    status: "DRAFT",
    email: "trans@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "transformer",
  },
  {
    userId: null,
    organizationName: "xccvcv",
    status: "DRAFT",
    email: "rrr@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "xccvcv",
  },
  {
    userId: null,
    organizationName: "qwe",
    status: "DRAFT",
    email: "qwe@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/07/2020",
    lastLogin: "30/07/2020",
    customUrl: "qwe",
  },
  {
    userId: null,
    organizationName: "innovation pvt. ltd.",
    status: "COMPLETED",
    email: "innovation@g.com",
    phoneNo: 9.855447777e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f23e9095b4164725cc1d2bf",
        userName: "innovation john",
        customUrl: "innovationjohn",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "31/07/2020",
    lastLogin: "31/07/2020",
    customUrl: "innovation pvt. ltd.",
  },
  {
    userId: null,
    organizationName: "doremon",
    status: "DRAFT",
    email: "dore@yopmail.com",
    phoneNo: 9.988855225e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f241e8c82e1943adb5f3dc7",
        userName: "dore mon",
        customUrl: "doremon1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "31/07/2020",
    lastLogin: "31/07/2020",
    customUrl: "doremon",
  },
  {
    userId: null,
    organizationName: "doremon",
    status: "COMPLETED",
    email: "doremon@yopmail.com",
    phoneNo: 9.574455544e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5fad0f3fb879db6e36914fcd",
        userName: "doremon info",
        customUrl: "doremoninfo",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 2.0,
    followers: [
      {
        userId: "5f2423af82e1943adb5f3dc9",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2423af82e1943adb5f3dc9",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 3.0,
    createdOn: "31/07/2020",
    lastLogin: "31/07/2020",
    customUrl: "doremon2",
  },
  {
    userId: null,
    organizationName: "jdfjj",
    status: "DRAFT",
    email: "testphn@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/08/2020",
    lastLogin: "02/08/2020",
    customUrl: "jdfjj",
  },
  {
    userId: null,
    organizationName: "aaasx",
    status: "COMPLETED",
    email: "aaazx@yopmail.com",
    phoneNo: 3.323444322e9,
    organizationCountry: "Angola",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f27eaa9fa83de196e09a0ac",
        userName: "ff fff",
        customUrl: "fffff",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "03/08/2020",
    lastLogin: "03/08/2020",
    customUrl: "aaasx",
  },
  {
    userId: null,
    organizationName: "ddffd",
    status: "DRAFT",
    email: "data@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "03/08/2020",
    lastLogin: "03/08/2020",
    customUrl: "ddffd",
  },
  {
    userId: null,
    organizationName: "dfff",
    status: "COMPLETED",
    email: "dddd@ghhh.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/08/2020",
    lastLogin: "04/08/2020",
    customUrl: "dfff",
  },
  {
    userId: null,
    organizationName: "dddxdf",
    status: "COMPLETED",
    email: "ddd@yopmail.com",
    phoneNo: 4.333333343e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2a601dc772ed50b90a00fb",
        userName: "pddds fdfds",
        customUrl: "pdddsfdfds",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/08/2020",
    lastLogin: "05/08/2020",
    customUrl: "dddxdf",
  },
  {
    userId: null,
    organizationName: "Community",
    status: "COMPLETED",
    email: "comemail@yopmail.com",
    phoneNo: 3.343322322e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2a9780c772ed50b90a0105",
        userName: "fccffc email",
        customUrl: "fccffcemail",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/08/2020",
    lastLogin: "05/08/2020",
    customUrl: "community",
  },
  {
    userId: null,
    organizationName: "yahoo",
    status: "COMPLETED",
    email: "yahoo@mail.com",
    phoneNo: 9.876777777e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2ae079c772ed50b90a0108",
        userName: "Praveen Kumar",
        customUrl: "praveenkumar1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/08/2020",
    lastLogin: "05/08/2020",
    customUrl: "yahoo",
  },
  {
    userId: null,
    organizationName: "QA company",
    status: "COMPLETED",
    email: "test1@yopmail.com",
    phoneNo: 1.212412321e9,
    organizationCountry: "United States",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2bff41e891244e111a27bd",
        userName: "QA test",
        customUrl: "qatest",
      },
    ],
    noOfEmployees: 2.0,
    employeeDto: [
      {
        id: "5fcdf092db1bad7b36921241",
        userId: "5fcdefacdb1bad7b3692123c",
        companyId: "5f2bff23e891244e111a27bb",
        status: "REQUEST_SENT",
        createTime: 1.607331986748e12,
        lastUpdate: 1.607331986748e12,
      },
      {
        id: "5ffc0a27ef58bc12732dce86",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f2bff23e891244e111a27bb",
        status: "REQUEST_SENT",
        createTime: 1.610353191297e12,
        lastUpdate: 1.610353191297e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f2bff23e891244e111a27bb",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/08/2020",
    lastLogin: "06/08/2020",
    customUrl: "qa company",
  },
  {
    userId: null,
    organizationName: "Business Comp",
    status: "COMPLETED",
    email: "business@yopmail.com",
    phoneNo: 9.312321312e9,
    organizationCountry: "Angola",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2c046904f2905dc9ff0ed9",
        userName: "Harsh Tibrewal",
        customUrl: "harshtibrewal1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 4.0,
    followers: [
      {
        userId: "5f2c03f304f2905dc9ff0ed8",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2c03f304f2905dc9ff0ed8",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2c03f304f2905dc9ff0ed8",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2c03f304f2905dc9ff0ed8",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/08/2020",
    lastLogin: "10/08/2021",
    customUrl: "business comp",
  },
  {
    userId: null,
    organizationName: "test qa",
    status: "COMPLETED",
    email: "testcompany2@yopmail.com",
    phoneNo: 9.312312312e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2c12c7a4b32549c18ab17c",
        userName: "Harsh Tibrewal",
        customUrl: "harshtibrewal2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/08/2020",
    lastLogin: "06/08/2020",
    customUrl: "test qa",
  },
  {
    userId: null,
    organizationName: "Wenat community",
    status: "COMPLETED",
    email: "comwe@yopmail.com",
    phoneNo: 7.789898988e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2c170aa4b32549c18ab17f",
        userName: "We com Community",
        customUrl: "we comcommunity",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f2c168aa4b32549c18ab17d",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/08/2020",
    lastLogin: "06/08/2020",
    customUrl: "wenat community",
  },
  {
    userId: null,
    organizationName: "durcom",
    status: "COMPLETED",
    email: "durcom@yopmail.com",
    phoneNo: 44.0,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2d33f3a4b32549c18ab18a",
        userName: "ffdd ffddf",
        customUrl: "ffddffddf",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f7c741768e5e04c315a7175",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f2d33d2a4b32549c18ab189",
        status: "REQUEST_SENT",
        createTime: 1.601991703508e12,
        lastUpdate: 1.601991703508e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "07/08/2020",
    lastLogin: "07/08/2020",
    customUrl: "durcom",
  },
  {
    userId: null,
    organizationName: "business pro123",
    status: "COMPLETED",
    email: "businesspro@yopmail.com",
    phoneNo: 9.855855555e9,
    organizationCountry: "Singapore",
    organizationType: "COMPANY",
    noOfAdmins: 2.0,
    admin: [
      {
        userId: "5f2e0c76953ec6145df7bd23",
        userName: "business profile",
        customUrl: "businessprofile",
      },
      {
        userId: "5ee362797ebf4c287157fa73",
        userName: "Pk Kolkata",
        customUrl: "pk",
      },
    ],
    noOfEmployees: 41.0,
    employeeDto: [
      {
        id: "5f34320a14a19b1f87aa97b1",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.597256202907e12,
        lastUpdate: 1.597256202907e12,
      },
      {
        id: "5f4f74351baad96a54d03dfd",
        userId: "5ecbb1f227e66c5ada4557c6",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599042613457e12,
        lastUpdate: 1.599042613457e12,
      },
      {
        id: "5f5732d0442bcf59ec8ee5eb",
        userId: "5efaf465b2f83a731d09d992",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599550160058e12,
        lastUpdate: 1.599550160058e12,
      },
      {
        id: "5f587f338488da5f33cf104b",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599635251797e12,
        lastUpdate: 1.599635251797e12,
      },
      {
        id: "5f5881a48488da5f33cf104d",
        userId: "5edb436e04c242330a7a3497",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599635876551e12,
        lastUpdate: 1.599635876551e12,
      },
      {
        id: "5fb298f562a2bc757b580880",
        userId: "5f75b0ec8f2e07270124b51e",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.605540085814e12,
        lastUpdate: 1.605540085814e12,
      },
      {
        id: "5fba357a4bc68a73a8e0e8f4",
        userId: "5f9bbf7013dbc95ff597f2f2",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.606038906183e12,
        lastUpdate: 1.606038906183e12,
      },
      {
        id: "5fcdd841db1bad7b36921231",
        userId: "5fcdd673db1bad7b3692122a",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.607325761185e12,
        lastUpdate: 1.607325761185e12,
      },
      {
        id: "600003b3c329c913e1d12e2b",
        userId: "5ed9e8d17272215c9f7606cd",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.61061368377e12,
        lastUpdate: 1.61061368377e12,
      },
      {
        id: "6022352e409ae80e3947ede2",
        userId: "5f6e1be3faaf6d4ce0cc2f8f",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.612854574739e12,
        lastUpdate: 1.612854574739e12,
      },
      {
        id: "6037a023f8f330703b5b89d7",
        userId: "60349cf650864b0f59c857f1",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.614258211782e12,
        lastUpdate: 1.614258211782e12,
      },
      {
        id: "604863a47946e666aa9dab87",
        userId: "5fe1debcb9fdec4065cfc4bb",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.615356836786e12,
        lastUpdate: 1.615356836786e12,
      },
      {
        id: "604863d97946e666aa9dab88",
        userId: "603c8cec8c33961062dc8fcc",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.615356889113e12,
        lastUpdate: 1.615356889113e12,
      },
      {
        id: "60492711ad945f248e8c63e1",
        userId: "603f3781dfc0930228fd23b8",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.615406865768e12,
        lastUpdate: 1.615406865768e12,
      },
      {
        id: "608a7560ef782b5c2b983996",
        userId: "5f983d83bb8444186f9110d7",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.619686752475e12,
        lastUpdate: 1.619686752475e12,
      },
      {
        id: "608bed536014c25fc668956d",
        userId: "5f22befe1494893d41d0cd38",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.619782995601e12,
        lastUpdate: 1.619782995601e12,
      },
      {
        id: "609cc8d00e50ab1fbe1880e5",
        userId: "609b7202878e817e81f2cfd5",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.620887760803e12,
        lastUpdate: 1.620887760803e12,
      },
      {
        id: "609cda270e50ab1fbe1880ec",
        userId: "608a8a3def782b5c2b983998",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.62089219978e12,
        lastUpdate: 1.62089219978e12,
      },
      {
        id: "60a282f41be1dc33e20dffad",
        userId: "60a281671be1dc33e20dffa6",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.621263092885e12,
        lastUpdate: 1.621263092885e12,
      },
      {
        id: "60a38ac0bd970720db5a5259",
        userId: "5f43648eb5da123aef677e4e",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.621330624551e12,
        lastUpdate: 1.621330624551e12,
      },
      {
        id: "60a78c616df1d904218b8b75",
        userId: "5ecbd038811990408de96492",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.621593185912e12,
        lastUpdate: 1.621593185912e12,
      },
      {
        id: "60a78ce06df1d904218b8b79",
        userId: "60a4b657ba2a4a1c59efc7fa",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.621593312251e12,
        lastUpdate: 1.621593312251e12,
      },
      {
        id: "60a78d0f6df1d904218b8b7b",
        userId: "6094ce9c67c56a5cfd54e1c4",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.621593359391e12,
        lastUpdate: 1.621593359391e12,
      },
      {
        id: "60a78d526df1d904218b8b7c",
        userId: "60a49da0ba2a4a1c59efc7f1",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.621593426156e12,
        lastUpdate: 1.621593426156e12,
      },
      {
        id: "60a78e066df1d904218b8b7d",
        userId: "608b99996e6d041717e8fd52",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.62159360627e12,
        lastUpdate: 1.62159360627e12,
      },
      {
        id: "60a7af0cd709cf6a2d326444",
        userId: "607d15473aa362564d429d23",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.621602060251e12,
        lastUpdate: 1.621602060251e12,
      },
      {
        id: "60b1f2f7fc30a27ab353fcf2",
        userId: "60b1f07afc30a27ab353fcee",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.622274807493e12,
        lastUpdate: 1.622274807493e12,
      },
      {
        id: "60c73505765f66676e031436",
        userId: "60c3199d6e77eb4b140a97ad",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.623667973543e12,
        lastUpdate: 1.623667973543e12,
      },
      {
        id: "60caebc41735ad5bb81e35f5",
        userId: "60c319286e77eb4b140a97aa",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.623911364159e12,
        lastUpdate: 1.623911364159e12,
      },
      {
        id: "60cb69601a0d077c675dded9",
        userId: "60c9b27344cad80241015c2e",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.62394352043e12,
        lastUpdate: 1.62394352043e12,
      },
      {
        id: "60cc3a6e533ea90ee6527fb6",
        userId: "60cc2d60533ea90ee6527faf",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.623997038745e12,
        lastUpdate: 1.623997038745e12,
      },
      {
        id: "60cca4f7ae4a0f739f319b0e",
        userId: "5f2d32d4a4b32549c18ab185",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.624024311617e12,
        lastUpdate: 1.624024311617e12,
      },
      {
        id: "60d9ee7eaaac526fb1bf6816",
        userId: "60d2b1b9e80a7d0410c08179",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.624895102448e12,
        lastUpdate: 1.624895102448e12,
      },
      {
        id: "610cdf7c7f744a13f8074d71",
        userId: "60afa11359066a61761acbe7",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.628233596766e12,
        lastUpdate: 1.628233596766e12,
      },
      {
        id: "6110e4c10ef92063d7d9415f",
        userId: "6110d9bb0ef92063d7d9415a",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_ACCEPTED",
        createTime: 1.628497089819e12,
        lastUpdate: 1.628497089819e12,
      },
      {
        id: "611e5386487d8047b5d9e4e2",
        userId: "60e692e2525add12609f49af",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.629377414103e12,
        lastUpdate: 1.629377414103e12,
      },
      {
        id: "61271fb6b4b7101636493946",
        userId: "611238912d2dab7aaf50a2bb",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.629953974406e12,
        lastUpdate: 1.629953974406e12,
      },
      {
        id: "612773fec96f9f1caba5bf9b",
        userId: "61268eeab4b710163649393e",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.62997555063e12,
        lastUpdate: 1.62997555063e12,
      },
      {
        id: "61278d49ad2bc138b63af31d",
        userId: "60e2b340a159b075f532f34a",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.629982025943e12,
        lastUpdate: 1.629982025943e12,
      },
      {
        id: "6127ed5a7cfbe364e161c7ea",
        userId: "6127c5fdad2bc138b63af322",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.630006618988e12,
        lastUpdate: 1.630006618988e12,
      },
      {
        id: "6127fc5c66bf8e315af47d1b",
        userId: "61276593c96f9f1caba5bf8f",
        companyId: "5f2e0b0f953ec6145df7bd22",
        status: "REQUEST_SENT",
        createTime: 1.630010460588e12,
        lastUpdate: 1.630010460588e12,
      },
    ],
    employees: null,
    noOfPosts: 209.0,
    noOfFollowers: 24.0,
    followers: [
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f2e0b0f953ec6145df7bd22",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 248.0,
    createdOn: "08/08/2020",
    lastLogin: "30/09/2021",
    customUrl: "bp2020",
  },
  {
    userId: null,
    organizationName: "google",
    status: "COMPLETED",
    email: "google@g.com",
    phoneNo: 2555885.0,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2e0f4c953ec6145df7bd25",
        userName: "google google",
        customUrl: "googlegoogle",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/08/2020",
    lastLogin: "08/08/2020",
    customUrl: "google",
  },
  {
    userId: null,
    organizationName: "dffdff",
    status: "COMPLETED",
    email: "ffff@ycff.com",
    phoneNo: 3.322322123e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2eb0b60defc6754c74542e",
        userName: "ffff vfcf",
        customUrl: "ffffvfcf",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/08/2020",
    lastLogin: "08/08/2020",
    customUrl: "dffdff",
  },
  {
    userId: null,
    organizationName: "deep company",
    status: "COMPLETED",
    email: "deep@yopmail.com",
    phoneNo: 9.030990093e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f2f69500defc6754c745436",
        userName: "honey bee",
        customUrl: "honeybee",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f570d3a442bcf59ec8ee5e8",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f2f68e40defc6754c745434",
        status: "REQUEST_SENT",
        createTime: 1.599540538554e12,
        lastUpdate: 1.599540538554e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f2f68e40defc6754c745434",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "09/08/2020",
    lastLogin: "09/08/2020",
    customUrl: "deep company",
  },
  {
    userId: null,
    organizationName: "sdsfd",
    status: "COMPLETED",
    email: "you@yopmail.com",
    phoneNo: 6.895562102e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "6024d82ce73a0a128681318e",
        userName: "Page you",
        customUrl: "pageyou",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "6024d928e73a0a1286813192",
        userId: "6024d1c6e73a0a1286813182",
        companyId: "5f3247b70defc6754c74543d",
        status: "REQUEST_ACCEPTED",
        createTime: 1.613027624337e12,
        lastUpdate: 1.613027624337e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f3247b70defc6754c74543d",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 1.0,
    createdOn: "11/08/2020",
    lastLogin: "11/08/2020",
    customUrl: "dff",
  },
  {
    userId: null,
    organizationName: "deepa",
    status: "COMPLETED",
    email: "deepadur@yopmail.com",
    phoneNo: 2.025550124e9,
    organizationCountry: "United States of America",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "60991bed6069d42bcffe9210",
        userName: "migration newly",
        customUrl: "migrationnewly",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f3290090defc6754c745443",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "11/08/2020",
    lastLogin: "11/08/2020",
    customUrl: "deepa",
  },
  {
    userId: null,
    organizationName: "fff",
    status: "COMPLETED",
    email: "fff@yopmail.com",
    phoneNo: 22.0,
    organizationCountry: "Angola",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f329f780defc6754c745449",
        userName: "abcd ssw",
        customUrl: "abcdssw",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "11/08/2020",
    lastLogin: "11/08/2020",
    customUrl: "fff",
  },
  {
    userId: null,
    organizationName: "went",
    status: "COMPLETED",
    email: "went@yopmail.com",
    phoneNo: 99.0,
    organizationCountry: "Angola",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f32a04d0defc6754c74544c",
        userName: "went rr",
        customUrl: "wentrr",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "11/08/2020",
    lastLogin: "11/08/2020",
    customUrl: "went",
  },
  {
    userId: null,
    organizationName: "sss",
    status: "COMPLETED",
    email: "sss@yopmail.com",
    phoneNo: 2222.0,
    organizationCountry: "Andorra",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f32a08e0defc6754c74544e",
        userName: "sss sss",
        customUrl: "ssssss",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "11/08/2020",
    lastLogin: "11/08/2020",
    customUrl: "sss",
  },
  {
    userId: null,
    organizationName: "Companywe",
    status: "COMPLETED",
    email: "companywe@yopmail.com",
    phoneNo: 2.323444444e9,
    organizationCountry: "Armenia",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f35243f06ca57530a018aac",
        userName: "company we",
        customUrl: "companywe",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "601d4764af699e07b163949d",
        userId: "5f75b0ec8f2e07270124b51e",
        companyId: "5f35241206ca57530a018aaa",
        status: "REQUEST_SENT",
        createTime: 1.612531556591e12,
        lastUpdate: 1.612531556591e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f35241206ca57530a018aaa",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "13/08/2020",
    lastLogin: "13/08/2020",
    customUrl: "0",
  },
  {
    userId: null,
    organizationName: "company3",
    status: "COMPLETED",
    email: "cmp3@yopmail.com",
    phoneNo: 2.233333333e9,
    organizationCountry: "Aruba",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f3524d406ca57530a018aaf",
        userName: "cccv ccvcv",
        customUrl: "cccvccvcv",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "13/08/2020",
    lastLogin: "13/08/2020",
    customUrl: "company3",
  },
  {
    userId: null,
    organizationName: "pallabi page",
    status: "COMPLETED",
    email: "plb@yopmail.com",
    phoneNo: 7.12345678e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5fa92c79e7e15e363b2c47ca",
        userName: "pallabi dd",
        customUrl: "pallabidd",
      },
    ],
    noOfEmployees: 7.0,
    employeeDto: [
      {
        id: "5fb252721e07dd05a965f837",
        userId: "5efaf465b2f83a731d09d992",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_SENT",
        createTime: 1.605522034745e12,
        lastUpdate: 1.605522034745e12,
      },
      {
        id: "5fb252f11e07dd05a965f839",
        userId: "5f9bbf7013dbc95ff597f2f2",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_ACCEPTED",
        createTime: 1.605522161963e12,
        lastUpdate: 1.605522161963e12,
      },
      {
        id: "5fb27bed62a2bc757b580876",
        userId: "5facc32385344418df2ac33b",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_SENT",
        createTime: 1.605532653473e12,
        lastUpdate: 1.605532653473e12,
      },
      {
        id: "6022351c409ae80e3947ede1",
        userId: "5f6e1be3faaf6d4ce0cc2f8f",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_ACCEPTED",
        createTime: 1.612854556316e12,
        lastUpdate: 1.612854556316e12,
      },
      {
        id: "6048c3b7ad945f248e8c63db",
        userId: "5f75b0ec8f2e07270124b51e",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_ACCEPTED",
        createTime: 1.615381431519e12,
        lastUpdate: 1.615381431519e12,
      },
      {
        id: "6048c3c4ad945f248e8c63dc",
        userId: "5f3f9f5e35001f3a8c8ef97d",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_ACCEPTED",
        createTime: 1.615381444148e12,
        lastUpdate: 1.615381444148e12,
      },
      {
        id: "609bd2ba878e817e81f2cfdf",
        userId: "5f983d83bb8444186f9110d7",
        companyId: "5f367f8d06ca57530a018abf",
        status: "REQUEST_ACCEPTED",
        createTime: 1.620824762462e12,
        lastUpdate: 1.620824762462e12,
      },
    ],
    employees: null,
    noOfPosts: 42.0,
    noOfFollowers: 7.0,
    followers: [
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f367f8d06ca57530a018abf",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 39.0,
    createdOn: "14/08/2020",
    lastLogin: "14/08/2020",
    customUrl: "pallabi pae",
  },
  {
    userId: null,
    organizationName: "fcfcfc",
    status: "COMPLETED",
    email: "fcfc@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "14/08/2020",
    lastLogin: "14/08/2020",
    customUrl: "fcfcfc",
  },
  {
    userId: null,
    organizationName: "comp",
    status: "DRAFT",
    email: "compl@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "14/08/2020",
    lastLogin: "14/08/2020",
    customUrl: "comp",
  },
  {
    userId: null,
    organizationName: "Comp 2",
    status: "COMPLETED",
    email: "company2@yopmail.com",
    phoneNo: 8.213123123e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f3a9fe35a2f3048e49206da",
        userName: "Harsh Tibrewal",
        customUrl: "harshtibrewal3",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 3.0,
    followers: [
      {
        userId: "5f3a9fd25a2f3048e49206d9",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f3a9fd25a2f3048e49206d9",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f3a9fd25a2f3048e49206d9",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "17/08/2020",
    lastLogin: "17/08/2020",
    customUrl: "comp 2",
  },
  {
    userId: null,
    organizationName: "ffddd",
    status: "COMPLETED",
    email: "ffr@yopmail.com",
    phoneNo: 8.989889889e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f3f9e3535001f3a8c8ef97c",
        userName: "ffr ffr",
        customUrl: "ffrffr",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "21/08/2020",
    lastLogin: "21/08/2020",
    customUrl: "ffddd",
  },
  {
    userId: null,
    organizationName: "fra",
    status: "COMPLETED",
    email: "fra@yopmail.com",
    phoneNo: 2.422222222e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f3fa32f35001f3a8c8ef987",
        userName: "fra fra",
        customUrl: "frafra",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "21/08/2020",
    lastLogin: "21/08/2020",
    customUrl: "fra",
  },
  {
    userId: null,
    organizationName: "okcomp",
    status: "COMPLETED",
    email: "okcomp@yopmail.com",
    phoneNo: 4.222222222e9,
    organizationCountry: "Armenia",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f3fa3e735001f3a8c8ef98a",
        userName: "okcom ppp",
        customUrl: "okcomppp",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 5.0,
    createdOn: "21/08/2020",
    lastLogin: "21/08/2020",
    customUrl: "okcomp",
  },
  {
    userId: null,
    organizationName: "mom",
    status: "DRAFT",
    email: "mom@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "22/08/2020",
    lastLogin: "22/08/2020",
    customUrl: "mom",
  },
  {
    userId: null,
    organizationName: "abc testing",
    status: "COMPLETED",
    email: "punam@wsegames.com",
    phoneNo: 0.0,
    organizationCountry: "Algeria",
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 5.0,
    noOfFollowers: 3.0,
    followers: [
      {
        userId: "5f42229fb5da123aef677e3b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f42229fb5da123aef677e3b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f42229fb5da123aef677e3b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 1.0,
    createdOn: "23/08/2020",
    lastLogin: "18/08/2021",
    customUrl: "abc testing",
  },
  {
    userId: null,
    organizationName: "WSE",
    status: "COMPLETED",
    email: "punam@wseventures.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "23/08/2020",
    lastLogin: "23/08/2020",
    customUrl: "wse",
  },
  {
    userId: null,
    organizationName: "testing details",
    status: "COMPLETED",
    email: "test@ewre.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "23/08/2020",
    lastLogin: "23/08/2020",
    customUrl: "testing details",
  },
  {
    userId: null,
    organizationName: "jk company",
    status: "COMPLETED",
    email: "jk@yopmail.com",
    phoneNo: 9.585587445e9,
    organizationCountry: "Iceland",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f48ae8fa5569257e8678c31",
        userName: "jk comp",
        customUrl: "jkcomp",
      },
    ],
    noOfEmployees: 2.0,
    employeeDto: [
      {
        id: "5f57899d442bcf59ec8ee607",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5f422d11b5da123aef677e3e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599572381653e12,
        lastUpdate: 1.599572381653e12,
      },
      {
        id: "5f578be5442bcf59ec8ee609",
        userId: "5efaf465b2f83a731d09d992",
        companyId: "5f422d11b5da123aef677e3e",
        status: "REQUEST_SENT",
        createTime: 1.59957296504e12,
        lastUpdate: 1.59957296504e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "23/08/2020",
    lastLogin: "23/08/2020",
    customUrl: "d",
  },
  {
    userId: null,
    organizationName: "sun network",
    status: "COMPLETED",
    email: "network@yopmail.com",
    phoneNo: 9.855885558e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f4230cfb5da123aef677e41",
        userName: "sun network",
        customUrl: "sunnetwork",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "23/08/2020",
    lastLogin: "23/08/2020",
    customUrl: "sun network",
  },
  {
    userId: null,
    organizationName: "testing",
    status: "COMPLETED",
    email: "red@er.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "24/08/2020",
    lastLogin: "24/08/2020",
    customUrl: "testing",
  },
  {
    userId: null,
    organizationName: "testing",
    status: "COMPLETED",
    email: "test@wed.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "24/08/2020",
    lastLogin: "24/08/2020",
    customUrl: "testing1",
  },
  {
    userId: null,
    organizationName: "jkgro",
    status: "COMPLETED",
    email: "khkk@yopmai.com",
    phoneNo: 9.885588885e9,
    organizationCountry: "Kazakhstan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f43beecb5da123aef677e5f",
        userName: "fjf jva",
        customUrl: "fjfjva",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "24/08/2020",
    lastLogin: "24/08/2020",
    customUrl: "jkgro",
  },
  {
    userId: null,
    organizationName: "dk com",
    status: "COMPLETED",
    email: "dk@yopmail.com",
    phoneNo: 1.212121212e9,
    organizationCountry: "Belgium",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f450f2b7d581118c4532023",
        userName: "DdDD kkkk",
        customUrl: "ddddkkkk",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/08/2020",
    lastLogin: "25/08/2020",
    customUrl: "dk com",
  },
  {
    userId: null,
    organizationName: "compvfcc",
    status: "COMPLETED",
    email: "vfcc@yopmail.com",
    phoneNo: 1.233333333e9,
    organizationCountry: "Australia",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f4513307d581118c453202b",
        userName: "cffdc v cvcv",
        customUrl: "cffdcv cvcv",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/08/2020",
    lastLogin: "25/08/2020",
    customUrl: "compvfcc",
  },
  {
    userId: null,
    organizationName: "sddsds",
    status: "COMPLETED",
    email: "sds@yopmail.com",
    phoneNo: 2.2332323e7,
    organizationCountry: "Azerbaijan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f4791625250a50e7b16e866",
        userName: "fddfdf dfdfd",
        customUrl: "fddfdfdfdfd",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "27/08/2020",
    lastLogin: "27/08/2020",
    customUrl: "sddsds",
  },
  {
    userId: null,
    organizationName: "dfdfdfdf",
    status: "COMPLETED",
    email: "ssz@yopmail.com",
    phoneNo: 1.2121212e7,
    organizationCountry: "Armenia",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f4791c95250a50e7b16e868",
        userName: "dxsdzsd dsdsd",
        customUrl: "dxsdzsddsdsd",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "27/08/2020",
    lastLogin: "27/08/2020",
    customUrl: "dfdfdfdf",
  },
  {
    userId: null,
    organizationName: "jp company",
    status: "COMPLETED",
    email: "jp@yopmail.com",
    phoneNo: 9.555855558e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f575c8b442bcf59ec8ee5f8",
        userName: "jp company",
        customUrl: "jpcompany",
      },
    ],
    noOfEmployees: 2.0,
    employeeDto: [
      {
        id: "5f577385442bcf59ec8ee604",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5f479d6f5250a50e7b16e86b",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599566725353e12,
        lastUpdate: 1.599566725353e12,
      },
      {
        id: "5f58a9488488da5f33cf1054",
        userId: "5ecbd038811990408de96492",
        companyId: "5f479d6f5250a50e7b16e86b",
        status: "REQUEST_ACCEPTED",
        createTime: 1.599646024237e12,
        lastUpdate: 1.599646024237e12,
      },
    ],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "27/08/2020",
    lastLogin: "27/08/2020",
    customUrl: "jp company",
  },
  {
    userId: null,
    organizationName: "jp company",
    status: "COMPLETED",
    email: "jpd@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 2.0,
    employeeDto: [
      {
        id: "5f58a83a8488da5f33cf1050",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5f479dd45250a50e7b16e86c",
        status: "REQUEST_SENT",
        createTime: 1.599645754622e12,
        lastUpdate: 1.599645754622e12,
      },
      {
        id: "5f58a9228488da5f33cf1053",
        userId: "5ecbd038811990408de96492",
        companyId: "5f479dd45250a50e7b16e86c",
        status: "REQUEST_SENT",
        createTime: 1.599645986288e12,
        lastUpdate: 1.599645986288e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "27/08/2020",
    lastLogin: "27/08/2020",
    customUrl: "jp company1",
  },
  {
    userId: null,
    organizationName:
      "Commanders Commanders Commanders Commanders Commanders Commanders Commanders Commanders Commanders C",
    status: "COMPLETED",
    email: "cock@yopmail.com",
    phoneNo: 8.817855886e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "606edc7dd633023a11523467",
        userName: "company complete",
        customUrl: "companycomplete",
      },
    ],
    noOfEmployees: 2.0,
    employeeDto: [
      {
        id: "5f57725c442bcf59ec8ee602",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5f479e8c5250a50e7b16e86d",
        status: "REQUEST_SENT",
        createTime: 1.59956642818e12,
        lastUpdate: 1.59956642818e12,
      },
      {
        id: "5fcdef27db1bad7b3692123a",
        userId: "5fcdd673db1bad7b3692122a",
        companyId: "5f479e8c5250a50e7b16e86d",
        status: "REQUEST_SENT",
        createTime: 1.607331623758e12,
        lastUpdate: 1.607331623758e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "27/08/2020",
    lastLogin: "27/08/2020",
    customUrl: "commenders",
  },
  {
    userId: null,
    organizationName: "Neo Leo",
    status: "COMPLETED",
    email: "neo@gmail.com",
    phoneNo: 0.0,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/09/2020",
    lastLogin: "04/09/2020",
    customUrl: "neo leo",
  },
  {
    userId: null,
    organizationName: "dsdsssdds",
    status: "COMPLETED",
    email: "er@yopmail.com",
    phoneNo: 4.433333444e9,
    organizationCountry: "Bahrain",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f523a484ddde722f02e0796",
        userName: "zzzsszd dsfdssdsd",
        customUrl: "zzzsszddsfdssdsd",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/09/2020",
    lastLogin: "04/09/2020",
    customUrl: "meenapallabi",
  },
  {
    userId: null,
    organizationName: "company asssd",
    status: "COMPLETED",
    email: "cmp@tft.com",
    phoneNo: 3.332234344e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f523a9e4ddde722f02e0798",
        userName: "Pallabi Das",
        customUrl: "pallabidas12",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f523a7f4ddde722f02e0797",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "04/09/2020",
    lastLogin: "04/09/2020",
    customUrl: "company asssd",
  },
  {
    userId: null,
    organizationName: "dxsddsds",
    status: "COMPLETED",
    email: "fdddss@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "07/09/2020",
    lastLogin: "07/09/2020",
    customUrl: "dxsddsds",
  },
  {
    userId: null,
    organizationName: "xdxd",
    status: "COMPLETED",
    email: "we@yopmail.com",
    phoneNo: 8.884333333e9,
    organizationCountry: "Austria",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f55da80442bcf59ec8ee5ce",
        userName: "cffffdfddf cxdxdxccx",
        customUrl: "cffffdfddfcxdxdxccx",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "07/09/2020",
    lastLogin: "07/09/2020",
    customUrl: "xdxd",
  },
  {
    userId: null,
    organizationName: "sxsx",
    status: "COMPLETED",
    email: "xs@y.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "sxsx",
  },
  {
    userId: null,
    organizationName: "ss`cs",
    status: "COMPLETED",
    email: "cdc@g.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "ss`cs",
  },
  {
    userId: null,
    organizationName: "dwd",
    status: "COMPLETED",
    email: "js@j.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "dwd",
  },
  {
    userId: null,
    organizationName: "dsjdfj",
    status: "COMPLETED",
    email: "sdjjs@g.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "dsjdfj",
  },
  {
    userId: null,
    organizationName: "ccc",
    status: "COMPLETED",
    email: "ccc@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "ccc",
  },
  {
    userId: null,
    organizationName: "Company",
    status: "COMPLETED",
    email: "company888@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f575936442bcf59ec8ee5f4",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "company12",
  },
  {
    userId: null,
    organizationName: "cmpany",
    status: "COMPLETED",
    email: "cmp32@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "cmpany",
  },
  {
    userId: null,
    organizationName: "copm",
    status: "COMPLETED",
    email: "comp12@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "copm",
  },
  {
    userId: null,
    organizationName: "business",
    status: "COMPLETED",
    email: "busi@yopmail.com",
    phoneNo: 7.123456785e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7c175ffcffaf3e5e0f2820",
        userName: "First name Last",
        customUrl: "firstnamelast",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f5766bf442bcf59ec8ee5ff",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "08/09/2020",
    lastLogin: "08/09/2020",
    customUrl: "business",
  },
  {
    userId: null,
    organizationName: "Jd company",
    status: "COMPLETED",
    email: "jd@yopmail.com",
    phoneNo: 9.874445555e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5fb3fb583e4436513a4201b2",
        userName: "Jd comp",
        customUrl: "jdcomp",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "20/09/2020",
    lastLogin: "20/09/2020",
    customUrl: "jd company",
  },
  {
    userId: null,
    organizationName: "testing",
    status: "COMPLETED",
    email: "sdfsdf@gmail.com",
    phoneNo: 2.025559977e9,
    organizationCountry: "United States of America",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "609a195373cec75680a30ca6",
        userName: "newly born",
        customUrl: "newlyborn",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "21/09/2020",
    lastLogin: "21/09/2020",
    customUrl: "testing2",
  },
  {
    userId: null,
    organizationName: "asdd",
    status: "COMPLETED",
    email: "praveen1233@wsegames.com",
    phoneNo: 7.279999999e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f699eb1c5b97c10bc954b36",
        userName: "Durga prasad",
        customUrl: "durgaprasad",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f699e54c5b97c10bc954b34",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "22/09/2020",
    lastLogin: "22/09/2020",
    customUrl: "asdd",
  },
  {
    userId: null,
    organizationName: "DG COMPANY",
    status: "COMPLETED",
    email: "dgtestcomp@yopmail.com",
    phoneNo: 9.99e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f6ad5e204a21431b903416f",
        userName: "Durga prasad",
        customUrl: "durgaprasad1",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f6b455a04a21431b9034178",
        userId: "5ee362797ebf4c287157fa73",
        companyId: "5f6ad5c204a21431b903416e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.600865626271e12,
        lastUpdate: 1.600865626271e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "23/09/2020",
    lastLogin: "23/09/2020",
    customUrl: "dg company",
  },
  {
    userId: null,
    organizationName: "testing",
    status: "COMPLETED",
    email: "testing@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "23/09/2020",
    lastLogin: "23/09/2020",
    customUrl: "testing3",
  },
  {
    userId: null,
    organizationName: "Tom Jerry",
    status: "COMPLETED",
    email: "todaybusiness@mail.com",
    phoneNo: 9.877655568e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f6c890627e68031f998a301",
        userName: "Durga prasad",
        customUrl: "durgaprasad2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 3.0,
    followers: [
      {
        userId: "5f6c88c827e68031f998a300",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6c88c827e68031f998a300",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6c88c827e68031f998a300",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "24/09/2020",
    lastLogin: "24/09/2020",
    customUrl: "today business",
  },
  {
    userId: null,
    organizationName: "pokemon",
    status: "COMPLETED",
    email: "pokemon@yopmail.com",
    phoneNo: 9.555885858e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f73065179abed0428f41104",
        userName: "doremon company",
        customUrl: "doremoncompany",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f9f983e852704367f34dd8e",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f6c91a8f399cf53b868865d",
        status: "REQUEST_ACCEPTED",
        createTime: 1.604294718001e12,
        lastUpdate: 1.604294718001e12,
      },
    ],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f6c91a8f399cf53b868865d",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 7.0,
    createdOn: "24/09/2020",
    lastLogin: "24/09/2020",
    customUrl: "pokemon",
  },
  {
    userId: null,
    organizationName: "new company",
    status: "DRAFT",
    email: "newcompf@yopmail.com",
    phoneNo: 8.998889898e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f6da692843c196c1fc321fa",
        userName: "Newcomp fr edit",
        customUrl: "newcompfr edit",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "new company",
  },
  {
    userId: null,
    organizationName: "geo company",
    status: "COMPLETED",
    email: "geocom@yopmail.com",
    phoneNo: 7.405850931e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f6da92e843c196c1fc321fe",
        userName: "geo company",
        customUrl: "geocompany",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 4.0,
    followers: [
      {
        userId: "5f6da848843c196c1fc321fd",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6da848843c196c1fc321fd",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6da848843c196c1fc321fd",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6da848843c196c1fc321fd",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 1.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "geo company",
  },
  {
    userId: null,
    organizationName: "wipro",
    status: "DRAFT",
    email: "wipro@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "wipro",
  },
  {
    userId: null,
    organizationName: "joinkoro",
    status: "COMPLETED",
    email: "joinkaro@yopmail.com",
    phoneNo: 9.855885555e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f6e1b87faaf6d4ce0cc2f8d",
        userName: "join karo",
        customUrl: "joinkaro",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "joinkoro",
  },
  {
    userId: null,
    organizationName: "address",
    status: "COMPLETED",
    email: "address@yopmail.com",
    phoneNo: 8.756525612e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f6e2f56faaf6d4ce0cc2f9d",
        userName: "address update",
        customUrl: "addressupdate",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f6e2eaffaaf6d4ce0cc2f9b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "address",
  },
  {
    userId: null,
    organizationName: "jhbk,m.iluinnubju",
    status: "COMPLETED",
    email: "mobie2@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "jhbk,m.iluinnubju",
  },
  {
    userId: null,
    organizationName: "34rtetrret",
    status: "COMPLETED",
    email: "wrwerewr@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "60b75942574c4e50c47adf89",
        userName: "Kundan Kumar",
        customUrl: "kundankumar",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 5.0,
    noOfFollowers: 7.0,
    followers: [
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f6e4e560ee2d51b8831261b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 1.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "34rtetrret",
  },
  {
    userId: null,
    organizationName: "sdfsdf",
    status: "COMPLETED",
    email: "fdsf@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "25/09/2020",
    lastLogin: "25/09/2020",
    customUrl: "sdfsdf",
  },
  {
    userId: null,
    organizationName: "wqeqwqweqwe",
    status: "COMPLETED",
    email: "dgcompany@yopmail.com",
    phoneNo: 9.999999997e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7193ef0ee2d51b88312636",
        userName: "dsfdsfdsf dsfdsfdsf",
        customUrl: "dsfdsfdsfdsfdsfdsf",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "28/09/2020",
    lastLogin: "28/09/2020",
    customUrl: "wqeqwqweqwe",
  },
  {
    userId: null,
    organizationName: "Company geo",
    status: "COMPLETED",
    email: "comgeo@yopmail.com",
    phoneNo: 6.767687898e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f71a99f0ee2d51b88312642",
        userName: "Company  Forgeo",
        customUrl: "company forgeo",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "28/09/2020",
    lastLogin: "28/09/2020",
    customUrl: "company geo",
  },
  {
    userId: null,
    organizationName: "durga pooja",
    status: "COMPLETED",
    email: "durga@yopmail.comm",
    phoneNo: 6.711122225e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5fb76d04e4567c6c78026a11",
        userName: "durga pooj",
        customUrl: "durgapooj",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5fb7718992cc8e28ddf483e6",
        userId: "5f91f3baf25cf8231f123818",
        companyId: "5f7200a2bb4350191f60b4da",
        status: "REQUEST_ACCEPTED",
        createTime: 1.605857673282e12,
        lastUpdate: 1.605857673282e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7200a2bb4350191f60b4da",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "28/09/2020",
    lastLogin: "28/09/2020",
    customUrl: "durga pooja",
  },
  {
    userId: null,
    organizationName: "footer company",
    status: "COMPLETED",
    email: "footercom@yomail.com",
    phoneNo: 9.855525555e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f721127bb4350191f60b4e0",
        userName: "footer company",
        customUrl: "footercompany",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "28/09/2020",
    lastLogin: "28/09/2020",
    customUrl: "footer company",
  },
  {
    userId: null,
    organizationName: "company footer",
    status: "COMPLETED",
    email: "comfooter9@yopmail.com",
    phoneNo: 9.905522555e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f721daabb4350191f60b4e9",
        userName: "compnay footer",
        customUrl: "compnayfooter",
      },
    ],
    noOfEmployees: 3.0,
    employeeDto: [
      {
        id: "5f89557d0f0a3a28c57b474b",
        userId: "5ecbb1f227e66c5ada4557c6",
        companyId: "5f721d7cbb4350191f60b4e7",
        status: "REQUEST_SENT",
        createTime: 1.602835837893e12,
        lastUpdate: 1.602835837893e12,
      },
      {
        id: "5f8957140f0a3a28c57b474d",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f721d7cbb4350191f60b4e7",
        status: "REQUEST_SENT",
        createTime: 1.602836244327e12,
        lastUpdate: 1.602836244327e12,
      },
      {
        id: "5fb3fba83e4436513a4201b5",
        userId: "5f67789c4e5b2267fe2c488e",
        companyId: "5f721d7cbb4350191f60b4e7",
        status: "REQUEST_SENT",
        createTime: 1.605630888249e12,
        lastUpdate: 1.605630888249e12,
      },
    ],
    employees: null,
    noOfPosts: 2.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "28/09/2020",
    lastLogin: "28/09/2020",
    customUrl: "company footer",
  },
  {
    userId: null,
    organizationName: "Test Comp",
    status: "COMPLETED",
    email: "testcompany1@yopmail.com",
    phoneNo: 9.222222222e9,
    organizationCountry: "Algeria",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f72ccaadc661e24b7c5f450",
        userName: "Harsh Tibrewal",
        customUrl: "harshtibrewal4",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f72cc86dc661e24b7c5f44f",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "test comp",
  },
  {
    userId: null,
    organizationName: "QA company",
    status: "COMPLETED",
    email: "qacompany@yopmail.com",
    phoneNo: 9.123321321e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f72d13460c36e641b3d834c",
        userName: "Harsh Tibrewal",
        customUrl: "harshtibrewal5",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f72d11a60c36e641b3d834b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "qacompany",
  },
  {
    userId: null,
    organizationName: "company",
    status: "COMPLETED",
    email: "cm@yopmail.com",
    phoneNo: 8.989789809e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f72d90560c36e641b3d8355",
        userName: "Palla Daa",
        customUrl: "palladaa",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "company1",
  },
  {
    userId: null,
    organizationName: "test company : new",
    status: "COMPLETED",
    email: "new00@yopmail.com",
    phoneNo: 8.987890987e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f72daff60c36e641b3d8358",
        userName: "Palla Daa",
        customUrl: "palladaa1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "testcompanynew",
  },
  {
    userId: null,
    organizationName: "Test company - new$editi",
    status: "COMPLETED",
    email: "editnew@yopmail.com",
    phoneNo: 9.090900909e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f72db9560c36e641b3d835b",
        userName: "Test new compff fddf",
        customUrl: "testnewcompfffddf",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f72db5c60c36e641b3d8359",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "testcompanynewediti",
  },
  {
    userId: null,
    organizationName: "Test QA",
    status: "COMPLETED",
    email: "testqa@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f72e89779abed0428f410f8",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "testqa",
  },
  {
    userId: null,
    organizationName: "hsjd",
    status: "COMPLETED",
    email: "hd@jdks.com",
    phoneNo: 5.444444444e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7305e279abed0428f41101",
        userName: "Durga prasad",
        customUrl: "durgaprasad3",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "hsjd",
  },
  {
    userId: null,
    organizationName: "follower",
    status: "COMPLETED",
    email: "followe@yopmail.com",
    phoneNo: 9.099900909e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f73063579abed0428f41103",
        userName: "Plv ds",
        customUrl: "plvds1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "follower",
  },
  {
    userId: null,
    organizationName: "second",
    status: "COMPLETED",
    email: "second@mail.com",
    phoneNo: 8.3102251e9,
    organizationCountry: "Benin",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f730b2279abed0428f41107",
        userName: "Plv ds",
        customUrl: "plvds2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 1.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "second",
  },
  {
    userId: null,
    organizationName: "doremon",
    status: "COMPLETED",
    email: "doremon9@yopmail.com",
    phoneNo: 9.03099e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f730bb079abed0428f41109",
        userName: "Durga prasad",
        customUrl: "durgaprasad4",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5f8adcad9a8f135ca71ef65e",
        userId: "5f742c0ce32e87106607e648",
        companyId: "5f730b8b79abed0428f41108",
        status: "REQUEST_SENT",
        createTime: 1.60293598127e12,
        lastUpdate: 1.60293598127e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 2.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "doremon3",
  },
  {
    userId: null,
    organizationName: "third",
    status: "COMPLETED",
    email: "third@mail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "third",
  },
  {
    userId: null,
    organizationName: "fourth",
    status: "COMPLETED",
    email: "fourth@mail.com",
    phoneNo: 8.31022519e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7311f779abed0428f4110c",
        userName: "Plv ds",
        customUrl: "plvds3",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7311d279abed0428f4110b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "fourth",
  },
  {
    userId: null,
    organizationName: "auto fill",
    status: "COMPLETED",
    email: "auto@yopmail.com",
    phoneNo: 9.558555885e9,
    organizationCountry: "Bahrain",
    organizationType: "COMPANY",
    noOfAdmins: 2.0,
    admin: [
      {
        userId: "5f733cda8d3ac8741f85c653",
        userName: "Ram Ram",
        customUrl: "ramram",
      },
      {
        userId: "60b75942574c4e50c47adf89",
        userName: "Kundan Kumar",
        customUrl: "kundankumar",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f731db879abed0428f4111b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 11.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "autopunch",
  },
  {
    userId: null,
    organizationName: "comp join",
    status: "COMPLETED",
    email: "cmp@yopmail.com",
    phoneNo: 8.989099999e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7329d68d3ac8741f85c649",
        userName: "Plv ds",
        customUrl: "plvds4",
      },
    ],
    noOfEmployees: 3.0,
    employeeDto: [
      {
        id: "5f89737a3b89a437c90dec97",
        userId: "5ecbb1f227e66c5ada4557c6",
        companyId: "5f73299e8d3ac8741f85c648",
        status: "REQUEST_SENT",
        createTime: 1.602843514797e12,
        lastUpdate: 1.602843514797e12,
      },
      {
        id: "5ff863e6a808de62e3d82e13",
        userId: "5ed89c5308bace4c69413451",
        companyId: "5f73299e8d3ac8741f85c648",
        status: "REQUEST_SENT",
        createTime: 1.610114022527e12,
        lastUpdate: 1.610114022527e12,
      },
      {
        id: "5ffe8b14d15dd74639380ab9",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f73299e8d3ac8741f85c648",
        status: "REQUEST_SENT",
        createTime: 1.610517268735e12,
        lastUpdate: 1.610517268735e12,
      },
    ],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f73299e8d3ac8741f85c648",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "compjoin",
  },
  {
    userId: null,
    organizationName: "busi",
    status: "COMPLETED",
    email: "busin@yopmail.com",
    phoneNo: 8.989898098e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f732ad78d3ac8741f85c64d",
        userName: "Nira Sen",
        customUrl: "nirasen1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 3.0,
    followers: [
      {
        userId: "5f732ab48d3ac8741f85c64b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f732ab48d3ac8741f85c64b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f732ab48d3ac8741f85c64b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 4.0,
    createdOn: "29/09/2020",
    lastLogin: "29/09/2020",
    customUrl: "busi",
  },
  {
    userId: null,
    organizationName: "test",
    status: "COMPLETED",
    email: "auto@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/09/2020",
    lastLogin: "30/09/2020",
    customUrl: "test",
  },
  {
    userId: null,
    organizationName: "testing560",
    status: "COMPLETED",
    email: "test567@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/09/2020",
    lastLogin: "30/09/2020",
    customUrl: "testing560",
  },
  {
    userId: null,
    organizationName: "Otp authentication ",
    status: "DRAFT",
    email: "otp9@yopmail.com",
    phoneNo: 9.030088555e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f74257ee32e87106607e645",
        userName: "iPhone  Otp",
        customUrl: "iphoneotp",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/09/2020",
    lastLogin: "30/09/2020",
    customUrl: "otpauthentication",
  },
  {
    userId: null,
    organizationName: "palao",
    status: "COMPLETED",
    email: "palao@yopmail.com",
    phoneNo: 8.697779065e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f74643be32e87106607e657",
        userName: "Palla Daa",
        customUrl: "palladaa2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/09/2020",
    lastLogin: "30/09/2020",
    customUrl: "palao",
  },
  {
    userId: null,
    organizationName: "ghghg",
    status: "DRAFT",
    email: "ffgf@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "30/09/2020",
    lastLogin: "30/09/2020",
    customUrl: "ghghg",
  },
  {
    userId: null,
    organizationName: "company signup test",
    status: "COMPLETED",
    email: "signuptest@yopmail.com",
    phoneNo: 7.405850921e9,
    organizationCountry: "United Kingdom",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f748db441197147efa360a5",
        userName: "signup london",
        customUrl: "signuplondon",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f748a15352fab72d7773076",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "30/09/2020",
    lastLogin: "30/09/2020",
    customUrl: "companysignuptest",
  },
  {
    userId: null,
    organizationName: "hjhjjhj",
    status: "COMPLETED",
    email: "ghg@gh.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7551f241197147efa360b9",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "hjhjjhj",
  },
  {
    userId: null,
    organizationName: "Company signup",
    status: "COMPLETED",
    email: "signup@yopmail.com",
    phoneNo: 7.87656789e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75938e7541567a21d9f717",
        userName: "Comp Sign",
        customUrl: "compsign",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "companysignup",
  },
  {
    userId: null,
    organizationName: "Company signup",
    status: "DRAFT",
    email: "signup@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "companysignup1",
  },
  {
    userId: null,
    organizationName: "check company",
    status: "COMPLETED",
    email: "check@yopmail.com",
    phoneNo: 9.855857122e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7594f97541567a21d9f720",
        userName: "Company account",
        customUrl: "companyaccount",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "checkcompany",
  },
  {
    userId: null,
    organizationName: "jkdfkd",
    status: "COMPLETED",
    email: "jfkdjsk@jkf.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "jkdfkd",
  },
  {
    userId: null,
    organizationName: "test comp2",
    status: "COMPLETED",
    email: "testcomp@yopmail.com",
    phoneNo: 6.135550155e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75a7cfaf4c7d5e87f97410",
        userName: "Hrahs dadassd",
        customUrl: "hrahsdadassd",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "testcomp2",
  },
  {
    userId: null,
    organizationName: "indian company",
    status: "COMPLETED",
    email: "comin@wsegames.com",
    phoneNo: 9.87456123e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75b0028f2e07270124b51a",
        userName: "business indian",
        customUrl: "businessindian",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "indiancompany",
  },
  {
    userId: null,
    organizationName: "Company Foreigner",
    status: "COMPLETED",
    email: "comfor@yopmail.com",
    phoneNo: 7.405850999e9,
    organizationCountry: "Argentina",
    organizationType: "COMPANY",
    noOfAdmins: 3.0,
    admin: [
      {
        userId: "5f75b1258f2e07270124b520",
        userName: "company foreigner",
        customUrl: "companyforeigner1",
      },
      {
        userId: "5ecb5d7327e66c5ada45579e",
        userName: "Durga Old",
        customUrl: "dp2020",
      },
      {
        userId: "5edb436e04c242330a7a3497",
        userName: "dolphin dolphin",
        customUrl: "dolphin",
      },
    ],
    noOfEmployees: 12.0,
    employeeDto: [
      {
        id: "5f801ca474dd2a33b7389f71",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.602231460916e12,
        lastUpdate: 1.602231460916e12,
      },
      {
        id: "5fa18d83435f5d54df85e823",
        userId: "5ecb5d7327e66c5ada45579e",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.604423043878e12,
        lastUpdate: 1.604423043878e12,
      },
      {
        id: "5fa2b0c4cb3afd73f8175f5d",
        userId: "5f9bbf7013dbc95ff597f2f2",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.604497604903e12,
        lastUpdate: 1.604497604903e12,
      },
      {
        id: "5fb6591b84620b356d198c52",
        userId: "5fb3a96ce719697c7cbe3f10",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.605785883775e12,
        lastUpdate: 1.605785883775e12,
      },
      {
        id: "5fba8d604bc68a73a8e0e8f7",
        userId: "5f2d32d4a4b32549c18ab185",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.606061408194e12,
        lastUpdate: 1.606061408194e12,
      },
      {
        id: "5ff457fedb0be579db2e9648",
        userId: "5ed9e8d17272215c9f7606cd",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.609848830759e12,
        lastUpdate: 1.609848830759e12,
      },
      {
        id: "5ffc01d4ef58bc12732dce81",
        userId: "5f43648eb5da123aef677e4e",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.610351060508e12,
        lastUpdate: 1.610351060508e12,
      },
      {
        id: "5ffc11b82a70d7032212a9bb",
        userId: "5efaf465b2f83a731d09d992",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.610355128355e12,
        lastUpdate: 1.610355128355e12,
      },
      {
        id: "5ffed581e42672120c4ca185",
        userId: "5fe1debcb9fdec4065cfc4bb",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.610536321754e12,
        lastUpdate: 1.610536321754e12,
      },
      {
        id: "601d4702af699e07b163949c",
        userId: "5f3f9f5e35001f3a8c8ef97d",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_ACCEPTED",
        createTime: 1.612531458765e12,
        lastUpdate: 1.612531458765e12,
      },
      {
        id: "605980c22e9b86202d70baf3",
        userId: "6052fe611f9e091f4f6c04dd",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_SENT",
        createTime: 1.616478402335e12,
        lastUpdate: 1.616478402335e12,
      },
      {
        id: "6127faed34b8221d2afe3aa9",
        userId: "6110d9bb0ef92063d7d9415a",
        companyId: "5f75b0ec8f2e07270124b51e",
        status: "REQUEST_SENT",
        createTime: 1.630010093178e12,
        lastUpdate: 1.630010093178e12,
      },
    ],
    employees: null,
    noOfPosts: 102.0,
    noOfFollowers: 11.0,
    followers: [
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f75b0ec8f2e07270124b51e",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 102.0,
    createdOn: "01/10/2020",
    lastLogin: "27/09/2021",
    customUrl: "comfor",
  },
  {
    userId: null,
    organizationName: "Ghj",
    status: "DRAFT",
    email: "Ghj@yopmail.com",
    phoneNo: 7.890786547e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75b9508f2e07270124b527",
        userName: "Fdxgjh Gfxfgg",
        customUrl: "fdxgjhgfxfgg",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "ghj",
  },
  {
    userId: null,
    organizationName: "Comfghjj",
    status: "COMPLETED",
    email: "comfore@yopmail.com",
    phoneNo: 7.123456789e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75d3f1f31767307478cc04",
        userName: "Comfore Rdtgfx",
        customUrl: "comforerdtgfx",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f75d3a7f31767307478cc02",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "comfghjj",
  },
  {
    userId: null,
    organizationName: "company",
    status: "COMPLETED",
    email: "comp90@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "company2",
  },
  {
    userId: null,
    organizationName: "fgfghg",
    status: "COMPLETED",
    email: "hjkhkj@ghj.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "fgfghg",
  },
  {
    userId: null,
    organizationName: "ffddf",
    status: "COMPLETED",
    email: "fk@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "ffddf",
  },
  {
    userId: null,
    organizationName: "hjhk",
    status: "COMPLETED",
    email: "hjk@hjj.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f75d86ff31767307478cc0e",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "hjhk",
  },
  {
    userId: null,
    organizationName: "fgfgh",
    status: "COMPLETED",
    email: "hjkhk@hjk.com",
    phoneNo: 9.876543219e9,
    organizationCountry: "Burkina Faso",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "607d0d0e3aa362564d429d1f",
        userName: "fdsf dfsdsf",
        customUrl: "fdsfdfsdsf",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "fgfgh",
  },
  {
    userId: null,
    organizationName: "cvcxxcxc",
    status: "COMPLETED",
    email: "oi@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "cvcxxcxc",
  },
  {
    userId: null,
    organizationName: "dffdsdsf",
    status: "COMPLETED",
    email: "uio@yopmail.com",
    phoneNo: 7.890765432e9,
    organizationCountry: "United Kingdom",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75dd2df31767307478cc17",
        userName: "Jolly Das",
        customUrl: "jollydas1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f75dce6f31767307478cc16",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "dffdsdsf",
  },
  {
    userId: null,
    organizationName: "companyxdxddf",
    status: "COMPLETED",
    email: "cmp90@yopmail.com",
    phoneNo: 7.890765432e9,
    organizationCountry: "United Kingdom",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75e2d6f31767307478cc1c",
        userName: "Jolly Das",
        customUrl: "jollydas2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f75de1bf31767307478cc18",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "companyxdxddf",
  },
  {
    userId: null,
    organizationName: "qqq",
    status: "DRAFT",
    email: "abc@yopmail.com",
    phoneNo: 9.225885551e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f75e37ef31767307478cc1d",
        userName: "error message",
        customUrl: "errormessage",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "qqq",
  },
  {
    userId: null,
    organizationName: "Join comp",
    status: "COMPLETED",
    email: "Joincm@yopmail.com",
    phoneNo: 7.654321234e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f760c18d25e49288904a749",
        userName: "Test Test",
        customUrl: "testtest12",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "01/10/2020",
    lastLogin: "01/10/2020",
    customUrl: "joincomp",
  },
  {
    userId: null,
    organizationName: "next",
    status: "COMPLETED",
    email: "nex@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "next",
  },
  {
    userId: null,
    organizationName: "test",
    status: "COMPLETED",
    email: "testing55@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "test1",
  },
  {
    userId: null,
    organizationName: "testt",
    status: "COMPLETED",
    email: "testing123@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f773eda33614f0a65fdc301",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "testt",
  },
  {
    userId: null,
    organizationName: "street fighter",
    status: "COMPLETED",
    email: "street@yopmail.com",
    phoneNo: 9.030990012e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f77414133614f0a65fdc303",
        userName: "Durga prasad",
        customUrl: "durgaprasad5",
      },
    ],
    noOfEmployees: 2.0,
    employeeDto: [
      {
        id: "5f89b2517de71f131632211e",
        userId: "5ecbb1f227e66c5ada4557c6",
        companyId: "5f77400a33614f0a65fdc302",
        status: "REQUEST_ACCEPTED",
        createTime: 1.602859601275e12,
        lastUpdate: 1.602859601275e12,
      },
      {
        id: "5f8ada959a8f135ca71ef65c",
        userId: "5f742c0ce32e87106607e648",
        companyId: "5f77400a33614f0a65fdc302",
        status: "REQUEST_ACCEPTED",
        createTime: 1.602935445261e12,
        lastUpdate: 1.602935445261e12,
      },
    ],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 16.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "streetfighter",
  },
  {
    userId: null,
    organizationName: "teeest",
    status: "COMPLETED",
    email: "dev123d@yopmail.com",
    phoneNo: 7.275164495e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7743b333614f0a65fdc306",
        userName: "fvv dddf",
        customUrl: "fvvdddf",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7742f233614f0a65fdc304",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 1.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "teeest",
  },
  {
    userId: null,
    organizationName: "teat",
    status: "COMPLETED",
    email: "dev12334@yopmail.com",
    phoneNo: 8.167061956e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f775a8adf03386814a614e5",
        userName: "mohitt agr",
        customUrl: "mohittagr2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "teat",
  },
  {
    userId: null,
    organizationName: "Company fdfdf",
    status: "COMPLETED",
    email: "cmpf@yopmail.com",
    phoneNo: 8.697779065e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f776459df03386814a614ea",
        userName: "Palla Daa",
        customUrl: "palladaa3",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f776427df03386814a614e9",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "companyfdfdf",
  },
  {
    userId: null,
    organizationName: "dklsld",
    status: "COMPLETED",
    email: "kdlfklds@kfldskf.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "dklsld",
  },
  {
    userId: null,
    organizationName: "test dg company",
    status: "COMPLETED",
    email: "dibakarcomp@yopmail.com",
    phoneNo: 9.030990015e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7778296dc2320379edcb6a",
        userName: "Durga prasad",
        customUrl: "durgaprasad6",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "testdgcompany",
  },
  {
    userId: null,
    organizationName: "Comp78",
    status: "COMPLETED",
    email: "comp78@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 2.0,
    followers: [
      {
        userId: "5f777f426dc2320379edcb6b",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f777f426dc2320379edcb6b",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "comp78",
  },
  {
    userId: null,
    organizationName: "Companu89",
    status: "COMPLETED",
    email: "comp901@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 2.0,
    followers: [
      {
        userId: "5f7780d96dc2320379edcb6c",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f7780d96dc2320379edcb6c",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 3.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "companu89",
  },
  {
    userId: null,
    organizationName: "country isd code",
    status: "COMPLETED",
    email: "country@yopmail.com",
    phoneNo: 9.030990045e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7781bd6dc2320379edcb6f",
        userName: "Durga prasad",
        customUrl: "durgaprasad7",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/09/2021",
    customUrl: "countryisdcode",
  },
  {
    userId: null,
    organizationName: "dfxdxfdf",
    status: "COMPLETED",
    email: "ddfda@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7781a76dc2320379edcb6e",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "dfxdxfdf",
  },
  {
    userId: null,
    organizationName: "fddfdd",
    status: "COMPLETED",
    email: "iop@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7781f76dc2320379edcb70",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "fddfdd",
  },
  {
    userId: null,
    organizationName: "fcffd",
    status: "COMPLETED",
    email: "oqqi@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7782d96dc2320379edcb71",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "fcffd",
  },
  {
    userId: null,
    organizationName: "company without operator",
    status: "COMPLETED",
    email: "wo@yopmail.com",
    phoneNo: 8.745822665e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7783b16dc2320379edcb73",
        userName: "company without",
        customUrl: "companywithout",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "companywithoutoperator",
  },
  {
    userId: null,
    organizationName: "dxfdfdddf",
    status: "COMPLETED",
    email: "klk@yopmail.com",
    phoneNo: 7.125468907e9,
    organizationCountry: "United Kingdom",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7786ef6dc2320379edcb75",
        userName: "Nira Sen",
        customUrl: "nirasen2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7786e96dc2320379edcb74",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "dxfdfdddf",
  },
  {
    userId: null,
    organizationName: "qqq",
    status: "COMPLETED",
    email: "qq@yopmail.com",
    phoneNo: 9.030990045e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f77882c6dc2320379edcb77",
        userName: "Durga prasad",
        customUrl: "durgaprasad8",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "qqq1",
  },
  {
    userId: null,
    organizationName: "qqq",
    status: "DRAFT",
    email: "fff9@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "02/10/2020",
    lastLogin: "02/10/2020",
    customUrl: "qqq2",
  },
  {
    userId: null,
    organizationName: "testing endorsement",
    status: "COMPLETED",
    email: "endorse@gmail.com",
    phoneNo: 9.030990045e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f79a1b0abd6fa37d30f74e4",
        userName: "Durga prasad",
        customUrl: "durgaprasad9",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "04/10/2020",
    lastLogin: "04/10/2020",
    customUrl: "testingendorsement",
  },
  {
    userId: null,
    organizationName: "Endorsement ",
    status: "COMPLETED",
    email: "Endorse@gmail.com",
    phoneNo: 9.866666666e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f79a529abd6fa37d30f74e6",
        userName: "Test Drop",
        customUrl: "testdrop1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f79a51fabd6fa37d30f74e5",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "04/10/2020",
    lastLogin: "04/10/2020",
    customUrl: "endorsement",
  },
  {
    userId: null,
    organizationName: "aaa",
    status: "DRAFT",
    email: "aaaa@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "aaa1",
  },
  {
    userId: null,
    organizationName: "dsadsa",
    status: "DRAFT",
    email: "adad@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "dsadsa",
  },
  {
    userId: null,
    organizationName: "Qwerty",
    status: "COMPLETED",
    email: "durga@gmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7ae16dabd6fa37d30f752e",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "qwerty",
  },
  {
    userId: null,
    organizationName: "single comp",
    status: "COMPLETED",
    email: "scom@yopamil.com",
    phoneNo: 9.475555788e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7aeb48abd6fa37d30f7534",
        userName: "single com",
        customUrl: "singlecom",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "singlecomp",
  },
  {
    userId: null,
    organizationName: "Ddfgf",
    status: "COMPLETED",
    email: "dd@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7aef21abd6fa37d30f7536",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "ddfgf",
  },
  {
    userId: null,
    organizationName: "Company",
    status: "DRAFT",
    email: "cmpo@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "company13",
  },
  {
    userId: null,
    organizationName: "Mobile company",
    status: "COMPLETED",
    email: "com9@yopmail.com",
    phoneNo: 7.844512203e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5ffc3c0d0e294e7efc88a7fc",
        userName: "Comp nine",
        customUrl: "compnine",
      },
    ],
    noOfEmployees: 1.0,
    employeeDto: [
      {
        id: "5ffd2a1660e4ac075cf45027",
        userId: "5ecb5e6727e66c5ada4557a3",
        companyId: "5f7b0468cfa0ac2799db82c1",
        status: "REQUEST_ACCEPTED",
        createTime: 1.610426902206e12,
        lastUpdate: 1.610426902206e12,
      },
    ],
    employees: null,
    noOfPosts: 14.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7b0468cfa0ac2799db82c1",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "mobilecompany",
  },
  {
    userId: null,
    organizationName: "Abc",
    status: "COMPLETED",
    email: "abv@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 3.0,
    followers: [
      {
        userId: "5f7b313d00efdd773a0cbf3d",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f7b313d00efdd773a0cbf3d",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f7b313d00efdd773a0cbf3d",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "abc",
  },
  {
    userId: null,
    organizationName: "Chm",
    status: "DRAFT",
    email: "cnm@yopmail.com",
    phoneNo: 7.890789078e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b326900efdd773a0cbf40",
        userName: "Rr Rr",
        customUrl: "rrrr",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "chm",
  },
  {
    userId: null,
    organizationName: "ffdsf",
    status: "COMPLETED",
    email: "dev222@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7b39ff00efdd773a0cbf4a",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "ffdsf",
  },
  {
    userId: null,
    organizationName: "Wer",
    status: "COMPLETED",
    email: "wer@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7b3c6800efdd773a0cbf4c",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "wer",
  },
  {
    userId: null,
    organizationName: "Rer",
    status: "COMPLETED",
    email: "rer@yopmail.com",
    phoneNo: 7.654433567e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b3d9400efdd773a0cbf4e",
        userName: "Pallabi Das",
        customUrl: "pallabidas",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7b3d4400efdd773a0cbf4d",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "rer",
  },
  {
    userId: null,
    organizationName: "hideoperator",
    status: "COMPLETED",
    email: "hide@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "hideoperator",
  },
  {
    userId: null,
    organizationName: "hide op",
    status: "COMPLETED",
    email: "op@yopmail.com",
    phoneNo: 6.678922244e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b496200efdd773a0cbf51",
        userName: "hide op",
        customUrl: "hideop1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "hideop",
  },
  {
    userId: null,
    organizationName: "hide2",
    status: "COMPLETED",
    email: "hide2@yopmail.com",
    phoneNo: 9.030990045e9,
    organizationCountry: "Albania",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b4b7b00efdd773a0cbf55",
        userName: "Durga prasad",
        customUrl: "durgaprasad12",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 1.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "hide2",
  },
  {
    userId: null,
    organizationName: "hfs",
    status: "COMPLETED",
    email: "jeed@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "hfs",
  },
  {
    userId: null,
    organizationName: "acp",
    status: "COMPLETED",
    email: "acp@yopmail.com",
    phoneNo: 1.963477982e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b58a9fcffaf3e5e0f2801",
        userName: "ac pp",
        customUrl: "acpp",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "acp",
  },
  {
    userId: null,
    organizationName: "oppassword",
    status: "COMPLETED",
    email: "oppo@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "oppassword",
  },
  {
    userId: null,
    organizationName: "imranNew",
    status: "COMPLETED",
    email: "imran@uu.com",
    phoneNo: 4.444444448e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b60abfcffaf3e5e0f2806",
        userName: "Imran New",
        customUrl: "imrannew1",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "imrannew",
  },
  {
    userId: null,
    organizationName: "omp",
    status: "COMPLETED",
    email: "omp@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7b6181fcffaf3e5e0f2808",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "omp",
  },
  {
    userId: null,
    organizationName: "lion Jio",
    status: "COMPLETED",
    email: "lion@jj.com",
    phoneNo: 4.165550135e9,
    organizationCountry: "Canada",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7b6331fcffaf3e5e0f280b",
        userName: "Lion New",
        customUrl: "lionnew",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "lionjio",
  },
  {
    userId: null,
    organizationName: "password",
    status: "COMPLETED",
    email: "pass@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "05/10/2020",
    lastLogin: "05/10/2020",
    customUrl: "password",
  },
  {
    userId: null,
    organizationName: "dsjk",
    status: "COMPLETED",
    email: "jdksj@jk.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "dsjk",
  },
  {
    userId: null,
    organizationName: "hdjsh",
    status: "COMPLETED",
    email: "hsj@jdk.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "hdjsh",
  },
  {
    userId: null,
    organizationName: "JoinHello",
    status: "COMPLETED",
    email: "join@we.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "joinhello",
  },
  {
    userId: null,
    organizationName: "Company yui",
    status: "COMPLETED",
    email: "cmp12@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7c0e8cfcffaf3e5e0f281e",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "companyyui1",
  },
  {
    userId: null,
    organizationName: "ompany on",
    status: "COMPLETED",
    email: "on@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7c1a04fcffaf3e5e0f2822",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "ompanyon",
  },
  {
    userId: null,
    organizationName: "Company",
    status: "COMPLETED",
    email: "compt@yopmail.com",
    phoneNo: 7.124563589e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7c2bff8fe86e07b3424ea8",
        userName: "Ffgh Fghjj",
        customUrl: "ffghfghjj",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7c2bba8fe86e07b3424ea7",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "company4",
  },
  {
    userId: null,
    organizationName: "Cmpu",
    status: "COMPLETED",
    email: "cmpu@yopmail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 3.0,
    followers: [
      {
        userId: "5f7c2c2c8fe86e07b3424eaa",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f7c2c2c8fe86e07b3424eaa",
        userName: null,
        customUrl: null,
      },
      {
        userId: "5f7c2c2c8fe86e07b3424eaa",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "cmpu",
  },
  {
    userId: null,
    organizationName: "firt company",
    status: "COMPLETED",
    email: "firstcompant@yopmail.com",
    phoneNo: 8.52252546e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7c310044d0e26086193ca6",
        userName: "first com",
        customUrl: "firstcom",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "firtcompany",
  },
  {
    userId: null,
    organizationName: "sdad",
    status: "COMPLETED",
    email: "asdsa@mail.com",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "sdad",
  },
  {
    userId: null,
    organizationName: "ssff",
    status: "COMPLETED",
    email: "aaa@yopmail.om",
    phoneNo: 0.0,
    organizationCountry: null,
    organizationType: "COMPANY",
    noOfAdmins: 0.0,
    admin: [],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7c4432be71c14263c368a1",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "ssff",
  },
  {
    userId: null,
    organizationName: "join",
    status: "COMPLETED",
    email: "joe@yopmail.com",
    phoneNo: 7.458554122e9,
    organizationCountry: "India",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7c65a768e5e04c315a7169",
        userName: "jo ws",
        customUrl: "jows",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 1.0,
    followers: [
      {
        userId: "5f7c656568e5e04c315a7168",
        userName: null,
        customUrl: null,
      },
    ],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "join",
  },
  {
    userId: null,
    organizationName: "Company durga",
    status: "DRAFT",
    email: "ducom@yopmail.com",
    phoneNo: 7.123456874e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7c687668e5e04c315a716c",
        userName: "Ducom Cmp",
        customUrl: "ducomcmp",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "companydurga",
  },
  {
    userId: null,
    organizationName: "Durcom",
    status: "COMPLETED",
    email: "durga786@yopmail.com",
    phoneNo: 7.123562356e9,
    organizationCountry: "Afghanistan",
    organizationType: "COMPANY",
    noOfAdmins: 1.0,
    admin: [
      {
        userId: "5f7c6a7568e5e04c315a7170",
        userName: "Dur Com",
        customUrl: "durcom2",
      },
    ],
    noOfEmployees: 0.0,
    employeeDto: [],
    employees: null,
    noOfPosts: 0.0,
    noOfFollowers: 0.0,
    followers: [],
    noOfProjects: 0.0,
    createdOn: "06/10/2020",
    lastLogin: "06/10/2020",
    customUrl: "durcom1",
  },
];
