export const data = [
  {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637658805715,
    "trackingId" : "pay_IOzXBL876r7eNC",
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "SUCCESS",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "SUCCESS"
  }, {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637658886946,
    "trackingId" : "pay_IOzZDYf5AgzoET",
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "SUCCESS",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "SUCCESS"
  }, {
    "userId" : "6116059bee5d686c151ca08a",
    "username" : "Local Account",
    "time" : 1637658950827,
    "trackingId" : "pay_IOzZj5ggP5lsgz",
    "contactNo" : 9988776655,
    "email" : "lacc@yopmail.com",
    "amount" : 5000,
    "status" : "SUCCESS",
    "gateway" : "RAZOR_PAY",
    "settleStatus" : "SUCCESS"
  }
];
