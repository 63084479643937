import React from "react";
import { Link } from "react-router-dom";

// This is the table constant/settings which needed to render table elements
export const tableStudioConstants = (handleEdit) => {
    return [
        {
            id: 1,
            name: "Post Title",
            cell: (row) => {
                return (
                  <a href={`${process.env.REACT_APP_frontEndURL}/studio/${row.slug}`} target="_blank">
                    <span className="sub">{row.title}</span>
                  </a>
                );
            },

            conditionalCellStyles: [
                {
                    when: row => row.flagged,
                    classNames: ['emailUnverified'],
                }
            ],
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },


        {
            id: 2,
            name: "Project Name",
            cell: (row) => row.entityId,
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 3,
            name: "Views",
            cell: (row) => {
                return (
                  <Link to={`/studio/view/${row.id}`}>{row.viewCount}</Link>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 4,
            name: "Click on Pay now",
            cell: (row) => {
                return (
                  <Link to={`/studio/click/${row.id}`}>{row.clickCount}</Link>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 6,
            name: "Paid View",
            cell: (row) => {
                return (
                  <Link to={`/studio/paidview/${row.id}`}>{row.paidViewCount}</Link>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 7,
            name: "Amount",
            cell: (row) => row.amount,
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 8,
            name: "Status",
            cell: (row) => {
                return (
                  row.status !== "ENABLE" ? "Draft" : "Publish"
                );
            },
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 9,
            name: "Content Type",
            cell: (row) => {
                return (
                  row.publishionForFree ? "Free" : "Paid"
                );
            },
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 10,
            name: "Flag Status",
            cell: (row) => {
                return (
                  row.flagged ? "Yes" : "No"
                );
            },
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 11,
            name: "Active",
            cell: (row) => {
                return (
                  <Link to={`/studio/subscriber/${row.id}`}>Subscribers</Link>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },        
    ];
};
