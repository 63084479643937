import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { tableBelongHereConstants } from './BelongHere/tableBelongHereConstants';
import { tableInspiringConstants } from './InspiringStories/tableInspiringConstants';
import { tableCommonManagement } from './tableCommonManagement';
import { tableTrendingContentConstants } from './TrendingContent/tableTrendingContentConstants';
import { customStyles } from '../../../utils/tableCustomStyle';
import { FilterComponent } from '../../../utils/filter';
import PreLoginMenuList from './PreLoginMenuList';
import axios from 'axios';
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { confirmDeleteModalSwal, epochTogmt, utcTodate } from '../../../utils/common';
import { tableCategoryConstant } from '../PostLogin/Category/tableCategoryConstant';
import { tableWouldLikeConstant } from '../PostLogin/WoulLike/tableWouldLikeConstant';
import { tableVisitorUserList } from '../PostLogin/WoulLike/tableVisitorUserList';
import { CSVLink } from 'react-csv';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Button from '../../../components/Buttons/button';
import { DateRange } from 'react-date-range';

const MySwal = withReactContent(alertPopUp);

const headers = [
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Country Code", key: "countryCode" },
    { label: "Mobile", key: "mobile" },
    { label: "Country", key: "country" },
    { label: "Type", key: "type" },
    { label: "Created On", key: "date" },
    { label: "Registered On", key: "createdDate" }
];

const wouldLikeHeaders = [
    { label: "Selected I am", key: "category" },
    { label: "Selected I would", key: "belongTitle" },
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Country Code", key: "countryCode" },
    { label: "Mobile", key: "mobile" },
    { label: "Country", key: "country" },
    { label: "Type", key: "type" },
    { label: "Created On", key: "date" },
    { label: "Registered On", key: "createdDate" }
];

class CommonPreloginList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            wouldLikeDataList: [],
            loading: false,
            totalRows: 0,
            perPage: 10,
            filterText: "",
            filterTextWouldLike: "",
            pageType: props.type,
            exportUserList: [],
            fileName: "",
            date: { startDate: new Date(), endDate: new Date(), key: "selection" },
            displayRange: false
        }
    }

    actionButton = () => {
        return (
            <React.Fragment>
                <PreLoginMenuList type={this.state.pageType} loginType={this.props.loginType} />
                {this.state.dataList?.length > 0 &&
                    <>
                        {(this.state.pageType === "BELONG_HERE" || this.state.pageType === "INSPIRING_STORY" || this.state.pageType === "VISITOR_USER_HISTORY") && this.getSubHeaderComponent()}
                        {this.state.pageType === "VISITOR_USER_HISTORY" && <button type="button" className="btn btn-primary" onClick={() => this.state.pageType === "WOULD_LIKE" ? this.openWouldLikeExport() : this.exportButton()}><i class="iDownloadFile" /> Export</button>}
                        {this.state.pageType === "WOULD_LIKE" && <a href="#wouldLikeListModal" className="btn addRoleGlobal ml-auto" data-toggle="modal" onClick={this.getWouldLikeData}><i class="iDownloadFile" /> Export</a>}
                    </>
                }
            </React.Fragment>
        );
    };

    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.dataList.filter(
                            (item) =>
                                item.title &&
                                item.title
                                    .toLowerCase()
                                    .includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    searchType={`Search by ${this.state.pageType === "VISITOR_USER_HISTORY" ? "username" : "title"}`}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    fetchAllDataList = async (page) => {
        let url = "";
        if (this.state.pageType === "BELONG_HERE" || this.state.pageType === "BELONG_HERE_POST") {
            url = `belong/list?page=${page}&size=${this.state.perPage}&loginType=${this.props.loginType}`;
        } else if (this.state.pageType === "INSPIRING_STORY" || this.state.pageType === "INSPIRING_STORY_POST") {
            url = `inspiringStories/list?page=${page}&size=${this.state.perPage}&loginType=${this.props.loginType}`;
        } else if (this.state.pageType === "TRENDING_CONTENT") {
            url = `trendingContent/list?page=${page}&size=${this.state.perPage}&loginType=PRE_LOGIN`;
        } else if (this.state.pageType === "CATEGORY") {
            url = `list-belong-category?page=${page}&size=${this.state.perPage}&loginType=${this.props.loginType}`;
        } else if (this.state.pageType === "WOULD_LIKE") {
            url = `list-belong-data?page=${page}&size=${this.state.perPage}&loginType=${this.props.loginType}`;
        } else if (this.state.pageType === "VISITOR_USER_HISTORY") {
            url = `userList?belongId=${this.props.categoryId}&page=${page}&size=${this.state.perPage}`;
        } else {
            url = `management/list?page=${page}&size=${this.state.perPage}&loginType=PRE_LOGIN&preLoginManagementType=${this.state.pageType}`;
        }

        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/${this.state.pageType !== "VISITOR_USER_HISTORY" ? "backend/" : ""}${(this.props.loginType === "POST_LOGIN") ? "post" : "pre"}/login/${url}`,
        })
            .then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    this.setState({
                        dataList: (this.state.pageType !== "CATEGORY" && this.state.pageType !== "WOULD_LIKE") ? response.data.body?.content : response.data?.body,
                        totalRows: response.data.body?.totalElements,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    dataList: [],
                    loading: false
                })
            });
    }

    exportButton = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/post/login/userList?belongId=${this.props.categoryId}&page=0&size=100`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let date = new Date();
                let dateMs = date.valueOf();
                const file = "visitor_user_list-" + epochTogmt(dateMs) + "-" + dateMs + ".csv";
                let exportUserList = [];
                response.data.body?.content?.map((data) => {
                    exportUserList.push({ "username": data.username, "userId": data.userId, "email": data.email, "countryCode": "+" + data.countryCode, "mobile": data.mobile, "country": data.country, "type": (data.type === "COMPANY") ? "Organization" : "Individual", "date": epochTogmt(data.date), "createdDate": epochTogmt(data.createdDate) })
                })
                this.setState({
                    exportUserList: exportUserList,
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                });
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                exportUserList: []
            })
        });
    }

    handlePageChange = async (page) => {
        this.fetchAllDataList(page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllDataList(page - 1);
        })
    };

    handleConfirmModal = (id) => {
        const handleConfirm = async () => {
            const swalval = await MySwal.fire(confirmDeleteModalSwal());
            if (swalval && swalval.value === true) {
                let url = "";
                if (this.state.pageType === "BELONG_HERE" || this.state.pageType === "BELONG_HERE_POST") {
                    url = `belong/delete?id=${id}`;
                } else if (this.state.pageType === "INSPIRING_STORY" || this.state.pageType === "INSPIRING_STORY_POST") {
                    url = `inspiringStories/delete?id=${id}`;
                } else if (this.state.pageType === "TRENDING_CONTENT") {
                    url = `trendingContent/delete?id=${id}`;
                } else if (this.state.pageType === "CATEGORY") {
                    url = `delete-belong-category?id=${id}`;
                } else if (this.state.pageType === "WOULD_LIKE") {
                    url = `delete-belong-data?id=${id}`
                } else {
                    url = `management/delete?id=${id}`;
                }
                axios({
                    method: "DELETE",
                    url: `${process.env.REACT_APP_userServiceURL}/backend/${(this.state.pageType === "INSPIRING_STORY_POST" || this.state.pageType === "CATEGORY" || this.state.pageType === "WOULD_LIKE") ? "post" : "pre"}/login/${url}`,
                    withCredentials: true,
                }).then((response) => {
                    if (response && response.data && response.data.message === "Success!") {
                        this.fetchAllDataList();
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        };

        handleConfirm();
    };

    getWouldLikeData = () => {
        let wouldLikeDataList = this.state.dataList && this.state.dataList.filter((data) => data.status !== "DRAFT");
        wouldLikeDataList && wouldLikeDataList.map((data) => data.isChecked = true);
        this.setState({
            wouldLikeDataList: wouldLikeDataList
        })
    }

    selectedWouldLikeData = () => {
        let wouldLikeDataList = this.state.wouldLikeDataList && this.state.wouldLikeDataList.filter((data) => data.isChecked);
        return wouldLikeDataList && wouldLikeDataList.map((data) => data.id);
    }

    closeWouldLikeModal = () => {
        this.setState({
            filterTextDepartment: "",
            date: { startDate: new Date(), endDate: new Date(), key: "selection" },
        });
    }

    exportWouldLike = () => {
        this.setState({
            displayRange: false
        });
        let belongIdList = this.state.wouldLikeDataList && this.state.wouldLikeDataList.filter((item) => item.isChecked);
        belongIdList = belongIdList && belongIdList.map((data) => data.id);
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/post/login/userList/specified/period?&belongId=${belongIdList}&startTime=${this.state.date.startDate.getTime()}&endTime=${this.state.date.endDate.getTime()}&page=0&size=2000`,
        }).then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                let date = new Date();
                let dateMs = date.valueOf();
                const file = "would_like_user_list-" + epochTogmt(dateMs) + "-" + dateMs + ".csv";
                let exportUserList = [];
                response.data.body?.content?.map((data) => {
                    exportUserList.push({ "category": data.category, "belongTitle": data.title, "username": data.username, "userId": data.userId, "email": data.email, "countryCode": "+" + data.countryCode, "mobile": data.mobile, "country": data.country, "type": (data.type === "COMPANY") ? "Organization" : "Individual", "date": epochTogmt(data.date), "createdDate": epochTogmt(data.createdDate) })
                })
                this.setState({
                    exportUserList: exportUserList,
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                    this.setState({
                        date: { startDate: new Date(), endDate: new Date(), key: "selection" },
                    })
                });
                document.getElementById("closeWouldLikeExportModal").click();
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.data.status === "404 NOT_FOUND") {
                document.getElementById("closeWouldLikeExportModal").click();
                MySwal.fire({
                    didOpen: () => {
                        MySwal.clickConfirm()
                    }
                }).then(() => {
                    return MySwal.fire(<p>User not found in this date range.</p>)
                })
            }
            this.setState({
                exportUserList: []
            });
        });
    }

    closeDateRange = () => {
        this.setState({
            displayRange: false
        })
    }

    displayRangeSelect = () => {
        this.setState({
            displayRange: !this.state.displayRange
        });
    };

    handleSelect = (range) => {
        this.setState({
            date: {
                ...this.state.date,
                startDate: range.selection.startDate,
                endDate: range.selection.endDate,
                key: range.selection.key,
            },
        });
    };

    componentDidMount() {
        this.fetchAllDataList(0);
    }

    render() {
        const { pageType, date } = this.state;
        const { loginType } = this.props;
        if (pageType === "VISITOR_USER_HISTORY") {
            this.filteredItems = this.state.dataList && this.state.dataList.filter(
                (item) => item.username && item.username.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        } else {
            this.filteredItems = this.state.dataList && this.state.dataList.filter(
                (item) => item.title && item.title.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        }

        this.filteredWouldLike = this.state.wouldLikeDataList && this.state.wouldLikeDataList.filter(
            (item) =>
                item.title &&
                item.title.toLowerCase().includes(this.state.filterTextWouldLike.toLowerCase())
        );

        const selectWouldLikeRowProp = {
            mode: 'checkbox',
            selected: this.selectedWouldLikeData(),
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.wouldLikeDataList && this.state.wouldLikeDataList.map((item) => {
                    if (item.id === row.id) {
                        item.isChecked = isSelect;
                    }
                })
            },
            onSelectAll: (isSelect, rows, e) => {
                this.state.wouldLikeDataList && this.state.wouldLikeDataList.map((item) => {
                    item.isChecked = isSelect;
                })
            }
        };
        return (
            <div className="col2Large menu-list" >
                <DataTable
                    columns={(pageType === "BELONG_HERE" || pageType === "BELONG_HERE_POST") ? tableBelongHereConstants(this.fetchAllDataList, this.handleConfirmModal, loginType) : pageType === "TRENDING_CONTENT" ? tableTrendingContentConstants(this.fetchAllDataList, this.handleConfirmModal) : (pageType === "INSPIRING_STORY" || pageType === "INSPIRING_STORY_POST") ? tableInspiringConstants(this.fetchAllDataList, this.handleConfirmModal, loginType) : pageType === "CATEGORY" ? tableCategoryConstant(this.fetchAllDataList, this.handleConfirmModal, pageType) : pageType === "WOULD_LIKE" ? tableWouldLikeConstant(this.fetchAllDataList, this.handleConfirmModal, pageType) : pageType === "VISITOR_USER_HISTORY" ? tableVisitorUserList() : tableCommonManagement(this.fetchAllDataList, this.handleConfirmModal, pageType)}
                    // data={this.filteredItems}
                    data={(pageType === "BELONG_HERE" || pageType === "INSPIRING_STORY" || pageType === "VISITOR_USER_HISTORY") ? this.filteredItems : this.state.dataList}
                    customStyles={customStyles}
                    actions={this.actionButton()}
                    pagination
                    progressPending={this.state.loading}
                    paginationServer
                    paginationPerPage={this.state.perPage}
                    pointerOnHover
                    fixedHeader
                    paginationTotalRows={this.state.totalRows}
                    fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                />

                <div class="modal fade dgpopup permissionMod" id="wouldLikeListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Select Would Like</h5>
                            </div>
                            <div class="modal-body">
                                <div className="tableadminpsearch role">
                                    <div className="col2Large dgtable">
                                        <BootstrapTable striped data={this.filteredWouldLike} selectRow={selectWouldLikeRowProp}>
                                            <TableHeaderColumn isKey dataField='id' ref='wouldLikeId' hidden={true}>ID</TableHeaderColumn>
                                            <TableHeaderColumn dataField='title' ref='wouldLike'>Would Like</TableHeaderColumn>
                                            <TableHeaderColumn dataField='categoryTitle' ref='category'>Category</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5 class="modal-title">Select Date Rage</h5>
                                        <Button
                                            onClick={this.displayRangeSelect}
                                            type={"range"}
                                            children={`${utcTodate(
                                                this.state.date.startDate
                                            )} to ${utcTodate(this.state.date.endDate)}`}
                                        />
                                        <Button
                                            onClick={(e) => {
                                                this.closeDateRange();
                                            }}
                                            type={"anchor"}
                                            children={`CLose`}
                                        />
                                        {this.state.displayRange &&
                                            <DateRange
                                                ranges={[date]}
                                                onChange={this.handleSelect}
                                                maxDate={new Date()}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" onClick={this.closeWouldLikeModal} id="closeWouldLikeExportModal" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-primary" onClick={this.exportWouldLike}>Export</button>
                            </div>
                        </div>
                    </div>
                </div>

                <CSVLink headers={pageType === "WOULD_LIKE" ? wouldLikeHeaders : headers} data={this.state.exportUserList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>
            </div>
        )
    }
}

export default CommonPreloginList;