import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../auth/auth_service";
import { CommonHeader } from "../../utils/commonHeader";
import CommonNotificationEmail from "./CommonNotificationEmail";

class EditEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            itemId: this.props.match.params.id
        };
    }

    render() {
        const { itemId } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/email" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <CommonNotificationEmail pageType={"EMAIL"} formType={"EDIT"} itemId={itemId} disabledUpdate={this.props.location?.state?.disabledUpdate} />
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default EditEmail;
