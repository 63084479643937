import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import styles from "./location_input.module.css";
import style from "../InputFields/form_input.module.css";

const LocationInput = (props) => {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");

  // fetch countries
  const fetchCountries = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/region/countries`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          setCountries(
            response.data.body &&
              response.data.body.map((data) => ({
                value: data,
                label: data,
              }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // fetch states according to country
  const fetchStates = (data) => {
    setCity({});
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/region/states/${data.value}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          setStates(
            response.data.body &&
              response.data.body.map((data) => ({
                value: data,
                label: data,
              }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // slelct from country dropdown
  const getCountry = (data) => {
    setState({ value: "", label: "" });
    setCity({ value: "", label: "" });
    setCountry(data);
    props.country(data.value);
    fetchStates(data);
  };

  // select states from dropdown
  const getStates = (data) => {
    setCity({ value: "", label: "" });
    setState(data);
    props.state(data.value);
    fetchCities(data);
  };

  // fetch cities
  const fetchCities = (data) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/region/cities/${data.value}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          setCities(
            response.data.body &&
              response.data.body.map((data) => ({
                value: data,
                label: data,
              }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // select city from dropdown
  const getCity = (data) => {
    setCity(data);
    props.city(data.value);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className={`form-group ${styles.locationWrap}`}>
      <label>Location {props.isMandatory && <span className="text-danger">*</span>}</label>
      <div className={`row ${styles.locationselect}`}>
        <div className={`form-group col-md-4 ${styles.selectlist}`  + (props.countryValidate ? ' error' : '')}>
          <Select
            value={country}
            placeholder={"Select Country"}
            options={countries}
            onChange={getCountry}
            styles={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = "#cfe7c7";
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? null
                    : isSelected
                    ? "#cfe7c7"
                    : isFocused
                    ? "#cfe7c7"
                    : null,
                  color: isDisabled
                    ? "#ccc"
                    : isSelected
                    ? color > 2
                      ? "#00374d"
                      : "#00374d"
                    : data.color,
                  cursor: isDisabled ? "not-allowed" : "default",

                  ":active": {
                    ...styles[":active"],
                    backgroundColor:
                      !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                  },
                };
              },
            }}
          />
          {props.countryValidate && (
              <small className={style.danger}>
                {props.countryValidate}
              </small>
            )}
        </div>
        {!props.notAvailableState && <div className={`form-group col-md-4 ${styles.selectlist}` + (props.stateValidate ? ' error' : '')}>
          <Select
            value={state}
            placeholder={"Select State"}
            options={states}
            onChange={getStates}
            styles={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = "#cfe7c7";
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? null
                    : isSelected
                    ? "#cfe7c7"
                    : isFocused
                    ? "#cfe7c7"
                    : null,
                  color: isDisabled
                    ? "#ccc"
                    : isSelected
                    ? color > 2
                      ? "#00374d"
                      : "#00374d"
                    : data.color,
                  cursor: isDisabled ? "not-allowed" : "default",

                  ":active": {
                    ...styles[":active"],
                    backgroundColor:
                      !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                  },
                };
              },
            }}
          />
          {props.stateValidate && (
              <small className={style.danger}>
                {props.stateValidate}
              </small>
            )}
        </div>}
        {!props.notAvailableCity && <div className={`form-group col-md-4 ${styles.selectlist}` + (props.cityValidate ? ' error' : '')}>
          <CreatableSelect
            placeholder={"Select City"}
            options={cities}
            onChange={getCity}
            style={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = "#cfe7c7";
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? null
                    : isSelected
                    ? "#cfe7c7"
                    : isFocused
                    ? "#cfe7c7"
                    : null,
                  color: isDisabled
                    ? "#ccc"
                    : isSelected
                    ? color > 2
                      ? "#00374d"
                      : "#00374d"
                    : data.color,
                  cursor: isDisabled ? "not-allowed" : "default",

                  ":active": {
                    ...styles[":active"],
                    backgroundColor:
                      !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                  },
                };
              },
            }}
          />
          {props.cityValidate && (
              <small className={style.danger}>
                {props.cityValidate}
              </small>
            )}
        </div>}
      </div>
    </div>
  );
};

export default LocationInput;
