import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { deletePostData } from "../Data/deletePostData";
import { tableDeletePostConstants } from "../Data/deletePostTableConstants";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styles from "../post/post.module.css";
import Select from "react-select";
import { FilterComponent } from "../../../utils/filter";
import { CSVLink } from "react-csv";
import { epochTogmt } from "../../../utils/common";

const MySwal = withReactContent(alertPopUp);

const filterOptions = [
  { value: "ALL", label: "ALL" },
  { value: "POLL", label: "POLL" },
  { value: "FORUM", label: "FORUM" },
  { value: "POST", label: "POST" },
  { value: "ASSIGNMENT", label: "ASSIGNMENT" },
  { value: "EXPEDITION", label: "EXPEDITION" },
  { value: "STORYBOOK", label: "STORYBOOK" },
  { value: "FUNDRAISE", label: "FUNDRAISE" },
  { value: "EVENT", label: "EVENT" },
  { value: "JOB", label: "JOB" },
  { value: "TRAINING", label: "TRAINING" },
  { value: "STUDIO", label: "STUDIO" },
];

let confirmModalSwal = {
  title: '<h5>Are you sure you want to reactivate this post?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "Post Type", key: "postType" },
  { label: "Auto Deleted", key: "autoDeleted"},
  { label: "Entity Deleted on", key: "reportedTime" },
  { label: "Entity Created on", key: "createTime" },
  { label: "Number of Reports", key: "reportCount" },
  { label: "Link", key: "link" }
];

export class DeletePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePost: [],
      filterData: { value: "ALL", label: "ALL" },
      filterText: "",
      loading: false,
      totalRows: 0,
      perPage: 10,
      exportDataList: [], 
      fileName: ""
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.deletePost.filter(
              (item) =>
                item.postType &&
                item.postType.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by post type'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch delete post report
  fetchDeletePost = (page, size) => {
    this.setState({
      load: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/deletedList?createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&filterType=${this.state.filterData.value}&page=${page}&size=${size}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            deletePost: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            perPage: size,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          deletePost: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchDeletePost(page-1, this.state.perPage)
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.fetchDeletePost(page-1, newPerPage);
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/reactivate?id=${id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              let deleteItemList = this.state.deletePost;
              const index = deleteItemList && deleteItemList.findIndex(data => data.id === id);
              if (index > -1) {
                deleteItemList.splice(index, 1); 
              }
              this.setState({
                deletePost: deleteItemList
              });
              //this.fetchDeletePost();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  handleFilterChange = (value) => {
    this.setState({
      filterData: value
    },() => {
      this.fetchDeletePost(0, this.state.perPage);
    })
  }

  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        <a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={() => this.exportData()}><i class="iDownloadFile" /> Export</a>
      </React.Fragment>
    );
  };

  exportData = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/deletedList?createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&filterType=${this.state.filterData.value}&page=0&size=1000`
    }).then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          let exportDataList = [];
          let data = response.data.body && response.data.body.content;
          let date = new Date();
          let dateMs = date.valueOf();
          const file = "deleted_entity_list-" + epochTogmt(dateMs) + "-" + dateMs + ".csv";
          data && data.map((item) => {
            exportDataList.push({ "postType": item.postType, "autoDeleted": item.autoDeleted ? "Yes" : "No", 
              "createTime": epochTogmt(item.createTime), "reportCount": item?.reportCount, "reportedTime": epochTogmt(item.reportedTime),
              "link": !item.mywenat ? `${process.env.REACT_APP_frontEndURL}` : `${process.env.REACT_APP_myWenatUrl}/${item.link}/${item.slug}` })
          })
          this.setState({
            exportDataList: exportDataList,
            fileName: file
          }, () => {
            this.csvLink.link.click();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          exportDataList: []
        });
      });
  }

  componentDidMount() {
    this.fetchDeletePost(0, this.state.perPage);
  }

  render() {
    const { deletePost, filterData } = this.state;
    this.filteredItems = this.state.deletePost.filter(
      (item) =>
        item.postType &&
        item.postType.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
          <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <Select
                      options={filterOptions}
                      placeholder={"Filter by status"}
                      value={filterData}
                      onChange={this.handleFilterChange}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              title={"Deleted Entity"}
              columns={tableDeletePostConstants(this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={10}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              highlightOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange} 
            />
          </div>
        </div>
        <CSVLink headers={headers} data={this.state.exportDataList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>
      </CommonHeader>
    );
  }
}

export default DeletePost;
