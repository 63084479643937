import { epochTogmt, trimDescription } from "../../../../utils/common";
import StatusDetails from "../../../../components/StatusDetails/status_details";
// This is the table constant/settings which needed to render table elements
export const tableConstants = (
  fetchAllRecievedList
) => {
  return [
    {
      id: 1,
      name: "User",
      selector: (row) => row.username,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Tracking Id",
      selector: (row) => row.trackingId,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Wenat Order Id",
      selector: (row) => row.wenatOrderId,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Date",
      selector: (row) => epochTogmt(row.time),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Custom Url",
      selector: (row) => row.customUrl,
      cell: (row) => {
        return (
            <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.customUrl} target="_blank">
            <span className="sub">{row.customUrl}</span>
            </a>
      );
      },

      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Phone No.",
      selector: (row) => row.contactNo,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Remitted to",
      selector: (row) => "--",
      sortable: true,
      reorder: true,
    },
    {
      id: 11,
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
    },
    {
      id: 12,
      name: "Gateway",
      selector: (row) => row.gateway,
      sortable: true,
      reorder: true,
    },
    {
      id: 13,
      name: "Settled Status",
      selector: (row) => row.settleStatus,
      sortable: true,
      reorder: true,
    },
  ];
};
