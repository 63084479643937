import React from "react";
import { Link } from "react-router-dom";

// This is the table constant/settings which needed to render table elements
export const tableViewClickConstants = (handleEdit) => {
    return [
        {
            id: 1,
            name: "User Name",
            cell: (row) => {
                return (
                  <a href={`${process.env.REACT_APP_frontEndURL}/profile/${row.userCustomUrl}`} target="_blank">
                    <span className="sub">{row.userName}</span>
                  </a>
                );
            },
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "User Id",
            selector: (row) => row.userId,
            sortable: true,
            reorder: true,
            grow: 2,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 3,
            name: "Persona",
            selector: (row) => row.persona,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        {
            id: 4,
            name: "Country",
            selector: (row) => row.addressDetail && row.addressDetail.country,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },      
    ];
};
