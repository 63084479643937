import React, { Component } from 'react'
import styles from "./course.module.css";
import { Link } from 'react-router-dom';
import { tableConstantsCourse } from './tableConstantsCourse';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utils/tableCustomStyle';
import axios from 'axios';
import { CommonHeader } from '../../utils/commonHeader';

class CourseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseList: [],
            loading: false,
            totalRows: 0,
            perPage: 10, 
        }
    }

    fetchAllCourseList = async (page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/course/list?page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    courseList: response.data.body?.content,
                    totalRows: response.data.body.totalElements,
                    loading: false
                });
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    handlePageChange = page => {
        this.fetchAllCourseList(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllCourseList(page-1);
        })
    };

    componentDidMount() {
        this.fetchAllCourseList(0);
    }

    render() {
        const { courseList, perPage, loading, totalRows } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="marketingTable">
                            <DataTable
                                title={"Course List"}
                                columns={tableConstantsCourse(this.fetchAllCourseList, "COURSE")}
                                data={courseList}
                                customStyles={customStyles}
                                //actions={this.actionButton()}
                                fixedHeader
                                fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                                pagination
                                paginationPerPage={perPage}
                                pointerOnHover
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
                <Link to="/course/create" className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Add Course
                </Link>
            </CommonHeader>

        )
    }
}

export default CourseList;