export const selectionListData = [
  {
    id: 1,
    value: "POSTING",
    label: "Posting",
  },
  {
    id: 2,
    value: "SHARING",
    label: "Sharing",
  },
  {
    id: 3,
    value: "PROJECT",
    label: "Project",
  },
  {
    id: 4,
    value: "EXPLORE",
    label: "Explore",
  },
  {
    id: 5,
    value: "CHATS",
    label: "Chats",
  },
  {
    id: 6,
    value: "FORUM",
    label: "Forum",
  },
  {
    id: 7,
    value: "COMPANY",
    label: "Company",
  },
  {
    id: 8,
    value: "PROFILE",
    label: "Profile",
  },
  {
    id: 9,
    value: "CommonQuestions",
    label: "Common Question",
  },
];
