import React, { Component } from "react";
import FormInput from "../../../components/InputFields/form_input";
import styles from "../AmountAndTenure/CreateAmountTenure/create_amunt_tenure.module.css";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import Select from "react-select";
import history from "../../../utils/history";
import httpService from "../../../AxiosInterceptors";
import style from "../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getSubscriptionType, subscriptionType } from "../CommonSubscriptionFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp)
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export class AmountTenureSubscriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionType: "",
            monthlyPriceINR: "",
            annuallyPriceINR: "",
            monthlyPriceUSD: "",
            annuallyPriceUSD: "",
            freeTrialFlag: false,
            freeTrialPeriod: "",
            errors: {},
            error: false,
            message: null,
            subscriptionId: ""
        };
    }

    // validate create amount
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.monthlyPriceINR) {
            formIsValid = false;
            errors["monthlyPriceINR"] = "Please enter monthly price in INR";
        }
        if (!this.state.annuallyPriceINR) {
            formIsValid = false;
            errors["annuallyPriceINR"] = "Please enter annually price in INR";
        }
        if (!this.state.monthlyPriceUSD) {
            formIsValid = false;
            errors["monthlyPriceUSD"] = "Please enter monthly price in USD";
        }
        if (!this.state.annuallyPriceUSD) {
            formIsValid = false;
            errors["annuallyPriceUSD"] = "Please enter annually price in USD";
        }
        if (!this.state.subscriptionType) {
            formIsValid = false;
            errors["subscriptionType"] = "Please enter subscription type";
        }
        if (this.state.freeTrialFlag && (!this.state.freeTrialPeriod || parseInt(this.state.freeTrialPeriod) === 0)) {
            formIsValid = false;
            errors["freeTrialPeriod"] = !this.state.freeTrialPeriod ? "Please enter free trial period" : "Trial period should be greater than 0";
        }

        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    //   price value change
    priceValueChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };

    agreeHandleChange = (event) => {
        this.setState({
            freeTrialFlag: event.target.checked
        })
    }

    subscriptionType = (type) => {
        this.setState({ subscriptionType: type });
    };

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                type: this.state.subscriptionType && this.state.subscriptionType.value,
                monthlyINR: this.state.monthlyPriceINR,
                annuallyINR: this.state.annuallyPriceINR,
                monthlyUSD: this.state.monthlyPriceUSD,
                annuallyUSD: this.state.annuallyPriceUSD,
                discount: 0,
                freeTrial: this.state.freeTrialFlag,
                trialPeriod: this.state.freeTrialPeriod
            };
            if (this.props.formType === "EDIT") {
                postData.id = this.state.subscriptionId;
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/subscription/${this.props.formType === "CREATE" ? "create" : "edit"}`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.message === "Success!"
                    ) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: false
                });
            }, 5000);
        }
    };

    fetchSubscriptionById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/subscription/get?id=${this.props.id}`,
            withCredentials: true,
        })
            .then((response) => {
                if (
                    response && response.data && response.data.body &&
                    response.data.status === "200 OK"
                ) {
                    this.setState({
                        subscriptionId: response.data.body.id,
                        subscriptionType: { value: response.data.body.type, label: getSubscriptionType(response.data.body.type) },
                        monthlyPriceINR: response.data.body.monthly.amount.inr,
                        annuallyPriceINR: response.data.body.annual.amount.inr,
                        monthlyPriceUSD: response.data.body.monthly.amount.usd,
                        annuallyPriceUSD: response.data.body.annual.amount.usd,
                        freeTrialFlag: response.data.body.freeTrial,
                        freeTrialPeriod: response.data.body.trialPeriod
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    componentDidMount() {
        if (this.props.formType === "EDIT") {
            this.fetchSubscriptionById();
        }
    }

    render() {
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="d-flex align-items-center">Subscription Type <span className="text-danger">*</span></label>
                        <Select
                            options={subscriptionType}
                            onChange={this.subscriptionType}
                            placeholder={"Start typing"}
                            closeMenuOnSelect={true}
                            filterOption={(option) => option.value !== "ALL"}
                            value={this.state.subscriptionType}
                            isDisabled={this.props.formType === "EDIT" ? true : false}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {this.state.errors["subscriptionType"] && (
                            <small className={style.danger}>
                                {this.state.errors["subscriptionType"]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.monthlyPriceINR}
                            label={"Tenure Monthly(In INR)"}
                            onChange={this.priceValueChange}
                            name={"monthlyPriceINR"}
                            error={this.state.errors["monthlyPriceINR"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.annuallyPriceINR}
                            label={"Annual Discount pay only for 10 months(In INR)"}
                            onChange={this.priceValueChange}
                            name={"annuallyPriceINR"}
                            error={this.state.errors["annuallyPriceINR"]}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.monthlyPriceUSD}
                            label={"Tenure Monthly(In USD)"}
                            onChange={this.priceValueChange}
                            name={"monthlyPriceUSD"}
                            error={this.state.errors["monthlyPriceUSD"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={this.state.annuallyPriceUSD}
                            label={"Annual Discount pay only for 10 months(In USD)"}
                            onChange={this.priceValueChange}
                            name={"annuallyPriceUSD"}
                            error={this.state.errors["annuallyPriceUSD"]}
                        />
                    </div>
                </div>

                {this.state.subscriptionType && this.state.subscriptionType !== "BRONZE" &&
                    <div className="form-group row">
                        <div className="col-md-6">
                            <div className={`form-group`} style={{ marginTop: '10px' }}>
                                <label class="switch">
                                    Free Trial
                                    <input
                                        type="checkbox"
                                        ref="freeTrialFlag"
                                        name="freeTrialFlag"
                                        onClick={(e) => {
                                            this.agreeHandleChange(e);
                                        }}
                                        checked={this.state.freeTrialFlag}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>}
                {this.state.freeTrialFlag &&
                    <div className="form-group row">
                        <div className="col-md-6">
                            <FormInput
                                group={"large"}
                                value={this.state.freeTrialPeriod}
                                label={"Free Trial Period"}
                                onChange={this.priceValueChange}
                                name={"freeTrialPeriod"}
                                error={this.state.errors["freeTrialPeriod"]}
                            />
                        </div>
                    </div>
                }

                <ButtonWrap className={`${styles.bottombutt}`}>
                    <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                </ButtonWrap>
                {this.state.error.message !== "" && this.state.error.message !== undefined &&
                    <div className={`${styles.bottombutt} alert alert-warning`}>
                        {this.state.error.message}
                    </div>
                }
            </div>
        );
    }
}

export default AmountTenureSubscriptionForm;