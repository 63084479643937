import axios from "axios";
import React, { Component } from "react";
import { tableMarketingConstants } from "./Data/tableMarketingConstants";
import DataTable from "react-data-table-component";
import "../../../Year/YearList/year.module.css";
import { customStyles } from "../../../../../utils/tableCustomStyle";
import { FilterComponent } from "../../../../../utils/filter";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../../../utils/commonHeader";
import YearMenuList from "../../../../../components/YearMenuList/year_menu_list";
import { auth_service } from "../../../../../auth/auth_service";
import { CSVLink } from "react-csv";
import { epochTogmt } from "../../../../../utils/common";

const headers = [
    { label: "User Id", key: "userId" },
    { label: "User Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Category Name", key: "categoryTitle" },
    { label: "Nominated By", key: "nominatedByUserName" },
    { label: "Outside Wenat", key: "outsidewenat" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Year", key: "year" },
    { label: "Status", key: "status" },
];

class MarketingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            marketingUserList: [],
            exportMemberList: [],
            fileName: "",
            selectedYear: this.props.match.params.year,
            filterText: "",
            filterDisplay: false,
            resetPaginationToggle: false,
            loading: false,
            totalRows: 0,
            perPage: 10,
        };
    }

    // subheader of a component
    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.marketingUserList.filter(
                            (item) =>
                                item.title &&
                                item.title
                                    .toLowerCase()
                                    .includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    searchType={'Search by title'}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    // fetch all years list
    fetchMarketingDataList = (page) => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/marketing/list?year=${this.props.match.params.year}&page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response.data.status === "200 OK") {
                this.setState({
                    marketingUserList: response.data.body?.content,
                    totalRows: response.data.body?.totalElements,
                    loading: false
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    exportMemberList = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/marketing/list?year=${this.props.match.params.year}&page=0&size=200`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let date = new Date();
                let dateMs = date.valueOf();
                const file = "markeing_member_list-"+epochTogmt(dateMs)+"-"+dateMs+".csv"
                this.setState({
                    exportMemberList: response.data.body?.content && response?.data?.body?.content?.map((data) => (
                        { "userId": data?.id, "year": data?.year, "username": data?.username, "email": data?.email, "status": data?.status, "country": data?.location?.country, "state": data?.location?.state, "city": data?.location?.district, "nominatedByUserName": data?.nominatedByUserName, "outsidewenat": data?.outsidewenat ? "Yes" : "No", "categoryTitle": data?.categoryTitle }
                    )),
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    // handle filter display
    showFilter = () => {
        this.setState({
            filterDisplay: this.state.filterDisplay ? false : true,
        });
    };

    // action button
    actionButton = () => {
        return (
            <React.Fragment>
                <YearMenuList type="marketing" selectedYear={this.state.selectedYear} />
                {<a className={"btn addRoleGlobal"} href="javascript:void(0)" onClick={this.exportMemberList}><i class="iDownloadFile" /> Export</a>}
            </React.Fragment>
        );
    };

    handlePageChange = page => {
        this.fetchMarketingDataList(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchMarketingDataList(page-1);
        })
    };

    componentDidMount() {
        this.fetchMarketingDataList(0);
    }

    render() {
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to={`/wpond/upcoming-year`} className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large menu-list">
                            <DataTable
                                columns={tableMarketingConstants(this.fetchMarketingDataList)}
                                data={this.state.marketingUserList}
                                customStyles={customStyles}
                                actions={this.actionButton()}
                                pagination
                                paginationPerPage={10}
                                pointerOnHover
                                fixedHeader
                                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                                progressPending={this.state.loading}
                                paginationServer
                                paginationTotalRows={this.state.totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>

                <CSVLink headers={headers} data={this.state.exportMemberList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

            </CommonHeader>
        );
    }
}

export default MarketingList;
