import React from "react";
import { Link } from "react-router-dom";
import WpondStatusDrop from "../../../../components/WpondStatusDropDown/wpond_status_drop";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../../utils/common";

export const tableYearConstants = (fetchAllYearsList) => {
  return [
    {
      id: 1,
      name: "Years",
      cell: (row) => {
        return (
          <Link to={`/wpond/year/edit/${row.id}`}>
            <span className="sub">{row.year}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Events",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/event`}>
            <span className="sub">{row.event}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Medias",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/media`}>
            <span className="sub">{row.media}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Draft";
          status = "DRAFT";
        }
        if (row.status === "UPCOMING") {
          content = "Upcoming";
          status = "UPCOMING";
        }
        if (row.status === "COMPLETED") {
          content = "Completed";
          status = "COMPLETED";
        }
        if (row.status === "DISABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        return (
          <WpondStatusDrop
            children={content}
            id={row.id}
            status={status}
            year={row.title}
            type={"year"}
            action={fetchAllYearsList}
            fullPermission={"WPOND_FullAccess"}
            upcomingPermission={"WPOND_WPOND_Upcoming"}
            completedPermission={"WPOND_WPOND_Complete"}
            disablePermission={"WPOND_WPOND_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
