import React from "react";
import { epochTogmt } from "../../utils/common";

export const tableConstantsFeedbackList = (handleConfirmModal, pageType) => {

  
    return ( pageType === "PROJECT_FEEDBACK" ? 
    [
        {
          id: 1,
          name: "Project Name",
          selector: (row) => row.projectName,
          sortable: true,
          width: "200px"
        },
        {
          id: 2,
          name: "Flagged by",
          selector: (row) => row.flaggedByUsername,
          sortable: true,
          width: "200px"
        },
        {
          id: 3,
          name: "Feedback written by",
          selector: (row) => row.username,
          sortable: true,
          width: "200px"
        },
        {
          id: 4,
          name: "Feedback Date",
          selector: (row) => row.feedbackDate && epochTogmt(row.feedbackDate),
          sortable: true,
          width: "200px"
          
        },
        {
            id: 5,
            name: "Flagged On",
            selector: (row) => row.flaggedOn && epochTogmt(row.flaggedOn),
            sortable: true,
            width: "200px"
            
        },
        {
          id: 6,
          name: "Status",
          selector: (row) => row.status,
          sortable: true,
          width: "200px"
          
      },
      {
        id: 6,
        name: "Action",
        width: '230px',
        cell: (row) => {
          return (
            <>
              {(row.status === "Ignored" || row.status === "-" ) && <button  className={ "btnUpdte nonwidth" } onClick={(e) => {handleConfirmModal(row.id, "HIDE")}} >Hide</button>}
              {(row.status === "Hidden" || row.status === "-" ) && <button  className={ "btnUpdte"} onClick={(e) => {handleConfirmModal(row.id, "IGNORE");}}>IGNORE</button>}
            </>
          )       
        },      
        sortable: true,
        reorder: true,
      },
        
    ] 

    : pageType === "GLOBAL_FEEDBACK" ? 

    [
      {
        id: 1,
        name: "Flagged by",
        selector: (row) => row.flaggedByUsername,
        sortable: true,
        width: "200px"
      },
      {
        id: 2,
        name: "Feedback written by",
        selector: (row) => row.username,
        sortable: true,
        width: "200px"
      },
      {
        id: 3,
        name: "Feedback Date",
        selector: (row) => row.feedbackDate && epochTogmt(row.feedbackDate),
        sortable: true,
        width: "200px"
      },
      {
        id: 4,
        name: "Flagged On",
        selector: (row) => row.flaggedOn && epochTogmt(row.flaggedOn),
        sortable: true,
        width: "200px"
        
      },
      {
        id: 5,
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        width: "200px"
          
      },
      {
        id: 6,
        name: "Action",
        width: '230px',
        cell: (row) => {
          return (
            <>
              {(row.status === "Ignored" || row.status === "-" ) && <button  className={ "btnUpdte nonwidth" } onClick={(e) => {handleConfirmModal(row.id, "HIDE")}} >Hide</button>}
              {(row.status === "Hidden" || row.status === "-" ) && <button  className={ "btnUpdte"} onClick={(e) => {handleConfirmModal(row.id, "IGNORE")}}>IGNORE</button>}
            </>
          )       
        },      
        sortable: true,
        reorder: true,
      }, 
  ] : 

  [
    {
      id: 1,
      name: "User email",
      selector: (row) => row.email,
      sortable: true,
      width: "200px"
    },
    {
      id: 2,
      name: "User name",
      selector: (row) => row.userName,
      sortable: true,
      width: "200px"
    },
    {
      id: 3,
      name: "User id",
      selector: (row) => row.userId,
      sortable: true,
      width: "200px"
    },
    {
        id: 4,
        name: "Action",
        cell: (row) => {
            return <i className={"iTrashBin"} onClick={(e) => {handleConfirmModal(row.id)}} ></i>;
        },
        sortable: true,
        reorder: true,
      },
]
    

    )
};