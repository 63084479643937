import { Link } from "react-router-dom";
import { epochTogmt, trimDescription } from "../../../../utils/common";
import styled from "styled-components";
import { getSubscriptionType } from "../../CommonSubscriptionFunction";
// This is the table constant/settings which needed to render table elements

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export const tableOtherConstants = () => {


  return [
    {
      id: 1,
      name: "Description",
      cell: (row) => {
        return (
          <Link to={`/subscription/others/edit/${row.id}`}>
            <span className="sub">{getSubscriptionType(row.type)}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Manage Permission",
      cell: (row) => {
        if (row.managePermission) {
          return 'YES';
        } else {
          return 'NO';
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Created On",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
  ];
};
