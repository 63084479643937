import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../../../../components/Buttons/button";
import FormInput from "../../../../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../../../../utils/commonHeader";
import styles from "../../../../year.module.css";
import { auth_service } from "../../../../../../../../auth/auth_service";
import UploadFiles from "../../../../../../../../components/UploadFiles/upload_files";
import history from "../../../../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../../../../components/InputFields/form_input.module.css";
import stripHtml from "string-strip-html";
import MagicUrl from "quill-magic-url";

const ErorrMsgPopUp = withReactContent(alertPopUp);


const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

export class CreateEventSpeaker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            selectedYear: this.props.match.params.year,
            eventSlug: this.props.match.params.slug,
            coverImage: null,
            name: "",
            website: '',
            content: '',
            facebook: "",
            instragram: "",
            youtube: "",
            twitter: "",
            coverImageMessage: '',
            positionSelectedValue: "SPEAKER1",
            errors: "",
        };
    }
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) {
            formIsValid = false;
            this.setState({
                coverImageMessage: "Please select cover image"
            })
        }
        if (!this.state.title || this.state.title.trim() === "") {
            formIsValid = false;
            errors["title"] = "Please enter title";
        }
        if (!this.state.content || this.state.content.trim() === "" || stripHtml(this.state.content) == '') {
            formIsValid = false;
            errors["content"] = "Please enter description";
        }
        if (this.state.content && stripHtml(this.state.content) != "" && stripHtml(this.state.content).match(/\S+/g).length > 250) {
            formIsValid = false;
            errors["content"] = "Description should be within 250 words";
        }
        if (this.state.website) {
            let valudateLink = this.state.website;
            let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                formIsValid = false;
                errors["website"] = "Please enter a valid website link";
            }
        }
        if (this.state.facebook) {
            let valudateLink = this.state.facebook;
            let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                formIsValid = false;
                errors["facebook"] = "Please enter a valid link";
            }
        }
        if (this.state.instragram) {
            let valudateLink = this.state.instragram;
            let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                formIsValid = false;
                errors["instragram"] = "Please enter a valid link";
            }
        }
        if (this.state.youtube) {
            let valudateLink = this.state.youtube;
            let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                formIsValid = false;
                errors["youtube"] = "Please enter a valid link";
            }
        }
        if (this.state.twitter) {
            let valudateLink = this.state.twitter;
            let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (urlValid == null) {
                formIsValid = false;
                errors["twitter"] = "Please enter a valid link";
            }
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    coverImageMessage: "",
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    };

    // cover image update
    updateUploadedFiles = (files) => {
        this.setState({
            coverImage: files[0],
        });
    };

    onChangeValue(value) {
        this.setState({
            positionSelectedValue: value
        })
    }

    //handle change
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart(),
        });
    };

    contentChange = (value) => {
        this.setState({ content: value });
    }


    onSubmit = () => {
        if (this.validate()) {
            const formData = new FormData();
            formData.append("image", this.state.coverImage);
            let postData = {
                year: this.state.selectedYear,
                eventSlug: this.state.eventSlug,
                name: this.state.title,
                website: this.state.website,
                content: this.state.content,
                facebook: this.state.facebook,
                instagram: this.state.instragram,
                youTube: this.state.youtube,
                twitter: this.state.twitter,
                position: (this.state.positionSelectedValue).charAt(this.state.positionSelectedValue.length - 1)
            };
            formData.append("data", JSON.stringify(postData));
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/wpondAboutEventSpeaker/create`,
                headers: { "Content-Type": "multipart/form-data" },
                data: formData,
                withCredentials: true,
            })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.status === "201 CREATED"
                    ) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
    };
    modules = {
        toolbar: [
            [{ header: "2" }, { header: "3" }, { header: "4" }, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
        ],
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            handleStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        imageCompress: {
            quality: 0.7, // default
            maxWidth: 750, // default
            maxHeight: 500, // default
            imageType: 'image/jpeg' + 'image/png', // default
        },
        magicUrl: {
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
            globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        },
    };
    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }
    render() {
        const { title, content, website, facebook, instragram, youtube, twitter } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to={`/wpond/${this.state.selectedYear}/upcoming-event/${this.state.eventSlug}/speaker`} className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <UploadFiles
                                accept=".jpg,.png,.jpeg"
                                label="Cover Image"
                                updateFilesCb={this.updateUploadedFiles}
                                imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
                            />
                            {this.state.coverImageMessage &&
                                <div className="text-center">
                                    <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                                </div>
                            }
                            <FormInput
                                group={"large"}
                                value={title}
                                label={"Speaker Name"}
                                name={'title'}
                                onChange={this.handleChange}
                                error={this.state.errors["title"]}
                            />
                            <FormInput
                                group={"large"}
                                value={website}
                                label={"Website"}
                                name={'website'}
                                className="not_mandatory"
                                onChange={this.handleChange}
                                error={this.state.errors["website"]}
                            />
                            <div>
                                <label for="01">Social Links</label>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="iSocial_FB"></i></div>
                                        </div>
                                        <input type="text" class="form-control custom-input" placeholder="Facebook" name="facebook" value={facebook} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="iSocial_Twitter"></i></div>
                                        </div>
                                        <input type="text" class="form-control custom-input" placeholder="Twitter" name="twitter" value={twitter} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="iSocial_Instagram"></i></div>
                                        </div>
                                        <input type="text" class="form-control custom-input" placeholder="Instragram" name="instragram" value={instragram} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><i class="iYouTube_Logo"></i></div>
                                        </div>
                                        <input type="text" class="form-control custom-input" placeholder="Youtube" name="youtube" value={youtube} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className={"form-group " + (this.state.errors["content"] ? 'error' : '')}>
                                <label>
                                    Content <span className="text-danger">*</span>
                                </label>
                                <div className="reactQuillWrap">
                                    <ReactQuill
                                        value={content}
                                        onChange={this.contentChange}
                                        modules={this.modules}
                                    />
                                </div>
                                {this.state.errors["content"] && (
                                    <small className={style.danger}>
                                        {this.state.errors["content"]}
                                    </small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>
                                    Position
                                </label>
                                <div className="row locationselect">
                                    <div className="col-md-3 col-sm-4">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("SPEAKER1")}>Speaker 1
                                                <input type="radio" name="radio" className={this.state.positionSelectedValue === "SPEAKER1" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("SPEAKER2")}>Speaker 2
                                                <input type="radio" name="radio" className={this.state.positionSelectedValue === "SPEAKER2" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("SPEAKER3")}>Speaker 3
                                                <input type="radio" name="radio" className={this.state.positionSelectedValue === "SPEAKER3" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("SPEAKER4")}>Speaker 4
                                                <input type="radio" name="radio" className={this.state.positionSelectedValue === "SPEAKER4" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("SPEAKER5")}>Speaker 5
                                                <input type="radio" name="radio" className={this.state.positionSelectedValue === "SPEAKER5" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("SPEAKER6")}>Speaker 6
                                                <input type="radio" name="radio" className={this.state.positionSelectedValue === "SPEAKER6" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {auth_service.getPermission("WPOND_EventSpeaker_Create", "WPOND_EventSpeaker_FullAccess") &&
                            <ButtonWrap className={`${styles.bottombutt}`}>
                                <Button children={"Submit"} onClick={this.onSubmit} />
                            </ButtonWrap>}
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default CreateEventSpeaker;
