import styled from "styled-components";
import Button from "../components/Buttons/button";
import React from "react";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const FilterComponent = ({ filterText, onFilter, onClear, searchType, maxCharacter }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder={searchType === undefined ? "Search by title" : searchType}
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
      className="searchField"
      maxLength={maxCharacter}
    />
  </>
);
