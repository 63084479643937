import StatusDrop from "../../../components/StatusDropDown/status_drop";
import { epochTogmt } from "../../../utils/common";
import {Link} from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";

export const tableDeactivateUserConstants = (userData, handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Username",
      cell: (row) => {
        return (
          <>
            <div className="sub">{row.userName}</div>
            {row.userName.trim() === "WeNaturalists Member" && <div>({row.originalUsername})</div>}
          </>
            
        );
      },
      conditionalCellStyles: [
        {
          when: row => row.userName === "WeNaturalists Member",
          style: row => ({ alignItems: "flex-start", flexDirection: "column" })
        }
      ],
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Address",
      selector: (row) => row.addressDetail,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Deactivated time",
      selector: (row) => epochTogmt(row.deactivatedTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Reason",
      cell: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Link",
      cell: (row) => {
        return (
          <a href={`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/profile/${row.customUrl}`} target="_blank">
            <span className="sub">{`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/profile/${row.customUrl}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      cell: (row) => {
        return <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Deactivated_User_Action") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.id);}} disabled={!auth_service.getPermission("Report_Deactivated_User_Action")}>
          Reactive
        </button>;
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Admin",
      selector: (row) => userData.firstName,
      sortable: true,
      reorder: true,
    },
  ];
};
