import { epochTogmt, trimDescription } from "../../../../utils/common";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import { getSubscriptionType } from "../../CommonSubscriptionFunction";

export const tableTrialDetailsConstants = () => {


  return [
    {
      id: 1,
      name: "Type",
      selector: (row) => getSubscriptionType(row.type),
      sortable: true,
      reorder: true,
    }, {
      id: 2,
      name: "Date of request",
      selector: (row) => epochTogmt(row.dateOfRequest),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Free trial started on",
      selector: (row) => epochTogmt(row.trialStartsOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Free trial ended on",
      selector: (row) => epochTogmt(row.trialPeriodEndsOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
