import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import UploadFiles from "../../../../../../components/UploadFiles/upload_files";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../../components/Buttons/button";
import FormInput from "../../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../../utils/commonHeader";
import styles from "../../year.module.css";
import { auth_service } from "../../../../../../auth/auth_service";
import history from "../../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../../components/InputFields/form_input.module.css";
import Select from "react-select";
import stripHtml from "string-strip-html";
import MagicUrl from "quill-magic-url";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export class EditJuries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: {},
      coverImage: null,
      selectedYear: this.props.match.params.year,
      name: "",
      country: "",
      states: "",
      city: "",
      description: "",
      coverImageMessage: '',
      countryList: [],
      stateList: [],
      cityList: [],
      errors: "",
    };
  }
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.name || this.state.name.trim() === "") {
      formIsValid = false;
      errors["name"] = "Please enter name";
    }
    if (!this.state.country || !this.state.country.label || !this.state.country.value) {
      formIsValid = false;
      errors["country"] = "Please enter country";
    }
    if (!this.state.states || !this.state.states.label || ! this.state.states.value) {
      formIsValid = false;
      errors["state"] = "Please enter state";
    }
    if (!this.state.city || !this.state.city.label || ! this.state.city.value) {
      formIsValid = false;
      errors["city"] = "Please enter city";
    }
    if (!this.state.description || stripHtml(this.state.description) === "") {
      formIsValid = false;
      errors["description"] = "Please enter description";
    }
    if(this.state.description && stripHtml(this.state.description) != "" && stripHtml(this.state.description).match(/\S+/g).length > 250) {
      formIsValid = false;
      errors["description"] = "Description should be within 250 words";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  // handle content change
  contentChange = (value) => {
    this.setState({ description: value });
  };

  //handle change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let postData = {
        id: this.state.editData.id,
        status: this.state.editData.status,
        name: this.state.name,
        description: this.state.description,
        country: this.state.country.value,
        state: this.state.states.value,
        city: this.state.city.value
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/wpondJury/update`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  modules = {
    toolbar: [
      [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 750, // default
      maxHeight: 500, // default
      imageType: 'image/jpeg' + 'image/png', // default
    },
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
  };

  fetchcountryList = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/region/countries`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            countryList: 
            response.data.body &&
              response.data.body.map((data) => ({
                value: data,
                label: data,
              }))
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // fetch states according to country
  fetchStates = (data) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/region/states/${data.value}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            stateList: 
            response.data.body &&
              response.data.body.map((data) => ({
                value: data,
                label: data,
              }))
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // slelct from country dropdown
  getCountry = (data) => {
    this.setState({
      states: { value: "", label: "" },
      city: { value: "", label: "" },
      country: data,   
    })
    this.fetchStates(data);
  };

  // select states from dropdown
  getStates = (data) => {
    this.setState({
      city: { value: "", label: "" },
      states: data,   
    })
    this.fetchcityList(data);
  };

  // fetch cityList
  fetchcityList = (data) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_userServiceURL}/backend/region/cities/${data.value}`,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            cityList: 
            response.data.body &&
              response.data.body.map((data) => ({
                value: data,
                label: data,
              }))
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // select city from dropdown
  getCity = (data) => {
    this.setState({
      city: data
    })
  };

  fetchJuriesById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/wpondJury/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            editData: response.data.body,
            name: response.data.body.name,
            description: response.data.body.description,
            country: {"value": response.data.body.location.country, "label": response.data.body.location.country},
            states: {"value": response.data.body.location.state, "label": response.data.body.location.state},
            city: {"value": response.data.body.location.district, "label": response.data.body.location.district}
          },() => {
            this.fetchStates(this.state.country);
            this.fetchcityList(this.state.states);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.fetchJuriesById();
    this.fetchcountryList();
  }

  render() {
    const { name, location, description } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/juries`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <UploadFiles
                accept=".jpg,.png,.jpeg"
                label="Cover Image"
                updateImage={this.state.editData.image}
                updateFilesCb={this.updateUploadedFiles}
                imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
              />
              {this.state.coverImageMessage &&
                <div className="text-center">
                  <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                </div>
              }
              <FormInput
                group={"large"}
                value={name}
                label={"Name"}
                name={"name"}
                onChange={this.handleChange}
                error={this.state.errors["name"]}
              />
              <div className={`form-group ${styles.locationWrap}`}>
                  <label>Location {<span className="text-danger">*</span>}</label>
                  <div className={`row ${styles.locationselect}`}>
                    <div className={`form-group col-md-4 ${styles.selectlist}`  + (this.state.errors["country"] ? ' error' : '')}>
                      <Select
                        value={this.state.country}
                        placeholder={"Select Country"}
                        options={this.state.countryList}
                        onChange={this.getCountry}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = "#cfe7c7";
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                ? "#cfe7c7"
                                : null,
                              color: isDisabled
                                ? "#ccc"
                                : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                              cursor: isDisabled ? "not-allowed" : "default",

                              ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                  !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                              },
                            };
                          },
                        }}
                      />
                      {this.state.errors["country"] && (
                          <small className={style.danger}>
                            {this.state.errors["country"]}
                          </small>
                        )}
                    </div>
                    <div className={`form-group col-md-4 ${styles.selectlist}` + (this.state.errors["state"] ? ' error' : '')}>
                      <Select
                        value={this.state.states}
                        placeholder={"Select State"}
                        options={this.state.stateList}
                        onChange={this.getStates}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = "#cfe7c7";
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                ? "#cfe7c7"
                                : null,
                              color: isDisabled
                                ? "#ccc"
                                : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                              cursor: isDisabled ? "not-allowed" : "default",

                              ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                  !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                              },
                            };
                          },
                        }}
                      />
                      {this.state.errors["state"] && (
                          <small className={style.danger}>
                            {this.state.errors["state"]}
                          </small>
                        )}
                    </div>
                    <div className={`form-group col-md-4 ${styles.selectlist}` + (this.state.errors["city"] ? ' error' : '')}>
                      <Select
                        value={this.state.city}
                        placeholder={"Select City"}
                        options={this.state.cityList}
                        onChange={this.getCity}
                        style={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = "#cfe7c7";
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                ? "#cfe7c7"
                                : null,
                              color: isDisabled
                                ? "#ccc"
                                : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                              cursor: isDisabled ? "not-allowed" : "default",

                              ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                  !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                              },
                            };
                          },
                        }}
                      />
                      {this.state.errors["city"] && (
                          <small className={style.danger}>
                            {this.state.errors["city"]}
                          </small>
                        )}
                    </div>
                  </div>
                </div>
              <div className={"form-group " + (this.state.errors["description"] ? 'error' : '')}>
                <label>
                  Description <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={description}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["description"] && (
                  <small className={style.danger}>
                    {this.state.errors["description"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("WPOND_Juries_Submit", "WPOND_Juries_FullAccess") &&
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Submit"} onClick={this.onSubmit} />
              </ButtonWrap>}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditJuries;
