import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../auth/auth_service";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import "./how_we_impact_category_list.module.css";
import { customStyles } from "../../utils/tableCustomStyle";
import Export from "../../components/Buttons/export_button";
import { downloadCSV } from "../../utils/export_csv";
import { FilterComponent } from "../../utils/filter";
import Button from "../../components/Buttons/button";
import styles from "./how_we_impact_category_list.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";


export class HowWeImpactCategoryList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.categoryList.filter(
              (item) =>
                item.name &&
                item.name.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all cuses list
  fetchAllCategoryList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/howWeEmpactController/getList",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            categoryList: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("HowWeImpact_ExportCSV", "HowWeImpact_FullAccess") && <Export onExport={() => downloadCSV(this.state.categoryList, "how_we_impact")} />}
      </React.Fragment>
    );
  };
  componentDidMount() {
    this.fetchAllCategoryList();
  }
  render() {
    this.filteredItems = this.state.categoryList.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="How We Impact"
                columns={tableConstants(this.fetchAllCategoryList)}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("HowWeImpact_Create", "HowWeImpact_FullAccess") &&
        <Link to="/how-we-impact/create" className={`btn ${styles.createforumbtn}`}>
          <i className="iAddCircle"></i> Create
        </Link>}
      </CommonHeader>
    );
  }
}

export default HowWeImpactCategoryList;
