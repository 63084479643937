import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import style from "./left_panel.module.css";
import MetisMenu from "@metismenu/react";
import "metismenujs/dist/metismenujs.css";
import { auth_service } from "../../auth/auth_service";
import Button from "../../components/Buttons/button";
import { Scrollbars } from 'react-custom-scrollbars';
import httpService from '../../AxiosInterceptors';

httpService.setupInterceptors();

const LeftPanel = (props) => {

  const sidebarHeading = {
    padding: "10px 5px 10px 5px",
    background: "#698F8A",
  };
  const listItem = {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#698F8A",
    fontWeight: "500",
    padding: "11px 13px 13px 16px",
    textTransform: "capitalize",
  };
  const logout = () => {
    auth_service.logout();
    window.location.href = "/";
  };

  



  const getCurrentTabPath = (loginType) => {
    if(auth_service.getPermission(`${loginType === "PRE_LOGIN" ? "Pre" : "Post"}_Login_Belong_Here_Tab`, `${loginType === "PRE_LOGIN" ? "Pre" : "Post"}_Login_Belong_Here_FullAccess`)){
      return `/${loginType === "PRE_LOGIN" ? "pre" : "post"}-login/belong-here`;
    }
    else if(auth_service.getPermission(`${loginType === "PRE_LOGIN" ? "Pre": "Post"}_Login_Inspiring_Stories_Tab`, `${loginType === "PRE_LOGIN" ? "Pre" : "Post"}_Login_Inspiring_Stories_FullAccess`)){
      return `/${loginType === "PRE_LOGIN" ? "pre" : "post"}-login/inspiring-story`;
    }

    else if(auth_service.getPermission('Pre_Login_Project_Manage_Tab', "Pre_Login_Project_Manage_FullAccess")){
      return `/pre-login/project-management`;
    }
    else if(auth_service.getPermission('Pre_Login_Fund_Raise_Tab',"Pre_Login_Fund_Raise_FullAccess")){
      return '/pre-login/fund-raise'
    }
    else if(auth_service.getPermission('Pre_Login_Studio_Tab',"Pre_Login_Studio_FullAccess")){
      return '/pre-login/studio'
    }
    else if(auth_service.getPermission('Pre_Login_Community_Tab',"Pre_Login_Community_FullAccess")){
      return '/pre-login/community'
    }
    else if(auth_service.getPermission('Pre_Login_Trending_Content_Tab',"Pre_Login_Trending_Content_FullAccess")){
      return '/pre-login/trending-content'
    }
    else if(auth_service.getPermission('Pre_Login_Trending_Content_Tab',"Pre_Login_Trending_Content_FullAccess")){
      return '/pre-login/trending-content'
    }

    else if(auth_service.getPermission('Post_Login_Category_Tab',"Post_Login_Category_FullAccess")){
      return '/post-login/category'
    }
    else if(auth_service.getPermission('Post_Login_Would_Like_Tab',"Post_Login_Would_Like_FullAccess")){
      return '/post-login/would-like'
    }
    else if(auth_service.getPermission('post-login/persona',"")){
      return '/post-login/persona'
    }

  };

  return (
    <aside className="navbar-default navbar-side">
      <Scrollbars style={{ height: '100%' }}>
        <MetisMenu toggle={false}>
          {auth_service.getPermission("Dashboard_Tab") && (
          <li className="">
            <Link to="/dashboard" activeClassName="bgcolor" exact>
              <i className="iDashboard"></i> Dashboard
            </Link>
          </li>
          )}
          {auth_service.getPermission("AD_Tab") && (
          <li>
            <Link to="#" className="has-arrow">
              <i class="iSingleUser"></i> Account Details
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("AD_Individual_Tab") && (
              <li>
                <NavLink to="/user/individual">Individual User</NavLink>
              </li>
              )}
                {auth_service.getPermission("AD_Organization_Tab") && (
              <li>
                <NavLink to="/user/organization">Organization</NavLink>
              </li>
              )}
                {auth_service.getPermission("AD_Project_Tab") && (
              <li>
                <NavLink to="/user/projects">Project</NavLink>
              </li>
              )}
              {auth_service.getPermission("AD_Circle_Tab") && (
              <li>
                <NavLink to="/user/circles">Circle</NavLink>
              </li>)}
              {auth_service.getPermission("AD_Operator_Tab") && (
              <li>
                <NavLink to="/user/operator">Operator</NavLink>
              </li>)}
              {auth_service.getPermission("AD_DraftUser_Tab") &&
                <li>
                  <Link to="/user/draft-user">Draft User</Link>
                </li>
              }
            </ul>
          </li>
          )}
          {auth_service.getPermission("Messages_Tab", "Messages_FullAccess") && (
          <li>
            <Link to={"/messages"}>
              <i className="iMesseges_F"></i> Messages
            </Link>
          </li>
          )}
          {auth_service.getPermission("Explore_Tab") && (
          <li>
            <Link to="#" className="has-arrow">
              <i className="iExplore_F"></i> Explore
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("Explore_Blogs_Tab", "Explore_Blogs_FullAccess") && (
              <li>
                <NavLink to="/blogs">Blogs</NavLink>
              </li>
              )}
              {auth_service.getPermission("Explore_Categories_Tab", "Explore_Category_FullAccess") && (
              <li>
                <NavLink to="/explore-categories">Catagories</NavLink>
              </li>
              )}
            </ul>
          </li>
          )}
          {auth_service.getPermission("People_Of_Nature_Tab") && (
          <li>
            <Link to="#" className="has-arrow">
              <i className="iProjects_F"></i> Pepole of Nature
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("People_Of_Nature_Winner_Tab", "People_Of_Nature_Winner_FullAccess") && (
              <li>
                <NavLink to="/winner">Winner</NavLink>
              </li>
              )}
                {auth_service.getPermission("People_Of_Nature_Speaker_Tab", "People_Of_Nature_Speaker_FullAccess") && (
              <li>
                <NavLink to="/speaker">Speaker</NavLink>
              </li>
              )}
            </ul>
          </li>)}
          {auth_service.getPermission("Promotions_Tab", "Promotions_FullAccess") && 
          <li>
            <Link to={"/promotion"}>
              <i className="iMegaphone"></i> Promotion
            </Link>
          </li>}
          {auth_service.getPermission("Testimonial_Tab", "Testimonial_FullAccess") && 
          <li>
            <Link to={"/testimonials"}>
              <i className="iMegaphone"></i> Testimonial
            </Link>
          </li>}  
          {auth_service.getPermission("FAQ_Tab", "FAQ_FullAccess") && (
          <li>
            <Link to={"/faq"}>
              <i className="iFAQ"></i> FAQ
            </Link>
          </li>
          )}
          {auth_service.getPermission("Causes_Tab", "Causes_FullAccess") && (
          <li className="">
            <Link to="/causes" activeClassName="bgcolor" exact>
              <i className="iCauses_F"></i> Causes
            </Link>
          </li>)}
          {auth_service.getPermission("HowWeImpact_Tab", "HowWeImpact_FullAccess") && 
          <li className="">
            <Link to="/how-we-impact" activeClassName="bgcolor" exact>
               <i class="iLogoMark" style={{fontSize: '8px', verticalAlign: '2px'}}></i> How We Impact
            </Link>
          </li>}
          {auth_service.getPermission("Studio_Tab") &&
          <li>
            <Link to="#" className="has-arrow">
              <i class="iAddProject" style={{fontSize: '16px'}}></i> Studio
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("Studio_List_Tab") &&
              <li>
                <Link to="/studio/list">List</Link>
              </li>}
              {auth_service.getPermission("Studio_ExcludeUser_Tab") && 
              <li>
                <Link to="/studio/exclude-user-list">Exclude User</Link>
              </li>}
            </ul>
          </li>}
          {auth_service.getPermission("Report_Tab") && (
          <li>
            <Link to="#" className="has-arrow">
              <i class="iBlog"></i> Reports
            </Link>
            <ul className="nav nav-second-level">
              {/* {auth_service.getPermission("Report_FlagPost_Tab") && (
              <li>
                <Link to="/flag/post">Flagged Post</Link>
              </li>)} */}
              {auth_service.getPermission("Report_Post_Tab") && 
              <li>
                <Link to="/report/post">Entity</Link>
              </li>}
              {auth_service.getPermission("Report_User_Tab") && (
              <li>
                <Link to="/report/user">User</Link>
              </li>)}
              {/* {auth_service.getPermission("Reports_Studio_Tab") &&
              <li>
                <Link to="/report/studio">Studio</Link>
              </li>} */}
              {/* {auth_service.getPermission("Report_Circle_Tab") && (
              <li>
                <Link to="/report/circle">Circle</Link>
              </li>
              )} */}
              {auth_service.getPermission("Report_Deleted_Post_Tab") && (
              <li>
                <Link to="/report/delete-post">Deleted Entity</Link>
              </li>)}
              {/* {auth_service.getPermission("Report_Deleted_Post_Tab") && (
              <li>
                <Link to="/report/delete-post" className="has-arrow">Deleted Entity</Link>
                <ul className="nav nav-third-level">
                  <li>
                    <Link to="/report/deleted-post">Post</Link>
                  </li>
                  <li>
                    <Link to="/report/deleted-poll">Poll</Link>
                  </li>
                  <li>
                    <Link to="/report/deleted-forum">Forum</Link>
                  </li>
                  <li>
                    <Link to="/report/deleted-studio">Studio</Link>
                  </li>
                </ul>
              </li>)} */}
              {auth_service.getPermission("Report_Deactivated_User_Tab") &&
              <li>
                <Link to="/report/deactivate-user">Deactivated User</Link>
              </li>}
              {/* {auth_service.getPermission("Report_Deactivated_User_Tab") &&
              <li>
                <Link to="/report/spam-user">Spam User</Link>
              </li>} */}
            </ul>
          </li>)}
          {/* <li>
            <Link to={"/generate-reports"}>
              <i className="iTask"></i> Generate Reports
            </Link>
          </li> */}
          {auth_service.getPermission("Payment_Tab") &&
          <li className="">
            <Link to="#" className="has-arrow">
              <i className="iWallet"></i> Payment
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("Payment_Received_Tab") &&
              <li>
                <NavLink to="/payment/received">Received</NavLink>
              </li>}
              {auth_service.getPermission("Payment_Remitted_Tab") &&
              <li>
                <NavLink to="/payment/remitted">Remitted</NavLink>
              </li>}
            </ul>
          </li>}
          <li className="">
            <Link to="#" className="has-arrow">
              <i className="iSheild"></i> Badge
            </Link>
            <ul className="nav nav-second-level">
              <li>
                <NavLink to="/badge-config">Configuration</NavLink>
              </li>
              <li>
                <NavLink to="/badge-user-list">User List</NavLink>
              </li>
              <li>
                <NavLink to="/user-badge-config">User Badge Config</NavLink>
              </li>
              <li>
                <NavLink to="/user-badge-list">User Badge List</NavLink>
              </li>
            </ul>
          </li>
          {auth_service.getPermission("Subscripton_Module_Tab") && (
          <li className="">
            <Link to="#" className="has-arrow">
              <i className="iSubscription"></i> Subscription Module{" "}
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("SM_Amount_Tenure_Tab") && (
              <li>
                <NavLink to="/subscription/amount-tenure">Amount & Tenure</NavLink>
              </li>
              )}
              {auth_service.getPermission("SM_Circle_Tab") && (
              <li>
                <NavLink to="/subscription/circle">Circle</NavLink>
              </li>
              )}
              {auth_service.getPermission("SM_Project_Tab") && (
                <li>
                <NavLink to="/subscription/project">Projects</NavLink>
              </li>
              )}
              {auth_service.getPermission("SM_Organization_Tab") && (
              <li>
                <NavLink to="/subscription/organization">Organizations</NavLink>
              </li>)}
              {auth_service.getPermission("SM_ExcludeUser_Tab", "SM_ExcludeUser_Fullaccess") &&
              <li>
                <NavLink to="/subscription/exclude-user-list">Exclude User</NavLink>
              </li>}
              {auth_service.getPermission("SM_Others_Tab") &&
              <li>
                <NavLink to="/subscription/others">Others</NavLink>
              </li>}
              {auth_service.getPermission("SM_TRIAL_Tab", "SM_TRIAL_FullAccess") && <li>
                <NavLink to="/subscription/trial-user">Trial User</NavLink>
              </li>}
            </ul>
          </li>)}
          {auth_service.getPermission("World_People_of_Nature_Day_Tab") &&
           <li className="">
            <Link to="#" className="has-arrow">
              World People Of Nature Day{" "}
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("WPOND_Tab", "WPOND_FullAccess") &&
              <li>
                <Link to="#" className="has-arrow">
                  WPOND{" "}
                </Link>
                <ul class="nav nav-third-level">
                  <li>
                    <NavLink to="/wpond/year">Year</NavLink>
                  </li>
                </ul>
              </li>}
              {auth_service.getPermission("WPOND_UpcomingTab", "WPOND_Upcoming_FullAccess") &&
              <li>
                <Link to="#" className="has-arrow">
                  Upcoming Year{" "}
                </Link>
                <ul class="nav nav-third-level">
                  <li>
                    <NavLink to="/wpond/upcoming-year">About</NavLink>
                  </li>
                </ul>
              </li>}
            </ul>
          </li>}
          {auth_service.getPermission("Landing_Tab") && 
            <li className="">
              <Link to="#" className="has-arrow">
                Landing Page
              </Link>

              <ul className="nav nav-second-level">
                {auth_service.getPermission("Pre_Login_Tab") && <li>
                  <NavLink to={getCurrentTabPath("PRE_LOGIN")}>Pre-Login</NavLink>
                  
                </li>}
              {auth_service.getPermission("Post_Login_Tab") && <li>
                  <NavLink to={getCurrentTabPath("POST_LOGIN")}>Post-Login</NavLink>
                </li>}
              </ul>

          </li>}
          {auth_service.getPermission("Admin_Panel_Tab") && (
          <li className="">
            <Link to="#" className="has-arrow">
              <i className="iAdministrator"></i> Admin Panel{" "}
            </Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("AP_Permission_Tab") && (
              <li>
                <NavLink to="/admin-panel/permission">Permission</NavLink>
              </li>
              )}
              {auth_service.getPermission("AP_Group_Tab") && (
              <li>
                <NavLink to="/admin-panel/group">Group</NavLink>
              </li>
              )}
              {auth_service.getPermission("AP_Department_Tab", "AP_Department_FullAccess") && (
              <li>
                <NavLink to="/admin-panel/department">Department</NavLink>
              </li>
              )}
              {auth_service.getPermission("AP_Roles_Tab", "AP_Roles_FullAccess") && (
              <li>
                <NavLink to="/admin-panel/role">Role</NavLink>
              </li>
              )}
              {auth_service.getPermission("AP_User_Tab", "AP_User_FullAccess") && (
              <li>
                <NavLink to="/admin-panel/user">User</NavLink>
              </li>
              )}
              {auth_service.getPermission("AP_FE_Admin_tab") &&
              <li>
                <NavLink to="/admin-panel/fe-admin">FE Admin</NavLink>
              </li>}
              {auth_service.getPermission("AP_ServerScript_Tab") &&
              <li>
                <NavLink to={"/admin-panel/server-script"}>Server Script</NavLink>
              </li>}
              <li>
                <NavLink to={"/admin-panel/exclude-analytics-user"}>Exclude analytics user</NavLink>
              </li>
              {auth_service.getPermission("AP_FlagPost_Tab") &&
              <li>
                  <NavLink to={"/admin-panel/flag-post"}>Flag Post Alert</NavLink>
              </li>}
              {auth_service.getPermission("AP_Exclude_Global_User_Tab") &&
              <li>
                  <NavLink to={"/admin-panel/exclude-global-user"}>Exclude Global user</NavLink>
              </li>}
              {auth_service.getPermission("AP_Restricted_User_Tab", "AP_Restricted_User_FullAccess") && 
              <li>
                  <NavLink to={"/restricted-user"}>Restricted User</NavLink>
              </li>}
              { 
              <li>
                  <NavLink to={"/spam-word"}>Spam Word</NavLink>
              </li>}
              <li>
                  <NavLink to={"/update-user-manual"}>Update User Manual</NavLink>
              </li>
            </ul>
          </li>
          )}
          {auth_service.getPermission("Marketing_Tab") && 
          <li className="">
            <Link to="#" className="has-arrow">Marketing{" "}</Link>
            <ul className="nav nav-second-level">
              {auth_service.getPermission("Notification_Tab", "Notification_FullAccess") && 
                <li><NavLink to="/notification">Notification{" "}</NavLink></li>
              }
              {auth_service.getPermission("Email_Tab", "Email_FullAccess") && 
                <li><NavLink to="/email">Email{" "}</NavLink></li>
              }
              <li><NavLink to="/credit-points">Credit points</NavLink></li>
            </ul>
          </li>}

          {/* <li className="">
            <Link to="/feedback" activeClassName="bgcolor" exact>
               <i class="iMegaphone" style={{fontSize: '14px', verticalAlign: '0px'}}></i> Feedback
            </Link>
          </li> */}

          {/* feedback section */}
          {auth_service.getPermission("FEEDBACK_TAB") && 
            <li className="">
                <Link to="#" className="has-arrow"><i className="iMegaphone"></i> Feedback</Link>
                <ul className="nav nav-second-level">
                    <li><NavLink to="/project-feedback">Project feedback</NavLink></li>
                    <li><NavLink to="/global-feedback">Global Feedback</NavLink></li>
                    <li><NavLink to="/exclude-feedback-user">Exclude feedback user</NavLink></li>
                </ul>
            </li>
          }

          <li>
            <a onClick={logout} href="javascript:void(0)">
              <i className="iSwitchOff"></i> Logout
            </a>
          </li>
        </MetisMenu>
      </Scrollbars>
    </aside>
  );
};

export default LeftPanel;
