import { Link } from "react-router-dom";

const YearMenuList = ({ type, selectedYear, ...props }) => {
    return (
        <div class="pagetopwleft wpondMain">
            <div class="ptwrow">
                <div class="topBtns">
                    <Link className={"btn btn1 " + (type === "award" || type=== "awardNominations" || type === "awardNominationsVoters" ? "active" : "")} to={`/wpond/${selectedYear}/award-categories`}>Award Categories</Link>
                    <Link className={"btn btn1 " + (type === "juries" ? "active" : "")} to={`/wpond/${selectedYear}/juries`}>Juries</Link>
                    <Link className={"btn btn1 " + (type === "sponsors" ? "active" : "")} to={`/wpond/${selectedYear}/sponsors`}>Sponsors</Link>
                    <Link className={"btn btn1 " + (type === "partners" ? "active" : "")} to={`/wpond/${selectedYear}/partners`}>Partners</Link>
                    <Link className={"btn btn1 " + (type === "events" || type === "eventSpeaker" ? "active" : "")} to={`/wpond/${selectedYear}/upcoming-event`}>Event</Link>
                    <Link className={"btn btn1 " + (type === "leaderBoard" ? "active" : "")} to={`/wpond/${selectedYear}/leader-board`}>Leader Board</Link>
                    <Link className={"btn btn1 " + (type === "marketing" ? "active" : "")} to={`/wpond/${selectedYear}/marketing`}>Marketing</Link>
                </div>
            </div>
        </div>
    );
};

export default YearMenuList;
