import React from "react";
import { Link } from "react-router-dom";
import { epochTogmt } from "../../../../utils/common";
import CreditStatusDetails from "./CreditStatusDropDown/CreditStatusDetails";
import CreditStatusDrop from "./CreditStatusDropDown/CreditStatusDrop";

export const tableConstantsCreditGiven = (fetchAllCreditPointsTableData) => {

  
    return [
        {
          id: 1,
          name: "Issued Date",
          selector: (row) => row?.createTime && epochTogmt(row?.createTime),
          sortable: true,
          width: "200px"
        },
        {
          id: 2,
          name: "Start Date",
          selector: (row) => row?.startPeriod && epochTogmt(row?.startPeriod),
          sortable: true,
          width: "200px"
        },
        {
          id: 3,
          name: "Date of Expiry",
          selector: (row) => row?.validPeriod && epochTogmt(row?.validPeriod),
          sortable: true,
          width: "200px"
        },
        {
          id: 4,
          name: "Total Credit given",
          cell: (row) => {
          return (<span className="sub">{row?.creditAmount}</span>)
          },
          sortable: true,
        },
        {
          id: 10,
          name: "% allowed to be used",
          selector: (row) => row?.percentageAllowed,
          sortable: true,
      },
        {
          id: 5,
          name: "Credit Status",
          cell: (row) => {
            return (
              <CreditStatusDetails creditStatus={row.creditStatus} statusType={"CREDIT_STATUS"}/>
            );
          },
          sortable: true,
          reorder: true,
          width: "200px"
        },
        
        {
            id: 7,
            name: "Use for",
            cell: (row) => {
            return (
              row.usedvalue > 0 ?
                <Link to={`/credit-points/credit-given/use-details/${row?.id}`}>
                    <span className="sub">{row.usedvalue}</span>
                </Link> : 
                <span className="sub">{row.usedvalue}</span>
            );
            },
            sortable: true,
        },

        {
          id: 6,
          name: "Status",
          cell: (row) => {
            return (
              <CreditStatusDetails status={row?.status} statusType={"ACTION_STATUS"}/>
            );
          },
          sortable: true,
          reorder: true,
          width: "200px"
      },

        {
          id: 8,
          name: "Action",
          cell: (row) => {
            let content = "";
            let status = "";

            if (row.status === "DRAFT") {
              content = "Enable";
              status = "DRAFT";
            }

            if (row.status === "ENABLE") {
              content = "Disable";
              status = "ENABLE";
            }

            if (row.status === "DISABLE") {
              content = "Enable";
              status = "DISABLE";
            }
    
            return (
              <CreditStatusDrop
                  children={content}
                  id={row.id}
                  status={status}
                  creditStatus={row.creditStatus}
                  action={fetchAllCreditPointsTableData}
                  fullPermission={""}
                  approvalPermission={""}
                  publishPermission={""}
                  enablePermission={""}
                  disablePermission={""}
                />
            );
          },
          sortable: true,
          reorder: true,
        },
        {
          id: 9,
          name: "Issued By",
          selector: (row) => "Punam Kanodia",
          sortable: true,
          width: "200px"
        },
    ];
};