import React from "react";
import { auth_service } from "../../auth/auth_service";
import style from "./admin_header.module.css";
import Button from "../../components/Buttons/button";



const AdminHeader = () => {
  let userData = auth_service.getUserData();
  return (
    <div className="top_nav">
      <div className="nav_menu">
        <nav>
          <div className="nav toggle">
            {" "}
            <a id="menu_toggle">
              <img src="assets/img/toggle-ico.png" alt="" />
            </a>{" "}
          </div>
          <div className="toplogo">
            <a href="/">
              <img src="https://cdn.dscovr.com/images/welogoNew.webp" width={34}
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.dscovr.com/images/welogoNew.png"}} alt=""/>              
            </a>
          </div>
          <div className="loguser">{userData && userData.email}</div>
          {/* <ul className="nav navbar-right">
            <li className="deprtmpos">
              <div>
                <span>Roll:</span> Content Reviewer
              </div>
              <div>
                <span>Department:</span> Content Management
              </div>
            </li>           
          </ul> */}
        </nav>
      </div>
    </div>
  );
};

export default AdminHeader;
