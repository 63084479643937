import React, { Component } from "react";
import FormInput from "../../../components/InputFields/form_input";
import styles from "./create_how_we_impact_category.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import { CommonHeader } from "../../../utils/commonHeader";
import Select from "react-select";
import history from "../../../utils/history";
import httpService from "../../../AxiosInterceptors";
import style from "../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { auth_service } from "../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const options = [
  { value: 'visual-storytellers-of-nature', label: 'Visual Storytellers of Nature' },
  { value: 'writers-in-nature', label: 'Writers in Nature' },
  { value: 'media-professionals-in-nature', label: 'Media Professionals in Nature' },
  { value: 'specialist-in-nature', label: 'Specialists in Nature' },
  { value: 'voices-of-Nature', label: 'Voices of Nature' },
  { value: 'wildlife-professionals-and-experts', label: 'Wildlife Professionals and Experts' },
  { value: 'explorers-in-nature', label: 'Explorers of Nature' },
  { value: 'entrepreneurs-in-eco-business', label: 'Entrepreneurs in Eco-businesses' },
  { value: 'entrepreneurs-in-eco-tourism', label: 'Entrepreneurs in Ecotourism' },
  { value: 'educators-in-nature', label: 'Educators in Nature' },
  { value: 'education-and-training-institutes-in-nature', label: 'Education & Training institutes in Nature' },
  { value: 'ngos-and-igos-in-nature', label: 'NGOs & IGOs in Nature' },
  { value: 'governance-in-nature', label: 'Governance in Nature' },
]

export class CreateHowWeImpactCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: "",
      videoURL: '',
      errors: {},
      error: false,
      message: null,
    };
  }

  // validate create amount
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.categoryName) {
      formIsValid = false;
      errors["categoryName"] = "Please enter category name";
    }
    if (!this.state.videoURL) {
      formIsValid = false;
      errors["videoURL"] = "Please enter video URL";
    }
    if (this.state.videoURL) {
      let valudateLink = this.state.videoURL;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      if(urlValid == null) {
          formIsValid = false;
          errors["videoURL"] = "Please enter a valid URL";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //   price value change
  handleChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value.trimStart(),
      });
  };

  categoryName = (type) => {
    this.setState({ categoryName: type });
  };

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        name: this.state.categoryName && this.state.categoryName.label,
        slug: this.state.categoryName && this.state.categoryName.value,
        video: this.state.videoURL,
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/howWeEmpactController/create`,
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          error: false
        });
      }, 5000);
    }
  };

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/how-we-impact" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="form-group row">
                <div className="col-md-6">
                  <label className="d-flex align-items-center">Category Name <span className="text-danger">*</span></label>
                  <Select
                    options={options}
                    onChange={this.categoryName}
                    placeholder={"Start typing"}
                    closeMenuOnSelect={true}
                    styles={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        const color = "#cfe7c7";
                        return {
                          ...styles,
                          backgroundColor: isDisabled
                            ? null
                            : isSelected
                              ? "#cfe7c7"
                              : isFocused
                                ? "#cfe7c7"
                                : null,
                          color: isDisabled
                            ? "#ccc"
                            : isSelected
                              ? color > 2
                                ? "#00374d"
                                : "#00374d"
                              : data.color,
                          cursor: isDisabled ? "not-allowed" : "default",

                          ":active": {
                            ...styles[":active"],
                            backgroundColor:
                              !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                          },
                        };
                      },
                    }}
                  />
                  {this.state.errors["categoryName"] && (
                    <small className={style.danger}>
                      {this.state.errors["categoryName"]}
                    </small>
                  )}
                </div>
              </div>
              <div className="form-group">
                <FormInput
                    group={"large"}
                    value={this.state.videoURL}
                    label={"Video URL"}
                    onChange={this.handleChange}
                    name={"videoURL"}
                    error={this.state.errors["videoURL"]}
                  />
              </div>
              {auth_service.getPermission("HowWeImpact_Create", "HowWeImpact_FullAccess") && 
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Create"} onClick={this.onSubmit} />
              </ButtonWrap>}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateHowWeImpactCategory;