import axios from "axios";
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";
import { epochTogmt, trimDescription } from "../../../../utils/common";
import styled from "styled-components";
import Button from "../../../../components/Buttons/button";
// This is the table constant/settings which needed to render table elements

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export const tableConstants = (fetchAllUsersList, deleteUser, handleEdit) => {
  return [
    {
      id: 1,
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Bronze (free)",
      cell: (row) => {
        return (
          <input type="text" className="form-control" placeholder="-" value="" />
        ); 
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Silver",
      cell: (row) => {
        return (
          <input type="text" className="form-control" placeholder="-" value="" />
        ); 
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Gold",
      cell: (row) => {
        return (
          <input type="text" className="form-control" placeholder="-" value="" />
        ); 
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Platinum",
      cell: (row) => {
        return (
          <input type="text" className="form-control" placeholder="-" value="" />
        ); 
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "",
      width: '230px',
      cell: (row) => {
        return (
          <>
            <a href="javascript:void(0)" className="edtbtn btn"><i class="iEditBox"></i> Edit</a>
            <a href="javascript:void(0)" className="btn btnUpdte">Update</a>
          </>
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
