export const userData = [
  {
    id: "5eccc3c4fd93cc03f9d4d081",
    userName: "WenatQA Test",
    persona: "Researcher",
    addressDetail: "India",
    customUrl: "d1cad562-3345-460f-9d18-0599d5202b43",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ec93bf027e66c5ada455788",
    userName: "Devi Prasad",
    persona: "Forest Officer",
    addressDetail: "United States",
    customUrl: "70a3be14-408f-42ad-b41f-835cd0f8ed8e",
    deactivated: false,
    reportCount: 3,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ec91d9727e66c5ada455780",
    userName: "Dev Tester",
    persona: "Forest Officer",
    addressDetail: "India",
    customUrl: "efce5ffa-5ef1-4ff8-88ce-14b114ce634b",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ece0d611cddb74f5fad952f",
    userName: "Abhijit Banerjee",
    persona: "Naturalist",
    addressDetail: "India",
    customUrl: "0d4ca64d-ab38-4cfa-a136-ebc44ef9e9f9",
    deactivated: false,
    reportCount: 3,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f3a9fd25a2f3048e49206d9",
    userName: "Comp 2",
    persona: null,
    addressDetail: "Albania",
    customUrl: "comp 2",
    deactivated: false,
    reportCount: 1,
    adminId: "5e8eb3c336a09d3234f0d53e",
    adminName: "punam kanodia",
  },
  {
    id: "5ecb5e6727e66c5ada4557a3",
    userName: "MM Gg",
    persona: "Event Planner",
    addressDetail: "Switzerland",
    customUrl: "fr",
    deactivated: false,
    reportCount: 4,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ecaa97c27e66c5ada455793",
    userName: "Mohit Agr",
    persona: "painting",
    addressDetail: "India",
    customUrl: "0e73d9bb-e75b-4a10-bfd7-93bddd5bb00b",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f2c03f304f2905dc9ff0ed8",
    userName: "Business Comp",
    persona: null,
    addressDetail: "Angola",
    customUrl: "business comp",
    deactivated: false,
    reportCount: 2,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ee7484a5891681c1ef2b910",
    userName: "Nishani Wer",
    persona: "Academician",
    addressDetail: "Bangladesh",
    customUrl: "db5d7604-e07c-4f65-80c6-99c57680a0fb",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f75b0ec8f2e07270124b51e",
    userName: "Company Foreigner",
    persona: null,
    addressDetail: "Argentina",
    customUrl: "comfor",
    deactivated: false,
    reportCount: 2,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f182b29ea9a3c568b449af3",
    userName: "Falak Sams",
    persona: "",
    addressDetail: "Burkina Faso",
    customUrl: "falaksams",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ed49aa108bace4c69413442",
    userName: "E 3",
    persona: "Naturalist",
    addressDetail: "India",
    customUrl: "4704856c-051b-41b3-bfea-7f62ce655b2c",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f777f426dc2320379edcb6b",
    userName: "Comp78",
    persona: null,
    addressDetail: null,
    customUrl: "comp78",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f7463fde32e87106607e656",
    userName: "Palao",
    persona: "",
    addressDetail: "India",
    customUrl: "palao",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f2e0b0f953ec6145df7bd22",
    userName: "Business Pro123",
    persona: null,
    addressDetail: "Singapore",
    customUrl: "bp2020",
    deactivated: false,
    reportCount: 6,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ee362797ebf4c287157fa73",
    userName: "Pk Kolkata",
    persona: "Entrepreneur",
    addressDetail: "India",
    customUrl: "pk",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ecba69627e66c5ada4557bc",
    userName: "Deepa Durga",
    persona: "painting",
    addressDetail: "India",
    customUrl: "2b78b396-68e8-499f-89f9-dea2516f598e",
    deactivated: false,
    reportCount: 3,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ecbb1f227e66c5ada4557c6",
    userName: "Pallabi Das",
    persona: "Accountant",
    addressDetail: "India",
    customUrl: "aaa",
    deactivated: false,
    reportCount: 4,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5fb7725c92cc8e28ddf483e8",
    userName: "New Signup Fcgfgfd",
    persona: "Biologist",
    addressDetail: "Afghanistan",
    customUrl: "newsignupfcgfgfd",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "605c2f29aed65e7178eceb47",
    userName: "Companjy Test",
    persona: null,
    addressDetail: "Afghanistan",
    customUrl: "companjytest",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ecb5d7327e66c5ada45579e",
    userName: "Durga Old",
    persona: "Student",
    addressDetail: "Albania",
    customUrl: "dp2020",
    deactivated: false,
    reportCount: 13,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5ecbd038811990408de96492",
    userName: "Dolo Nature",
    persona: "Nature Lover",
    addressDetail: "India",
    customUrl: "dolo",
    deactivated: false,
    reportCount: 4,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5efaf465b2f83a731d09d992",
    userName: "Panda Panda",
    persona: "Select",
    addressDetail: "Canada",
    customUrl: "pandapanda",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "606d982875913111bbf7efea",
    userName: "Hundreedten Hundreedten",
    persona: null,
    addressDetail: "South Africa",
    customUrl: "hundreedtenhundreedten",
    deactivated: false,
    reportCount: 3,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f73299e8d3ac8741f85c648",
    userName: "Comp Join",
    persona: null,
    addressDetail: "India",
    customUrl: "compjoin",
    deactivated: false,
    reportCount: 1,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5edb436e04c242330a7a3497",
    userName: "Dolphin Dolphin",
    persona: "Naturalist",
    addressDetail: "India",
    customUrl: "dolphin",
    deactivated: false,
    reportCount: 5,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "6059eb592e9b86202d70bb16",
    userName: "Community Page",
    persona: null,
    addressDetail: "India",
    customUrl: "communitypage1",
    deactivated: false,
    reportCount: 2,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "6079328323026d3b658617fd",
    userName: "New Company",
    persona: null,
    addressDetail: "United States of America",
    customUrl: "newcompany2",
    deactivated: true,
    reportCount: 1,
    adminId: "5e8eb3c336a09d3234f0d53e",
    adminName: "punam kanodia",
  },
  {
    id: "5f983d83bb8444186f9110d7",
    userName: "Pallavi Wsegames Dass",
    persona: "Bird Photographer",
    addressDetail: "Angola",
    customUrl: "pallavi",
    deactivated: false,
    reportCount: 2,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5fe1b517b9fdec4065cfc4b1",
    userName: "Pallabi Apk Das",
    persona: "Academician",
    addressDetail: "Bahrain",
    customUrl: "pallabiapk",
    deactivated: false,
    reportCount: 3,
    adminId: null,
    adminName: "Punam Kanodia",
  },
  {
    id: "5f23dfc05b4164725cc1d2ba",
    userName: "Moit Agrwal",
    persona: "",
    addressDetail: "India",
    customUrl: "moitagrwal",
    deactivated: false,
    reportCount: 2,
    adminId: null,
    adminName: "Punam Kanodia",
  },
];
