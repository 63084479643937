import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../../../../../../auth/auth_service";
import { tableProjectPointsConstants } from "./Data/tableProjectPointsConstants";
import { tableProjectActivityConstants } from "./Data/tableProjectActivityConstants";
import { tableProjectParticipantsConstants } from "./Data/tableProjectParticipantsConstants";
import DataTable from "react-data-table-component";
import "../../../../year.module.css";
import { customStyles } from "../../../../../../../../utils/tableCustomStyle";
import ProjectMenuList from "./project_menu_list";
import ExportCSV from "../../../../../../../../components/Buttons/export_csv_button";
import { FilterComponent } from "../../../../../../../../utils/filter";
import { epochTogmt } from "../../../../../../../../utils/common";

const projectHeader = [
    { label: "Project Id", key: "id" },
    { label: "Title", key: "title" },
    { label: "Project Link", key: "link" },
    { label: "Date", key: "createTime" }
];
const activityHeader = [
    { label: "Activity Type", key: "activityType" },
    { label: "Link", key: "link" },
    { label: "Date", key: "time" }
];
const participantsHeader = [
    { label: "Project Id", key: "projectId" },
    { label: "User Id", key: "userId" },
    { label: "Username", key: "username" },
    { label: "Title", key: "title" },
    { label: "Joining Date", key: "joiningDate"}
];


export class ProjectActivityParticipantsCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            dataList: [],
            exportDataList: [],
            filterText: "",
            filterDisplay: false,
            userId: this.props.userId,
            selectedYear: this.props.year,
            awardCategorySlug: this.props.awardCategorySlug,
        };
    }

    fetchAllDataList = () => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/pointcalculation/get/project/point?userId=${this.state.userId}&year=${this.state.selectedYear}&slug=${this.state.awardCategorySlug}`,
        })
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.status === "200 OK" &&
                    response.data.body
                ) {
                    let exportDataList = [];
                    let dataList =  this.props.pageType === "PROJECT" ? response.data.body?.projectList : this.props.pageType === "ACTIVITY" ? response.data.body?.projectActivityList : response.data.body?.participantList;
                    dataList = dataList ? dataList : [];
                    dataList && dataList.map((item, index) => {
                        if(this.props.pageType === "PROJECT") {
                            exportDataList.push({ "id": item.id, "title": item.title, "link": item.link, "createTime": epochTogmt(item.createTime) })
                        } else if(this.props.pageType === "ACTIVITY") {
                            exportDataList.push({ "activityType": item.activityType, "link": item.link, "time": epochTogmt(item.time) })
                        } else {
                            exportDataList.push({ "projectId": item.projectId, "userId": item.userId, "username": item.username, "title": item.title, "joiningDate": epochTogmt(item.joiningDate) })
                        }
                    })
                    this.setState({
                        dataList: dataList,
                        exportDataList: exportDataList
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentDidMount() {
        this.fetchAllDataList();
    }

    handleChangeFilter = (e) => {
        this.setState({
            filterText: e.target.value.trimStart()
        })
    }

    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={this.handleChangeFilter}
                    searchType={this.props.pageType !== "ACTIVITY" ? 'Search by title' : 'Search by activity type'}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    actionButton = () => {
        return (
            <React.Fragment>
                <ProjectMenuList {...this.props} />
                {this.state.dataList?.length > 0 && 
                    <>
                        {this.getSubHeaderComponent()}
                        {/* auth_service.getPermission("WPOND_AC_ExportCsv", "WPOND_AC_FullAccess") &&  */<ExportCSV headers={this.props.pageType === "PROJECT" ? projectHeader : this.props.pageType === "ACTIVITY" ? activityHeader : participantsHeader} data={this.state.exportDataList} filename={this.props.pageType.toLowerCase()+ "_list"} />}
                    </>
                }
            </React.Fragment>
        );
    };

    render() {
        if (this.props.pageType === "ACTIVITY") {
            this.filteredItems = this.state.dataList && this.state.dataList.filter((item) => item.activityType && item.activityType.toLowerCase().includes(this.state.filterText.toLowerCase()))
        } else {
            this.filteredItems = this.state.dataList && this.state.dataList.filter((item) => item.title && item.title.toLowerCase().includes(this.state.filterText.toLowerCase()))
        }
        return (
            <DataTable
                columns={this.props.pageType === "PROJECT" ? tableProjectPointsConstants() : this.props.pageType === "ACTIVITY" ? tableProjectActivityConstants() : tableProjectParticipantsConstants()}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={10}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
        );
    }
}

export default ProjectActivityParticipantsCommon;
