import React, { Component } from "react";
import Select from "react-select";
import { auth_service } from "../../../auth/auth_service";
import { CommonHeader } from "../../../utils/commonHeader";
import { subscriptionType } from "../CommonSubscriptionFunction";
import CommonSubscriptionListPage from "../CommonSubscriptionListPage";
import FormInput from "../../../components/InputFields/form_input";
import axios from "axios";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../components/InputFields/form_input.module.css";
import * as _ from "lodash";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const headers = [
  { label: "Name", key: "username" },
  { label: "Email", key: "email" },
  { label: "Mobile", key: "mobile" }
];
export class TrialSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      trialNoOfDays: "",
      subscriptionTypeValue: { value: 'SILVER', label: 'Professional' },
      userId: "",
      errors: {},
      error: false,
      showExtraTrialPopUp: false
    };
  }

  handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  subscriptionType = (type) => {
    this.setState({
      subscriptionTypeValue: type
    })
  }

  openExtraTrialModal = (userId) => {
    this.setState({
      userId: userId,
      showExtraTrialPopUp: true
    }, () => {
      window.$("#extraTrialPopUp").modal("show");
    })
  }

  closeModal = () => {
    window.$("#extraTrialPopUp").modal("hide");
    this.setState({
      trialNoOfDays: "",
      subscriptionTypeValue: { value: 'SILVER', label: 'Professional' },
      showExtraTrialPopUp: false
    })
  }

  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (_.isEmpty(this.state.subscriptionTypeValue)) {
      formIsValid = false;
      errors["subscriptionTypeValue"] = "Please enter subscription type";
    }
    if (!this.state.trialNoOfDays) {
      formIsValid = false;
      errors["trialNoOfDays"] = "Please enter no of days";
    }
    if (this.state.trialNoOfDays && parseInt(this.state.trialNoOfDays) <= 0) {
      formIsValid = false;
      errors["trialNoOfDays"] = "No of days should be greater than 0";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        type: this.state.subscriptionTypeValue.value,
        days: parseInt(this.state.trialNoOfDays),
        userId: this.state.userId
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/trial/subscription/avail-by-admin`,
        headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (response && response.data && response.data.message === "Success!") {
            this.closeModal();
          }
        })
        .catch((error) => {
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          error: false
        });
      }, 5000);
    }
  }

  render() {
    const { trialNoOfDays, subscriptionTypeValue, errors, showExtraTrialPopUp } = this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <CommonSubscriptionListPage type="TRIAL" openExtraTrialModal={this.openExtraTrialModal} headers={headers} fileName={"trial-subscription-user"} />
        </div>

        {showExtraTrialPopUp &&
          <div class="modal fade dgpopup permissionMod" id="extraTrialPopUp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Extra Trial</h5>
                </div>
                <div class="modal-body">
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label className="d-flex align-items-center">Subscription Type <span className="text-danger">*</span></label>
                      <Select
                        options={subscriptionType}
                        onChange={this.subscriptionType}
                        placeholder={"Start typing"}
                        value={subscriptionTypeValue}
                        closeMenuOnSelect={true}
                        filterOption={(option) => option.value !== "BRONZE" && option.value !== "ALL"}
                        styles={{
                          option: (
                            styles,
                            { data, isDisabled, isFocused, isSelected }
                          ) => {
                            const color = "#cfe7c7";
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? null
                                : isSelected
                                  ? "#cfe7c7"
                                  : isFocused
                                    ? "#cfe7c7"
                                    : null,
                              color: isDisabled
                                ? "#ccc"
                                : isSelected
                                  ? color > 2
                                    ? "#00374d"
                                    : "#00374d"
                                  : data.color,
                              cursor: isDisabled ? "not-allowed" : "default",

                              ":active": {
                                ...styles[":active"],
                                backgroundColor:
                                  !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                              },
                            };
                          },
                        }}
                      />
                      {errors["subscriptionTypeValue"] && (
                        <small className={style.danger}>
                          {errors["subscriptionTypeValue"]}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <FormInput
                        group={"large"}
                        value={trialNoOfDays}
                        label={"No of Days"}
                        onChange={this.handleChange}
                        name={"trialNoOfDays"}
                        error={errors["trialNoOfDays"]}
                      />
                    </div>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.closeModal} data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        }
      </CommonHeader>
    );
  }
}

export default TrialSubscription;