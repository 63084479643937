import React from "react";
import Export from "../components/Buttons/export_button";
import { epochTogmt } from "../utils/common";

export const convertArrayOfObjectsToCSV = (array, data) => {
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);
  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      // if(key === "createdOn" || key === "time" || key === "createTime" || key === "endDate" ||
      // key === "startDate" || key === "updateTime" || key === "publishedTime" || key === "registerdOn") {
      //   item[key] = epochTogmt(item[key]);
      // }
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCSV = (array, data) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  let date = new Date();
  let dateMs = date.valueOf();
  if (csv == null) return;
  const filename = data+"-"+epochTogmt(dateMs)+"-"+dateMs+".csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};
