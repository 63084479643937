import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../auth/auth_service";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import "./causes_list.module.css";
import { customStyles } from "../../utils/tableCustomStyle";
import Export from "../../components/Buttons/export_button";
import { downloadCSV } from "../../utils/export_csv";
import { FilterComponent } from "../../utils/filter";
import Button from "../../components/Buttons/button";
import styles from "./causes_list.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const options = [
  { value: "name", label: "Filter By Name" },
  { value: "status", label: "Filter By Name" },
  { value: "name", label: "Filter By Name" },
];

export class CausesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      causesList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.causesList.filter(
              (item) =>
                item.name &&
                item.name.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all cuses list
  fetchAllCausesList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/cause/list?status=undefined&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            causesList: response.data.body.list,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Delete cause by id
  deleteCause = (id) => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL + "/backend/cause/delete/" + id,
    })
      .then((response) => {
        this.fetchAllCausesList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("Causes_ExportCSV", "Causes_FullAccess") && <Export onExport={() => downloadCSV(this.state.causesList, "cause")} />}
      </React.Fragment>
    );
  };
  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/cause/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchAllCausesList();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };
  componentDidMount() {
    this.fetchAllCausesList();
  }

  render() {
    this.filteredItems = this.state.causesList.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                //title="Causes"
                columns={tableConstants(this.fetchAllCausesList,this.deleteCause,this.handleConfirmModal)}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("Causes_Create", "Causes_FullAccess") && (
        <Link to="/causes/create" className={`btn ${styles.createforumbtn}`}>
          <i className="iAddCircle"></i> Create Cause
        </Link>
        )}
      </CommonHeader>
    );
  }
}

export default CausesList;
