import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableStudioConstants } from "../Data/studioTableConstants";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};
export class Studio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      studioData: [],
    };
  }

  fetchReportStudioData = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/studioList`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            studioData: response.data.body.reportPostList,
          });
          MySwal.clickConfirm()
        }
      })
      .then((error) => {
        console.log(error);
      });
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/update?id=${id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchReportStudioData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    this.fetchReportStudioData();
  }

  render() {
    const { studioData } = this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title="Studio Report"
              columns={tableStudioConstants(this.handleConfirmModal)}
              data={studioData}
              customStyles={customStyles}
              pagination
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default Studio;
