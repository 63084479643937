import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { auth_service } from "../../../auth/auth_service";

export const tableTestimonialsConstants = (fetchAllPromotionList,agreeHandleChange,handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "User Name",
      cell: (row) => {
        return (
          <Link to={`/testimonials/edit/${row.id}`}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Profile Link",
      cell: (row) => {
        return (
          <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.profileLink} target="_blank">
            <span className="sub">{`${process.env.REACT_APP_frontEndURL}/profile/${row.profileLink}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Enable";
          status = "ENABLE";
        }
        // if (row.status === "PENDING_APPROVAL") {
        //   content = "Publish";
        //   status = "PUBLISH";
        // }
        // if (row.status === "PUBLISH") {
        //   content = "Enable";
        //   status = "ENABLE";
        // }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"testimonial"}
            action={fetchAllPromotionList}
            fullPermission={"Testimonial_FullAccess"}
            approvalPermission={"Testimonial_Approval"}
            publishPermission={"Testimonial_Publish"}
            enablePermission={"Testimonial_Enable"}
            disablePermission={"Testimonial_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 8,
      name: "Landing Page",
      cell: (row)=>{
        return(
          <label className="switch">
            <input
              type="checkbox"
              name="landingPage"
              onClick={(e) => agreeHandleChange(e, row.id)}
              checked={row.landingPage}
              disabled={!auth_service.getPermission("Testimonial_Landing", "Testimonial_FullAccess")}
            />
              <span class="slider round"></span>
          </label>
        )
      }
    },
    {
      id: 9,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("Testimonial_Delete", "Testimonial_FullAccess") || row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
