import axios from "axios";
import React, { Component } from "react";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import "./permission_list.module.css";
import { customStyles } from "../../../utils/tableCustomStyle";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import { FilterComponent } from "../../../utils/filter";
import Button from "../../../components/Buttons/button";
import styles from "./permission_list.module.css";
import { Link } from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";
import { CommonHeader } from "../../../utils/commonHeader";

const options = [
  { value: "name", label: "Filter By Name" },
  { value: "status", label: "Filter By Name" },
  { value: "name", label: "Filter By Name" },
];

export class PermissionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      permissionsList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.permissionsList.filter(
              (item) =>
                item.permission &&
                item.permission
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all cuses list
  fetchAllPermissionsList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/adminGroup/mapList?status=undefined&createdBy=" +
        this.state.userData.firstName +
        "&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            permissionsList: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("AP_Permission_ExportCSV") && <Export onExport={() => downloadCSV(this.state.permissionsList, "permission")} />}
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchAllPermissionsList();
  }

  render() {
    this.filteredItems = this.state.permissionsList.filter(
      (item) =>
        item.permission &&
        item.permission
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Permission"
                columns={tableConstants(this.fetchAllPermissionsList)}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default PermissionList;
