import React from "react";
import { Link } from "react-router-dom";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";
import { auth_service } from "../../../../auth/auth_service";

export const tableCategoryConstant = (fetchAllCategoryList, handleConfirmModal, loginType) => {
  return [
    {
      id: 1,
      name: "Title",
      cell: (row) => {
        return (
          <Link to={`/post-login/category/edit/${row.id}`}>
            <span className="sub">{row.title}</span>
          </Link>
        )
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 7,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"CATEGORY"}
            action={fetchAllCategoryList}
            fullPermission={"Post_Login_Category_FullAccess"}
            approvalPermission={"Post_Login_Category_Approval"}
            publishPermission={"Post_Login_Category_Publish"}
            enablePermission={"Post_Login_Category_Enable"}
            disablePermission={"Post_Login_Category_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 9,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("Post_Login_Category_Delete", "Post_Login_Category_FullAccess") || row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;

      },
      sortable: true,
      reorder: true,
    }
  ];
};