import React, { Component } from "react";
import styles from "./marketing.module.css";
import { auth_service } from "../../auth/auth_service";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import CommomListPage from "./CommomListPage";
export class EmailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <CommomListPage pageType={"EMAIL"} />
        </div>
        {auth_service.getPermission("Email_Create", "Email_FullAccess") && (
          <Link to="/email/create" className={`btn ${styles.createforumbtn}`}>
            <i className="iAddCircle"></i> Create Email
          </Link>
        )}
      </CommonHeader>
    );
  }
}

export default EmailPage;
