import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../auth/auth_service";
import { tableUserListConstants } from "./tableUserListConstants";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyle";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import { FilterComponent } from "../../../utils/filter";
import { CommonHeader } from "../../../utils/commonHeader";

export class BadgeUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            userList: [],
            filterText: "",
            filterDisplay: false,
            loading: false,
            totalRows: 0,
            perPage: 10,
        };
    }

    // subheader of a component
    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.userList.filter(
                            (item) =>
                                item.id &&
                                item.id.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };

    fetchAllUserList = (page) => {
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/badge/${this.props?.pageType === "USER_BADGE_LIST" ? "assigned/user/list" : "userList"}?page=${page}&size=${this.state.perPage}&userId=${this.state?.userData?.id}`,
        }).then((response) => {
            if (response.data.message === "Success!") {
                this.setState({
                    userList: response.data?.body?.content,
                    loading: false,
                    totalRows: response?.data?.body?.totalElements
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    showFilter = () => {
        this.setState({
            filterDisplay: this.state.filterDisplay ? false : true,
        });
    };

    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
                <Export onExport={() => downloadCSV(this.state.userList, "receive")} />
            </React.Fragment>
        );
    };

    handlePageChange = (page) => {
        this.fetchAllUserList(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllUserList(page-1);
        })
    };

    componentDidMount() {
        this.fetchAllUserList(0);
    }

    render() {
        const { userList, totalRows, loading, perPage } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large">
                            <DataTable
                                title="Badge User List"
                                columns={tableUserListConstants(this.props?.pageType, this.fetchAllUserList)}
                                data={userList}
                                customStyles={customStyles}
                                //actions={this.actionButton()}
                                pagination
                                highlightOnHover
                                pointerOnHover
                                paginationPerPage={perPage}
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default BadgeUserList;