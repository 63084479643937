import StatusDrop from "../../../components/StatusDropDown/status_drop";
import {Link} from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";
export const tableCircleConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Circle Title",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Slug",
      selector: (row) => row.slug,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Link",
      cell: (row) => {
        return (
          <a href={process.env.REACT_APP_frontEndURL+'/circle/'+row.slug} target="_blank">
            <span className="sub">{process.env.REACT_APP_frontEndURL}/circle/{row.slug}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Username",
      cell: (row) => {
        return (
          /*<Link
            to={`/report/post-details/${row.id}`}
            style={{ color: "#154A59" }}
          >*/
            <span className="sub">{row.username}</span>
          /*</Link>*/
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Action",
      cell: (row) => {
        return <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Circle_Action") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.id);}}  disabled={!auth_service.getPermission("Report_Circle_Action")}>
          Reactive
        </button>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
