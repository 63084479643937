import React, { Component } from "react";
import { CommonHeader } from "../../../../utils/commonHeader";

import httpService from "../../../../AxiosInterceptors";
httpService.setupInterceptors();

export class CreatePermission extends Component {

  render() {
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div>
              <h1 className="text-center">You have no permission to view this page.</h1>
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreatePermission;
