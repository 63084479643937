import axios from "axios";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { auth_service } from "../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const StatusDrop = ({ children, id, status, type, ...props }) => {
  const statusChange = (id, status) => {
    const formData = new FormData();
    let postData = {
      status: status,
      [type === "USER_BADGE_LIST" ? "userId" : "id"]: id,
    };

    if(type === "PROJECT" || type === "STUDIO" || type === "FUND_RAISE" || type === "COMMUNITIES") {
      postData.preLoginManagementType = type
    }

    let url;
    if (type === "blog") {
      postData.updatedById = auth_service.getUserData()?.id;
      url = "backend/explore_service/api/explore_blog/updateByStatus";
    } else if (type === "speaker" || type === "winner") {
      postData.type = type.toUpperCase();
      postData.category = props.category;
      url = "backend/peopleOfNature/updateStatus";
    } else if (type === "faq") {
      url = "backend/faq/updateStatus";
    } else if (type === "department") {
      url = "adminDepartment/updateStatus";
    } else if (type === "role") {
      url = "adminRole/updateStatus";
    } else if (type === "group") {
      url = "adminGroup/updateStatus";
    } else if (type === "user") {
      url = "adminUser/updateStatus";
    } else if (type === "category") {
      url = "backend/explore_service/api/explore_category/updateByStatus";
    } else if (type === "message") {
      url = "messaging/message_service/api/message_group/updateNewsGroupByStatus";
    } else if (type === "promotion") {
      url = "backend/promotion/updateStatus?id=" + id + "&status=" + status;
    } else if (type === "wpondEvent" || type === "wpondMedia") {
      url = "wpondEventNMedia/updateStatus";
    } else if (type === "wpondAwardCategories") {
      url = "wpondAwardCategory/updateStatus";
    }  else if (type === "wpondSponsors") {
      url = "wpondSponsor/updateStatus";
    } else if (type === "wpondPartners") {
      url = "wpondPartner/updateStatus";
    } else if (type === "wpondAboutEvent") {
      postData.year = props.year;
      url = "wpondAboutEvent/updateStatus";
    }  else if (type === "wpondAboutEventSpeaker") {
      url = "wpondAboutEventSpeaker/updateStatus";
    } else if (type === "wpondAboutJuries") {
      url = "wpondJury/updateStatus";
    } else if (type === "wpondAwardNomination") {
      url = "wpondNomination/updateStatus";
    } else if (type === "howWeImpact") {
      url = "howWeEmpactController/updateStatus";
    } else if (type === "testimonial") {
      url = "testimonial/updateStatus";
    } else if (type === "notification" || type === "email") {
      url = "notifier/updateStatus";
    } else if(type === "BELONG_HERE") {
      url = "backend/pre/login/updateBelong/status";
    } else if(type === "INSPIRING_STORY") {
      url = `backend/pre/login/updateInspiring/status`
    } else if(type === "PROJECT" || type === "STUDIO" || type === "FUND_RAISE" || type === "COMMUNITIES") {
      url = "backend/pre/login/updateManagement/status";
    } else if(type === "TRENDING_CONTENT") {
      url = "backend/pre/login/updateTrendingContent/status"
    } else if(type === "CATEGORY") {
      url = "backend/post/login/updateBelongCategory/status"
    } else if(type === "WOULD_LIKE") {
      url = "backend/post/login/update-belong-data-status"
    } else if(type === "USER_BADGE_LIST") {
      url = "backend/badge/update/status";
    } else {
      url = "backend/cause/updateStatus?id=" + id + "&status=" + status;
    }
  
    formData.append("data", JSON.stringify(postData));
    axios({
      method: (type === "BELONG_HERE" || type === "INSPIRING_STORY" || type === "PROJECT" || type === "TRENDING_CONTENT" || type === "STUDIO" || type === "FUND_RAISE" || type === "COMMUNITIES" || type === "INSPIRING_STORY_POST" || type === "BELONG_HERE_POST" || type === "WOULD_LIKE") ? "PUT" : "POST",
      url: `${process.env.REACT_APP_userServiceURL}/${url}`,
      data:
        type === "blog" || "faq" || "role" || "group" || "department" || "user" || "testimonial" || "notification" || "email"
          ? postData
          : formData,
      withCredentials: true,
    })
      .then((response) => {
        props.action();
      })
      .catch((error) => {
        console.log(error);
        if (type === "wpondAboutEvent") {
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>Already four events are enabled.</p>)
          })
        }
        if (type === "promotion" || type === "winner" || type === "speaker" ||  type === "blog" || type === "testimonial" || type === "CATEGORY" || type === "BELONG_HERE" || type === "INSPIRING_STORY" || type === "PROJECT" || type === "STUDIO" || type === "FUND_RAISE" || type === "COMMUNITIES" || type === "TRENDING_CONTENT") {
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        }
      });
  };
  return (
    <div className="dgdropdown">
      <button
        type="button"
        className="btn dropdown-toggle"
        data-toggle="dropdown"
      >
        {children}
      </button>
      <div className="dropdown-menu fadeIn animated">
        {(!auth_service.getPermission(props.fullPermission) && !auth_service.getPermission(props.approvalPermission) && !auth_service.getPermission(props.publishPermission) && !auth_service.getPermission(props.disablePermission) && !auth_service.getPermission(props.enablePermission)) ?
          (<button
            className={
              "dropdown-item"
            }
          >
            Permission Denied
          </button>) : (
            <button
              className={
                children === "Draft" ? "dropdown-item" : "disabled dropdown-item"
              }
              onClick={(e) => {
                statusChange(id, status);
              }}
            >
              Draft
            </button>
          )
        }
        {type !== "testimonial" && <>
          {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.approvalPermission) || auth_service.getPermission(props.publishPermission) || auth_service.getPermission(props.disablePermission) || auth_service.getPermission(props.enablePermission)) &&
            <button
              className={
                children === "Approve"
                  ? "dropdown-item"
                  : "disabled dropdown-item"
              }
              onClick={(e) => {
                statusChange(id, status);
              }}
            >
              Approve
            </button>
          }
          {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.publishPermission) || auth_service.getPermission(props.disablePermission) || auth_service.getPermission(props.enablePermission)) &&
            <button
              className={
                children === "Publish" ? "dropdown-item" : "disabled dropdown-item"
              }
              onClick={(e) => {
                statusChange(id, status);
              }}
            >
              Publish
            </button>
          }
        </>}
        {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.disablePermission) || auth_service.getPermission(props.enablePermission)) &&
          <button
            className={
              children === "Enable" ? "dropdown-item" : "disabled dropdown-item"
            }
            onClick={(e) => {
              statusChange(id, status);
            }}
          >
            Enable
          </button>
        }
        {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.disablePermission)) &&
          <button
            className={
              children === "Disable" ? "dropdown-item" : "disabled dropdown-item"
            }
            onClick={(e) => {
              statusChange(id, status);
            }}
          >
            Disable
          </button>
        }
      </div>
    </div>
  );
};

export default StatusDrop;
