import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { epochTogmt } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";

export const tableConstants = (fetchAllFaqList,handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Questions",
      cell: (row) => {
        return (
          <Link to={`/faq/edit/${row.id}`}>
            <span className="sub">{row.question}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Section",
      cell: (row) => {
        let output = [];
        let count = 0;
        row.section &&
          row.section.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Created by",
      selector: (row) => row.createdBy,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Created On",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"faq"}
            action={fetchAllFaqList}
            fullPermission={"FAQ_FullAccess"}
            approvalPermission={"FAQ_Approval"}
            publishPermission={"FAQ_Publish"}
            enablePermission={"FAQ_Enable"}
            disablePermission={"FAQ_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 8,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("FAQ_Delete", "FAQ_FullAccess") || row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
