import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import FormInput from "../../../../components/InputFields/form_input";
import UploadFiles from "../../../../components/UploadFiles/upload_files";
import { CommonHeader } from "../../../../utils/commonHeader";
import styles from "../../winner.module.css";
import Select from "react-select";
import Button from "../../../../components/Buttons/button";
import styled from "styled-components";
import history from "../../../../utils/history";
import { auth_service } from "../../../../auth/auth_service";
import style from "../../../../components/InputFields/form_input.module.css";

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const options = [
  { id: "1", value: "Award Category 1", label: "Award Category 1" },
  { id: "2", value: "Award Category 2", label: "Award Category 2" },
  { id: "3", value: "Award Category 3", label: "Award Category 3" },
  { id: "4", value: "Award Category 4", label: "Award Category 4" },
  { id: "5", value: "Award Category 5", label: "Award Category 5" },
  { id: "6", value: "Award Category 6", label: "Award Category 6" },
  { id: "7", value: "Award Category 7", label: "Award Category 7" },
  { id: "8", value: "Award Category 8", label: "Award Category 8" },
];

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
export class UpdateWinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winnerDetails: {},
      winnerId: this.props.match.params.id,
      title: "",
      content: "",
      videoLink: "",
      selectedCategory: null,
      errors: "",
    };
  }

  // validate winner update
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.title || this.state.title.trim() == "") {
      formIsValid = false;
      errors["name"] = "Please enter winner name";
    }
    if (!this.state.content || this.state.content.trim() == "" || this.state.content === '<p><br></p>') {
      formIsValid = false;
      errors["content"] = "Please enter winner content";
    }
    if (this.state.videoLink !== "" && this.state.videoLink !== null && this.state.videoLink !== undefined) {
      const re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      if (!re.test(this.state.videoLink)) {
        formIsValid = false;
        errors["videoLink"] = "Please enter a valid link";
      }
    }
    if (
      this.state.selectedCategory === null ||
      this.state.selectedCategory === undefined ||
      this.state.selectedCategory.length === 0
    ) {
      formIsValid = false;
      errors["category"] = "Please select award category";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
    return formIsValid;
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  //   title change
  titleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  //   video url change
  videoChange = (event) => {
    this.setState({
      videoLink: event.target.value,
    });
  };

  // handle content change
  contentChange = (value) => {
    this.setState({ content: value });
  };

  //   category change
  onCategoryChange = (data) => {
    this.setState({
      selectedCategory: data.value,
    });
  };

  //   fetch winner details
  fetchWinnerDetails = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/peopleOfNature/getById?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            winnerDetails: response.data.body,
            title: response.data.body.name,
            content: response.data.body.description,
            videoLink: response.data.body.videoUrl,
            selectedCategory: response.data.body.category,
            videoLink: response.data.body.videoUrl,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  modules = {
      toolbar: [
          [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ align: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
      ],
      clipboard: {
          matchVisual: false,
      },
      imageResize: {
          parchment: Quill.import('parchment'),
          handleStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      imageCompress: {
          quality: 0.7, // default
          maxWidth: 750, // default
          maxHeight: 500, // default
          imageType: 'image/jpeg' + 'image/png', // default
      },
  };

  componentDidMount() {
    this.fetchWinnerDetails();
  }

  // update winner details
  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let postData = {
        id: this.state.winnerId,
        name: this.state.title,
        description: this.state.content,
        videoUrl: this.state.videoLink,
        status: this.state.winnerDetails.status,
        type: "WINNER",
        category: this.state.selectedCategory,
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/backend/peopleOfNature/update?type=winner`,
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
              response &&
              response.data &&
              response.data.status === "202 ACCEPTED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    const { title, content, videoLink, selectedCategory } = this.state;

    return (

      <CommonHeader>
        <div className="topglobal">
          <Link to="/winner" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <UploadFiles
                accept=".jpg,.png,.jpeg"
                label="Cover Image"
                updateImage={this.state.winnerDetails.coverImageUrl}
                updateFilesCb={this.updateUploadedFiles}
              />
              <FormInput
                group={"large"}
                value={title}
                label={"Winner Name"}
                onChange={this.titleChange}
                error={this.state.errors["name"]}
              />
              <FormInput
                group={"large"}
                value={videoLink}
                label={"Video Url"}
                onChange={this.videoChange}
                className={'not_required'}
                error={this.state.errors["videoLink"]}
              />
              <div className={`form-group`}>
                <label>
                  Content <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                      value={content}
                      onChange={this.contentChange}
                      modules={this.modules}
                  />
                </div>
                {this.state.errors["content"] && (
                  <small className={style.danger}>
                    {this.state.errors["content"]}
                  </small>
                )}
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {`Award Category`} <span className="text-danger">*</span>
                  </label>
                  <Select options={options} value={{
                        value: selectedCategory,
                        label: selectedCategory,
                      }} onChange={this.onCategoryChange} />
                    {this.state.errors["category"] && (
                    <small className={style.danger}>
                      {this.state.errors["category"]}
                    </small>
                  )}
                </div>
              </div>
              {auth_service.getPermission("People_Of_Nature_Winner_Update", "People_Of_Nature_Winner_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Update"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default UpdateWinner;
