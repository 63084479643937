import React from "react";
import style from "./form_input.module.css";
import PropTypes from "prop-types";

const FormInput = ({
  name,
  type,
  placeholder,
  onChange,
  className,
  value,
  error,
  children,
  label,
  group,
  showPreSection,
    notNull,
  ...props
}) => {
  return (
    <div className={`form-group ${group}`}>
      {label && (
        <label>
          {label} {(!notNull && className === '') &&  <span className="text-danger">*</span>}
        </label>
      )}
      <div className={showPreSection ? "input-group mb-3" : ""}>
        {showPreSection && <span className="input-group-text" id="basic-addon1">{props?.preValue}</span>}
        <input
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={props.onBlur}
          value={value}
          //className={style.formControl}
          maxlength={props.maxlength ? props.maxlength : ""}
          className="form-control"
          style={error && { border: "solid 1px red" }}
          disabled={props.isDisable}
        />
      </div>
      {error && <small className={style.danger}>{error}</small>}
    </div>
  );
};

FormInput.defaultProps = {
  type: "text",
  className: "",
};

FormInput.prototype = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default FormInput;
