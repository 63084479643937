import React, { Component } from 'react'
import styles from "../marketing.module.css";
import { Link } from 'react-router-dom';
import { CommonHeader } from '../../../utils/commonHeader';
import CommonCreditPointsList from './CommonCreditPointsList';
import { auth_service } from '../../../auth/auth_service';

class CreditPointsList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <CommonCreditPointsList pageType={"CREDIT_POINTS"}/>
                    </div>
                </div>
                {auth_service.getPermission("CREDIT_POINT_ADD", "CREDIT_POINT_FullAccess") && <Link to="/credit-points/create" className={`btn ${styles.createforumbtn}`}>
                    <i className="iAddCircle"></i> Add Credit
                </Link>}
            </CommonHeader>

        )
    }
}

export default CreditPointsList;