import React, { Component } from "react";
import { auth_service } from "../../../auth/auth_service";
import styles from "../../admin_panel/Role/roles_list.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../utils/commonHeader";
import CommonSubscriptionListPage from "../CommonSubscriptionListPage";

export class AmountTenure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData()
    };
  }

  render() {
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <CommonSubscriptionListPage type="AMOUNT_TENURE" />
        </div>
        <Link
          to="/subscription/amount-tenure/create"
          className={`btn ${styles.createforumbtn}`}
        >
          <i className="iAddCircle"></i> Create Subscription
        </Link>
      </CommonHeader>
    );
  }
}

export default AmountTenure;
