// Utc to dd/mm/yyyy formatting
export const utcTodate = (date) => {
  return date.toLocaleDateString("es-CL");
};

// Epoch date to GMT conversion
export const epochTogmt = (date) => {
  var newDate = new Date(date).toLocaleDateString("en-US")
  return newDate;
};

export const epochTogmtDateTime = (date) => {
  var newDate = new Date(date);
  return newDate.toLocaleString("en-US");
}

// trim html description
export const trimDescription = (item) => {

  if (item !== null && item !== undefined) {
    var trimmed = item.split("^^__").join(" ").indexOf("@@@__");

    var str = item
      .split("^^__")
      .join(" ")
      .indexOf(" ", item.split("^^__").join(" ").indexOf("@@@__"));

    var sub = item.substring(trimmed, str);

    item = item.replace(" " + sub, "");
    item = item.replace("@@@^^^", " ");
    item = item.replace("@@@__", " ");
    item = item.replace("  ", "");

    item = item.replace(/&nbsp;/g, " ");
    item = item.replace(/<br\s*[\/]?>/gi, "\n");

    const regex = /(<([^>]+)>)/gi;
    item = item.replace(regex, "");

    return item.split("^^__").join(" ");
  } else {
    return null;
  }
};

export const numberFormatter = (number) => {
  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export let confirmModalSwal = (type, userName) => {
  return ({
    title: `<h5>${type !== "GENERATE_CODE" ? `Are you sure you want to update ${type === "ENTITY" ? "entity" : type === "DATE" ? "date" : "code"}` : `Are you sure you want to generate Subdomain code for this ${userName}`}?</h5>`,
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
  })
};

export let confirmDeleteModalSwal = () => {
  return ({
    title: '<h5>Are you sure you want to Delete?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
  })
}

export const isYouTubeLink = (url) => {
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=([^&\s]+)|embed\/([^\/\s]+)|([^\/\s]+))$/;
  return regex.test(url);
}

export const isVideoFileValid = (url) => {
  let idSlash = url.lastIndexOf("/") + 1;
  let extFile = url.substr(idSlash, url.length).toLowerCase();
  const idxDot = extFile.lastIndexOf(".") + 1;
  const extFileExtension = extFile.substr(idxDot, extFile.length).toLowerCase();
  const allowedAttachmentsTypeArr = [ "mp4","webm","mkv", "mov","avi","ogg","flv","wmv","mpeg"];
  let isValidVideoLink = allowedAttachmentsTypeArr.includes(extFileExtension)
  return isValidVideoLink;
}

export const fundRaiseConfirmModalSwal = (flag) => {
  return({
    title: `<h5>Are you sure you want to ${!flag ? "enable" : "disable"} restrict fund raise?</h5>`,
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
  })
};