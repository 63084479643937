import StatusDrop from "../../../components/StatusDropDown/status_drop";
import { epochTogmt } from "../../../utils/common";
import {Link} from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";
export const tableDeletePostConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Post Type",
      cell: (row) => {
        return (          
          <>
            <Link
              to={`/report/delete-post-details/${row.id}`}
              style={{ color: "#154A59" }}
            >
              <span className="sub">{row.postType}</span>
            </Link>
            {row.autoDeleted && 
                <span style={{marginLeft: 'auto', padding: '2px 5px', border: '1px dashed #00394d', borderRadius: '5px', flex: '0 0 auto', maxWidth: 'initial'}}>Auto</span>
            }
          </>
        );
      },
      conditionalCellStyles: [
        {
          when: row => row.systemGenerated,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Entity Created on",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Entity Deleted on",
      selector: (row) => epochTogmt(row.reportedTime),
      sortable: true,
      reorder: true,
    },

    {
      id: 4,
      name: "Number of Reports",
      selector: (row) => row.reportCount,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Link",
      cell: (row) => {
        return (
          <a href={`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/${row.link}/${row.slug}?admin=true`} target="_blank">
            <span className="sub">{`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/${row.link}/${row.slug}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      cell: (row) => {
        return <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Deleted_Post_Action") ? "" : "disabled") } onClick={(e) => {handleConfirmModal(row.id);}} disabled={!auth_service.getPermission("Report_Deleted_Post_Action")}>
          Reactive
        </button>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
