import axios from "axios";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { auth_service } from "../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const WpondStatusDrop = ({ children, id, status, type, ...props }) => {
  const statusChange = (id, status) => {
    const formData = new FormData();
    let postData = {
      status: status,
      id: id,
    };
    let url;
    if (type === "year") {
      postData.year = props.year
      url = "wpondYear/update";
    } else {
      postData.year = props.year
      url = "wpondAbout/updateStatus";
    }
    formData.append("data", JSON.stringify(postData));
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_userServiceURL}/${url}`,
      data: postData,
      withCredentials: true,
    })
      .then((response) => {
        props.action();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        ErorrMsgPopUp.fire({
          didOpen: () => {
            ErorrMsgPopUp.clickConfirm()
          }
        }).then(() => {
          return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
        })
      });
  };
  return (
    <div className="dgdropdown">
      <button
        type="button"
        className="btn dropdown-toggle"
        data-toggle="dropdown"
      >
        {children}
      </button>
      <div className="dropdown-menu fadeIn animated">
        {(!auth_service.getPermission(props.fullPermission) && !auth_service.getPermission(props.upcomingPermission) && !auth_service.getPermission(props.completedPermission) && !auth_service.getPermission(props.disablePermission)) ?
          (<button
            className={
              "dropdown-item"
            }
          >
            Permission Denied
            </button>) : (
              <button
                className={"disabled dropdown-item"}
              >
                Draft
            </button>
          )
        }
        {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.upcomingPermission)) && 
        <button
            className={
              children === "Upcoming" ? "disabled dropdown-item" : "dropdown-item"
            }
            onClick={(e) => {
              statusChange(id, 'UPCOMING');
            }}
          >
            Upcoming
        </button>}
        {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.completedPermission)) &&
        <button
            className={
              children === "Completed" ? "disabled dropdown-item" : "dropdown-item"
            }
            onClick={(e) => {
              statusChange(id, 'COMPLETED');
            }}
          >
            Complete
        </button>}
        {(auth_service.getPermission(props.fullPermission) || auth_service.getPermission(props.disablePermission)) &&
        <button
            className={
              children === "Disable" ? "disabled dropdown-item" : "dropdown-item"
            }
            onClick={(e) => {
              statusChange(id, 'DISABLE');
            }}
          >
            Disable
        </button>}
      </div>
    </div>
  );
};

export default WpondStatusDrop;
