export const data = [
  {
    id: "6156ada50136125785a753a6",
    name: "Webuzz notification for test case",
    desc: "WEBUZZ NOTIFICATION FOR TEST CASE",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.633070501719e12,
    lastUpdate: 1.633070501719e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5ff4658252a97a324dc5a6ac",
    name: "Webuzz test notification",
    desc: "Notification",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.609852290848e12,
    lastUpdate: 1.632750240775e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 1.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "613c6658ae4d772903489b26",
    name: "testing sep",
    desc: "testing sep ter",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.631348312938e12,
    lastUpdate: 1.631348312938e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "613b2667b1e97262f4362e71",
    name: "Unauth Message Webuzz",
    desc: "UNAUTH MESSAGE WEBUZZ",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.631266407126e12,
    lastUpdate: 1.631266407126e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5ea91ce034b8da3773fd76a7",
    name: "Corona Virus News",
    desc: "This chat group consist of people working in Corona virus treatment related work.",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.588141280675e12,
    lastUpdate: 1.628862351844e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5ef48868de5ecb11d75723d7",
    name: "new vaccine for corona +ve people",
    desc: "new vaccine has been implemented for +ve people by glenmark",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.593084008357e12,
    lastUpdate: 1.626679730455e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f4faafba3840b6e0e3d9698",
    name: "Websocket messages testing",
    desc: "Testing messages",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.599056635587e12,
    lastUpdate: 1.623090420435e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 1.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f64611138ae5700375006b2",
    name: "Testing messages",
    desc: "testing messages",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.600413969111e12,
    lastUpdate: 1.608120882399e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 1.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f58d672a3840b6e0e3d969f",
    name: "WeBuzz description TESTING",
    desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.599657586249e12,
    lastUpdate: 1.606720770511e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 1.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5faa584142957704a8aa5723",
    name: "Testing webuzz",
    desc: "webuzz",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.604999233793e12,
    lastUpdate: 1.604999845564e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 1.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5faa5a4b42957704a8aa5724",
    name: "Retesting webuzz",
    desc: "Retest",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.604999755428e12,
    lastUpdate: 1.604999826245e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f66ff9a38ae5700375006b4",
    name: 'Testing for question caps "important"?',
    desc: 'testing description - @ "special cahracter" post. Need to be specific.',
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.600585626396e12,
    lastUpdate: 1.60429867174e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 1.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f0de7775578b976394d9ad5",
    name: "test webuzz",
    desc: "test",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.594746743205e12,
    lastUpdate: 1.601055494711e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f66fe7838ae5700375006b3",
    name: "what impact can naturalists bring to the society?",
    desc: "Naturalists have been plating a vital role in the upliftment of the society in various ways. Share your views and contributions you like to refer.",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.60058533619e12,
    lastUpdate: 1.60058533619e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5f40f4fdd870175c7be03925",
    name: "NAture TEACHES us in its own way| nature|Lesson",
    desc: "NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON  NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON  NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON    NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON    NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON    NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON   NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON   NATURE TEACHES US IN ITS OWN WAY| NATURE|LESSON",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.598092541544e12,
    lastUpdate: 1.598092541544e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5efd745090a2a00a05433019",
    name: "Lock down extended",
    desc: "LOCKDOWN EXTENDED UPTO 31ST JULY",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.593668688376e12,
    lastUpdate: 1.593668688376e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5ef45bbfde5ecb11d75723d3",
    name: "Extension of lockdown",
    desc: "coronavirus making the people scary",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.593072575439e12,
    lastUpdate: 1.593072575439e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "PUBLISHED",
  },
  {
    id: "5ef19dbec982914a852a8f2a",
    name: "India China clash",
    desc: "This group consist of people interested in international politics.",
    members: [],
    admins: [],
    domain: "Travel",
    messageGroupType: "NewsGroup",
    active: true,
    createTime: 1.59289286296e12,
    lastUpdate: 1.59289286296e12,
    participantCnt: 0.0,
    messageCnt: 0.0,
    pinned: 0.0,
    groupPublished: "No",
    requestSent: [],
    exitUsers: [],
    groupDeletedByUsers: [],
    status: "DISABLED",
  },
];
