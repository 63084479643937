import axios from "axios";
import React, { Component } from "react";
import styled from "styled-components";
import Button from "../../components/Buttons/button";
import FormInput from "../../components/InputFields/form_input";
import styles from "../wpond/UpcomingYear/UpcomingYearList/year.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CommonHeader } from "../../utils/commonHeader";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export class ProfileCompletionConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { definesYouBest: "", defineYourSelf: "", experience: "", skills: "", specialization: "", interest: "", bio: "", supportedCause: "", education: "", awards: "", website: "", profileImage: "", coverImage: "" },
            isBtnShowDisabled: false,
            errors: {}
        };
    }

    //handle change
    handleValueChange = (event, fieldName) => {
        this.setState((prevState) => ({
            formData: {...prevState.formData,[fieldName]: event.target.value?.replace(/^\D+/g, '')},
            isBtnShowDisabled: true
        }));
    };

    validateForm = () => {
        let errors = {};
        let formIsValid = true;

        this.setState({
            errors: errors,
        });
        {setTimeout(() => {
            this.setState({
                errors: {}
            });
        }, 5000)}
        return formIsValid;
    }

    onSubmit = () => {
        if (this.validateForm()) {
            let formData = this.state.formData;
            let postData = {
                definesYouBest: formData?.definesYouBest,
                defineYourSelf: formData?.defineYourSelf,
                experience: formData?.experience,
                skills: formData?.skills,
                specialization: formData?.specialization,
                interest: formData?.interest,
                bio: formData?.bio,
                supportedCause: formData?.supportedCause,
                education: formData?.education,
                awards: formData?.awards,
                website: formData?.website,
                profileImage: formData?.profileImage,
                coverImage: formData?.coverImage,
                reminder: formData?.reminder
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/profile/completion/config/create`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response.data.message === "Success!") {
                    this.setState({
                        isBtnShowDisabled: false
                    })
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{"Data is saved successfully"}</p>)
                    })
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    fetchProfileCompletionConfigData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/profile/completion/config/get`,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        }).then((response) => {
            if (response.data.message === "Success!" && response?.data?.body?.id) {
                let data = response?.data?.body;
                this.setState({
                    formData: data
                })
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.fetchProfileCompletionConfigData();
    }

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }


    render() {
        const { formData, isBtnShowDisabled, errors } = this.state;
        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <h2>{"Profile Completion Config"}</h2>
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.definesYouBest}
                                        label={"Define you best"}
                                        onChange={(event) => this.handleValueChange(event, "definesYouBest")}
                                        name={"definesYouBest"}
                                        error={errors["definesYouBest"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.defineYourSelf}
                                        label={"Define yourself"}
                                        onChange={(event) => this.handleValueChange(event, "defineYourSelf")}
                                        name={"defineYourSelf"}
                                        error={errors["defineYourSelf"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.experience}
                                        label={"Experience"}
                                        onChange={(event) => this.handleValueChange(event, "experience")}
                                        name={"experience"}
                                        error={errors["experience"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.skills}
                                        label={"Skills"}
                                        onChange={(event) => this.handleValueChange(event, "skills")}
                                        name={"skills"}
                                        error={errors["skills"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.specialization}
                                        label={"Specialization"}
                                        onChange={(event) => this.handleValueChange(event, "specialization")}
                                        name={"specialization"}
                                        error={errors["specialization"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.interest}
                                        label={"Interest"}
                                        onChange={(event) => this.handleValueChange(event, "interest")}
                                        name={"interest"}
                                        error={errors["interest"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.bio}
                                        label={"Bio"}
                                        onChange={(event) => this.handleValueChange(event, "bio")}
                                        name={"bio"}
                                        error={errors["bio"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.supportedCause}
                                        label={"Supported Cause"}
                                        onChange={(event) => this.handleValueChange(event, "supportedCause")}
                                        name={"supportedCause"}
                                        error={errors["supportedCause"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.education}
                                        label={"Education"}
                                        onChange={(event) => this.handleValueChange(event, "education")}
                                        name={"education"}
                                        error={errors["education"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.awards}
                                        label={"Awards"}
                                        onChange={(event) => this.handleValueChange(event, "awards")}
                                        name={"awards"}
                                        error={errors["awards"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.website}
                                        label={"Website"}
                                        onChange={(event) => this.handleValueChange(event, "website")}
                                        name={"website"}
                                        error={errors["website"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.profileImage}
                                        label={"Profile Image"}
                                        onChange={(event) => this.handleValueChange(event, "profileImage")}
                                        name={"profileImage"}
                                        error={errors["profileImage"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.coverImage}
                                        label={"Cover Image"}
                                        onChange={(event) => this.handleValueChange(event, "coverImage")}
                                        name={"coverImage"}
                                        error={errors["coverImage"]}
                                        className={"not-required"}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FormInput
                                        group={"large"}
                                        value={formData?.reminder}
                                        label={"Reminder(In days)"}
                                        onChange={(event) => this.handleValueChange(event, "reminder")}
                                        name={"reminder"}
                                        error={errors["reminder"]}
                                        className={"not-required"}
                                    />
                                </div>
                            </div>

                            {isBtnShowDisabled &&
                                <ButtonWrap className={`${styles.bottombutt}`}>
                                    <Button children={"Submit"} onClick={this.onSubmit} />
                                </ButtonWrap>
                            }
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default ProfileCompletionConfig;