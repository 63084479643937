import React, { useState, useRef, useEffect } from "react";
import styles from "../../pages/causes/CreateCause/create_cause.module.css";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);
const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const UploadFiles = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  updateImage,
  ...props
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});
  const [previewImage, setPreviewImage] = useState([]);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      var url = URL.createObjectURL(file);
      setPreviewImage(props.fileType === "VIDEO" ? "https://cdn.dscovr.com/images/welogo-notific.png" : url);
      if (file.size < maxFileSizeInBytes) {
        if (!props.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };


  const removeLogo = () => {
    setPreviewImage([]);
    props.removeUploadedLogoFiles();
  }

  useEffect(() => {
    setPreviewImage(updateImage);
  }, [updateImage]);

  return (
    <div className={"form-group text-center " + (props.imagePresent === 'noImage' ? 'error' : '')}>
      <div style={{ display: "inline-block" }}>
        <div className={`${styles.causesformuploadimg} upBlk` + (props.logoUploadFiles ? " logoUploadFiles" : "")}>
          <input
            type="file"
            onChange={handleNewFileUpload}
            ref={fileInputField}
            title=""
            className={`${styles.file2}`}
            value=""
            {...props}
          />
          <button
            type="button"
            onClick={handleUploadBtnClick}
            className={`${styles.projbrowse} ${styles.btn}`}
          >
            <div>
              <i className={props.fileType === "VIDEO" ? "iAddVideo" : "iUploadPhoto"}></i>
              Add {label}
            </div>
            <img src={previewImage} id="preview2" alt="preview" />
          </button>

          {props.logoUploadFiles && props.removeLogoFlag && <button type="button" class="propicdel btn sponsors-remove" onClick={(e) => removeLogo()}><i class="iTrashBin" /></button>}
        </div>
        <div className={`${styles.recommendimg}`}>
          {props.fileType !== "VIDEO" && (!props.logoUploadFiles ? "Recommended size: 552px by 200px." :  "Recommended size: 150px by 150px.")}
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
