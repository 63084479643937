import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { FilterComponent } from "../../../utils/filter";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableConstantsBlog } from "../Data/tableConstantsBlog";
import styles from "./blogs.module.css";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ExportCSV from "../../../components/Buttons/export_csv_button";
import { epochTogmt } from "../../../utils/common";


const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "Blog Title", key: "title" },
  { label: "Source", key: "source" },
  { label: "Category", key: "category" },
  { label: "Status", key: "status" },
  { label: "Created on", key: "createTime" },
  { label: "Last Updated by", key: "updatedByName" },
];

export class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
      exportCsvDataList: []
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.blogList.filter(
              (item) =>
                item.title &&
                item.title.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("Explore_Blog_ExportCSV", "Explore_Blogs_FullAccess") && <ExportCSV headers={headers} data={this.state.exportCsvDataList} filename="explore_blog" />}
      </React.Fragment>
    );
  };

  fetchBlog = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/explore/api/explore_blog/getAll?categoryId=all&status=all`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.message === "Success!") {
          let exportCsvDataList = [];
          response.data.body && response.data.body.map((data) => {
            exportCsvDataList.push({"title": data.title, "source": data.source, "category": data.categoryIdList, "status": data?.status, "createTime": epochTogmt(data?.createTime), "updatedByName": data?.updatedByName})
          })
          this.setState({
            blogList: response.data.body,
            exportCsvDataList: exportCsvDataList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/explore_service/api/explore_blog/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchBlog();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    this.fetchBlog();
  }
  render() {
    const { blogList, filterText } = this.state;
    this.filteredItems = blogList.filter(
      (item) =>
        item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title="Blogs"
              columns={tableConstantsBlog(this.fetchBlog, this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
        {auth_service.getPermission("Explore_Blogs_Create", "Explore_Blogs_FullAccess") && (
          <Link to="/blogs/create" className={`btn ${styles.createforumbtn}`}>
            <i className="iAddCircle"></i> Create Blog
          </Link>
        )}
      </CommonHeader>
    );
  }
}

export default Blogs;
