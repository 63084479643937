import { epochTogmt } from "../../../../../../../../../utils/common";

export const tableProjectParticipantsConstants = () => {
  return [
    {
      id: 1,
      name: "Project Id",
      selector: (row) => row.projectId,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
      reorder: true,
      grow: 1,
    },
    {
      id: 3,
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Joining Date",
      selector: (row) => epochTogmt(row.joiningDate),
      sortable: true,
      reorder: true,
    },
  ];
};
