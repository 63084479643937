import { Quill } from "react-quill";
export const icons = Quill.import('ui/icons');
icons.header[3] = `
<svg viewBox="0 0 18 16">    
    <path class="ql-fill" d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
    <text id="3" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
        <tspan x="11.3" y="11">3</tspan>
    </text>
</svg>`
icons.header[4] = `
<svg viewBox="0 0 18 16">    
    <path class="ql-fill" d="M9,1 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,7 L2,7 L2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 0,11.5522847 0,11 L0,1 C-1.11022302e-16,0.44771525 0.44771525,0 1,0 C1.55228475,0 2,0.44771525 2,1 L2,5 L7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 Z" id="Shape" fill-rule="nonzero"></path>
    <text id="3" font-family="PingFangSC-Semibold, PingFang SC" font-size="9" font-weight="500">
        <tspan x="11.3" y="11">4</tspan>
    </text>
</svg>`