import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../../../../components/Buttons/button";
import FormInput from "../../../../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../../../../utils/commonHeader";
import styles from "../../../../year.module.css";
import { auth_service } from "../../../../../../../../auth/auth_service";
import UploadFiles from "../../../../../../../../components/UploadFiles/upload_files";
import history from "../../../../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../../../../components/InputFields/form_input.module.css";
import stripHtml from "string-strip-html";
import LocationInput from "../../../../../../../../components/LocationFields/location_input";
import MagicUrl from "quill-magic-url";

const ErorrMsgPopUp = withReactContent(alertPopUp);


const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

export class CreateNomination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: auth_service.getUserData(),
            selectedYear: this.props.match.params.year,
            awardCategorySlug: this.props.match.params.slug,
            coverImage: null,
            title: "",
            description: '',
            nominatedBy: "",
            country: "",
            states: "",
            city: "",
            userId: "",
            coverImageMessage: '',
            errors: "",
        };
    }
    validate = () => {
        let errors = {};
        let formIsValid = true;
        if (this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) {
            formIsValid = false;
            this.setState({
                coverImageMessage: "Please select cover image"
            })
        }
        if (!this.state.title || this.state.title.trim() === "") {
            formIsValid = false;
            errors["title"] = "Please enter name";
        }
        if (!this.state.country) {
            formIsValid = false;
            errors["country"] = "Please enter country";
        }
        if (!this.state.states) {
            formIsValid = false;
            errors["state"] = "Please enter state";
        }
        if (!this.state.city) {
            formIsValid = false;
            errors["city"] = "Please enter city";
        }
        // if (!this.state.userId) {
        //     formIsValid = false;
        //     errors["userId"] = "Please enter user id";
        // }
        if (!this.state.description || this.state.description.trim() === "" || stripHtml(this.state.description) == '') {
            formIsValid = false;
            errors["description"] = "Please enter description";
        }
        if (this.state.description && stripHtml(this.state.description) != "" && stripHtml(this.state.description).match(/\S+/g).length > 250) {
            formIsValid = false;
            errors["description"] = "Description should be within 250 words";
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    coverImageMessage: "",
                    errors: {},
                });
            }, 5000);
        }
        return formIsValid;
    };

    // cover image update
    updateUploadedFiles = (files) => {
        this.setState({
            coverImage: files[0],
        });
    };

    //handle change
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart(),
        });
    };

    contentChange = (value) => {
        this.setState({ description: value });
    }

    // country states
    country = (value) => {
        this.setState({
            country: value,
        });
    };

    // states state
    states = (value) => {
        this.setState({
            states: value,
        });
    };

    // city state
    city = (value) => {
        this.setState({
            city: value,
        });
    };


    onSubmit = () => {
        if (this.validate()) {
            const formData = new FormData();
            formData.append("image", this.state.coverImage);
            let postData = {
                year: this.state.selectedYear,
                name: this.state.title,
                description: this.state.description,
                nominatedBy: this.state.userData.firstName + " " + this.state.userData.lastName,
                nominatorId: this.state.userData.userId,
                awardCategorySlug: this.state.awardCategorySlug,
                country: this.state.country,
                state: this.state.states,
                city: this.state.city,
                email: null,
                outSideWenat: false,
                userId: this.state.userData.id,
                project: "",
            };
            formData.append("data", JSON.stringify(postData));
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_userServiceURL}/wpondNomination/create`,
                headers: { "description-Type": "multipart/form-data" },
                data: formData,
                withCredentials: true,
            })
                .then((response) => {
                    if (
                        response &&
                        response.data &&
                        response.data.status === "201 CREATED"
                    ) {
                        history.goBack();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    ErorrMsgPopUp.fire({
                        didOpen: () => {
                            ErorrMsgPopUp.clickConfirm()
                        }
                    }).then(() => {
                        return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                    })
                });
        }
    };
    modules = {
        toolbar: [
            [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ align: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
        ],
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            handleStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white',
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        imageCompress: {
            quality: 0.7, // default
            maxWidth: 750, // default
            maxHeight: 500, // default
            imageType: 'image/jpeg' + 'image/png', // default
        },
        magicUrl: {
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
            globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        },
    };
    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }
    render() {
        const { title, description, userId } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to={`/wpond/${this.state.selectedYear}/award-categories/${this.state.awardCategorySlug}/nominations`} className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <UploadFiles
                                accept=".jpg,.png,.jpeg"
                                label="Cover Image"
                                updateFilesCb={this.updateUploadedFiles}
                                imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
                            />
                            {this.state.coverImageMessage &&
                                <div className="text-center">
                                    <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                                </div>
                            }
                            <FormInput
                                group={"large"}
                                value={title}
                                label={"Title"}
                                name={'title'}
                                onChange={this.handleChange}
                                error={this.state.errors["title"]}
                            />
                            {/* <FormInput
                                group={"large"}
                                value={userId}
                                label={"User Id"}
                                name={'userId'}
                                onChange={this.handleChange}
                                error={this.state.errors["userId"]}
                            /> */}
                            <LocationInput
                                country={this.country}
                                state={this.states}
                                city={this.city}
                                isMandatory={true}
                                countryValidate={this.state.errors["country"]}
                                stateValidate={this.state.errors["state"]}
                                cityValidate={this.state.errors["city"]}
                            />
                            <div className={"form-group " + (this.state.errors["description"] ? 'error' : '')}>
                                <label>
                                    Description <span className="text-danger">*</span>
                                </label>
                                <div className="reactQuillWrap">
                                    <ReactQuill
                                        value={description}
                                        onChange={this.contentChange}
                                        modules={this.modules}
                                    />
                                </div>
                                {this.state.errors["description"] && (
                                    <small className={style.danger}>
                                        {this.state.errors["description"]}
                                    </small>
                                )}
                            </div>
                            <ButtonWrap className={`${styles.bottombutt}`}>
                                <Button children={"Submit"} onClick={this.onSubmit} />
                            </ButtonWrap>
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default CreateNomination;
