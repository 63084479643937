import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import TextArea from "../../../components/TextArea/text_area";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "../message.module.css";
import { auth_service } from "../../../auth/auth_service";
import TagsInput from "react-tagsinput";
import stripHtml from "string-strip-html";
import style from "../../../components/InputFields/form_input.module.css";
import history from "../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ErorrMsgPopUp = withReactContent(alertPopUp);


const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end;
`;
export class CreateMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      metaKeyWords: [],
      metaTitle: "",
      metaDescription: "",
      errors: "",

    };
  }

  // handle title change
  titleChange = (event) => {
    this.setState({
      title: event.target.value,
    });
  };

  //   description change
  descriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });
  };
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter  title";
    }

    if (!this.state.description || this.state.description.trim() === "" ) {
      formIsValid = false;
      errors["description"] = "Please enter description";
    }
    if (!this.state.metaTitle) {
      formIsValid = false;
      errors["metaTitle"] = "Please enter meta title";
    }
    if (!this.state.metaDescription || this.state.metaDescription.trim() === "" || this.state.metaDescription === "<p><br></p>") {
      formIsValid = false;
      errors["metaDescription"] = "Please enter meta description";
    }
    if (
        this.state.metaKeyWords === null ||
        this.state.metaKeyWords === undefined ||
        this.state.metaKeyWords.length === 0
    ) {
      formIsValid = false;
      errors["metaKeyWords"] = "Please enter a meta keywords";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          coverImageMessage: "",
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

   //handle change
   handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  // handle meta keywords change
  metaKeyWordsChange = (keywords) => {
    this.setState({ metaKeyWords: keywords });
  };
  onSubmit = () => {
    if(this.validate()) {
      let postData = {
        content: this.state.description,
        desc: this.state.description,
        domain: "Travel",
        messageGroupType: "NewsGroup",
        name: this.state.title,
        title: this.state.title,
        keyWords: this.state.metaKeyWords,
        metaTitle: this.state.metaTitle,
        metaDescription: this.state.metaDescription,
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/messaging/message_service/api/message_group/create`,
        data: postData,
        withCredentials: true,
      })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.message === "Success!"
            ) {
              history.goBack();
            }
            if (
              response &&
              response.data &&
              response.data.resultType === "failure"
            ) {
              ErorrMsgPopUp.fire({
                didOpen: () => {
                  ErorrMsgPopUp.clickConfirm()
                }
              }).then(() => {
                return ErorrMsgPopUp.fire(<p>{response.data.message}</p>)
              })
            }
          })
          .catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
              didOpen: () => {
                ErorrMsgPopUp.clickConfirm()
              }
            }).then(() => {
              return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
          });
    }
  };
  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }
  componentDidMount() {}
  render() {
    const { title, description,
      metaTitle,
      metaKeyWords,
      metaDescription,} = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/messages" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <FormInput
                group={"large"}
                value={title}
                label={"Topic title"}
                name={"title"}
                onChange={this.handleChange}
                error={this.state.errors["title"]}
              />
              <TextArea
                label={"Description"}
                value={description}
                name={"description"}
                onChange={this.handleChange}
                error={this.state.errors["description"]}
              />
              <FormInput
                  group={"large"}
                  value={metaTitle}
                  label={"Meta Title"}
                  name={"metaTitle"}
                  onChange={this.handleChange}
                  error={this.state.errors["metaTitle"]}
              />
              <TextArea
                  label={"Meta Description"}
                  value={metaDescription}
                  name={"metaDescription"}
                  onChange={this.handleChange}
                  error={this.state.errors["metaDescription"]}
              />
              <div className={"form-group " + (this.state.errors["metaKeyWords"] ? 'error' : '')}>
                <label>
                  {`Meta Keywords `}
                  <span className="text-danger">*</span>{" "}
                </label>
                <TagsInput
                    value={metaKeyWords}
                    onChange={this.metaKeyWordsChange}
                    addKeys={[9, 13, 62, 188]}
                />
                {this.state.errors["metaKeyWords"] && (
                    <small className={style.danger}>
                      {this.state.errors["metaKeyWords"]}
                    </small>
                )}
              </div>
              {auth_service.getPermission("Messages_Create", "Messages_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Submit"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreateMessage;
