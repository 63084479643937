// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".how_we_impact_category_list_createforumbtn__3LHwg {\n    font-family: \"Montserrat\", sans-serif;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 15px;\n    color: #f7f7f5;\n    background: #00394d;\n    border-radius: 22px;\n    text-transform: uppercase;\n    padding: 12px 18px 12px 12px;\n    position: fixed;\n    right: 30px;\n    bottom: 30px;\n  }\n  .how_we_impact_category_list_createforumbtn__3LHwg i {\n    margin-right: 8px;\n  }\n  .how_we_impact_category_list_createforumbtn__3LHwg:hover {\n    background: #154a59;\n    color: #ffffff !important;\n  }\n  ", "",{"version":3,"sources":["webpack://src/pages/how_we_impact/how_we_impact_category_list.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,4BAA4B;IAC5B,eAAe;IACf,WAAW;IACX,YAAY;EACd;EACA;IACE,iBAAiB;EACnB;EACA;IACE,mBAAmB;IACnB,yBAAyB;EAC3B","sourcesContent":[".createforumbtn {\n    font-family: \"Montserrat\", sans-serif;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 15px;\n    color: #f7f7f5;\n    background: #00394d;\n    border-radius: 22px;\n    text-transform: uppercase;\n    padding: 12px 18px 12px 12px;\n    position: fixed;\n    right: 30px;\n    bottom: 30px;\n  }\n  .createforumbtn i {\n    margin-right: 8px;\n  }\n  .createforumbtn:hover {\n    background: #154a59;\n    color: #ffffff !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createforumbtn": "how_we_impact_category_list_createforumbtn__3LHwg"
};
export default ___CSS_LOADER_EXPORT___;
