import React from "react";

export const tableConstants = () => {
  return [
    {
      id: 1,
      name: "Username",
      cell: (row) => {
        return <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
          <span className="sub">{row.username}</span>
        </a>
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Email",
      cell: (row) => {
        return <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
          <span className="sub">{row.type}</span>
        </a>
      },
      sortable: true,
      reorder: true,
    },
  ];
};
