import React from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../../../auth/auth_service";

export const tableConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Username",
      cell: (row) => {
        return <>
          <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
            <span className="sub">{row.userName}</span></a>
          {row.licenseAllowed && auth_service.getUserData().email === "punam@wsegroup.in" &&
            <p style={{margin: '0 0 0 auto', flex: '0 0 auto', maxWidth: 'initial'}}>
            <Link to={{pathname: `/user/individual/generate-code/${row.userId}`, state: {"userName": row.userName}}} style={{padding: '3px 4px', border: '1px dashed #00394d', borderRadius: '50%',}}>
              <span className="sub"><i className="iInfo_I" /></span>
            </Link>
            </p>
          }
        </>
      },
      grow: 2,
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Status",
      selector: (row) => row.userStatus,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "MyWeNat",
      selector: (row) => row?.licenseMember ? "Yes" : "No",
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Subscription",
      cell: (row) => {
        let type = "";
        if (row.subscriptionType == "BRONZE") {
          type = "Standard";
        } else if (row.subscriptionType == "SILVER") {
          type = "Professional"
        } else if (row.subscriptionType == "GOLD") {
          type = "Enterprise"
        } else if (row.subscriptionType == "PLATINUM") {
          type = "Grand"
        } else {
          type = row.subscriptionType;
        }
        return (
          <span className="sub">{type}</span>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Email",
      selector: (row) => row.email,
      conditionalCellStyles: [
        {
          when: row => row.emailVerified,
          classNames: ['emailVerified'],
        },
        {
          when: row => !row.emailVerified,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
    },


    /*{
      id: 5,
      name: "Email Status",
      selector: (row) => row.emailVerified ? 'Verified':'Unverified',
      conditionalCellStyles: [
        {
          when: row => row.emailVerified,
          classNames: ['emailVerified'],
        },
        {
          when: row => !row.emailVerified,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
    },*/
    {
      id: 6,
      name: "Country Code",
      cell: (row) => {
        return '+' + row.countryCode
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Phone No.",
      selector: (row) => row.phoneNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Persona",
      selector: (row) => row.persona,
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "No of connects",
      selector: (row) => row.noOfConnects,
      sortable: true,
      reorder: true,
    },
    {
      id: 11,
      name: "No of followers",
      selector: (row) => row.noOfFollowers,
      sortable: true,
      reorder: true,
    },
    {
      id: 12,
      name: "Organization Pages",
      cell: (row) => {
      return ( row.noOfbusinessPages > 0 ?
          <Link 
            to={{
              pathname: `/user/individual/${row.userId}`,
              state: { columnName: 'ORGANIZATION',userName: row?.userName },
            }}
          >
            <span className="sub">{row.noOfbusinessPages}</span>
          </Link> : row.noOfbusinessPages
      );
      },
      sortable: true,
  },
  {
    id: 13,
    name: "Circle",
    cell: (row) => {
    return ( row.noOfCircle > 0 ?
        <Link 
          to={{
            pathname: `/user/individual/${row.userId}`,
            state: { columnName: 'CIRCLE',userName: row?.userName },
          }}
        >
         <span className="sub">{row.noOfCircle}</span>
        </Link> : row.noOfCircle
    );
    },
    sortable: true,
  },
  {
  id: 14,
  name: "No of Projects",
  cell: (row) => {
  return ( row.noOfProjects > 0 ?
      <Link 
        to={{
          pathname: `/user/individual/${row.userId}`,
          state: { columnName: 'PROJECT',userName: row?.userName },
        }}
      >
        <span className="sub">{row.noOfProjects}</span>
      </Link> : row.noOfProjects
  );
  },
  sortable: true,
  },
    {
      id: 15,
      name: "No of  Post",
      selector: (row) => row.noOfPost,
      sortable: true,
      reorder: true,
    },
    {
      id: 16,
      name: "Registered on",
      selector: (row) => row.registerdOn,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 17,
      name: "Last Login",
      selector: (row) => row.lastLogin,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 18,
      name: "Action",
      cell: (row) => {
        return (row.userStatus === "DELETED" ? 
          <button  className={ "btnUpdte"} onClick={(e) => {handleConfirmModal(row.userId);}}  disabled={false}>
            Reactive
          </button> : 
          null);
      },
      sortable: true,
      reorder: true,
    },
  ];
};
