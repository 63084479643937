import StatusDrop from "../../../../components/StatusDropDown/status_drop";
import {Link} from "react-router-dom";
import { epochTogmt } from "../../../../utils/common";
import { auth_service } from "../../../../auth/auth_service";

export const tableDraftUserConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Username",
      cell: (row) => {
        return <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
          <span className="sub">{row.userName}</span>
        </a>
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Email",
      selector: (row) => row.email,
      conditionalCellStyles: [
        {
          when: row => row.emailVerified,
          classNames: ['emailVerified'],
        },
        {
          when: row => !row.emailVerified,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Country Code",
      cell: (row) => {
        return '+' + row.countryCode
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Phone No.",
      selector: (row) => row.phoneNumber,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Persona",
      selector: (row) => row.persona,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Registered on",
      selector: (row) => row.registerdOn,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Last Login",
      selector: (row) => row.lastLogin,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
        id: 9,
        name: "Delete",
        cell: (row) => {
          return <i className={"iTrashBin" + (auth_service.getPermission("AD_DraftUser_Delete") ? '' : ' disable')} onClick={(e) => {handleConfirmModal(row.userId)}}></i>;
        },
        sortable: true,
        reorder: true,
    },
  ];
};
