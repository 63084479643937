import React from "react";
import { Link } from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";
import StatusDetails from "../../../components/StatusDetails/status_details";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import { epochTogmt, epochTogmtDateTime } from "../../../utils/common";
import styles from "../marketing.module.css";
import { formatString } from "./CommonFunction";

// This is the table constant/settings which needed to render table elements
export const tableConstantsNotification = (updateNotificationData, stopNotificationHandle, deleteNotificationHandle) => {
    return [
        {
            id: 1,
            name: "Type",
            cell: (row) => {
                return (
                  <Link to={{ pathname: `/notification/edit/${row.id}`, state: {disabledUpdate: true}}}>
                    <span className="sub">{formatString(row.category)}</span>
                  </Link>
                );
            },
            sortable: true,
            reorder: true,
            //grow: .1,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
          id: 2,
          name: "Execution Date Time",
          selector: (row) => row.executionTime === 0 ? "NA" : epochTogmtDateTime(row.executionTime),
          sortable: true,
          reorder: true,
          //grow: .2,
          style: {
              color: "rgba(0,0,0,.54)",
          },
        },
        {
          id: 8,
          name: "Create Date",
          selector: (row) => row.createTime === 0 ? "NA" : epochTogmt(row.createTime),
          sortable: true,
          reorder: true,
          //grow: .2,
          style: {
              color: "rgba(0,0,0,.54)",
          },
        },
        {
          id: 9,
          name: "Criteria",
          selector: (row) => row.notifierCriteriaType,
          sortable: true,
          reorder: true,
          //grow: .2,
          style: {
              color: "rgba(0,0,0,.54)",
          },
        },
        {
            id: 3,
            name: "Notifier Status",
            cell: (row) => {
                return (
                  <>
                    {row.status}
                    {row.status === 'COMPLETE' && <a href="javascript:void(0)" className={`btn ${styles.btnCreateNoti}`}>Create</a>}
                  </>
                );
              },
            sortable: true,
            reorder: true,
            //grow: .2,
        },
        {
            id: 4,
            name: "Notification content",
            selector: (row) => row.content,
            wrap: true,
            format: row => `${row.content && row.content.slice(0, 390)}...`,
            sortable: true,
            reorder: true,
            style: {
                color: "rgba(0,0,0,.54)",
            },
        },
        
        {
          id: 7,
          name: "Status",
          //grow: .2,
          cell: (row) => {
            return (
              <>
                <StatusDetails status={row.notifierStatus}/>
              </>
            );
          },
          sortable: true,
          reorder: true,
        },
          {
            id: 8,
            name: "Action",
            cell: (row) => {
              let content = "";
              let status = "";
              if (row.notifierStatus === "DRAFT") {
                content = "Approve";
                status = "PENDING_APPROVAL";
              }
              if (row.notifierStatus === "PENDING_APPROVAL") {
                content = "Publish";
                status = "PUBLISH";
              }
              if (row.notifierStatus === "PUBLISH") {
                content = "Enable";
                status = "ENABLE";
              }
              if (row.notifierStatus === "ENABLE") {
                content = "Disable";
                status = "DISABLE";
              }
              if (row.notifierStatus === "DISABLE") {
                content = "Enable";
                status = "ENABLE";
              }
              return (
                <StatusDrop
                  children={content}
                  id={row.id}
                  status={status}
                  type={"notification"}
                  action={() => updateNotificationData(row.id, status)}
                  fullPermission={"Notification_FullAccess"}
                  approvalPermission={"Notification_Approval"}
                  publishPermission={"Notification_Publish"}
                  enablePermission={"Notification_Enable"}
                  disablePermission={"Notification_Disable"}
                />
              );
            },
            sortable: true,
            reorder: true,
            style: {
              color: "#202124",
              fontSize: "14px",
              fontWeight: 500,
            },
          },
          {
            id: 5,
            name: "",
            width: '290px',
            cell: (row) => {
              return (
                <>
                  {(row.notifierStatus !== 'ENABLE' && row.notifierStatus !== 'DISABLE') && auth_service.getPermission("Notification_Update", "Notification_FullAccess") && <Link to={`/notification/edit/${row.id}`} className={`btn ${styles.edtbtn}`}><i class="iEditBox"></i> Edit</Link>}
                  {row.status === 'WAITING' && row.notifierStatus === 'ENABLE' && <a href="javascript:void(0)" className="btn btnUpdte" onClick={(e) => stopNotificationHandle(row.id, "DISABLE")}>Stop</a>}
                  {row.notifierStatus === 'DISABLE' && <a href="javascript:void(0)" className="btn btnUpdte" onClick={(e) => deleteNotificationHandle(row.id)}>Delete</a>}
                </>
              );
            },
            sortable: true,
            reorder: true,
          },
          
    ];
};
