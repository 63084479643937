import React from "react";
import { Link } from "react-router-dom";
import { epochTogmt } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";

export const tableConstantsIndividualsDetails = (activeTableTab) => {
    
    return (
        activeTableTab === "ORGANIZATION" ?
        [
            {
                id: 1,
                name: "Name",
                cell: (row) => {
                    return <a href={process.env.REACT_APP_frontEndURL+'/profile/' + row.customUrl} target="_blank">
                      <span className="sub">{row.companyName}</span>
                    </a>
                  },
                
                sortable: true,
                style: {
                    color: "#202124",
                    fontSize: "14px",
                    fontWeight: 500,
                },
                grow: 1.5,
            },
            {
                id: 2,
                name: "Status",
                selector: (row) => row.status,
                sortable: true,
            },
            {
                id: 3,
                name: "Email id",
                selector: (row) => row.email,
                sortable: true,
            },
            {
                id: 4,
                name: "Country Code",
                cell: (row) => {
                    return '+' + row.countryISDCode
                },
                sortable: true,
            },
            {
                id: 5,
                name: "Phone No.",
                selector: (row) => row.mobile,
                sortable: true,
            },
            {
                id: 6,
                name: "Country",
                selector: (row) => row.country,
                sortable: true,
            },
            {
                id: 7,
                name: "Created on",
                selector: (row) => row?.createTime && epochTogmt(row?.createTime),
                sortable: true,
                reorder: true,
              },
        ] 

        : activeTableTab === "CIRCLE" ? 

        [
            {
                id: 1,
                name: "Name",
                cell: (row) => {
                    return (<a href={`${process.env.REACT_APP_frontEndURL}/circle/${row?.slug}`} target="_blank"><span className="sub">{row.title}</span> </a>)
                  },
                sortable: true,
                style: {
                    color: "#202124",
                    fontSize: "14px",
                    fontWeight: 500,
                },
                grow: 1.5,
            },
            {
                id: 2,
                name: "Country",
                selector: (row) => row.country,
                sortable: true,
            },
            {
                id: 3,
                name: "Type",
                selector: (row) => row.type,
                sortable: true,
            },
            {
                id: 4,
                name: "Status",
                selector: (row) => row.active ? "Active" :"Inactive",
                sortable: true,
            },
            {
                id: 7,
                name: "Created on",
                selector: (row) => row?.createTime && epochTogmt(row?.createTime),
                sortable: true,
                reorder: true,
              },
        ]   
         : 
        [
            {
                id: 1,
                name: "Name",
                cell: (row) => {
                    return (<a href={`${process.env.REACT_APP_frontEndURL}/project/feeds/${row?.slug}?admin=true`} target="_blank"><span className="sub">{row.projectName}</span> </a>)
                  },
                sortable: true,
                style: {
                    color: "#202124",
                    fontSize: "14px",
                    fontWeight: 500,
                },
                grow: 1.5,
            },
            {
                id: 2,
                name: "Start Date",
                selector: (row) => row?.beginningTime && epochTogmt(row?.beginningTime),
                
                sortable: true,
                width: "200px"
              },
              {
                id: 3,
                name: "End Date",
                selector:  (row) => row?.endingTime > 0 ? epochTogmt(row?.endingTime) : '--',
                sortable: true,
                width: "200px"
              },
            {
                id: 4,
                name: "Category",
                selector: (row) => row.category ? row.category : '-' ,
                sortable: true,
            },
            {
                id: 5,
                name: "Type",
                selector: (row) => row.type,
                sortable: true,
            },
            {
                id: 6,
                name: "Entity Type",
                selector: (row) => row.partyType === "CIRCLE" ? "Circle" : "Individual or Recruter",
                sortable: true,
            },
            {
                id: 7,
                name: "Country",
                selector: (row) => row.country,
                sortable: true,
            },

            {
                id: 8,
                name: "Status",
                selector: (row) => row.status,
                sortable: true,
            },
            {
                id: 9,
                name: "Created on",
                selector: (row) => row?.createTime && epochTogmt(row?.createTime),
                sortable: true,
                reorder: true,
            },
        ] 

        
    )
    
};