import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../../auth/auth_service";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import { FilterComponent } from "../../../utils/filter";
import Select from "react-select";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import styles from "../individual/individual_details.module.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "../../../components/Buttons/button";
import { DateRange } from "react-date-range";
import { epochTogmt, utcTodate } from "../../../utils/common";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CSVLink, CSVDownload } from "react-csv";

const ErorrMsgPopUp = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Reactivate this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "Project Name", key: "projectName" },
  { label: "Category", key: "category" },
  { label: "Type", key: "type" },
  { label: "Admins", key: "noOfAdmins" },
  { label: "Created on", key: "createdOn" },
  { label: "Application Recieved", key: "applicationReceived" },
  { label: "Participants", key: "participants" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Country", key: "country" },
  { label: "Status", key: "status" },
  { label: "Post", key: "posts" },
];


// status data
const statusOptions = [
  { value: "ALL", label: "ALL" },
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "CANCELLED", label: "CANCELLED" },
  { value: "DELETED", label: "DELETED" },
];

// date range data
const options = [
  { value: "ALL", label: "ALL" },
  { value: "TODAY", label: "TODAY" },
  { value: "YESTERDAY", label: "YESTERDAY" },
  { value: "LAST7DAY", label: "LAST 7 DAY" },
  { value: "LAST14DAY", label: "LAST 14 DAY" },
  { value: "LASTMONTH", label: "LAST MONTH" },
  { value: "CUSTOM", label: "CUSTOM" },
];
export class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      projectList: [],
      exportProjectList: [],
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRangeButton: false,
      displayRange: false,
      filterText: "",
      filterTextOne: "",
      filterDisplay: false,
      filterData: "",
      statusData: { value: "ALL", label: "ALL" },
      dateData: { value: "ALL", label: "ALL" },
      isEnabledCancelFiler: false,
      businessPagesIds: [],
      ownBusinessIds: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      changeType: 'ALL',
      fileName: "",
      donateNow: false,
      isSearchDisabled: true,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <div class="dgdropdown">
          <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">Search by<strong>{this.state.filterData}</strong></button>
          <div class="dropdown-menu fadeIn animated">
            <a onClick={e => { this.changeSearchData("Name") }} class="dropdown-item">Name</a>
            <a onClick={e => { this.changeSearchData("Country") }} class="dropdown-item">Country</a>
            <a onClick={e => { this.changeSearchData("Type") }} class="dropdown-item">Type</a>
          </div>
        </div>
        <div>
            <FilterComponent
              onFilter={this.handleSearchQuery}
              searchType={'Search by ' + this.state.filterData.toLowerCase()}
              onClear={this.handleClear}
              filterText={this.state.filterText}
            />
            <span class="search-close" onClick={() => this.handleClearSearchQuery()}></span>
        </div>
      </React.Fragment>
    );
  };

  handleSearchQuery = (e) => {    
    this.setState({
      filterText: e.target.value.trimStart(),
      isSearchDisabled: e.target.value === "" ? true : false
    })
  };

  handleClearSearchQuery = () => {
    this.setState({
      filterData: "",
      filterText: "",
      isSearchDisabled: true
    },()=> {
      this.fetchProjectCustomSearchData();
    });
  }

  fetchProjectCustomSearchData = () => {
    if(this.state.statusData.value === "ALL" && this.state.dateData.value === "ALL") {
      this.fetchProjectsDetailsList(0);
    } else if (this.state.dateData.value === "CUSTOM") {
      this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, 0);
    } else {
      this.fetchFilterProjectList(this.state.dateData.value, this.state.statusData.value, 0);
    }
  }

  fetchFilterProjectList = (date, status, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/project/search-by-status/?page=${page}&size=${this.state.perPage}&date=${date}&status=${status}&donateNow=${this.state.donateNow}&searchType=${this.state.filterData ? this.state.filterData : "ALL"}&searchQuery=${this.state.filterText}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            projectList: response.data.body === null ? [] : response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectList: [],
          loading: false
        })
      });
  };

  // fetch date from date range
  fetchFromDateRange = (startDate, endDate, page) => {
    this.setState({
      projectList: [],
      displayRange: false,
      loading: true
      //displayRangeButton: false,
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/project/search-by-status?page=" +page + "&size="+this.state.perPage+"&donateNow="+this.state.donateNow+"&date=CUSTOM&status=" + this.state.statusData.value + "&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate) + `&searchType=${this.state.filterData ? this.state.filterData : "ALL"}&searchQuery=${this.state.filterText}`,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            projectList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false

          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectList: [],
          loading: false
        })
      });
  };

  changeSearchData = (data) => {
    this.setState({
      filterData: data,
      filterText: ""
    });
  }

  donateNowHandleChange = (event) => {
    this.setState({
      donateNow: event.target.checked
    }, () => {
      this.fetchProjectCustomSearchData();
    })
  }

  handleStatusChange = (selectStatus) => {
    if (this.state.dateData.value === "CUSTOM") {
      this.setState({
        statusData: { value: selectStatus.value, label: selectStatus.value },
        isEnabledCancelFiler: false,
        changeType: 'DATE'
      }, () => {
        this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, 0);
      });
    } else {
      this.setState({
        statusData: { value: selectStatus.value, label: selectStatus.value },
        isEnabledCancelFiler: true,
        changeType: 'STATUS'
      }, () => {
        this.fetchFilterProjectList(this.state.dateData.value, this.state.statusData.value, 0);
      })
    }
  }

  // date range change
  handleDateChange = (newValue) => {
    if (newValue.value === "CUSTOM") {
      this.setState({
        dateData: { value: newValue.value, label: newValue.label },
        displayRangeButton: true,
        isEnabledCancelFiler: false,
        changeType: 'DATE'
      });
    } else {
      this.setState({
        dateData: { value: newValue.value, label: newValue.label },
        displayRangeButton: false,
        isEnabledCancelFiler: true,
        changeType: 'DATE'
      }, () => {
        this.fetchFilterProjectList(this.state.dateData.value, this.state.statusData.value, 0);
      })
    }
  };


  // display range select
  displayRangeSelect = () => {
    this.setState({
      displayRange: this.state.displayRange ? false : true,
    });
  };

  // handle range select
  handleSelect = (range) => {
    this.setState(
      {
        date: {
          ...this.state.date,
          startDate: range.selection.startDate,
          endDate: range.selection.endDate,
          key: range.selection.key,
        },
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  //Cancel filer
  cancelFilter = () => {
    this.setState({
      isEnabledCancelFiler: false,
      statusData: { value: "ALL", label: "ALL" },
      dateData: { value: "ALL", label: "ALL" },
    });
    this.fetchProjectsDetailsList(0);
  }

  //Cancel date dange
  cancelDateRange = () => {
    this.setState({
      displayRangeButton: false,
      isEnabledCancelFiler: false,
      statusData: { value: "ALL", label: "ALL" },
      dateData: { value: "ALL", label: "ALL" },
    })
    this.fetchProjectsDetailsList(0);
  }

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        <button className={"btn addRoleGlobal"} onClick={(e) => this.fetchProjectCustomSearchData()} disabled={this.state.isSearchDisabled}><i class="fa fa-search" /> Search</button>
        {auth_service.getPermission("AD_Project_ExportCSV") && <a className={"btn addRoleGlobal"} href="#exportCsvModal" data-toggle="modal"><i class="iDownloadFile" /> Export</a>}
      </React.Fragment>
    );
  };


  fetchProjectsDetailsList = async page => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/backend/project/list?page=${page}&size=${this.state.perPage}&donateNow=${this.state.donateNow}&searchType=${this.state.filterData ? this.state.filterData : "ALL"}&searchQuery=${this.state.filterText}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            projectList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectList: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    if(this.state.changeType === "STATUS" || (this.state.changeType === "DATE" && this.state.dateData.value !== "CUSTOM")) {
      this.fetchFilterProjectList(this.state.dateData.value, this.state.statusData.value, page-1);
    } else if(this.state.changeType === "DATE" && this.state.dateData.value === "CUSTOM") {
      this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, page-1);
    } else {
      this.fetchProjectsDetailsList(page-1);
    }
  };

  handlePerRowsChange = async (newPerPage, page) => {
    let url = '';
    if(this.state.changeType === "STATUS" || (this.state.changeType === "DATE" && this.state.dateData.value !== "CUSTOM")) {
      url = `${process.env.REACT_APP_userServiceURL}/backend/project/search-by-status/?page=${page-1}&size=${newPerPage}&date=${this.state.dateData.value}&status=${this.state.statusData.value}&donateNow=${this.state.donateNow}&searchType=${this.state.filterData ? this.state.filterData : "ALL"}&searchQuery=${this.state.filterText}`;
    } else if(this.state.changeType === "DATE" && this.state.dateData.value === "CUSTOM") {
      url = process.env.REACT_APP_userServiceURL + "/backend/project/search-by-status/?page=" +(page-1) + "&size="+newPerPage+"&donateNow="+this.state.donateNow+"&date=CUSTOM&status=" + this.state.statusData.value + "&startDate=" +utcTodate(this.state.date.startDate) +"&endDate=" +utcTodate(this.state.date.endDate) + `&searchType=${this.state.filterData ? this.state.filterData : "ALL"}&searchQuery=${this.state.filterText}`;
    } else {
      url = `${process.env.REACT_APP_userServiceURL}/backend/project/list?page=${page}&size=${newPerPage}&donateNow=${this.state.donateNow}&searchType=${this.state.filterData ? this.state.filterData : "ALL"}&searchQuery=${this.state.filterText}`;
    }
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: url,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            projectList: response.data.body && response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectList:[],
          loading: false
        })
      });
  };

  closeDateRange = () => {
    this.setState({
      displayRange: false
    })
  }

  exportButton = (startDate, endDate) => {
    this.setState({
      displayRange: false
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/project/search-by-status/?page=0&size=2000&date=CUSTOM&status="+this.state.statusData.value+"&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate)+"&donateNow="+this.state.donateNow,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          let data = response.data.body && response.data.body.content;
          let date = new Date();
          let dateMs = date.valueOf();
          const file = "project_list-"+epochTogmt(dateMs)+"-"+dateMs+".csv";
          this.setState({
            exportProjectList: response.data.body && response.data.body.content,
            fileName: file
          },() => {
            this.csvLink.link.click();
          });
          window.$("#exportCsvModal").modal('hide');
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.status === "404 NOT_FOUND") {
          window.$("#exportCsvModal").modal('hide');
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>Project not found in this date range.</p>)
          })
        }
        this.setState({
          exportProjectList: []
        });
      });
  };

  exportCsvModalClose = () => {
    this.setState({
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRange: false
    })
  }

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/reportPost/reactivate?id=${id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              let projectItemist = this.state.projectList;
              const index = projectItemist && projectItemist.findIndex(data => data.id === id);
              if (index > -1) {
                projectItemist.splice(index, 1); 
              }
              this.setState({
                projectList: projectItemist
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    //this.fetchProjectsList();
    this.fetchProjectsDetailsList(0);
    this.setState({
      id: this.state.userData.id
    })
  }
  render() {
    const { projectList, displayRange, displayRangeButton, date, isEnabledCancelFiler } = this.state;
    if(this.state.projectList !== null) {
      if (this.state.filterData === "Name") {
        this.filteredItems = this.state.projectList.filter(
          (item) =>
            item.projectName &&
            item.projectName.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
      } else if (this.state.filterData === "Country") {
        this.filteredItems = this.state.projectList.filter(
          (item) =>
            item.country &&
            item.country.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
      } else {
        this.filteredItems = this.state.projectList.filter(
          (item) =>
            item.type &&
            item.type.toLowerCase().includes(this.state.filterText.toLowerCase())
        );
      }
    }
    
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <Select
                      options={statusOptions}
                      placeholder={"Filter by status"}
                      onChange={this.handleStatusChange}
                      value={this.state.statusData}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      options={options}
                      placeholder={"ALL"}
                      onChange={this.handleDateChange}
                      value={this.state.dateData}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  {isEnabledCancelFiler &&
                    <div className="col-md-2">
                      <Button
                        onClick={(e) => {
                          this.cancelFilter();
                        }}
                        type={"anchor"}
                        dateFilter={true}
                        children={`Clear Filter`}
                      />
                    </div>
                  }
                  {displayRangeButton && (
                    <>
                      <div className="col-md-3">
                        <Button
                          onClick={this.displayRangeSelect}
                          type={"range"}
                          children={`${utcTodate(
                            this.state.date.startDate
                          )} to ${utcTodate(this.state.date.endDate)}`}
                        />
                        <Button
                          onClick={(e) => {
                            this.fetchFromDateRange(
                              this.state.date.startDate,
                              this.state.date.endDate,
                              0
                            );
                          }}
                          dateFilter={true}
                          type={"anchor"}
                          children={`Apply Filter`}
                        />
                        <Button
                          onClick={(e) => {
                            this.cancelDateRange();
                          }}
                          type={"anchor"}
                          dateFilter={true}
                          children={`Cancel`}
                        />
                        {displayRange && (
                          <DateRange
                            ranges={[date]}
                            onChange={this.handleSelect}
                            maxDate={new Date()}
                          />
                        )}
                      </div>
                    </>
                  )}
                  <div className={`col-md-3`}>
                    <label class="switch">
                        Donate Now
                        <input
                            type="checkbox"
                            ref="donateNow"
                            name="donateNow"
                            onClick={(e) => {
                                this.donateNowHandleChange(e);
                            }}
                            checked={this.state.donateNow}
                        />
                        <span class="slider round"></span>
                    </label>
                </div>
                </div>
              </div>
            </div>
            <DataTable
              title="Project List"
              columns={tableConstants(this.handleConfirmModal)}
              data={projectList}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              highlightOnHover
              pointerOnHover

              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>

          <CSVLink headers={headers} data={this.state.exportProjectList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

          {/* Export Button */}
          <div class="modal fade dgpopup" id="exportCsvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Select Date Rage</h5>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                        <Button
                          onClick={this.displayRangeSelect}
                          type={"range"}
                          children={`${utcTodate(
                            this.state.date.startDate
                          )} to ${utcTodate(this.state.date.endDate)}`}
                        />
                        <Button
                          onClick={(e) => {
                            this.closeDateRange();
                          }}
                          type={"anchor"}
                          children={`CLose`}
                        />
                        {this.state.displayRange && 
                          <DateRange
                            ranges={[date]}
                            onChange={this.handleSelect}
                            maxDate={new Date()}
                          />
                        }
                      </div>    
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" onClick={this.exportCsvModalClose} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={() => this.exportButton(this.state.date.startDate, this.state.date.endDate)}><i class="iDownloadFile" /> Export</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default ProjectDetails;
