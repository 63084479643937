import { Link } from "react-router-dom";
import { epochTogmt, trimDescription } from "../../../../utils/common";
import styled from "styled-components";
import { getSubscriptionType } from "../../CommonSubscriptionFunction";

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

export const tableProjectConstants = () => {


  return [
    {
      id: 1,
      name: "Description",
      cell: (row) => {
        return (
          <Link to={`/subscription/project/edit/${row.id}`}>
            <span className="sub">{getSubscriptionType(row.type)}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Create & Organize your projects",
      selector: (row) => row.create,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Raise funds",
      selector: (row) => row.raiseFunds,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Invite participants",
      selector: (row) => row.inviteParticipants,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Invite participants outside WeNat",
      selector: (row) => row.inviteParticipantsOutsideWN,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Multiple admins",
      selector: (row) => row.admins,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Manage Private Project",
      cell: (row) => {
        if (row.managePrivateProject) {
          return 'YES';
        } else {
          return 'NO';
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Created On",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
  ];
};
