import { epochTogmt } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";

export const spamUserTableConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Username",
      cell: (row) => {
        return (
          <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.userCustomUrl} target="_blank">
            <span className="sub">{row.userName}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Persona",
      selector: (row) => row.persona,
      sortable: true,
      reorder: true,
      //grow: .7,
    },
    {
      id: 3,
      name: "Link",
      cell: (row) => {
        return (
          <a href={process.env.REACT_APP_frontEndURL+'/profile/'+row.userCustomUrl} target="_blank">
            <span className="sub">{process.env.REACT_APP_frontEndURL+'/profile/'+row.userCustomUrl}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
      //grow: .7,
    },
    {
      id: 5,
      name: "Reason", 
      cell: (row) => {
        let output = [];
        let count = 0;
        row.reason &&
          row.reason.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },          
      sortable: true,
      reorder: true,
  },
  {
      id: 6,
      name: "Reported on",
      selector: (row) => row.reportedTime ? epochTogmt(row.reportedTime) : "NA",
      sortable: true,
      reorder: true,
      //grow: .7,
    },
    {
      id: 9,
      name: "Action",
      //grow: '2',
      width: '230px',
      cell: (row) => {
        let content = "";
        if (row.deactivated) {
          content = "REACTIVATE";
        } else {
          content = "DEACTIVATE";
        }
        return <>
        <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Spam_User_Action") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.userId, "DEACTIVATE");}} disabled={!auth_service.getPermission("Report_Spam_User_Action")}>
          Deactivate
        </button>
        <button  className={ "btnUpdte" + (auth_service.getPermission("Report_Spam_User_Ignore") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.userId, "IGNORE");}} disabled={!auth_service.getPermission("Report_Spam_User_Ignore")}>
          IGNORE
        </button>
        </>;        
      },      
      sortable: true,
      reorder: true,
    }
  ];
};
