import { epochTogmt } from "../../../../utils/common";

export const tableLicenseHistory = () => {
    return [
        {
            id: 1,
            name: "License Code",
            selector: (row) => row.licenseCode,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Start Date",
            selector: (row) => epochTogmt(row.startDate),
            grow: .1,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "End Date",
            selector: (row) => epochTogmt(row.endDate),
            sortable: true,
            reorder: true,
        },
        
    ];
};
