import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { userData } from "../../reports/Data/mockUserData";
import Button from "../../../components/Buttons/button";
import { tableDraftUserConstants } from "./Data/draftUserTableConstants";
import { auth_service } from "../../../auth/auth_service";
import { DateRange } from "react-date-range";
import { utcTodate,epochTogmt } from "../../../utils/common";
import styles from "../individual/individual_details.module.css";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const MySwal = withReactContent(alertPopUp);

let deleteModalSwal = {
  title: '<h5>Are you sure you want to delete this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const options = [
  { value: "ALL", label: "ALL" },
  { value: "TODAY", label: "TODAY" },
  { value: "YESTERDAY", label: "YESTERDAY" },
  { value: "LAST7DAY", label: "LAST 7 DAY" },
  { value: "LAST14DAY", label: "LAST 14 DAY" },
  { value: "LASTMONTH", label: "LAST MONTH" },
  { value: "CUSTOM", label: "CUSTOM" },
];

export class DraftUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      draftUserList: [],
      date: { startDate: new Date(), endDate: new Date(), key: "selection" },
      displayRangeButton: false,
      displayRange: false,
      loading: false,
      totalRows: 0,
      perPage: 10,
      dateData: { value: "ALL", label: "ALL" },
    };
  }

  // fetch report draftUserList
  fetchDraftUserList = async page => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/user/individual/search-by-date-status?page=${page}&size=${this.state.perPage}&date=ALL&status=DRAFT`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            draftUserList: response.data.body,
          });
          this.setState({
            draftUserList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          draftUserList: [],
          loading: false
        })
      });
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(deleteModalSwal);;
      let url ="";
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/user/deleteDraftUser?userId=${id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              this.fetchDraftUserList(0);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  handlePageChange = page => {
    this.fetchDraftUserList(page-1);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/user/draftUser?page=${page-1}&size=${newPerPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            draftUserList: response.data.body && response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          draftUserList: [],
          loading: false
        })
      });
  };

  // date range change
  handleDateChange = (newValue) => {
    if (newValue.value === "CUSTOM") {
      this.setState({
        dateData: { value: newValue.value, label: newValue.label },
        displayRangeButton: true,
        isEnabledCancelFiler: false,
        changeType: 'DATE'
      });
    } else {
      this.setState({
        dateData: { value: newValue.value, label: newValue.label },
        displayRangeButton: false,
        isEnabledCancelFiler: true,
        changeType: 'DATE'
      }, () => {
        this.fetchFilterDraftUserList(this.state.dateData.value, "DRAFT", 0);
      })
    }
  };

  fetchFilterDraftUserList = (date, status, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/user/individual/search-by-date-status/?page=${page}&size=${this.state.perPage}&date=${date}&status=${status}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            draftUserList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          draftUserList: [],
          loading: false
        });
      });
  };

  // fetch date from date range
  fetchFromDateRange = (startDate, endDate, page) => {
    this.setState({
      displayRange: false
      //displayRangeButton: false,
    });
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/user/individual/search-by-date-status?page=" +page + "&size="+this.state.perPage+"&date=CUSTOM&status=DRAFT&startDate=" +
        utcTodate(startDate) +
        "&endDate=" +
        utcTodate(endDate),
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            draftUserList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            loading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          draftUserList: [],
          loading: false
        });
      });
  };

  //Cancel filer
  cancelFilter = () => {
    this.setState({
      isEnabledCancelFiler: false,
      dateData: { value: "ALL", label: "ALL" },
    });
    this.fetchDraftUserList(0);
  }

  //Cancel date dange
  cancelDateRange = () => {
    this.setState({
      displayRangeButton: false,
      isEnabledCancelFiler: false,
      dateData: { value: "ALL", label: "ALL" },
    })
    this.fetchDraftUserList(0);
  }

  // display range select
  displayRangeSelect = () => {
    this.setState({
      displayRange: this.state.displayRange ? false : true,
    });
  };

  // handle range select
  handleSelect = (range) => {
    this.setState(
      {
        date: {
          ...this.state.date,
          startDate: range.selection.startDate,
          endDate: range.selection.endDate,
          key: range.selection.key,
        },
      });
  };

  
  componentDidMount() {
    this.fetchDraftUserList(0);
  }
  render() {
    const { draftUserList, displayRange, displayRangeButton, date, isEnabledCancelFiler } = this.state;
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                <div className="col-md-3">
                    <Select
                      options={options}
                      placeholder={"ALL"}
                      value={this.state.dateData}
                      onChange={this.handleDateChange}
                      styles={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          const color = "#cfe7c7";
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? null
                              : isSelected
                                ? "#cfe7c7"
                                : isFocused
                                  ? "#cfe7c7"
                                  : null,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected
                                ? color > 2
                                  ? "#00374d"
                                  : "#00374d"
                                : data.color,
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor:
                                !isDisabled &&
                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                            },
                          };
                        },
                      }}
                    />
                  </div>
                  {isEnabledCancelFiler &&
                    <div className="col-md-3">
                      <Button
                        onClick={(e) => {
                          this.cancelFilter();
                        }}
                        type={"anchor"}
                        children={`Clear Filter`}
                      />
                    </div>
                  }
                  {displayRangeButton && (
                    <>
                      <div className="col-md-5">
                        <Button
                          onClick={this.displayRangeSelect}
                          type={"range"}
                          children={`${utcTodate(
                            this.state.date.startDate
                          )} to ${utcTodate(this.state.date.endDate)}`}
                        />
                        <Button
                          onClick={(e) => {
                            this.fetchFromDateRange(
                              this.state.date.startDate,
                              this.state.date.endDate,
                              0
                            );
                          }}
                          type={"anchor"}
                          children={`Apply Filter`}
                        />
                        <Button
                          onClick={(e) => {
                            this.cancelDateRange();
                          }}
                          type={"anchor"}
                          children={`Cancel`}
                        />
                        {displayRange && (
                          <DateRange
                            ranges={[date]}
                            onChange={this.handleSelect}
                            maxDate={new Date()}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <DataTable
              title={"Draft User Report"}
              columns={tableDraftUserConstants(this.handleConfirmModal)}
              data={draftUserList}
              customStyles={customStyles}
              pagination
              highlightOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
            />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default DraftUser;
