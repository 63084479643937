import React, { Component } from "react";
import styles from "../../PreLogin/BelongHere/pre-login.module.css"
import styled from "styled-components";
import Select from "react-select";
import Button from "../../../../components/Buttons/button"
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormInput from "../../../../components/InputFields/form_input";
import axios from "axios";
import style from "../../../../components/InputFields/form_input.module.css";
import { v4 as uuidv4 } from 'uuid';
import history from "../../../../utils/history";
import { isYouTubeLink } from "../../../../utils/common";
import { auth_service } from "../../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const sectionsList = [{"label": "Project Management", "value": "PROJECT"}, {"label": "Fund Raise", "value": "FUND_RAISE"}, {"label": "Studio", "value": "STUDIO"}, {"label": "Community", "value": "COMMUNITY"}];

export class WouldLikeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            category: {},
            categoryList: [],
            sectionsData: [],
            inputFieldValue: [{ link: "", id: uuidv4()}],
            errors: {},
            editData: {},
            error: false
        };
    }

    // validate create amount
    validate = () => {
        let errors = {};
        let formIsValid = true;
        this.state.inputFieldValue && this.state.inputFieldValue.map((data, index) => {
            const newArray = this.state.inputFieldValue;
            if (!data.link) {
                newArray[index].errorMessage = "Please enter a link.";
                formIsValid = false;
            }
            if (data.link) {
                let urlValid = data.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (urlValid == null || !isYouTubeLink(data.link)) {
                    newArray[index].errorMessage = "Please enter a valid link.";
                    formIsValid = false;
                }
            }
            this.setState({
                inputFieldValue: newArray
            })
            setTimeout(() => {
                newArray[index].errorMessage = null;
                this.setState({
                    inputFieldValue: newArray
                })
            }, 5000);
        });
        if (!this.state.category?.value) {
            formIsValid = false;
            errors["category"] = "Please enter category";
        }
        if(!this.state.title) {
            formIsValid = false;
            errors["title"] = "Please enter title";
        }
        if(this.state.sectionsData?.length === 0) {
            formIsValid = false;
            errors["sectionsData"] = "Please select atleast one sections";
        }
        this.setState({
            errors: errors,
        });
        {
            setTimeout(() => {
                this.setState({
                    errors: {}
                });
            }, 5000);
        }
        return formIsValid;
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    }

    onSubmit = () => {
        if (this.validate()) {
            let postData = {
                title: this.state.title,
                categoryId: this.state.category?.value,
                links: this.state.inputFieldValue && this.state.inputFieldValue.map((data) => {return data?.link}),
                sections: this.state.sectionsData && this.state.sectionsData.map((data) => {return data?.value}),
                status: this.props.formType === "CREATE" ? "DRAFT" : this.state.editData.status
            };
            if(this.props.formType === "UPDATE") {
                postData.id = this.props.id
            }
            axios({
                method: this.props.formType === "UPDATE" ? "PUT" : "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/post/login/${this.props.formType === "UPDATE" ? "update-belong-data" : "create-belong-data"}`,
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response && response.data && response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                console.log(error);
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }
    }


    fetchCategoryData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/post/login/enable-list-belong-category`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                let categoryList = result && result.map((data) => {return {label: data.title, value: data.id}})
                this.setState({
                    categoryList: categoryList
                }, () => {
                    if(this.props.formType === "UPDATE") {
                        this.fetchWouldLikeData();
                    }
                })
                
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    addLinkSection = () => {
        let inputFieldValue = this.state.inputFieldValue;
        inputFieldValue.push({ highlight: "", id: uuidv4() });
        this.setState({
            inputFieldValue
        })
    };

    removeLinkSection = (id) => {
        let inputFieldValue = this.state.inputFieldValue;
        let index = inputFieldValue && inputFieldValue.findIndex((item) => item.id === id);
        if (index > -1) {
            inputFieldValue.splice(index, 1);
        }
        this.setState({
            inputFieldValue
        })
    }

    onChangeLinks = (id, event) => {
        let inputFieldValue = this.state.inputFieldValue;
        let index = inputFieldValue && inputFieldValue.findIndex((item) => item.id === id);
        if(index > -1) {
            inputFieldValue[index].link = event.target.value.trimStart();
        }
        this.setState({
            inputFieldValue,
        })
    };

    onChangeCategoryAndSections = (data, name) => {
        this.setState({
            [name]: data
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trimStart()
        })
    };

    fetchWouldLikeData = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/post/login/get-belong-data?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                let result = response.data.body;
                let category = this.state.categoryList && this.state.categoryList.find((data)=> data.value === result.categoryId);
                let linksValue = result?.links && result?.links.map(obj => {
                    let editObj = { link: obj, id: uuidv4() }
                    return editObj;
                })
                let resultSections = result?.sections ? result?.sections : [];
                let sectionsValue = resultSections && resultSections.map(obj => {
                    let editObj = { value: obj, label: sectionsList && sectionsList.find((data) => data.value === obj).label }
                    return editObj;
                })
                this.setState({
                    title: result.title,
                    category: category,
                    inputFieldValue: linksValue,
                    sectionsData: sectionsValue,
                    editData: result
                })
            }
        }).catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
                didOpen: () => {
                    ErorrMsgPopUp.clickConfirm()
                }
            }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
        });
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    render() {
        const { title, category, errors, categoryList, inputFieldValue, sectionsData } = this.state;
        const { loginType } = this.props;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="d-flex align-items-center">Category <span className="text-danger">*</span></label>
                        <Select
                            options={categoryList}
                            onChange={(data) => this.onChangeCategoryAndSections(data, "category")}
                            placeholder={"Select Category"}
                            closeMenuOnSelect={true}
                            value={!category?.value ? "" : category}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {errors["category"] && (
                            <small className={style.danger}>
                                {errors["category"]}
                            </small>
                        )}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-12">
                        <FormInput
                            group={"large"}
                            value={title}
                            label={"Title"}
                            onChange={this.handleChange}
                            name={"title"}
                            error={errors["title"]}
                        />
                    </div>
                    <div className="col-md-12">
                        <h5>Link section</h5>
                    </div>


                    {inputFieldValue && inputFieldValue.map((item, index) => {
                        return (
                            <>
                                <div className={`col-md-${index === 0 ? "12" : "10"}`}>
                                    <FormInput
                                        group={"large"}
                                        value={item?.link}
                                        label={"Link"}
                                        name={"link"}
                                        onChange={(event) => this.onChangeLinks(item.id, event)}
                                        error={item?.errorMessage}
                                    />
                                </div>
                                {index > 0 &&
                                    <div className="col-md-2">
                                        <Button className="iTrashBin" children={"Remove"} style={{ marginTop: "35px" }} onClick={() => { this.removeLinkSection(item.id) }} />
                                    </div>
                                }
                            </>
                        )
                    })}
                    {inputFieldValue?.length < 2 && 
                        <div className="col-md-12">
                            <Button children={"Add Links"} onClick={this.addLinkSection} />
                        </div>
                    }
                </div>

                <div className="form-group row">
                    <div className="col-md-12">
                        <label className="d-flex align-items-center">Sections <span className="text-danger">*</span></label>
                        <Select
                            options={sectionsList}
                            onChange={(data) => this.onChangeCategoryAndSections(data, "sectionsData")}
                            placeholder={"Select Category"}
                            closeMenuOnSelect={false}
                            isMulti
                            value={!sectionsData?.length === 0 ? "" : sectionsData}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {errors["sectionsData"] && (
                            <small className={style.danger}>
                                {errors["sectionsData"]}
                            </small>
                        )}
                    </div>
                </div>

                {auth_service.getPermission(this.props.formType === "CREATE" ? "Post_Login_Would_Like_Create" : "Post_Login_Would_Like_Update", "Post_Login_Would_Like_FullAccess") && 
                    <ButtonWrap className={`${styles.bottombutt}`}>
                        <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                    </ButtonWrap>
                }

            </div>
        );
    }
}

export default WouldLikeForm;