// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".users_list_createforumbtn__2yNpW {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 15px;\n  color: #f7f7f5;\n  background: #00394d;\n  border-radius: 22px;\n  text-transform: uppercase;\n  padding: 12px 18px 12px 12px;\n  position: fixed;\n  right: 30px;\n  bottom: 30px;\n}\n.users_list_createforumbtn__2yNpW i {\n  margin-right: 8px;\n}\n.users_list_createforumbtn__2yNpW:hover {\n  background: #154a59;\n  color: #ffffff !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/admin_panel/User/users_list.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;EACf,WAAW;EACX,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".createforumbtn {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 15px;\n  color: #f7f7f5;\n  background: #00394d;\n  border-radius: 22px;\n  text-transform: uppercase;\n  padding: 12px 18px 12px 12px;\n  position: fixed;\n  right: 30px;\n  bottom: 30px;\n}\n.createforumbtn i {\n  margin-right: 8px;\n}\n.createforumbtn:hover {\n  background: #154a59;\n  color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createforumbtn": "users_list_createforumbtn__2yNpW"
};
export default ___CSS_LOADER_EXPORT___;
