import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utils/tableCustomStyle';
import { tableConstantsEmailNotificationUserList } from "./tableConstantsEmailNotificationUserList";
import axios from "axios";
import { CommonHeader } from "../../utils/commonHeader";
import { Link } from "react-router-dom";
import history from "../../utils/history";

class EmailNotificationUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            showModal: false,
            fileName: "",
            loading: false,
            totalRows: 0,
            perPage: 10, 
            modalType: props.location.state?.modalType,
            url: props.location.state?.url 
        };
    }

    fetchAllUserListTableData = async (page) => {
        let modalType = this.state.modalType;
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/notifier/get/${modalType === "OPENED" ? "opened" : modalType === "DELIVERED" ? "delivered" : modalType === "UNSUBSCRIBED" ? "unsubscribed" : modalType == "CLICKED" ? "clicked" : "deleted"}/list?notifierId=${this.props.match.params?.id}${this.state.url ? `&url=${this.state.url}` : ""}&page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    userList: response.data.body?.content,
                    totalRows: response.data.body.totalElements,
                    loading: false,
                    showModal: true
                }, () => {
                    window.$("#emailNotificationUserList").modal("show");
                });
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    handlePageChange = page => {
        this.fetchAllUserListTableData(page - 1);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllUserListTableData(page - 1);
        })
    };

    capitalFirstLetter = (s) => {
        return s[0].toUpperCase() + s.slice(1).toLowerCase();
    }

    componentDidMount() {
        this.fetchAllUserListTableData(0);
    }

    render() {
        const { userList, perPage, totalRows, loading, modalType } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <a href="javascript:void(0)" onClick={() => history.goBack()} className="goback">
                        Go Back
                    </a>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="col2Large">
                            <DataTable
                                title={`${this.capitalFirstLetter(modalType)} User List`}
                                columns={tableConstantsEmailNotificationUserList()}
                                data={userList}
                                customStyles={customStyles}
                                // actions={this.actionButton()}
                                fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                                pagination
                                paginationPerPage={perPage}
                                pointerOnHover
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CommonHeader>

        );
    }
}

export default EmailNotificationUserList;
