import React, { Component } from 'react'
import styles from "./course.module.css";
import { Link } from 'react-router-dom';
import { tableConstantsCourse } from './tableConstantsCourse';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utils/tableCustomStyle';
import axios from 'axios';
import { CommonHeader } from '../../utils/commonHeader';

class CourseUsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            loading: false,
            totalRows: 0,
            perPage: 10, 
        }
    }

    fetchAllCourseUsersList = async (page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            withCredentials: true,
            url: `${process.env.REACT_APP_userServiceURL}/course/user/list?courseId=${this.props.match?.params?.courseId}&page=${page}&size=${this.state.perPage}`,
        }).then((response) => {
            if (response && response.data && response.data.message === "Success!") {
                this.setState({
                    userList: response.data.body?.content,
                    totalRows: response.data.body.totalElements,
                    loading: false
                });
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            })
        });
    }

    handlePageChange = page => {
        this.fetchAllCourseUsersList(page-1);
    };
    
    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({
            perPage: newPerPage
        }, () => {
            this.fetchAllCourseUsersList(page-1);
        })
    };

    componentDidMount() {
        this.fetchAllCourseUsersList(0);
    }

    render() {
        const { userList, perPage, loading, totalRows } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/course" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className="marketingTable">
                            <DataTable
                                title={"Course Users List"}
                                columns={tableConstantsCourse(this.fetchAllCourseUsersList, "COURSE_USER")}
                                data={userList}
                                customStyles={customStyles}
                                fixedHeader
                                fixedHeaderScrollHeight={"calc(100vh - 216px)"}
                                pagination
                                paginationPerPage={perPage}
                                pointerOnHover
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CommonHeader>

        )
    }
}

export default CourseUsersList;