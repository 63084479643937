import React from "react";
import { epochTogmt } from "../../../../../../../../../utils/common";

export const tableActivityPointsConstants = () => {
  return [
    {
      id: 1,
      name: "Activity Type",
      selector: (row) => row.activityType,
      sortable: true,
      reorder: true,
      width: '230px',
    },
    {
      id: 2,
      name: "Date",
      selector: (row) => epochTogmt(row.time),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Link",
      cell: (row) => {
        return (
          <a href={row.link} target="_blank">
            <span className="sub">{row.link}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
