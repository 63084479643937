import React from "react";
import StatusDetails from "../../../../../../components/StatusDetails/status_details";

export const tableMarketingConstants = () => {
    return [
        {
            id: 1,
            name: "Name",
            cell: (row) => {
                return (
                    <a href={process.env.REACT_APP_frontEndURL + '/profile/' + row.customUrl} target="_blank">
                        <span className="sub">{row.username}</span>
                    </a>
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 2,
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "Category Name",
            selector: (row) => row.categoryTitle,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "Nominated By",
            cell: (row) => {
                return (
                    <a href={process.env.REACT_APP_frontEndURL + '/profile/' + row.nominatedByCustomUrl} target="_blank">
                        <span className="sub">{row.nominatedByUserName}</span>
                    </a>
                );
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 4,
            name: "Outside Wenat",
            selector: (row) => row.outsidewenat ? "Yes" : "No",
            sortable: true,
            reorder: true,
        },
        {
            id: 5,
            name: "Country",
            selector: (row) => row.location?.country,
            sortable: true,
            reorder: true,
        },
        {
            id: 6,
            name: "State",
            selector: (row) => row.location?.state,
            sortable: true,
            reorder: true,
        },
        {
            id: 7,
            name: "City",
            selector: (row) => row.location?.district,
            sortable: true,
            reorder: true,
        },
        {
            id: 8,
            name: "Status",
            cell: (row) => {
                return (
                    <StatusDetails status={row.status} />
                );
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 9,
            name: "Nomination Status",
            cell: (row) => {
              return (
                <StatusDetails status={row.nominationStatus} />
              );
            },
            sortable: true,
            reorder: true,
        },
    ];
};
