import { Link } from "react-router-dom";
import StatusDrop from "../../../components/StatusDropDown/status_drop";
import StatusDetails from "../../../components/StatusDetails/status_details";
import { auth_service } from "../../../auth/auth_service";

export const tableConstants = (fetchWinner,handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Name",
      cell: (row) => {
        return (
          <Link to={`/winner/edit/${row.id}`}>
            <span className="sub">{row.name}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Award Category",
      selector: (row) => row.category,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status}/>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"winner"}
            category={row.category}
            action={fetchWinner}
            fullPermission={"People_Of_Nature_Winner_FullAccess"}
            approvalPermission={"People_Of_Nature_Winner_Approval"}
            publishPermission={"People_Of_Nature_Winner_Publish"}
            enablePermission={"People_Of_Nature_Winner_Enable"}
            disablePermission={"People_Of_Nature_Winner_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 7,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + ((!auth_service.getPermission("People_Of_Nature_Winner_Delete", "People_Of_Nature_Winner_FullAccess") || row.status === 'ENABLE') ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
