import React, { Component } from "react";
import AdminHeader from "../parts/Header/admin_header";

export class CommonHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="wrapper">
        <div className="main_container">
          <AdminHeader children={"Causes"} />

          {this.props.children}
        </div>
      </div>
    );
  }
}
