import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { userData } from "../Data/mockUserData";
import { tableDeactivateUserConstants } from "../Data/deactivateUserTableConstants";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FilterComponent } from "../../../utils/filter";
import ExportCSV from "../../../components/Buttons/export_csv_button";
import { epochTogmt } from "../../../utils/common";


const MySwal = withReactContent(alertPopUp);

const headers = [
  { label: "Username", key: "userName" },
  { label: "Address", key: "addressDetail" },
  { label: "Deactivated Time", key: "deactivatedTime" },
  { label: "Reason", key: "reason" },
  { label: "Profile Link", key: "profileLink" },
  { label: "Admin", key: "adminName"}
];

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Reactivate this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

export class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
       userData: auth_service.getUserData(),
      deactivateUserList: [],
      exportDeactivateduserList: [],
      filterText: "",
      loading: false,
      totalRows: 0,
      perPage: 10
    };
  }


  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.deactivateUserList.filter((item) => item.userName && item.userName.toLowerCase().includes(newFilterText.toLowerCase()));
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by user name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("Report_Deactivated_User_ExportCSV") && <ExportCSV headers={headers} data={this.state.exportDeactivateduserList} filename="deactivated-user" />}
      </React.Fragment>
    );
  };

  // fetch report userlist
  fetchDeactivateUserList = (page, size) => {
    this.setState({
      loading: false
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/reportAbuse/deletedUserList?createdBy=&status=&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=&searchValue=undefined&page=${page}&size=${size}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          let exportDeactivateduserList = [];
          response.data.body && response.data.body.content && response.data.body.content.map((data) => {
            exportDeactivateduserList.push({"userName": data.userName, "addressDetail": data.addressDetail, "deactivatedTime": epochTogmt(data.deactivatedTime), "reason": data.reason, "profileLink": `${process.env.REACT_APP_frontEndURL}/profile/${data.customUrl}`, "adminName": data.adminName ? data.adminName : this.state.userData.firstName})
          })
          this.setState({
            deactivateUserList: response.data.body && response.data.body.content,
            totalRows: response.data.body.totalElements,
            perPage: size,
            loading: false,
            exportDeactivateduserList: exportDeactivateduserList
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          deactivateUserList: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchDeactivateUserList(page-1, this.state.perPage)
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.fetchDeactivateUserList(page-1, newPerPage);
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/deactivation/reactivateProfile?id=${id}&adminId=${this.state.userData.id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              let deactivateUser = this.state.deactivateUserList;
              const index = deactivateUser && deactivateUser.findIndex(data => data.id === id);
              if (index > -1) {
                deactivateUser.splice(index, 1); 
              }
              this.setState({
                deactivateUserList: deactivateUser
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    this.fetchDeactivateUserList(0, this.state.perPage);
  }
  render() {
    this.filteredItems = this.state.deactivateUserList.filter((item) => item.userName && item.userName.toLowerCase().includes(this.state.filterText.toLowerCase()));
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title={"Deactivated User Report"}
              columns={tableDeactivateUserConstants(this.state.userData,this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={10}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange} 
            />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default User;
