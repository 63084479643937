import React from "react";
import { Link } from "react-router-dom";
import WpondStatusDrop from "../../../../components/WpondStatusDropDown/wpond_status_drop";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import stripHtml from "string-strip-html";

export const tableUpcomingYearConstants = (fetchAllUpcomingYearsList) => {
  return [
    {
      id: 1,
      name: "Banner",
      cell: (row) => {
        return (
          <Link to={`/wpond/upcoming-year/edit/${row.id}`}>
            <img
              src={row.image}
              alt="coverImage"
              style={{ width: "100px", height: "56px" }}
            />
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Years",
      cell: (row) => {
        return (
          <Link to={`/wpond/${row.year}/award-categories`}>
            <span className="sub">{row.year}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Meta Title",
      selector: (row) => row.metaTitle,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Meta Description",
      selector: (row) => stripHtml(row.metaDescription),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "keywords",
      cell: (row) => {
        let output = [];
        let count = 0;
        row.keywords &&
          row.keywords.map((data, index) => {
            if (index < 1) {
              output.push(data);
            } else {
              count = count + 1;
            }
          });
        if (count === 0) {
          return output;
        } else {
          output = output + ", +" + count;
          return output;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Draft";
          status = "DRAFT";
        }
        if (row.status === "UPCOMING") {
          content = "Upcoming";
          status = "UPCOMING";
        }
        if (row.status === "COMPLETED") {
          content = "Completed";
          status = "COMPLETED";
        }
        if (row.status === "DISABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        return (
          <WpondStatusDrop
            children={content}
            id={row.id}
            status={status}
            year={row.year}
            type={"wpondAboutYear"}
            action={fetchAllUpcomingYearsList}
            fullPermission={"WPOND_Upcoming_FullAccess"}
            upcomingPermission={"WPOND_Upcoming_Upcoming"}
            completedPermission={"WPOND_Upcoming_Completed"}
            disablePermission={"WPOND_Upcoming_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
