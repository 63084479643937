import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { auth_service } from "../../../auth/auth_service";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FilterComponent } from "../../../utils/filter";
import { spamUserTableConstants } from "../Data/spamUserTableConstants.js";


const MySwal = withReactContent(alertPopUp);

let deactivateModalSwal = {
  title: '<h5>Are you sure you want to Deactivate this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

let ignoreModalSwal = {
  title: '<h5>Are you sure you want to Ignore this User?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

export class SpamUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      spamUserList: [],
      filterText: "",
      loading: false,
      totalRows: 0,
      perPage: 10,
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.spamUserList.filter(
              (item) =>
                item.userName &&
                item.userName.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by user name'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />

      </React.Fragment>
    );
  };

  fetchSpamUserList = async page => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/spam/user/list?page=${page}&size=${this.state.perPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            spamUserList: response.data.body && response.data.body.content,
            loading: false,
            totalRows: response.data.body.totalElements
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          spamUserList: [],
          loading: false
        })
      });
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      loading: true
    })
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/spam/user/list?page=${page - 1}&size=${newPerPage}`,
      withCredentials: true,
    })
      .then((response) => {
        if (response && response.data && response.data.status === "200 OK") {
          this.setState({
            spamUserList: response.data.body.content,
            perPage: newPerPage,
            loading: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          spamUserList: [],
          loading: false
        })
      });
  };

  handlePageChange = page => {
    this.fetchSpamUserList(page);
  };

  handleConfirmModal = (userId, content) => {
    const handleConfirm = async () => {
      let swalval = await MySwal.fire(content === "DEACTIVATE" ? deactivateModalSwal : ignoreModalSwal);
      let url = "";
      if (content === "DEACTIVATE") {
        url = `backend/spam/user/deactivate?userId=${userId}&adminId=${this.state.userData.id}`;
      } else {
        url = `backend/spam/user/ignore?userId=${userId}`;
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/${url}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              let spamUserItemList = this.state.spamUserList;
              const index = spamUserItemList && spamUserItemList.findIndex(data => data.userId === userId);
              if (index > -1) {
                spamUserItemList.splice(index, 1);
              }
              this.setState({
                spamUserList: spamUserItemList
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    handleConfirm();
  };

  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
      </React.Fragment>
    );
  };

  componentDidMount() {
    this.fetchSpamUserList(0);
  }

  render() {
    this.filteredItems = this.state.spamUserList.filter((item) =>
      item.userName &&
      item.userName.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <DataTable
              title={"Spam User"}
              columns={spamUserTableConstants(this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              paginationPerPage={20}
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              highlightOnHover
              progressPending={this.state.loading}
              paginationServer
              paginationTotalRows={this.state.totalRows}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
            />
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default SpamUser;