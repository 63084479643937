import StatusDrop from "../../../components/StatusDropDown/status_drop";
import {Link} from "react-router-dom";
import { epochTogmt } from "../../../utils/common";
import { auth_service } from "../../../auth/auth_service";

export const tableuserConstants = (userData, handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Username",
      cell: (row) => {
        return (
          <Link
            to={`/report/user-details/${row.id}`}
            style={{ color: "#154A59" }}
          >
            <span className="sub">{row.userName}</span>
          </Link>
        );
      },
      conditionalCellStyles: [
        {
          when: row => row.systemGenerated,
          classNames: ['emailUnverified'],
        },
      ],
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Persona",
      selector: (row) => row.persona,
      sortable: true,
      reorder: true,
      //grow: .7,
    },
    {
      id: 3,
      name: "Address",
      selector: (row) => row.addressDetail,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Number of Reports",
      selector: (row) => row.reportCount,
      sortable: true,
      reorder: true,
      //grow: .7,
    },
    {
      id: 5,
      name: "Link",
      cell: (row) => {
        return (
          <a href={`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/profile/${row.customUrl}`} target="_blank">
            <span className="sub">{`${!row.mywenat ? process.env.REACT_APP_frontEndURL : process.env.REACT_APP_myWenatUrl}/profile/${row.customUrl}`}</span>
          </a>
        );
      },
      sortable: true,
      reorder: true,
      grow: 1.5,
    },
    {
      id: 6,
      name: "Reported on",
      selector: (row) => epochTogmt(row.reportedTime),
      sortable: true,
      reorder: true,
      //grow: .6,
    },

    {
      id: 7,
      name: "Deactivated time",
      selector: (row) => row.deactivatedTime > 0 && row.deactivated  ? epochTogmt(row.deactivatedTime):'-',
      sortable: true,
      reorder: true,
      //grow: .6,
    },
    // {
    //   id: 8,
    //   name: "Reason",
    //   cell: (row) => row.reason,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 9,
      name: "Action",
      //grow: '2',
      width: '230px',
      cell: (row) => {
        let content = "";
        if (row.deactivated) {
          content = "REACTIVATE";
        } else {
          content = "DEACTIVATE";
        }
        return <><button  className={ "btnUpdte nonwidth" + (auth_service.getPermission("Report_User_Action") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.id, content);}} disabled={!auth_service.getPermission("Report_User_Action")}>
          {content}
        </button>
        {!row.deactivated && 
          <button  className={ "btnUpdte" + (auth_service.getPermission("Report_User_Ignore") ? "" : " disabled") } onClick={(e) => {handleConfirmModal(row.id, "IGNORE");}} disabled={!auth_service.getPermission("Report_User_Ignore")}>
            IGNORE
          </button>
        }
        </>;        
      },      
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Last action taken by",
      //grow: .7,
      cell: (row) => {
        return (
          <Link
            to={`/report/user-action-history/${row.id}`}
            style={{ color: "#154A59" }}
          >
            <span className="sub">{row.adminName}</span>
          </Link>
        );
      },
      sortable: true,
      reorder: true,
    },
  ];
};
