export const data = [
  {
    id: "6156ada50136125785a753a6",
    year: "2019",
    image: "https://dscovr-assets-dev.s3.amazonaws.com/exploreBlogFiles/compressed-61c4bd6456ab891c9b46c504-1640322980349.jpg",
    name: "Derek Antony",
    website: "https://www.google.com",
    facebook: "https://www.facebook.com/WeNaturalists/",
    instragram: "https://www.instagram.com/WeNaturalists/",
    twitter: "https://twitter.com/WeNaturalists",
    youtube: "https://www.youtube.com/channel/UCUFUqiJGKl9nj_v6fcbsTnw?view_as=subscriber",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "5ff4658252a97a324dc5a6ac",
    year: "2020",
    image: "https://dscovr-assets-dev.s3.amazonaws.com/exploreBlogFiles/compressed-61c4bd6456ab891c9b46c504-1640322980349.jpg",
    name: "Derek Antony",
    website: "https://www.google.com",
    facebook: "https://www.facebook.com/WeNaturalists/",
    instragram: "https://www.instagram.com/WeNaturalists/",
    twitter: "https://twitter.com/WeNaturalists",
    youtube: "https://www.youtube.com/channel/UCUFUqiJGKl9nj_v6fcbsTnw?view_as=subscriber",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "613c6658ae4d772903489b26",
    year: "2021",
    image: "https://dscovr-assets-dev.s3.amazonaws.com/exploreBlogFiles/compressed-61c4bd6456ab891c9b46c504-1640322980349.jpg",
    name: "Derek Antony",
    website: "https://www.google.com",
    facebook: "https://www.facebook.com/WeNaturalists/",
    instragram: "https://www.instagram.com/WeNaturalists/",
    twitter: "https://twitter.com/WeNaturalists",
    youtube: "https://www.youtube.com/channel/UCUFUqiJGKl9nj_v6fcbsTnw?view_as=subscriber",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "613b2667b1e97262f4362e71",
    year: "2022",
    image: "https://dscovr-assets-dev.s3.amazonaws.com/exploreBlogFiles/compressed-61c4bd6456ab891c9b46c504-1640322980349.jpg",
    name: "Derek Antony",
    website: "https://www.google.com",
    facebook: "https://www.facebook.com/WeNaturalists/",
    instragram: "https://www.instagram.com/WeNaturalists/",
    twitter: "https://twitter.com/WeNaturalists",
    youtube: "https://www.youtube.com/channel/UCUFUqiJGKl9nj_v6fcbsTnw?view_as=subscriber",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "5ea91ce034b8da3773fd76a7",
    year: "2023",
    image: "https://dscovr-assets-dev.s3.amazonaws.com/exploreBlogFiles/compressed-61c4bd6456ab891c9b46c504-1640322980349.jpg",
    name: "Derek Antony",
    website: "https://www.google.com",
    facebook: "https://www.facebook.com/WeNaturalists/",
    instragram: "https://www.instagram.com/WeNaturalists/",
    twitter: "https://twitter.com/WeNaturalists",
    youtube: "https://www.youtube.com/channel/UCUFUqiJGKl9nj_v6fcbsTnw?view_as=subscriber",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
  {
    id: "5ef48868de5ecb11d75723d7",
    year: "2024",
    image: "https://dscovr-assets-dev.s3.amazonaws.com/exploreBlogFiles/compressed-61c4bd6456ab891c9b46c504-1640322980349.jpg",
    name: "Derek Antony",
    website: "https://www.google.com",
    facebook: "https://www.facebook.com/WeNaturalists/",
    instragram: "https://www.instagram.com/WeNaturalists/",
    twitter: "https://twitter.com/WeNaturalists",
    youtube: "https://www.youtube.com/channel/UCUFUqiJGKl9nj_v6fcbsTnw?view_as=subscriber",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    status: "DRAFT"
  },
];
