import React from "react";
import { epochTogmt } from "../../../../utils/common";
import StatusDetails from "../../../../components/StatusDetails/status_details";

export const tableConstants = (handleConfirmModal) => {
  return [
    {
      id: 1,
      name: "Project name",
      cell: (row) => {
        return (<a href={`${process.env.REACT_APP_frontEndURL}/project/feeds/${row?.slug}?admin=true`} target="_blank"><span className="sub">{row.projectName}</span> </a>)
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Admin",
      selector: (row) => row.noOfAdmins,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Created On",
      selector: (row) => epochTogmt(row.createdOn),
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Applications received",
      selector: (row) => row.applicationReceived,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Participants",
      selector: (row) => row.participants,
      cell: (row) => {
        if(row.participants == "" || row.participants === null) {
          return "--";
        } else {
          return <span className="sub">{row.participants}</span>;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Start Date",
      selector: (row) => epochTogmt(row.startDate),
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "End Date",
      cell: (row) => {
        if(row.endDate == 0 || row.endDate === null || row.endDate === 'NA') {
          return "--";
        } else {
          return <span className="sub">{epochTogmt(row.endDate)}</span>;
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 11,
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      reorder: true,
    },
    {
      id: 12,
      name: "Guest invite",
      selector: (row) => row.guestInvite.length,
      sortable: true,
      reorder: true,
    },
    {
      id: 13,
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
    },
    {
      id: 14,
      name: "Posts",
      selector: (row) => row.posts,
      sortable: true,
      reorder: true,
    },
    {
      id: 17,
      name: "Action",
      cell: (row) => {
        return (row.status === "DELETED" ? 
          <button  className={ "btnUpdte"} onClick={(e) => {handleConfirmModal(row.id);}}  disabled={false}>
            Reactive
          </button> : 
          null);
      },
      sortable: true,
      reorder: true,
    },
  ];
};
