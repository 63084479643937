import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableExcludeGlobalUserConstants } from "./Data/tableExcludeGlobalUserConstants";
import Button from "../../../components/Buttons/button";
import styles from "./exclude_global_user.module.css";
import { FilterComponent } from "../../../utils/filter";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FormInput from "../../../components/InputFields/form_input";
import ExportCSV from "../../../components/Buttons/export_csv_button";
import { auth_service } from "../../../auth/auth_service";
import Select from "react-select";
import style from "../../../components/InputFields/form_input.module.css";
import _ from "lodash";

const ErorrMsgPopUp = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const headers = [
  { label: "User Name", key: "userName" },
  { label: "User Email", key: "email" },
  { label: "User Id", key: "userId" },
  { label: "Access Type", key: "accessType" },
];

const options = [
  { value: "FULL_ACCESS", label: "Full Access" },
  { value: "VIEW_ACCESS", label: "View Access" },
  /* { value: "CUSTOM_ACCESS", label: "Custom Access" } */
];
export class ExcludeGlobalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      accessType: {},
      excludeGlobalUserList: [],
      filterText: "",
      filterTextOne: "",
      filterDisplay: false,
      changeType: 'ALL',
      exportUserFlag: false,
      fileName: "",
      errors: {},
      error: false,
    };
  }

  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.excludeGlobalUserList && this.state.excludeGlobalUserList.filter(
              (item) =>
                item.email &&
                item.email
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          searchType={'Search by email'}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // validate
 validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.userId) {
      formIsValid = false;
      errors["userId"] = "Please enter user id";
    }
    if (
      !this.state.accessType ||
      this.state.accessType === null ||
      this.state.accessType === undefined ||
      _.isEmpty(this.state.accessType)
    ) {
      formIsValid = false;
      errors["accessType"] = "Please select access type";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("AP_Exclude_Global_User_ExportCSV") && <ExportCSV headers={headers} data={this.state.excludeGlobalUserList} filename="exclude_global_user" />}
      </React.Fragment>
    );
  };

  handleChange = (event) => {
      this.setState({
          userId: event.target.value.trimStart()
      })
  }

  onAccessChange = (data) => {
    this.setState({
      accessType: data
    })
  }

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        userId: this.state.userId,
        type: this.state.accessType && this.state.accessType.value
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/excludeGlobalUser/create`,
        //headers: { "Content-Type": "application/json" },
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            this.setState({
                userId: "",
                accessType: {}
            })
            this.fetchExcludeGlobalUser();
          }
        })
        .catch((error) => {
            console.log(error)
            this.setState({
              userId: "",
              accessType: {}
          })
            ErorrMsgPopUp.fire({
                didOpen: () => {
                  ErorrMsgPopUp.clickConfirm()
                }
              }).then(() => {
                return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
              })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          error: false
        });
      }, 5000);
    }
  };


  // fetch all exclude user list
  fetchExcludeGlobalUser = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/excludeGlobalUser/list`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK"
        ) {
          this.setState({
            excludeGlobalUserList: response.data.body
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await ErorrMsgPopUp.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        let postData = {
          id: id
        }
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/excludeGlobalUser/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.message === "Success!") {
              this.fetchExcludeGlobalUser();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };


  componentDidMount() {
    this.fetchExcludeGlobalUser();
  }

  render() {
    this.filteredItems = this.state.excludeGlobalUserList && this.state.excludeGlobalUserList.filter(
        (item) =>
          item.email &&
          item.email
            .toLowerCase()
            .includes(this.state.filterText.toLowerCase())
    );

    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <FormInput
                        group={"large"}
                        value={this.state.userId}
                        label={"User Id"}
                        onChange={this.handleChange}
                        name={"userId"}
                        error={this.state.errors["userId"]}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label>
                      {`Access Type`} <span className="text-danger">*</span>
                    </label>
                    <Select options={options} onChange={this.onAccessChange} value={this.state.accessType} />
                    {this.state.errors["accessType"] && (
                      <small className={style.danger}>
                        {this.state.errors["accessType"]}
                      </small>
                    )}
                  </div>
                  {auth_service.getPermission("AP_Exclude_Global_User_Add") &&
                  <div className="col-md-3">
                    <Button children={"Add"} onClick={this.onSubmit} />
                  </div>}
                </div>
              </div>
            </div>
            <DataTable
              title="Exclude Global Users"
              columns={tableExcludeGlobalUserConstants(this.handleConfirmModal)}
              data={this.filteredItems}
              customStyles={customStyles}
              actions={this.actionButton()}
              pagination
              highlightOnHover
              pointerOnHover
            />
          </div>

        </div>
      </CommonHeader>
    );
  }
}

export default ExcludeGlobalUser;
