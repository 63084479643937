import React, { Component } from 'react'
import CommonPreloginList from './CommonPreloginList';

class CommonManagementList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render() {
        return (
            <div id="page-wrapper" className={"dgcontainer"}>
                <div id="page-inner">
                    <CommonPreloginList type={this.props.type} />
                </div>
            </div>


        )
    }
}

export default CommonManagementList;