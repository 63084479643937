import axios from "axios";
import React, { Component } from "react";
import { data } from "./Data/mockData";
import { auth_service } from "../../../auth/auth_service";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import "./users_list.module.css";
import { customStyles } from "../../../utils/tableCustomStyle";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import { FilterComponent } from "../../../utils/filter";
import Button from "../../../components/Buttons/button";
import styles from "./users_list.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../../utils/commonHeader";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

const options = [
  { value: "name", label: "Filter By Name" },
  { value: "status", label: "Filter By Name" },
  { value: "name", label: "Filter By Name" },
];

export class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      usersList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.usersList.filter(
              (item) =>
                item.firstName &&
                item.firstName
                  .toLowerCase()
                  .includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all cuses list
  fetchAllUsersList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/adminUser/list?status=undefined&createdBy=" +
        this.state.userData.firstName +
        "&entryDateTo=undefined&entryDateFrom=undefined&searchTerm=undefined&searchValue=undefined",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          this.setState({
            usersList: response.data.body,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Delete cause by id
  deleteUser = (id) => {
    axios({
      method: "GET",
      withCredentials: true,
      url: process.env.REACT_APP_userServiceURL + "/backend/cause/delete/" + id,
    })
      .then((response) => {
        this.fetchAllUsersList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("AP_User_ExportCSV", "AP_User_FullAccess") && <Export onExport={() => downloadCSV(this.state.usersList, "user")} />}
      </React.Fragment>
    );
  };

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      if (swalval && swalval.value === true) {
        axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_userServiceURL}/adminUser/delete?id=${id}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              this.fetchAllUsersList();
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response?.data?.status === "409 CONFLICT") {
              MySwal.fire({
                didOpen: () => {
                  MySwal.clickConfirm()
                }
              }).then(() => {
                return MySwal.fire(<p>{error.response.data.message}</p>)
              })
            }
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    this.fetchAllUsersList();
  }

  render() {
    this.filteredItems = this.state.usersList.filter(
      (item) =>
        item.firstName &&
        item.firstName
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Users"
                columns={tableConstants(
                  this.fetchAllUsersList,
                  this.handleConfirmModal
                )}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("AP_User_Create", "AP_User_FullAccess") && (
        <Link
          to="/admin-panel/user/create"
          className={`btn ${styles.createforumbtn}`}
        >
          <i className="iAddCircle"></i> Create Users
        </Link>
        )}
      </CommonHeader>
    );
  }
}

export default UsersList;
