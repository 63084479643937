import React, { Component } from "react";
import Button from "../../components/Buttons/button";
import FormInput from "../../components/InputFields/form_input";
import styles from "./login.module.css";
import axios from "axios";
import { auth_service } from "../../auth/auth_service";
import style from "../../components/InputFields/form_input.module.css";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        password: "",
      },
      responseMessage: '',
      errors: {},
    };
  }

  //handle input change
  input_change = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  // validate create user
  validate = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.user.username) {
      formIsValid = false;
      errors["username"] = "Please enter your username";
    }
    if (!this.state.user.password) {
      formIsValid = false;
      errors["password"] = "Please enter your password";
    }
    if (this.state.user.username && this.state.user.username.length < 3) {
      formIsValid = false;
      errors["username"] = "Username must be at least 3 character";
    }
    if (this.state.user.password && this.state.user.password.length < 3) {
      formIsValid = false;
      errors["password"] = "Password must be at least 3 character";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  //on submit click
  onSubmit = () => {
    if (this.validate()) {
      const { user: { username, password } } = this.state;
      let postBody = {
        username: username,
        password: password,
      };
      axios({
        method: "post",
        data: postBody,
        url: process.env.REACT_APP_userServiceURL + "/adminUser/login",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }).then((response) => {
        if (
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body &&
          response.data.body.user
        ) {
          auth_service.setUserData(response.data.body.user);
          auth_service.setUserPermission(response.data.body.user.permission);
          auth_service.setRefreshToken(response.headers["authorization"]);
          window.location.href = "/dashboard";
        }
      }).catch((error) => {
        console.log(error);
        this.setState({
          responseMessage: error.response.data.message
        })
      });;
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          responseMessage: ''
        });
      }, 5000);
    }
  };

  render() {
    const {
      errors,
      user: { username, password },
    } = this.state;
    return (
      <div className={styles.loginBody}>
        <div className={styles.loginLogo}>
          <img
            src="http://dev.backoffice.beegram.in/assets/img/logo_big.png"
            alt="welogo"
          ></img>
        </div>
        <div className={"container mt-40 text-center"}>
          <div className="loginPanel">
            <h2 className={styles.logTitle}>Log In</h2>
            <FormInput
              type={"text"}
              name={"username"}
              value={username}
              error={this.state.errors["username"]}
              placeholder={"User Name"}
              onChange={this.input_change}
              className={"form-control"}
            />
            <FormInput
              type={"password"}
              name={"password"}
              value={password}
              error={this.state.errors["password"]}
              placeholder={"Password"}
              onChange={this.input_change}
              className={"form-control"}
            />
            {this.state.responseMessage && (
              <small className={style.danger}>
                {this.state.responseMessage}
              </small>
            )}
            <div className="logBottBtns">
              <Button children={"Login"} onClick={this.onSubmit} />
              <span><Button children={"Forgot Password"} /></span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
