import { epochTogmt, trimDescription } from "../../../../utils/common";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import { Link } from "react-router-dom";
import { auth_service } from "../../../../auth/auth_service";

export const tableTrialConstants = (openExtraTrialModal) => {


  return [
    {
      id: 1,
      name: "Name",
      cell: (row) => {
        return <Link to={`/subscription/trial-user-details/${row.userId}`}>{row.username}</Link>
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Phone no",
      selector: (row) => row.mobile === 0 ? "NA" : row.mobile,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Action",
      cell: (row) => {
        return (
          <button  className={ "btnUpdte" + (auth_service.getPermission("SM_TRIAL_Extra", "SM_TRIAL_FullAccess") && row.extraTrialAllowed ? "" : " disabled")} onClick={(e) => openExtraTrialModal(row.userId)} disabled={auth_service.getPermission("SM_TRIAL_Extra", "SM_TRIAL_FullAccess") && row.extraTrialAllowed ? false : true}>
            Extra Trial
          </button>
        )
      },
      sortable: true,
      reorder: true,
    }
  ];
};
