import axios from "axios";
import React, { Component } from "react";
import styles from "./generate-report.module.css";
import { tableConstants } from "./Data/tableConstants";
import { mockData } from "./Data/mockData";
import { auth_service } from "../../auth/auth_service";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "../../components/Buttons/button";
import { utcTodate } from "../../utils/common";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/tableCustomStyle";
import { CommonHeader } from "../../utils/commonHeader";


export class GenerateReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
      
  }

  render() {
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            
            <DataTable
              title="Generate Reports"
              columns={tableConstants()}
              data={mockData}
              customStyles={customStyles}
              
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight={"calc(100vh - 216px)"}
            />
            
          </div>
        </div>
        {auth_service.getPermission("") && (
          <Link to="/generate-reports/create" className={`btn ${styles.createforumbtn}`}>
            <i className="iAddCircle"></i> Create Blog
          </Link>
        )}
      </CommonHeader>
    );
  }
}

export default GenerateReport;
