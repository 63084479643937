import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import { customStyles } from "../../utils/tableCustomStyle";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import styles from "./message.module.css";
import { auth_service } from "../../auth/auth_service";
import Export from "../../components/Buttons/export_button";
import { downloadCSV } from "../../utils/export_csv";
import { FilterComponent } from "../../utils/filter";
export class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.messageList.filter(
              (item) =>
                item.name &&
                item.name.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch message list
  fetchMessage = () => {
    axios({
      method: "GET",
       url: `${process.env.REACT_APP_userServiceURL}/messaging/message_service/api/message_group/getAllNewsGroupByStatus?status=all`,
      //url: `${process.env.REACT_APP_userServiceURL}/message/getAllNewsGroup?status=all`,
      withCredentials: true,
    })
      .then((response) => {
        console.log(response);
        if (
          response &&
          response.data &&
          response.data.message === "Success!" &&
          response.data.body
        ) {
          this.setState({
            messageList: response.data.body
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("Messages_ExportCSV","Messages_FullAccess") && <Export onExport={() => downloadCSV(this.state.messageList, "message")} />}
      </React.Fragment>
    );
  };


  componentDidMount() {
    this.fetchMessage();
  }
  render() {
    this.filteredItems = this.state.messageList.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Messages"
                columns={tableConstants(this.fetchMessage)}
                data={this.state.messageList}
                customStyles={customStyles}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("Messages_Create", "Messages_FullAccess") && (
          <Link
            to="/messages/create"
            className={`btn ${styles.createforumbtn}`}
          >
            <i className="iAddCircle"></i> Create Message
          </Link>
        )}
      </CommonHeader>
    );
  }
}

export default Message;
