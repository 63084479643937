import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../auth/auth_service";
import { data } from "./Data/mockData";
import { tableConstants } from "./Data/tableConstants";
import DataTable from "react-data-table-component";
import "./promotion.module.css";
import { customStyles } from "../../utils/tableCustomStyle";
import Export from "../../components/Buttons/export_button";
import { downloadCSV } from "../../utils/export_csv";
import { FilterComponent } from "../../utils/filter";
import Button from "../../components/Buttons/button";
import styles from "./promotion.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { epochTogmt } from "../../utils/common";
import ExportCSV from "../../components/Buttons/export_csv_button";


const MySwal = withReactContent(alertPopUp);

const headers = [
  { label: "Title", key: "title" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Promotion Landing", key: "canLanding" },
  { label: "Status", key: "status" },
];

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};

export class Promotion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      promotionList: [],
      exportPromotionList: [],
      filterText: "",
      filterDisplay: false,
      resetPaginationToggle: false,
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.promotionList.filter(
              (item) =>
                item.title &&
                item.title.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all cuses list
  fetchAllPromotionList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url:
        process.env.REACT_APP_userServiceURL +
        "/backend/promotion/getAll",
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          let exportPromotionList = [];
          let data = response.data.body;
          data && data.map((item, index) => {
            exportPromotionList.push({"title": item.title, "startDate": epochTogmt(item.startDate), "endDate": epochTogmt(item.endDate), "canLanding": item.canLanding, "status": item.status})
          })
          this.setState({
            promotionList: data,
            exportPromotionList: exportPromotionList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };

  // action button
  actionButton = () => {
    return (
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {/* <Export onExport={() => downloadCSV(this.state.promotionList, "promotion")} /> */}
        {auth_service.getPermission("Promotions_ExportCSV", "Promotions_FullAccess") && <ExportCSV headers={headers} data={this.state.exportPromotionList} filename="promotion" />}
      </React.Fragment>
    );
  };
  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/backend/promotion/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.fetchAllPromotionList();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };
  componentDidMount() {
    this.fetchAllPromotionList();
  }
  render() {
    this.filteredItems = this.state.promotionList.filter(
      (item) =>
        item.title &&
        item.title.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className="col2Large">
              <DataTable
                title="Promotion"
                columns={tableConstants(this.fetchAllPromotionList,this.handleConfirmModal)}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("Promotions_Create", "Promotions_FullAccess") && (
        <Link to="/promotion/create" className={`btn ${styles.createforumbtn}`}>
          <i className="iAddCircle"></i> Create Promotion
        </Link>
        )}
      </CommonHeader>
    );
  }
}

export default Promotion;
