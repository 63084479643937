import React, { Component } from "react";
import FormInput from "../../../components/InputFields/form_input";
import styles from "./config_list.module.css";
import axios from "axios";
import styled from "styled-components";
import Button from "../../../components/Buttons/button";
import Select from "react-select";
import history from "../../../utils/history";
import httpService from "../../../AxiosInterceptors";
import style from "../../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getUserBadgeType, userBadgeType } from "../../subscription/CommonSubscriptionFunction";

const ErorrMsgPopUp = withReactContent(alertPopUp)
httpService.setupInterceptors();

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export class UserBadgeConfigForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { type: {}, point: "", pointIssued: "", creditValueInINR: "", creditValueInUSD: "", module: "", moduleValueInINR: "", moduleValueInUSD: "", valuationInINR: "", valuationInUSD: "" },
            errors: {},
            error: false,
            message: null,
            subscriptionId: ""
        };
    }

    validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.type?.value) {
            formIsValid = false;
            errors["type"] = "Please select user badge type";
        }
        if (!this.state.point) {
            formIsValid = false;
            errors["point"] = "Please enter point";
        }
        if (!this.state.pointIssued) {
            formIsValid = false;
            errors["pointIssued"] = "Please enter point issued";
        }
        if (!this.state.valuationInINR) {
            formIsValid = false;
            errors["valuationInINR"] = "Please enter per point valuation in INR";
        }
        if (!this.state.valuationInUSD) {
            formIsValid = false;
            errors["valuationInUSD"] = "Please enter per point valuation in USD";
        }
        if (!this.state.module) {
            formIsValid = false;
            errors["module"] = "Please enter module";
        }
        if (!this.state.moduleValueInINR) {
            formIsValid = false;
            errors["moduleValueInINR"] = "Please enter module value in INR";
        }
        if (!this.state.moduleValueInUSD) {
            formIsValid = false;
            errors["moduleValueInUSD"] = "Please enter module value in USD";
        }

        this.setState({
            errors: errors,
        });
        return formIsValid;
    };

    onHandleChange = (data, fieldName) => {
        this.setState({
            [fieldName]: fieldName === "type" ? data : data?.target?.value?.replace(/^\D+/g, '')?.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, ''),
        }, () => {
            if(fieldName === "valuationInINR" || fieldName === "valuationInUSD" || fieldName === "pointIssued") {
                this.setState({
                    creditValueInINR: ((this.state.pointIssued || 0) * (this.state.valuationInINR || 0)).toFixed(2),
                    creditValueInUSD: ((this.state.pointIssued || 0) * (this.state.valuationInUSD || 0)).toFixed(2),
                })
            }
        });
    };

    onSubmit = () => {
        if (this.validateForm()) {
            let postData = {
                type: this.state.type?.value,
                point: this.state.point,
                credit: { pointIssued: this.state.pointIssued, creditValueInINR: this.state.creditValueInINR, creditValueInUSD: this.state.creditValueInUSD },
                incentives: { module: this.state.module, moduleValueInINR: this.state.moduleValueInINR, moduleValueInUSD: this.state.moduleValueInUSD },
                conversionFactor: { valuationInINR: this.state.valuationInINR, valuationInUSD: this.state.valuationInUSD }
            };
            if (this.props.formType === "EDIT") {
                postData.id = this.props.id;
            }
            axios({
                method: this.props.formType === "EDIT" ? "PUT" : "POST",
                url: `${process.env.REACT_APP_userServiceURL}/backend/user/badge/${this.props.formType === "CREATE" ? "save" : "update"}`,
                headers: { "Content-Type": "application/json" },
                data: postData,
                withCredentials: true,
            }).then((response) => {
                if (response.data.message === "Success!") {
                    history.goBack();
                }
            }).catch((error) => {
                ErorrMsgPopUp.fire({
                    didOpen: () => {
                        ErorrMsgPopUp.clickConfirm()
                    }
                }).then(() => {
                    return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
                })
            });
        }
        {
            setTimeout(() => {
                this.setState({
                    errors: {},
                    error: false
                });
            }, 5000);
        }
    };

    fetchUserBadgeConfigById = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/backend/user/badge/get?id=${this.props.id}`,
            withCredentials: true,
        }).then((response) => {
            if (response?.data?.body && response?.data?.status === "200 OK") {
                let data = response?.data?.body;
                this.setState({
                    point: data?.point,
                    pointIssued: data?.credit?.pointIssued,
                    creditValueInINR: data?.credit?.creditValueInINR,
                    creditValueInUSD: data?.credit?.creditValueInUSD,
                    module: data?.incentives?.module,
                    moduleValueInINR: data?.incentives?.moduleValueInINR,
                    moduleValueInUSD: data?.incentives?.moduleValueInUSD,
                    valuationInINR: data?.conversionFactor?.valuationInINR,
                    valuationInUSD: data?.conversionFactor?.valuationInUSD,
                    type: { value: data?.type, label: getUserBadgeType(data?.type) }
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    componentWillUnmount() {
        ErorrMsgPopUp.close();
    }

    componentDidMount() {
        if (this.props.formType === "EDIT") {
            this.fetchUserBadgeConfigById();
        }
    }

    render() {
        const { formType } = this.props;
        const { point, type, pointIssued, creditValueInINR, creditValueInUSD, module, moduleValueInINR, moduleValueInUSD, errors, valuationInUSD, valuationInINR } = this.state;
        return (
            <div className={`${styles.forumcreatwrap}`}>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label className="d-flex align-items-center">User Badge Type <span className="text-danger">*</span></label>
                        <Select
                            options={userBadgeType}
                            onChange={(data) => this.onHandleChange(data, "type")}
                            placeholder={"Start typing"}
                            closeMenuOnSelect={true}
                            filterOption={(option) => option.value !== "ALL"}
                            value={type}
                            isDisabled={formType === "EDIT" ? true : false}
                            styles={{
                                option: (
                                    styles,
                                    { data, isDisabled, isFocused, isSelected }
                                ) => {
                                    const color = "#cfe7c7";
                                    return {
                                        ...styles,
                                        backgroundColor: isDisabled
                                            ? null
                                            : isSelected
                                                ? "#cfe7c7"
                                                : isFocused
                                                    ? "#cfe7c7"
                                                    : null,
                                        color: isDisabled
                                            ? "#ccc"
                                            : isSelected
                                                ? color > 2
                                                    ? "#00374d"
                                                    : "#00374d"
                                                : data.color,
                                        cursor: isDisabled ? "not-allowed" : "default",

                                        ":active": {
                                            ...styles[":active"],
                                            backgroundColor:
                                                !isDisabled && (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                        },
                                    };
                                },
                            }}
                        />
                        {errors["type"] && <small className={style.danger}>{errors["type"]}</small>}
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={point}
                            label={"Point"}
                            onChange={(e) => this.onHandleChange(e, "point")}
                            name={"point"}
                            error={errors["point"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={valuationInINR}
                            label={"Per Point Valuation(In INR)"}
                            onChange={(e) => this.onHandleChange(e, "valuationInINR")}
                            name={"valuationInINR"}
                            error={errors["valuationInINR"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={valuationInUSD}
                            label={"Per Point Valuation(In USD)"}
                            onChange={(e) => this.onHandleChange(e, "valuationInUSD")}
                            name={"valuationInUSD"}
                            error={errors["valuationInUSD"]}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <h5>Badge Credit Config</h5>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={pointIssued}
                            label={"Point Issued"}
                            onChange={(e) => this.onHandleChange(e, "pointIssued")}
                            name={"pointIssued"}
                            error={errors["pointIssued"]}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={creditValueInINR}
                            label={"Credit Value(In INR)"}
                            //onChange={(e) => this.onHandleChange(e, "creditValueInINR")}
                            name={"creditValueInINR"}
                            error={errors["creditValueInINR"]}
                            isDisable
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={creditValueInUSD}
                            label={"Credit Value(In USD)"}
                            //onChange={(e) => this.onHandleChange(e, "creditValueInUSD")}
                            name={"creditValueInUSD"}
                            error={errors["creditValueInUSD"]}
                            isDisable
                        />
                    </div>
                </div>
                <div className="form-group">
                    <h5>Badge Incentive Config</h5>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={module}
                            label={"Module"}
                            onChange={(e) => this.onHandleChange(e, "module")}
                            name={"module"}
                            error={errors["module"]}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={moduleValueInINR}
                            label={"Module Value(In INR)"}
                            onChange={(e) => this.onHandleChange(e, "moduleValueInINR")}
                            name={"moduleValueInINR"}
                            error={errors["moduleValueInINR"]}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            group={"large"}
                            value={moduleValueInUSD}
                            label={"Module Value(In USD)"}
                            onChange={(e) => this.onHandleChange(e, "moduleValueInUSD")}
                            name={"moduleValueInUSD"}
                            error={errors["moduleValueInUSD"]}
                        />
                    </div>
                </div>

                <ButtonWrap className={`${styles.bottombutt}`}>
                    <Button children={this.props.formType === "CREATE" ? "Create" : "Update"} onClick={this.onSubmit} />
                </ButtonWrap>
            </div>
        );
    }
}

export default UserBadgeConfigForm;