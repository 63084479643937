import axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { CommonHeader } from "../../../utils/commonHeader";
import { customStyles } from "../../../utils/tableCustomStyle";
import { tableConstants } from "./Data/tableConstants";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "../../../components/Buttons/button";
import { DateRange } from "react-date-range";
import { epochTogmt, utcTodate } from "../../../utils/common";
import styles from "./operator_details.module.css";
import { FilterComponent } from "../../../utils/filter";
import Export from "../../../components/Buttons/export_button";
import { downloadCSV } from "../../../utils/export_csv";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CSVLink } from "react-csv";
import { auth_service } from "../../../auth/auth_service";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const headers = [
    { label: "Username", key: "username" },
    { label: "Email", key: "type" },
    { label: "Custom Url", key: "customUrl" },
];
// date range data
const options = [
    { value: "ALL", label: "ALL" },
    { value: "TODAY", label: "TODAY" },
    { value: "YESTERDAY", label: "YESTERDAY" },
    { value: "LAST7DAY", label: "LAST 7 DAY" },
    { value: "LAST14DAY", label: "LAST 14 DAY" },
    { value: "LASTMONTH", label: "LAST MONTH" },
    { value: "CUSTOM", label: "CUSTOM" },
];
export class OpratorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operatorList: [],
            exportOperatorList: [],
            date: { startDate: new Date(), endDate: new Date(), key: "selection" },
            displayRangeButton: false,
            displayRange: false,
            filterText: "",
            filterTextOne: "",
            filterDisplay: false,
            filterData: "Name",
            statusData: { value: "ALL", label: "ALL" },
            dateData: { value: "ALL", label: "ALL" },
            isEnabledCancelFiler: false,
            loading: false,
            totalRows: 0,
            perPage: 10,
            changeType: 'ALL'
        };
    }

    // subheader of a component
    getSubHeaderComponent = () => {
        return (
            <React.Fragment>
                <FilterComponent
                    onFilter={(e) => {
                        let newFilterText = e.target.value;
                        this.filteredItems = this.state.operatorList.filter(
                            (item) =>
                                item.username &&
                                item.username.toLowerCase().includes(newFilterText.toLowerCase())
                        );
                        this.setState({ filterText: newFilterText });
                    }}
                    searchType={'Search by name'}
                    onClear={this.handleClear}
                    filterText={this.state.filterText}
                />
            </React.Fragment>
        );
    };


    changeSearchData = (data) => {
        this.setState({
            filterData: data,
            filterText: ""
        });
    }

    fetchFilterOperatorList = (date, page) => {
        this.setState({
            loading: true
        })
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_userServiceURL}/user/operator/search-by-date-status/?page=${page}&size=${this.state.perPage}&date=${date}&status=ALL`,
            withCredentials: true,
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        operatorList: response.data.body && response.data.body.content,
                        totalRows: response.data.body.totalElements,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    operatorList: [],
                    loading: false
                });
            });
    };

    // fetch date from date range
    fetchFromDateRange = (startDate, endDate, page) => {
        this.setState({
            displayRange: false,
            //displayRangeButton: false,
            loading: true
        });
        axios({
            method: "GET",
            withCredentials: true,
            url:
                process.env.REACT_APP_userServiceURL +
                "/user/operator/search-by-date-status/?page=" +page + "&size="+this.state.perPage+"&date=CUSTOM&status=ALL&startDate=" +
                utcTodate(startDate) +
                "&endDate=" +
                utcTodate(endDate),
        })
            .then((response) => {
                if (response && response.data && response.data.status === "200 OK") {
                    this.setState({
                        operatorList: response.data.body && response.data.body.content,
                        totalRows: response.data.body.totalElements,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    operatorList: [],
                    loading: false
                })
            });
    };

    // date range change
    handleDateChange = (newValue) => {
        if (newValue.value === "CUSTOM") {
            this.setState({
                dateData: { value: newValue.value, label: newValue.label },
                displayRangeButton: true,
                isEnabledCancelFiler: false,
                changeType: 'DATE'
            });
        } else {
            this.setState({
                dateData: { value: newValue.value, label: newValue.label },
                displayRangeButton: false,
                isEnabledCancelFiler: true,
                changeType: 'DATE'
            }, () => {
                this.fetchFilterOperatorList(this.state.dateData.value,0);
            })
        }
    };

    // display range select
    displayRangeSelect = () => {
        this.setState({
            displayRange: this.state.displayRange ? false : true,
        });
    };

    // handle range select
    handleSelect = (range) => {
        this.setState(
            {
                date: {
                    ...this.state.date,
                    startDate: range.selection.startDate,
                    endDate: range.selection.endDate,
                    key: range.selection.key,
                },
            });
    };

    // handle filter display
    showFilter = () => {
        this.setState({
            filterDisplay: this.state.filterDisplay ? false : true,
        });
    };

    //Cancel filer
    cancelFilter = () => {
        this.setState({
            isEnabledCancelFiler: false,
            dateData: { value: "ALL", label: "ALL" },
        });
        this.fetchOperatorDetailsList(0);
    }

    //Cancel date dange
    cancelDateRange = () => {
        this.setState({
            displayRangeButton: false,
            isEnabledCancelFiler: false,
            statusData: { value: "ALL", label: "ALL" },
            dateData: { value: "ALL", label: "ALL" },
        })
        this.fetchOperatorDetailsList(0);
    }

    // action button
    actionButton = () => {
        return (
            <React.Fragment>
                {this.getSubHeaderComponent()}
                {auth_service.getPermission("AD_Operator_ExportCSV") && <a className={"btn addRoleGlobal"} href="#exportCsvModal" data-toggle="modal"><i class="iDownloadFile" /> Export</a>}
            </React.Fragment>
        );
    };

    fetchOperatorDetailsList = async page => {
        this.setState({
          loading: true
        })
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_userServiceURL}/user/operator?page=${page}&size=${this.state.perPage}`,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.setState({
                operatorList: response.data.body && response.data.body.content,
                totalRows: response.data.body.totalElements,
                loading: false
              })
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
                operatorList: [],
                loading: false
              })
          });
      };
    
      handlePageChange = page => {
        if(this.state.changeType === "DATE" && this.state.dateData.value !== "CUSTOM") {
            this.fetchFilterOperatorList(this.state.dateData.value, page-1);
        } else if(this.state.changeType === "DATE" && this.state.dateData.value === "CUSTOM") {
            this.fetchFromDateRange(this.state.date.startDate, this.state.date.endDate, page-1);
        } else {
            this.fetchOperatorDetailsList(page-1);
        }
      };
    
      handlePerRowsChange = async (newPerPage, page) => {
        let url = '';
        if(this.state.changeType === "DATE" && this.state.dateData.value !== "CUSTOM") {
          url = `${process.env.REACT_APP_userServiceURL}/user/operator/search-by-date-status/?page=${page-1}&size=${newPerPage}&date=${this.state.dateData.value}&status=ALL`;
        } else if(this.state.changeType === "DATE" && this.state.dateData.value === "CUSTOM") {
          url = process.env.REACT_APP_userServiceURL + "/user/operator/search-by-date-status/?page=" +(page-1) + "&size="+newPerPage+"&date=CUSTOM&status=ALL&startDate=" +utcTodate(this.state.date.startDate) +"&endDate=" +utcTodate(this.state.date.endDate);
        } else {
          url = `${process.env.REACT_APP_userServiceURL}/user/operator?page=${page-1}&size=${newPerPage}`;
        }
        this.setState({
          loading: true
        })
        axios({
          method: "GET",
          url: url,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
              this.setState({
                operatorList: response.data.body.content,
                perPage: newPerPage,
                loading: false
              })
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
                operatorList: [],
                loading: false
            })
          });
      };
    
      closeDateRange = () => {
        this.setState({
          displayRange: false
        })
      }
    
    exportButton = (startDate, endDate) => {
        this.setState({
            displayRange: false
        });
        axios({
            method: "GET",
            withCredentials: true,
            url: process.env.REACT_APP_userServiceURL +"/user/operator/search-by-date-status/?page=0&size=2000&date=CUSTOM&status=ALL&startDate=" +utcTodate(startDate) +"&endDate=" +utcTodate(endDate),
        })
            .then((response) => {
            if (response && response.data && response.data.status === "200 OK") {
                let date = new Date();
                let dateMs = date.valueOf();
                const file = "operator_list-"+epochTogmt(dateMs)+"-"+dateMs+".csv"
                this.setState({
                    exportOperatorList: response.data.body && response.data.body.content,
                    fileName: file
                }, () => {
                    this.csvLink.link.click();
                });
                window.$("#exportCsvModal").modal('hide');
            }
            })
            .catch((error) => {
            console.log(error);
            if (error.response.data.status === "404 NOT_FOUND") {
                window.$("#exportCsvModal").modal('hide');
                ErorrMsgPopUp.fire({
                  didOpen: () => {
                    ErorrMsgPopUp.clickConfirm()
                  }
                }).then(() => {
                  return ErorrMsgPopUp.fire(<p>Organization not found in this date range.</p>)
                })
            }
            this.setState({
                exportOperatorList: []
            });
        });
    };

    exportCsvModalClose = () => {
    this.setState({
        date: { startDate: new Date(), endDate: new Date(), key: "selection" },
        displayRange: false
    })
    }

    componentDidMount() {
        //this.fetchOperatorDetails();
        this.fetchOperatorDetailsList(0);
    }

    render() {
        const { operatorList, displayRange, displayRangeButton, date, isEnabledCancelFiler } = this.state;
        if (this.state.operatorList !== null) {
            this.filteredItems = this.state.operatorList.filter(
                (item) =>
                    item.username &&
                    item.username.toLowerCase().includes(this.state.filterText.toLowerCase())
            );
        }

        return (
            <CommonHeader>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={styles.pagetopwrap}>
                            <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                                <div className={`${styles.ptwrow}`}>
                                    <div className="col-md-3">
                                        <Select
                                            options={options}
                                            placeholder={"ALL"}
                                            value={this.state.dateData}
                                            onChange={this.handleDateChange}
                                            styles={{
                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled &&
                                                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                    {isEnabledCancelFiler &&
                                        <div className="col-md-3">
                                            <Button
                                                onClick={(e) => {
                                                    this.cancelFilter();
                                                }}
                                                type={"anchor"}
                                                children={`Clear Filter`}
                                            />
                                        </div>
                                    }
                                    {displayRangeButton && (
                                        <>
                                            <div className="col-md-5">
                                                <Button
                                                    onClick={this.displayRangeSelect}
                                                    type={"range"}
                                                    children={`${utcTodate(
                                                        this.state.date.startDate
                                                    )} to ${utcTodate(this.state.date.endDate)}`}
                                                />
                                                <Button
                                                    onClick={(e) => {
                                                        this.fetchFromDateRange(
                                                            this.state.date.startDate,
                                                            this.state.date.endDate,
                                                            0
                                                        );
                                                    }}
                                                    type={"anchor"}
                                                    children={`Apply Filter`}
                                                />
                                                <Button
                                                    onClick={(e) => {
                                                        this.cancelDateRange();
                                                    }}
                                                    type={"anchor"}
                                                    children={`Cancel`}
                                                />
                                                {displayRange && (
                                                    <DateRange
                                                        ranges={[date]}
                                                        onChange={this.handleSelect}
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <DataTable
                            title="Operator User List"
                            columns={tableConstants()}
                            data={this.filteredItems}
                            customStyles={customStyles}
                            actions={this.actionButton()}
                            pagination
                            highlightOnHover
                            pointerOnHover

                            progressPending={this.state.loading}
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            onChangeRowsPerPage={this.handlePerRowsChange}
                            onChangePage={this.handlePageChange}
                        />
                    </div>

                    <CSVLink headers={headers} data={this.state.exportOperatorList} filename={this.state.fileName} ref={(r) => (this.csvLink = r)}></CSVLink>

                    {/* Export Button */}
                    <div class="modal fade dgpopup" id="exportCsvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                        <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Select Date Rage</h5>
                            </div>
                            <div class="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <Button
                                    onClick={this.displayRangeSelect}
                                    type={"range"}
                                    children={`${utcTodate(
                                        this.state.date.startDate
                                    )} to ${utcTodate(this.state.date.endDate)}`}
                                    />
                                    <Button
                                    onClick={(e) => {
                                        this.closeDateRange();
                                    }}
                                    type={"anchor"}
                                    children={`CLose`}
                                    />
                                    {this.state.displayRange && 
                                    <DateRange
                                        ranges={[date]}
                                        onChange={this.handleSelect}
                                        maxDate={new Date()}
                                    />
                                    }
                                </div>    
                            </div>
                            </div>
                            <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={this.exportCsvModalClose} id="closePermissionModal" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.exportButton(this.state.date.startDate, this.state.date.endDate)}><i class="iDownloadFile" /> Export</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </CommonHeader>
        );
    }
}

export default OpratorDetails;
