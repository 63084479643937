import React from "react";
import { Link } from "react-router-dom";
import StatusDetails from "../../../../components/StatusDetails/status_details";
import StatusDrop from "../../../../components/StatusDropDown/status_drop";

export const tableBelongHereConstants = (fetchAllBelongHereList, handleConfirmModal, loginType) => {
  return [
    {
      id: 1,
      name: "Title",
      cell: (row) => {
        return (
          <Link to={`/${loginType !== "POST_LOGIN" ? "pre-login" : "post-login"}/belong-here/edit/${row.id}`}>
            <span className="sub">{row.title}</span>
          </Link>
        )
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 2,
      name: "Image",
      cell: (row) => {
        return (
          <img
            src={row.attachment?.attachmentUrl}
            alt="coverImage"
            style={{ width: "100px", height: "56px" }}
          />
        )
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Link",
      cell: (row) => {
        return (
          <a href={row.link} target="_blank">{row.link}</a>
        )
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 7,
      name: "Status",
      cell: (row) => {
        return (
          <StatusDetails status={row.status} />
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Action",
      cell: (row) => {
        let content = "";
        let status = "";
        if (row.status === "DRAFT") {
          content = "Approve";
          status = "PENDING_APPROVAL";
        }
        if (row.status === "PENDING_APPROVAL") {
          content = "Publish";
          status = "PUBLISH";
        }
        if (row.status === "PUBLISH") {
          content = "Enable";
          status = "ENABLE";
        }
        if (row.status === "ENABLE") {
          content = "Disable";
          status = "DISABLE";
        }
        if (row.status === "DISABLE") {
          content = "Enable";
          status = "ENABLE";
        }
        return (
          <StatusDrop
            children={content}
            id={row.id}
            status={status}
            type={"BELONG_HERE"}
            action={fetchAllBelongHereList}
            fullPermission={loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_FullAccess" : "Post_Login_Belong_Here_FullAccess"}
            approvalPermission={loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_Approval" : "Post_Login_Belong_Here_Approval"}
            publishPermission={loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_Publish" : "Post_Login_Belong_Here_Publish"}
            enablePermission={loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_Enable" : "Post_Login_Belong_Here_Publish"}
            disablePermission={loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_Disable" : "Post_Login_Belong_Here_Disable"}
          />
        );
      },
      sortable: true,
      reorder: true,
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    {
      id: 9,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (row.status === 'ENABLE' ? ' disable' : '')} onClick={(e) => {handleConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    }
  ];
};
