import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import styled from "styled-components";
import { auth_service } from "../../auth/auth_service";
import Button from "../../components/Buttons/button";
import FormInput from "../../components/InputFields/form_input";
import TextArea from "../../components/TextArea/text_area";
import UploadFiles from "../../components/UploadFiles/upload_files";
import { CommonHeader } from "../../utils/commonHeader";
import history from "../../utils/history";
import styles from "./generate-report.module.css";
import style from "../../components/InputFields/form_input.module.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const ErorrMsgPopUp = withReactContent(alertPopUp);

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

const reference = [
    { value: "All", label: "All", name: '' },
    { value: "Individual", label: "Individual", name: 'individual' },
    { value: "Organization", label: "Organization", name: 'organization' },
    { value: "Circle", label: "Circle", name: 'circle' }
]

const reportType = [
    { value: "Activity", label: "Activity", name: 'activity' },
    { value: "Projects", label: "Projects", name: 'projects' },
    { value: "Payment", label: "Payment", name: 'payment' }
]

const dateSelector = [
    { value: "TODAY", label: "TODAY" },
    { value: "YESTERDAY", label: "YESTERDAY" },
    { value: "LAST7DAY", label: "LAST 7 DAY" },
    { value: "LAST14DAY", label: "LAST 14 DAY" },
    { value: "MONTHTODATE", label: "MONTH TO DATE" },
    { value: "LASTMONTH", label: "LAST MONTH" },
    { value: "CUSTOM", label: "CUSTOM" },
]

const statusField = [
    {value: "Country", label: "Country"},
    {value: "Persona", label: "Persona"},
    {value: "Skills", label: "Skills"},
    {value: "Specialization", label: "Specialization"},
    {value: "Interests", label: "Interests"}
]

const mandatoryField = [
    {value: "Name", label: "Name"},
    {value: "Country", label: "Country"},
    {value: "Privacy status - On email and phone no", label: "Privacy status - On email and phone no"}
]

const selectField = [
    {value: "Name", label: "Name"},
    {value: "Posts", label: "Posts"},
    {value: "Images", label: "Images"},
    {value: "Blogs", label: "Blogs"},
    {value: "Videos", label: "Videos"},
    {value: "Audios", label: "Audios"},
    {value: "Links", label: "Links"},
    {value: "Documents", label: "Documents"},
    {value: "Circle", label: "Circle"},
    {value: "Organizations", label: "Organizations"},
    {value: "Projects", label: "Projects"},
    {value: "Circle", label: "Circle"},
    {value: "Connects / members / employee", label: "Connects / members / employee"},
    {value: "Followers", label: "Followers"},
    {value: "Following", label: "Following"},
    {value: "Persona", label: "Persona"},
    {value: "Email", label: "Email"},
    {value: "Super admin", label: "Super admin"},
    {value: "URl full link ", label: "URl full link"},
    {value: "State", label: "State"},
    {value: "City", label: "City"},
    {value: "Subscription", label: "Subscription"},
    {value: "Status", label: "Status"},
    {value: "Registered on", label: "Registered on"},
    {value: "Last login", label: "Last login"},
    {value: "Country code", label: "Country code"},
    {value: "Phone number", label: "Phone number"},
]

export class CreateGenerateReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            sectionSelectedValue: 'General',
            reference: [],
            reportType: [],
            dateSelector: [],
            emailModeValue: 'One time',
            filterOnOffValue: 'On',
        };
    }

    //   title change
    titleChange = (event) => {
        this.setState({
            title: event.target.value,
        });
    };

    onChangeValue(value) {
        this.setState({
            sectionSelectedValue: value
        })
    }

    onChangeEmailMode(value) {
        this.setState({
            emailModeValue: value
        })
    }

    onChangeFilterOn(value) {
        this.setState({
            filterOnOffValue: value
        })
    }

    onSubmit = () => {

    }

    componentDidMount() {
        window.$('input[name="emailMode"]').on('click',function(){
            if(window.$(this).val() === 'schedule'){
                window.$('#scheduleMail').show();
            }else{
                window.$('#scheduleMail').val('').hide();
            }
        });

        window.$('input[name="filteronoff"]').on('click',function(){
            if(window.$(this).val() === 'filteron'){
                window.$('#filterWrap').show();
            }else{
                window.$('#filterWrap').val('').hide();
            }
        });
    }

    componentWillUnmount() {
        
    }

    render() {
        const {
            title,
            //reference,
            //reportType,
            //dateSelector,
        } = this.state;
        return (
            <CommonHeader>
                <div className="topglobal">
                    <Link to="/generate-reports" className="goback">
                        Go Back
                    </Link>
                </div>
                <div id="page-wrapper" className={"dgcontainer"}>
                    <div id="page-inner">
                        <div className={`${styles.forumcreatwrap}`}>
                            <FormInput
                                group={"large"}
                                value={title}
                                label={"Report Name"}
                                onChange={this.titleChange}
                            />
                            <div className="form-group">
                                <label>Section</label>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("General")}>General
                                                <input type="radio" name="radio" className={this.state.sectionSelectedValue === "General" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeValue("Explore")}>Explore
                                                <input type="radio" name="radio" className={this.state.sectionSelectedValue === "Explore" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"form-group smallSelectDrop"}>
                                <div className="row">
                                    <div className={"col-md-4"}>
                                        <label>Reference</label>
                                        <Select
                                            placeholder={"Select For"}
                                            options={reference}
                                            styles={{
                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled &&
                                                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className={"col-md-4"}>
                                        <label>Report type</label>
                                        <Select
                                            placeholder={"Select For"}
                                            options={reportType}
                                            styles={{
                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled &&
                                                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className={"col-md-4"}>
                                        <label>Date selector</label>
                                        <Select
                                            placeholder={"Select For"}
                                            options={dateSelector}
                                            styles={{
                                                option: (
                                                    styles,
                                                    { data, isDisabled, isFocused, isSelected }
                                                ) => {
                                                    const color = "#cfe7c7";
                                                    return {
                                                        ...styles,
                                                        backgroundColor: isDisabled
                                                            ? null
                                                            : isSelected
                                                                ? "#cfe7c7"
                                                                : isFocused
                                                                    ? "#cfe7c7"
                                                                    : null,
                                                        color: isDisabled
                                                            ? "#ccc"
                                                            : isSelected
                                                                ? color > 2
                                                                    ? "#00374d"
                                                                    : "#00374d"
                                                                : data.color,
                                                        cursor: isDisabled ? "not-allowed" : "default",

                                                        ":active": {
                                                            ...styles[":active"],
                                                            backgroundColor:
                                                                !isDisabled &&
                                                                (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                        },
                                                    };
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Email mode</label>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeEmailMode("One time")}>One time
                                                <input type="radio" name="emailMode" className={this.state.emailModeValue === "One time" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeEmailMode("Schedule")}>Schedule
                                                <input type="radio" name="emailMode" className={this.state.emailModeValue === "Schedule" ? "checked" : ''} value="schedule" />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class={`form-group ${styles.scheduleMailBox}`} id="scheduleMail" style={{display: 'none'}}>
                                <div class="form-group">
                                    <div class="row align-items-center">
                                        <div class="col-md-2"><label for="">Day of the week</label></div>
                                        <div class="col-md-10">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day1" />
                                                        <label class="custom-control-label" for="day1">Monday</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day2" />
                                                        <label class="custom-control-label" for="day2">Tuesday</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day3" />
                                                        <label class="custom-control-label" for="day3">Wednesday</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day4" />
                                                        <label class="custom-control-label" for="day4">Thrusday</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day5" />
                                                        <label class="custom-control-label" for="day5">Friday</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day6" />
                                                        <label class="custom-control-label" for="day6">Saturday</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="day7" />
                                                        <label class="custom-control-label" for="day7">Sunday</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>
                                <div class="form-group">
                                    <div class="row align-items-center">
                                        <div class="col-md-2"><label for="">Time of the day</label></div>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control" placeholder="Select Time" />
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Email to</label>
                                <input type="text" class="form-control" />

                                <div class={styles.emailtoSelectRow}>
                                    <div class={styles.emailToInn}>
                                        <span>punam@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>durga@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>praveen@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>dheeraj@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>mohit@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>sandip@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>imran@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>tiyasha@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>amit@domain.com <i class="iCross_Rounded"></i></span>
                                        <span>chandidas@domain.com <i class="iCross_Rounded"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Filter on</label>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeFilterOn("On")}>On
                                                <input type="radio" name="filteronoff" className={this.state.filterOnOffValue === "On" ? "checked" : ''} value="filteron" />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div class="custom-control custom-checkbox">
                                            <label class="radio-container" onClick={(e) => this.onChangeFilterOn("Off")}>Off
                                                <input type="radio" name="filteronoff" className={this.state.filterOnOffValue === "Off" ? "checked" : ''} />
                                                <span class="radio-checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" id="filterWrap">
                                <Select
                                    placeholder={"Status"}
                                    classNamePrefix="react-select"
                                    name={"status"}
                                    options={statusField}
                                    isMulti
                                    isClearable
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    styles={{
                                        option: (
                                            styles,
                                            { data, isDisabled, isFocused, isSelected }
                                        ) => {
                                            const color = "#cfe7c7";
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? null
                                                    : isSelected
                                                        ? "#cfe7c7"
                                                        : isFocused
                                                            ? "#cfe7c7"
                                                            : null,
                                                color: isDisabled
                                                    ? "#ccc"
                                                    : isSelected
                                                        ? color > 2
                                                            ? "#00374d"
                                                            : "#00374d"
                                                        : data.color,
                                                cursor: isDisabled ? "not-allowed" : "default",

                                                ":active": {
                                                    ...styles[":active"],
                                                    backgroundColor:
                                                        !isDisabled &&
                                                        (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                },
                                            };
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Mandatory fields</label>
                                <Select
                                    placeholder={"Mandatory fields"}
                                    classNamePrefix="react-select"
                                    name={"mandatoryField"}
                                    options={mandatoryField}
                                    isMulti
                                    isClearable
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    styles={{
                                        option: (
                                            styles,
                                            { data, isDisabled, isFocused, isSelected }
                                        ) => {
                                            const color = "#cfe7c7";
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? null
                                                    : isSelected
                                                        ? "#cfe7c7"
                                                        : isFocused
                                                            ? "#cfe7c7"
                                                            : null,
                                                color: isDisabled
                                                    ? "#ccc"
                                                    : isSelected
                                                        ? color > 2
                                                            ? "#00374d"
                                                            : "#00374d"
                                                        : data.color,
                                                cursor: isDisabled ? "not-allowed" : "default",

                                                ":active": {
                                                    ...styles[":active"],
                                                    backgroundColor:
                                                        !isDisabled &&
                                                        (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                },
                                            };
                                        },
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Select fields</label>
                                <Select
                                    placeholder={"Select fields"}
                                    classNamePrefix="react-select"
                                    name={"mandatoryField"}
                                    options={selectField}
                                    isMulti
                                    isClearable
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    styles={{
                                        option: (
                                            styles,
                                            { data, isDisabled, isFocused, isSelected }
                                        ) => {
                                            const color = "#cfe7c7";
                                            return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                    ? null
                                                    : isSelected
                                                        ? "#cfe7c7"
                                                        : isFocused
                                                            ? "#cfe7c7"
                                                            : null,
                                                color: isDisabled
                                                    ? "#ccc"
                                                    : isSelected
                                                        ? color > 2
                                                            ? "#00374d"
                                                            : "#00374d"
                                                        : data.color,
                                                cursor: isDisabled ? "not-allowed" : "default",

                                                ":active": {
                                                    ...styles[":active"],
                                                    backgroundColor:
                                                        !isDisabled &&
                                                        (isSelected ? "#cfe7c7" : "#cfe7c7"),
                                                },
                                            };
                                        },
                                    }}
                                />
                            </div>
                            <ButtonWrap className={`justify-content-end d-flex ${styles.bottombutt}`} style={{marginTop: '80px'}}>
                                <Button children={"Generate report"} onClick={this.onSubmit} />
                            </ButtonWrap>
                        </div>
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

export default CreateGenerateReport;
