import { Link } from "react-router-dom";
import { auth_service } from "../../../auth/auth_service";

const PreLoginMenuList = ({ type, loginType, ...props }) => {

    return (
        <div class="pagetopwleft wpondMain">
            <div class="ptwrow">
                <div class="topBtns">
                    {auth_service.getPermission(loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_Tab" : "Post_Login_Belong_Here_Tab", loginType !== "POST_LOGIN" ? "Pre_Login_Belong_Here_FullAccess" : "Post_Login_Belong_Here_FullAccess") && <Link className={"btn btn1 " + ((type === "BELONG_HERE" || type === "BELONG_HERE_POST") ? "active" : "")} to={`/${(loginType !== "POST_LOGIN") ? "pre-login" : "post-login"}/belong-here`}>Belong Here</Link>}
                    {auth_service.getPermission(loginType !== "POST_LOGIN" ? "Pre_Login_Inspiring_Stories_Tab" : "Post_Login_Inspiring_Stories_Tab", loginType !== "POST_LOGIN" ? "Pre_Login_Inspiring_Stories_FullAccess" : "Post_Login_Inspiring_Stories_FullAccess") && <Link className={"btn btn1 " + ((type === "INSPIRING_STORY" || type === "INSPIRING_STORY_POST") ? "active" : "")} to={`/${loginType !== "POST_LOGIN" ? "pre-login" : "post-login"}/inspiring-story`}>Inspiring Stories</Link>}
                   
                    {loginType !== "POST_LOGIN" ?
                        <>
                            {auth_service.getPermission("Pre_Login_Project_Manage_Tab","Pre_Login_Project_Manage_FullAccess") && <Link className={"btn btn1 " + (type === "PROJECT" ? "active" : "")} to={"/pre-login/project-management"}>Project Management</Link>}
                            {auth_service.getPermission("Pre_Login_Fund_Raise_Tab","Pre_Login_Fund_Raise_FullAccess") && <Link className={"btn btn1 " + (type === "FUND_RAISE" ? "active" : "")} to={"/pre-login/fund-raise"}>Fund Raise</Link>}
                            {auth_service.getPermission("Pre_Login_Studio_Tab","Pre_Login_Studio_FullAccess") && <Link className={"btn btn1 " + (type === "STUDIO" ? "active" : "")} to={"/pre-login/studio"}>Studio</Link>}
                            {auth_service.getPermission("Pre_Login_Community_Tab","Pre_Login_Community_FullAccess") && <Link className={"btn btn1 " + (type === "COMMUNITIES" ? "active" : "")} to={"/pre-login/communities"}>Communities</Link>}
                            {auth_service.getPermission("Pre_Login_Trending_Content_Tab","Pre_Login_Trending_Content_FullAccess") && <Link className={"btn btn1 " + (type === "TRENDING_CONTENT" ? "active" : "")} to={"/pre-login/trending-content"}>Trending Content</Link>}
                        </> : 
                        <>
                            {auth_service.getPermission("Post_Login_Category_Tab","Post_Login_Category_FullAccess") && <Link className={"btn btn1 " + (type === "CATEGORY" ? "active" : "")} to={"/post-login/category"}>Category</Link>}
                            {auth_service.getPermission("Post_Login_Would_Like_Tab","Post_Login_Would_Like_FullAccess") && <Link className={"btn btn1 " + ((type === "WOULD_LIKE" || type === "VISITOR_USER_HISTORY") ? "active" : "")} to={"/post-login/would-like"}>Would Like</Link>}
                            {auth_service.getPermission("Post_Login_Persona_Tab") &&<Link className={"btn btn1 " + (type === "PERSONA" ? "active" : "")} to={"/post-login/persona"}>Persona</Link>}
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default PreLoginMenuList;