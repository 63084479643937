import { epochTogmt } from "../../../utils/common";

export const tableConstantsCreditUseDetails = () => {
    return [
        {
            id: 1,
            name: "Type",
            selector: (row) => row?.creditUsedSource,
            sortable: true,
        },
        {
            id: 1,
            name: "Amount",
            selector: (row) => row?.creditAmountUsed,
            sortable: true,
        },
        {
            id: 1,
            name: "% adjusted",
            selector: (row) => row?.percentageUsed,
            sortable: true,
        },
        {
            id: 2,
            name: "Date",
            selector: (row) => row?.createTime && epochTogmt(row?.createTime),
            sortable: true,
        },
    ];
};