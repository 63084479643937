import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { auth_service } from "../../../../auth/auth_service";
// This is the table constant/settings which needed to render table elements

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;
export const tableConstants = (handleConfirmModal, deleteConfirmModal) => {
  return [
    {
      id: 1,
      name: "User Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Device List",
      cell: (row) => {
        return (<button  className={ "btnUpdte"} disabled={false} onClick={() => {handleConfirmModal(row)}}>
            Device List
          </button>);
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Delete",
      cell: (row) => {
        return <i className={"iTrashBin" + (!auth_service.getPermission("AP_Restricted_User_Delete", "AP_Restricted_User_FullAccess") ? ' disable' : '')} onClick={(e) => {deleteConfirmModal(row.id)}}></i>;
      },
      sortable: true,
      reorder: true,
    },
  ];
};
