import { epochTogmt } from "../../../utils/common";

export const postDetailsTableConstants = () => {
  return [
    {
      id: 1,
      name: "Reporter",
      selector: (row) => row.reporterName,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Reported Date",
      selector: (row) => epochTogmt(row.createTime),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
  ];
};
