import axios from "axios";
import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageCompress from 'quill-image-compress';
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../../components/Buttons/button";
import FormInput from "../../../../../../components/InputFields/form_input";
import { CommonHeader } from "../../../../../../utils/commonHeader";
import styles from "../../year.module.css";
import { auth_service } from "../../../../../../auth/auth_service";
import history from "../../../../../../utils/history";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import style from "../../../../../../components/InputFields/form_input.module.css";
import stripHtml from "string-strip-html";
import MagicUrl from "quill-magic-url";

const ErorrMsgPopUp = withReactContent(alertPopUp);

const Line = styled.hr`
  margin: 38px 0;
  border-top: 4px solid #154a59;
`;

const ButtonWrap = styled.div`
  justify-content: flex-end !important;
`;

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register("modules/magicUrl", MagicUrl);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
  'alt',
  'height',
  'width',
  'style'
];

const eventType = [
  {name: "Event One", value: "FIRST"},
  {name: "Event Two", value: "SECOND"},
  {name: "Event Three", value: "THIRD"},
  {name: "Event Four", value: "FOURTH"}
]

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

export class EditUpcomingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: this.props.match.params.year,
      title: "",
      linkOne: "",
      linkTwo: "",
      timeZoneOne: "",
      timeZoneTwo: "",
      description: "",
      sectionSelectedValue: "FIRST",
      errors: "",
    };
  }
  validate = () => {
    let errors = {};
    let formIsValid = true;
    // if (!this.state.themeName || this.state.themeName.trim() === "") {
    //   formIsValid = false;
    //   errors["themeName"] = "Please enter theme";
    // }
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter title";
    }
    // if (!this.state.linkOne || this.state.linkOne.trim() === "") {
    //   formIsValid = false;
    //   errors["linkOne"] = "Please enter link zone 1";
    // }
    if (this.state.linkOne) {
      let valudateLink = this.state.linkOne;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      if (urlValid == null) {
        formIsValid = false;
        errors["linkOne"] = "Please enter a valid link";
      }
    }
    // if (!this.state.timeZoneOne || this.state.timeZoneOne.trim() === "") {
    //   formIsValid = false;
    //   errors["timeZoneOne"] = "Please enter time zone 1";
    // }
    if (!this.state.description || this.state.description.trim() === "" || stripHtml(this.state.description) == '') {
      formIsValid = false;
      errors["description"] = "Please enter description";
    }
    if (this.state.description && stripHtml(this.state.description) != "" && stripHtml(this.state.description).match(/\S+/g).length > 750) {
      formIsValid = false;
      errors["description"] = "Description should be within 750 words";
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {},
        });
      }, 5000);
    }
    return formIsValid;
  };

  // handle content change
  contentChange = (value) => {
    this.setState({ description: value });
  };

  //handle change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  //title change
  titleChange = (event) => {
    let data = event.target.value;
    data = data.replace(/[`~!@$%^&*()|+\=?;:'",.#<>\{\}\[\]\\\/\s]/gi, '');
    this.setState({
      [event.target.name]: data.trim(),
    });
  };

  onChangeValue(value) {
    this.setState({
      sectionSelectedValue: value
    })
  }

  onSubmit = () => {
    if (this.validate()) {
      let postData = {
        id: this.state.editData.id,
        status: this.state.editData.status,
        themeName: this.state.themeName,
        title: this.state.title,
        year: this.state.selectedYear,
        linkOne: this.state.linkOne,
        timeZoneOne: this.state.timeZoneOne,
        description: this.state.description,
        wpondAboutEventSection: this.state.sectionSelectedValue
      };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_userServiceURL}/wpondAboutEvent/update`,
        data: postData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.message === "Success!"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
  };

  fetchAboutEventById = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_userServiceURL}/wpondAboutEvent/get?id=${this.props.match.params.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.body &&
          response.data.message === "Success!"
        ) {
          this.setState({
            editData: response.data.body,
            title: response.data.body.title,
            linkOne: response.data.body.linkOne,
            timeZoneOne: response.data.body.timeZoneOne,
            description: response.data.body.description,
            themeName: response.data.body.themeName,
            sectionSelectedValue: response.data.body.wpondAboutEventSection
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  modules = {
    toolbar: [
      [{ header: "2" }, { header: "3"}, {header: "4"}, { font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      handleStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
      },
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 750, // default
      maxHeight: 500, // default
      imageType: 'image/jpeg' + 'image/png', // default
    },
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
      globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    },
  };

  componentDidMount() {
    this.fetchAboutEventById();
  }


  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  render() {
    const { title, linkOne, themeName, timeZoneOne, description } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to={`/wpond/${this.state.selectedYear}/upcoming-event`} className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <div className="form-group">
                <label>
                  Section
                </label>
                <div className="row">
                  {eventType && eventType.map((item) => {
                      return (
                        <div className="col-md-2">
                          <div class="custom-control custom-checkbox">
                            <label class="radio-container" onClick={(e) => this.onChangeValue(item.value)}>{item.name}
                              <input type="radio" name="radio" className={this.state.sectionSelectedValue === item.value ? "checked" : ''} />
                              <span class="radio-checkmark"></span>
                            </label>
                          </div>
                        </div>
                      )
                    })
                    }
                </div>
              </div>
              <FormInput
                group={"large"}
                value={themeName}
                label={"Theme Name"}
                name={"themeName"}
                onChange={this.handleChange}
                error={this.state.errors["themeName"]}
                className="not_mandatory"
              />
              <FormInput
                group={"large"}
                value={title}
                label={"Title"}
                name={"title"}
                onChange={this.handleChange}
                error={this.state.errors["title"]}
              />
              <FormInput
                group={"large"}
                value={linkOne}
                label={"Link Zone"}
                name={"linkOne"}
                onChange={this.handleChange}
                className="not_mandatory"
              />
              <FormInput
                group={"large"}
                value={timeZoneOne}
                label={"Time Zone"}
                name={"timeZoneOne"}
                onChange={this.handleChange}
                className="not_mandatory"
              />
              <div className={"form-group " + (this.state.errors["description"] ? 'error' : '')}>
                <label>
                  Description <span className="text-danger">*</span>
                </label>
                <div className="reactQuillWrap">
                  <ReactQuill
                    value={description}
                    onChange={this.contentChange}
                    modules={this.modules}
                  />
                </div>
                {this.state.errors["description"] && (
                  <small className={style.danger}>
                    {this.state.errors["description"]}
                  </small>
                )}
              </div>
              {auth_service.getPermission("WPOND_Event_Submit", "WPOND_Event_FullAccess") && 
              <ButtonWrap className={`${styles.bottombutt}`}>
                <Button children={"Submit"} onClick={this.onSubmit} />
              </ButtonWrap>}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default EditUpcomingEvent;
