import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UploadFiles from "../../../components/UploadFiles/upload_files";
import { auth_service } from "../../../auth/auth_service";
import Button from "../../../components/Buttons/button";
import FormInput from "../../../components/InputFields/form_input";
import { CommonHeader } from "../../../utils/commonHeader";
import styles from "./create_promotion.module.css";
import DatePicker from "react-datepicker";
import axios from "axios";
import style from "../../../components/InputFields/form_input.module.css";
import "react-datepicker/dist/react-datepicker.css";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import history from "../../../utils/history";

const ErorrMsgPopUp = withReactContent(alertPopUp)

const ButtonWrap = styled.div`
  justify-content: flex-end;
`;

export class CreatePromotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: auth_service.getUserData(),
      coverImage: null,
      title: '',
      link: "",
      noOfDays: "",
      timeUserSee: "",
      startDateErrorMessage: "",
      endDateErrorMessage: "",
      coverImageMessage: "",
      errors: {},
    };
  }

  // validate faq create
  validate = () => {
    let formIsValid = true;
    let errors = {};
    if (this.state.coverImage === null || this.state.coverImage === "" || this.state.coverImage === undefined) {
      formIsValid = false;
      this.setState({
        coverImageMessage: "Please select cover image"
      })
    }
    if (!this.state.title || this.state.title.trim() === "") {
      formIsValid = false;
      errors["title"] = "Please enter title";
    }
    if (!this.state.link || this.state.link.trim() === "") {
      formIsValid = false;
      errors["link"] = "Please enter link";
    }
    if (!this.state.timeUserSee) {
      formIsValid = false;
      errors["timeUserSee"] = "Please enter time";
    }
    if (!this.state.noOfDays) {
      formIsValid = false;
      errors["noOfDays"] = "Please enter days";
    }
    if (!this.state.startDate) {
      formIsValid = false;
      this.setState({
        startDateErrorMessage: "Please select start date"
      })
    }
    if (!this.state.endDate) {
      formIsValid = false;
      this.setState({
        endDateErrorMessage: "Please select end date"
      })
    }
    if (this.state.startDate && this.state.endDate) {
      if (this.state.startDate > this.state.endDate) {
        formIsValid = false;
        this.setState({
          endDateErrorMessage: "End date should be after start date"
        })
      }
    }
    if (this.state.link) {
      let valudateLink = this.state.link;
      let urlValid = valudateLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      if (urlValid == null) {
        formIsValid = false;
        errors["link"] = "Please enter a valid link";
      }
    }
    this.setState({
      errors: errors,
    });
    {
      setTimeout(() => {
        this.setState({
          errors: {},
          coverImageMessage: "",
          startDateErrorMessage: "",
          endDateErrorMessage: ""
        });
      }, 5000);
    }
    return formIsValid;
  };

  // cover image update
  updateUploadedFiles = (files) => {
    this.setState({
      coverImage: files[0],
    });
  };

  //date change
  handleDateChange = (date, name) => {
    if (date) {
      date = date.getTime();
    }
    this.setState({
      [name]: date
    })
  };

  //handle change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  //   handle number change
  handleNumberChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  handleChangeChk = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  }

  // on faq submit
  onSubmit = () => {
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.coverImage);
      let postData = {
        title: this.state.title,
        link: this.state.link,
        noOfTime: this.state.timeUserSee,
        noOfDays: this.state.noOfDays,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        status: "DRAFT",
        canLanding: this.state.promotionPopup
      };
      formData.append("data", JSON.stringify(postData));
      axios({
        method: "POST",
        url: process.env.REACT_APP_userServiceURL + "/backend/promotion/create",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        withCredentials: true,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.status === "201 CREATED"
          ) {
            history.goBack();
          }
        })
        .catch((error) => {
          console.log("object", error);
          ErorrMsgPopUp.fire({
            didOpen: () => {
              ErorrMsgPopUp.clickConfirm()
            }
          }).then(() => {
            return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
          })
        });
    }
    {
      setTimeout(() => {
        this.setState({
          errors: {}
        });
      }, 5000);
    }
  };

  componentWillUnmount() {
    ErorrMsgPopUp.close();
  }

  render() {
    const { title, noOfDays, timeUserSee, link, startDate, endDate } = this.state;
    return (
      <CommonHeader>
        <div className="topglobal">
          <Link to="/promotion" className="goback">
            Go Back
          </Link>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <div className={`${styles.forumcreatwrap}`}>
              <UploadFiles
                accept=".jpg,.png,.jpeg"
                label="Cover Image"
                updateFilesCb={this.updateUploadedFiles}
                imagePresent={this.state.coverImageMessage ? 'noImage' : ''}
              />
              {this.state.coverImageMessage &&
                <div className="text-center">
                  <small class="form_input_danger__3kp97">{this.state.coverImageMessage}</small>
                </div>
              }
              <FormInput
                group={"large"}
                value={title}
                label={"Title"}
                name={"title"}
                onChange={this.handleChange}
                error={this.state.errors["title"]}
              />
              <FormInput
                group={"large"}
                value={link}
                label={"Link"}
                name={"link"}
                onChange={this.handleChange}
                error={this.state.errors["link"]}
              />
              <FormInput
                group={"large"}
                value={timeUserSee}
                label={"Times a user can see"}
                name={"timeUserSee"}
                onChange={this.handleNumberChange}
                error={this.state.errors["timeUserSee"]}
              />
              <FormInput
                group={"large"}
                value={noOfDays}
                label={"After how many days"}
                name={"noOfDays"}
                onChange={this.handleNumberChange}
                error={this.state.errors["noOfDays"]}
              />
              <div className="row">
                <div className="col-md-6">
                  <div className={"form-group " + (this.state.startDateErrorMessage ? 'error' : '')}>
                    <label>Start Date <span className="text-danger">*</span>{" "}</label>
                    <div className="d-flex">
                      <DatePicker
                        className={"form-control custom-input-date"}
                        dateFormat={"dd-MMM-yyyy"}
                        minDate={new Date()}
                        openToDate={new Date()}
                        dropdownMode="select"
                        placeholderText="Start Date"
                        selected={startDate}
                        onChange={date => {
                          this.handleDateChange(date, "startDate")
                        }}
                      />
                    </div>
                    {this.state.startDateErrorMessage &&
                      <div className="">
                        <small class="form_input_danger__3kp97">{this.state.startDateErrorMessage}</small>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={"form-group " + (this.state.endDateErrorMessage ? 'error' : '')}>
                    <label>End Date <span className="text-danger">*</span>{" "}</label>
                    <DatePicker
                      className={"form-control custom-input-date"}
                      dateFormat={"dd-MMM-yyyy"}
                      minDate={new Date(startDate)}
                      dropdownMode="select"
                      placeholderText="End Date"
                      selected={endDate}
                      onChange={date => {
                        this.handleDateChange(date, "endDate")
                      }}
                    />
                    {this.state.endDateErrorMessage &&
                      <div className="">
                        <small class="form_input_danger__3kp97">{this.state.endDateErrorMessage}</small>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" defaultChecked={this.state.promotionPopup} ref="promotionPopup" name="promotionPopup" onClick={this.handleChangeChk}  />
                    <label className="custom-control-label" htmlFor="promotionPopup"
                        onClick={(e) => {
                            this.refs["promotionPopup"].click();
                        }}>
                        Unauth Promotion Popup
                    </label>
                  </div>
                </div>
              </div>
              {auth_service.getPermission("Promotions_Create", "Promotions_FullAccess") && (
                <ButtonWrap className={`${styles.bottombutt}`}>
                  <Button children={"Submit"} onClick={this.onSubmit} />
                </ButtonWrap>
              )}
            </div>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreatePromotion;
