import axios from "axios";
import React, { Component } from "react";
import { auth_service } from "../../auth/auth_service";
import { data } from "./Data/mockData";
import { tableTestimonialsConstants } from "./Data/tableTestimonialsConstants";
import DataTable from "react-data-table-component";
import "./testimonials.module.css";
import { customStyles } from "../../utils/tableCustomStyle";
import { FilterComponent } from "../../utils/filter";
import styles from "./testimonials.module.css";
import { Link } from "react-router-dom";
import { CommonHeader } from "../../utils/commonHeader";
import { epochTogmt } from "../../utils/common";
import ExportCSV from "../../components/Buttons/export_csv_button";
import alertPopUp from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ErorrMsgPopUp = withReactContent(alertPopUp);

const headers = [
  { label: "User Name", key: "name" },
  { label: "User Id", key: "userId" },
  { label: "Profile Link", key: "profileLink" },
  { label: "Status", key: "status" },
];

const MySwal = withReactContent(alertPopUp);

let confirmModalSwal = {
  title: '<h5>Are you sure you want to Delete?</h5>',
  focusConfirm: false,
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  allowOutsideClick: false,
};
export class Testimonials extends Component {

  constructor(props) {
    super(props);
    this.state = {
      testimonialList: [],
      exportTestimonialList: [],
      filterText: "",
      filterDisplay: false,
      customSelectTestimonial: false
    };
  }

  // subheader of a component
  getSubHeaderComponent = () => {
    return (
      <React.Fragment>
        <FilterComponent
          onFilter={(e) => {
            let newFilterText = e.target.value;
            this.filteredItems = this.state.testimonialList.filter(
              (item) =>
                item.name &&
                item.name.toLowerCase().includes(newFilterText.toLowerCase())
            );
            this.setState({ filterText: newFilterText });
          }}
          onClear={this.handleClear}
          filterText={this.state.filterText}
        />
      </React.Fragment>
    );
  };

  // fetch all cuses list
  
  fetchAllTestimonialList = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${process.env.REACT_APP_userServiceURL}/testimonial/list`,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status === "200 OK" &&
          response.data.body
        ) {
          let exportTestimonialList = [];
          let data = response.data.body;
          data && data.map((item, index) => {
            exportTestimonialList.push({"title": item.title, "startDate": epochTogmt(item.startDate), "endDate": epochTogmt(item.endDate), "canLanding": item.canLanding, "status": item.status})
          })
          this.setState({
            testimonialList: data,
            exportTestimonialList: exportTestimonialList
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle filter display
  showFilter = () => {
    this.setState({
      filterDisplay: this.state.filterDisplay ? false : true,
    });
  };
   
  // action button
  actionButton = () => {
    return (
      this.state.testimonialList.length > 0 &&
      <React.Fragment>
        {this.getSubHeaderComponent()}
        {auth_service.getPermission("Testimonial_ExportCSV", "Testimonial_FullAccess") && <ExportCSV headers={headers} data={this.state.testimonialList} filename="promotion" />}
      </React.Fragment>
    );
  };

  agreeHandleChange = (e, id) => {
      let postData = {
        landingPage: e.target.checked,
        id: id
      }
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/testimonial/updateLandingStatus`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              let testimonialList = this.state.testimonialList;
              testimonialList.filter((item)=>
                item.id == id
              ).map(x => x.landingPage = !e.target.checked);
            this.setState({testimonialList:testimonialList})
            }
          })
          .catch((error) => {
            console.log(error);
            ErorrMsgPopUp.fire({
              didOpen: () => {
                ErorrMsgPopUp.clickConfirm()
              }
            }).then(() => {
              return ErorrMsgPopUp.fire(<p>{error.response.data.message}</p>)
            })
          });
  }

  handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(confirmModalSwal);
      let postData = {
        id: id
      }
      if (swalval && swalval.value === true) {
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_userServiceURL}/testimonial/delete`,
          data: postData,
          withCredentials: true,
        })
          .then((response) => {
            if (response && response.data && response.data.status === "202 ACCEPTED") {
              this.fetchAllTestimonialList();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    
    handleConfirm();
  };

  componentDidMount() {
    this.fetchAllTestimonialList();
  }

  render() {
    this.filteredItems = this.state.testimonialList.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    return (
      <CommonHeader>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            {/* <div className={styles.pagetopwrap}>
              <div className={`${styles.pagetopwleft} ${styles.accDtlsWrap}`}>
                <div className={`${styles.ptwrow}`}>
                  <div className="col-md-3">
                    <div className={`form-group`} style={{marginTop: '40px'}}>
                      <label class="switch">
                        Custom Select Testimonial
                        <input
                          type="checkbox"
                          ref="customSelectTestimonial"
                          name="customSelectTestimonial"
                          onClick={(e) => {
                            this.agreeHandleChange(e);
                          }}
                          checked={this.state.customSelectTestimonial}
                        />
                        <span class="slider round"></span>
                      </label>
                  </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col2Large testimonial-page">
              <DataTable
                title="Testimonial"
                columns={tableTestimonialsConstants(this.fetchAllTestimonialList,this.agreeHandleChange,this.handleConfirmModal)}
                data={this.filteredItems}
                customStyles={customStyles}
                actions={this.actionButton()}
                pagination
                paginationPerPage={20}
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight={"calc(100vh - 226px)"}
                //selectableRows={this.state.customSelectTestimonial}
                //onSelectedRowsChange={this.onSelectedTestimonial}
              />
            </div>
          </div>
        </div>
        {auth_service.getPermission("Testimonial_Create", "Testimonial_FullAccess") && (
        <Link to="/testimonials/create" className={`btn ${styles.createforumbtn}`}>
          <i className="iAddCircle"></i> Create Testimonial
        </Link>
        )}
      </CommonHeader>
    );
  }
}

export default Testimonials;
