import React, { Component } from "react";
import { CommonHeader } from "../../../../utils/commonHeader";
import CommonCreditPointsList from "../CommonCreditPointsList";
import history from "../../../../utils/history";
export class CreditGivenpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  clickGoback = () => {
    history.goBack();
  } 

  render() {
    return (
      <CommonHeader>
        <div className="topglobal">
          <a href={"javascript:void(0)"} className="goback" onClick={(e) => {e.preventDefault();this.clickGoback()}}>
            Go Back
          </a>
        </div>
        <div id="page-wrapper" className={"dgcontainer"}>
          <div id="page-inner">
            <CommonCreditPointsList pageType={"CREDIT_GIVEN"} id={this.props.match?.params?.id}/>
          </div>
        </div>
      </CommonHeader>
    );
  }
}

export default CreditGivenpoints;